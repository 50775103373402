import { typeSortEnum } from 'components/SortTable';

interface ITableData {
  id: number;
  title: string;
  dataKey: string;
  headAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  bodyAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  fixedCell?: boolean;
  isAmount?: boolean;
  typeSort?: typeSortEnum.ALPHABET;
  isHiddenSort?: boolean;
}

export const tableCellData: ITableData[] = [
  {
    id: 1,
    title: 'contentHeader.companyName',
    dataKey: 'company_name',
    headAlign: 'left',
    bodyAlign: 'left',
    fixedCell: true,
    typeSort: typeSortEnum.ALPHABET,
  },
  {
    id: 2,
    title: 'tables.clientAmounts.principalAmountDeltaEur',
    dataKey: 'principal_amount_delta_eur',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
  {
    id: 3,
    title: 'tables.clientAmounts.principalAmountDeltaUsd',
    dataKey: 'principal_amount_delta_usd',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
  {
    id: 4,
    title: 'tables.clientAmounts.principalAmountDeltaEurEurUsd',
    dataKey: 'principal_amount_delta_usd_to_eur',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
  {
    id: 5,
    title: 'tables.clientAmounts.principalAmountTotalEur',
    dataKey: 'principal_amount_total_eur',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 6,
    title: 'tables.clientAmounts.principalAmountTotalUsd',
    dataKey: 'principal_amount_total_usd',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 7,
    title: 'tables.clientAmounts.principalAmountTotalEurEurUsd',
    dataKey: 'principal_amount_total_usd_to_eur',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 8,
    title: 'tables.clientAmounts.commissionAccruedEur',
    dataKey: 'accrued_interest_eur',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 9,
    title: 'tables.clientAmounts.commissionAccruedUsd',
    dataKey: 'accrued_interest_usd',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 10,
    title: 'tables.clientAmounts.commissionAccruedEurEurUsd',
    dataKey: 'accrued_interest_usd_to_eur',
    bodyAlign: 'right',
    isAmount: true,
  },
  {
    id: 11,
    title: 'tables.clientAmounts.defaultInterestGrossEur',
    dataKey: 'default_interest_eur',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
  {
    id: 12,
    title: 'tables.clientAmounts.defaultInterestGrossUsd',
    dataKey: 'default_interest_usd',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
  {
    id: 13,
    title: 'tables.clientAmounts.defaultInterestGrossUsdEur',
    dataKey: 'default_interest_usd_to_eur',
    bodyAlign: 'right',
    isAmount: true,
    isHiddenSort: true,
  },
];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import notConnectedIcon from 'assets/img/connectionNotPossible.svg';
import closeRevenueIcon from 'assets/img/closeRevenue.svg';

import PopUpLayout from '../../../../helpers/PopUpLayout';
import Button from '../../../uiKit/Button';

import classes from './styles.module.scss';

type PropsT = {
  isOpen?: boolean;
  onClose?: () => void;
};

const PopupErrorCreate: FC<PropsT> = (props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <PopUpLayout open={isOpen} onClose={onClose}>
      <div className={classes.wrapper}>
        <Button onClick={onClose} variant="text" className={classes.closeButton}>
          <img src={closeRevenueIcon} />
        </Button>
        <img src={notConnectedIcon} />
        <p className={classes.text}>{t('forms.revenue.connectedError')}</p>
      </div>
    </PopUpLayout>
  );
};

export default PopupErrorCreate;

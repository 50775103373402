import React, { useEffect } from 'react';

import AdminTableHeader from 'components/tables/AdminTable/Header';
import AdminTable from 'components/tables/AdminTable';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const AdminListScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Admins');
  }, []);

  return (
    <MainContent type="flexibleLimit">
      <AdminTableHeader requestParams={requestParams} />
      <AdminTable />
    </MainContent>
  );
};

export default AdminListScreen;

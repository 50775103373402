import React, { FC, Children } from 'react';

import styles from './styles.module.scss';

interface PropsT {
  list: { title: string; style?: React.CSSProperties }[];
  current: number;
  callback: (i: number) => void;
  style?: React.CSSProperties;
}

const SwitcherButton: FC<PropsT> = ({ list, current, callback, style }) => (
  <div className={styles.wrapper} style={style}>
    {Children.toArray(
      list.map((item, i) => (
        <button
          onClick={() => callback(i)}
          style={{ background: i === current ? '#ECEEF2' : '', ...item.style }}
          type="button"
        >
          {item.title}
        </button>
      )),
    )}
  </div>
);

export default SwitcherButton;

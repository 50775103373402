import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { ICompanyParamsType } from 'types/CompanyTypes';
import { companiesTableDataV3Type } from 'hooks/api/useCompany';

interface companiesDataType extends ICompanyParamsType {
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

const getCompaniesV3 = ({
  type = '',
  page = 0,
  limit = 25,
  status = '',
  sortedBy,
  order = 'ASC',
  companyName = '',
  group = '',
  errorCallback,
  successCallback,
  resultKey = 'companiesTableDataV3',
}: companiesDataType = {}) =>
  requestAsync({
    url: endpoints.getCompaniesUrlV3({ type, page, order, sortedBy, limit, status, companyName, group }),
    transform: (response: companiesTableDataV3Type) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompaniesUrlV3({ type, page, limit, status, companyName, group }),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

export default getCompaniesV3;

import React, { useRef, useEffect } from 'react';
import { Select, SelectProps } from '@gamesb42/ui-kit';

import { getCompanyById } from 'api/company';
import { CompanyKeyEnum } from 'types/CompanyTypes';
import NoFoundContent from 'components/NoFoundContent';
import useLoadCompanies from 'hooks/useLoadCompanies';

const CompanySelect = ({ value, exceptFactor = false, ...props }: SelectProps & { exceptFactor?: boolean }) => {
  const { onSearch, companies, setCompanies, onClear, loading } = useLoadCompanies(exceptFactor);
  const isFirstLoadData = useRef(true);

  useEffect(() => {
    if (value && isFirstLoadData.current) {
      getCompanyById(value)
        .then((company) => {
          const valueCompany = value.includes('-')
            ? company[CompanyKeyEnum.ID]
            : company[CompanyKeyEnum.ID].replaceAll('-', '');

          setCompanies([
            {
              label: company[CompanyKeyEnum.NAME] as string,
              value: valueCompany,
              source: company,
            },
          ]);
          isFirstLoadData.current = false;
        })
        .catch(console.error);
    }
  }, [setCompanies, value]);

  return (
    <Select
      options={companies}
      onSearch={onSearch}
      filterOption={false}
      notFoundContent={loading ? <NoFoundContent /> : null}
      showSearch
      onClear={onClear}
      value={companies.length > 0 ? value : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CompanySelect;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { getCurrencyWithCode, formatAmount } from 'helpers/formatters';
import getParams from 'helpers/getParams';
import { DocumentTypeEnum } from 'components/tables/TransactionsTable/ViewsForm/data';
import urls from 'constants/urls';
import { getSymbolAndColorTransactions } from 'components/tables/TransactionsTable/data';

import { getPathOfDocumentType, getTitleOfDocumentType } from '../../data';

import styles from './styles.module.scss';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
  aoType: string;
};

const AdjustmentTransactionView: FC<PropsT> = ({ transactionsDataById, aoType }) => {
  const { t } = useTranslation();
  const { color, symbol } = getSymbolAndColorTransactions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transactionsDataById?.amount?.amount,
    transactionsDataById.type,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.amount')}`}
            value={`${symbol}${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency))}${formatAmount(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              Math.abs(transactionsDataById?.amount?.amount) || 0,
            )}`}
            additionalStyle={{ fontWeight: 700, color }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')}`}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')}`}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            title={`${t('forms.transaction.client')}`}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              link: `${getPathOfDocumentType[transactionsDataById.document_type || DocumentTypeEnum.DEFAULT]}/${
                transactionsDataById.document_id || transactionsDataById?.factoring_order_id
              }${getParams({ type: aoType })}`,
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            title={`${t(getTitleOfDocumentType[transactionsDataById.document_type || DocumentTypeEnum.DEFAULT])}`}
            value={transactionsDataById?.document_number || transactionsDataById?.factoring_order_number}
            additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.comment')}`}
            value={transactionsDataById?.comment}
          />
        </Card>
      </div>
    </div>
  );
};

export default AdjustmentTransactionView;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonModal from 'components/uiKit/CommonModal';
import { InputKit } from 'components/uiKit/InputKit';
import Button from 'components/uiKit/Button';
import PdfIcon from 'components/common/PdfIcon';
import acrossSvg from 'assets/img/across.svg';
import DropZoneDocument from '../../../DropZoneDocument';

import { UploadInvoiceParamsProps } from '../index';
import styles from './styles.module.scss';

const applicationPdf = 'application/pdf';

interface Props {
  uploadInvoiceParams?: UploadInvoiceParamsProps;
  setUploadInvoiceParams: (value?: UploadInvoiceParamsProps) => void;
  onSaveFile: (value: { file: File; documentNumber?: string }) => void;
}

const UploadModal: FC<Props> = ({ uploadInvoiceParams, setUploadInvoiceParams, onSaveFile }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [documentNumber, setDocumentNumber] = useState<string>();

  const onRemoveFile = () => setFile(undefined);

  const onClearForm = () => {
    onRemoveFile();
    setDocumentNumber(undefined);
  };

  const onCloseUploadPopup = () => {
    setUploadInvoiceParams();
    onClearForm();
  };

  const uploadCurrentFile = () => {
    const objectURL = URL.createObjectURL(file as File);
    const link = document.createElement('a');
    link.href = objectURL;
    link.target = '_blank';
    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
  };

  const onClickSave = () => onSaveFile({ file: file as File, documentNumber });

  const nameFile =
    uploadInvoiceParams?.invoiceNumber &&
    uploadInvoiceParams?.name.includes(uploadInvoiceParams.invoiceNumber) &&
    documentNumber
      ? uploadInvoiceParams?.name.replace(uploadInvoiceParams.invoiceNumber, documentNumber)
      : uploadInvoiceParams?.name;

  if (!uploadInvoiceParams) return null;

  return (
    <CommonModal
      open={!!uploadInvoiceParams}
      onOpen={onClearForm}
      title={`Upload Invoice #${uploadInvoiceParams.invoiceNumber}`}
      onClose={onCloseUploadPopup}
      actions={
        <Button color="gray" variant="fill" disabled={!file} onClick={onClickSave}>
          Upload Document
        </Button>
      }
      actionsClassName={styles.actions}
    >
      <div className={styles.popup}>
        <InputKit
          value={documentNumber}
          onChange={(e) => setDocumentNumber(e.target.value)}
          label="Document number"
          wrapProps={{ height: 60, marginBottom: 24 }}
        />
        {!file && (
          <DropZoneDocument
            maxSize={10000000}
            accessFiles={file ? [file] : []}
            acceptFormat={applicationPdf}
            setAccessFiles={(access) => setFile(access[0])}
            title={t('clientTabs.dragAndDropFile')}
            text="(file weight before 10 Mb)"
            buttonText={t('forms.activationOrder.dropZoneButton')}
          />
        )}
        {file && (
          <div className={styles.fileWrapper} onClick={uploadCurrentFile}>
            <PdfIcon className={styles.pdfIcon} />
            <span className={styles.name}>{nameFile}</span>
            <button
              className={styles.close}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveFile();
              }}
            >
              <img src={acrossSvg} className={styles.closeIcon} alt="close" />
            </button>
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default UploadModal;

import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { RevenueSource, RevenueSourceForm } from 'types/RevenueSources';

export const getRevenueSources = () => baseRequest<RevenueSource[]>({ path: endpoints.getRevenueSourcesUrl() });
export const getRevenueSourceById = (id: string) => baseRequest({ path: endpoints.getRevenueSourcesUrl(id) });
export const createRevenueSource = (revenueSource: RevenueSourceForm) =>
  baseRequest({ path: endpoints.getRevenueSourcesUrl(), options: { method: 'POST', body: revenueSource } });
export const editRevenueSource = (revenueSource: RevenueSourceForm, id: string) =>
  baseRequest({ path: endpoints.getRevenueSourcesUrl(id), options: { method: 'PATCH', body: revenueSource } });
export const deleteRevenueSource = (id: string) =>
  baseRequest({ path: endpoints.getRevenueSourcesUrl(id), options: { method: 'DELETE' } });
export const syncPds = () => baseRequest({ path: endpoints.getSyncPdsUrl() });

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/uiKit/Button';
import PopUpLayout from 'helpers/PopUpLayout';
import DropZoneDocument, { DropZoneDocumentPropsType } from 'components/forms/CompanyForm/DropZoneDocument';

import styles from './styles.module.scss';

const closeSvg = require('assets/img/closeRevenue.svg').default;

interface PropsT extends DropZoneDocumentPropsType {
  titlePopUp: string;
  titleButton: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  callback: (data: File[]) => void;
}
const DropZoneWithTitleButton: FC<PropsT> = ({
  titlePopUp,
  titleButton,
  isOpen,
  setIsOpen,
  title = 'clientTabs.dragAndDropFile',
  text = 'clientTabs.youCanUploadUpCSVfile',
  buttonText = 'clientTabs.chooseFile',
  callback,
  ...props
}) => {
  const { t } = useTranslation();
  const [accessFiles, setAccessFiles] = useState<File[]>([]);
  const isFile = accessFiles.length > 0;

  return (
    <PopUpLayout
      style={{
        padding: 40,
        backgroundColor: '#F7F8FA',
        borderRadius: 16,
        position: 'relative',
        width: 630,
        height: isFile ? 277 : 394,
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <img
        onClick={() => setIsOpen(false)}
        style={{ position: 'absolute', right: 40, cursor: 'pointer' }}
        src={closeSvg}
        alt="Close"
      />
      <div className={styles.titlePopUp}>{titlePopUp}</div>
      <DropZoneDocument
        isHiddenDropZoneArea={isFile}
        wrapperStyle={{ height: 145 }}
        acceptFormat={['.csv']}
        title={t(title)}
        text={t(text)}
        buttonText={t(buttonText)}
        accessFiles={accessFiles}
        setAccessFiles={setAccessFiles}
        {...props}
      />
      <Button
        variant="fill"
        color="grey_light"
        style={{ height: 60, width: 211, marginTop: 40 }}
        onClick={() => {
          callback(accessFiles);
          setIsOpen(true);
        }}
      >
        {titleButton}
      </Button>
    </PopUpLayout>
  );
};

export default DropZoneWithTitleButton;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface createContractDataBodyType {
  name: string;
  type: string;
  number: string;
  date: string;
  company_id: string;
  file_data: string;
}

type createContractDataType = {
  errorCallback: Function;
  resultKey?: string;
  successCallback: Function;
  body: createContractDataBodyType;
};

export default ({ body, resultKey = 'createContractData', errorCallback, successCallback }: createContractDataType) =>
  requestAsync({
    url: endpoints.getContractCreateUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getContractCreateUrl(),
    body,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC, Children, ButtonHTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Popup from 'components/common/Popup';

import styles from './styles.module.scss';

type ButtonPropsT = ButtonHTMLAttributes<HTMLButtonElement> & {
  image: string;
  title: string;
};

type CustomContentT = (handleOpenPopup: (value: boolean) => void) => ReactNode;

interface PropsT {
  buttonList?: ButtonPropsT[];
  customContents?: CustomContentT[];
}

const EditGeneral: FC<PropsT> = (props) => {
  const { buttonList = [], customContents = [] } = props;
  const { t } = useTranslation();

  if (buttonList.length === 0 && customContents.length === 0) return null;

  return (
    <Popup>
      {(handleOpenPopup) => (
        <>
          {Children.toArray(
            buttonList.map(({ title, image, onClick, ...restProps }) => (
              <button
                type="button"
                className={styles.item}
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onClick(e);
                  handleOpenPopup(false);
                }}
                {...restProps}
              >
                <span className={styles.iconWrapper}>
                  <img src={image} alt="img" />
                </span>

                <span>{t(title)}</span>
              </button>
            )),
          )}
          {customContents.map((customContent: CustomContentT) => customContent(handleOpenPopup))}
        </>
      )}
    </Popup>
  );
};

export default EditGeneral;

import React, { useEffect } from 'react';

import PurchaseRequestForm from 'components/forms/PurchaseRequestForm/Form';
import { VariantEnum } from 'components/forms/PurchaseRequestForm/Form/data';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const PurchaseRequestEditScreen = () => {
  useEffect(() => {
    updateTitle('Edit Purchase Request');
  }, []);

  return (
    <MainContent type="form">
      <PurchaseRequestForm variant={VariantEnum.EDIT} />
    </MainContent>
  );
};

export default PurchaseRequestEditScreen;

import React, { FC, useContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import useErrorCallback from 'hooks/useErrorCallback';
import useAmount from 'hooks/api/useAmount';
import { RefreshContext } from 'contexts/RefreshContext';
import { tableSettingsStyles } from 'theme/styles';
import useRouteTitle from 'hooks/useRouteTitle';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { firstDayOfMonth, firstDayOfNextMonth } from 'helpers/dt';
import { formatDateIsoShort, formatMonthYear } from 'helpers/formatters';
import { ClientsAmountReportFilters } from 'consts/tableFilters';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';

const downloadSvg = require('assets/img/download.svg').default;

const getFirstDayOfMonth = (value: string) => formatDateIsoShort(firstDayOfMonth(new Date(value)));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ClientAmountReportTableHeader: FC | any = ({ requestParams }) => {
  const { t } = useTranslation();
  const classes = tableSettingsStyles();
  const { title } = useRouteTitle();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);
  const { dateValidationByRange } = useFilterDateValidate();
  const {
    getClientAmountsReport,
    clientAmountsTableParams,
    setClientAmountsTableParams,
    clearClientAmountsReportData,
  } = useAmount();
  const [validate, setValidate] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    date_for: { status: false, text: `${t('dateErrors.dateMax')} ${formatMonthYear(new Date())}` },
  });
  const isShowDownload = false;

  useEffect(() => {
    if (validate) {
      refreshContext.clear();
      refreshContext.add({
        func: getClientAmountsReport,
        params: { ...clientAmountsTableParams, errorCallback },
      });
    }
  }, [getClientAmountsReport, clientAmountsTableParams, refreshContext, errorCallback, validate]);

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'clientAmountsTableParams',
      params: clientAmountsTableParams,
      requestParams,
    });

    const validated = dateValidationByRange(newParams?.date_for, undefined, firstDayOfNextMonth(new Date()));

    if (validated) {
      setValidate(true);

      return;
    }
    setErrors(({ date_for }) => ({
      date_for: { ...date_for, status: true },
    }));
  }, []);

  useEffect(() => {
    refreshContext.setParams({ datakey: 'clientAmountsTableParams', params: clientAmountsTableParams });
    setUrlStringParams({ filters: ClientsAmountReportFilters, currentScreenParams: clientAmountsTableParams });

    if (validate) {
      getClientAmountsReport({
        params: clientAmountsTableParams,
        errorCallback,
      });
    }
  }, [clientAmountsTableParams, validate]);

  const onChangeDateForHandler = useCallback(
    ({ target: { name, value } }) => {
      const newParams = { ...clientAmountsTableParams, [name]: getFirstDayOfMonth(value) };
      const validated = dateValidationByRange(value, undefined, firstDayOfNextMonth(new Date()));
      setValidate(validated);

      if (validated) {
        setErrors(({ date_for }) => ({
          date_for: { ...date_for, status: false },
        }));
      } else {
        clearClientAmountsReportData();
        setErrors(({ date_for }) => ({
          date_for: { ...date_for, status: true },
        }));
      }
      setClientAmountsTableParams(newParams);
    },
    [
      setClientAmountsTableParams,
      clientAmountsTableParams,
      errorCallback,
      dateValidationByRange,
      getClientAmountsReport,
      clearClientAmountsReportData,
    ],
  );

  return (
    <div>
      <div className={classes.titleSettings}>{title}</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr', marginBottom: 100 }}>
        <DateInput
          disableFutureMonth
          disableFutureYears
          type={DatePikerEnum.WITHOUT}
          name="date_for"
          required
          date={clientAmountsTableParams.date_for}
          commonStyle={{
            width: errors.date_for.status ? 320 : 170,
            height: 40,
            marginRight: 10,
            flexDirection: 'initial',
          }}
          onChangeDateHandler={onChangeDateForHandler}
          errorText={errors.date_for.text}
          isError={errors.date_for.status}
          HelperTextComponent={(props) => <span {...props} style={{ display: 'block', lineHeight: 1.2 }} />}
        />
        {isShowDownload && (
          <Button variant="text" style={{ minWidth: 40, height: 40, justifySelf: 'end', border: '1px solid #A7AFB7' }}>
            <img src={downloadSvg} alt="download" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ClientAmountReportTableHeader;

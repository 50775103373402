import React, { useEffect, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '@gamesb42/ui-kit';

import useRouteTitle from 'hooks/useRouteTitle';
import DateInput from 'components/inputs/DateInput';
import urls from 'constants/urls';
import SelectButton from 'components/SelectButton';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { TransactionTableFilters } from 'consts/tableFilters';
import { getUrlStringParams } from 'helpers/setUrlStringParams';
import { removeEmptyValuesFromObject } from 'helpers/dt';
import { InputKit } from 'components/uiKit/InputKit';
import {
  KeyTransactions,
  getListValueTypeTransactions,
  getObjectTransactions,
  getCurrentObjCurrency,
  getCurrencyListTransactions,
  getTypeTransactions,
} from 'components/tables/TransactionsTable/data';
import Select from 'components/uiKit/Select';
import CompanySelect from 'components/CompanySelect';

import useDebounce from '../../../hooks/useDebounce';

import styles from './styles.module.scss';
import { getListTransactionCategory } from './ViewsForm/data';
import DownloadLink from './DownloadLink';
import TransactionsMenu from './TransactionsMenu';

const currentPath = [
  `${urls.getTransactionsCreate()}/incoming`,
  `${urls.getTransactionsCreate()}/outgoing`,
  `${urls.getTransactionsCreate()}/internal`,
  `${urls.getTransactionsCreate()}/fee`,
  `${urls.getTransactionsCreate()}/adjustment`,
  `${urls.getTransactionsCreate()}/conversion`,
  urls.getConversionTransactionCreate(),
];

interface TransactionTableHeaderPropsType {
  requestParams: { filter: string; sort: string };
  isChecked: boolean;
  setIsChecked: (data: boolean) => void;
}

const TransactionsTableHeader: FC<TransactionTableHeaderPropsType> = ({ requestParams, isChecked, setIsChecked }) => {
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const { getListTransactions, getArchiveTransactions }: useTransactionType = useTransaction();
  const history = useHistory();
  const { filter, sort } = requestParams;
  const { type, currency, decodeList, category, date_from, date_to, amount, client_id } = useMemo(() => {
    let filterArray: {
      type: { key?: string; value?: string };
      currency: {
        key: string;
        value: string;
      };
      category: string;
      date_from: string;
      date_to: string;
      amount: string;
      client_id: string;
      decodeList: [];
    } = {
      type: {},
      currency: {
        key: '',
        value: '',
      },
      category: '',
      date_from: '',
      date_to: '',
      amount: '',
      client_id: '',
      decodeList: [],
    };

    if (!filter) return filterArray;
    const decodeFilter: { property: string; operator: string; value: string }[] = JSON.parse(decodeURI(filter));

    if (Array.isArray(decodeFilter)) {
      decodeFilter.forEach((item) => {
        if (item.property === 'type') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterArray = { ...filterArray, [item.property]: getObjectTransactions()[item.value] };
        }

        if (item.property === 'currency') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterArray = { ...filterArray, [item.property]: getCurrentObjCurrency[item.value] };
        }

        if (item.property !== 'type' && item.property !== 'currency') {
          filterArray = { ...filterArray, [item.property]: item.value };
        }
      });
    }

    return { ...filterArray, decodeList: decodeFilter };
  }, [filter]);

  const getListOfTransactions = useCallback(
    (params) =>
      isChecked
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getArchiveTransactions({ resultKey: 'listTransactionData', params })
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getListTransactions({ params }),
    [isChecked],
  );

  useEffect(() => {
    getListOfTransactions(requestParams);
  }, [isChecked]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const categoryOptions = type.key ? getListTransactionCategory[KeyTransactions[type.key.toUpperCase()]]() : [];

  const getListTransactionDebounce = useDebounce((getTransactions: () => void) => {
    getTransactions();
  }, 500);

  useEffect(() => {
    const newPath = getUrlStringParams({
      filters: TransactionTableFilters,
      currentScreenParams: removeEmptyValuesFromObject({ ...requestParams }),
    });

    history.replace(newPath);
  }, []);

  const setParams = useCallback(
    (
      param: {
        type?: string;
        currency?: string;
        category?: string;
        date_from?: string;
        date_to?: string;
        amount?: string;
        client_id?: string;
      },
      operator = '=',
    ) => {
      // NOTE: почему считывается только первый показатель, из-за этого бага при сбрасывании param.category
      const value = Object.values(param)[0];
      const key = Object.keys(param)[0];
      const filterWithoutCurrentParams = decodeList.filter((item) => item.property !== key);
      const current = { property: key, operator, value };
      let filterParams = value ? [...filterWithoutCurrentParams, current] : filterWithoutCurrentParams;

      if (param.category === '') {
        filterParams = filterParams.filter((item) => item.property !== 'category');
      }
      const result =
        filterParams.length === 0
          ? { filter: '', page: 1 }
          : { filter: encodeURI(JSON.stringify([...filterParams])), page: 1 };
      const newPath = getUrlStringParams({
        filters: TransactionTableFilters,
        currentScreenParams: removeEmptyValuesFromObject({ ...requestParams, ...result }),
      });
      history.replace(newPath);

      if (current.property !== 'amount') {
        getListOfTransactions(removeEmptyValuesFromObject({ ...requestParams, ...result }));
      }

      if (current.property === 'amount') {
        getListTransactionDebounce(() =>
          getListOfTransactions(removeEmptyValuesFromObject({ ...requestParams, ...result })),
        );
      }
    },
    [requestParams],
  );

  return (
    <div>
      <PageHeader
        title={title}
        className={styles.headerTitle}
        rightContent={
          <SelectButton
            title={t('tables.createTransaction')}
            list={getTypeTransactions()}
            callback={(i) => history.push(currentPath[i])}
          />
        }
      />
      <div style={{ marginRight: 60, position: 'relative' }}>
        <DownloadLink filter={filter} sort={sort} />
        <div className={styles.menuWrapper}>
          <TransactionsMenu isChecked={isChecked} setIsChecked={setIsChecked} />
        </div>
        <div className={styles.headerWrap}>
          <DateInput
            isNotErrorLocal
            date={date_from}
            labelKey="tables.dateFrom"
            name="date_from"
            onChangeDateHandler={({ target: { value } }) => {
              if (value !== 'finish') setParams({ date_from: value }, '>=');
            }}
            commonStyle={{ height: 40, width: 200 }}
          />
          <DateInput
            isNotErrorLocal
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            date={date_to || null}
            labelKey="tables.dateTo"
            name="date_to"
            onChangeDateHandler={({ target: { value } }) => {
              if (value !== 'finish') setParams({ date_to: value }, '<');
            }}
            commonStyle={{ height: 40, width: 200 }}
          />
          <Select
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={type.key}
            label={t('forms.transaction.type')}
            options={getListValueTypeTransactions()}
            onChange={(newType) => {
              setParams({ type: newType, category: '' });
            }}
            width="256px"
          />
          {(type?.key === KeyTransactions.INTERNAL || type.key === KeyTransactions.OUTGOING) && (
            <Select
              label={t('forms.transaction.category')}
              options={categoryOptions}
              value={category}
              onChange={(newCategory) => {
                setParams({ category: newCategory });
              }}
              width="256px"
            />
          )}
          <Select
            label={t('contentHeader.currency')}
            options={getCurrencyListTransactions}
            value={currency.key}
            onChange={(newCurrency) => {
              setParams({ currency: newCurrency });
            }}
            width="112px"
          />
          <InputKit
            label={t('forms.transaction.amount')}
            onChange={(e) => {
              setParams({ amount: e.target.value });
            }}
            value={amount}
            wrapProps={{ height: 40, width: 256 }}
          />
          <CompanySelect
            label={t('tables.client')}
            size="middle"
            width="320px"
            value={client_id || undefined}
            onChange={(id) => setParams({ client_id: id })}
            allowClear
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionsTableHeader;

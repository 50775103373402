import React, { FC } from 'react';

import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import ConstructorTransForm from '../../ConstructorTransForm';

interface OutgoingCardType {}
const OutgoingCard: FC<OutgoingCardType> = () => {
  const { transactionsFormData }: useTransactionType = useTransaction();

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ConstructorTransForm types={[KeyTransactions.TYPE, null]} />
      <ConstructorTransForm types={[KeyTransactions.CATEGORY, KeyTransactions.VALUE_DATE_TIME]} />
      <ConstructorTransForm types={[KeyTransactions.RECIPIENT]} />
      <ConstructorTransForm types={[KeyTransactions.CHECKBOX]} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {!transactionsFormData[KeyTransactions.CHECKBOX] && <ConstructorTransForm types={[KeyTransactions.SENDER]} />}
      <ConstructorTransForm types={[KeyTransactions.FACTOR_BANK_ACCOUNT]} />
      <ConstructorTransForm types={[KeyTransactions.DOCUMENT_AO]} />
      <ConstructorTransForm types={[KeyTransactions.DOCUMENT_REQUEST]} />
      <ConstructorTransForm types={[KeyTransactions.CURRENCY, KeyTransactions.AMOUNT]} />
      {['outgoing-balance-payable', 'outgoing-transit'].includes(transactionsFormData?.category!) && (
        <ConstructorTransForm types={[KeyTransactions.AVAILABLE_BALANCE]} />
      )}
      <ConstructorTransForm types={[KeyTransactions.DETAILS]} />
      <ConstructorTransForm types={[KeyTransactions.COMMENT]} />
    </>
  );
};
export default OutgoingCard;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { VatType, PenaltyType } from 'types/ActivationOrderTypes';

export type editActivationOrderDataType = {
  id: string;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
  body: {
    company_id?: string;
    name?: string;
    amount?: string;
    currency?: string;
    interest?: string;
    purchase_date?: string;
    repayment_date?: string;
    status?: string;
    contract_id?: string;
    grace_period?: number;
    is_custom_payment_day?: boolean;
    payment_days?: [];
    commission_disable_date?: string;
    capitalization_disable_date?: string;
    default_interest_disable_date?: string;
    vat_policy?: VatType;
    penalty_policy?: PenaltyType;
    ao_document_id?: string;
    capitalization_period_disabled?: Array<object>;
  };
};

export default ({
  id,
  successCallback,
  errorCallback,
  body,
  resultKey = 'editActivationOrderData',
}: editActivationOrderDataType) =>
  requestAsync({
    url: endpoints.getActivationOrderUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getActivationOrderUrl(id)}`,
    body,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { KeyCardsEnum, StatusEnumAo } from '../data';
import { CustomParamsKeyOfCardType } from '../useCurrentRequiredKeyOfCard';

const initialFuture = [
  KeyCardsEnum.STATUS,
  KeyCardsEnum.AO_TYPE,
  KeyCardsEnum.COMPANY_ID,
  KeyCardsEnum.NAME,
  KeyCardsEnum.CONTRACT_ID,
  KeyCardsEnum.CURRENCY,
  KeyCardsEnum.SIGN_DATE,
  KeyCardsEnum.PENALTY_POLICY,
  KeyCardsEnum.COMMENT,
  KeyCardsEnum.AMOUNT,
  KeyCardsEnum.WITHHOLD_PERCENTAGE,
  KeyCardsEnum.PURCHASED_RECEIVABLES,
];

interface FuncFutureParamsType {
  status: StatusEnumAo;
  customParams?: CustomParamsKeyOfCardType;
}

export const requiredFutureReceivablesKeys = ({ status, customParams = {} }: FuncFutureParamsType) => {
  if (status === StatusEnumAo.REPAID) {
    return [...initialFuture, KeyCardsEnum.CLOSING_DATE];
  }

  if (status === StatusEnumAo.FUNDED && customParams?.isOpenClosingDatePopUp) {
    return [...initialFuture, KeyCardsEnum.TX_DATE];
  }

  return initialFuture;
};

export const requiredFutureReceivablesEditKeys = ({ status, customParams }: FuncFutureParamsType) => [
  ...requiredFutureReceivablesKeys({ status, customParams }),
];

export const errorsFutureReceivables = {
  [KeyCardsEnum.ROOT]: [
    { key: KeyCardsEnum.AO_TYPE, parameters: { required: true } },
    { key: KeyCardsEnum.COMPANY_ID, parameters: { required: true } },
    { key: KeyCardsEnum.NAME, parameters: { required: true } },
    { key: KeyCardsEnum.CONTRACT_ID, parameters: { required: true } },
    { key: KeyCardsEnum.CURRENCY, parameters: { required: true } },
    { key: KeyCardsEnum.SIGN_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.PENALTY_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.STATUS, parameters: { required: true } },
    { key: KeyCardsEnum.AMOUNT, parameters: { required: true } },
    { key: KeyCardsEnum.CLOSING_DATE, parameters: { required: true, date: true } },
    {
      key: KeyCardsEnum.WITHHOLD_PERCENTAGE,
      parameters: { required: false, range: { minRange: 1, maxRange: 100 } },
    },
    { key: KeyCardsEnum.TX_DATE, parameters: { required: true } },
    { key: KeyCardsEnum.PURCHASED_RECEIVABLES, parameters: { required: true } },
  ],
};

export type errorsAvailableReceivableType = typeof errorsFutureReceivables;

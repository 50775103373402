import { KeyCardsEnum, StatusEnumAo } from '../data';

const initialAvailable = [
  KeyCardsEnum.AO_TYPE,
  KeyCardsEnum.COMPANY_ID,
  KeyCardsEnum.NAME,
  KeyCardsEnum.CONTRACT_ID,
  KeyCardsEnum.SIGN_DATE,
  KeyCardsEnum.PENALTY_POLICY,
  KeyCardsEnum.DISTRIBUTION_PLATFORMS,
  KeyCardsEnum.STATUS,
];

export const requiredAvailableReceivablesKeys = (status?: StatusEnumAo) =>
  status === StatusEnumAo.REPAID ? [...initialAvailable, KeyCardsEnum.CLOSING_DATE] : initialAvailable;

export const requiredAvailableReceivablesEditKeys = (status?: StatusEnumAo) => [
  ...requiredAvailableReceivablesKeys(status),
];

export const errorsAvailableReceivable = {
  [KeyCardsEnum.ROOT]: [
    { key: KeyCardsEnum.AO_TYPE, parameters: { required: true } },
    { key: KeyCardsEnum.COMPANY_ID, parameters: { required: true } },
    { key: KeyCardsEnum.NAME, parameters: { required: true } },
    { key: KeyCardsEnum.CONTRACT_ID, parameters: { required: true } },
    { key: KeyCardsEnum.SIGN_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.PENALTY_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.STATUS, parameters: { required: true } },
    { key: KeyCardsEnum.CLOSING_DATE, parameters: { required: true, date: true } },
  ],
  [KeyCardsEnum.DISTRIBUTION_PLATFORMS]: [
    { key: KeyCardsEnum.CURRENCY, parameters: { required: true } },
    { key: KeyCardsEnum.PLATFORM_ID, parameters: { required: true } },
    { key: KeyCardsEnum.PURCHASE_PERCENTAGE, parameters: { required: true } },
    { key: KeyCardsEnum.COMMISSION, parameters: { required: true } },
    { key: KeyCardsEnum.ACTIVATION_DATE, parameters: { date: true } },
  ],
};

export type errorsAvailableReceivableType = typeof errorsAvailableReceivable;

import React, { Children, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import useTransaction from 'hooks/api/useTransaction';
import urls from 'constants/urls';
import { TransactionTableFilters } from 'consts/tableFilters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { TransactionTablePropsType } from 'components/tables/TransactionTableOld/data';
import { tableSettingsStyles } from 'theme/styles';

import TableWrapWithPagination from '../TableWrapWithPagination';

import Row from './Row';

const TransactionTableOld: FC<TransactionTablePropsType> = ({ wrapperStyle }) => {
  const classes = tableSettingsStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { transactionsDataV2, setTransactionTableParams, transactionTableParams } = useTransaction();
  const [transactions, setTransactions] = useState([]);
  const [transactionsTotal, setTransactionsTotal] = useState(0);
  const [localPage, setLocalPage] = useState<number>(transactionTableParams.page);
  const [disabled, setDisable] = useState<boolean>(false);

  useMemo(() => {
    if (Object.keys(transactionsDataV2).length) {
      setTransactions(transactionsDataV2.records);
      setTransactionsTotal(transactionsDataV2.total);
      setLocalPage(transactionsDataV2.page);
      setDisable(false);
    } else {
      setTransactions([]);
      setTransactionsTotal(0);
      setLocalPage(0);
      setDisable(false);
    }
  }, [transactionsDataV2]);

  useEffect(() => {
    setUrlStringParams({ filters: TransactionTableFilters, currentScreenParams: transactionTableParams });
  }, [transactionTableParams]);

  const rowClickHandler = useCallback(
    (transaction: any) => {
      let route;

      if (transaction.type === 'CONVERSION') {
        route = `${urls.getConversionTransaction()}/${transaction.id}`;
      } else if (transaction.adjusting_transaction) {
        route = `${urls.getAdjustingTransaction()}/${transaction.id}`;
      } else {
        route = `${urls.getTransaction()}/${transaction.id}`;
      }
      history.push(route);
    },
    [history],
  );

  const handleChangePage = useCallback(
    (page) => {
      setTransactionTableParams({
        ...transactionTableParams,
        page,
      });
      setDisable(true);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  const handleChangeRowsPerPage = useCallback(
    (e) => {
      setTransactionTableParams({
        ...transactionTableParams,
        limit: e.target.value,
        page: e.target.value !== transactionTableParams.page ? 0 : transactionTableParams.page,
      });
      setDisable(true);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  return (
    <TableWrapWithPagination
      styleTop={{ top: 50, ...wrapperStyle }}
      count={transactionsTotal}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={localPage}
      rowsPerPageOptions={[25, 50, 100]}
      rowsPerPage={Number(transactionTableParams.limit)}
      disabled={disabled}
    >
      <TableHead>
        <TableRow classes={{ root: classes.headTableRowRoot }}>
          <TableCell style={{ minWidth: 100, width: 100 }} classes={{ root: classes.headTableCellRoot }} align="left">
            {t('tables.date')}
          </TableCell>
          <TableCell classes={{ root: classes.headTableCellRoot }} align="left">
            {t('tables.companyName')}
          </TableCell>
          <TableCell classes={{ root: classes.headTableCellRoot }} align="left">
            {t('tables.type')}
          </TableCell>
          <TableCell classes={{ root: classes.headTableCellRoot }} align="right">
            {t('tables.amount')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.bodyTableRoot }}>
        {Children.toArray(
          transactions.map((transaction) => <Row transaction={transaction} onClick={rowClickHandler} />),
        )}
      </TableBody>
    </TableWrapWithPagination>
  );
};

export default TransactionTableOld;

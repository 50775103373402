import React, { FC } from 'react';

const wrapStyle = {
  display: 'grid',
  columnGap: 24,
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  marginBottom: 24,
  position: 'relative' as const,
};
interface TemplateGridType {
  style?: React.CSSProperties;
}
const TemplateGrid: FC<TemplateGridType> = ({ children, style }) => (
  <div style={{ ...wrapStyle, ...style }}>{children}</div>
);

export default TemplateGrid;

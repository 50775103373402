import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatAmount } from 'helpers/formatters';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LoanBalanceAlert = ({ target }) => {
  const { t } = useTranslation();

  if (target && target.order_id && target.target_type === 'LOAN_ISSUE') {
    const currency = target.order_currency;
    const formattedIssued = formatAmount(target.order_amount, currency);
    const amount = formatAmount(target.amount, currency);

    const delta = target.order_amount - target.amount;

    if (!delta) {
      return null;
    }

    let diffWord = t('forms.transaction.less');

    if (delta > 0) {
      diffWord = t('forms.transaction.more');
    }

    const firstPart = t('forms.transaction.loanBalanceAlert.alertTextFirsPart');
    const secondPart = t('forms.transaction.loanBalanceAlert.alertTextSecondPart');
    const thirdPart = t('forms.transaction.loanBalanceAlert.alertTextThirdPart');
    const lastPart = t('forms.transaction.loanBalanceAlert.alertTextLastPart');

    const formattedDelta = formatAmount(Math.abs(delta), currency);

    const message = `${firstPart}${formattedIssued}${secondPart}${diffWord}${thirdPart}${amount}${lastPart}${formattedDelta}`;

    return <span style={{ color: 'blue', marginBottom: 5 }}>{message}</span>;
  }

  return null;
};

export default LoanBalanceAlert;

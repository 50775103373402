import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

type DeleteButtonPropsType = {
  deleted: boolean;
  deleteHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  toolTip?: string;
  style?: React.CSSProperties;
};

const DeleteButton: FC<DeleteButtonPropsType> = ({
  deleted,
  deleteHandler,
  toolTip = '',
  style,
}: DeleteButtonPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div style={style} className={styles.deleteBlock} data-tip={toolTip}>
      <Button color="secondary" disabled={deleted} onClick={deleteHandler}>
        <DeleteIcon fontSize="small" className={styles.buttonIcon} />
        <span>{t('global.delete')}</span>
      </Button>
    </div>
  );
};

export default DeleteButton;

const useStyles = makeStyles(() => ({
  deleteBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '24px',
    paddingBottom: '8px',
  },
  buttonIcon: {
    marginRight: '8px',
  },
}));

import React, { Children, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMonthlyReport from 'hooks/api/useMonthlyReport';
import { monthlyInvoiceReportsType } from 'queries/monthlyReport/getMonthlyInvoiceReports';
import Button from 'components/uiKit/Button';
import { getCurrentYear } from 'helpers/formatters';
import useAlert from 'hooks/api/useAlert';
import useErrorCallback from 'hooks/useErrorCallback';

import { reportsDataButtons } from './data';
import styles from './styles.module.scss';

interface ReportsPropsType {
  company_id: string;
  tab: 'invoice' | 'monthly';
}

interface useMonthlyReportType {
  getMonthlyInvoiceReports: (data: monthlyInvoiceReportsType) => void;
}

const ActionButtons: FC<ReportsPropsType> = ({ company_id, tab }) => {
  const [year] = useState<number>(getCurrentYear());
  const { t } = useTranslation();
  const { errorCallback } = useErrorCallback(() => true);
  const { getMonthlyInvoiceReports }: useMonthlyReportType = useMonthlyReport();
  const [visibleButton, setVisibleButton] = useState<string | null>(null);
  const actionButtons = tab === 'invoice' ? [] : reportsDataButtons;
  const { setAlert } = useAlert();
  const showAlert = useCallback(
    (message) => {
      setAlert({
        type: 'success',
        message: `${t(message)}`,
      });
    },
    [setAlert, t],
  );

  return (
    <div className={styles.wrapper}>
      {Children.toArray(
        actionButtons.map(({ dataKey, func, titleButton }) => (
          <Button
            style={{ height: 40 }}
            color="gray"
            disabled={visibleButton === dataKey}
            onClick={() => {
              if (visibleButton) {
                return;
              }
              func({
                companyId: company_id,
                getMonthlyInvoiceReports,
                setVisibleButton,
                visibleButton,
                showAlert,
                year,
                errorCallback,
              });
            }}
          >
            {t(titleButton || '')}
          </Button>
        )),
      )}
    </div>
  );
};

export default ActionButtons;

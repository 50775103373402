import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab } from '@material-ui/core';
import { TabList, TabPanel, TabContext } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import useModal from 'hooks/api/useModal';
import useTransaction from 'hooks/api/useTransaction';
import DeleteButton from 'components/buttons/DeleteButton';

import EntriesTable from './EntriesTable';
import TransactionFormBody from './TransactionFormBody';
import ConversionTransaction from './ConversionTransaction';

const TransactionForm = ({ editType = false, transactionType = 'transaction' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { transactionData } = useTransaction();
  const { setModal } = useModal();
  const [currentTab, setCurrentTab] = useState('editForm');
  const [deleted, setDeleted] = useState<boolean>(false);
  const [toolTip, setToolTip] = useState<string>('');

  useMemo(() => {
    if (editType && Object.keys(transactionData).length) {
      setDeleted(!transactionData.deletion.can_be_deleted);
      setToolTip(transactionData.deletion.reason);
    }
  }, [transactionData]);

  const handleTabChange = useCallback(
    (_, newValue) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab],
  );

  const deleteHandler = useCallback(() => {
    setModal({
      show: true,
      title: `Delete transaction ?`,
    });
  }, [setModal]);

  return (
    <>
      {editType ? (
        <DeleteButton deleted={deleted || !transactionData.id} deleteHandler={deleteHandler} toolTip={toolTip} />
      ) : (
        <div className={classes.paddingBlock} data-tip={toolTip}>
          <span />
        </div>
      )}
      <TabContext value={currentTab}>
        <AppBar position="static" color="transparent" style={{ boxShadow: 'none' }}>
          <TabList onChange={handleTabChange} aria-label="simple tabs example" indicatorColor="primary">
            <Tab label={t('forms.transaction.transaction')} value="editForm" style={{ marginLeft: -10 }} />
            <Tab label={t('forms.transaction.entries')} value="entries" />
          </TabList>
        </AppBar>
        <hr className={classes.line} />
        <TabPanel value="editForm" style={{ padding: '24px 0' }}>
          {transactionType === 'conversion' ? (
            <ConversionTransaction editType={editType} />
          ) : (
            <TransactionFormBody editType={editType} transactionType={transactionType} />
          )}
        </TabPanel>
        <TabPanel value="entries" style={{ padding: '24px 0' }}>
          {editType && <EntriesTable />}
        </TabPanel>
      </TabContext>
    </>
  );
};

export default TransactionForm;

const useStyles = makeStyles(() => ({
  line: {
    border: 'none',
    height: '1px',
    margin: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  paddingBlock: {
    paddingTop: '60px',
    paddingBottom: '8px',
  },
}));

import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';

import updateUtils from 'actions/updateUtils';
import { firstDayInPreviousMonth, firstDayOfMonth, lastDayOfMonth } from 'helpers/dt';
import { formatDateIsoShort } from 'helpers/formatters';
import getClientAmountsReport from 'queries/amount/getClientAmountsReport';
import getPrincipalAmountsReport from 'queries/amount/getPrincipalAmountsReport';
import getAccrualReport, { accrualReportType } from 'queries/amount/getAccrualReport';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, utilsSelector } from './selectors';

export const getInitialDate = (type?: string) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const d = new Date(year, month, 1);
  let date;

  if (type === 'for') {
    date = firstDayOfMonth(d);
  } else if (type === 'last') {
    date = firstDayInPreviousMonth(d);
  } else {
    date = lastDayOfMonth(d);
  }

  return formatDateIsoShort(date);
};

type clientAmountsTableParamsType = { date_for?: string; company_id?: string; _sort?: string; _order?: 'asc' | 'desc' };

type principalAmountsTableParamsType = {
  date_from: string;
  date_to: string;
};

const initialClientAmountsTableParams: clientAmountsTableParamsType = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date_for: getInitialDate('last'),
};
const principalAmountsTableParams: principalAmountsTableParamsType = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date_from: getInitialDate('from'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date_to: getInitialDate(),
};

export type clientAmountsReportDataType = {
  accrued_interest_eur: string;
  accrued_interest_usd: string;
  accrued_interest_usd_to_eur: string;
  company_name: string;
  id: string;
  principal_amount_delta_eur: string;
  principal_amount_delta_usd: string;
  principal_amount_delta_usd_to_eur: string;
  principal_amount_paid_eur: string;
  principal_amount_paid_usd: string;
  principal_amount_total_eur: string;
  principal_amount_total_usd: string;
  principal_amount_total_usd_to_eur: string;
  purchase_price_paid_eur: string;
}[];

interface accrualReportDataType {
  bank_fee_eur: string;
  bank_fee_eur_not_round: string;
  bank_fee_usd: string;
  bank_fee_usd_not_round: string;
  commission_accrued_gross_eur: string;
  commission_accrued_gross_eur_not_round: string;
  commission_accrued_gross_usd: string;
  commission_accrued_gross_usd_not_round: string;
  commission_accrued_net_eur: string;
  commission_accrued_net_eur_not_round: string;
  commission_accrued_net_usd: string;
  commission_accrued_net_usd_not_round: string;
  company_id: string;
  company_name: string;
  contract_number: string;
  default_interest: string;
  default_interest_accrued_eur_not_round: string;
  default_interest_accrued_usd_not_round: string;
  file_id_invoice_in_eur: null | string;
  file_id_invoice_in_usd: null | string;
  id: string;
  vat_eur: string;
  vat_eur_not_round: string;
  vat_usd: string;
  vat_usd_not_round: string;
}

export type useAmountType = {
  clientAmountsReportData?: clientAmountsReportDataType;
  getAccrualReport?: (data?: accrualReportType) => void;
  accrualReportData?: accrualReportDataType[];
  clientAmountsTableParams?: clientAmountsTableParamsType;
  setClientAmountsTableParams?: (clientAmountsTableParamsData: clientAmountsTableParamsType) => void;
};

const useAmount = () => {
  const selector = useCallback((state) => {
    let propName = Object.keys(state.queries);

    return {
      accrualReportData: arraySelector(state, 'accrualReportData'),
      isPending: state.queries[propName[0]]?.isPending,
      clientAmountsReportData: arraySelector(state, 'clientAmountsReportData') as clientAmountsReportDataType,
      principalAmountsReportData: arraySelector(state, 'principalAmountsReportData'),
      clientAmountsTableParams: utilsSelector(state, 'clientAmountsTableParams', initialClientAmountsTableParams),
      principalAmountsTableParams: utilsSelector(state, 'principalAmountsTableParams', principalAmountsTableParams),
    };
  }, []);

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getClientAmountsReport,
      getPrincipalAmountsReport,
      getAccrualReport,
    }),
    [],
  );

  const amountTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setClientAmountsTableParams: (clientAmountsTableParamsData: clientAmountsTableParamsType) =>
            updateUtils({
              clientAmountsTableParams: clientAmountsTableParamsData,
            }),
          setPrincipalAmountsTableParams: (principalAmountsTableParamsData: principalAmountsTableParamsType) =>
            updateUtils({
              principalAmountsTableParams: principalAmountsTableParamsData,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearClientAmountsReportData: () =>
            updateEntities({
              clientAmountsReportData: () => [],
            }),
          clearAccrualReportData: () =>
            updateEntities({
              accrualReportData: () => [],
            }),
          clearPrincipalAmountsReportData: () =>
            updateEntities({
              principalAmountsReportData: () => [],
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...amountTableActions,
    ...actionWithoutIsFetching,
  };
};

export default useAmount;

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import updateUtils from 'actions/updateUtils';
import { CompanyType } from 'types/CompanyTypes';
import { formatDateIsoShort } from 'helpers/formatters';
import { firstDayOfMonth, lastDayOfMonth } from 'helpers/dt';
import getMonthlyReports from 'queries/monthlyReport/getMonthlyReports';
import getMonthlyInvoiceReports, { monthlyInvoiceReportsType } from 'queries/monthlyReport/getMonthlyInvoiceReports';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

type monthlyReportTableParamsType = {
  dateFrom: string;
  dateTo: string;
  companyId: string;
  company: CompanyType[];
};

const dtFrom = new Date();
dtFrom.setMonth(dtFrom.getMonth() - 1);

const initialMonthlyReportTableParams: monthlyReportTableParamsType = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dateFrom: formatDateIsoShort(firstDayOfMonth(dtFrom)),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dateTo: formatDateIsoShort(lastDayOfMonth(dtFrom)),
  companyId: '',
  company: [],
};

export enum metaReportsEnum {
  MONTHLY_REPORT = 'monthly_report',
  INVOICE_REPORT = 'invoice_report',
  INVOICE_REPORT_USD = 'invoice_report_usd',
  INVOICE_REPORT_EUR = 'invoice_report_eur',
}
export interface monthlyInvoiceReportsRecordsType {
  currency: string;
  id: string;
  file_id: string;
  name: string;
  invoice_number?: string;
  invoice_alice?: string;
  size: number;
  owner_id: string;
  report_date: Date;
  updated: Date;
  meta: {
    type: metaReportsEnum;
    end_date: Date;
    start_date: Date;
    friendly_name: string;
  };
}

export interface monthlyInvoiceReportsDataType {
  records: monthlyInvoiceReportsRecordsType[];
  total: number;
  page: number;
  limit: null | number;
}

export interface useMonthlyReportType {
  getMonthlyInvoiceReports: (data: monthlyInvoiceReportsType) => void;
  monthlyInvoiceReportsData: monthlyInvoiceReportsDataType;
}

const useMonthlyReport = () => {
  const selector = useCallback(
    (state) => ({
      monthlyInvoiceReportsData: objectSelector(state, 'monthlyInvoiceReportsData') as monthlyInvoiceReportsDataType,
      monthlyReportsData: arraySelector(state, 'monthlyReportsData'),
      monthlyReportsTableParams: utilsSelector(state, 'monthlyReportsTableParams', initialMonthlyReportTableParams),
    }),
    [],
  );

  const dispatch = useDispatch();

  const data = useSelector(selector);
  const actionCreators = useMemo(
    () => ({
      getMonthlyReports,
      getMonthlyInvoiceReports,
    }),
    [],
  );

  const monthlyReportTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setMonthlyReportTableParams: (monthlyReportsTableParams: monthlyReportTableParamsType) =>
            updateUtils({
              monthlyReportsTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...monthlyReportTableActions,
  };
};

export default useMonthlyReport;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const topSvg = require('assets/img/topSort.svg').default;
const bottomSvg = require('assets/img/bottomSort.svg').default;
const sortOnlyDownSvg = require('assets/img/sortOnlyDown.svg').default;
const sortOnlyTopSvg = require('assets/img/sortOnlyTop.svg').default;
const sortOnlyRightSvg = require('assets/img/sortOnlyRight.svg').default;

export enum typeSortEnum {
  ALPHABET = 'alphabet',
  STANDARD = 'standard',
}

interface SortTableType {
  onClickHandler?: (sort: SortType) => void;
  isActive?: boolean;
  order?: SortType;
  type?: typeSortEnum;
  isHiddenSort?: boolean;
}

export type SortType = 'asc' | 'desc';

const SortTable: FC<SortTableType> = ({
  children,
  onClickHandler = () => {},
  isActive,
  order,
  type = typeSortEnum.STANDARD,
  isHiddenSort,
}) => {
  const classes = useStyles();
  const [sort, setSort] = useState<boolean | null>(null);
  const [isHover, setIsHover] = useState(false);

  const callback = useCallback((isSort: boolean) => {
    setSort(isSort);
    setIsHover(false);
    onClickHandler(isSort ? 'asc' : 'desc');
  }, []);

  useEffect(() => {
    if (isActive && order) {
      setSort(order === 'asc' || order === 'desc');

      return;
    }
    setSort(null);
  }, [isActive, order]);

  const styles = type === typeSortEnum.ALPHABET && !isHover ? { right: -35, bottom: 0 } : {};

  if (isHiddenSort) {
    return <>{children}</>;
  }

  return (
    <div
      className={classes.wrap}
      onMouseEnter={() => {
        if (type === typeSortEnum.ALPHABET && sort !== null) return;
        setIsHover(true);
      }}
      onMouseLeave={() => setIsHover(false)}
    >
      <div style={styles} className={classes.arrow} onClick={() => callback(!sort)}>
        {isHover && <img src={topSvg} alt="top" />}
        {isHover && <img style={{ marginTop: 4 }} src={bottomSvg} alt="bottom" />}
        {!isHover && sort !== null && type === typeSortEnum.STANDARD && (
          <img style={{ marginTop: 4 }} src={sort ? sortOnlyTopSvg : sortOnlyDownSvg} alt="arrow" />
        )}
        {sort !== null && type === typeSortEnum.ALPHABET && (
          <span className={classes.alphabet}>
            {sort ? 'A' : 'W'}
            <img style={{ padding: '0px 2px' }} src={sortOnlyRightSvg} alt="right" />
            {sort ? 'W' : 'A'}
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'inline-block',
    position: 'relative',
  },
  arrow: {
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: -12,
    bottom: 6,
    cursor: 'pointer',
  },
  alphabet: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default SortTable;

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, Children, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import TemplateGrid from 'components/forms/ActivationOrderForm/CardsAo/TemplateGrid';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { formatAmount, formatDate, getCodeCurrencyOfName, getNameCurrencyOfCode } from 'helpers/formatters';
import CheckboxKit from 'components/uiKit/CheckboxKit';
import { InputKit } from 'components/uiKit/InputKit';
import Textarea from 'components/uiKit/TextareaKit';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import useActivationOrder, { useActivationOrderType } from 'hooks/api/useActivationOrder';
import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import { statusParamsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import CompanySelect from 'components/CompanySelect';

import {
  getCurrencyTransactions,
  getListTransactionCategory,
  getListTransactionType,
  getTitleCheckbox,
  getTitleSender,
  getValueCurrencyTransactions,
} from './data';
import useRequiredKeyOfCardsTransactions from './useRequiredKeyOfCardsTransactions';
import SellBuy, { GET_CONVERSION_RATE, getConvertFcOfCodeCurrencySell } from './SellBuy';
import AvailableBalance from './AvailableBalance';
import FactorAccountsSelect from 'components/FactorAccountsSelect';

export type setInitialFilterType = (
  key: KeyTransactions,
  value: string | object | boolean,
  error?: { keyError?: KeyTransactions; valueError?: string; params?: { date?: boolean; required?: boolean } },
  addObjParams?: object,
) => void;
interface TransactionsFormPropsType {
  types: KeyTransactions[];
}

const ConstructorTransForm: FC<TransactionsFormPropsType> = ({ types }) => {
  const { t } = useTranslation();
  const {
    transactionsFormData,
    setTransactionFormData,
    factorDetails: { bank_accounts = [] },
  }: useTransactionType = useTransaction();
  const {
    getActivationOrderV2,
    activationOrdersV2Data: { records = [] },
  }: useActivationOrderType = useActivationOrder();
  const { getPurchaseRequestList, purchaseRequestList } = usePurchaseRequest();
  const {
    companiesData: { names = [] },
    getPurchaseClaims,
    purchaseClaims,
  }: useCompanyType = useCompany();
  const valueSenderCompany = useMemo(
    () => names.find((item) => item.id === transactionsFormData[KeyTransactions.SENDER][KeyTransactions.COMPANY_ID]),
    [transactionsFormData, names],
  );
  const valueAo = useMemo(
    () => records.find((item) => item.id === transactionsFormData[KeyTransactions.DOCUMENT_AO]),
    [transactionsFormData, records],
  );
  const valueRequest = useMemo(
    () =>
      (purchaseRequestList?.records || []).find(
        (item) => item.id === transactionsFormData[KeyTransactions.DOCUMENT_REQUEST],
      ),
    [transactionsFormData, purchaseRequestList],
  );
  const valueClaims = useMemo(
    () => (purchaseClaims || []).find((item) => item.id === transactionsFormData[KeyTransactions.CLAIMS]),
    [transactionsFormData, purchaseRequestList],
  );

  const { getParamsItem } = useRequiredKeyOfCardsTransactions();
  const { errors, setErrors } = useErrors();
  const { type, status } = useParams();
  const setInitialFilter = useCallback(
    (key, value, error?, addObjParams?) => {
      const objKey = { [key]: value, ...addObjParams };
      setTransactionFormData(objKey);

      if (error && error?.keyError && error?.params) {
        setErrors(error?.valueError, error?.keyError, error.params);
      }
    },
    [transactionsFormData],
  ) as setInitialFilterType;

  const disabledAoDocuments =
    (transactionsFormData[KeyTransactions.TYPE] === KeyTransactions.OUTGOING
      ? !transactionsFormData[KeyTransactions.FACTORING_COMPANY_ID]
      : !transactionsFormData[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID]) ||
    !!transactionsFormData[KeyTransactions.DOCUMENT_REQUEST] ||
    !!transactionsFormData[KeyTransactions.CLAIMS] ||
    (transactionsFormData[KeyTransactions.TYPE] === KeyTransactions.OUTGOING &&
      [KeyTransactions.OUTGOING_TRANSIT, KeyTransactions.OUTGOING_BALANCE_PAYABLE].includes(
        transactionsFormData[KeyTransactions.CATEGORY] as KeyTransactions,
      ));
  const disabledDocumentRequest =
    (transactionsFormData[KeyTransactions.TYPE] === KeyTransactions.OUTGOING
      ? !transactionsFormData[KeyTransactions.FACTORING_COMPANY_ID]
      : !transactionsFormData[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID]) ||
    !!transactionsFormData[KeyTransactions.DOCUMENT_AO] ||
    (transactionsFormData[KeyTransactions.TYPE] === KeyTransactions.OUTGOING &&
      [KeyTransactions.OUTGOING_TRANSIT, KeyTransactions.OUTGOING_BALANCE_PAYABLE].includes(
        transactionsFormData[KeyTransactions.CATEGORY] as KeyTransactions,
      ));

  const getValueForSender = () => {
    if ('outgoing' === transactionsFormData[KeyTransactions.TYPE])
      return transactionsFormData[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID];

    return transactionsFormData[KeyTransactions.SENDER][KeyTransactions.COMPANY_ID];
  };

  const getValueForRecipient = () => {
    if (['incoming', 'outgoing'].includes(transactionsFormData[KeyTransactions.TYPE]))
      return transactionsFormData[KeyTransactions.FACTORING_COMPANY_ID];

    return transactionsFormData[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID];
  };

  return (
    <TemplateGrid>
      {Children.toArray(
        types.map((item) => {
          if (item === KeyTransactions.TYPE) {
            return (
              <DropdownKit
                isHiddenMenuAll
                label={t('forms.transaction.transactionType')}
                options={getListTransactionType()}
                keyObj="value"
                value={getListTransactionType().find((val) => val.key === transactionsFormData[item])}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                disabled={
                  status === statusParamsEnum.EDIT &&
                  (type === KeyTransactions.CONVERSION || type === KeyTransactions.INCOMING)
                }
              />
            );
          }

          if (item === KeyTransactions.BANK_NAME) {
            return (
              <DropdownKit
                label={t('forms.transaction.bank')}
                options={bank_accounts}
                keyObj="bank_name"
                value={bank_accounts?.find((val) => val.bank_name === transactionsFormData.bank_name)}
                changeSelectItem={(_, data) => {
                  setInitialFilter(
                    item,
                    data.bank_name,
                    {
                      keyError: item,
                      valueError: data.bank_name,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    { [KeyTransactions.SWIFT]: data[KeyTransactions.SWIFT] },
                  );
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyTransactions.CATEGORY) {
            return (
              <DropdownKit
                label={t('forms.transaction.category')}
                options={getListTransactionCategory[transactionsFormData[KeyTransactions.TYPE]]()}
                keyObj="value"
                value={getListTransactionCategory[transactionsFormData[KeyTransactions.TYPE]]().find(
                  (val) => val.key === transactionsFormData[item],
                )}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyTransactions.AVAILABLE_BALANCE) {
            return <AvailableBalance setInitialFilter={setInitialFilter} />;
          }

          if (item === KeyTransactions.DOCUMENT_AO) {
            return (
              <DropdownKit
                label={t('forms.transaction.ao')}
                options={records.filter(el => ['ACTIVE', 'FUNDED'].includes(el.status))}
                formattingFc={({ contract_number, name, purchase_date, amount, currency }) =>
                  `${contract_number}-${name}[${formatDate(purchase_date)}] ${formatAmount(amount, currency)}`
                }
                value={valueAo}
                changeSelectItem={(_, data) => {
                  setInitialFilter(
                    item,
                    data.id || '',
                    {
                      keyError: item,
                      valueError: data.id,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    {
                      [KeyTransactions.DOCUMENT_REQUEST]: '',
                      [KeyTransactions.CLAIMS]: '',
                      [KeyTransactions.DOCUMENT_TYPE]: 'order',
                      [KeyTransactions.DOCUMENT_ID]: data.id,
                      [KeyTransactions.DOCUMENT_NUMBER]: data.name,
                      [KeyTransactions.CURRENCY]: getCodeCurrencyOfName(data.currency),
                    },
                  );
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                disabled={disabledAoDocuments}
              />
            );
          }

          if (item === KeyTransactions.DOCUMENT_REQUEST) {
            return (
              <DropdownKit
                label={t('forms.transaction.request')}
                options={purchaseRequestList?.records || []}
                formattingFc={({ request, purchase_date, purchase_price, currency }) =>
                  `${request} [${formatDate(purchase_date)}] ${formatAmount(purchase_price, currency)}`
                }
                value={valueRequest}
                changeSelectItem={(_, data) => {
                  setInitialFilter(
                    item,
                    data.id || '',
                    {
                      keyError: item,
                      valueError: data.id,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    {
                      [KeyTransactions.DOCUMENT_AO]: '',
                      [KeyTransactions.DOCUMENT_TYPE]: 'request',
                      [KeyTransactions.DOCUMENT_ID]: data.id,
                      [KeyTransactions.DOCUMENT_NUMBER]: data.request,
                      [KeyTransactions.CURRENCY]: getCodeCurrencyOfName(data.currency),
                    },
                  );
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                disabled={disabledDocumentRequest}
              />
            );
          }

          if (item === KeyTransactions.FACTOR_BANK_ACCOUNT) {
            return (
              <FactorAccountsSelect
                needSetDefaultValue
                currency={getNameCurrencyOfCode(transactionsFormData[KeyTransactions.CURRENCY])}
                value={
                  (transactionsFormData[KeyTransactions.TYPE] === 'outgoing'
                    ? transactionsFormData[KeyTransactions.SENDER]?.account
                    : transactionsFormData[KeyTransactions.RECIPIENT]?.account) || undefined
                }
                onChange={(account, { source: { bank_name, swift, id, name } }) =>
                  setInitialFilter(
                    transactionsFormData[KeyTransactions.TYPE] === 'outgoing'
                      ? [KeyTransactions.SENDER]
                      : [KeyTransactions.RECIPIENT],
                    {
                      currency: transactionsFormData[KeyTransactions.CURRENCY],
                      company_id: id,
                      company_name: name,
                      account,
                      bank_name,
                      swift,
                    },
                  )
                }
              />
            );
          }

          if (item === KeyTransactions.CLAIMS) {
            return (
              <DropdownKit
                label={t('forms.transaction.claim')}
                options={purchaseClaims || []}
                keyObj="name"
                keyImg="logo"
                value={valueClaims}
                changeSelectItem={(_, data) => {
                  setInitialFilter(
                    item,
                    data.id || '',
                    {
                      keyError: item,
                      valueError: data.id,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    {
                      [KeyTransactions.DOCUMENT_AO]: '',
                      [KeyTransactions.DOCUMENT_TYPE]: 'claim',
                      [KeyTransactions.DOCUMENT_ID]: data.id,
                      [KeyTransactions.DOCUMENT_NUMBER]: data.name,
                      [KeyTransactions.CURRENCY]: getCodeCurrencyOfName(data.currency),
                    },
                  );
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                disabled={
                  !transactionsFormData[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID] ||
                  !!transactionsFormData[KeyTransactions.DOCUMENT_AO]
                }
              />
            );
          }

          if (item === KeyTransactions.CURRENCY) {
            return (
              <DropdownKit
                label={t('forms.transaction.currency')}
                options={getCurrencyTransactions()}
                keyObj="value"
                value={getValueCurrencyTransactions()[transactionsFormData[item]]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(
                    item,
                    data.key || '',
                    {
                      keyError: item,
                      valueError: data.key,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    {
                      [KeyTransactions.AMOUNT_SELL]: '',
                      [KeyTransactions.AMOUNT_BUY]: '',
                    },
                  );
                  if (['incoming', 'outgoing'].includes(transactionsFormData[KeyTransactions.TYPE])) {
                    setInitialFilter(
                      transactionsFormData[KeyTransactions.TYPE] === 'outgoing'
                        ? [KeyTransactions.SENDER]
                        : [KeyTransactions.RECIPIENT],
                      {
                        ...(transactionsFormData[KeyTransactions.TYPE] === 'outgoing' &&
                          transactionsFormData[KeyTransactions.SENDER]),
                        ...(transactionsFormData[KeyTransactions.TYPE] === 'incoming' &&
                          transactionsFormData[KeyTransactions.RECIPIENT]),
                        account: '',
                        bank_name: '',
                        swift: '',
                        currency: '',
                      },
                    );
                  }
                }}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                disabled={
                  (!!transactionsFormData[KeyTransactions.DOCUMENT_REQUEST] ||
                    !!transactionsFormData[KeyTransactions.CLAIMS] ||
                    !!transactionsFormData[KeyTransactions.DOCUMENT_AO]) &&
                  !!transactionsFormData[KeyTransactions.CURRENCY]
                }
              />
            );
          }

          if (item === KeyTransactions.RECIPIENT) {
            return (
              <CompanySelect
                exceptFactor
                size="large"
                label={t('forms.transaction.client')}
                value={getValueForRecipient()}
                onChange={(value, { label }) => {
                  let addObjParams = {};

                  if (
                    transactionsFormData.type === KeyTransactions.OUTGOING ||
                    transactionsFormData.type === KeyTransactions.INTERNAL ||
                    transactionsFormData.type === KeyTransactions.ADJUSTMENT
                  ) {
                    addObjParams = {
                      [KeyTransactions.DOCUMENT_AO]: '',
                      [KeyTransactions.DOCUMENT_REQUEST]: '',
                      [KeyTransactions.CLAIMS]: '',
                      [KeyTransactions.DOCUMENT_ID]: '',
                    };

                    if (value) {
                      transactionsFormData.type === KeyTransactions.OUTGOING &&
                        getPurchaseRequestList({ params: { company_id: value } });
                      getActivationOrderV2({
                        params: {
                          company_id: value,
                          _sort: 'name',
                          limit: 10000,
                        },
                      });
                      (transactionsFormData.type === KeyTransactions.INTERNAL ||
                        transactionsFormData.type === KeyTransactions.ADJUSTMENT) &&
                        getPurchaseClaims({ params: { company_id: value } });
                    }
                  }

                  if (['incoming', 'outgoing'].includes(transactionsFormData[KeyTransactions.TYPE])) {
                    setInitialFilter(
                      KeyTransactions.FACTORING_COMPANY_ID,
                      value,
                      {
                        keyError: item,
                        valueError: value,
                        params: getParamsItem(KeyTransactions.ROOT, item),
                      },
                      {
                        [KeyTransactions.FACTORING_COMPANY_NAME]: label,
                      },
                    );
                  } else {
                    setInitialFilter(
                      item,
                      {
                        [KeyTransactions.COMPANY_ID]: value,
                        [KeyTransactions.COMPANY_NAME]: label,
                      },
                      {
                        keyError: item,
                        valueError: value,
                        params: getParamsItem(KeyTransactions.ROOT, item),
                      },
                      addObjParams,
                    );
                  }
                }}
              />
            );
          }

          if (item === KeyTransactions.SENDER) {
            return (
              <CompanySelect
                size="large"
                exceptFactor
                label={t(getTitleSender[transactionsFormData.type] || 'forms.transaction.sender')}
                value={getValueForSender()}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                status={!!errors[item] ? 'error' : undefined}
                onChange={(companyId, { label }) => {
                  if ('outgoing' === transactionsFormData[KeyTransactions.TYPE]) {
                    setInitialFilter(
                      KeyTransactions.RECIPIENT,
                      {
                        [KeyTransactions.COMPANY_ID]: companyId,
                        [KeyTransactions.COMPANY_NAME]: label,
                      },
                      {
                        keyError: item,
                        valueError: companyId,
                        params: getParamsItem(KeyTransactions.ROOT, item),
                      },
                    );
                  } else {
                    setInitialFilter(
                      item,
                      {
                        [KeyTransactions.COMPANY_ID]: companyId,
                        [KeyTransactions.COMPANY_NAME]: label,
                      },
                      {
                        keyError: item,
                        valueError: companyId,
                        params: getParamsItem(KeyTransactions.ROOT, item),
                      },
                    );
                  }
                }}
              />
            );
          }

          if (item === KeyTransactions.COMMISSION) {
            return (
              <AmountKit
                label={t('forms.transaction.commission')}
                value={transactionsFormData.commission}
                onChange={(e) => {
                  setInitialFilter(
                    item,
                    e.target.value,
                    {
                      keyError: item,
                      valueError: e.target.value,
                      params: getParamsItem(KeyTransactions.ROOT, item),
                    },
                    {
                      [KeyTransactions.AMOUNT_BUY]: getConvertFcOfCodeCurrencySell[transactionsFormData.currency](
                        Number(
                          GET_CONVERSION_RATE(
                            transactionsFormData.rate_ecb,
                            e.target.value,
                            transactionsFormData.currency,
                          ),
                        ),
                        Number(transactionsFormData.amount_sell),
                      ),
                    },
                  );
                }}
                amount={transactionsFormData.commission}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                decimalScale={2}
              />
            );
          }

          if (item === KeyTransactions.AMOUNT) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('forms.transaction.amount')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  })
                }
                amount={String(transactionsFormData[item])}
                required={getParamsItem(KeyTransactions.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                decimalScale={2}
                currency={getNameCurrencyOfCode(Number(transactionsFormData[KeyTransactions.CURRENCY]))}
              />
            );
          }

          if (item === KeyTransactions.VALUE_DATE_TIME) {
            return (
              <DateInput
                labelKey={t('forms.transaction.date')}
                date={transactionsFormData[item]}
                isNotErrorLocal
                isError={!!errors[item]}
                errorText={errors[item]}
                required={getParamsItem(KeyTransactions.ROOT, item).required}
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
              />
            );
          }

          if (item === KeyTransactions.TRANSACTION_DATE) {
            return (
              <DateInput
                labelKey={t('forms.transaction.receiptDate')}
                date={transactionsFormData[item]}
                isNotErrorLocal
                isError={!!errors[item]}
                errorText={errors[item]}
                required={getParamsItem(KeyTransactions.ROOT, item).required}
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
              />
            );
          }

          if (item === KeyTransactions.CHECKBOX) {
            return (
              <CheckboxKit
                label={t(getTitleCheckbox[transactionsFormData.type] || 'forms.transaction.senderIsTheSameAsClient')}
                checked={transactionsFormData[item]}
                onClick={() => {
                  setInitialFilter(item, !transactionsFormData[item]);
                }}
              />
            );
          }

          if (item === KeyTransactions.REFERENCE) {
            return (
              <InputKit
                wrapProps={{ height: 60 }}
                label={t('forms.transaction.referenceNumber')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  })
                }
                value={transactionsFormData[item]}
                required={getParamsItem(KeyTransactions.ROOT, item).required}
                isError={!!errors[item]}
                helperText={errors[item]}
              />
            );
          }

          if (item === KeyTransactions.DETAILS) {
            return (
              <Textarea
                value={transactionsFormData[item]}
                onChange={(e) => {
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
                wrapProps={{ height: 140 }}
                maxLength={4000}
                placeholder={t('forms.transaction.paymentDetails')}
                isError={!!errors[item]}
                helperText={errors[item]}
              />
            );
          }

          if (item === KeyTransactions.COMMENT) {
            return (
              <Textarea
                value={transactionsFormData[item]}
                onChange={(e) => {
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyTransactions.ROOT, item),
                  });
                }}
                wrapProps={{ height: 140 }}
                maxLength={4000}
                placeholder={t('forms.transaction.comment')}
                isError={!!errors[item]}
                helperText={errors[item]}
              />
            );
          }

          if (item === KeyTransactions.RATE) {
            return <SellBuy setInitialFilter={setInitialFilter} />;
          }

          return <div />;
        }),
      )}
    </TemplateGrid>
  );
};

export default ConstructorTransForm;

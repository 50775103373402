import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type adminsDataType = {
  show_deleted?: boolean;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ show_deleted = false, errorCallback, resultKey = 'adminsData' }: adminsDataType) =>
  requestAsync({
    url: endpoints.getAdminsUrl(show_deleted),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAdminsUrl(show_deleted),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

/* eslint-disable no-shadow */
import { PropTypes } from '@material-ui/core';

import { formatDateIsoShort } from 'helpers/formatters';
import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { contractDataBaseType } from 'hooks/api/useContracts';

import { getFileStorage } from './reportsData';

export enum CompanyKeyEnum {
  ACCOUNT = 'account',
  SWIFT = 'swift',
  BANK_NAME = 'bank_name',
  BANK_ADDRESS = 'bank_address',
  MASTER = 'master',
  CURRENCY = 'currency',
  BANK_DETAILS = 'bank_details',
  CONTACTS = 'contacts',
  FULL_NAME = 'full_name',
  POSITION = 'position',
  COMPANY_NAME = 'company_name',
  LEGAL_NAME = 'legal_name',
  COMPANY_REGISTRATION = 'company_registration',
  CONTACT_PHONE = 'contact_phone',
  COMPANY_PHONE = 'company_phone_number',
  TAX_NUMBER = 'tax_number',
  WEBSITE = 'website',
  CITY = 'city',
  COUNTRY = 'country',
  ZIP = 'zip',
  ADDRESS = 'address',
  ADDRESS2 = 'address2',
  SIGNATURE_POSITION = 'signature_position',
  SIGNATURE_EMAIL = 'signature_email',
  SIGNER_EMAIL = 'signer_email',
  SIGNATURE_FULL_NAME = 'signature_full_name',
  CONTACT_PERSONS = 'contact_persons',
  BANK_USD = 'bank_usd',
  BANK_EUR = 'bank_eur',
  SWIFT_USD = 'swift_usd',
  SWIFT_EUR = 'swift_eur',
  IBAN_USD = 'iban_usd',
  IBAN_EUR = 'iban_eur',
  SPECIAL_INSTRUCTIONS = 'special_instructions',
  CORRESPONDING_ACCOUNT_USD = 'correspondent_account_usd',
  CORRESPONDING_BANK_USD = 'correspondent_bank_usd',
  CORRESPONDING_SWIFT_USD = 'correspondent_swift_usd',
  FACTOR_ACCOUNT_EUR = 'factor_account_eur',
  FACTOR_ACCOUNT_USD = 'factor_account_usd',
  PHONE = 'contact_phone',
  EMAIL = 'email',
  COMPANY_EMAIL = 'company_email',
  ROOT = 'root',
  STATE = 'state',
  TYPE = 'type',
}

export enum CompanyType {
  client = 'client',
  thirdParty = 'third-party',
  revenueSource = 'revenue-source',
  // NOTE: пока не используется
  // factor = 'Factor',
}

export type GeneralTabPropsType = {
  tabValue: string;
  company_id: string;
  disabled: boolean;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getInitialErrors = (text): ErrorType => ({
  name: { status: false, text },
  number: { status: false, text },
  date: { status: false, text: '' },
  file_data: { status: false, text: '' },
});

export type contractDataType = {
  name: string;
  type: string;
  number: string;
  date: string;
  link: string;
  file_data: string;
};

export const initialContractFormData: contractDataType = {
  number: '',
  name: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date: formatDateIsoShort(new Date()),
  type: '',
  link: '',
  file_data: '',
};

export type ErrorType = {
  name: { status: boolean; text: string };
  number: { status: boolean; text: string };
  date: { status: boolean; text: string };
  file_data: { status: boolean; text: string };
};

type VariantType = 'text' | 'outlined' | 'contained';
const alignCommon = 'left' as PropTypes.Alignment;
const center = 'center' as PropTypes.Alignment;
const variant = 'contained' as VariantType;
const color = 'primary' as PropTypes.Color;
const style = { padding: '0px 24px' };
export enum DocumentTabKeys {
  NAME = 'name',
  NUMBER = 'number',
  DATE = 'date',
  LINK = 'link',
  DELETE = 'delete',
  INVOICE_NUMBER = 'invoice_num',
}

interface documentTabFunc {
  date?: string;
  link?: string;
  number?: string;
  id?: string;
  company_id?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getDocumentsByIdV2?: (data: Object) => void;
  setIsLoading: (data: boolean) => void;
}

export const documentTabData = [
  {
    props: { align: alignCommon, style: { padding: '0px 32px' } },
    key: DocumentTabKeys.NAME,
  },
  { props: { align: alignCommon, style: { ...style } }, key: DocumentTabKeys.NUMBER },
  { props: { align: alignCommon, style: { ...style } }, key: DocumentTabKeys.INVOICE_NUMBER },
  {
    props: { align: alignCommon, style: { ...style } },
    key: DocumentTabKeys.DATE,
  },
  {
    props: { align: center, style: { ...style, width: 64, border: 'none' } },
    propsButton: { variant, color },
    key: DocumentTabKeys.LINK,
    func: async ({ link, setIsLoading }: documentTabFunc) => {
      setIsLoading(true);
      await getFileStorage(endpoints.getStorageFileUrl(link));
      setIsLoading(false);
    },
  },
];

export const delCustomerContract = async (id: string) => {
  if (!id) return false;
  try {
    const res = await fetch(endpoints.delCustomerContractUrl({ id }), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (await res.json()) as Promise<any>;
  } catch (error) {
    return false;
  }
};

export interface setCustomerContractBodyType {
  name: string;
  type: string;
  number: string;
  date: string;
  company_id: string;
  file_data: string;
}

interface ContractType {
  description?: { invoice_num: string };
  title?: string;
  customer_contract?: contractDataBaseType;
}

export const setCustomerContract = async (id: string, params: setCustomerContractBodyType) => {
  try {
    const res = await fetch(endpoints.setCustomerContractUrl({ id }), {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(params),
    });

    return (await res.json()) as Promise<ContractType>;
  } catch (error) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react';

import useActivationOrder, { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import GeneralInformation from 'components/forms/ActivationOrderForm/General/common/GeneralInformation';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import Card from 'components/common/GeneralView/Card';
import DistributionPlatformList from 'components/forms/ActivationOrderForm/General/common/DistributionPlatformList';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';
import { AOKeyEnum, Account } from 'types/ActivationOrderTypes';

import styles from '../common/GeneralInformation/styles.module.scss';

import Schedule from './Schedule';

type PropsT = {
  activationOrderData: activationOrderDataType;
  accounts: Account[];
  updateAccounts: (id: string) => void;
};

const FutureReceivablesAO: FC<PropsT> = ({ activationOrderData, accounts, updateAccounts }) => {
  const { schedulesFutureReceivablesData } = useActivationOrder();

  return (
    <>
      <GeneralInformation
        type="future_receivables"
        activationOrderData={activationOrderData}
        accounts={accounts}
        updateAccounts={updateAccounts}
      />
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.comment"
          value={activationOrderData[GeneralEnum.COMMENT]}
        />
      </Card>
      <div
        className={activationOrderData[GeneralEnum.TYPE] === AoTypeEnum.FUTURE_RECEIVABLES && styles.blockWithTwoCard}
      >
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title="forms.activationOrder.purchasePrice"
            value={activationOrderData[GeneralEnum.AMOUNT]}
            valueFormatType="money"
            currency={activationOrderData.currency}
          />
          <Item
            type={TypeEnum.TEXT}
            title="forms.activationOrder.minimal_principal_repayment"
            value={activationOrderData[GeneralEnum.WITHHOLD_PERCENTAGE]}
            valueFormatType="percent"
          />
        </Card>
        {activationOrderData[GeneralEnum.TYPE] === AoTypeEnum.FUTURE_RECEIVABLES && (
          <Card>
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.purchaseDate"
              value={activationOrderData[GeneralEnum.PURCHASE_DATE]}
              valueFormatType="date"
            />
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.purchasedReceivables"
              value={activationOrderData[GeneralEnum.PURCHASED_RECEIVABLES]}
              valueFormatType="money"
              currency={activationOrderData.currency}
            />
          </Card>
        )}
      </div>
      {schedulesFutureReceivablesData.records?.length > 0 && (
        <Schedule
          effectiveDate={activationOrderData[AOKeyEnum.EFFECTIVE_DATE]}
          aoId={activationOrderData[AOKeyEnum.ID]}
          schedule={schedulesFutureReceivablesData}
          currency={activationOrderData.currency}
          purchasedReceivables={activationOrderData[AOKeyEnum.PURCHASED_RECEIVABLES]}
        />
      )}
      {activationOrderData.distribution_platforms?.length > 0 && (
        <DistributionPlatformList activationOrderData={activationOrderData} />
      )}
    </>
  );
};

export default FutureReceivablesAO;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Switcher from 'components/uiKit/Switcher';
import ViewGeneralActions from 'components/common/ViewGeneralActions';

interface Props {
  isChecked: boolean;
  setIsChecked: (data: boolean) => void;
}
const TransactionsMenu: FC<Props> = ({ isChecked, setIsChecked }) => {
  const { t } = useTranslation();

  return (
    <ViewGeneralActions
      customContents={[
        () => (
          <Switcher
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            label={t('forms.transaction.deletedOnly')}
            checked={isChecked}
          />
        ),
      ]}
    />
  );
};
export default TransactionsMenu;

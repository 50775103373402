import React from 'react';

export type CompanyType = {
  name: string;
  id: string;
  active?: boolean;
};

export type TransactionTableHeaderPropsType = {
  wrapperStyle?: React.CSSProperties;
  requestParams: Object;
};

export type TransactionTablePropsType = {
  wrapperStyle?: React.CSSProperties;
};

export const statusChoicesAutocomplete = [
  { id: 'completed', name: 'completed', active: true },
  { id: 'failed', name: 'failed', active: true },
];

export type ErrorType = {
  fullName: { status: boolean; text: string };
  username: { status: boolean; text: string };
  password?: { status: boolean; text: string };
  email?: { status: boolean; text: string };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getInitialError = (text): ErrorType => ({
  fullName: { status: false, text },
  username: { status: false, text },
  password: { status: false, text },
  email: { status: false, text },
});

export type AdminFormPropsType = {
  editType?: boolean;
};

export type AdminFormFetchBodyType = {
  id?: string;
  fullName: string;
  username: string;
  email: string;
  authProvider: string;
  password?: string;
  successCallback: Function;
  errorCallback: Function;
};

export type AdminStateType = {
  fullName: string;
  username: string;
  email: string;
  authProvider: string;
  password?: string;
  active: boolean;
};

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import setRouteAction from 'actions/setRoute';

import { routeSelector } from './selectors';

const useRoute = () => {
  const selector = useCallback(
    (state) => ({
      routeData: routeSelector(state),
    }),
    [],
  );

  const dispatch = useDispatch();

  const data = useSelector(selector);

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          setRoute: (payload) => setRouteAction(payload),
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    ...data,
    ...actions,
  };
};

export default useRoute;

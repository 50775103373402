import React, { FC, HTMLAttributes } from 'react';

import styles from './styles.module.scss';

const Card: FC<HTMLAttributes<HTMLElement>> = ({ children, ...restProps }) => (
  <div className={styles.wrapper} {...restProps}>
    {children}
  </div>
);

export default Card;

import type { Dictionary } from 'lodash';
import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { expectedPaymentsAllDataType } from 'hooks/api/useExpectedPayments';

export interface expectedPaymentsParamsType {
  company_id?: string;
  page?: string;
  ao_ids?: string;
  payment_type?: string;
  dont_show_late_payments?: string;
}

export type expectedPaymentsType = {
  params: expectedPaymentsParamsType | Dictionary<expectedPaymentsParamsType>;
  errorCallback?: () => void;
  successCallback?: (data: expectedPaymentsAllDataType) => void;
  resultKey?: string;
};

export default ({ params, successCallback, errorCallback, resultKey = 'expectedPaymentsData' }: expectedPaymentsType) =>
  requestAsync({
    url: endpoints.getExpectedPaymentsUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: 'expectedPaymentsData',
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

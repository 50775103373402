import React, { FC, FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, FilledInputProps, FormHelperText, MenuItem, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type TextInputPropsType = {
  labelKey?: string;
  onChange: any;
  disabled?: boolean;
  defaultValue?: string;
  isError?: boolean;
  required?: boolean;
  onBlurHandler?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  name?: string;
  errorText?: string;
  stylesProp?: React.CSSProperties;
  type?: string;
  rows?: number;
  multiline?: boolean;
  select?: true;
  selectItems?: any;
  inputWidth?: number | string;
  toolTip?: string;
  shrink?: boolean;
  helperText?: string;
  helperTextStyle?: React.CSSProperties;
  isFieldRequired?: boolean;
  trim?: boolean;
  inputParams?: Partial<FilledInputProps>;
  inputVariant?: 'standard' | 'filled' | 'outlined';
  textFieldProps?: TextFieldProps;
};

const CustomInput: FC<TextInputPropsType> = ({
  labelKey,
  onChange,
  disabled,
  defaultValue,
  isError,
  required,
  onBlurHandler,
  name,
  errorText = 'Required',
  stylesProp,
  type,
  rows,
  multiline,
  select,
  selectItems,
  inputWidth,
  toolTip,
  shrink = false,
  helperText,
  helperTextStyle,
  isFieldRequired,
  trim = false,
  inputParams,
  inputVariant = 'filled',
  textFieldProps,
}: TextInputPropsType) => {
  const getHelperTextStyle = () => {
    const basicStyle = {
      color: 'red',
      maxWidth: inputWidth || 'auto',
      ...helperTextStyle,
    };

    return isFieldRequired ? { ...basicStyle, marginTop: -15, marginBottom: 5 } : basicStyle;
  };

  const useStyles = makeStyles(() => ({
    input: {
      width: inputWidth || '500px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      backgroundColor: '#eef2f7',
      'MuiFilledInput-root': {
        backgroundColor: '#eef2f7 !important',
      },
      '& *': {
        borderRadius: '6px !important',
        backgroundColor: '#eef2f7',
      },
      '&:before': {
        borderColor: 'white !important',
      },
      '& label': {
        '&:focus': {
          color: 'black !important',
        },
        color: 'black !important',
      },
      '&:hover': {
        backgroundColor: '#eef2f7 !important',
      },
      selectItem: {
        height: 50,
      },
    },
  }));

  const styles = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onFieldChange = (fieldData) => {
    const newFieldData = { ...fieldData };

    if (trim && !newFieldData.target.value.trim()) {
      newFieldData.target.value = newFieldData.target.value.trim();
    }

    onChange(newFieldData);
  };

  return (
    <>
      <TextField
        type={type}
        autoComplete="off"
        id="filled-basic"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label={isFieldRequired ? `${t(labelKey)}*` : t(labelKey)}
        variant={inputVariant}
        onChange={(e) => {
          onFieldChange(e);
        }}
        disabled={disabled}
        required={required}
        name={name}
        onBlur={onBlurHandler}
        defaultValue={defaultValue}
        value={defaultValue}
        error={isError}
        style={stylesProp}
        rows={rows}
        multiline={multiline}
        select={select}
        helperText={isError ? helperText : ''}
        InputProps={{
          className: styles.input,
          autoComplete: 'off',
          inputProps: type === 'date' ? { min: '1900-01-01', max: '2099-12-31' } : {},
          ...inputParams,
        }}
        InputLabelProps={shrink ? { shrink: true } : {}}
        data-tip={toolTip}
        {...textFieldProps}
      >
        {selectItems &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectItems.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              style={{
                height: 50,
              }}
            >
              {t(item.label)}
            </MenuItem>
          ))}
      </TextField>
      {(isError || required) && (
        <FormHelperText
          id="component-error-text"
          style={{ ...getHelperTextStyle(), visibility: !isError ? 'hidden' : 'visible' }}
        >
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomInput;

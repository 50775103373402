// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Title from 'components/common/Title';
import Button from 'components/uiKit/Button';
import { statusParamsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import useActivationOrder, { useActivationOrderType } from 'hooks/api/useActivationOrder';
import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import { getTransactionsByIdDataType } from 'queries/transaction/getTransactionsById';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import useSystemSetting from 'hooks/api/useSystemSetting';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';

import InternalCard from './Cards/Internal/InternalCard';
import OutgoingCard from './Cards/Outgoing/OutgoingCard';
import { initialObjTransactionsType, setInitialKey, setInitialOutgoingIncomingKey } from './data';
import IncomingCard from './Cards/Incoming/IncomingCard';
import FeeCard from './Cards/Fee/FeeCard';
import AdjustmentCard from './Cards/Adjustment/AdjustmentCard';
import ConversionCard from './Cards/Conversion/ConversionCard';

interface TransactionsFormPropsType {
  transactionsFormData: initialObjTransactionsType;
  getTransactionsById: (data: getTransactionsByIdDataType) => void;
  setTransactionFormData: (data: any) => void;
  status: statusParamsEnum;
  type: string;
  transaction_id: string;
  isSubmittingForm: boolean;
}

const currentTittle = {
  [statusParamsEnum.EDIT]: 'forms.transaction.editTransaction',
  [statusParamsEnum.CREATE]: 'forms.transaction.createTransaction',
};

export const isEditCreateCard = {
  [KeyTransactions.INCOMING]: true,
  [KeyTransactions.OUTGOING]: true,
  [KeyTransactions.INTERNAL]: true,
  [KeyTransactions.FEE]: true,
  [KeyTransactions.ADJUSTMENT]: true,
  [KeyTransactions.CONVERSION]: true,
};

const currentCard = {
  [KeyTransactions.INCOMING]: <IncomingCard />,
  [KeyTransactions.OUTGOING]: <OutgoingCard />,
  [KeyTransactions.INTERNAL]: <InternalCard />,
  [KeyTransactions.FEE]: <FeeCard />,
  [KeyTransactions.ADJUSTMENT]: <AdjustmentCard />,
  [KeyTransactions.CONVERSION]: <ConversionCard />,
};

const CreateAndEditTransactions: FC<TransactionsFormPropsType> = ({
  transactionsFormData,
  getTransactionsById,
  setTransactionFormData,
  status,
  type,
  transaction_id,
  isSubmittingForm,
}) => {
  const history = useHistory();
  const { getFactorDetails }: useTransactionType = useTransaction();
  const { getCompanies, getPurchaseClaims }: useCompanyType = useCompany();
  const { getActivationOrderV2 }: useActivationOrderType = useActivationOrder();
  const { getPurchaseRequestList } = usePurchaseRequest();
  const { getSystemSettings } = useSystemSetting();
  const { t } = useTranslation();

  const onBack = () => {
    if (transaction_id) history.push(`${urls.getTransactions()}/${transaction_id}`);

    if (!transaction_id) history.goBack();
  };

  const getDisabled = () => {
    if (
      transactionsFormData.type === KeyTransactions.INCOMING &&
      transactionsFormData?.value_datetime &&
      transactionsFormData?.transaction_date
    ) {
      return moment(transactionsFormData.value_datetime).isBefore(transactionsFormData.transaction_date);
    }

    return false;
  };

  useEffect(() => {
    transaction_id &&
      getTransactionsById({
        transaction_id,
        successCallback: (res) => {
          const initialKey = ['outgoing', 'incoming'].includes(res.type)
            ? setInitialOutgoingIncomingKey(res)
            : setInitialKey(res);
          getPurchaseRequestList({ params: { company_id: initialKey?.recipient?.company_id } });
          getPurchaseClaims({ params: { company_id: initialKey?.recipient?.company_id } });
          getActivationOrderV2({
            params: {
              company_id: initialKey?.recipient?.company_id,
              _sort: 'name',
              limit: 10000,
            },
          });
          setTransactionFormData({ ...initialKey, [KeyTransactions.TYPE]: type });
        },
      });
    getSystemSettings({
      block_name: 'company_info',
      successCallback: ({ factor_id, trading_name }) => {
        const INFO = {
          [KeyTransactions.FACTOR_ID]: factor_id,
          [KeyTransactions.TRADING_NAME]: trading_name,
        };
        setTransactionFormData({
          ...INFO,
          [KeyTransactions.TYPE]: type,
        });
      },
    });
    getCompanies({ showDeleted: false });
    getFactorDetails({});
  }, []);

  return (
    <>
      <Title text={currentTittle[status]} styles={{ marginBottom: 24 }} />
      <div>{currentCard[transactionsFormData[KeyTransactions.TYPE]]}</div>
      {transactionsFormData[KeyTransactions.TYPE] && (
        <div style={{ display: 'flex' }}>
          <Button
            type="submit"
            style={{ height: 40, marginRight: 24 }}
            variant="fill"
            color="grey_light"
            loading={isSubmittingForm}
            disabled={getDisabled()}
            onlyLoadingIcon
          >
            {t('forms.transaction.saveTransaction')}
          </Button>
          <Button style={{ height: 40 }} onClick={onBack}>
            {t('forms.transaction.cancel')}
          </Button>
        </div>
      )}
    </>
  );
};

export default CreateAndEditTransactions;

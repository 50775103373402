import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getArchiveTransactionsType {
  params?: object;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({
  params,
  errorCallback,
  resultKey = 'archiveTransactionsData',
  successCallback,
}: getArchiveTransactionsType) =>
  requestAsync({
    url: endpoints.getArchiveTransactionsUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: 'archiveTransactionsQuery',
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { initialObjTransactionsType } from 'components/tables/TransactionsTable/ViewsForm/data';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

const initialAdjustmentRequiredKey = [
  KeyTransactions.TYPE,
  KeyTransactions.CURRENCY,
  KeyTransactions.AMOUNT,
  KeyTransactions.RECIPIENT,
  KeyTransactions.DETAILS,
  KeyTransactions.COMMENT,
];

export const requiredAdjustmentKeys = (transactionsFormData: initialObjTransactionsType) => {
  const initial = [...initialAdjustmentRequiredKey, KeyTransactions.DOCUMENT_AO, KeyTransactions.CLAIMS];

  if (transactionsFormData[KeyTransactions.DOCUMENT_AO] && initial.indexOf(KeyTransactions.CLAIMS) !== -1) {
    initial.splice(initial.indexOf(KeyTransactions.CLAIMS), 1);
  }

  if (transactionsFormData[KeyTransactions.CLAIMS] && initial.indexOf(KeyTransactions.DOCUMENT_AO) !== -1) {
    initial.splice(initial.indexOf(KeyTransactions.DOCUMENT_AO), 1);
  }

  return initial;
};
export const errorsAdjustment = {
  [KeyTransactions.ROOT]: [
    { key: KeyTransactions.TYPE, parameters: { required: true } },
    { key: KeyTransactions.VALUE_DATE_TIME, parameters: { required: true } },
    { key: KeyTransactions.CURRENCY, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT, parameters: { required: true } },
    { key: KeyTransactions.RECIPIENT, parameters: { required: true } },
  ],
};

export type errorsIncomingType = typeof errorsAdjustment;

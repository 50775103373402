import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UseStylingButton } from 'hooks/useStylingForMaterialUi';
import useErrorCallback from 'hooks/useErrorCallback';
import useActivationOrder from 'hooks/api/useActivationOrder';

import ModalTable from './ModalTable';

const Calculations = () => {
  const {
    activationOrderTechnicalInfoData,
    getActivationOrderTechnicalInfo,
    recalculateAOTechnicalInfo,
    getActivationOrderCalculateStatus,
  } = useActivationOrder();
  const stylingButton = UseStylingButton();
  const { errorCallback } = useErrorCallback(() => {
    setIsloading(false);

    return true;
  });
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { activationOrderId } = useParams();
  const [isLoading, setIsloading] = useState(true);

  const getRecalculateHandler = useCallback(() => {
    setIsloading(true);
    recalculateAOTechnicalInfo({
      id: activationOrderId,
      errorCallback,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      successCallback: (data) => {
        if (data.calc_status === 'done') {
          getActivationOrderTechnicalInfo({
            id: activationOrderId,
            errorCallback,
            successCallback: () => setIsloading(false),
          });

          return;
        }
        setIsloading(false);
      },
    });
  }, [getActivationOrderTechnicalInfo, activationOrderId, errorCallback, recalculateAOTechnicalInfo]);

  const getTechnicalInfoHandler = useCallback(() => {
    setIsloading(true);
    getActivationOrderCalculateStatus({
      id: activationOrderId,
      errorCallback,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      successCallback: (data) => {
        if (data.calc_status === 'done') {
          getActivationOrderTechnicalInfo({
            id: activationOrderId,
            errorCallback,
            successCallback: () => setIsloading(false),
          });

          return;
        }
        setIsloading(false);
      },
    });
  }, [getActivationOrderTechnicalInfo, getActivationOrderCalculateStatus, activationOrderId, errorCallback]);

  useEffect(() => {
    getTechnicalInfoHandler();
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {!isLoading && (
        <Button
          onClick={getRecalculateHandler}
          style={{ height: 40, width: 120, position: 'absolute', right: 0, top: -50 }}
          {...stylingButton}
        >
          {t('forms.activationOrder.modalTable.recalculate')}
        </Button>
      )}
      {isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <ModalTable
          isShowPagination={false}
          technicalInfo={activationOrderTechnicalInfoData}
          getRecalculateHandler={getRecalculateHandler}
        />
      )}
    </div>
  );
};

export default Calculations;

import { FormInstance } from 'antd/es/form/Form';

import { Company, CompanyKeyEnum, TabEnum } from 'types/CompanyTypes';

// TODO:
// необходимо избавиться от generalField и contactFields.
// Для этого необходимо переписать создание формы - на три отдельные формы по табам
const generalField = [
  CompanyKeyEnum.TYPE,
  CompanyKeyEnum.OPERATING_CURRENCY,
  CompanyKeyEnum.NAME,
  CompanyKeyEnum.LEGAL_NAME,
  CompanyKeyEnum.REGISTRATION_NUMBER,
  CompanyKeyEnum.TAX_NUMBER,
  CompanyKeyEnum.WEBSITE,
  CompanyKeyEnum.EMAIL,
  CompanyKeyEnum.PHONE,
  CompanyKeyEnum.COUNTRY,
  CompanyKeyEnum.ZIP,
  CompanyKeyEnum.ADDRESS,
];

const contactFields = [
  CompanyKeyEnum.SIGNER_FULL_NAME,
  CompanyKeyEnum.SIGNER_POSITION,
  CompanyKeyEnum.SIGNER_EMAIL,
  CompanyKeyEnum.CONTACTS,
];

interface FinishFailed {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance<any>;
  isFactor?: boolean;
  currentTabId: TabEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInitialValues: (values: Partial<Company>) => void;
  setTabId: (tab: TabEnum) => void;
  error: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorFields: any;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onFinishFailed = ({
  form,
  currentTabId,
  isFactor,
  setInitialValues,
  setTabId,
  error: { errorFields },
}: FinishFailed) => {
  const updateInitialAndOpenTab = (newTabId: TabEnum) => {
    const values = form.getFieldsValue();

    if (newTabId !== currentTabId) {
      setInitialValues(values);
      form.resetFields();

      setTabId(newTabId);
    }
  };

  const errorFieldsName = errorFields.reduce((prevVal: string[], curVal: { name: string[] }) => {
    return [...prevVal, curVal.name[0]];
  }, []);

  if (generalField.some((field) => errorFieldsName.includes(field))) {
    updateInitialAndOpenTab(TabEnum.GENERAL);

    return;
  }

  if (contactFields.some((field) => errorFieldsName.includes(field))) {
    updateInitialAndOpenTab(isFactor ? TabEnum.REPRESENTATIVE : TabEnum.CONTACT);

    return;
  }

  updateInitialAndOpenTab(TabEnum.BANK_ACCOUNT);
};

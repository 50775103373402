import { notification } from '@gamesb42/ui-kit';
import urls from 'constants/urls';
import endpoints from 'consts/endpoints';

import { getRefreshToken, getToken, setRefreshToken, setToken } from 'helpers/token';

const parseResponse = async (res: Response, reqArgs: any) => {
  const onFailedRefreshToken = () => {
    localStorage.clear();
    window.location.href = `${window.location.origin}${urls.getAuth()}${window.location.pathname}`;
  };

  if ([401, 403].includes(res.status)) {
    if (!getRefreshToken()) {
      onFailedRefreshToken();
    }

    fetch(endpoints.getRefreshTokenUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: getRefreshToken() || '',
      }),
    })
      .then(async (res: Response) => {
        if (res.status !== 200) {
          onFailedRefreshToken();
          return;
        }
        const result = await res.json();

        setToken(result.access_token);
        setRefreshToken(result.refresh_token);

        return baseRequest(reqArgs);
      })
      .catch(() => {
        onFailedRefreshToken();
        return;
      });
  }

  if (res.ok) {
    const result = await res.json();

    return result;
  }

  const result = await res.json();

  throw new Error(JSON.stringify(result));
};

interface BaseRequestArgs<T> {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: Omit<RequestInit, 'body'> & { body?: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapper?: (backendData: any) => T;
  mapperToBackend?: (frontend: T) => any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseRequest = <T = any>(reqArgs: BaseRequestArgs<T>): Promise<T> => {
  const { path, options = {}, mapper, mapperToBackend } = reqArgs;
  const { headers, method = 'GET', body, ...extraOpts } = options;

  const token = getToken();

  const reqOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
    ...extraOpts,
  };

  if (body) {
    reqOptions.body = typeof body === 'object' ? JSON.stringify(mapperToBackend ? mapperToBackend(body) : body) : body;
  }

  return fetch(path, reqOptions)
    .then((res) => parseResponse(res, reqArgs))
    .then((result) => (mapper ? mapper(result) : result))
    .catch((errors) => {
      const errorObject = JSON.parse(errors.message);

      if (errorObject.detail?.length > 0 && Array.isArray(errorObject.detail)) {
        errorObject.detail.map((e: { type: string; msg: string }) =>
          notification.error({
            message: e.type,
            description: e.msg,
          }),
        );
      }

      if (Array.isArray(errorObject.errors))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errorObject.errors.forEach((error: any) => {
          if (Array.isArray(error))
            error.forEach((e) => {
              if (e.msg && e.type)
                notification.error({
                  message: e.type,
                  description: e.msg,
                });
            });
        });

      throw new Error(errors.message);
    });
};

export default baseRequest;

import React from 'react';
import { LinearProgress, Typography, Box } from '@material-ui/core';

type LinearProgressWithLabelPropsType = {
  value: number;
  progressStr?: string;
};

const LinearProgressWithLabel = ({ value, progressStr = undefined }: LinearProgressWithLabelPropsType) => {
  const typographyValue = progressStr !== undefined ? progressStr : `${Math.round(value)}%`;

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {typographyValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;

import { FC } from 'react';
import { Location } from 'history';

export interface BackendVersion {
  version: string;
  build_date: Date;
  build_branch: string;
  build_revision: string;
}

export const enum MENU_ITEM_TYPE {
  LINK,
  DIVIDER,
  SUB_MENU,
  SUB_LINK,
}

export interface MenuItemLink {
  type: MENU_ITEM_TYPE.LINK;
  title: string;
  Icon: (props: any) => JSX.Element;
  to: string | ((location: Location<unknown>) => string);
}

export interface MenuItemSubLink {
  type: MENU_ITEM_TYPE.SUB_LINK;
  title: string;
  to: string;
}

export interface MenuItemDivider {
  type: MENU_ITEM_TYPE.DIVIDER;
}

export interface MenuItemSubMenu {
  type: MENU_ITEM_TYPE.SUB_MENU;
  Icon: (props: any) => JSX.Element;
  title: string;
  subItems: MenuItemSubLink[];
}

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type editUserDataType = {
  id: string;
  fullName: string;
  username: string;
  email?: string;
  companyId: string;
  authProvider: string;
  password?: string;
  active: boolean;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

type editUserBodyType = {
  full_name: string;
  user_name: string;
  company_id: string;
  email?: string;
  auth_provider: string;
  password?: string;
  active: boolean;
};

export default ({
  id,
  fullName,
  username,
  email,
  companyId,
  authProvider,
  password,
  active,
  successCallback,
  errorCallback,
  resultKey = 'editUserData',
}: editUserDataType) => {
  const bodyObj: editUserBodyType = {
    full_name: fullName,
    user_name: username,
    company_id: companyId,
    auth_provider: authProvider,
    active,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bodyObj.company_id = companyId && companyId.length > 0 ? companyId : null;

  if (email) {
    bodyObj.email = email;
  }

  if (password) {
    bodyObj.password = password;
  }

  return requestAsync({
    url: endpoints.getUserUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getUserUrl(id),
    body: bodyObj,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });
};

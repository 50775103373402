import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type deleteUserDataType = {
  id: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

export default ({ id = '', successCallback, errorCallback, resultKey = 'deleteUserData' }: deleteUserDataType) =>
  requestAsync({
    url: endpoints.getUserUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getUserUrl(id),
    body: {},
    options: {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

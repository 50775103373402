import { KeyTransactions } from 'components/tables/TransactionsTable/data';

const initialConversionRequiredKey = [
  KeyTransactions.TYPE,
  KeyTransactions.CURRENCY,
  KeyTransactions.VALUE_DATE_TIME,
  KeyTransactions.COMMISSION,
  KeyTransactions.RECIPIENT,
  KeyTransactions.COMMENT,
  KeyTransactions.AMOUNT_SELL,
  KeyTransactions.AMOUNT_BUY,
];

export const requiredConversionKeys = () => initialConversionRequiredKey;
export const errorsConversion = {
  [KeyTransactions.ROOT]: [
    { key: KeyTransactions.TYPE, parameters: { required: true } },
    { key: KeyTransactions.CURRENCY, parameters: { required: true } },
    { key: KeyTransactions.VALUE_DATE_TIME, parameters: { required: true } },
    { key: KeyTransactions.COMMISSION, parameters: { required: true } },
    { key: KeyTransactions.RECIPIENT, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT_BUY, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT_SELL, parameters: { required: true } },
  ],
};

export type errorsConversionType = typeof errorsConversion;

import React, { FC } from 'react';
import classNames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import { TableCell, TableRow } from '@material-ui/core';

import styles from './styles.module.scss';

const ClientAmountReportTableSkeleton = () => (
  <TableRow className={styles.row}>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="98px" className={styles.cell}>
      <Skeleton variant="rectangular" width={57} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="98px" className={styles.cell}>
      <Skeleton variant="rectangular" width={57} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="98px" className={styles.cell}>
      <Skeleton variant="rectangular" width={57} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="98px" className={styles.cell}>
      <Skeleton variant="rectangular" width={57} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
    <TableCell align="left" width="152px" className={styles.cell}>
      <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />
    </TableCell>
  </TableRow>
);

export default ClientAmountReportTableSkeleton;

import React, { FC, useState, ReactNode, Dispatch, SetStateAction, useRef, useEffect } from 'react';

import dotSvgIcon from 'assets/img/dots.svg';
import Button, { PropsT as ButtonPropsT } from 'components/uiKit/Button';

import styles from './styles.module.scss';

type PropsT = {
  buttonProps?: ButtonPropsT;
  buttonComponent?: (isActive: boolean, handleOpenPopup: Dispatch<SetStateAction<boolean>>) => ReactNode;
  children: (handleOpenPopup: Dispatch<SetStateAction<boolean>>) => ReactNode;
};

const EditGeneral: FC<PropsT> = (props) => {
  const { buttonProps, buttonComponent, children } = props;
  const rootRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onClick = (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!rootRef.current?.contains(event.target)) {
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
    }

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isActive]);

  return (
    <div ref={rootRef} style={{ position: 'relative' }}>
      {buttonComponent && buttonComponent(isActive, setIsActive)}
      {!buttonComponent && (
        <Button type="button" className={styles.editWrap} {...buttonProps} onClick={() => setIsActive(!isActive)}>
          <img src={dotSvgIcon} alt="dots" />
        </Button>
      )}
      {isActive && <div className={styles.editMenu}>{children(setIsActive)}</div>}
    </div>
  );
};

export default EditGeneral;

/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompany from 'hooks/api/useCompany';
import useExpectedPayments, { expectedPaymentsAllDataType } from 'hooks/api/useExpectedPayments';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import { tableHeaderStyles } from 'theme/styles';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import useRouteTitle from 'hooks/useRouteTitle';
import { CompanyType, TargetsType } from 'types/CompanyTypes';
import { ExpectedPaymentsFilters } from 'consts/tableFilters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { formatDate } from 'helpers/formatters';
import { expectedPaymentsParamsType, expectedPaymentsType } from 'queries/expectedPayments/getExpectedPayments';
import { removeEmptyValuesFromObject } from 'helpers/dt';
import Select from 'components/uiKit/Select';
import CompanySelect from 'components/CompanySelect';

import { nameTargetEnum } from '../Row';

import styles from './styles.module.scss';

interface useExpectedPaymentsType {
  expectedPaymentsData: expectedPaymentsAllDataType;
  getExpectedPayments: (data: expectedPaymentsType) => void;
  expectedPaymentsTableParams: expectedPaymentsParamsType;
  setExpectedPaymentsTableParams: () => void;
  listTarget: TargetsType[];
  latePayments: TargetsType[];
}

const companyDf = { id: '', name: '', active: false };
const ExpectedPaymentsHeader: FC | any = ({ requestParams, refreshCount }) => {
  const { t } = useTranslation();
  const classes = tableHeaderStyles();
  const location = useLocation();
  const { title } = useRouteTitle();
  const { dateValidator } = useFilterDateValidate();
  const {
    companiesData: { companiesData = [] },
    getCompanies,
  } = useCompany();
  const {
    listTarget,
    latePayments,
    getExpectedPayments,
    expectedPaymentsTableParams,
    setExpectedPaymentsTableParams,
  }: useExpectedPaymentsType = useExpectedPayments();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);
  const [errors, setErrors] = useState({
    date_to: { status: false, text: '' },
  });
  const { company_id } = expectedPaymentsTableParams;
  const [validate, setValidate] = useState<boolean>(false);
  const [listOrders, setListOrders] = useState<CompanyType[]>([]);
  const [company, setCompany] = useState<CompanyType>(null);
  const [type, setType] = useState<TargetsType>(null);
  const [showLatePayments, setShowLatePayments] = useState<TargetsType>(null);

  const setOrders = useCallback((data: expectedPaymentsAllDataType | null | undefined) => {
    if (!data) return [];
    let ordersData = [] as CompanyType[];
    data?.orders?.forEach(({ name, id, purchase_date }) => {
      if (name === nameTargetEnum.EUR || name === nameTargetEnum.USD) return;
      ordersData = [...ordersData, { name: `${name} [${formatDate(purchase_date)}]`, id }];
    });

    return ordersData;
  }, []);

  useEffect(() => {
    getCompanies({ errorCallback });
  }, [getCompanies, errorCallback]);

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'expectedPaymentsTableParams',
      params: expectedPaymentsTableParams,
      requestParams,
    });
    setExpectedPaymentsTableParams(newParams);

    if (newParams.payment_type) {
      const target = listTarget.find((item) => item.key === newParams.payment_type);

      if (target) {
        setType(target);
      }
    }

    if (newParams.dont_show_late_payments) {
      const target = latePayments.find((item) => item.key === newParams.dont_show_late_payments);

      if (target) {
        setShowLatePayments(target);
      }
    }
    setValidate(true);
    getExpectedPayments({
      params: removeEmptyValuesFromObject<expectedPaymentsParamsType>(newParams),
      errorCallback: () => {
        delete expectedPaymentsTableParams.ao_ids;
        setExpectedPaymentsTableParams(expectedPaymentsTableParams);
      },
      successCallback: (res) => {
        if (listOrders.length === 0) {
          const check = setOrders(res);
          setListOrders(check);
        }
      },
    });
  }, [refreshCount]);

  useEffect(() => {
    refreshContext.setParams({ datakey: 'expectedPaymentsTableParams', params: expectedPaymentsTableParams });
    setUrlStringParams({ filters: ExpectedPaymentsFilters, currentScreenParams: expectedPaymentsTableParams });
  }, [expectedPaymentsTableParams]);

  const onChangeCompanyHandler = useCallback(
    (values: CompanyType | null | CompanyType[]) => {
      let newParams = null as null | expectedPaymentsParamsType;

      if (Array.isArray(values)) {
        const current = values.filter((item) => item.id);
        newParams = {
          ...expectedPaymentsTableParams,
          ao_ids: current.map((item) => item.id).toString(),
        };
      }

      if (!Array.isArray(values)) {
        setCompany({ name: values ? values.name : '', id: values ? values.id : '' });
        newParams = {
          ...expectedPaymentsTableParams,
          company_id: values ? values.id : '',
        };
      }

      if (validate) {
        getExpectedPayments({
          params: removeEmptyValuesFromObject<expectedPaymentsParamsType>(newParams),
          errorCallback,
          successCallback: (res) => {
            if (!Array.isArray(values) || values.length === 0) {
              const check = setOrders(res);
              setListOrders(check);
            }
          },
        });
      }
      setExpectedPaymentsTableParams(newParams);
    },
    [setExpectedPaymentsTableParams, expectedPaymentsTableParams, errorCallback, validate, getExpectedPayments],
  );

  const onChangeTypeHandler = useCallback(
    (values: TargetsType | null) => {
      const newParams = {
        ...expectedPaymentsTableParams,
        payment_type: values ? values.key : '',
      };

      if (validate) {
        setType({ name: values ? values.name : '', key: values ? values.key : '' });
        getExpectedPayments({
          params: removeEmptyValuesFromObject<expectedPaymentsParamsType>(newParams),
          errorCallback,
          successCallback: (res) => {
            const check = setOrders(res);
            setListOrders(check);
          },
        });
      }
      setExpectedPaymentsTableParams(newParams);
    },
    [expectedPaymentsTableParams],
  );

  const onChangeShowLatePaymentsHandler = useCallback(
    (values: TargetsType | null) => {
      const newParams = {
        ...expectedPaymentsTableParams,
        dont_show_late_payments: values ? values.key : '',
      };

      if (validate) {
        setShowLatePayments({ name: values ? values.name : '', key: values ? values.key : '' });
        getExpectedPayments({
          params: removeEmptyValuesFromObject<expectedPaymentsParamsType>(newParams),
          errorCallback,
          successCallback: (res) => {
            const check = setOrders(res);
            setListOrders(check);
          },
        });
      }
      setExpectedPaymentsTableParams(newParams);
    },
    [expectedPaymentsTableParams],
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.container}>
        <form className={classes.form} noValidate autoComplete="off">
          <div className={styles.company}>
            <CompanySelect
              label={t('contentHeader.company')}
              value={company_id}
              onChange={(_, value) => {
                onChangeCompanyHandler(value?.source || '');
              }}
              allowClear
            />
          </div>
          <div className={styles.type}>
            <Select
              label={t('tables.expectedPaymentsTable.type')}
              options={listTarget
                .filter((item) => item.key !== 'bank_fee')
                .map((e) => ({ key: e.key, value: t(e.name) }))}
              value={type === null ? companyDf.id : type.key}
              onChange={(value) => {
                const newType = listTarget.find((e) => e.key === value);
                onChangeTypeHandler(newType);
              }}
            />
          </div>
          <div className={styles.payments}>
            <Select
              options={latePayments.filter((item) => item.key).map((e) => ({ key: e.key, value: t(e.name) }))}
              value={!showLatePayments ? '' : showLatePayments.key}
              onChange={(value) => {
                const newLatePayments = latePayments.find((e) => e.key === value);
                onChangeShowLatePaymentsHandler(newLatePayments);
              }}
              label={t('tables.expectedPaymentsTable.latePayments')}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpectedPaymentsHeader;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export enum GroupsEnumCompany {
  APPLAUD = 'applaud',
  CLASSIC = 'classic',
  APPLAUD_FIX = 'applaud_fix',
}

export interface ContactPersonsType {
  full_name: string;
  job_position: string;
  email: string;
  contact_phone: string;
}

export interface BankAccountsT {
  bank_name: string;
  swift: string;
}

export interface CreateCompanyParamsType {
  company_name: string;
  groups: GroupsEnumCompany[];
  legal_name?: string;
  company_registration?: string;
  tax_number?: string;
  website?: string;
  city?: string;
  country?: string;
  zip?: string;
  address?: string;
  address2?: string;
  signature_position?: string;
  signature_full_name?: string;
  contact_persons?: ContactPersonsType[];
  bank_usd?: string;
  swift_usd?: string;
  swift_eur?: string;
  iban_usd?: string;
  iban_eur?: string;
  correspondent_account_usd?: string;
}
export interface CreateCompanyDataType {
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
  params: CreateCompanyParamsType;
}

export default ({ successCallback, errorCallback, resultKey = 'createCompanyData', params }: CreateCompanyDataType) =>
  requestAsync({
    url: endpoints.getCompanyCreateUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompanyCreateUrl(),
    body: params,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type systemSettingEditType = {
  formData: {
    signature: {
      position: string;
      full_name: string;
      factor_name: string;
    };
    auth: {
      enabled: boolean;
      client_id: string;
      realm_name: string;
      server_url: string;
    };
    accounts: {
      enabled: boolean;
      server_url: string;
    };
    general: {
      investor_interest: string;
      auto_debiting: boolean;
    };
  };
  block_name: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

export default ({
  formData,
  block_name,
  successCallback,
  errorCallback,
  resultKey = 'systemSettingsEdit',
}: systemSettingEditType) =>
  requestAsync({
    url: endpoints.getSystemSettingsUrl(block_name),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getSystemSettingsUrl(block_name),
    body: {
      id: 'edit',
      ...formData,
    },
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC } from 'react';

import { PurchaseRequestDataType } from 'hooks/api/usePurchaseRequest';
import GeneralInformation from 'components/forms/PurchaseRequestForm/General/common/GeneralInformation';

type PropsT = {
  purchaseRequestData: PurchaseRequestDataType;
};

const ClassicView: FC<PropsT> = ({ purchaseRequestData }) => (
  <GeneralInformation purchaseRequestData={purchaseRequestData} />
);

export default ClassicView;

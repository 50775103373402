import { StatusEnumTitle } from 'components/common/Status';

export type CompanyStatusType = {
  name: string;
  status: string;
  id?: any;
  active?: boolean;
};

export type CompanyType = {
  name: string;
  id?: string;
  active?: boolean;
};

export type TargetsType = {
  key: string;
  name: string;
  active?: boolean;
};

export enum EGroups {
  classic = 'classic',
  applaud = 'applaud',
  applaud_fix = 'applaud_fix',
}

export const enum TabEnum {
  GENERAL = 'General',
  CONTACT = 'Contact',
  BANK_ACCOUNT = 'Bank Account',
  REPRESENTATIVE = 'Representative',
  INTERNAL_ACCOUNTS = 'Internal Accounts',
}

export interface ICompanyParamsType {
  showDeleted?: boolean;
  type?: string;
  status?: string;
  page?: number;
  limit?: number;
  order?: string;
  sortedBy?: string;
  companyName?: string;
  group?: EGroups | '';
}

export const typeCompanies = [
  {
    id: 'client',
    name: 'Client',
  },
  {
    id: 'third-party',
    name: 'Third party',
  },
  {
    id: 'revenue-source',
    name: 'Revenue source',
  },
];

// TODO: нужно удалить CompanyType выше, а этот переименовать в CompanyType
export const enum CompanyTypeREPLACE {
  CLIENT = 'client',
  THIRD_PARTY = 'third-party',
  REVENUE_SOURCE = 'revenue-source',
}

export const enum CompanyForm {
  SOLE_PROPRIETOR = 'sole-proprietor',
  PARTNERSHIP = 'partnership',
  CORPORATION = 'corporation',
}

export const enum CompanyStatus {
  NEW = 'new',
  IN_REVIEW = 'in-review',
  ACTIVE = 'active',
  REJECTED = 'rejected',
  BLOCKED = 'blocked',
  ARCHIVE = 'archive',
  DELETED = 'deleted',
}

export const enum CompanyKeyEnum {
  ID = 'id',
  TYPE = 'type',
  NAME = 'name',
  STATUS = 'status',
  LEGAL_NAME = 'legal_name',
  REGISTRATION_NUMBER = 'registration_number',
  TAX_NUMBER = 'tax_number',
  WEBSITE = 'website',
  EMAIL = 'email',
  PHONE = 'phone',
  COUNTRY = 'country',
  ZIP = 'zip',
  STATE = 'state',
  CITY = 'city',
  ADDRESS = 'address',
  CONTACTS = 'contacts',
  BANK_DETAILS = 'bank_details',
  FULL_NAME = 'full_name',
  POSITION = 'position',
  CONTACT_PHONE = 'contact_phone',
  CURRENCY = 'currency',
  BANK_NAME = 'bank_name',
  SWIFT = 'swift',
  MASTER = 'master',
  ACCOUNT = 'account',
  BANK_ADDRESS = 'bank_address',
  SPECIAL_INSTRUCTIONS = 'special_instructions',
  SIGNER_ID = 'signer_id',
  SIGNER_FULL_NAME = 'signer_full_name',
  SIGNER_POSITION = 'signer_position',
  SIGNER_EMAIL = 'signer_email',
  SIGNER_PHONE = 'signer_phone',
  OPERATING_CURRENCY = 'operating_currency',
  CORRESPONDENT_ACCOUNT = 'correspondent_account',
  CORRESPONDENT_BANK_NAME = 'correspondent_bank_name',
  CORRESPONDENT_SWIFT = 'correspondent_swift',
  SETTINGS = 'settings',
  COMPANY_FORM = 'company_form',
}

export interface CompanyBackend {
  [CompanyKeyEnum.ID]: string;
  [CompanyKeyEnum.NAME]: string;
  [CompanyKeyEnum.TYPE]: CompanyTypeREPLACE;
  [CompanyKeyEnum.STATUS]: StatusEnumTitle;
  [CompanyKeyEnum.SETTINGS]?: {
    [CompanyKeyEnum.OPERATING_CURRENCY]?: string;
  };
  created: string;
  updated: string;
  details: null | {
    [CompanyKeyEnum.LEGAL_NAME]?: string;
    [CompanyKeyEnum.REGISTRATION_NUMBER]?: string;
    [CompanyKeyEnum.TAX_NUMBER]?: string;
    [CompanyKeyEnum.WEBSITE]?: string | null;
    [CompanyKeyEnum.EMAIL]?: string;
    [CompanyKeyEnum.PHONE]?: string;
    [CompanyKeyEnum.COUNTRY]?: string;
    [CompanyKeyEnum.ZIP]?: string;
    [CompanyKeyEnum.STATE]?: string;
    [CompanyKeyEnum.CITY]?: string;
    [CompanyKeyEnum.ADDRESS]?: string;
    [CompanyKeyEnum.COMPANY_FORM]?: CompanyForm;
    factor_iban?: string;
    comment?: string;
  };
  [CompanyKeyEnum.BANK_DETAILS]:
    | null
    | {
        [CompanyKeyEnum.CURRENCY]?: string;
        [CompanyKeyEnum.ID]?: string;
        [CompanyKeyEnum.MASTER]?: boolean;
        [CompanyKeyEnum.BANK_NAME]?: string;
        [CompanyKeyEnum.BANK_ADDRESS]?: string;
        [CompanyKeyEnum.ACCOUNT]?: string;
        [CompanyKeyEnum.SWIFT]?: string;
        [CompanyKeyEnum.SPECIAL_INSTRUCTIONS]?: string;
        [CompanyKeyEnum.CORRESPONDENT_ACCOUNT]?: string;
        [CompanyKeyEnum.CORRESPONDENT_BANK_NAME]?: string;
        [CompanyKeyEnum.CORRESPONDENT_SWIFT]?: string;
      }[];
  [CompanyKeyEnum.CONTACTS]:
    | null
    | {
        [CompanyKeyEnum.ID]?: string;
        [CompanyKeyEnum.TYPE]: 'signer' | 'contact';
        [CompanyKeyEnum.FULL_NAME]?: string;
        [CompanyKeyEnum.POSITION]?: string;
        [CompanyKeyEnum.EMAIL]?: string;
        [CompanyKeyEnum.CONTACT_PHONE]?: string;
      }[];
}

export interface BankAccount {
  [CompanyKeyEnum.ID]?: string;
  [CompanyKeyEnum.CURRENCY]?: string;
  [CompanyKeyEnum.MASTER]?: boolean;
  [CompanyKeyEnum.BANK_NAME]?: string;
  [CompanyKeyEnum.SWIFT]?: string;
  [CompanyKeyEnum.ACCOUNT]?: string;
  [CompanyKeyEnum.BANK_ADDRESS]?: string;
  [CompanyKeyEnum.SPECIAL_INSTRUCTIONS]?: string;
  [CompanyKeyEnum.CORRESPONDENT_ACCOUNT]?: string;
  [CompanyKeyEnum.CORRESPONDENT_BANK_NAME]?: string;
  [CompanyKeyEnum.CORRESPONDENT_SWIFT]?: string;
}

export interface CompanyContact {
  [CompanyKeyEnum.ID]?: string;
  [CompanyKeyEnum.FULL_NAME]?: string;
  [CompanyKeyEnum.POSITION]?: string;
  [CompanyKeyEnum.EMAIL]?: string;
  [CompanyKeyEnum.CONTACT_PHONE]?: string;
}

export interface Company {
  [CompanyKeyEnum.ID]: string;
  [CompanyKeyEnum.TYPE]?: CompanyTypeREPLACE;
  [CompanyKeyEnum.NAME]?: string;
  [CompanyKeyEnum.STATUS]?: StatusEnumTitle;
  [CompanyKeyEnum.LEGAL_NAME]?: string;
  [CompanyKeyEnum.REGISTRATION_NUMBER]?: string;
  [CompanyKeyEnum.OPERATING_CURRENCY]?: string;
  [CompanyKeyEnum.TAX_NUMBER]?: string;
  [CompanyKeyEnum.WEBSITE]?: string;
  [CompanyKeyEnum.EMAIL]?: string;
  [CompanyKeyEnum.PHONE]?: string;
  [CompanyKeyEnum.COUNTRY]?: string;
  [CompanyKeyEnum.ZIP]?: string;
  [CompanyKeyEnum.STATE]?: string;
  [CompanyKeyEnum.CITY]?: string;
  [CompanyKeyEnum.ADDRESS]?: string;
  [CompanyKeyEnum.SIGNER_ID]?: string;
  [CompanyKeyEnum.SIGNER_FULL_NAME]?: string;
  [CompanyKeyEnum.SIGNER_EMAIL]?: string;
  [CompanyKeyEnum.SIGNER_POSITION]?: string;
  [CompanyKeyEnum.SIGNER_PHONE]?: string;
  [CompanyKeyEnum.COMPANY_FORM]?: CompanyForm;
  [CompanyKeyEnum.BANK_DETAILS]?: null | BankAccount[];
  [CompanyKeyEnum.CONTACTS]?: null | CompanyContact[];
}

import React, { FC } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import SwitchButton from 'components/buttons/SwitchButton';
import { systemSettingsFieldsEnum } from 'components/forms/SystemSettingsForm/SystemSettingsFormData';
import { InputKit } from 'components/uiKit/InputKit';

type AuthTabGroupDataType = {
  enabled: boolean;
  client_id: string;
  realm_name: string;
  server_url: string;
};

type AuthTabPanelPropsType = {
  groupData: AuthTabGroupDataType;
  setValue: any;
};

const AuthTabPanel: FC<AuthTabPanelPropsType> = ({ groupData, setValue }: AuthTabPanelPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <TabPanel value="auth" style={{ padding: 0 }}>
      <div className={styles.tabBlock}>
        <div style={{ marginBottom: 20 }}>
          <SwitchButton
            inputSwitch={groupData.enabled}
            name="enabled"
            buttonName="global.enabled"
            handleChangeSwitch={setValue}
          />
        </div>
        <InputKit
          label={t('systemSetting.openIDServerURL')}
          name={systemSettingsFieldsEnum.server_url}
          onChange={setValue}
          value={groupData.server_url}
          wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
        />
        <InputKit
          label={t('systemSetting.realmName')}
          name={systemSettingsFieldsEnum.realm_name}
          onChange={setValue}
          value={groupData.realm_name}
          wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
        />
        <InputKit
          label={t('systemSetting.clientId')}
          name={systemSettingsFieldsEnum.client_id}
          onChange={setValue}
          value={groupData.client_id}
          wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
        />
      </div>
    </TabPanel>
  );
};

export default AuthTabPanel;

const useStyles = makeStyles(() => ({
  tabBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '500px',
    marginBottom: '23px',
    height: '48px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

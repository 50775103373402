import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { TargetOrderDataType } from 'types/TransactionFormTypes';

type createTransactionDataType = {
  date: string;
  company_id: string;
  currency: string;
  amount: string;
  type: string;
  receipt_date?: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  adjusting_transaction;
  reason?: string;
  reference_id: string;
  file?: {};
  file_name: string;
  file_data: string;
  mode?: string;
  targets: TargetOrderDataType[];
  comment?: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey?: string;
};

export default ({
  date,
  company_id,
  currency,
  amount,
  type,
  receipt_date,
  adjusting_transaction,
  reason,
  reference_id,
  file_name,
  file_data,
  mode,
  comment,
  targets,
  resultKey = 'createTransactionData',
  successCallback,
  errorCallback,
}: createTransactionDataType) =>
  requestAsync({
    url: endpoints.getCreateTransactionUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getCreateTransactionUrl()}`,
    body: {
      date,
      company_id,
      currency,
      amount,
      type,
      receipt_date,
      adjusting_transaction,
      reason,
      reference_id,
      file_name,
      file_data,
      mode,
      comment,
      targets,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      successCallback,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

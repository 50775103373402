// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const emailValidator = (email) => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})$/;

  return reg.test(email);
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phoneValidator = (phone) => {
  // eslint-disable-next-line no-useless-escape
  // const reg = /^((8|\+)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{5,15}$/;
  const reg = /^((\+)[- ()]?)?([- ()]*\d){5,15}$/;

  return reg.test(phone);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useState, FC, useEffect, useMemo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RemoveFileIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';

import useUploadData from 'hooks/api/useUploadData';
import useErrorCallback from 'hooks/useErrorCallback';
import useProcess from 'hooks/api/useProcess';
import useAlert from 'hooks/api/useAlert';
import { convertFileToBase64 } from 'helpers/file';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';

import FormBottomBlock from './FormBottomBlock';

type LoadAllDataFormPropsType = {
  type?: string;
};

const LoadDataForm: FC<LoadAllDataFormPropsType> = ({ type = 'load_old_data' }: LoadAllDataFormPropsType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadAllData, importedData, cancelUploadAllData, cancelImportedData } = useUploadData();
  const {
    getProcessStatus,
    load_old_data: loadAllDataProcessStatus,
    import_data: importDataProcessStatus,
    setProcessParams,
    processParams,
  } = useProcess();
  const { errorCallback } = useErrorCallback();
  const { setAlert } = useAlert();
  const [error, setError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<any>('');
  const [src, setSrc] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [toolTip, setTooltip] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const processIntervalRef = useRef(null);

  const successCallback = useCallback(() => {
    setDisabled(true);
    getProcessStatus({ process_name: type, errorCallback });
  }, [type, errorCallback, getProcessStatus]);

  const processResponseCallback = useCallback(
    (processResponse) => {
      const { stage, stage_name, documents } = processResponse;

      if (stage) {
        if (stage === 'finish_saving' || stage === 'error' || stage === 'cancel') {
          setFileName('');
          setSrc('');
          setProgress(0);
          setDisabled(false);
          const alertType = stage === 'error' ? 'error' : 'success';
          setTooltip('');

          if (processIntervalRef.current) {
            clearInterval(processIntervalRef.current);
            processIntervalRef.current = null;
          }

          if (processParams[type]) {
            setAlert({ type: alertType, message: stage_name });
            setProcessParams({ ...processParams, [type]: false });
          }
        } else {
          const localProgress = documents.done ? 100 / (documents.total / documents.done) : 0;
          setProgress(localProgress);
          setTooltip(t('processTooltip', { processStatus: stage_name }));
          setDisabled(true);

          if (!processIntervalRef.current) {
            processIntervalRef.current = setInterval(() => {
              getProcessStatus({ process_name: type, errorCallback });
            }, 5000);
          }

          if (!processParams[type]) {
            setProcessParams({ ...processParams, [type]: true });
          }
        }
      }
    },
    [getProcessStatus, errorCallback, type, processParams, setProcessParams, processIntervalRef, setAlert, t],
  );

  useMemo(() => {
    if (type === 'load_old_data') {
      processResponseCallback(loadAllDataProcessStatus);
    }
  }, [loadAllDataProcessStatus, processResponseCallback, type]);

  useMemo(() => {
    if (type === 'import_data') {
      processResponseCallback(importDataProcessStatus);
    }
  }, [importDataProcessStatus, processResponseCallback, type]);

  useEffect(() => {
    getProcessStatus({ process_name: type, errorCallback });
  }, [getProcessStatus, type, errorCallback]);

  useEffect(
    () => () => {
      clearInterval(processIntervalRef.current);
    },
    [processIntervalRef],
  );

  const onClickInput = useCallback((event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }, []);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUrl: any = await convertFileToBase64(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setError(!e.target.value);
    setSrc(fileUrl);
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (fileName) {
        setError(false);
        const res = await fetch(src);
        const blob = await res.blob();
        const blobUrl = URL.createObjectURL(blob);
        const loadData = {
          file: { fileRow: { path: fileName }, src: fileName, url: blobUrl },
          fileData: src,
          fileName,
          errorCallback,
          successCallback,
        };

        if (type === 'load_old_data') {
          uploadAllData(loadData);
        } else {
          importedData(loadData);
        }
      } else {
        setError(true);
      }
    },
    [uploadAllData, importedData, fileName, src, successCallback, errorCallback, type],
  );

  const handleRemoveFile = () => {
    setFileName('');
    setSrc('');
    setError(true);
  };

  const cancelHandler = useCallback(() => {
    setFileName('');
    setSrc('');
    clearInterval(processIntervalRef.current);
    processIntervalRef.current = null;
    setDisabled(false);
    setTooltip('');
    const loadData = {
      errorCallback,
      successCallback,
    };

    if (type === 'load_old_data') {
      cancelUploadAllData(loadData);
    } else {
      cancelImportedData(loadData);
    }
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <div className={classes.topBlock}>
        <p className={classes.uploadLabel} style={{ color: error && 'red' }}>
          {t('file.relatedFilesRequired')}
        </p>
        <div data-tip={toolTip}>
          <input
            style={{ display: 'none' }}
            id="contained-button-file"
            multiple
            disabled={disabled}
            type="file"
            onClick={onClickInput}
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" className={classes.uploadButton} component="span" disabled={disabled}>
              {t('file.dropFileText')}
            </Button>
          </label>
          <p
            className={classes.uploadLabel}
            style={{ visibility: error ? 'visible' : 'hidden', color: error && 'red' }}
          >
            {t('auth.required')}
          </p>
          {src && (
            <div>
              <IconButton onClick={handleRemoveFile} disabled={disabled}>
                <RemoveFileIcon style={{ color: 'red' }} />
              </IconButton>
              <a
                href={src}
                download
                style={{
                  color: disabled ? 'gray' : '-webkit-link',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  pointerEvents: disabled ? 'none' : 'auto',
                }}
              >
                {fileName}
              </a>
            </div>
          )}
        </div>
        {disabled ? (
          <div>
            <LinearProgressWithLabel value={progress} />
          </div>
        ) : null}
      </div>
      <FormBottomBlock cancelHandler={cancelHandler} disabled={disabled} toolTip={toolTip} validate={!!fileName} />
      <ReactTooltip />
    </form>
  );
};

export default LoadDataForm;

const useStyles = makeStyles(() => ({
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  uploadBlock: {
    marginBottom: '8px',
  },
  uploadLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '1',
    marginBottom: '8px',
  },
  uploadButton: {
    width: '100%',
    height: '66px',
    background: '#fafafa',
    marginBottom: '8px',
  },
}));

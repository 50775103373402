// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button } from '@material-ui/core';
import React, { FC, Children, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { SelectKit } from 'components/uiKit/SelectKit';
import usePlatform, { PlatformDataType, usePlatformType } from 'hooks/api/usePlatform';
import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import stylesSelect from 'components/uiKit/SelectKit/styles.module.scss';

import styles from '../../PurchaseRequestForm/Form/View/Claim/styles.module.scss';

import {
  distributionPlatformsType,
  getCurrencyList,
  getListDistributionPlatform,
  getValueCurrency,
  getValueDistributionPlatform,
  KeyCardsEnum,
} from './data';
import TemplateGrid from './TemplateGrid';
import useCurrentRequiredKeyOfCard from './useCurrentRequiredKeyOfCard';
import useErrors from './useErrors';

const plusSvg = require('assets/img/plus.svg').default;
const basketSvg = require('assets/img/basket.svg').default;

interface SwitchCustomSettingsType {
  style?: React.CSSProperties;
  data: distributionPlatformsType[];
  onChange?: (key, value, i) => void;
  callback?: () => void;
  deleteCallback?: (i: number) => void;
  itemKey?: KeyCardsEnum;
}
const DistributionPlatform: FC<SwitchCustomSettingsType> = ({
  style,
  data,
  onChange = () => {},
  callback = () => {},
  deleteCallback = () => {},
  itemKey,
}) => {
  const { errors } = useErrors();
  const { t } = useTranslation();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const { platformData, getDocuments }: usePlatformType = usePlatform();
  const { getParamsItem } = useCurrentRequiredKeyOfCard();

  useEffect(() => {
    getDocuments({
      endpoint: 'getPlatformsFactoring',
      resultKey: 'platformData',
      params: { show_deleted: false },
    });
  }, []);

  return (
    <div style={{ ...style, height: '100%', display: 'grid', gap: 40 }}>
      {Array.isArray(data) &&
        Children.toArray(
          data.map(
            (
              { purchase_price_percentage, platform_id, purchase_percentage, funding_limit, activation_date, currency },
              i,
            ) => (
              <div style={{ position: 'relative', border: '1px solid #CED0D7', borderRadius: 8, padding: 12 }}>
                <TemplateGrid>
                  <SelectKit
                    label={t('forms.activationOrder.distributionPlatform')}
                    options={getListDistributionPlatform(platformData)}
                    val={getValueDistributionPlatform(platform_id, platformData)?.name}
                    changeSelectItem={(_, value) => {
                      onChange(KeyCardsEnum.PLATFORM_ID, value.id, i);
                    }}
                    keyObj="name"
                    withAllOption={false}
                    required={getParamsItem(itemKey, KeyCardsEnum.PLATFORM_ID)?.required}
                    isError={!!errors[`${KeyCardsEnum.PLATFORM_ID}_${i}`]}
                    helperText={errors[`${KeyCardsEnum.PLATFORM_ID}_${i}`]}
                    wrapProps={{ height: 60 }}
                    customOption={(item: PlatformDataType, isActive, handleMouseDown) => (
                      <div
                        onMouseDown={handleMouseDown}
                        className={stylesSelect.menuSelectItemStyle}
                        style={{
                          display: 'grid',
                          gridAutoFlow: 'column',
                          justifyContent: 'start',
                          gridTemplateColumns: '20px 1fr',
                          gap: 15,
                          backgroundColor: !isActive ? '#F3F3F4' : undefined,
                        }}
                      >
                        <img src={item?.logo} alt="" style={{ width: 24 }} />
                        {item.name}
                      </div>
                    )}
                    startAdornment={
                      getValueDistributionPlatform(platform_id, platformData)?.logo ? (
                        <img
                          style={{
                            zIndex: 2,
                            width: 24,
                            height: 24,
                            marginTop: 25,
                            marginLeft: 18,
                          }}
                          className={styles.inputImg}
                          src={getValueDistributionPlatform(platform_id, platformData)?.logo}
                          alt={getValueDistributionPlatform(platform_id, platformData)?.name}
                        />
                      ) : undefined
                    }
                  />
                </TemplateGrid>
                <TemplateGrid>
                  <AmountKit
                    amount={purchase_percentage}
                    label={t('forms.activationOrder.purchasePercentage')}
                    onChange={(e) => {
                      onChange(KeyCardsEnum.PURCHASE_PERCENTAGE, e.target.value, i);
                    }}
                    required={getParamsItem(itemKey, KeyCardsEnum.PURCHASE_PERCENTAGE)?.required}
                    isError={!!errors[`${KeyCardsEnum.PURCHASE_PERCENTAGE}_${i}`]}
                    helperText={errors[`${KeyCardsEnum.PURCHASE_PERCENTAGE}_${i}`]}
                    wrapProps={{ height: 60 }}
                    fontSize={16}
                  />
                  <AmountKit
                    amount={purchase_price_percentage}
                    label={t('forms.activationOrder.purchasePricePercent')}
                    onChange={(e) => {
                      onChange(KeyCardsEnum.PURCHASE_PRICE_PERCENTAGE, e.target.value, i);
                    }}
                    required={getParamsItem(itemKey, KeyCardsEnum.PURCHASE_PRICE_PERCENTAGE)?.required}
                    isError={!!errors[`${KeyCardsEnum.PURCHASE_PRICE_PERCENTAGE}_${i}`]}
                    helperText={errors[`${KeyCardsEnum.PURCHASE_PRICE_PERCENTAGE}_${i}`]}
                    wrapProps={{ height: 60 }}
                    fontSize={16}
                  />
                </TemplateGrid>
                <TemplateGrid style={{ gridTemplateColumns: '1.3fr 2fr 2fr', marginBottom: 0 }}>
                  <SelectKit
                    label={t('forms.activationOrder.currency')}
                    options={getCurrencyList()}
                    keyObj="name"
                    val={getValueCurrency()[currency.toUpperCase()]}
                    changeSelectItem={(_, value) => {
                      onChange(KeyCardsEnum.CURRENCY, value?.key || '', i);
                    }}
                    required={getParamsItem(itemKey, KeyCardsEnum.CURRENCY)?.required}
                    isError={!!errors[`${KeyCardsEnum.CURRENCY}_${i}`]}
                    helperText={errors[`${KeyCardsEnum.CURRENCY}_${i}`]}
                    wrapProps={{ height: 60 }}
                  />
                  <AmountKit
                    amount={funding_limit || ''}
                    label={t('forms.activationOrder.fundingLimit')}
                    onChange={(e) => {
                      onChange(KeyCardsEnum.FUNDING_LIMIT, e.target.value || null, i);
                    }}
                    required={getParamsItem(itemKey, KeyCardsEnum.FUNDING_LIMIT)?.required}
                    currency={currency}
                    isError={!!errors[`${KeyCardsEnum.FUNDING_LIMIT}_${i}`]}
                    helperText={errors[`${KeyCardsEnum.FUNDING_LIMIT}_${i}`]}
                    wrapProps={{ height: 60 }}
                  />
                  <DateInput
                    isNotErrorLocal
                    date={activation_date}
                    labelKey={t('forms.activationOrder.activationDate')}
                    onChangeDateHandler={({ target: { value } }) => {
                      onChange(KeyCardsEnum.ACTIVATION_DATE, value, i);
                    }}
                    required={getParamsItem(itemKey, KeyCardsEnum.ACTIVATION_DATE)?.required}
                    commonStyle={{ height: 60 }}
                    isError={!!errors[`${KeyCardsEnum.ACTIVATION_DATE}_${i}`]}
                    errorText={errors[`${KeyCardsEnum.ACTIVATION_DATE}_${i}`]}
                  />
                </TemplateGrid>
                {data.length > 1 && (
                  <img
                    src={basketSvg}
                    alt="basket"
                    onClick={() => deleteCallback(i)}
                    style={{
                      alignSelf: 'center',
                      position: 'absolute',
                      padding: 10,
                      border: '1px solid #A7AFB7',
                      borderRadius: 8,
                      cursor: 'pointer',
                      right: -61,
                      top: 10,
                    }}
                  />
                )}
              </div>
            ),
          ),
        )}
      <Button
        onClick={callback}
        {...dropZoneStylingButton}
        style={{ width: 255, height: 40 }}
        startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
      >
        {t('forms.activationOrder.addDistributionPlatform')}
      </Button>
    </div>
  );
};

export default DistributionPlatform;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type editContractDataType = {
  id: string;
  companyName: string;
  isCustomPaymentDay: boolean;
  customPaymentDay?: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
  company_data: any;
  status?: string;
};

type editContractBodyType = {
  id: string;
  company_name: string;
  is_custom_payment_day: boolean;
  payment_day?: string;
};

export default ({
  id,
  companyName,
  customPaymentDay,
  isCustomPaymentDay,
  successCallback,
  errorCallback,
  company_data,
  resultKey = 'editContractData',
  status,
}: editContractDataType) => {
  const bodyObj: editContractBodyType = {
    id,
    company_name: companyName,
    is_custom_payment_day: isCustomPaymentDay,
    status,
    ...company_data,
  };

  if (customPaymentDay) {
    bodyObj.is_custom_payment_day = isCustomPaymentDay;
  }

  if (customPaymentDay) {
    bodyObj.payment_day = customPaymentDay;
  }

  return requestAsync({
    url: endpoints.getContractUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getContractUrl(id),
    body: bodyObj,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });
};

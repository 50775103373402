import React, { useEffect } from 'react';

import PurchaseRequest from 'components/forms/PurchaseRequestForm/PurchaseRequest';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const PurchaseRequestScreen = () => {
  useEffect(() => {
    updateTitle('Request details');
  }, []);

  return (
    <MainContent>
      <PurchaseRequest />
    </MainContent>
  );
};

export default PurchaseRequestScreen;

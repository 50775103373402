import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type contractsDataType = {
  id: string;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({ id, errorCallback, resultKey = 'contractsData' }: contractsDataType) =>
  requestAsync({
    url: endpoints.getContractsUrlV2(id),
    transform: ({ contracts = [] }) => ({
      [resultKey]: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        namesContracts: contracts.map((item) => ({
          name: `${item.name} ${item.number}`,
          id: item.id,
        })),
        contracts,
      },
    }),
    queryKey: endpoints.getContractsUrlV2(id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC } from 'react';

import styles from './styles.module.scss';

const minusSvg = require('./img/minus.svg').default;
const plusSvg = require('./img/plus.svg').default;

export interface SwitcherTextType {
  title: string;
  isOpen?: boolean;
  callback?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SwitcherText: FC<SwitcherTextType> = ({ title, isOpen, callback }) => (
  <button className={styles.switcherWrap} type="button" onClick={callback}>
    <img className={styles.switcherImg} src={isOpen ? minusSvg : plusSvg} alt="switch" />
    <span>{title}</span>
  </button>
);

import { FullNameSort, ShortNameSort } from 'types/Sort';

interface GetNextSortArgs {
  value: ShortNameSort | FullNameSort | null | '' | undefined;
  type?: 'short' | 'full';
  withEmptySort?: boolean;
}

export const getNextSort = ({ value, type = 'short', withEmptySort = true }: GetNextSortArgs) => {
  switch (value) {
    case FullNameSort.ASCEND:
      return FullNameSort.DESCEND;
    case ShortNameSort.ASC:
      return ShortNameSort.DESC;

    case FullNameSort.DESCEND:
      return withEmptySort ? null : FullNameSort.DESCEND;
    case ShortNameSort.DESC:
      return withEmptySort ? null : ShortNameSort.ASC;
    case null:
    case '':
    case undefined:
      return type === 'short' ? ShortNameSort.ASC : FullNameSort.ASCEND;
    default:
      break;
  }
};

export const removeUnderline = (value: string) => value.replaceAll('_', ' ');

export const firstLetterUpperCase = (value: string) => value[0].toUpperCase() + value.substring(1);

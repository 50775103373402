import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import useCompany from 'hooks/api/useCompany';
import useErrorCallback from 'hooks/useErrorCallback';
import useTransaction from 'hooks/api/useTransaction';
import { tableHeaderStyles } from 'theme/styles';
import { RefreshContext } from 'contexts/RefreshContext';
import { downloadFile } from 'helpers/file';
import useRouteTitle from 'hooks/useRouteTitle';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import { CompanyType } from 'types/CompanyTypes';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { filterCompanies } from 'helpers/companies';
import { firstDayOfMonth, firstDayOfNextMonth } from 'helpers/dt';
import { formatDateIsoShort, formatMonthYear } from 'helpers/formatters';
import { PivotTransactionFilters } from 'consts/tableFilters';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import CompanySelect from 'components/CompanySelect';
import { getCompanyById } from 'api/company';

const downloadSvg = require('assets/img/download.svg').default;

const getFirstDayOfMonth = (value: string) => formatDateIsoShort(firstDayOfMonth(new Date(value)));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PivotTransactionsHeader: FC | any = ({ requestParams }) => {
  const classes = tableHeaderStyles();
  const { t } = useTranslation();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);
  const { dateValidationByRange } = useFilterDateValidate();
  const {
    getPivotTransactionReports,
    exportPivotTransactionReportsData,
    pivotTransactionReportsData,
    pivotTransactionReportsTableParams,
    setPivotTransactionReportsTableParams,
    clearPivotTransactionReportsData,
  } = useTransaction();
  const { title } = useRouteTitle();
  const [downloadAccept, setDownloadAccept] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyType>({ name: '', id: '', active: true });
  const [errors, setErrors] = useState({
    report_date: { status: false, text: `${t('dateErrors.dateMax')} ${formatMonthYear(new Date())}` },
  });

  useMemo(() => {
    if (exportPivotTransactionReportsData.bs64_data && downloadAccept) {
      const contentType = 'application/vnd.ms-excel';
      const base64FileData = exportPivotTransactionReportsData.bs64_data;
      downloadFile(base64FileData, contentType, 'PivotTransaction.xlsx');
      setDownloadAccept(false);
    }
  }, [exportPivotTransactionReportsData, downloadAccept]);

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'pivotTransactionReportsTableParams',
      params: { ...pivotTransactionReportsTableParams, ...requestParams },
    });
    setPivotTransactionReportsTableParams({ ...pivotTransactionReportsTableParams, ...requestParams });
    const validated = dateValidationByRange(newParams?.report_date, undefined, firstDayOfNextMonth(new Date()));

    if (validated) {
      setValidate(true);

      return;
    }
    setErrors(({ report_date }) => ({
      report_date: { ...report_date, status: true },
    }));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (company && company.id !== pivotTransactionReportsTableParams.company_id) {
        const currentCompany = await getCompanyById(pivotTransactionReportsTableParams.company_id);

        if (currentCompany) {
          setCompany(currentCompany as CompanyType);
        } else {
          setCompany({ name: '', id: '', active: true });
        }
      }
    };

    loadData();
  }, [company, pivotTransactionReportsTableParams]);

  useEffect(() => {
    refreshContext.setParams({
      datakey: 'pivotTransactionReportsTableParams',
      params: { pivotTransactionReportsTableParams },
    });
    setUrlStringParams({
      filters: PivotTransactionFilters,
      currentScreenParams: pivotTransactionReportsTableParams,
    });

    if (validate) {
      getPivotTransactionReports({
        params: pivotTransactionReportsTableParams,
        errorCallback,
      });
    }
  }, [getPivotTransactionReports, pivotTransactionReportsTableParams, validate]);

  const onChangeCompanyHandler = useCallback(
    (values: CompanyType | null) => {
      const newParams = {
        ...pivotTransactionReportsTableParams,
        company_id: values ? values.id : '',
        company: values,
      };
      setPivotTransactionReportsTableParams(newParams);
    },
    [setPivotTransactionReportsTableParams, pivotTransactionReportsTableParams],
  );

  const onChangeReportDateHandler = useCallback(
    ({ target: { name, value } }) => {
      const newParams = { ...pivotTransactionReportsTableParams, [name]: getFirstDayOfMonth(value) };
      const validated = dateValidationByRange(value, undefined, firstDayOfNextMonth(new Date()));
      setValidate(validated);

      if (validated) {
        setErrors(({ report_date }) => ({
          report_date: { ...report_date, status: false },
        }));
      } else {
        clearPivotTransactionReportsData();
        setErrors(({ report_date }) => ({
          report_date: { ...report_date, status: true },
        }));
      }
      setPivotTransactionReportsTableParams(newParams);
    },
    [
      setPivotTransactionReportsTableParams,
      pivotTransactionReportsTableParams,
      dateValidationByRange,
      clearPivotTransactionReportsData,
    ],
  );

  const exportHandler = useCallback(() => {
    getPivotTransactionReports({
      params: { ...pivotTransactionReportsTableParams, export: true },
      resultKey: 'exportPivotTransactionReportsData',
    });
    setDownloadAccept(true);
  }, [pivotTransactionReportsTableParams, getPivotTransactionReports]);

  return (
    <div>
      <div className={classes.title}>{title}</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr' }}>
        <div className={classes.leftBlockWrapper}>
          <DateInput
            disableFutureMonth
            disableFutureYears
            type={DatePikerEnum.WITHOUT}
            date={pivotTransactionReportsTableParams.report_date}
            onChangeDateHandler={onChangeReportDateHandler}
            name="report_date"
            commonStyle={{ width: 170, height: 40, marginRight: 20 }}
            errorText={errors.report_date.text}
            isError={errors.report_date.status}
          />
          <div style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}>
            <CompanySelect
              label={t('contentHeader.companyName')}
              value={pivotTransactionReportsTableParams.company_id}
              onChange={(_, value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChangeCompanyHandler(value?.source || '');
              }}
              allowClear
            />
          </div>
        </div>
        <div>
          <Button
            variant="text"
            style={{ minWidth: 40, height: 40, justifySelf: 'end', border: '1px solid #A7AFB7' }}
            onClick={exportHandler}
            disabled={!pivotTransactionReportsData.length}
          >
            <img src={downloadSvg} alt="download" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PivotTransactionsHeader;

import i18next from 'i18next';

import { PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';
import { CurrencyType } from 'components/uiKit/InputKit';
import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

export const MAP_TYPE_TO_INITIAL_VALUE: Partial<Record<PurchaseRequestStatus, TypeAllValues>> = {
  [PurchaseRequestStatus.REJECTED]: {
    comment: '',
  },
  [PurchaseRequestStatus.SIGNED]: {
    file_data: '',
  },
  [PurchaseRequestStatus.FUNDED]: {
    tx_date: '',
    currency: '',
    amount: '',
    checkbox: true,
    recipient: '',
  },
  [PurchaseRequestStatus.REPAID]: {
    repayment_date: '',
  },
};

export type TypeAllValues = {
  comment?: string;
  file_data?: string;
  repayment_date?: string;
  currency?: CurrencyType | '';
  amount?: string;
  tx_date?: string;
  checkbox?: boolean;
  recipient?: string | object;
};

export const MAP_TYPE_TO_BUTTON_SUBMIT_TEXT: Partial<Record<PurchaseRequestStatus, string>> = {
  [PurchaseRequestStatus.REJECTED]: 'forms.purchaseRequest.changeStatusModal.rejected.submitButton',
  [PurchaseRequestStatus.SIGNED]: 'forms.purchaseRequest.changeStatusModal.signed.submitButton',
  [PurchaseRequestStatus.FUNDED]: 'forms.purchaseRequest.changeStatusModal.funded.submitButton',
  [PurchaseRequestStatus.REPAID]: 'forms.purchaseRequest.changeStatusModal.repaid.submitButton',
};

export const getCurrencyList = () => [
  { key: KeyCardsEnum.EUR, name: i18next.t('selectFiledValues.eur') },
  { key: KeyCardsEnum.USD, name: i18next.t('selectFiledValues.usd') },
];

export const getValueCurrency = () => ({
  [KeyCardsEnum.EUR]: getCurrencyList()[0],
  [KeyCardsEnum.USD]: getCurrencyList()[1],
});

import { useTranslation } from 'react-i18next';

import { CompanyInfoTabPanelGroupDataType } from 'types/SettingsTabTypes';
import { emailValidator, phoneValidator } from 'helpers/validators';

export default (data: CompanyInfoTabPanelGroupDataType) => {
  const { t } = useTranslation();

  const {
    legal_name,
    company_registration,
    tax_number,
    address,
    director_full_name,
    director_title,
    representative_full_name,
    representative_title,
    representative_email,
    representative_phone_number,
    beneficiary_usd,
    beneficiary_bank_usd,
    bank_usd,
    swift_usd,
    iban_usd,
    bank_address_usd,
    beneficiary_eur,
    bank_eur,
    swift_eur,
    iban_eur,
    bank_address_eur,
  } = { ...data };

  const errors = {
    isFormValid: true,
    legal_name: { isError: false, errorText: t('errors.required') },
    company_registration: { isError: false, errorText: t('errors.required') },
    tax_number: { isError: false, errorText: t('errors.required') },
    address: { isError: false, errorText: t('errors.required') },
    director_full_name: { isError: false, errorText: t('errors.required') },
    director_title: { isError: false, errorText: t('errors.required') },
    representative_full_name: { isError: false, errorText: t('errors.required') },
    representative_title: { isError: false, errorText: t('errors.required') },
    representative_email: {
      isError: false,
      errorText: representative_email ? t('errors.emailNotValid') : t('errors.required'),
    },
    representative_phone_number: {
      isError: false,
      errorText: representative_phone_number ? t('errors.phoneNotValid') : t('errors.required'),
    },
    beneficiary_usd: { isError: false, errorText: t('errors.required') },
    bank_usd: { isError: false, errorText: t('errors.required') },
    swift_usd: { isError: false, errorText: t('errors.required') },
    iban_usd: { isError: false, errorText: t('errors.required') },
    bank_address_usd: { isError: false, errorText: t('errors.required') },
    beneficiary_bank_usd: { isError: false, errorText: t('errors.required') },
    reference_usd: { isError: false, errorText: t('errors.required') },
    beneficiary_eur: { isError: false, errorText: t('errors.required') },
    bank_eur: { isError: false, errorText: t('errors.required') },
    swift_eur: { isError: false, errorText: t('errors.required') },
    iban_eur: { isError: false, errorText: t('errors.required') },
    bank_address_eur: { isError: false, errorText: t('errors.required') },
  };

  if (legal_name === '' || legal_name === null) {
    errors.isFormValid = false;
    errors.legal_name.isError = true;
  }

  if (company_registration === '' || company_registration === null) {
    errors.isFormValid = false;
    errors.company_registration.isError = true;
  }

  if (tax_number === '' || tax_number === null) {
    errors.isFormValid = false;
    errors.tax_number.isError = true;
  }

  if (address === '' || address === null) {
    errors.isFormValid = false;
    errors.address.isError = true;
  }

  if (director_full_name === '' || director_full_name === null) {
    errors.isFormValid = false;
    errors.director_full_name.isError = true;
  }

  if (director_title === '' || director_title === null) {
    errors.isFormValid = false;
    errors.director_title.isError = true;
  }

  if (representative_full_name === '' || representative_full_name === null) {
    errors.isFormValid = false;
    errors.representative_full_name.isError = true;
  }

  if (representative_title === '' || representative_title === null) {
    errors.isFormValid = false;
    errors.representative_title.isError = true;
  }

  if (!emailValidator(representative_email)) {
    errors.isFormValid = false;
    errors.representative_email.isError = true;
  }

  if (
    !phoneValidator(representative_phone_number) &&
    representative_phone_number !== '' &&
    representative_phone_number !== null
  ) {
    errors.isFormValid = false;
    errors.representative_phone_number.isError = true;
  }

  if (bank_usd === '' || bank_usd === null) {
    errors.isFormValid = false;
    errors.bank_usd.isError = true;
  }

  if (swift_usd === '' || swift_usd === null) {
    errors.isFormValid = false;
    errors.swift_usd.isError = true;
  }

  if (iban_usd === '' || iban_usd === null) {
    errors.isFormValid = false;
    errors.iban_usd.isError = true;
  }

  if (beneficiary_usd === '' || beneficiary_usd === null) {
    errors.isFormValid = false;
    errors.beneficiary_usd.isError = true;
  }

  if (bank_eur === '' || bank_eur === null) {
    errors.isFormValid = false;
    errors.bank_eur.isError = true;
  }

  if (swift_eur === '' || swift_eur === null) {
    errors.isFormValid = false;
    errors.swift_eur.isError = true;
  }

  if (iban_eur === '' || iban_eur === null) {
    errors.isFormValid = false;
    errors.iban_eur.isError = true;
  }

  if (beneficiary_eur === '' || beneficiary_eur === null) {
    errors.isFormValid = false;
    errors.beneficiary_eur.isError = true;
  }

  if (bank_address_usd === '' || bank_address_usd === null) {
    errors.isFormValid = false;
    errors.bank_address_usd.isError = true;
  }

  if (bank_address_eur === '' || bank_address_eur === null) {
    errors.isFormValid = false;
    errors.bank_address_eur.isError = true;
  }

  if (beneficiary_bank_usd === '' || beneficiary_bank_usd === null) {
    errors.isFormValid = false;
    errors.beneficiary_bank_usd.isError = true;
  }

  return { errors };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import { getCurrencyWithCode, getYYYYMMDD, formatAmount } from 'helpers/formatters';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';

import { setInitialFilterType } from './ConstructorTransForm';

interface RelatedTransactionsType {
  setInitialFilter: setInitialFilterType;
}
const AvailableBalance: FC<RelatedTransactionsType> = () => {
  const { transactionsFormData, getCurrencyRate, setTransactionFormData }: useTransactionType = useTransaction();
  const { accountsByIdCompany, getAccountsByIdCompany }: useCompanyType = useCompany();
  const [localError, setLocalError] = useState(false);
  const { addErrors } = useErrors();
  const { t } = useTranslation();

  useEffect(() => {
    if (transactionsFormData.value_datetime) {
      const date = new Date(transactionsFormData.value_datetime);

      if (date.toDateString() === 'Invalid Date') return;
      date.setDate(date.getDate() - 1);
      getCurrencyRate({
        params: { on_date: getYYYYMMDD(date) },
        successCallback: (res) => {
          setTransactionFormData({ [KeyTransactions.RATE_ECB]: (res?.rate || 0).toFixed(4) });
        },
      });
    }
  }, [transactionsFormData.value_datetime]);

  const recipientCompanyId =
    ['outgoing-transit', 'outgoing-balance-payable'].includes(transactionsFormData?.category) &&
    transactionsFormData?.checkbox &&
    transactionsFormData?.factoring_company_id
      ? transactionsFormData?.factoring_company_id
      : transactionsFormData?.recipient?.company_id;

  useEffect(() => {
    if (recipientCompanyId) {
      const date = new Date(transactionsFormData.value_datetime);
      // NOTE: чтобы расчеты делалились по 23:59:59 от date, нужно передать апишке следующую за этим днем дату
      date.setDate(date.getDate() + 1);
      if (isNaN(date.getTime()) === false) {
        const balanceDate = getYYYYMMDD(date);

        getAccountsByIdCompany({
          company_id: recipientCompanyId,
          balanceDate,
        });
      }
    }
  }, [transactionsFormData.value_datetime, recipientCompanyId]);

  const balanceData = accountsByIdCompany.find(
    (item) =>
      Number(item.currency) === Number(transactionsFormData.currency) &&
      recipientCompanyId &&
      item.type === 'counterparty-client',
  );

  const balance = balanceData
    ? getCurrencyWithCode(Math.abs(Number(balanceData.currency))) + formatAmount(balanceData.balance)
    : '0,00';

  useEffect(() => {
    if (transactionsFormData?.amount > (balanceData?.balance || 0)) {
      addErrors({ [KeyTransactions.AMOUNT]: 'Amount is exceeding Available Balance' });
      setLocalError(true);

      return;
    }
    setLocalError(false);
    addErrors({ [KeyTransactions.AMOUNT]: '' });
  }, [transactionsFormData.amount, balanceData?.balance]);

  return (
    <div
      style={{
        backgroundColor: localError ? '#FFF1F1' : '#ECF0F5',
        borderRadius: 8,
        height: 48,
        padding: '0px 18px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        lineHeight: '24px',
      }}
    >
      <div>
        <span style={{ color: localError ? '#EC3E72' : '#707A87' }}>
          {`${t('forms.transaction.availableBalance')}: `}
        </span>
        <span style={{ color: localError ? '#EC3E72' : '#29292C' }}>
          {balanceData?.balance < 0 ? '-' : ''}
          {balance}
        </span>
      </div>
    </div>
  );
};
export default AvailableBalance;

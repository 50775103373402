import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, FormHelperText, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AutocompletedInputPropsType } from 'types/TransactionFormTypes';

export const AutocompletedInput = ({
  error,
  list,
  onChangeHandler,
  onBlurHandler,
  disabled = false,
  dataTip = '',
  defaultValue,
  label,
  inputWidth = undefined,
}: AutocompletedInputPropsType) => {
  const useStyle = makeStyles(() => ({
    autocompleteInput: {
      width: inputWidth ? `${inputWidth}px` : '257px',
      marginRight: '16px!important',
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      backgroundColor: '#eef2f7 !important',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      '& *:before': {
        borderColor: 'white !important',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#eef2f7',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#eef2f7',
      },
    },
  }));
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <FormControl error={error}>
      <Autocomplete
        key={defaultValue.id}
        id="combo-box-demo"
        className={classes.autocompleteInput}
        options={list}
        data-tip={dataTip}
        getOptionLabel={(option) => (option ? option.name : '')}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            variant="filled"
            required
            className={classes.autocompleteInput}
            label={label}
          />
        )}
      />
      <FormHelperText id="component-error-text" style={{ visibility: !error ? 'hidden' : 'visible' }}>
        {t('auth.required')}
      </FormHelperText>
    </FormControl>
  );
};

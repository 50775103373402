// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { downloadFile } from 'helpers/file';
import useCompany from 'hooks/api/useCompany';
import useErrorCallback from 'hooks/useErrorCallback';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import useContract, { useContractType } from 'hooks/api/useContract';
import Button from 'components/uiKit/Button';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import Title from 'components/common/Title';
import DateInput from 'components/inputs/DateInput';
import CompanySelect from 'components/CompanySelect';

import styles from './styles.module.scss';

const INITIAL_ERRORS = { date_from: { status: false, text: '' }, date_to: { status: false, text: '' } };

const FORMAT = [
  { value: 'pdf', label: 'PDF' },
  { value: 'docx', label: 'DOC' },
];

const Reconciliation = () => {
  const { t } = useTranslation();
  const { dateValidator } = useFilterDateValidate();
  const { getAlert } = useErrorCallback();
  const [formData, setFormData] = useState({
    company: '',
    contract: '',
    date_from: '',
    date_to: '',
    format: '',
  });

  const {
    contractsData: { namesContracts = [] },
    getContracts,
  }: useContractType = useContract();
  const [error, setError] = useState(INITIAL_ERRORS);

  const onChangeFormData = (key: string, value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmitButtonClick = async () => {
    const { company, contract, date_from, date_to, format } = formData;
    const res = await fetch(
      endpoints.getReconciliationReport({
        id: company,
        start_report_date: date_from,
        end_report_date: date_to,
        document_id: contract,
        file_format: format,
      }),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    const { report_file, report_title } = await res.json();

    if (report_file && res.status === 200) {
      const contentType =
        format === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      downloadFile(report_file, contentType, report_title);

      return;
    }
    getAlert('error', res.statusText || 'Internal server error');
  };

  const checkValidateDate = (value: string, key: 'date_from' | 'date_to') => {
    const { validatedErrors } = dateValidator({
      name: key === 'date_from' ? 'date_from' : 'date_to',
      value,
      filterParams: formData,
    });

    if (validatedErrors[key].status) {
      setError((prevError) => ({ ...prevError, [key]: validatedErrors[key] }));

      return;
    }
    setError(INITIAL_ERRORS);
  };

  const dateInput = (key: 'date_from' | 'date_to') => (
    <DateInput
      isNotErrorLocal
      date={formData[key]}
      labelKey={`reports.reconciliation.${key}`}
      onChangeDateHandler={({ target: { value } }) => {
        checkValidateDate(value, key);
        setFormData((prev) => ({ ...prev, [key]: value }));
      }}
      required
      stylingLabel={{ color: '#838998', paddingLeft: 8 }}
      inputStyle={{ paddingLeft: 17 }}
      isError={error[key].status}
      errorText={error[key].text}
    />
  );

  useEffect(() => {
    if (formData.company) getContracts({ id: formData.company });
  }, [formData.company]);

  return (
    <div className={styles.wrapper}>
      <Title text={t('menu.reconciliationReport')} />
      <main className={styles.main}>
        <CompanySelect
          label={t('reports.reconciliation.client')}
          required
          value={formData.company}
          onChange={(id) => {
            onChangeFormData('contract', '');
            onChangeFormData('company', id);
          }}
          size="large"
        />
        <DropdownKit
          options={namesContracts}
          keyObj="name"
          value={namesContracts.find(({ id }) => formData.contract === id)}
          positionType={DropdownSizeEnum.SIZE_60}
          changeSelectItem={(_, data) => onChangeFormData('contract', data.id)}
          label={t('reports.reconciliation.agreement')}
          required
        />
        <div className={styles.date_container}>
          {dateInput('date_from')}
          {dateInput('date_to')}
          <DropdownKit
            options={FORMAT}
            positionType={DropdownSizeEnum.SIZE_60}
            keyObj="label"
            value={FORMAT.find(({ value }) => formData.format === value)}
            changeSelectItem={(_, data) => onChangeFormData('format', data.value)}
            label={t('reports.reconciliation.format')}
            required
          />
        </div>
      </main>
      <div style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'start', gap: 20 }}>
        <Button
          style={{ height: 40 }}
          gutters={[0, 20]}
          variant="fill"
          color="gray"
          disabled={Object.values(formData).some((value) => !value)}
          onClick={handleSubmitButtonClick}
        >
          {t('reports.reconciliation.generateReport')}
        </Button>
      </div>
    </div>
  );
};

export default Reconciliation;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';

import { currencyChoicesAutocomplete, purchaseRequestStatus } from 'consts/dict';
import { PurchaseFilters } from 'consts/tableFilters';
import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import useActivationOrder from 'hooks/api/useActivationOrder';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import Select from 'components/uiKit/Select';
import CompanySelect from 'components/CompanySelect';

import styles from './styles.module.scss';

const currencyOptions = currencyChoicesAutocomplete.filter(({ id }) => id !== 'RUB');

const Filters = () => {
  const { t } = useTranslation();
  const { purchaseRequestListForFilter = [], purchaseRequestParams } = usePurchaseRequest();
  const { activationOrderListData = [] } = useActivationOrder();

  const setValueByKey = (key: string, value: string) => {
    const newParams =
      key === 'company_id'
        ? { ...purchaseRequestParams, [key]: value || '', request_number: '', order_id: '', page: 0 }
        : { ...purchaseRequestParams, [key]: value, page: 0 };
    setUrlStringParams({
      filters: PurchaseFilters,
      currentScreenParams: newParams,
    });
  };

  return (
    <div className={styles.wrapper}>
      <CompanySelect
        label={t('tables.purchaseRequest.filters.client')}
        onChange={(id) => setValueByKey('company_id', id)}
        value={purchaseRequestParams.company_id || undefined}
        width="320px"
        allowClear
      />
      {purchaseRequestParams.company_id && (
        <>
          <DropdownKit
            label={t('tables.purchaseRequest.filters.ao')}
            options={activationOrderListData}
            value={activationOrderListData.find(({ id }) => purchaseRequestParams.order_id === id)}
            changeSelectItem={(_, data) => {
              setValueByKey('order_id', data.id);
            }}
            keyObj="name"
            positionType={DropdownSizeEnum.SIZE_40}
            wrapStyle={{ width: '112px' }}
          />
          <DropdownKit
            label={t('tables.purchaseRequest.filters.request')}
            options={purchaseRequestListForFilter}
            value={purchaseRequestParams.request_number}
            changeSelectItem={(_, data) => {
              setValueByKey('request_number', String(data));
            }}
            positionType={DropdownSizeEnum.SIZE_40}
            wrapStyle={{ width: '112px' }}
          />
        </>
      )}
      <Select
        label={t('tables.purchaseRequest.filters.currency')}
        options={currencyOptions.map((e) => ({ key: e.id, value: e.name }))}
        value={purchaseRequestParams.currency}
        onChange={(key) => {
          setValueByKey('currency', key);
        }}
        width="112px"
      />
      <Select
        label={t('tables.purchaseRequest.filters.status')}
        options={purchaseRequestStatus.map((e) => ({ key: e.id, value: e.name }))}
        value={purchaseRequestParams.status}
        onChange={(key) => {
          setValueByKey('status', key);
        }}
        width="200px"
      />
    </div>
  );
};

export default Filters;

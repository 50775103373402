import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type activationOrderKeyDataType = {
  id: string;
  type?: string;
  errorCallback?: Function;
  getterIndex?: number;
  resultKey?: string;
};

export default ({
  id,
  type,
  getterIndex,
  errorCallback,
  resultKey = 'activationOrderData',
}: activationOrderKeyDataType) =>
  requestAsync({
    url: endpoints.getActivationOrderUrl(id, type),
    transform: (response) => ({
      [resultKey]: {
        companyId: response.company_id,
        purchaseDate: response.purchase_date,
        repaymentDate: response.repayment_date,
        contractNumber: response.contract_number,
        contractDate: response.contract_date,
        isCustomPaymentDay: response.is_custom_payment_day,
        getterIndex,
        ...response,
      },
    }),
    queryKey: `${endpoints.getActivationOrderUrl(id)}`,
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Input, Button, AddIcon, TrashIcon } from '@gamesb42/ui-kit';
import { Form } from 'antd';
import classNames from 'classnames';

import { CompanyKeyEnum } from 'types/CompanyTypes';

import styles from './styles.module.scss';

const Contact: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item name={CompanyKeyEnum.SIGNER_ID} hidden>
        <Input label={t('systemSetting.signerEmail')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.SIGNER_FULL_NAME}
        rules={[{ required: true, message: 'Please input signer full name!' }]}
      >
        <Input label={t('systemSetting.signerFullName')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.SIGNER_POSITION}
        rules={[{ required: true, message: 'Please input signer position!' }]}
      >
        <Input label={t('systemSetting.signerPosition')} size="large" />
      </Form.Item>
      <Form.Item
        name={CompanyKeyEnum.SIGNER_EMAIL}
        rules={[
          { required: true, message: 'Please input signer email!' },
          { type: 'email', message: 'Please input correct email!' },
        ]}
      >
        <Input label={t('systemSetting.signerEmail')} size="large" />
      </Form.Item>
      <FormLayout>
        <Form.Item
          name={CompanyKeyEnum.SIGNER_PHONE}
          rules={[
            { required: true, message: 'Please input contact phone!' },
            { pattern: /^[\+]?[\d]{7,}?/, message: 'Please input phone number!' },
          ]}
          className={styles.signer}
        >
          <Input label={t('systemSetting.signerPhone')} size="large" />
        </Form.Item>
        <div />
      </FormLayout>
      <Form.List name={CompanyKeyEnum.CONTACTS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div className={classNames(fields.length - 1 !== index && styles.contact)} key={key}>
                <div className={styles.fullNameWrapper}>
                  <Form.Item
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...restField}
                    name={[name, CompanyKeyEnum.FULL_NAME]}
                    rules={[{ required: true, message: 'Please input full name!' }]}
                  >
                    <Input label={t('company.contactPersonFullName')} size="large" />
                  </Form.Item>
                  {fields.length > 1 && (
                    <Button
                      icon={<TrashIcon size={16} />}
                      type="primary"
                      onClick={() => remove(name)}
                      className={styles.remove}
                    />
                  )}
                </div>
                <Form.Item
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...restField}
                  name={[name, CompanyKeyEnum.POSITION]}
                  rules={[{ required: true, message: 'Please input position!' }]}
                >
                  <Input label={t('company.contactPersonPosition')} size="large" />
                </Form.Item>
                <Form.Item
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...restField}
                  name={[name, CompanyKeyEnum.EMAIL]}
                  rules={[
                    { required: true, message: 'Please input email!' },
                    { type: 'email', message: 'Please input correct email!' },
                  ]}
                >
                  <Input label={t('company.contactPersonEmail')} size="large" />
                </Form.Item>
                <FormLayout>
                  <Form.Item
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...restField}
                    name={[name, CompanyKeyEnum.CONTACT_PHONE]}
                    rules={[
                      { required: true, message: 'Please input contact phone!' },
                      { pattern: /^[\+]?[\d]{7,}?/, message: 'Please input phone number!' },
                    ]}
                  >
                    <Input label={t('company.contactPersonPhoneNumber')} size="large" />
                  </Form.Item>
                  <div />
                </FormLayout>
              </div>
            ))}
            <Form.Item>
              <Button onClick={() => add()} icon={<AddIcon size={16} />}>
                Add contact
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default Contact;

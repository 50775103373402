import * as React from 'react';

import TransactionForm from 'components/forms/TransactionForm';
import ContentWrapper from 'components/ContentWrapper';

const AdjustingTransactionEditScreen = () => (
  <ContentWrapper>
    <TransactionForm editType transactionType="adjusting" />
  </ContentWrapper>
);

export default AdjustingTransactionEditScreen;

//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { Table } from '@gamesb42/ui-kit';

import s from './styles.module.scss';

export function AppGameInfo({ rows, getOnboardingData, mapOnboardingData }) {
  const [renderrows, setRenderRows] = useState(rows);
  console.log({ rows });

  useEffect(() => {
    if (rows.length === 0) {
      // console.log('call getdata');
      getOnboardingData()
        .then((data: any) => setRenderRows(mapOnboardingData(data)))
        .catch(() => {});
    }
  }, []);

  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'value',
    },
  ];

  return (
    <div className={s.wrapper}>
      <Table className={s.table} columns={columns} dataSource={renderrows} showHeader={false} scroll={{ y: 356 }} />
    </div>
  );
}

import React, { FC, useState, createContext, useContext, useEffect } from 'react';

import { getMe, setActiveCompany } from 'api/users';
import urls from 'constants/urls';
import {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  setUser as setLocalStorageUser,
  getUser as getLocalStorageUser,
} from 'helpers/token';
import { User, UserKeyEnum } from 'types/UserTypes';
import baseRequest from 'queries/baseRequest';
import { getFactorCompany } from 'api/company';

interface UserContextValues {
  user: null | User;
  logOut: () => void;
  logIn: (successCallback?: () => void) => void;
  updateUser: () => Promise<void>;
}

export const clearSession = (path?: string) => {
  localStorage.clear();
  window.location.href = `${window.location.origin}${path || urls.getAuth()}`;
};

const UserContext = createContext<UserContextValues>({
  user: null,
  updateUser: () => Promise.resolve(),
  logIn: () => {},
  logOut: () => {},
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const updateUser = async () => {
    await Promise.allSettled([getMe(), getFactorCompany()])
      .then(([userResult, factorResult]) => {
        if (userResult.status === 'fulfilled') {
          setUser(userResult.value);
          setLocalStorageUser(JSON.stringify(userResult.value));
        }

        const userOtherCompanies =
          (userResult.status === 'fulfilled' &&
            userResult.value[UserKeyEnum.OTHER_COMPANIES].map(({ company: { id = undefined } = { id: '' } }) => id)) ||
          [];

        if (
          factorResult.status === 'fulfilled' &&
          userResult.status === 'fulfilled' &&
          factorResult.value.id !== userResult.value.company.id &&
          userOtherCompanies.includes(factorResult.value.id)
        ) {
          return setActiveCompany(userResult.value.id, factorResult.value.id).then(() => {
            return updateUser();
          });
        }
      })
      .catch(console.error);
  };

  const logIn: UserContextValues['logIn'] = async (successCallback) => {
    if (getToken()) {
      await updateUser();
      successCallback?.();
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (!urlParams.get('access_token')) {
      window.location.href = `${process.env.REACT_APP_SCA_URL}/v1/auth/login?redirect_uri=${window.location.origin}${window.location.pathname}`;
      return;
    } else {
      setToken(urlParams.get('access_token') as string);
      setRefreshToken(urlParams.get('refresh_token') as string);
      await updateUser();
      successCallback?.();
    }
  };

  const logOut = async () => {
    await baseRequest({
      path: `${process.env.REACT_APP_SCA_URL}/v1/auth/logout`,
      options: {
        method: 'POST',
        body: {
          refresh_token: getRefreshToken(),
        },
      },
    }).catch(console.error);

    clearSession();
  };

  const values = {
    user,
    logIn,
    logOut,
    updateUser,
  };

  useEffect(() => {
    // baseRequest({
    //   path: 'https://dev.api.gamesboost42.com/sca/v1/users/79376fa8-cdd2-4cbb-ba70-9c3a2e298814/companies?company-id=2df533a0-6f77-46d3-b047-ae7cb85143ff&role=factor-admin',
    //   options: {
    //     method: 'POST',
    //   },
    // });

    const localStorageUser = getLocalStorageUser();

    if (localStorageUser) {
      setUser(JSON.parse(localStorageUser));
    }
  }, []);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

import React, { FC } from 'react';

import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

import { paramsObjAO } from '../../ActivationOrderForm';
import ItemsAo from '../ItemsAo';

interface CardFutureReceivablesType {
  params?: paramsObjAO;
}

const CardFutureReceivables: FC<CardFutureReceivablesType> = React.memo(() => (
  <>
    <ItemsAo types={[KeyCardsEnum.AO_TYPE, KeyCardsEnum.SIGN_DATE]} />
    <ItemsAo types={[KeyCardsEnum.COMPANY_ID]} />
    <ItemsAo types={[KeyCardsEnum.CONTRACT_ID]} />
    <ItemsAo types={[KeyCardsEnum.NAME, KeyCardsEnum.PENALTY_POLICY]} />
    <ItemsAo types={[KeyCardsEnum.DROPZONE]} />
    <ItemsAo types={[KeyCardsEnum.COMMENT]} />
    <ItemsAo types={[KeyCardsEnum.CURRENCY, KeyCardsEnum.AMOUNT]} />
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <ItemsAo types={[KeyCardsEnum.WITHHOLD_PERCENTAGE, KeyCardsEnum.PURCHASED_RECEIVABLES]} />
    <ItemsAo types={[KeyCardsEnum.PAYMENT_PLAN]} />
  </>
));

export default CardFutureReceivables;

/* eslint-disable camelcase */
/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import useCompany from 'hooks/api/useCompany';
import useCommissionReport from 'hooks/api/useCommissionReport';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import { tableHeaderStyles } from 'theme/styles';
import useRouteTitle from 'hooks/useRouteTitle';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import { CompanyType } from 'types/CompanyTypes';
import { autocompleteAOData, autocompleteContractData } from 'helpers/transformDataForAutocomplete';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { CommissionReportFilter } from 'consts/tableFilters';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';
import useContracts from 'hooks/api/useContracts';
import { currencyChoicesAutocomplete } from 'consts/dict';
import { statusCommissionReportAutocomplete } from 'components/tables/CommissionReportTable/data';
import useActivationOrder from 'hooks/api/useActivationOrder';
import Select from 'components/uiKit/Select';

import { DropdownKit, DropdownSizeEnum } from '../../uiKit/DropdownKit';
import useAlert from '../../../hooks/api/useAlert';
import CompanySelect from 'components/CompanySelect';
import { getCompanyById } from 'api/company';

const CommissionReportHeader: FC | any = ({ requestParams }) => {
  const location = useLocation();
  const classes = tableHeaderStyles();
  const inputClasses = useStyles();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { dateValidationByRange } = useFilterDateValidate();
  const {
    companiesData: { companiesData = [] },
    getCompanies,
  } = useCompany();
  const { contractsData, getDocumentsByIdV2 } = useContracts();
  const { contracts } = useMemo(() => contractsData, [contractsData]);
  const [contract, setContract] = useState<CompanyType>({ name: '', id: '', active: true });
  const { activationOrderListData, getActivationOrderList } = useActivationOrder();
  const [activationOrder, setActivationOrder] = useState<CompanyType>({ name: '', id: '', active: true });
  const {
    getCommissionReports,
    commissionReportTableParams,
    setCommissionReportTableParams,
    clearCommissionReportsData,
  } = useCommissionReport();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);
  const [company, setCompany] = useState<CompanyType>({ name: '', id: '', active: true });
  const [currentCurrency, setCurrency] = useState<CompanyType>({ name: '', id: '', active: true });
  const [currentStatus, setStatus] = useState<CompanyType>({ name: '', id: '', active: true });
  const [dateError, setDateError] = useState({ status: false, text: '' });
  const [validate, setValidate] = useState<boolean>(false);
  const [urlFiltersIsSet, setUrlFiltersIsSet] = useState<boolean>(false);

  useEffect(() => {
    getCompanies({ errorCallback });
  }, [getCompanies, errorCallback]);

  useEffect(() => {
    const loadData = async () => {
      const { company_id, currency, status } = commissionReportTableParams;

      if (company && company.id !== company_id) {
        const currentCompany = await getCompanyById(company_id);

        if (currentCompany) {
          setCompany(currentCompany);
        } else {
          setCompany({ name: '', id: '', active: true });
        }
      }

      if (currency) {
        const currentFindCurrency = currencyChoicesAutocomplete.find(
          (item) => item.id === commissionReportTableParams.currency,
        );
        setCurrency(currentFindCurrency);
      } else {
        setCurrency({ name: '', id: '', active: true });
      }

      if (status) {
        const currentFindStatus = statusCommissionReportAutocomplete.find(
          (item) => item.id === commissionReportTableParams.status,
        );
        setStatus(currentFindStatus);
      } else {
        setStatus({ name: '', id: '', active: true });
      }
    };

    loadData();
  }, [validate, getCommissionReports, dateError, commissionReportTableParams, errorCallback]);

  useEffect(() => {
    const { report_date } = commissionReportTableParams;

    if (report_date && validate && urlFiltersIsSet) {
      getCommissionReports({
        params: commissionReportTableParams,
        errorCallback: (error) => {
          clearCommissionReportsData();
          setAlert({
            type: 'error',
            message: error.body.description,
          });
        },
      });
    }
  }, [validate, getCommissionReports, dateError, commissionReportTableParams, errorCallback]);

  useEffect(() => {
    const loadData = async () => {
      if (!!commissionReportTableParams.company_id && urlFiltersIsSet) {
        if (company.id !== commissionReportTableParams.company_id) {
          const currentCompany = await getCompanyById(commissionReportTableParams.company_id);

          if (currentCompany) {
            setCompany(currentCompany);
            getDocumentsByIdV2({
              id: commissionReportTableParams.company_id,
              endpoint: 'getContracts',
              resultKey: 'contractsData',
              errorCallback,
            });

            if (
              commissionReportTableParams.contract_number &&
              commissionReportTableParams.contract_number !== contract.id
            ) {
              getActivationOrderList({
                company_id: commissionReportTableParams.company_id,
                contract_number: commissionReportTableParams.contract_number,
                errorCallback,
              });
            } else {
              getActivationOrderList({ company_id: commissionReportTableParams.company_id, errorCallback });
            }
          }
        } else if (
          !!commissionReportTableParams.contract_number &&
          commissionReportTableParams.contract_number !== contract.id
        ) {
          getActivationOrderList({
            company_id: commissionReportTableParams.company_id,
            contract_number: commissionReportTableParams.contract_number,
            errorCallback,
          });
        }
      }
    };

    loadData();
  }, [commissionReportTableParams, errorCallback]);

  useEffect(() => {
    if (commissionReportTableParams.contract_number !== contract.id && urlFiltersIsSet) {
      const currentContract = autocompleteContractData(contractsData.contracts).find(
        (item) => item.id === commissionReportTableParams.contract_number,
      );

      if (currentContract) {
        setContract(currentContract);
      } else {
        setContract({ name: '', id: '', active: true });
      }
    }

    if (commissionReportTableParams.activation_order_id !== activationOrder.id && urlFiltersIsSet) {
      const currentAO = autocompleteAOData(activationOrderListData).find(
        (item) => item.id === commissionReportTableParams.activation_order_id,
      );

      if (currentAO) {
        setActivationOrder(currentAO);
      } else {
        setActivationOrder({ name: '', id: '', active: true });
      }
    }
  }, [
    commissionReportTableParams,
    setContract,
    setActivationOrder,
    activationOrderListData,
    contractsData,
    errorCallback,
  ]);

  useEffect(
    () => () => {
      refreshContext.clear();
    },
    [refreshContext],
  );

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'commissionReportTableParams',
      params: commissionReportTableParams,
      requestParams,
    });

    const validated = dateValidationByRange(newParams.report_date);
    setValidate(validated);

    if (!validated) {
      setDateError({ status: true, text: dateError.text });
    }
    setUrlFiltersIsSet(true);
    setCommissionReportTableParams({ ...newParams });
  }, []);

  const onChangeCompanyHandler = useCallback(
    (values: CompanyType | null) => {
      const newParams = {
        ...commissionReportTableParams,
        company_id: values ? values.id : '',
        company: values,
        contract_number: '',
        activation_order_id: '',
      };
      setActivationOrder({ name: '', id: '', active: true });
      setContract({ name: '', id: '', active: true });
      setCommissionReportTableParams(newParams);
    },
    [validate, setCommissionReportTableParams, commissionReportTableParams, getDocumentsByIdV2, errorCallback],
  );

  const CurrencyHandler = useCallback(
    (values: CompanyType | null) => {
      const newParams = {
        ...commissionReportTableParams,
        currency: values?.name,
      };
      setCommissionReportTableParams(newParams);
    },
    [setCommissionReportTableParams, commissionReportTableParams, errorCallback],
  );

  const StatusHandler = useCallback(
    (values: CompanyType | null) => {
      const newParams = {
        ...commissionReportTableParams,
        status: values?.id,
      };

      if (values) {
        setStatus({ name: values?.name, id: values?.id, active: true });
      }
      setCommissionReportTableParams(newParams);
    },
    [setCommissionReportTableParams, commissionReportTableParams, errorCallback],
  );

  useEffect(() => {
    refreshContext.setParams({ datakey: 'commissionReportTableParams', params: commissionReportTableParams });
    setUrlStringParams({ filters: CommissionReportFilter, currentScreenParams: commissionReportTableParams });
  }, [commissionReportTableParams]);

  const onChangeReportDateHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const newParams = { ...commissionReportTableParams, report_date: value };
      const validated = dateValidationByRange(value);
      setValidate(validated);

      if (!validated) {
        clearCommissionReportsData();
      }
      setDateError({ status: validated, text: t('auth.required') });
      setCommissionReportTableParams(newParams);
    },
    [
      setCommissionReportTableParams,
      commissionReportTableParams,
      t,
      errorCallback,
      clearCommissionReportsData,
      dateValidationByRange,
    ],
  );

  const handleContractChange = useCallback(
    (values) => {
      const newParams = {
        ...commissionReportTableParams,
        contract_number: values?.id || '',
        activation_order_id: '',
        page: 0,
      };
      setActivationOrder({ name: '', id: '', active: true });
      setCommissionReportTableParams(newParams);
    },
    [setCommissionReportTableParams, commissionReportTableParams, errorCallback],
  );

  const handleActivationOrderChange = useCallback(
    (values) => {
      const newParams = {
        ...commissionReportTableParams,
        activation_order_id: values?.id || '',
        page: 0,
      };
      setCommissionReportTableParams(newParams);
    },
    [setCommissionReportTableParams, commissionReportTableParams],
  );

  const isActiveCompany = !!commissionReportTableParams.company_id;

  return (
    <div>
      <div className={classes.title}>{t('menu.commissionReport')}</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr' }}>
        <form className={`${classes.form} ${inputClasses.flexWrap}`} noValidate autoComplete="off">
          <div style={{ width: '200px', marginBottom: '20px', marginRight: '20px' }}>
            <CompanySelect
              label={t('contentHeader.client')}
              value={commissionReportTableParams.company_id}
              onChange={(_, value) => {
                onChangeCompanyHandler(value?.source || '');
              }}
              allowClear
            />
          </div>
          {isActiveCompany && (
            <div style={{ minWidth: '150px', marginBottom: '20px', marginRight: '20px' }}>
              <DropdownKit
                options={autocompleteContractData(contracts)}
                value={contract}
                changeSelectItem={(_, value) => {
                  handleContractChange(value || '');
                }}
                keyObj="name"
                positionType={DropdownSizeEnum.SIZE_40}
                label={t('tables.contracts')}
              />
            </div>
          )}
          {isActiveCompany && (
            <div style={{ minWidth: '150px', marginBottom: '20px', marginRight: '20px' }}>
              <DropdownKit
                options={autocompleteAOData(activationOrderListData)}
                value={activationOrder.name}
                changeSelectItem={(_, value) => {
                  handleActivationOrderChange(value || '');
                }}
                keyObj="name"
                positionType={DropdownSizeEnum.SIZE_40}
                label={t('forms.transaction.ao')}
              />
            </div>
          )}
          <div style={{ minWidth: '150px', marginBottom: '20px', marginRight: '20px' }}>
            <Select
              label={t('contentHeader.currency')}
              options={currencyChoicesAutocomplete.map((e) => ({ key: e.id, value: e.name }))}
              value={currentCurrency.id}
              onChange={(value) => {
                if (!value) {
                  CurrencyHandler(null);

                  return;
                }
                const newCurrentCurrency = currencyChoicesAutocomplete.find((e) => e.id === value);

                if (newCurrentCurrency) CurrencyHandler(newCurrentCurrency);
              }}
            />
          </div>
          <div style={{ minWidth: '150px', marginBottom: '20px', marginRight: '20px' }}>
            <Select
              label={t('contentHeader.status')}
              options={statusCommissionReportAutocomplete.map((e) => ({ key: e.id, value: e.name }))}
              value={currentStatus.id}
              onChange={(value) => {
                if (!value) {
                  StatusHandler(null);

                  return;
                }
                const newCurrentStatus = statusCommissionReportAutocomplete.find((e) => e.id === value);

                if (newCurrentStatus) StatusHandler(newCurrentStatus);
              }}
            />
          </div>
          <DateInput
            type={DatePikerEnum.ALL}
            date={commissionReportTableParams.report_date}
            required
            onChangeDateHandler={onChangeReportDateHandler}
            commonStyle={{ minWidth: 140, maxWidth: 227, height: 40, marginBottom: 20 }}
            labelKey="tables.reportDate"
          />
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  dateInput: {
    '& .MuiFilledInput-input.MuiInputBase-input': {
      paddingLeft: 'inherit',
    },
  },
  aoOptions: {
    '& .MuiAutocomplete-listbox': {
      backgroundColor: '#FFFF00',
      '& li:nth-child(even)': { backgroundColor: '#0CC' },
    },
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
}));

export default CommissionReportHeader;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { CurrencyType } from 'components/uiKit/InputKit';

export type CreateParamsT = {
  company_id: string;
  order_id: string;
  currency: string;
  request_number: number;
  pr_date: string;
  available_advance: {
    platform_id: string;
    purchase_price: string;
    currency: CurrencyType | '';
    due_date: string;
    purchased: string;
  }[];
};

export type CreatePurchaseRequestT = {
  body?: CreateParamsT;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  errorCallback = () => {},
  successCallback = () => {},
  resultKey = 'purchaseRequestCreate',
}: CreatePurchaseRequestT) =>
  requestAsync({
    url: endpoints.createPurchaseRequestUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.createPurchaseRequestUrl(),
    body: {
      ...body,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { CurrencyType } from 'components/uiKit/InputKit';
import { PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';

export type PurchaseRequestSetStatusParamsT = {
  id: string;
  comment?: string;
  repayment_date?: string;
  tx_date?: string;
  currency?: CurrencyType | '';
  amount?: string;
  file?: string;
  file_id?: string;
  status?: PurchaseRequestStatus;
};

export type PurchaseRequestSetStatusT = {
  params?: PurchaseRequestSetStatusParamsT;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({
  params,
  errorCallback = () => {},
  successCallback = () => {},
  resultKey = 'purchaseRequestSetStatus',
}: PurchaseRequestSetStatusT) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.setPurchaseRequestStatus(params.id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.setPurchaseRequestStatus(params.id),
    body: {
      ...params,
      id: undefined,
    },
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

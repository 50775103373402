// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import lodash from 'lodash';

import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { statusParamsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import { errorsIncoming, errorsIncomingType, requiredIncomingKeys } from './Cards/Incoming/data';
import { initialObjTransactionsType } from './data';
import { errorsOutgoing, requiredOutgoingKeys } from './Cards/Outgoing/data';
import { errorsInternal, requiredInternalKeys } from './Cards/Internal/data';
import { errorsFee, requiredFeeKeys } from './Cards/Fee/data';
import { errorsAdjustment, requiredAdjustmentKeys } from './Cards/Adjustment/data';
import { errorsConversionIncoming, requiredConversionIncomingKeys } from './Cards/ConversionIncoming/data';
import { errorsConversion, requiredConversionKeys } from './Cards/Conversion/data';

export interface CustomParamsKeyOfCardType {
  isOpenClosingDatePopUp?: boolean;
}

const getCurrentRequiredKeyOfCard = (transactionsFormData: initialObjTransactionsType) => ({
  [statusParamsEnum.CREATE]: {
    [KeyTransactions.INCOMING]: {
      listRequired: requiredIncomingKeys(transactionsFormData),
      errorsList: errorsIncoming,
    },
    [KeyTransactions.OUTGOING]: {
      listRequired: requiredOutgoingKeys(transactionsFormData),
      errorsList: errorsOutgoing,
    },
    [KeyTransactions.INTERNAL]: {
      listRequired: requiredInternalKeys(transactionsFormData),
      errorsList: errorsInternal,
    },
    [KeyTransactions.FEE]: {
      listRequired: requiredFeeKeys(),
      errorsList: errorsFee,
    },
    [KeyTransactions.ADJUSTMENT]: {
      listRequired: requiredAdjustmentKeys(transactionsFormData),
      errorsList: errorsAdjustment,
    },
    [KeyTransactions.CONVERSION_INCOMING]: {
      listRequired: requiredConversionIncomingKeys(),
      errorsList: errorsConversionIncoming,
    },
    [KeyTransactions.CONVERSION]: {
      listRequired: requiredConversionKeys(),
      errorsList: errorsConversion,
    },
  },
  [statusParamsEnum.EDIT]: {
    [KeyTransactions.INCOMING]: {
      listRequired: requiredIncomingKeys(transactionsFormData),
      errorsList: errorsIncoming,
    },
    [KeyTransactions.OUTGOING]: {
      listRequired: requiredOutgoingKeys(transactionsFormData),
      errorsList: errorsOutgoing,
    },
    [KeyTransactions.INTERNAL]: {
      listRequired: requiredInternalKeys(transactionsFormData),
      errorsList: errorsInternal,
    },
    [KeyTransactions.FEE]: {
      listRequired: requiredFeeKeys(),
      errorsList: errorsFee,
    },
    [KeyTransactions.ADJUSTMENT]: {
      listRequired: requiredAdjustmentKeys(transactionsFormData),
      errorsList: errorsAdjustment,
    },
    [KeyTransactions.CONVERSION_INCOMING]: {
      listRequired: requiredConversionIncomingKeys(),
      errorsList: errorsConversionIncoming,
    },
    [KeyTransactions.CONVERSION]: {
      listRequired: requiredConversionKeys(),
      errorsList: errorsConversion,
    },
  },
});

const useRequiredKeyOfCardsTransactions = (): {
  listRequired?: KeyTransactions[];
  errorsList?: errorsIncomingType[];
  getParamsItem?: (path: KeyTransactions, key: KeyTransactions) => { required?: boolean };
  getValidErrors?: () => boolean;
} => {
  const { transactionsFormData }: useTransactionType = useTransaction();
  const { status } = useParams();
  const { setErrors, changeErrors, getErrors } = useErrors();
  const { listRequired = [], errorsList = {} } =
    ((status === statusParamsEnum.CREATE || status === statusParamsEnum.EDIT) &&
      getCurrentRequiredKeyOfCard(transactionsFormData)[status][transactionsFormData[KeyTransactions.TYPE]]) ||
    {};
  const getValidErrors = useCallback(() => {
    const errors = getErrors();
    Object.keys(errorsList).forEach((objKey) => {
      if (objKey === KeyTransactions.ROOT) {
        errorsList[objKey].forEach(({ key, parameters }) => {
          if (listRequired.find((item) => key === item)) {
            if (errors[key]) {
              return;
            }

            if (key === KeyTransactions.RECIPIENT || key === KeyTransactions.SENDER) {
              setErrors(transactionsFormData[key][KeyTransactions.COMPANY_NAME], key, parameters);

              return;
            }
            setErrors(transactionsFormData[key], key, parameters);
          }
        });
      }
    });
    const errorsLocal = getErrors();

    if (!lodash.isEmpty(errorsLocal)) return false;

    return true;
  }, [listRequired, transactionsFormData, errorsList, changeErrors]);

  const getParamsItem = useCallback(
    (path: KeyTransactions, key: KeyTransactions) => {
      let params = {};
      errorsList &&
        Array.isArray(errorsList[path]) &&
        errorsList[path].forEach((item) => {
          if (item.key === key) {
            params = item?.parameters;
          }
        });

      return params || {};
    },
    [errorsList],
  );

  return { listRequired, errorsList, getParamsItem, getValidErrors };
};

export default useRequiredKeyOfCardsTransactions;

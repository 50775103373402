import React, { CSSProperties } from 'react';

interface ITableData {
  id: number;
  title: string;
  headAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  style?: CSSProperties;
  color?: string;
}

export type CompanyTableHeaderPropsType = {
  wrapperStyle?: React.CSSProperties;
  requestParams: Object;
};

export const tableDistributionPlatformData: ITableData[] = [
  {
    id: 1,
    title: 'forms.activationOrder.distributionPlatform',
    headAlign: 'left',
    color: '#838998',
  },
  {
    id: 2,
    title: 'forms.activationOrder.purchasePercent',
    color: '#838998',
  },
  {
    id: 3,
    title: 'forms.activationOrder.purchasePricePercentage',
    color: '#838998',
  },
  {
    id: 4,
    title: 'forms.activationOrder.activationDate',
    headAlign: 'right',
    color: '#838998',
  },
  {
    id: 5,
    title: 'forms.activationOrder.fundingLimit',
    headAlign: 'right',
    color: '#838998',
    style: { padding: '0 24px 0 12px' },
  },
];

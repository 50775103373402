import React, { Children, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Select, Status, Button, AddIcon } from '@gamesb42/ui-kit';
import { useHistory } from 'react-router-dom';

import { formatDateWithHour } from 'helpers/formatters';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import { listPdsAccountType } from 'hooks/api/useCompany';
import SwitcherButton from 'components/uiKit/SwitcherButton';
import { RevenueSourceSyncStatusEnum } from 'types/RevenueSources';

import { CompanyTypeParams } from '../index';
import DailyRevenue from '../DailyRevenue';
import {
  REVENUE_SOURCES_SYNC_STATUS_OPTIONS,
  REVENUE_SOURCES_SYNC_STATUS_COLOR,
  REVENUE_SOURCES_SYNC_STATUS_NAME,
} from 'constants/revenueSources';

import styles from './styles.module.scss';

interface PropsT {
  listPdsAccounts: listPdsAccountType;
  requestParams: CompanyTypeParams;
}

const RevenueSources: FC<PropsT> = ({ listPdsAccounts, requestParams }) => {
  const history = useHistory();
  const [syncStatus, setSyncStatus] = useState<RevenueSourceSyncStatusEnum | undefined>();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();

  const prepareList = Array.isArray(listPdsAccounts?.items)
    ? listPdsAccounts?.items.filter((item) => {
        if (!syncStatus) return true;
        return syncStatus === item.syncStatus;
      })
    : [];

  return (
    <div className={styles.wrapper}>
      <SwitcherButton
        style={{ height: 40, marginBottom: 12 }}
        list={[
          {
            title: t('forms.companies.tabs.dailyRevenue'),
          },
          {
            title: t('forms.companies.tabs.revenueSources'),
          },
        ]}
        current={currentTabIndex}
        callback={setCurrentTabIndex}
      />
      {currentTabIndex === 0 && <DailyRevenue requestParams={requestParams} listPdsAccounts={listPdsAccounts} />}
      {currentTabIndex === 1 && (
        <>
          <div className={styles.block}>
            <Select
              label={t('global.status')}
              value={syncStatus}
              onChange={setSyncStatus}
              width="260px"
              className={styles.selectStatus}
              allowClear
            >
              {REVENUE_SOURCES_SYNC_STATUS_OPTIONS.map((e) => (
                <Select.Option value={e.value} key={e.value}>
                  <Status color={REVENUE_SOURCES_SYNC_STATUS_COLOR[e.value]} className={styles.status}>
                    {e.label}
                  </Status>
                </Select.Option>
              ))}
            </Select>
            <Button
              type="primary"
              onClick={() => history.push(`${history.location.pathname}/add_new_platform`)}
              icon={<AddIcon size={24} />}
            >
              {t('forms.companies.agreements.addNewPlatform')}
            </Button>
          </div>
          <TableWrapWithPagination tableContainerStyle={{ backgroundColor: 'inherit' }} isShowPagination={false}>
            <TableHead>
              <TableRow style={{ height: 40, paddingLeft: 20 }} className={styles.headRow}>
                <TableCell align="left">{t('forms.companies.agreements.revenueSource')}</TableCell>
                <TableCell style={{ width: 180 }} align="right">
                  {t('forms.companies.agreements.lastSyncronizeDate')}
                </TableCell>
                <TableCell style={{ width: '250px', paddingRight: 20 }} align="center">
                  {t('forms.companies.agreements.connectionStatus')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Children.toArray(
                prepareList.map((item) => (
                  <TableRow style={{ height: 56 }} className={styles.tableRowStyle}>
                    <TableCell style={{ color: '#000000', paddingLeft: 20 }} align="left">
                      {item.storeName}
                    </TableCell>
                    <TableCell style={{ width: 120, color: '#29292C' }} align="right">
                      {(item.lastSync && formatDateWithHour(item.lastSync)) || '—'}
                    </TableCell>
                    <TableCell style={{ width: '250px', paddingRight: 20 }} align="center">
                      <Status color={REVENUE_SOURCES_SYNC_STATUS_COLOR[item.syncStatus]} className={styles.status}>
                        {REVENUE_SOURCES_SYNC_STATUS_NAME[item.syncStatus]}
                      </Status>
                    </TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </TableWrapWithPagination>
        </>
      )}
    </div>
  );
};

export default RevenueSources;

import { createUseStyles } from 'react-jss';

export const rowStyleDeleted = {
  fontStyle: 'italic',
  opacity: 0.7,
};

export const headerObjUserTable = {
  border: 'none',
  backgroundColor: '#FFFFFF',
  color: '#838998',
  fontSize: '14px',
  lineHeight: '17px',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const defaultListRowStyle = (record) => (record.active ? {} : rowStyleDeleted);

export const inactiveOptionStyles = createUseStyles({
  inactive: {
    color: 'grey',
    fontStyle: 'italic',
  },
});

export const headerStyles = createUseStyles(() => ({
  headerCell: { fontWeight: 'bold' },
}));

export const tableStyleAo = createUseStyles(() => ({
  bodyTableCellRoot: {
    fontSize: '14px !important',
    lineHeight: '17px !important',
    border: 'none !important',
    color: '#29292C !important',
  },
  bodyTableRowRoot: {
    height: 72,
    backgroundColor: '#FFFFFF',
    '&:last-child': {
      borderRadius: '0px 0px 12px 12px',
      '&>td:last-child': {
        borderRadius: '0px 0px 12px 0px',
      },
      '&>td:first-child': {
        borderRadius: '0px 0px 0px 12px',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#FBFBFD',
    },
  },
  documentHeaderRowRoot: {
    height: 61,
    backgroundColor: '#FFFFFF',
    boxShadow: 'inset 0px -1px 0px #FFFFFF',
    borderRadius: '12px 12px 0px 0px',
  },
  documentHederRoot: {
    border: 'none !important',
    backgroundColor: '#FFFFFF !important',
    color: '#838998 !important',
    fontSize: '14px !important',
    lineHeight: '17px !important',
  },
}));

export const tableSettingsStyles = createUseStyles({
  titleSettings: {
    color: '#606176',
    marginBottom: 20,
    fontSize: 24,
    lineHeight: '29px',
  },
  headTableRowRoot: {
    '&> th': {
      verticalAlign: 'top',
      color: '#838998',
      fontSize: 14,
      lineHeight: '17px',
      paddingTop: 16,
      paddingBottom: 16,
      boxSizing: 'border-box',
    },
    '&> th:first-child': {
      textAlign: 'left',
      color: '#29292C',
      borderTopLeftRadius: 8,
      zIndex: 3,
      verticalAlign: 'bottom',
    },
    '&> th:last-child': {
      borderTopRightRadius: 8,
    },
  },
  headTableRowRootRightBottom: {
    '&> th': {
      verticalAlign: 'bottom',
      color: '#838998',
      fontSize: 14,
      lineHeight: '17px',
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 0,
      boxSizing: 'border-box',
      textAlign: 'right',
    },
    '&> th:first-child': {
      textAlign: 'left',
      color: '#29292C',
      borderTopLeftRadius: 8,
      zIndex: 3,
      verticalAlign: 'bottom',
    },
    '&> th:last-child': {
      borderTopRightRadius: 8,
      paddingRight: 0,
    },
  },
  headTableRowRootIcon: {
    '& .MuiTableSortLabel-icon': {
      fontSize: 8,
      marginRight: 8,
      marginLeft: 8,
    },
  },
  bodyTableRowRoot: {
    '&>td:first-child': {
      minWidth: 152,
      position: 'sticky',
      zIndex: 1,
      left: 0,
      top: 0,
    },
  },
  bodyTableRowRootTd2: {
    '&>td:nth-child(2)': {
      minWidth: 102,
      position: 'sticky',
      zIndex: 1,
      left: 0,
      top: 0,
    },
  },
  bodyTableCellRoot: {
    borderBottom: 'none !important',
    color: '#29292C !important',
  },
  bodyRowTrHoverAo: {
    cursor: 'pointer',
    '&:nth-child(even):hover td': {
      backgroundColor: '#F4F4F6 !important',
    },
    '&:nth-child(odd):hover td': {
      backgroundColor: '#FAFAFB !important',
    },
  },
  bodyTableRoot: {
    '& tr': {
      height: 41,
    },
    '& tr:last-child': {
      '& td:last-child': {
        borderBottomRightRadius: 8,
      },
      '& td:first-child': {
        borderBottomLeftRadius: 8,
      },
    },
    '& tr:nth-child(even)': {
      '&>td': {
        backgroundColor: '#FAFAFB',
      },
    },
    '& tr:nth-child(odd)': {
      '&>td': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
  bodyTableRootWithoutChangeColor: {
    '& tr': {
      height: 41,
    },
    '& tr:last-child': {
      '& td:last-child': {
        borderBottomRightRadius: 8,
      },
      '& td:first-child': {
        borderBottomLeftRadius: 8,
      },
    },
  },
  headTableCellRoot: {
    backgroundColor: '#fff !important',
  },
});

export const tableHeaderStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    width: '100%',
    backgroundColor: '#fff',
    paddingLeft: '8px',
    flexWrap: 'wrap',
  },
  title: {
    width: '100%',
    fontSize: '24px',
    color: '#606176',
    lineHeight: '29px',
    marginBottom: 20,
  },
  leftBlockWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  wrapFixedHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 900,
  },
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: '0',
  },
  formControl: {
    minWidth: 120,
  },
  textInput: {
    width: '257px!important',
    marginRight: '16px!important',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  selectInput: {
    minWidth: '160px',
    marginRight: '16px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  dataInput: {
    marginRight: '16px!important',
  },
  checkWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-11px',
    marginRight: '16px',
    verticalAlign: 'middle',
    '& span': {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      cursor: 'pointer',
    },
  },
  buttonWrapper: {
    width: '832px',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '24px',
    paddingBottom: '8px',
    justifyContent: 'flex-end',
  },
  buttonText: {
    paddingLeft: '0.5em',
    fontSize: '13px',
  },
  textSelect: {
    marginTop: '30px !important',
    '& div': {
      backgroundColor: 'white !important',
    },
    '& *:focus': {
      backgroundColor: 'white !important',
    },
  },
  muiInput: {
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
      backgroundColor: '#eef200 !important',
    },
  },
});

export const tableBodyStyles = createUseStyles({
  paper: {
    overflowX: 'auto',
    borderBottom: '2px solid #DFE1E6',
  },
  paperMaxPage: {
    overflowX: 'auto',
    borderBottom: '2px solid #DFE1E6',
    maxHeight: 'calc(100vh - 280px)',
    minHeight: 164,
  },
  table: {
    minWidth: 650,
  },
  tHeadRow: {
    '& th': {
      color: '#5E6C84',
      fontWeight: 'bold',
      borderBottom: '2px solid #DFE1E6',
    },
  },
  tHeadRowSticky: {
    '& th': {
      color: '#5E6C84',
      fontWeight: 'bold',
      borderBottom: '2px solid #DFE1E6',
    },
    '& th:first-child': {
      zIndex: 3,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tHeadRowPreLine: {
    whiteSpace: 'pre-line',
  },
  tBodyRow: {
    cursor: 'pointer',
    borderColor: 'white',
    border: '2px solid white',
    height: 48,
    '& td': {
      color: '#091E42',
    },
    '&:hover': {
      backgroundColor: '#FAFBFC !important',
    },
  },
  tBodyRowSticky: {
    borderColor: 'white',
    border: '2px solid white',
    height: 48,
    '& td': {
      color: '#091E42',
    },
    '&:hover': {
      backgroundColor: '#FAFBFC !important',
    },
    '& td:first-child': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#FFFFFF',
    },
  },
  tHeadRowth1stSticky: {
    '& th:first-child': {
      zIndex: 3,
    },
  },
  tBodyRowtd1stSticky: {
    '& td:first-child': {
      position: 'sticky',
      left: 0,
      backgroundColor: '#FFFFFF',
    },
  },
  td2ndSticky: {
    '& td:nth-child(2)': {
      position: 'sticky',
      left: 192,
      backgroundColor: '#FFFFFF',
    },
  },
  td3rdSticky: {
    '& td:nth-child(3)': {
      position: 'sticky',
      left: 307,
      backgroundColor: '#FFFFFF',
    },
  },
  th2ndSticky: {
    '& th:nth-child(2)': {
      left: 192,
      zIndex: 3,
    },
  },
  tableDate: {
    color: '#6B778C',
    fontSize: 11,
  },
  tBody: {
    '& > tr > td': {
      borderBottom: '0!important',
    },
  },
  tableFixHeader: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  tablePagination: {
    width: '832px',
  },
  fixedCell: {
    maxWidth: 120,
    overflow: 'hidden',
    marginRight: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  fixedCell2: {
    maxWidth: 120,
    overflow: 'hidden',
    marginRight: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  overflowXFixed: {
    overflowX: 'auto',
    maxHeight: 'calc(100vh - 355px)',
  },
  nameWidth: {
    maxWidth: '1000px !important',
  },
});

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type deletePlatformDataType = {
  id: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

export default ({ id, successCallback, errorCallback, resultKey = 'deletePlatformData' }: deletePlatformDataType) =>
  requestAsync({
    url: endpoints.getPlatformByIdUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getPlatformByIdUrl(id),
    body: {},
    options: {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { useTranslation } from 'react-i18next';
import { FormControl, TextField, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { addDecimal } from 'helpers/location';

import AmountFormat from './AmountFormat';

export const AmountInput = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  amount,
  allowNegative = false,
  error = false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onBlurHandler,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  handleAmountChange,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name,
  disabled = false,
  toolTip = '',
  errorText = '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  label,
  inputWidth = '',
  fieldStyles = null,
  decimalScale = undefined,
  required = false,
  isNeedHandlePaste = false,
  isNeedBorder = false,
}) => {
  const useStyle = makeStyles(() => ({
    amountInput: {
      width: inputWidth ? `${inputWidth}px` : '500px',
      backgroundColor: '#FFFFFF !important',
      borderRadius: 6,
      marginRight: '8px',
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
      },
      '& div': {
        '&:before': {
          borderColor: isNeedBorder ? '1px solid rgba(0, 0, 0, 0.42)' : 'white !important',
        },
      },
    },
  }));

  const { t } = useTranslation();
  const classes = useStyle();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handlePaste = (event) => {
    event.preventDefault();
    const newAmount = event.clipboardData.getData('Text').replaceAll(',', '.');
    handleAmountChange({ target: { name, value: newAmount } });
  };

  return (
    <FormControl error={error}>
      <TextField
        onPaste={
          isNeedHandlePaste
            ? (event) => {
                handlePaste(event);
              }
            : () => {}
        }
        id="filled-basic"
        label={label}
        required={required}
        variant="filled"
        autoComplete="off"
        data-tip={toolTip}
        disabled={disabled}
        onBlur={onBlurHandler}
        onChange={handleAmountChange}
        error={error}
        name={name}
        defaultValue={addDecimal(amount)}
        value={addDecimal(amount)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={fieldStyles}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputComponent: AmountFormat,
          inputProps: { allowNegative, decimalScale },
          autoComplete: 'off',
          className: classes.amountInput,
        }}
        className={classes.amountInput}
      />
      <FormHelperText id="component-error-text" style={{ visibility: !error ? 'hidden' : 'visible' }}>
        {errorText || t('auth.required')}
      </FormHelperText>
    </FormControl>
  );
};

export default AmountInput;

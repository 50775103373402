// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { BaseTextFieldProps, InputProps, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React, { FC } from 'react';

import { UseStylingAutocompleteField } from 'hooks/useStylingForMaterialUi';

interface AutoCompleteNewProps {
  textFieldProps?: InputProps;
  baseTextFieldProps?: BaseTextFieldProps;
  autoCompleteProps?: AutocompleteProps<any, undefined, undefined, undefined>;
}

const AutoCompleteNew: FC<AutoCompleteNewProps> = ({ textFieldProps, autoCompleteProps, baseTextFieldProps }) => {
  const stylingAutocomplite = UseStylingAutocompleteField();

  return (
    <Autocomplete
      {...stylingAutocomplite.autoComplete}
      {...autoCompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...stylingAutocomplite.textField}
          InputProps={{
            ...params.InputProps,
            ...stylingAutocomplite.textField.InputProps,
            ...textFieldProps,
          }}
          {...baseTextFieldProps}
        />
      )}
    />
  );
};

export default AutoCompleteNew;

import { Button } from '@material-ui/core';
import React, { FC, useState, Children } from 'react';

import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';

const plusSvg = require('assets/img/plus.svg').default;

interface SelectButtonPropsType {
  title: string;
  list: string[];
  callback: (i: number) => void;
}

const SelectButton: FC<SelectButtonPropsType> = ({ title, list, callback }) => {
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const [isActive, setIsActive] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <Button
        style={{ height: 40 }}
        {...dropZoneStylingButton}
        onClick={() => setIsActive(!isActive)}
        startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
      >
        {title}
      </Button>
      {isActive && (
        <div
          style={{
            position: 'absolute',
            zIndex: 99999,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 14px 40px rgba(0, 0, 0, 0.07)',
            backgroundColor: '#FFFFFF',
            color: '#838998',
            fontSize: 16,
            lineHeight: '18px',
            borderRadius: 8,
            padding: '10px 4px',
            width: '100%',
            boxSizing: 'border-box',
            top: 44,
          }}
        >
          {list &&
            Children.toArray(
              list.map((item, i) => (
                <div
                  onMouseEnter={(e) => {
                    const { style } = e.currentTarget as HTMLElement;
                    style.backgroundColor = '#FAFAFB';
                  }}
                  onMouseLeave={(e) => {
                    const { style } = e.currentTarget as HTMLElement;
                    style.backgroundColor = '';
                  }}
                  onClick={() => callback(i)}
                  style={{
                    height: 36,
                    padding: 8,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 8,
                    cursor: 'pointer',
                  }}
                >
                  <span>{item}</span>
                </div>
              )),
            )}
        </div>
      )}
    </div>
  );
};

export default SelectButton;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState, Children, useRef, useEffect, MouseEventHandler } from 'react';
import { isEmpty } from 'lodash';

import ColorPass from './ColorPass';
import styles from './styles.module.scss';

const arrowSvg = require('./img/arrow.svg').default;

type viewType = 'field' | 'list';

export enum DropdownSizeEnum {
  SIZE_40,
  SIZE_60,
}

const listDropdownPosition = {
  [DropdownSizeEnum.SIZE_40]: {
    wrapSt: { height: 40 },
    inputSt: {
      paddingTop: 17,
      paddingRight: 48,
      paddingBottom: 5,
      paddingLeft: 11,
      fontSize: 16,
      lineHeight: '19px',
    },
    labelSt: { top: 4, left: 12, fontSize: 10 },
    arrowSt: { right: 7 },
    helperSt: { left: 12 },
  },
  [DropdownSizeEnum.SIZE_60]: {
    wrapSt: { height: 60 },
    inputSt: {
      paddingTop: 30,
      paddingRight: 58,
      paddingBottom: 10,
      paddingLeft: 18,
      fontSize: 16,
      lineHeight: '19px',
    },
    labelSt: { top: 8, left: 18, fontSize: 12 },
    arrowSt: { right: 17 },
    helperSt: { left: 18 },
  },
};
const styleActive = { backgroundColor: 'rgba(236, 238, 242, 0.4)', color: '#29292C' };

export type SelectItemType = string | number | object;
export interface DropdownKitType {
  positionType: DropdownSizeEnum;
  formattingFc?: (value, view: viewType) => any;
  options?: SelectItemType[];
  customOption?: (
    item: SelectItemType,
    isActive: boolean,
    change: MouseEventHandler<HTMLDivElement>,
  ) => React.ReactElement;
  keyObj?: string;
  keyImg?: string;
  changeSelectItem?: (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>,
    data?: any,
  ) => void;
  value?: string | object;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  onChange?: (data: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  isErrors?: boolean;
  wrapStyle?: React.CSSProperties;
  isHiddenMenuAll?: boolean;
  onFocus?: () => void;
  dataCy?: string;
}

export const DropdownKit: FC<DropdownKitType> = (props) => {
  const {
    options = [],
    keyObj,
    formattingFc,
    changeSelectItem,
    value = '',
    onChange,
    disabled,
    customOption,
    label,
    helperText,
    required,
    isErrors,
    positionType,
    wrapStyle,
    isHiddenMenuAll,
    keyImg,
    onFocus,
    dataCy,
  } = props;
  const [isSwitch, setIsSwitch] = useState(false);
  const [localValue, setLocalValue] = useState('');
  const [currentEl, setCurrentEl] = useState(null);
  const [localOptions, setLocalOptions] = useState<SelectItemType[] | null>(options);
  const rootRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  const getCurrentValue = (current: string | number | object = '', view: viewType) => {
    if (formattingFc) {
      return formattingFc(current, view);
    }
    const isObjValue = typeof current === 'object';
    let val = current;

    if (isObjValue && !keyObj) return '';

    if (isObjValue && keyObj) {
      val = current[keyObj] || '';
    }

    return val.toString();
  };

  const isCurrentElem = (item: string | number | object) => {
    if (!localValue) return false;

    if (typeof value === 'object') {
      const commonKey = Object.keys(value).filter((key) => item[key] !== undefined);
      const el = commonKey.find((key) => item[key] !== value[key]);

      if (!el) {
        return true;
      }

      return false;
    }

    if (item[keyObj] === localValue) {
      return true;
    }

    if ((typeof item === 'string' || typeof item === 'number') && localValue.toString() === item.toString()) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!value || isEmpty(value)) {
      setCurrentEl(null);
      setLocalValue('');

      return;
    }
    setLocalValue(getCurrentValue(value, 'field'));
  }, [value]);

  useEffect(() => {
    if (!localValue) return;

    if (!localValue && menuRef.current) {
      menuRef.current.scrollTop = 0;

      return;
    }
    const currentElIndex = options.findIndex((item) => isCurrentElem(item));

    if (currentElIndex > 0 && menuRef.current) {
      const item = menuRef.current.children[currentElIndex + 1];

      if (item) {
        menuRef.current.scrollTop = item.offsetTop - item.clientHeight;
      }
    }
    setCurrentEl(options[currentElIndex]);
  }, [isSwitch]);

  useEffect(() => {
    const onClick = (event) => {
      if (!rootRef.current?.contains(event.target)) {
        setIsSwitch(false);
        setLocalOptions(null);
      }
    };
    isSwitch && document.addEventListener('click', onClick);
    !isSwitch && document.removeEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isSwitch]);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const { wrapSt, arrowSt, inputSt, labelSt, helperSt } = listDropdownPosition[positionType];
  const addPoints = inputRef.current && inputRef.current.scrollWidth > inputRef.current.offsetWidth;
  const errorStyle = isErrors ? { color: 'rgb(236, 62, 114)', border: '1px solid rgb(236, 62, 114)' } : {};
  const disableStyle = disabled ? { opacity: 0.5 } : {};
  const IS_SHOW_ICON = keyImg && currentEl;

  return (
    <div style={{ position: 'relative' }} {...(dataCy && { 'data-cy': dataCy })}>
      <div ref={rootRef} className={styles.selectWrap} style={{ ...wrapStyle, ...wrapSt }}>
        <input
          style={{
            ...inputSt,
            ...errorStyle,
            ...disableStyle,
            paddingLeft: inputSt.paddingLeft + (IS_SHOW_ICON ? 34 : 0),
          }}
          className={styles.inputStyle}
          ref={inputRef}
          onChange={(e) => {
            !e.target.value && setLocalOptions(null);
            e.target.value &&
              setLocalOptions(
                options.filter((item) => {
                  const current = getCurrentValue(item, 'field');

                  return current.toLowerCase().includes(e.target.value.toLocaleLowerCase());
                }),
              );
            setLocalValue(e.target.value);
            changeSelectItem && !e.target.value && changeSelectItem(e, typeof options[0] === 'object' ? {} : '');
            setIsSwitch(true);
            onChange && onChange(e);
          }}
          onFocus={(e) => {
            setIsSwitch(true);
            e.target.select();
            onFocus && onFocus();
          }}
          value={localValue}
          disabled={disabled}
          placeholder={!localValue && !isSwitch ? 'All' : undefined}
        />
        {IS_SHOW_ICON && (
          <div
            style={{
              ...inputSt,
              ...disableStyle,
              paddingTop: inputSt.paddingTop - 2,
              position: 'absolute',
            }}
          >
            <img style={{ width: 24 }} src={currentEl[keyImg]} alt="open" />
          </div>
        )}
        {label && (
          <label style={{ ...labelSt, ...disableStyle }} className={styles.labelStyle}>
            {`${label}${required ? '*' : ''}`}
          </label>
        )}
        <div className={styles.selectIconStyle} style={{ ...arrowSt, ...disableStyle }}>
          <img
            onClick={() => {
              if (disabled) return;

              if (!isSwitch) {
                inputRef?.current.focus();
                setIsSwitch(true);

                return;
              }
              setIsSwitch(false);
            }}
            style={{ transform: isSwitch && 'rotateX(180deg)', padding: 10 }}
            src={arrowSvg}
            alt="open"
          />
        </div>
        {addPoints && !isSwitch && (
          <div
            className={styles.points}
            style={{
              fontSize: inputSt.fontSize,
              left: inputRef.current.offsetWidth - inputSt.paddingRight,
              bottom: inputSt.paddingBottom,
            }}
          >
            ...
          </div>
        )}
      </div>
      {isErrors && helperText && (
        <div style={helperSt} className={styles.helperStyle}>
          {helperText}
        </div>
      )}
      {isSwitch && options && (
        <div className={styles.menuSelectStyle} ref={menuRef}>
          {!isHiddenMenuAll && (
            <div className={styles.itemAllStyle}>
              <div
                onClick={(e) => {
                  setLocalValue('');
                  changeSelectItem(e, typeof options[0] === 'object' ? {} : '');
                }}
                className={styles.menuSelectItemStyle}
                style={localValue ? {} : styleActive}
              >
                All
              </div>
            </div>
          )}
          {Children.toArray(
            (localOptions || options).map((item) => {
              const current = getCurrentValue(item, 'list');
              let styleActiveItem = {};

              if (isCurrentElem(item)) {
                styleActiveItem = styleActive;
              }
              const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
                changeSelectItem(e, item);
                setIsSwitch(false);
                setLocalValue('');
              };

              if (customOption) {
                return customOption(item, isEmpty(styleActiveItem), handleMouseDown);
              }

              return (
                current && (
                  <div
                    style={{ ...styleActiveItem }}
                    className={styles.menuSelectItemStyle}
                    onMouseDown={(e) => {
                      setCurrentEl(item);
                      changeSelectItem(e, item);
                      setIsSwitch(false);
                    }}
                  >
                    {keyImg && <img style={{ marginRight: 10, width: 24 }} src={item[keyImg]} alt="img" />}
                    <ColorPass color="#29292C" splitText={localValue}>
                      {current}
                    </ColorPass>
                  </div>
                )
              );
            }),
          )}
        </div>
      )}
    </div>
  );
};

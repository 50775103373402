// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import useTransaction from 'hooks/api/useTransaction';
import useCompany from 'hooks/api/useCompany';
import useActivationOrder from 'hooks/api/useActivationOrder';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import { formatDateIsoShort } from 'helpers/formatters';
import { validateTransaction } from 'helpers/validateTransaction';
import { getErrorTextByErrorType, dateRangeValidator } from 'helpers/dt';
import { convertFileToBase64 } from 'helpers/file';
import { round2 } from 'helpers/num';
import useModal from 'hooks/api/useModal';
import useSuccessCallback from 'hooks/useSuccessCallback';
import { filterCompanies } from 'helpers/companies';
import urls from 'constants/urls';
import { fileMaxSize } from 'consts/dict';
import {
  CompanyType,
  initialTransactionFormDataType,
  TargetOrderDataType,
  AoParamsType,
  DefaultTransactionErrorType,
} from 'types/TransactionFormTypes';
import CustomInput from 'components/inputs/CustomInput';
import AmountInput from 'components/inputs/AmountInput';
import DateInput from 'components/inputs/DateInput';
import getUrlParams from 'helpers/getUrlParams';

import FormBottomBlock from '../FormBottomBlock';

import AdjustingTransactionForm from './AdjustingTransaction';
import TotalAmountAlert from './TotalAmountAlert';
import { AutocompletedInput } from './ui';
import TargetRow from './TargetRow';
import { initialTransactionFormData, initialTargetOrder, getInitialDefaultTransactionError } from './initialValues';

const TransactionFormBody = ({ editType = false, transactionType = 'transaction' }) => {
  const { transactionId = '' } = useParams();
  const { _type } = getUrlParams(window.location.href, ['_type']).requestParams as { _type: string };
  const { orderData = {} } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const requiredText = t('auth.required');
  const {
    companiesData: { companiesData = [] },
    getCompanies,
  } = useCompany();
  const {
    subTransactionData,
    getTransaction,
    getEntries,
    getTransactionIsFetching,
    createSubTransaction,
    createTransaction,
    editTransaction,
    deleteTransaction,
    orderAmountData,
    clearSubTransactionData,
    clearTransactionData,
    transactionData,
  } = useTransaction();
  const {
    activationOrderListData,
    getActivationOrderList,
    activationOrderData,
    clearActivationOrderData,
    clearActivationOrderListData,
  } = useActivationOrder();
  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText }) => {
    setIsPending(false);

    return errorCallbackView({ errorField, errorCallbackText });
  });
  const errorCallbackView = useCallback(({ errorField, errorCallbackText }) => {
    if (Object.keys(error).includes(errorField)) {
      setError((prevState) => ({
        ...prevState,
        [errorField]: { status: true, text: errorCallbackText },
      }));

      return false;
    }

    return true;
  }, []);

  const { successCallback } = useSuccessCallback({
    editType,
    route: urls.getTransaction(),
    messageKey: 'alert.transaction',
  });
  const { modalData, setModal } = useModal();
  const refreshContext = useContext(RefreshContext);
  const [error, setError] = useState<DefaultTransactionErrorType>(getInitialDefaultTransactionError(requiredText));
  const [isPending, setIsPending] = useState(false);
  const [formData, setFormData] = useState<initialTransactionFormDataType>({
    ...initialTransactionFormData,
    ...orderData,
    adjusting_transaction: transactionType === 'adjusting',
    type: transactionType === 'adjusting' ? 'IN' : _type ? _type.toUpperCase() : '',
  });
  const [activationOrders, setActivationOrders] = useState([]);
  const [company, setCompany] = useState<CompanyType>(orderData.company ? orderData.company : { name: '', id: '' });
  const [src, setSrc] = useState<string>(formData?.file?.rawFile || '');
  const [distributeDisable, setDistributeDisable] = useState<boolean>(true);
  const [isCheckErrors, setIsCheckErrors] = useState<boolean>(false);
  const [canByDeletedTransaction, setCanByDeletedTransaction] = useState<boolean>(true);
  const [toolTip, setToolTip] = useState(
    formData.targets.length ? t('forms.transaction.removeAllTargetsTooltipText') : '',
  );
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const disabledOffTargetsExist = !!formData.targets.length;

  const toolTipFromType = t('forms.transaction.reasonAdjustmentToolTip');
  const targetIncId = useRef(0);
  const companies = useMemo(() => filterCompanies(companiesData), [companiesData]);

  useMemo(() => {
    if (modalData.confirm) {
      if (modalData.type === 'distribute') {
        const { date, company_id, currency, type, amount } = formData;
        createSubTransaction({ date, company_id, currency, type, amount });
      } else {
        deleteTransaction({
          id: transactionId,
          successCallback: successCallback.bind(null, { deleted: true }),
          errorCallback,
        });
      }
      setModal({ show: false, confirm: false, type: '', description: '' });
    }
  }, [
    modalData,
    transactionId,
    deleteTransaction,
    successCallback,
    errorCallback,
    setModal,
    createSubTransaction,
    formData,
  ]);

  useMemo(() => {
    setActivationOrders(
      activationOrderListData.filter((ao) =>
        formData.currency ? formData.currency === ao.currency && ao.active : ao.active,
      ),
    );
  }, [activationOrderListData, formData]);

  useMemo(() => {
    setFormData((prev) => {
      const targets = [...prev.targets];
      const currentTarget = { ...targets[activationOrderData.getterIndex] };
      currentTarget.order_amount = activationOrderData.amount;
      currentTarget.order_currency = activationOrderData.currency;
      currentTarget.order_id = activationOrderData.id;
      currentTarget.order_issued_amount = activationOrderData.issued_amount;
      targets[activationOrderData.getterIndex] = currentTarget;

      return { ...prev, targets };
    });
  }, [activationOrderData]);

  useMemo(() => {
    setFormData((prev) => {
      let currentTarget: TargetOrderDataType = initialTargetOrder;
      prev.targets.forEach((target) => {
        if (target.keyId === orderAmountData.getterId) {
          currentTarget = target;
        }
      });

      if (orderAmountData.order_id) {
        if (currentTarget.target_type === 'INTEREST_REPAY') {
          if (orderAmountData.interest) {
            currentTarget.tx_currency_amount = round2(orderAmountData.interest * (currentTarget.rate || 0));
            currentTarget.amount = Number(orderAmountData.interest);
          }
        } else if (currentTarget.target_type === 'PENALTY_REPAY') {
          if (orderAmountData.penalty) {
            currentTarget.tx_currency_amount = round2(orderAmountData.penalty * (currentTarget.rate || 0));
            currentTarget.amount = Number(orderAmountData.penalty);
          }
        } else {
          currentTarget.amount = 0.0;
        }
      } else {
        currentTarget.amount = 0.0;
      }

      return {
        ...prev,
        targets: [
          ...prev.targets.map((item) => {
            if (item.keyId === orderAmountData.getterId) {
              return currentTarget;
            }

            return item;
          }),
        ],
      };
    });
  }, [orderAmountData]);

  useMemo(() => {
    if (subTransactionData.targets) {
      const newTargets = subTransactionData.targets.map((target, index) => {
        const keyId = index + 1;

        return { ...target, keyId };
      });
      targetIncId.current = newTargets.length;
      setFormData((prev) => ({ ...prev, targets: newTargets }));
    }
  }, [subTransactionData]);

  useEffect(() => {
    refreshContext.clear();
    getCompanies({ showDeleted: false, errorCallback });
    refreshContext.add({ func: getCompanies, params: { showDeleted: false, errorCallback } });

    if (editType) {
      getTransaction({ id: transactionId, errorCallback });
      getEntries({ id: transactionId, errorCallback });
      refreshContext.add({ func: getTransaction, params: { id: transactionId, errorCallback } });
      refreshContext.add({ func: getEntries, params: { id: transactionId, errorCallback } });
    }
  }, [editType, getEntries, getTransaction, transactionId, getCompanies, refreshContext, errorCallback]);

  useMemo(() => {
    if (Object.keys(transactionData).length && editType && transactionData.type !== 'CONVERSION') {
      setCanByDeletedTransaction(transactionData.deletion.can_be_deleted);
      setCompany({
        name: transactionData.company.name,
        id: transactionData.company.id,
        active: transactionData.company?.active,
      });

      if (transactionData.deletion.reason) {
        setToolTip(transactionData.deletion.reason);
      } else if (transactionData.targets.length) {
        setToolTip(t('forms.transaction.removeAllTargetsTooltipText'));
      }
      transactionData.targets = transactionData.targets.map((target, index) => ({ ...target, keyId: index + 1 }));
      targetIncId.current = transactionData.targets.length;
      const aoParams: AoParamsType = {
        company_id: transactionData.company_id,
        show_deleted: false,
        errorCallback,
      };

      if (transactionData.adjusting_transaction && transactionData.currency) {
        aoParams.currency = transactionData.currency;
      }
      getActivationOrderList(aoParams);
      setFormData((prev) => ({
        ...prev,
        ...transactionData,
        date: formatDateIsoShort(transactionData.date),
        receipt_date: formatDateIsoShort(transactionData.receipt_date),
        file_name: transactionData.file?.src,
        file_data: transactionData.file?.rawFile,
      }));
    }
  }, [transactionData, getActivationOrderList, editType, errorCallback, t]);

  useEffect(() => {
    const { date, company_id, currency, type, amount } = formData;

    if (date && company_id && type && type === 'IN' && amount && currency) {
      setDistributeDisable(false);
    } else {
      setDistributeDisable(true);
    }
  }, [formData]);

  useEffect(
    () => () => {
      clearSubTransactionData();
      clearActivationOrderData();
      clearActivationOrderListData();
      clearTransactionData();
    },
    [
      clearSubTransactionData,
      clearActivationOrderData,
      clearActivationOrderListData,
      refreshContext,
      clearTransactionData,
    ],
  );

  const setDateErrors = useCallback(
    ({ value, name }) => {
      if (value) {
        const dError = dateRangeValidator({ date: value });

        if (!dError.validate) {
          const dataErrorText = getErrorTextByErrorType({
            type: dError.type,
            minText: t('dateErrors.min'),
            maxText: t('dateErrors.max'),
          });
          setError((prev) => ({ ...prev, [name]: { error: true, text: dataErrorText } }));
        } else {
          setError((prev) => ({ ...prev, [name]: { error: false, text: requiredText } }));
        }
      }
    },
    [t, requiredText],
  );

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (error[name]) {
        if (name === 'receipt_date' || name === 'date') {
          setDateErrors(e.target);
        } else {
          setError((prev) => ({ ...prev, [name]: !value }));
        }
      }

      if (name === 'currency') {
        const aoParams: AoParamsType = {
          company_id: formData.company_id,
          show_deleted: false,
          errorCallback,
        };

        if (formData.adjusting_transaction) {
          aoParams.currency = value;
        }
        formData && formData.company_id && getActivationOrderList(aoParams);
      }

      if (name === 'adjusting_transaction') {
        setFormData((prev) => ({
          ...prev,
          adjusting_transaction: e.target.checked,
          type: e.target.checked ? 'IN' : '',
        }));

        if (e.target.checked) {
          setError((prev) => ({ ...prev, type: false }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [error, setDateErrors, formData, getActivationOrderList, errorCallback],
  );

  const onChangeCompanyHandler = useCallback(
    (_, values: CompanyType | null) => {
      if (values) {
        const aoParams: AoParamsType = {
          company_id: values?.id,
          show_deleted: false,
          errorCallback,
        };

        if (formData.adjusting_transaction && formData.currency) {
          aoParams.currency = formData.currency;
        }
        getActivationOrderList(aoParams);
      } else {
        setActivationOrders([]);
      }
      setCompany({ name: values?.name, id: values?.id });
      setFormData((prev) => ({ ...prev, company_id: values?.id }));
      setError({ ...error, company: !values });
    },
    [error, errorCallback, getActivationOrderList, formData],
  );

  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files[0].size > fileMaxSize) {
      setFileSizeError(true);
      setError((prev) => ({ ...prev, file: true }));
    } else {
      setFileSizeError(false);
      const fileUrl: any = await convertFileToBase64(e.target.files[0]);
      setFormData((prev) => ({ ...prev, file_data: fileUrl, file_name: e.target.files[0].name }));
      setSrc(fileUrl);
      setError((prev) => ({ ...prev, file: !fileUrl }));
    }
  }, []);

  const handleRemoveFile = useCallback(() => {
    setFormData((prev) => ({ ...prev, file_data: '', file_name: '' }));
    setSrc('');
  }, []);

  const handleDistribute = useCallback(() => {
    const { date, company_id, currency, type, amount } = formData;

    if (date && company_id && currency && type && amount) {
      if (!activationOrders.length) {
        const aoParams: AoParamsType = {
          company_id: formData.company_id,
          show_deleted: false,
          errorCallback,
        };

        if (formData.adjusting_transaction && formData.currency) {
          aoParams.currency = formData.currency;
        }
        getActivationOrderList(aoParams);
      }

      if (!formData.targets.length) {
        createSubTransaction({ date, company_id, currency, type, amount });
      } else {
        setModal({
          show: true,
          title: `${t('forms.transaction.distribute')}?`,
          description: t('forms.transaction.distributeAlert'),
          type: 'distribute',
        });
      }
    }
  }, [formData, activationOrders, createSubTransaction, getActivationOrderList, errorCallback, t, setModal]);

  const onBlurHandler = useCallback(
    (field: string, fieldValue: string) => {
      if (field === 'amount') {
        setError((prev) => ({ ...prev, amount: fieldValue === '0' || !fieldValue }));
      } else if (field === 'receipt_date' || field === 'date') {
        setDateErrors({ value: fieldValue, name: field });
      } else {
        setError((prev) => ({ ...prev, [field]: !fieldValue }));
      }
    },
    [setDateErrors],
  );

  const handleTargetAdd = useCallback(() => {
    setToolTip(t('forms.transaction.removeAllTargetsTooltipText'));
    targetIncId.current += 1;
    setFormData((prev) => ({
      ...prev,
      targets: [...prev.targets, { ...initialTargetOrder, keyId: targetIncId.current }],
    }));

    if (!activationOrders.length && formData.company_id) {
      const aoParams: AoParamsType = {
        company_id: formData.company_id,
        show_deleted: false,
        errorCallback,
      };

      if (formData.adjusting_transaction && formData.currency) {
        aoParams.currency = formData.currency;
      }
      getActivationOrderList(aoParams);
    }
  }, [getActivationOrderList, activationOrders, formData, errorCallback, t]);

  const handleTargetsAddInFormData = useCallback(
    (target, index) => {
      const newArr = [...formData.targets];
      newArr[index] = target;
      setFormData((prev) => ({ ...prev, targets: newArr }));
    },
    [setFormData, formData],
  );

  const setDateError = useCallback(() => {
    setError((prev) => ({ ...prev, date: { error: true, text: prev.date.text } }));
  }, []);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      setIsPending(true);
      setIsCheckErrors(true);
      event.preventDefault();
      const { validated, errors } = validateTransaction(formData);
      setError((prev) => ({ ...errors, receipt_date: prev.receipt_date, date: prev.date }));

      if (validated && !error.date.error && !(formData.receipt_date && error.receipt_date.error)) {
        const fetchingData = { ...formData };
        const res = await fetch(src);
        const blob = await res.blob();
        const blobUrl = URL.createObjectURL(blob);

        if (formData.adjusting_transaction) {
          fetchingData.file.rawFile = blobUrl;
          fetchingData.file.url = blobUrl;
          fetchingData.file.src = fetchingData.file_name;

          if (Number(formData.amount) < 0) {
            formData.amount = String.fromCharCode(Number('0x2212')) + Math.abs(Number(formData.amount));
          }
        }

        if (editType) {
          editTransaction({ ...fetchingData, id: transactionId, successCallback, errorCallback });
        } else {
          createTransaction({ ...fetchingData, successCallback, errorCallback });
        }
      } else {
        setIsPending(false);
      }
    },
    [formData, createTransaction, editTransaction, transactionId, editType, successCallback, errorCallback, src, error],
  );

  const cancelHandler = useCallback(() => {
    history.push(urls.getTransaction());
  }, [history]);

  const targetRemoveHandler = useCallback(
    (keyId) => {
      const newTargetArr = formData.targets.filter((target) => target.keyId !== keyId);

      if (!newTargetArr.length) {
        setToolTip('');
        setIsCheckErrors(false);
      }
      setFormData((prev) => ({ ...prev, targets: newTargetArr }));
    },
    [formData],
  );

  if (getTransactionIsFetching) {
    return null;
  }

  if (editType && !transactionData.id) {
    return <h3 style={{ color: 'gray' }}>{t('forms.transaction.transactionNotFound')}</h3>;
  }

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit}>
      <div className={classes.topBlock}>
        {formData.adjusting_transaction ? (
          <h1 style={{ marginBottom: 30 }}>{t('forms.transaction.adjusting')}</h1>
        ) : null}
        <DateInput
          date={formData.date}
          required
          disabled={disabledOffTargetsExist || !canByDeletedTransaction}
          toolTip={toolTip}
          labelKey="tables.date"
          onChangeDateHandler={setFormValue}
          name="date"
          commonStyle={{ width: 500, marginBottom: 10 }}
          setError={setDateError}
        />
        <AutocompletedInput
          error={error.company}
          disabled={disabledOffTargetsExist || !canByDeletedTransaction}
          dataTip={toolTip}
          label={t('contentHeader.companyName')}
          defaultValue={company}
          onBlurHandler={() => onBlurHandler('company', formData.company_id)}
          list={companies}
          onChangeHandler={onChangeCompanyHandler}
        />
        {!formData.adjusting_transaction ? (
          <FormControl error={error.currency}>
            <CustomInput
              labelKey="Type"
              name="type"
              onChange={setFormValue}
              onBlurHandler={() => onBlurHandler('type', formData.type)}
              disabled={disabledOffTargetsExist || !canByDeletedTransaction || formData.adjusting_transaction}
              defaultValue={formData.type}
              toolTip={toolTip || (formData.adjusting_transaction ? toolTipFromType : '')}
              select
              selectItems={[
                { value: 'IN', label: 'tables.in' },
                { value: 'OUT', label: 'tables.out' },
              ]}
              required
              isError={error.type}
              errorText={requiredText}
              inputWidth={170}
              stylesProp={{ width: 170 }}
            />
          </FormControl>
        ) : null}
        {!formData.adjusting_transaction ? (
          <div>
            <DateInput
              date={formData.receipt_date}
              disabled={!canByDeletedTransaction}
              toolTip={!canByDeletedTransaction ? toolTip : ''}
              labelKey="forms.transaction.receiptDate"
              onChangeDateHandler={setFormValue}
              name="receipt_date"
              commonStyle={{ width: 500, marginBottom: 10 }}
            />
          </div>
        ) : null}
        <div className={classes.amountBlock}>
          <AmountInput
            isNeedHandlePaste
            amount={formData.amount}
            disabled={disabledOffTargetsExist || !canByDeletedTransaction}
            error={error.amount}
            required
            toolTip={toolTip}
            allowNegative={formData.adjusting_transaction}
            label={t('tables.amount')}
            handleAmountChange={setFormValue}
            onBlurHandler={() => onBlurHandler('amount', formData.amount)}
            name="amount"
            inputWidth="257"
          />
          <FormControl error={error.currency}>
            <CustomInput
              labelKey="Currency"
              name="currency"
              onChange={setFormValue}
              onBlurHandler={() => onBlurHandler('currency', formData.currency)}
              disabled={disabledOffTargetsExist || !canByDeletedTransaction}
              defaultValue={formData.currency}
              toolTip={toolTip}
              select
              required
              selectItems={[
                { value: 'EUR', label: 'selectFiledValues.eur' },
                { value: 'USD', label: 'selectFiledValues.usd' },
                { value: 'RUB', label: 'selectFiledValues.rub' },
              ]}
              isError={error.currency}
              errorText={requiredText}
              inputWidth={150}
              stylesProp={{
                marginRight: 8,
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            disabled={distributeDisable || !canByDeletedTransaction}
            className={classes.disableButton}
            color="primary"
            onClick={handleDistribute}
          >
            {t('forms.transaction.distribute')}
          </Button>
        </div>

        {formData.adjusting_transaction && (
          <AdjustingTransactionForm
            fileName={formData.file_name}
            dataTip={toolTip}
            companyId={formData.company_id}
            handleTransactionIdChange={setFormData}
            disabled={disabledOffTargetsExist || !canByDeletedTransaction}
            handleRemoveFile={handleRemoveFile}
            handleChange={setFormValue}
            handleFileChange={handleFileChange}
            error={{ file: error.file, reference_id: error.reference_id }}
            fileSizeError={fileSizeError}
            src={src}
            setError={setError}
            formData={formData}
            currency={formData.currency}
          />
        )}

        <TotalAmountAlert amount={formData.amount} currency={formData.currency} targets={formData.targets} />
        <div className={classes.targetBlock}>
          <div className={classes.targetLabel}>{t('forms.transaction.targets')}</div>
          {formData.targets.map((target, index) => (
            <TargetRow
              key={target.keyId?.toString()}
              disabled={!canByDeletedTransaction}
              toolTip={toolTip}
              index={index}
              target={target}
              handleTargetsAddInFormData={handleTargetsAddInFormData}
              removeHandler={targetRemoveHandler}
              activationOrders={activationOrders}
              formData={formData}
              isCheckErrors={isCheckErrors}
            />
          ))}
          <div data-tip={!canByDeletedTransaction ? toolTip : ''} style={{ display: 'inline-flex' }}>
            <Button onClick={handleTargetAdd} disabled={!canByDeletedTransaction}>
              <AddIcon className={classes.addIcon} />
              <span>{t('forms.transaction.add')}</span>
            </Button>
          </div>
        </div>
        <CustomInput
          labelKey="tables.comment"
          name="comment"
          onChange={setFormValue}
          defaultValue={formData.comment}
          disabled={!canByDeletedTransaction}
          toolTip={!canByDeletedTransaction ? toolTip : ''}
          multiline
          rows={6}
          stylesProp={{ width: 500 }}
        />
      </div>
      <FormBottomBlock cancelHandler={cancelHandler} deleted={!canByDeletedTransaction} disabled={isPending} />
      <ReactTooltip />
    </form>
  );
};

export default TransactionFormBody;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingBottom: '0',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    marginTop: '16px',
    borderRadius: '4px',
    overflowX: 'auto',
  },
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  amountBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  disableButton: {
    height: '30px',
  },
  targetBlock: {
    marginBottom: '32px',
  },
  targetLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '1',
    marginBottom: '8px',
  },
  formControl: {
    height: '48px',
    marginBottom: '35px',
  },
  addIcon: {
    marginRight: '8px',
  },
  removeIcon: {
    marginRight: '8px',
  },
  line: {
    border: 'none',
    height: '1px',
    margin: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}));

import { CSSProperties } from 'react';
import { TableCellProps } from '@material-ui/core/TableCell/TableCell';

import { PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';

export const HEADER_DATA: { title: string; align: TableCellProps['align']; style?: CSSProperties }[] = [
  { title: 'tables.purchaseRequest.tableHeaders.company', align: 'left' },
  { title: 'tables.purchaseRequest.tableHeaders.ao', align: 'center' },
  { title: 'tables.purchaseRequest.tableHeaders.request', align: 'center' },
  { title: 'tables.purchaseRequest.tableHeaders.purchaseDate', align: 'right' },
  { title: 'tables.purchaseRequest.tableHeaders.purchasePrice', align: 'right' },
  { title: 'tables.purchaseRequest.tableHeaders.status', align: 'left', style: { paddingLeft: 64 } },
];

export const purchaseRequestStatus: { id: PurchaseRequestStatus; name: string }[] = [
  { id: PurchaseRequestStatus.NEW, name: 'New' },
  { id: PurchaseRequestStatus.REJECTED, name: 'Rejected' },
  { id: PurchaseRequestStatus.PENDING, name: 'Pending' },
  { id: PurchaseRequestStatus.SIGNED, name: 'Signed' },
  { id: PurchaseRequestStatus.FUNDED, name: 'Funded' },
  { id: PurchaseRequestStatus.REPAID, name: 'Repaid' },
];

export const MAP_STATUSES: Partial<Record<PurchaseRequestStatus, { id: PurchaseRequestStatus; name: string }[]>> = {
  [PurchaseRequestStatus.NEW]: [
    { id: PurchaseRequestStatus.NEW, name: 'NEW' },
    { id: PurchaseRequestStatus.PENDING, name: 'PENDING' },
  ],
  [PurchaseRequestStatus.PENDING]: [
    { id: PurchaseRequestStatus.SIGNED, name: 'PENDING' },
    { id: PurchaseRequestStatus.SIGNED, name: 'SIGNED' },
    { id: PurchaseRequestStatus.REJECTED, name: 'REJECTED' },
  ],
  [PurchaseRequestStatus.SIGNED]: [
    { id: PurchaseRequestStatus.SIGNED, name: 'SIGNED' },
    { id: PurchaseRequestStatus.FUNDED, name: 'FUNDED' },
    { id: PurchaseRequestStatus.REJECTED, name: 'REJECTED' },
  ],
  [PurchaseRequestStatus.FUNDED]: [
    { id: PurchaseRequestStatus.FUNDED, name: 'FUNDED' },
    { id: PurchaseRequestStatus.REPAID, name: 'REPAID' },
  ],
  [PurchaseRequestStatus.REJECTED]: [
    { id: PurchaseRequestStatus.REJECTED, name: 'REJECTED' },
    { id: PurchaseRequestStatus.NEW, name: 'NEW' },
  ],
};

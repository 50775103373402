import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatAmount } from 'helpers/formatters';
import { TotalAmountAlertPropsType } from 'types/TransactionFormTypes';
import { round2 } from 'helpers/num';

const TotalAmountAlert: FC<TotalAmountAlertPropsType> = ({ targets, amount, currency }: TotalAmountAlertPropsType) => {
  const { t } = useTranslation();
  let total = 0;

  if (!targets.length || !amount || !currency) {
    return null;
  }
  targets.forEach((target) => {
    if (target) {
      let amountLocal;

      if (target.order_currency === currency) {
        amountLocal = target.amount;
      } else {
        amountLocal = target.tx_currency_amount;
      }

      if (amountLocal) {
        total += Number(amountLocal);
      }
    }
  });

  if (!amount) {
    return null;
  }

  const diff = round2(Number(amount) - total);

  if (diff === 0) {
    return null;
  }

  const formattedTotal = formatAmount(total, currency);
  const formattedDiff = formatAmount(Math.abs(diff), currency);
  const style = { color: diff > 0 ? 'red' : 'blue', marginBottom: 5 };
  const diffWord = diff > 0 ? t('forms.transaction.more') : t('forms.transaction.less');

  const firstPart = t('forms.transaction.totalAmountAlert.alertTextFirsPart');
  const middlePart = t('forms.transaction.totalAmountAlert.alertTextMiddlePart');
  const lastPart = t('forms.transaction.totalAmountAlert.alertTextLastPart');

  const message = `${firstPart}${diffWord}${middlePart}${formattedTotal}${lastPart}${formattedDiff}`;

  return <span style={style}>{message}</span>;
};

export default TotalAmountAlert;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import useAmount, { useAmountType } from 'hooks/api/useAmount';
import { formatAmount } from 'helpers/formatters';
import { tableBodyStyles, tableSettingsStyles } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import { UseStylingButton } from 'hooks/useStylingForMaterialUi';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';
import SortTable from 'components/SortTable';
import { RefreshContext } from 'contexts/RefreshContext';
import { CommissionReportFilter } from 'consts/tableFilters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import getUrlParams from 'helpers/getUrlParams';

import { accrualTableParams } from './Header';
import { dataAccrualKey, tableCellData, tableNameHead } from './config';

const getColorTableCell = (key: string) => {
  switch (key) {
    case 'Total':
      return '#ECEEF2';
    default:
      return undefined;
  }
};

const AccrualReportTable = () => {
  const classes = tableSettingsStyles();
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const { accrualReportData }: useAmountType = useAmount();
  const stylingButton = UseStylingButton();
  const [isActive, setIsActive] = useState(false);
  const refreshContext = useContext(RefreshContext);

  const {
    requestParams: { _sort, _order },
  } = useMemo(() => getUrlParams(window.location.href, CommissionReportFilter), [window.location.search]);
  const handleChangeSorting = useCallback(
    (label, orderLocal) => {
      if (!label || !orderLocal) return;
      const paramsLocal = { _order: orderLocal, _sort: label };
      const newParams = refreshContext.getNewParams({
        datakey: accrualTableParams,
      });
      setUrlStringParams({
        filters: CommissionReportFilter,
        currentScreenParams: { ...newParams, ...paramsLocal },
      });
      refreshContext.setParams({ datakey: accrualTableParams, params: { ...newParams, ...paramsLocal } });
    },
    [refreshContext, setUrlStringParams],
  );

  const getValue = useCallback(
    (value, key = dataAccrualKey) => {
      if (key === dataAccrualKey.COMPANY_NAME || key === dataAccrualKey.CONTRACT_NUMBER) {
        return value;
      }

      if (key === dataAccrualKey.FILE_ID_INVOICE_IN_EUR || key === dataAccrualKey.FILE_ID_INVOICE_IN_USD) {
        return (
          <Button
            disabled={value === null || isActive}
            onClick={async () => {
              setIsActive(true);
              await getFileStorage(endpoints.getStorageFileUrl(value));
              setIsActive(false);
            }}
            style={{ height: 40 }}
            {...stylingButton}
          >
            {t('download')}
          </Button>
        );
      }

      return formatAmount<{ maximumFractionDigits: number }>(Number(value), null, false, {
        maximumFractionDigits: 2,
      });
    },
    [isActive, setIsActive, t],
  );

  return (
    <TableWrapWithPagination isShowPagination={false} styleTop={{ top: 115 }}>
      <TableHead>
        <TableRow classes={{ root: classes.headTableRowRoot }} style={{ height: 60 }}>
          {Children.toArray(
            tableNameHead.map(({ value, props, typeSort, key, isHiddenSort }) => (
              <TableCell
                classes={{ root: classes.headTableCellRoot }}
                align="center"
                {...props}
                style={{ verticalAlign: 'bottom' }}
              >
                <SortTable
                  onClickHandler={(order) => handleChangeSorting(key, order)}
                  isActive={_sort === key}
                  order={_order}
                  type={typeSort}
                  isHiddenSort={isHiddenSort}
                >
                  {t(value)}
                </SortTable>
              </TableCell>
            )),
          )}
        </TableRow>
        <TableRow classes={{ root: classes.headTableRowRoot }}>
          {Children.toArray(
            tableCellData.map(({ title }, i) => (
              <TableCell
                style={{ top: 65 }}
                classes={{ root: classes.headTableCellRoot }}
                align={i % 2 === 0 && i < 2 ? 'left' : 'right'}
              >
                {t(title)}
              </TableCell>
            )),
          )}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.bodyTableRoot }}>
        {accrualReportData.map((item, i) => (
          <TableRow classes={{ root: classes.bodyTableRowRoot }} key={item.id}>
            {Children.toArray(
              tableCellData.map(({ bodyAlign, dataKey }) => (
                <TableCell
                  style={{
                    backgroundColor:
                      i === accrualReportData.length - 1 ? getColorTableCell(item.contract_number) : undefined,
                  }}
                  classes={{
                    root: `${classes.bodyTableCellRoot} ${
                      dataKey === dataAccrualKey.COMPANY_NAME ? styles.nameWidth : ''
                    }`,
                  }}
                  align={bodyAlign || 'inherit'}
                >
                  {getValue(item[dataKey], dataKey)}
                </TableCell>
              )),
            )}
          </TableRow>
        ))}
      </TableBody>
    </TableWrapWithPagination>
  );
};

export default AccrualReportTable;

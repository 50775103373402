import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import useUser from 'hooks/api/useUser';
import urls from 'constants/urls';
import { tableBodyStyles } from 'theme/styles';
import MailtoLink from 'components/buttons/MailtoLink';

const AdminTable: FC = () => {
  const classes = tableBodyStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { adminsData } = useUser();
  const admins = useMemo(() => adminsData, [adminsData]);

  const rowClickHandler = (id: string) => {
    history.push(`${urls.getAdmin()}/${id}`);
  };

  return (
    <TableContainer className={classes.paper} style={{ overflowX: 'hidden' }}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.tHeadRow}>
            <TableCell align="left">{t('auth.input.username')}</TableCell>
            <TableCell align="left">{t('tables.fullName')}</TableCell>
            <TableCell align="left">{t('tables.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            admins.map((admin) => (
              <TableRow
                key={admin.id}
                hover
                className={classes.tBodyRow}
                style={!admin.active ? { fontStyle: 'italic', opacity: 0.7 } : {}}
              >
                <TableCell align="left" onClick={() => rowClickHandler(admin.id)} className={classes.fixedCell}>
                  {admin.user_name}
                </TableCell>
                <TableCell align="left" onClick={() => rowClickHandler(admin.id)} className={classes.fixedCell}>
                  {admin.full_name}
                </TableCell>
                <TableCell align="left" className={classes.fixedCell}>
                  <MailtoLink email={admin.email} />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminTable;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import TransactionsForm from 'components/tables/TransactionsTable/ViewsForm/TransactionsForm';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const TransactionsFormScreen: any = ({ ...props }) => {
  const { requestParams } = props;
  const location = useLocation();

  useEffect(() => {
    updateTitle(location.pathname.includes('edit') ? 'Edit Transaction' : 'Create Transaction');
  });

  return (
    <MainContent type="form">
      <TransactionsForm requestParams={requestParams} />
    </MainContent>
  );
};

export default TransactionsFormScreen;

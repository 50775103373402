// @ts-nocheck

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import useExpectedPayments, {
  expectedPaymentsAllDataType,
  expectedPaymentsDataType,
} from 'hooks/api/useExpectedPayments';
import { tableBodyStyles } from 'theme/styles';
import CreateTransactionCompanyPopUp from 'components/forms/CompanyForm/View/PopUpsCompany/CreateTransactionCompanyPopUp/CreateTransactionCompanyPopUp';
import useTransaction, { ItemTransactionsType, useTransactionType } from 'hooks/api/useTransaction';
import { formatDateIsoShort, getCodeCurrencyOfName } from 'helpers/formatters';
import { bodyCreateTransactionsType } from 'queries/transaction/createListTransactions';

import Row, { nameTargetEnum } from './Row';
import ExpectedPaymentsSkeleton from './ExpectedPaymentsSkeleton';
import styles from './styles.module.scss';

const isNumber = (number: string) => !Number.isNaN(number) && Number(number) !== 0;

const ExpectedPaymentsTable: FC<{ refreshTable: () => void }> = ({ refreshTable }) => {
  const [dataForPopup, setDataForPopup] = useState<null | any>(null);
  const isFirstTotal = useRef(true);
  const classes = tableBodyStyles();
  const { t } = useTranslation();
  const { expectedPaymentsData, isPending = true } = useExpectedPayments();
  const expectedPayments: expectedPaymentsAllDataType = useMemo(() => expectedPaymentsData, [expectedPaymentsData]);
  const { createListTransactions, setSyncTr }: useTransactionType = useTransaction();

  const getTotal = (currency: 'EUR' | 'USD') =>
    (expectedPayments?.report || []).reduce(
      (prevVal, curVal) => {
        if (curVal.orders[0].currency !== currency) return prevVal;

        const result = { ...prevVal };

        if (isNumber(curVal.total)) {
          result.total += Number(curVal.total);
        }
        curVal.orders.forEach((order) => {
          if (isNumber(order.commission)) {
            result.orders[0].commission += Number(order.commission);
          }

          if (isNumber(order.principal_amount)) {
            result.orders[0].principal_amount += Number(order.principal_amount);
          }

          if (isNumber(order.default_interest)) {
            result.orders[0].default_interest += Number(order.default_interest);
          }
        });

        return result;
      },
      {
        company_name: `Total ${currency}`,
        currency,
        total: 0,
        orders: [
          {
            commission: 0,
            principal_amount: 0,
            default_interest: 0,
          },
        ],
      },
    );

  useEffect(() => {
    isFirstTotal.current = true;
  });

  return (
    <TableContainer className={styles.root}>
      {dataForPopup !== null && (
        <CreateTransactionCompanyPopUp
          isOpen={Boolean(dataForPopup)}
          onClose={() => setDataForPopup(null)}
          firstCellTitle={t('forms.activationOrder.ao')}
          secondCellTitle={t('global.type')}
          secondCellContent={dataForPopup.secondCellContent}
          thirdCellTitle={t('forms.activationOrder.totalAmount')}
          companyId={dataForPopup.companyId}
          firstCellContent={dataForPopup.firstCellContent}
          thirdCellContent={dataForPopup.thirdCellContent}
          total={dataForPopup.total}
          currency={dataForPopup.currency}
          leftCallback={({ date, amount }) => {
            createListTransactions({
              body: {
                factoring_order_id: dataForPopup.factoring_order_id,
                factoring_order_number: dataForPopup.factoring_order_number,
                factoring_company_id: dataForPopup.factoring_company_id,
                factoring_company_name: dataForPopup.factoring_company_name,
                type: 'internal',
                category: dataForPopup.category,
                value_datetime: `${formatDateIsoShort(date)}T00:00:00.000Z`,
                amount: {
                  currency: getCodeCurrencyOfName(dataForPopup.currency),
                  amount: Number(amount),
                },
              } as unknown as bodyCreateTransactionsType,
              successCallback: (result: ItemTransactionsType) => {
                setSyncTr({ params: { id_transactions: result.id } });
                setDataForPopup(null);
                refreshTable();
              },
            });
          }}
          rightCallback={() => setDataForPopup(null)}
        />
      )}
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={styles.header}>
              {t('tables.repaymentDay')}
            </TableCell>
            <TableCell align="left" className={styles.header}>
              {t('tables.company')}
            </TableCell>
            <TableCell align="right" className={styles.header}>
              {t('tables.totalPayment')}
            </TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isPending &&
            expectedPayments &&
            expectedPayments?.report?.map((expectedPayment) => {
              if (
                [nameTargetEnum.EUR, nameTargetEnum.USD].includes(expectedPayment.company_name as nameTargetEnum) &&
                isFirstTotal.current
              ) {
                isFirstTotal.current = false;

                return (
                  <Row
                    expectedPayment={expectedPayment}
                    isFirstTotal
                    key={expectedPayment.id}
                    setDataForPopup={setDataForPopup}
                  />
                );
              }

              return (
                <Row expectedPayment={expectedPayment} key={expectedPayment.id} setDataForPopup={setDataForPopup} />
              );
            })}
          {!isPending && (
            <Row
              expectedPayment={getTotal('USD') as unknown as expectedPaymentsDataType}
              key="total-Usd"
              setDataForPopup={setDataForPopup}
            />
          )}
          {!isPending && (
            <Row
              expectedPayment={getTotal('EUR') as unknown as expectedPaymentsDataType}
              key="total-Eur"
              setDataForPopup={setDataForPopup}
            />
          )}
          {isPending && (
            <>
              {Array(15)
                .fill(0)
                .map((_e, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ExpectedPaymentsSkeleton key={index} />
                ))}
              <ExpectedPaymentsSkeleton isTotal />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpectedPaymentsTable;

import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { getToken } from 'helpers/token';
import useRoute from 'hooks/api/useRoute';
import urls from 'constants/urls';
import getUrlParams from 'helpers/getUrlParams';
import { useUserContext } from 'contexts/UserProvider';
import { UserKeyEnum, UserRole } from 'types/UserTypes';
import Layout from 'components/Layout';

type ComponentProps = RouteComponentProps<Record<string, string>>;

interface IPrivateRoute {
  component: (() => ReactElement) | FC<ComponentProps> | ((props: ComponentProps) => JSX.Element);
  exact?: boolean;
  computedMatch?: object;
  path: string;
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: any;
  filters?: Array<string>;
  styles?: React.CSSProperties;
}

const PrivateRoute: FC<IPrivateRoute> = ({ filters = [], component: Component, ...rest }: IPrivateRoute) => {
  const { setRoute } = useRoute();
  const { user } = useUserContext();

  const { requestParams } = getUrlParams(window.location.href, filters);

  useMemo(() => {
    setRoute({ route: rest.location.pathname });
  }, [setRoute, rest.location]);

  if (getToken() === null)
    return <Redirect to={`${urls.getAuth()}${rest.location.pathname ? rest.location.pathname : ''}`} />;

  if (user && user[UserKeyEnum.ROLE] !== UserRole.FACTOR_ADMIN) {
    return <Redirect to={urls.getNoAccess()} />;
  }

  return (
    <Layout styles={rest.styles}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Route {...rest} render={(props) => <Component requestParams={requestParams} {...props} />} />
    </Layout>
  );
};

export default PrivateRoute;

import { EGroups } from 'types/CompanyTypes';

export const currencyChoices = [
  { id: 'EUR', name: 'EUR' },
  { id: 'USD', name: 'USD' },
  { id: 'RUB', name: 'RUB' },
];

export const currencySelect = [
  { value: 'EUR', label: 'selectFiledValues.eur' },
  { value: 'USD', label: 'selectFiledValues.usd' },
  { value: 'RUB', label: 'selectFiledValues.rub' },
];

export const listCurrency = ['EUR', 'USD'];

export const currencyChoicesAutocomplete = [
  { id: 'EUR', name: 'EUR', active: true },
  { id: 'USD', name: 'USD', active: true },
];

export const transactionTypeChoices = [
  { id: 'IN', name: 'IN' },
  { id: 'OUT', name: 'OUT' },
];

export const targetTypeChoices = {
  IN: [
    { id: 'INTEREST_REPAY', name: 'Commission' },
    { id: 'BODY_REPAY', name: 'Principal Repayment Amount' },
    { id: 'PENALTY_REPAY', name: 'Default Interest' },
    { id: 'REVERSE_INCOME', name: 'Reverse' },
    { id: 'BANK_FEE_IN', name: 'Bank fee' },
    { id: 'BALANCE_PAYABLE', name: 'Balance Payable' },
  ],
  OUT: [
    { id: 'LOAN_ISSUE', name: 'Purchase Price' },
    { id: 'REVERSE_RETURN', name: 'Reverse' },
    { id: 'BANK_FEE_OUT', name: 'Bank fee' },
  ],
};

type TargetType = {
  id: string;
  name: string;
};

export const targetNames: TargetType[] = [
  { id: 'INTEREST_REPAY', name: 'Commission' },
  { id: 'BODY_REPAY', name: 'Principal Repayment Amount' },
  { id: 'PENALTY_REPAY', name: 'Default Interest' },
  { id: 'REVERSE_INCOME', name: 'Reverse' },
  { id: 'BANK_FEE_IN', name: 'Bank fee' },
  { id: 'LOAN_ISSUE', name: 'Purchase Price' },
  { id: 'REVERSE_RETURN', name: 'Reverse' },
  { id: 'BANK_FEE_OUT', name: 'Bank fee' },
  { id: 'BALANCE_PAYABLE', name: 'Balance Payable' },
];

export const fileMaxSize = 50000000; // 50 MB
export const fileLogoMaxSize = 3000000;

export const minusSymbol = String.fromCharCode(Number('0x2212'));

export const typeCompanies = [
  {
    id: 'client',
    name: 'Client',
  },
  {
    id: 'third-party',
    name: 'Third party',
  },
  {
    id: 'revenue-source',
    name: 'Revenue source',
  },
];

export const groups: EGroups[] = [EGroups.applaud, EGroups.classic, EGroups.applaud_fix];

export const statusOptions = ['NewUser', 'Moderation', 'Client', 'Blocked', 'Deleted', 'Archive', 'NoCompany'];

export const alignRight = 'right' as 'inherit' | 'left' | 'center' | 'right' | 'justify';
export const alignLeft = 'left' as 'inherit' | 'left' | 'center' | 'right' | 'justify';

export const purchaseRequestStatus = [
  { id: 'new', name: 'New' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'pending', name: 'Pending' },
  { id: 'signed', name: 'Signed' },
  { id: 'funded', name: 'Funded' },
  { id: 'repaid', name: 'Repaid' },
];

import { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { getCompanies } from 'api/company';
import { Company, CompanyKeyEnum } from 'types/CompanyTypes';

interface Option {
  label: string;
  value: string;
  source: Company;
}

const useLoadCompanies = (exceptFactor: boolean = false) => {
  const [companies, setCompanies] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const searchIdRef = useRef(0);

  const onSearch = useMemo(() => {
    const loadCompanies = (name: string) => {
      searchIdRef.current += 1;
      const searchId = searchIdRef.current;

      if (!name || name.length < 3) return;

      setLoading(true);
      setCompanies([]);

      getCompanies({ name, ...(exceptFactor && { type: ['revenue-source', 'client', 'third-party'] }) })
        .then((result) => {
          if (searchId !== searchIdRef.current) return;

          const newResult = result.items.map((company: Company) => ({
            label: company[CompanyKeyEnum.NAME],
            value: company[CompanyKeyEnum.ID],
            source: company,
          }));

          setLoading(false);
          setCompanies(newResult);
        })
        .catch(() => setLoading(false));
    };

    return debounce(loadCompanies, 200);
  }, []);

  const onClear = useCallback(() => setCompanies([]), []);

  return { onSearch, companies, setCompanies, onClear, loading };
};
export default useLoadCompanies;

import * as React from 'react';

import TransactionForm from 'components/forms/TransactionForm';
import ContentWrapper from 'components/ContentWrapper';

const ConversionTransactionCreateScreen = () => (
  <ContentWrapper>
    <TransactionForm transactionType="conversion" />
  </ContentWrapper>
);

export default ConversionTransactionCreateScreen;

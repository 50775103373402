import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getCompanyType {
  id: string;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({ id, errorCallback, resultKey = 'companyData' }: getCompanyType) =>
  requestAsync({
    url: endpoints.getCompanyUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompanyUrl(id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';
import { bindActionCreators } from 'redux';

import getContracts, { contractsDataType } from 'queries/contract/getContracts';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector } from './selectors';

export interface conractsDateType {
  date: Date;
  deleted: boolean;
  file_name: string;
  id: string;
  invoice_num: number;
  link: string;
  name: string;
  number: string;
  type: string;
}

export interface useContractType {
  getContracts?: (data: contractsDataType) => void;
  contractsData?: { namesContracts: { name: string; id: string }[]; contracts: conractsDateType[] };
  clearContractsData?: () => void;
}

const useContract = () => {
  const selector = useCallback(
    (state) => ({
      contractsData: arraySelector(state, 'contractsData'),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getContracts,
    }),
    [],
  );

  const contractActions = useMemo(
    () =>
      bindActionCreators(
        {
          clearContractsData: () =>
            updateEntities({
              contractsData: () => [],
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...contractActions,
  };
};

export default useContract;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { makeStyles } from '@material-ui/core';

import { formatAmount } from 'helpers/formatters';

type CurrencyCellPropsType = {
  amount: number;
  currency: string;
  type?: string;
  sign?: string;
  wrapperStyle?: React.CSSProperties;
};

const CurrencyCell = ({
  amount,
  currency,
  type = undefined,
  sign = undefined,
  wrapperStyle,
}: CurrencyCellPropsType) => {
  let localAmount = amount;

  const classes = useStyles();

  const colors = {
    green: '#26B634',
    gray: '#000000',
    black: '#000',
    red: '#ef0e0e',
  };
  const symbols = {
    EUR: '€',
    USD: '$',
    RUB: '₽',
  };

  const color = colors[type];
  const symbol = symbols[currency];

  if (!localAmount) {
    localAmount = 0;
  }

  return (
    <div className={classes.cell} style={wrapperStyle}>
      {sign && (
        <span className={classes.sign} style={color !== undefined ? { color } : {}}>
          {sign}
        </span>
      )}
      <span style={color !== undefined ? { color } : {}}>{symbol !== undefined ? symbol : null}</span>
      <span style={color !== undefined ? { color } : {}}>{formatAmount(localAmount)}</span>
    </div>
  );
};

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  sign: {
    marginRight: 3,
  },
});

export default CurrencyCell;

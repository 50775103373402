import React, { FC, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ModalComponent, { ModalProps } from '@material-ui/core/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';

import Button from 'components/uiKit/Button';

import styles from './styles.module.scss';

interface Props extends ModalProps {
  onClose: () => void;
  actionsClassName?: string;
  rootClassName?: string;
  title: string;
  actions?: ReactNode;
  onOpen?: () => void;
}

const CommonModal: FC<Props> = ({
  open,
  onClose,
  title,
  children,
  actionsClassName,
  rootClassName,
  actions,
  onOpen,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const refOnOpen = useRef<() => void | undefined>();
  refOnOpen.current = onOpen;

  useEffect(() => {
    open && refOnOpen.current?.();
  }, [open]);

  return (
    <ModalComponent
      aria-labelledby={`modal-${title}`}
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
      }}
      closeAfterTransition
      {...otherProps}
    >
      <div className={classNames(styles.root, rootClassName)}>
        <span className={styles.title}>{title}</span>
        {children}
        <div className={classNames(styles.actions, actionsClassName)}>
          {actions}
          <Button onClick={onClose} gutters={[8, 20]}>
            {t('global.cancelC')}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default CommonModal;

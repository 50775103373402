import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type userDataType = {
  id: string;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ id, errorCallback, resultKey = 'userData' }: userDataType) =>
  requestAsync({
    url: endpoints.getUserUrl(id),
    transform: (response) => ({
      [resultKey]: {
        authProvider: response.auth_provider,
        fullName: response.full_name,
        username: response.user_name,
        ...response,
      },
    }),
    queryKey: endpoints.getUserUrl(id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type contractDataType = {
  id: string;
  errorCallback: Function;
  resultKey?: string;
  title: string;
  type: string;
  number: string;
  date: string;
  link: string;
};

export default ({ id, errorCallback, resultKey = 'contractData' }: contractDataType) =>
  requestAsync({
    url: endpoints.getContractUrl(id),
    transform: (response) => ({
      [resultKey]: {
        companyName: response.company_name,
        ...response,
      },
    }),
    queryKey: endpoints.getContractUrl(id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

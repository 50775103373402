import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface IGetCountriesParams {
  resultKey?: string;
  successCallback?: Function;
  errorCallback?: Function;
  withoutErrorText?: boolean;
}

export default ({
  resultKey = 'countriesData',
  successCallback,
  errorCallback,
  withoutErrorText,
}: IGetCountriesParams) =>
  requestAsync({
    url: endpoints.getCountriesUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCountriesUrl(),
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
      withoutErrorText,
    },
    options: {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

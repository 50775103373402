import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

import styles from './styles.module.scss';

type PropsT = {
  title: string;
};

const GeneralNew: FC<PropsT> = ({ title, children }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);

  const showContentHandler = () => {
    setIsShow((currentState) => !currentState);
  };

  return (
    <>
      <div className={styles.buttonCustomSettings}>
        <Button style={{ padding: 0, lineHeight: 1.2 }} color="primary" onClick={showContentHandler}>
          {isShow ? <Remove fontSize="small" /> : <Add fontSize="small" />}
          <span className={styles.buttonTextCustomSwitch}>{t(title)}</span>
        </Button>
      </div>
      {isShow && <div>{children}</div>}
    </>
  );
};

export default GeneralNew;

import React, { FC, useCallback, Children, useState, CSSProperties } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';

const plusSvg = require('assets/img/plus.svg').default;
const clipSvg = require('assets/img/clip.svg').default;
const acrossSvg = require('assets/img/across.svg').default;

export interface DropZoneDocumentPropsType {
  title?: string;
  text?: string | string[];
  buttonText?: string;
  multiple?: boolean;
  acceptFormat?: string | string[];
  maxSize?: number;
  wrapperStyle?: CSSProperties;
  additionalClassNameForContent?: string;
  accessFiles?: File[];
  setAccessFiles?: (files: File[]) => void;
  isHiddenDropZoneArea?: boolean;
}

enum typeDropZoneEnum {
  ACCESS = 'access',
  REJECTED = 'rejected',
}

const DropZoneDocument: FC<DropZoneDocumentPropsType> = ({
  title,
  text,
  buttonText,
  multiple,
  acceptFormat = 'application/pdf',
  additionalClassNameForContent,
  maxSize = 5e7,
  accessFiles = [],
  wrapperStyle,
  setAccessFiles,
  isHiddenDropZoneArea,
}) => {
  const classes = useStyles();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const [rejectFiles, setRejectFiles] = useState<FileRejection[]>([]);
  const isFile = accessFiles.length > 0 || rejectFiles.length > 0;

  const { getRootProps, getInputProps } = useDropzone({
    maxSize,
    accept: acceptFormat,
    onDropAccepted: (files) => {
      const checkFiles = files.filter(
        (item) =>
          !accessFiles.find(
            (itemLocal) => item.name === itemLocal.name && item.size === itemLocal.size && item.type === itemLocal.type,
          ),
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAccessFiles(!multiple ? [files[0]] : [...accessFiles, ...checkFiles]);
    },
    onDropRejected: (files) => {
      const checkFiles = files.filter(
        ({ file }) =>
          !rejectFiles.find(
            ({ file: { size, name, type } }) => file.name === name && file.size === size && file.type === type,
          ),
      );
      setRejectFiles([...rejectFiles, ...checkFiles]);
    },
  });

  const acceptedFilesUI = useCallback(
    (file: File, type: typeDropZoneEnum) => (
      <div>
        <div className={classes.acceptedFiles}>
          <img src={clipSvg} alt="left" />
          <span className={classes.acceptedFilesName}>{file.name}</span>
          <span className={classes.acceptedFilesSize}>{file.size}</span>
          <img
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              type === typeDropZoneEnum.ACCESS && setAccessFiles(accessFiles.filter((item) => item !== file));
              type === typeDropZoneEnum.REJECTED && setRejectFiles((prev) => prev.filter((item) => item.file !== file));
            }}
            style={{ cursor: 'pointer' }}
            src={acrossSvg}
            alt="right"
          />
        </div>
      </div>
    ),
    [accessFiles],
  );

  return (
    <section className="container">
      {!isHiddenDropZoneArea && (
        <div
          style={{ marginBottom: isFile ? 12 : 0, ...wrapperStyle }}
          {...getRootProps({ className: classes.dropZoneDocument })}
        >
          <input {...getInputProps()} />
          <div className={`${classes.dropZoneContent} ${additionalClassNameForContent}`}>
            <div className={classes.dropZoneTitle}>{title}</div>
            {Array.isArray(text) ? (
              Children.toArray(text.map((txt) => <div className={classes.dropZoneText}>{txt}</div>))
            ) : (
              <div className={classes.dropZoneText}>{text}</div>
            )}
            <Button
              {...dropZoneStylingButton}
              className={classes.dropZoneButton}
              startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      )}
      <aside>
        {Children.toArray(
          accessFiles.map((file, i) => {
            if (i !== 0 && !multiple) {
              return null;
            }

            return acceptedFilesUI(file, typeDropZoneEnum.ACCESS);
          }),
        )}
        {Children.toArray(
          rejectFiles.map((data, i) => {
            if (i !== 0 && !multiple) {
              return null;
            }

            return (
              <div style={{ position: 'relative' }}>
                {acceptedFilesUI(data.file, typeDropZoneEnum.REJECTED)}
                <div className={classes.dropZoneErrorItem}>
                  {data.errors.map((e) => (
                    <div key={e.code}>{e.message}</div>
                  ))}
                </div>
              </div>
            );
          }),
        )}
      </aside>
    </section>
  );
};

const useStyles = makeStyles(() => ({
  dropZoneButton: {
    width: 150,
    height: 40,
    padding: '8px 12px',
    '&:hover .dropZoneImg': {
      filter: 'invert(37%) sepia(28%) saturate(314%) hue-rotate(199deg) brightness(92%) contrast(83%)',
    },
    marginTop: 20,
  },
  dropZoneErrorItem: {
    color: '#EC3E72',
  },
  acceptedFilesSize: {
    color: '#606176',
    fontSize: '16px',
    lineHeight: '19px',
    marginRight: 8,
  },
  acceptedFilesName: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#29292C',
    margin: '0px 8px',
  },
  acceptedFiles: {
    padding: '6.5px 13px',
    background: '#ECEEF2',
    borderRadius: 8,
    display: 'inline-flex',
    alignItems: 'center',
  },
  dropZoneTitle: {
    color: '#606176',
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 4,
  },
  dropZoneText: {
    color: '#838998',
    fontSize: 12,
    lineHeight: '14px',
  },
  dropZoneContent: {
    width: 270,
    textAlign: 'center',
  },
  dropZoneDocument: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 160,
    border: '1px dashed #606176',
    borderRadius: 8,
    padding: '24px 0',
  },
}));

export default DropZoneDocument;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Tab, Tabs } from '@material-ui/core';
import React, { FC, Children } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface MenuItemAoProps {
  order?: number;
  items: string[];
  styleTabs?: React.CSSProperties;
  setValue?: (order: number) => void;
}

const MenuItemAo: FC<MenuItemAoProps> = ({ order = 0, items, styleTabs, setValue }) => {
  const classes = useStyles();
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      style={{ ...styleTabs }}
      value={order}
      classes={{ indicator: classes.indicator, scroller: classes.scrollTabs }}
      onChange={handleChange}
    >
      {Children.toArray(
        items.map((item) => (
          <Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.selectedTab }} label={item} />
        )),
      )}
    </Tabs>
  );
};

const useStyles = makeStyles(() => ({
  scrollTabs: {
    display: 'flex',
  },
  indicator: {
    backgroundColor: '#000000',
  },
  selectedTab: {
    color: '#000000 !important',
  },
  tabRoot: {
    '&:last-child': {
      marginRight: 0,
    },
    opacity: 1,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
    color: '#707A87',
    padding: '0px 8px',
    marginRight: 20,
    minWidth: 'inherit',
    minHeight: 'inherit',
  },
}));

export default MenuItemAo;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { history } from '../configureStore';

const setUrlStringParams = ({ filters, currentScreenParams }) => {
  const filterParams = filters.filter(
    (param) =>
      currentScreenParams[`${param}`] !== undefined &&
      currentScreenParams[`${param}`] !== null &&
      currentScreenParams[`${param}`].toString().length > 0,
  );

  const str = filterParams
    .map((param, idx) => {
      let newValue = '';

      if (idx + 1 === filterParams.length) {
        newValue = `${param}=${currentScreenParams[`${param}`]}`;
      }

      if (idx === 0) {
        newValue = `?${param}=${currentScreenParams[`${param}`]}${filterParams.length !== 1 ? '&' : ''}`;
      }

      if (idx !== 0 && idx + 1 !== filterParams.length) {
        newValue = `${param}=${currentScreenParams[`${param}`]}&`;
      }

      return newValue;
    })
    .join('');

  history.push(`${window.location.pathname}${str}`);
};

export const getUrlStringParams = ({ filters, currentScreenParams }) => {
  const filterParams = filters.filter(
    (param) =>
      currentScreenParams[`${param}`] !== undefined &&
      currentScreenParams[`${param}`] !== null &&
      currentScreenParams[`${param}`].toString().length > 0,
  );

  const str = filterParams
    .map((param, idx) => {
      let newValue = '';

      if (idx + 1 === filterParams.length) {
        newValue = `${param}=${currentScreenParams[`${param}`]}`;
      }

      if (idx === 0) {
        newValue = `?${param}=${currentScreenParams[`${param}`]}${filterParams.length !== 1 ? '&' : ''}`;
      }

      if (idx !== 0 && idx + 1 !== filterParams.length) {
        newValue = `${param}=${currentScreenParams[`${param}`]}&`;
      }

      return newValue;
    })
    .join('');

  return `${window.location.pathname}${str}`;
};

export default setUrlStringParams;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SwitcherButton from 'components/uiKit/SwitcherButton';
import { contractDataBaseType } from 'hooks/api/useContracts';

import Agreements from '../Agreements';
import ReportingDocuments from '../ReportingDocuments';
import AOClient from '../AOClient';

import styles from './styles.module.scss';

interface Props {
  setShowAgreementButton: (value: boolean) => void;
  companyId?: string;
  activeMainTab: string;
  setCurrentDocument: (data: contractDataBaseType) => void;
  setIsOpen: (data: boolean) => void;
}

const Docs: FC<Props> = ({ setShowAgreementButton, companyId, setCurrentDocument, setIsOpen, activeMainTab }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (activeMainTab === 'general-docs') setShowAgreementButton(currentTabIndex === 3);
  }, [currentTabIndex, setShowAgreementButton, activeMainTab]);

  return (
    <div className={styles.wrapper}>
      <SwitcherButton
        style={{ height: 40, marginBottom: 12 }}
        list={[
          {
            title: t('clientTabs.invoices'),
          },
          {
            title: t('clientTabs.reports'),
          },
          {
            title: t('clientTabs.ao'),
          },
          {
            title: t('clientTabs.general'),
          },
        ]}
        current={currentTabIndex}
        callback={setCurrentTabIndex}
      />
      {(currentTabIndex === 0 || currentTabIndex === 1) && (
        <ReportingDocuments companyId={companyId} current={currentTabIndex} />
      )}
      {currentTabIndex === 2 && <AOClient companyId={companyId} />}
      {currentTabIndex === 3 && (
        <Agreements setCurrentDocument={setCurrentDocument} companyId={companyId} setIsOpen={setIsOpen} />
      )}
    </div>
  );
};

export default Docs;

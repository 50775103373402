import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import ClientAmountReportTableHeader from 'components/tables/ClientAmountReportTable/Header';
import ClientAmountReportTable from 'components/tables/ClientAmountReportTable';
import { updateTitle } from 'helpers/common';

const wrapperStyle = {
  padding: '20px 40px 16px 40px',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '108px calc(100% - 108px)',
  height: 'calc(100% - 36px)',
  backgroundColor: '#F7F8FA',
};

const ClientAmountsReportScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Client Amounts');
  }, []);

  return (
    <ContentWrapper style={wrapperStyle}>
      <ClientAmountReportTableHeader requestParams={requestParams} />
      <ClientAmountReportTable />
    </ContentWrapper>
  );
};

export default ClientAmountsReportScreen;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonModal from 'components/uiKit/CommonModal';
import Button from 'components/uiKit/Button';
import Status, { CompanyStatus, CompanyStatusArray, CompanyStatusToName } from 'components/common/Status';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';

import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  handleConfirmClick: (newStatus: CompanyStatus) => void;
  currentStatus: CompanyStatus;
};

const ChangeStatusModal: FC<Props> = ({ isOpen, onClose, handleConfirmClick, currentStatus }) => {
  const [newStatus, setNewStatus] = useState<CompanyStatus>();
  const { t } = useTranslation();

  useEffect(() => {
    setNewStatus(currentStatus);
  }, []);

  return (
    <CommonModal
      open={isOpen}
      onClose={onClose}
      title={t('company.changeStatus')}
      actions={
        <Button onClick={() => handleConfirmClick(newStatus)} variant="fill" disabled={currentStatus === newStatus}>
          {t('company.saveStatus')}
        </Button>
      }
    >
      <div className={styles.dropdownWrapper}>
        <DropdownKit
          required
          label={t('company.status')}
          options={CompanyStatusArray}
          positionType={DropdownSizeEnum.SIZE_60}
          wrapStyle={{ width: '245px' }}
          isHiddenMenuAll
          changeSelectItem={(_e, data) => setNewStatus(data)}
          value={CompanyStatusToName[newStatus]}
          customOption={(status: CompanyStatus, _isActive, handleMouseDown) => (
            <div className={styles.dropdownItem} onClick={handleMouseDown}>
              <Status status={status} className={styles.dropdownStatus} />
            </div>
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ChangeStatusModal;

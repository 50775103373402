import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

interface DistributionPlatformType {
  platform_id: string;
  purchase_percentage: string;
  currency: string;
  commission: string;
  funding_limit: number;
}

export interface bodyFutureReceivables {
  company_id: string;
  contract_id: string;
  capitalization: string;
  amount: string;
  commission: string;
  purchase_date: string;
  due_date: string;
  name: string;
  currency: string;
  purpose: string;
  registration_number: string;
  vat_policy: string;
  distribution_platforms: DistributionPlatformType[];
  activation_date: string;
}

export type createFutureReceivablesDataType = {
  body: bodyFutureReceivables;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  successCallback,
  errorCallback,
  resultKey = 'CreateFutureReceivables',
}: createFutureReceivablesDataType) =>
  requestAsync({
    url: endpoints.getCreateFutureReceivablesUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getCreateFutureReceivablesUrl()}`,
    body,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

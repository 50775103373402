// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';

import { contractDataBaseType } from 'hooks/api/useContracts';
import { dateRangeValidator, getDateByYearsAgo, getErrorTextByErrorType } from 'helpers/dt';
import DateInput from 'components/inputs/DateInput';
import { convertFileToBase64 } from 'helpers/file';
import PopUpLayout from 'helpers/PopUpLayout';
import { UseStylingButton, UseStylingDropZoneButton, UseStylingTextField } from 'hooks/useStylingForMaterialUi';

import { getInitialErrors, initialContractFormData, ErrorType, contractDataType } from '../data';
import { FormControlCustomInput } from '../FormFields';
import DropZoneDocument from '../DropZoneDocument';

import classes from './styles.module.scss';

const deleteSvg = require('assets/img/delete.svg').default;

interface PopUpContractPropsType {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  currentDocument?: contractDataBaseType | null;
  setCurrentDocument?: (data: contractDataBaseType | null) => void;
  deleteCallback?: (contract: contractDataBaseType) => void;
  successCallback?: (contract: contractDataType, document: contractDataBaseType | null) => void;
}
const PopUpContract: FC<PopUpContractPropsType> = ({
  isOpen,
  setIsOpen,
  currentDocument,
  setCurrentDocument,
  deleteCallback,
  successCallback,
}) => {
  const stylingButtonPopUp = UseStylingButton();
  const dropZoneStylingButtonPopUp = UseStylingDropZoneButton();
  const { t } = useTranslation();
  const requiredText = useMemo(() => t('auth.required'), [t]);
  const stylingTextField = UseStylingTextField();
  const [error, setError] = useState<ErrorType>(getInitialErrors(requiredText));
  const [contractFormData, setContractFormData] = useState(initialContractFormData);
  const [file, setFile] = useState<File[]>([]);

  useEffect(() => {
    if (!currentDocument) {
      setContractFormData(initialContractFormData);
      setError(getInitialErrors(requiredText));

      return;
    }
    setContractFormData({ ...currentDocument, file_data: '' });
    setError(getInitialErrors(requiredText));
  }, [currentDocument]);

  const onSaveContract = useCallback(() => {
    if (
      !!contractFormData.date &&
      !!contractFormData.name &&
      contractFormData.name.length > 0 &&
      !!contractFormData.number &&
      contractFormData.number.length > 0 &&
      ((!!contractFormData.file_data && contractFormData.file_data.length > 0) ||
        (!!contractFormData.link && contractFormData.link.length > 0))
    ) {
      successCallback(contractFormData, currentDocument);
      cancelFunc();
    }
  }, [contractFormData]);

  const nameValidateHandler = useCallback(
    (value) => {
      setError((prevState) => ({ ...prevState, name: { status: !(value && value.length > 0), text: requiredText } }));
    },
    [requiredText],
  );

  const setErrorHandler = useCallback(
    ({ name, value }) => {
      if (error[name]) {
        if (!value && name !== 'date') {
          setError((prevState) => ({ ...prevState, [name]: { status: true, text: requiredText } }));

          return;
        }
        let dError;
        let dataErrorText;

        if (name === 'date') {
          const min = name === 'date' ? getDateByYearsAgo(3) : '1900-01-01';
          const limit = { min, max: '2099-12-31' };
          dError = dateRangeValidator({ date: value, min });
          dataErrorText = getErrorTextByErrorType({
            type: dError.type,
            minText: t('dateErrors.min'),
            maxText: t('dateErrors.max'),
            limit,
          });
          setError((prev) => ({
            ...prev,
            contractDate: {
              status: !dError.validate,
              text: !dError.validate ? dataErrorText : requiredText,
            },
          }));

          return;
        }

        if (name === 'name') {
          nameValidateHandler(value);

          return;
        }
        setError((prev) => ({ ...prev, [name]: { status: false, text: requiredText } }));
      }
    },
    [nameValidateHandler, error, t, requiredText],
  );

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setErrorHandler({ name, value });
      setContractFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setErrorHandler],
  );

  const setDateErrors = useCallback((dateField: string) => {
    setError((prev) => ({ ...prev, [dateField]: { status: true, text: prev[dateField].text } }));
  }, []);

  const onBlurHandler = useCallback(
    (field: string) => {
      const fieldValue = contractFormData[field];
      setErrorHandler({ name: field, value: fieldValue });
    },
    [setErrorHandler, contractFormData],
  );

  const cancelFunc = useCallback(() => {
    setCurrentDocument(null);
    setIsOpen(false);
  }, []);

  const handleFileChange = useCallback(
    async (access: File[]) => {
      if (!access[0]) {
        setFile(access);
        setContractFormData((prev) => ({ ...prev, file_data: '' }));

        return;
      }
      const fileUrl: string = (await convertFileToBase64(access[0])) as string;
      setFile(access);
      setContractFormData((prev) => ({ ...prev, file_data: fileUrl }));
      setError((prev) => ({ ...prev, file: !fileUrl }));
    },
    [setContractFormData],
  );

  return (
    <PopUpLayout open={isOpen} onClose={cancelFunc}>
      <div className={classes.wrapperContract}>
        <div className={classes.titleContract}>
          {currentDocument?.id ? currentDocument.name : t('forms.companies.agreements.createNewAgreement')}
          {currentDocument && (
            <img
              onClick={() => {
                if (!deleteCallback) return;
                deleteCallback(currentDocument);
                cancelFunc();
              }}
              className={classes.deleteContract}
              src={deleteSvg}
              alt="delete"
            />
          )}
        </div>
        {FormControlCustomInput &&
          FormControlCustomInput.map((item) => (
            <TextField
              {...stylingTextField}
              key={item.name}
              label={t(item.labelKey)}
              name={item.name}
              style={{
                width: '100%',
                height: 84,
              }}
              inputProps={{ style: { height: 60 } }}
              onChange={setFormValue}
              value={contractFormData[item.defaultValue]}
              required={item.required}
              onBlur={() => onBlurHandler(item.onBlurHandler)}
              error={error[item.isError].status}
              helperText={error[item.isError].status ? error[item.errorText].text : ''}
            />
          ))}
        <div className={classes.dateField}>
          <DateInput
            date={contractFormData.date}
            required
            isError={error.date.status}
            errorText={error.date.text}
            onBlurHandler={onBlurHandler}
            labelKey="tables.date"
            setError={() => setDateErrors('date')}
            onChangeDateHandler={setFormValue}
            name="date"
            textFieldStyle={{ marginBottom: 24 }}
          />
        </div>
        <div className={classes.dropZoneContainer}>
          <DropZoneDocument
            wrapperStyle={{ height: 145 }}
            setAccessFiles={handleFileChange}
            accessFiles={file}
            title={t('clientTabs.dropZoneTitle')}
            text={t('clientTabs.dropZoneText')}
            buttonText={t('clientTabs.dropZoneButton')}
          />
        </div>
        <div className={classes.popUpContractButtons}>
          <Button
            onClick={onSaveContract}
            style={{ width: currentDocument ? 137 : 172, height: 60, marginRight: 40 }}
            {...stylingButtonPopUp}
          >
            {currentDocument ? t('clientTabs.save') : t('clientTabs.createAgreement')}
          </Button>
          <Button
            onClick={cancelFunc}
            style={{ width: 89, height: 60, padding: '20px 18px' }}
            {...dropZoneStylingButtonPopUp}
          >
            {t('clientTabs.cancel')}
          </Button>
        </div>
      </div>
    </PopUpLayout>
  );
};

export default PopUpContract;

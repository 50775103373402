import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import updateUtils from 'actions/updateUtils';
import getContracts from 'queries/contract/getContracts';
import getContractsV2 from 'queries/contract/getContractsV2';
import getDocumentsById from 'queries/universal/getDocumentsById';
import getDocumentsByIdV2 from 'queries/universal/getDocumentsByIdV2';
import getContract from 'queries/contract/getContract';
import createContract from 'queries/contract/createContract';
import editContract from 'queries/contract/editContract';
import deleteContract from 'queries/contract/deleteContract';
import getAOByContract from 'queries/contract/getAOByContract';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

type contractTableParamsType = {
  id: string;
};

const initialContractTableParams: contractTableParamsType = {
  id: '',
};

export interface contractDataBaseType {
  date: string;
  id: string;
  invoice_num: number;
  link: string;
  name: string;
  number: string;
  type: string;
  file_name?: string | string[];
}

const useContract = () => {
  const selector = useCallback(
    (state) => ({
      contractsData: arraySelector(state, 'contractsData') as contractDataBaseType[],
      contractsDataV2: arraySelector(state, 'contractsDataV2'),
      contractsTableDataV2: arraySelector(state, 'contractsTableDataV2'),
      contractsTableDataV3: arraySelector(state, 'contractsTableDataV3'),
      contractData: arraySelector(state, 'contractData'),
      aOByContractData: arraySelector(state, 'aOByContractData'),
      createContractData: objectSelector(state, 'createContractData'),
      editContractData: objectSelector(state, 'editContractData'),
      deleteContractData: objectSelector(state, 'deleteContractData'),
      countriesData: arraySelector(state, 'countriesData'),
      contractTableParams: utilsSelector(state, 'contractTableParams', initialContractTableParams),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getContracts,
      getContractsV2,
      getDocumentsById,
      getDocumentsByIdV2,
      getContract,
      createContract,
      editContract,
      deleteContract,
      getAOByContract,
    }),
    [],
  );

  const contractTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setContractTableParams: (contractTableParams: contractTableParamsType) =>
            updateUtils({
              contractTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...contractTableActions,
  };
};

export default useContract;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

import { bodyFutureReceivables } from './createFutureReceivables';

export type editFutureReceivablesDataType = {
  id: string;
  body: bodyFutureReceivables;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({
  id,
  body,
  successCallback,
  errorCallback,
  resultKey = 'editFutureReceivables',
}: editFutureReceivablesDataType) =>
  requestAsync({
    url: endpoints.getEditFutureReceivablesUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getEditFutureReceivablesUrl(id)}`,
    body,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import LoadDataForm from 'components/forms/LoadDataForm';
import { updateTitle } from 'helpers/common';

const ImportedDataScreen = () => {
  useEffect(() => {
    updateTitle('Import Data');
  }, []);

  return (
    <ContentWrapper>
      <LoadDataForm type="import_data" />
    </ContentWrapper>
  );
};

export default ImportedDataScreen;

import React, { memo } from 'react';
import { LoaderIcon } from '@gamesb42/ui-kit';

import styles from './styles.module.scss';

const NoFoundContent = memo(() => (
  <div className={styles.root}>
    <LoaderIcon size={24} />
  </div>
));

export default NoFoundContent;

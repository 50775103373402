import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getAccountsByIdCompanyType {
  company_id: string;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
  balanceDate?: string;
}

export default ({
  errorCallback,
  resultKey = 'getAccountsByIdCompany',
  successCallback,
  company_id,
  balanceDate,
}: getAccountsByIdCompanyType) =>
  requestAsync({
    url: endpoints.getAccountsByIdCompanyUrl(company_id, balanceDate ? { 'balance-date': balanceDate } : {}),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAccountsByIdCompanyUrl(company_id, balanceDate ? { 'balance-date': balanceDate } : {}),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableCell, TableBody, TableContainer, IconButton, Button } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import RemoveFileIcon from '@material-ui/icons/RemoveCircle';

import { tableBodyStyles } from 'theme/styles';
import { fileLogoMaxSize } from 'consts/dict';
import { convertFileToBase64 } from 'helpers/file';
import useErrorCallback from 'hooks/useErrorCallback';
import useAlert from 'hooks/api/useAlert';
import usePlatform from 'hooks/api/usePlatform';
import SwitchButton from 'components/buttons/SwitchButton';
import { InputKit } from 'components/uiKit/InputKit';
import { UseStylingButton } from 'hooks/useStylingForMaterialUi';
import DropZoneDocument from 'components/forms/CompanyForm/DropZoneDocument';
import { AmountKit } from 'components/uiKit/AmountKit';

import { FormControlCustomInput } from './FormFields';
import {
  GeneralTabPropsType,
  getInitialError,
  initialPlatformFormData,
  ErrorType,
  documentTabData,
  DocumentTabKeys,
  platformDataType,
} from './data';

const PlatformPanel: FC<GeneralTabPropsType> = () => {
  const classes = useStyles();
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const stylingButton = UseStylingButton();
  const requiredText = useMemo(() => t('auth.required'), [t]);
  const [error, setError] = useState<ErrorType>(getInitialError(requiredText));
  const { errorCallback } = useErrorCallback(() => {});
  const { errorCallback: errorAdvanceCallback } = useErrorCallback(() => {
    setAlert({ type: 'error', message: 'Incorrect CSV file' });
  });
  const { platformData, getDocuments, createPlatform, editPlatform, createAvailableAdvancePlatform } = usePlatform();
  const platforms = useMemo(() => platformData, [platformData]);
  const [platformFormData, setPlatformFormData] = useState(initialPlatformFormData);
  const [uploadAvailableAdvancedData, setUploadAvailableAdvancedData] = useState<platformDataType['file']['url']>('');
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [fileCSVSizeError, setFileCSVSizeError] = useState<boolean>(false);
  const [rowEdit, setRowEdit] = useState<string>(null);
  const [src, setSrc] = useState<string>(platformFormData?.file?.rawFile || '');

  const inputFile = useRef(null);
  const { setAlert } = useAlert();
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [file, setFile] = useState<File[]>([]);

  useEffect(() => {
    getDocuments({
      endpoint: 'getPlatforms',
      resultKey: 'platformData',
      params: { show_deleted: showDeleted },
      errorCallback,
    });
  }, [getDocuments, showDeleted, errorCallback]);

  const successCallback = useCallback(() => {
    getDocuments({
      endpoint: 'getPlatforms',
      resultKey: 'platformData',
      params: { show_deleted: showDeleted },
      errorCallback,
    });
    setPlatformFormData((prev) => ({
      ...prev,
      name: '',
      logo: '',
      deleted: false,
    }));
    setSrc('');
    setAlert({
      type: 'success',
      message: `${t('alert.platformUploaded')} `,
    });
  }, [t, setAlert, setPlatformFormData, platformFormData]);

  const availableAdvanceSuccessCallback = useCallback(
    (body) => {
      setUploadAvailableAdvancedData('');

      if (body.errors) {
        setAlert({
          type: 'error',
          message: <pre>{body.errors}</pre>,
        });
      }

      if (body.lines_imported) {
        setAlert({
          type: 'success',
          message: `${t('alert.availableAdvanceUploaded')} ${body.lines_imported}`,
        });
      }
    },
    [t, setAlert, uploadAvailableAdvancedData, setUploadAvailableAdvancedData],
  );

  const onSavePlatform = useCallback(() => {
    if (
      !!platformFormData.name &&
      platformFormData.name.length > 0 &&
      !!platformFormData.logo &&
      platformFormData.logo.length > 0
    ) {
      if (rowEdit) {
        editPlatform({
          ...platformFormData,
          deleted: false,
          successCallback,
          errorCallback,
        });
      } else {
        createPlatform({ ...platformFormData, successCallback, errorCallback });
      }
      cancelEditHandler();
    } else {
      nameValidateHandler(platformFormData.name);
      setErrorHandler({ name: 'logo', value: platformFormData.logo });
    }
  }, [createPlatform, successCallback, platformFormData, error, errorCallback]);

  const onSaveAvailableAdvancePlatform = useCallback(() => {
    if (uploadAvailableAdvancedData) {
      createAvailableAdvancePlatform({
        file: uploadAvailableAdvancedData,
        successCallback: availableAdvanceSuccessCallback,
        errorCallback: errorAdvanceCallback,
      });
    } else {
      setErrorHandler({ name: 'csvFile', value: uploadAvailableAdvancedData });
    }
  }, [
    createAvailableAdvancePlatform,
    availableAdvanceSuccessCallback,
    uploadAvailableAdvancedData,
    error,
    errorCallback,
  ]);

  const nameValidateHandler = useCallback(
    (value) => {
      setError((prevState) => ({ ...prevState, name: { status: !(value && value.length > 0), text: requiredText } }));
    },
    [requiredText],
  );

  const setErrorHandler = useCallback(
    ({ name, value }) => {
      if (error[name]) {
        if (name === 'name') {
          nameValidateHandler(value);
        } else {
          setError((prev) => ({ ...prev, [name]: { status: !(value && value.length > 0), text: requiredText } }));
        }
      }
    },
    [nameValidateHandler, error, t, requiredText],
  );

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.target;
      setErrorHandler({ name, value });

      if (name === 'name') {
        setPlatformFormData((prev) => ({
          ...prev,
          name: value || null,
        }));
      } else if (name === 'showDeleted') {
        setShowDeleted(checked);
      } else {
        setPlatformFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [setErrorHandler],
  );

  const onBlurHandler = useCallback(
    (field: string) => {
      const fieldValue = platformFormData[field];
      setErrorHandler({ name: field, value: fieldValue });
    },
    [setErrorHandler, platformFormData],
  );

  const onClickInput = (e) => {
    e.target.value = '';
  };

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files[0].size > fileLogoMaxSize) {
        setFileSizeError(true);
        setError((prev) => ({ ...prev, file: true }));
      } else {
        setFileSizeError(false);
        const fileUrl: string = (await convertFileToBase64(e.target.files[0])) as unknown as string;
        setPlatformFormData((prev) => ({ ...prev, logo: fileUrl, file_name: e.target.files[0].name }));
        setSrc(fileUrl);
        setError((prev) => ({ ...prev, file: !fileUrl }));
        setErrorHandler({ name: 'logo', value: fileUrl });
      }
    },
    [setPlatformFormData, error],
  );

  const handleCSVFileChange = useCallback(
    async (access: File[]) => {
      if (access.length === 0) {
        setFile(access);
        setUploadAvailableAdvancedData('');

        return;
      }

      if (access[0].size > fileLogoMaxSize) {
        setFileCSVSizeError(true);
        setError((prev) => ({ ...prev, csvFile: { status: true, text: 'max' } }));
        setFile(access);

        return;
      }
      setFile(access);
      setFileCSVSizeError(false);
      setError((prev) => ({ ...prev, csvFile: { ...prev.csvFile, status: false } }));
      const fileUrl: string = (await convertFileToBase64(access[0])) as string;
      setUploadAvailableAdvancedData(fileUrl);
    },
    [setUploadAvailableAdvancedData],
  );

  const handleRemoveFile = useCallback(() => {
    setPlatformFormData((prev) => ({ ...prev, logo: '', file_name: '' }));
    setSrc('');
  }, []);

  const rowClickHandler = (item: platformDataType) => {
    setRowEdit(item.id);
    setPlatformFormData((prev) => ({ ...prev, ...item, file_name: 'logo', [DocumentTabKeys.CUTOFF]: '' }));
    setSrc(item.logo);
  };

  const cancelEditHandler = () => {
    setRowEdit(null);
    setPlatformFormData(initialPlatformFormData);
    handleRemoveFile();
  };

  return (
    <TabPanel value="platform" style={{ padding: 0 }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '150px' }}>
        <div>
          <div>
            {Children.toArray(
              FormControlCustomInput &&
                FormControlCustomInput.map((item) => (
                  <InputKit
                    label={t(item.labelKey)}
                    name={item.name}
                    onChange={setFormValue}
                    value={platformFormData[item.defaultValue]}
                    onBlurHandler={() => onBlurHandler(item.onBlurHandler)}
                    isError={error[item.isError].status}
                    helperText={error[item.isError].status && error[item.errorText].text}
                    wrapProps={{ width: 400, height: 60, marginBottom: 20 }}
                    required
                  />
                )),
            )}
            <div>
              <div className={classes.uploadLabel} style={{ color: error.logo.status && 'red' }}>
                {t('file.relatedFilesMaxSize3mb')}
              </div>
              <input
                style={{ display: 'none' }}
                id="contained-button-file"
                multiple
                type="file"
                onClick={onClickInput}
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" className={classes.uploadButton} component="span">
                  {t('file.dropFileText')}
                </Button>
              </label>
              <div
                className={classes.uploadLabel}
                style={{ visibility: error.file ? 'visible' : 'hidden', color: error.file && 'red' }}
              >
                {fileSizeError ? t('file.sizeError3mb') : t('auth.required')}
              </div>
            </div>
            {platformFormData.file_name && (
              <div>
                <IconButton onClick={handleRemoveFile}>
                  <RemoveFileIcon style={{ color: 'red' }} />
                </IconButton>
                <a
                  href={platformFormData.file?.rawFile || src}
                  target="_blank"
                  download={platformFormData.file_name}
                  style={{
                    color: '-webkit-link',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  rel="noreferrer"
                >
                  {platformFormData.file_name}
                </a>
              </div>
            )}
            <input
              style={{ display: 'none' }}
              id="contained-button-file"
              multiple
              type="file"
              onClick={onClickInput}
              onChange={handleFileChange}
              ref={inputFile}
            />
            <Button
              style={{ height: 40, width: 120 }}
              {...stylingButton}
              className={classes.button}
              onClick={onSavePlatform}
            >
              <span>{t('global.upload')}</span>
            </Button>
          </div>
          <div style={{ marginBottom: 20 }}>
            <SwitchButton
              inputSwitch={showDeleted}
              handleChangeSwitch={setFormValue}
              name="showDeleted"
              buttonName="contentHeader.showDeleted"
              key="showDeleted"
            />
          </div>
        </div>
        <div style={{ display: 'grid', gap: 15, alignContent: 'start', position: 'relative', top: -40, width: 400 }}>
          <p style={{ color: '#606176', fontSize: 20 }}>{t('forms.platform.uploadAvailableAdvance')}</p>
          <div>
            <DropZoneDocument
              maxSize={3e6}
              acceptFormat=".csv"
              setAccessFiles={handleCSVFileChange}
              accessFiles={file}
              title={t('forms.platform.dropZoneTitle')}
              text={[t('forms.platform.dropZoneText'), t('forms.platform.dropZoneText1')]}
              buttonText={t('forms.platform.dropZoneButton')}
            />
            <div
              className={classes.uploadLabel}
              style={{
                visibility: error.csvFile.status ? 'visible' : 'hidden',
                color: error.csvFile.status && 'red',
              }}
            >
              {fileCSVSizeError ? t('file.sizeError3mb') : t('auth.required')}
            </div>
          </div>
          <Button
            style={{ height: 40, width: 120 }}
            {...stylingButton}
            className={classes.button}
            onClick={onSaveAvailableAdvancePlatform}
          >
            <span>{t('global.upload')}</span>
          </Button>
        </div>
      </div>
      <TableContainer
        className={`${styles.paper} ${styles.overflowXFixed}`}
        style={{ overflow: 'auto', width: '100%', maxWidth: 700, maxHeight: 'calc(100vh - 340px)' }}
      >
        <Table stickyHeader className={styles.table} size="small" aria-label="a dense table">
          <TableBody>
            {Children.toArray(
              platforms &&
                platforms.length > 0 &&
                platforms?.map((item, k) => (
                  <TableRow
                    key={item.id || k}
                    hover
                    className={styles.tBodyRow}
                    style={item.deleted ? { fontStyle: 'italic', opacity: 0.7 } : {}}
                  >
                    {Children.toArray(
                      documentTabData.map(({ props, func, key, translate }) => {
                        if (
                          key === DocumentTabKeys.LINK ||
                          (!item.deleted && item.id !== rowEdit && key === DocumentTabKeys.DELETE) ||
                          (item.id === rowEdit && key === DocumentTabKeys.CANCEL)
                        ) {
                          return (
                            <TableCell {...props} className={styles.fixedCell}>
                              <Button
                                style={{ height: 40, width: 120 }}
                                {...stylingButton}
                                onClick={() =>
                                  func({
                                    link: item.link,
                                    id: item.id,
                                    getDocuments,
                                    cancelEditHandler,
                                  })
                                }
                              >
                                <span>{t(translate)}</span>
                              </Button>
                            </TableCell>
                          );
                        }

                        if (key === DocumentTabKeys.LOGO) {
                          return (
                            <TableCell {...props} className={styles.fixedCell} onClick={() => rowClickHandler(item)}>
                              <img
                                style={{ display: 'block', width: '100px', maxHeight: '100px' }}
                                src={item.logo}
                                alt="logo"
                              />
                            </TableCell>
                          );
                        }

                        if (item.id === rowEdit && key === DocumentTabKeys.NAME) {
                          return (
                            <TableCell {...props} className={styles.fixedCell}>
                              <InputKit
                                label={t('tables.platform')}
                                name={DocumentTabKeys.NAME}
                                onChange={setFormValue}
                                value={item[key]}
                                wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
                                disabled
                              />
                            </TableCell>
                          );
                        }

                        if (item.id === rowEdit && key === DocumentTabKeys.CUTOFF) {
                          return (
                            <TableCell {...props} className={styles.fixedCell}>
                              <AmountKit
                                label={t('tables.cutoff')}
                                name={DocumentTabKeys.CUTOFF}
                                onChange={setFormValue}
                                amount={platformFormData[key]}
                                placeholder={item[key]}
                                wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
                              />
                            </TableCell>
                          );
                        }

                        if (item.id !== rowEdit && (key === DocumentTabKeys.NAME || key === DocumentTabKeys.CUTOFF)) {
                          return (
                            <TableCell {...props} className={styles.fixedCell} onClick={() => rowClickHandler(item)}>
                              {item[key]}
                            </TableCell>
                          );
                        }

                        return null;
                      }),
                    )}
                  </TableRow>
                )),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    width: '150px',
    marginBottom: '10px',
  },
  link: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formControl: {
    height: '48px',
    marginBottom: '35px',
  },

  uploadBlock: {
    marginBottom: '32px',
  },
  uploadLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '1',
    marginBottom: '8px',
  },
  uploadButton: {
    width: '100%',
    height: '66px',
    background: '#fafafa',
    marginBottom: '8px',
    maxWidth: 500,
  },
}));

export default PlatformPanel;

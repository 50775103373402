import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type createActivationOrderApplaudType = {
  body: {
    company_id?: string;
    name?: string;
    amount?: string;
    currency?: string;
    interest?: string;
    purchase_date?: string;
    repayment_date?: string;
    grace_period?: number;
    is_custom_payment_day?: boolean;
    payment_days?: [];
    commission_disable_date?: string;
    capitalization_disable_date?: string;
    default_interest_disable_date?: string;
    contract_id?: string;
    vat_policy?: string;
    days?: string;
    ao_type?: string;
    penalty_policy?: string;
    ao_document_id?: string;
    commission_daily?: string | null;
    plan_commission_net?: string | null;
    plan_commission_vat?: string | null;
    plan_commission_gross?: string | null;
    commission_gross?: string | null;
  };
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  resultKey = 'createActivationOrderData',
  successCallback,
  errorCallback,
}: createActivationOrderApplaudType) =>
  requestAsync({
    url: endpoints.getCreateActivationOrderUrlApplaud(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getCreateActivationOrderUrlApplaud()}`,
    body,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

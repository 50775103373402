import React, { FC } from 'react';
import classNames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import { TableCell, TableRow } from '@material-ui/core';

import styles from './styles.module.scss';

type Props = {
  isTotal?: boolean;
};

const ExpectedPaymentsSkeleton: FC<Props> = ({ isTotal }: Props) => (
  <TableRow className={styles.row}>
    <TableCell align="left" width="35%" className={styles.cell}>
      {!isTotal && <Skeleton variant="rectangular" width={75} height={20} className={styles.skeleton} />}
    </TableCell>
    <TableCell align="left" width="35%" className={styles.cell}>
      {!isTotal && <Skeleton variant="rectangular" width={140} height={20} className={styles.skeleton} />}
    </TableCell>
    <TableCell align="right" className={styles.cell}>
      <div className={classNames(styles.totalCell, { [styles.isTotal]: isTotal })}>
        {isTotal && <span className={styles.totalCategory}>Total:</span>}
        <Skeleton variant="rectangular" width={isTotal ? 60 : 40} height={20} className={styles.skeleton} />
      </div>
    </TableCell>
    <TableCell width={28} align="center" className={styles.cell} />
  </TableRow>
);

export default ExpectedPaymentsSkeleton;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr: string[] = dataurl.split(',');
  const mime: string = arr[0].match(/:(.*?);/)[1];
  const bstr: string = atob(arr[1]);
  let n: number = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadFile = (fileData, contentType, fileName) => {
  const blob = b64toBlob(fileData, contentType);
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.click();
};

export const formatSizeUnits = (bytes) => {
  let size;

  if (bytes >= 1073741824) {
    size = `${(bytes / 1073741824).toFixed(2)} GB`;
  } else if (bytes >= 1048576) {
    size = `${(bytes / 1048576).toFixed(2)} MB`;
  } else if (bytes >= 1024) {
    size = `${(bytes / 1024).toFixed(2)} KB`;
  } else if (bytes > 1) {
    size = `${bytes} bytes`;
  } else if (bytes === 1) {
    size = `${bytes} byte`;
  } else {
    size = '0 bytes';
  }

  return size;
};

export const downloadFileOfBlob = (blob: Blob, type: string, name: string, openFile?: boolean) => {
  const filelocal = new Blob([blob], { type });
  const blobUrl = URL.createObjectURL(filelocal);
  const link = document.createElement('a');
  link.href = blobUrl;
  if (!openFile) {
    link.download = name;
  }

  if (openFile) {
    link.target = '_blank';
  }
  link.click();
  link.remove();
  URL.revokeObjectURL(link.href);
};

export const createInputFileSetOnchangeFunc = (data: (f: FileList) => void) => {
  const fileEl = document.createElement('input');
  fileEl.type = 'file';
  fileEl.click();
  fileEl.onchange = (e) => {
    data((e.target as HTMLInputElement).files);
    fileEl.remove();
  };
};

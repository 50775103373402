import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface bodyScheduleFutureReceivables {
  order_id: string;
  schedules: { payment_date?: string; amount?: string }[];
}

export type createScheduleFutureReceivablesDataType = {
  body: bodyScheduleFutureReceivables;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  successCallback,
  errorCallback,
  resultKey = 'CreateScheduleFutureReceivables',
}: createScheduleFutureReceivablesDataType) =>
  requestAsync({
    url: endpoints.getCreateScheduleFutureReceivablesUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getCreateScheduleFutureReceivablesUrl()}`,
    body,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

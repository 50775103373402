// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, Children, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';

import { formatAmount } from 'helpers/formatters';
import { tableSettingsStyles } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import Tooltip from 'components/Tooltip';
import useActivationOrder, { AoTypeEnum, useActivationOrderType } from 'hooks/api/useActivationOrder';

import {
  technicalInfoItemType,
  technicalInfoTableConfig,
  getColorModalTable,
  ITechnicalInfoTableConfig,
  technicalInfoTableApplaud,
  getCorrectionCalculationTypes,
} from './data';
import { getAdjustmentCalcType, AdjustmentCalcInitial } from './AuditLog/data';
import CorrectionDetailsPopUp from './AuditLog/CorrectionDetailsPopUp';

export type ModalTableType = {
  technicalInfo: technicalInfoItemType[];
  isShowPagination: boolean;
  getRecalculateHandler?: () => void;
};

const styleCommon = {
  backgroundColor: '#606176',
  borderRadius: 2,
  padding: '0px 2px',
  color: '#fff',
  cursor: 'pointer',
};

const alignCustom = { width: 80, display: 'inline-block', textAlign: 'right' as const };
const styleBorder = { borderBottom: 0 };
const ModalTable: FC<ModalTableType> = ({ technicalInfo, isShowPagination, getRecalculateHandler }) => {
  const classes = tableSettingsStyles();
  const [currentItem, setCurrentItem] = useState<getAdjustmentCalcType>(AdjustmentCalcInitial);
  const {
    activationOrderData: { ao_type },
  }: useActivationOrderType = useActivationOrder();
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const { t } = useTranslation();

  const getCorrectionFunc = useCallback((value: getAdjustmentCalcType) => {
    setCurrentItem(value);
    setIsOpenDetails(true);
  }, []);

  let currentStatusTable = technicalInfoTableConfig;

  if (ao_type === AoTypeEnum.APPLAUD_FIX) {
    currentStatusTable = technicalInfoTableApplaud;
  }

  const defaultRenderer = (value, item: ITechnicalInfoTableConfig, obj: technicalInfoItemType) => {
    if (obj.adjustments.length > 0) {
      const activeObj = obj.adjustments.find(
        (ob) => ob.active && item.field === getCorrectionCalculationTypes(ob.type, ao_type),
      );

      if (activeObj) {
        return (
          <span onClick={() => getCorrectionFunc(activeObj)} style={{ ...styleCommon }}>
            {formatAmount(value)}
          </span>
        );
      }
    }

    if (obj.adjustments.length > 0) {
      const cancelObj = obj.adjustments.find(
        (ob) => !ob.active && item.field === getCorrectionCalculationTypes(ob.type, ao_type),
      );

      if (cancelObj) {
        return (
          <span onClick={() => getCorrectionFunc(cancelObj)} style={{ ...styleCommon, textDecoration: 'line-through' }}>
            {formatAmount(value)}
          </span>
        );
      }
    }

    return formatAmount(value);
  };

  return (
    <>
      <CorrectionDetailsPopUp
        confirmCallBack={() => {
          getRecalculateHandler();
        }}
        data={currentItem}
        isOpenDetails={isOpenDetails}
        setIsOpenDetails={setIsOpenDetails}
      />
      <TableWrapWithPagination
        wrapStyle={{ height: '100%' }}
        isShowPagination={isShowPagination}
        styleTop={{ top: 83 }}
        styleLeft={{ left: 110 }}
        count={100}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      >
        <TableHead>
          <TableRow classes={{ root: classes.headTableRowRoot }} style={{ height: 83 }}>
            {Children.toArray(
              currentStatusTable.map((item) => (
                <TableCell style={{ background: '#fff', ...item.styles, verticalAlign: 'bottom' }} align="left">
                  <span style={alignCustom}>{t(item.title)}</span>
                </TableCell>
              )),
            )}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: classes.bodyTableRoot }}>
          {Children.toArray(
            technicalInfo.map((obj) => (
              <TableRow classes={{ root: classes.bodyTableRowRoot }}>
                {Children.toArray(
                  currentStatusTable.map((setting) => {
                    const fieldValue = obj[setting.field];

                    return (
                      <TableCell
                        align="left"
                        data-tip={setting.dateType ? obj[setting.dateType]?.description : ''}
                        style={
                          setting.dateType
                            ? {
                                ...getColorModalTable(obj[setting.dateType]?.date_type),
                                ...setting.styles,
                                ...styleBorder,
                              }
                            : styleBorder
                        }
                      >
                        {setting.contentFunc ? (
                          <span style={alignCustom}>{setting.contentFunc(fieldValue)}</span>
                        ) : (
                          <span style={alignCustom}>
                            <Tooltip
                              title={obj[setting.tooltipKey] !== undefined && String(Number(obj[setting.tooltipKey]))}
                            >
                              {defaultRenderer(fieldValue, setting, obj)}
                            </Tooltip>
                          </span>
                        )}
                      </TableCell>
                    );
                  }),
                )}
              </TableRow>
            )),
          )}
        </TableBody>
        <ReactTooltip />
      </TableWrapWithPagination>
    </>
  );
};

export default ModalTable;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type ClientAmountParamsT = {
  date_for?: string;
  company_id?: string;
  _sort?: string;
  _order?: 'asc' | 'desc';
};

type clientAmountsReportType = {
  params?: ClientAmountParamsT;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ params, errorCallback, resultKey = 'clientAmountsReportData' }: clientAmountsReportType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getClientAmountsReportUrl(params),
    transform: (response) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: response.map((item) => ({
        accruedInterest: item.accrued_interest,
        companyName: item.company_name,
        issuedAmount: item.issued_amount,
        ...item,
      })),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getClientAmountsReportUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC, useEffect, useCallback, useState, useMemo, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import { formatDate } from 'helpers/formatters';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import { typeAo } from 'components/forms/ActivationOrderForm/CardsAo/data';
import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import downloadSvg from 'assets/img/download.svg';
import urls from 'constants/urls';

import classNames from 'classnames';
import Select from 'components/uiKit/Select';
import styles from './styles.module.scss';
import endpoints from 'consts/endpoints';
import { getFileStorage } from '../../reportsData';

type PropsT = {
  companyId: string;
};

const AOClient: FC<PropsT> = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getAOByCompany, aOByCompanyData }: useCompanyType = useCompany();
  const [currentProductFilter, setCurrentProductFilter] = useState('');

  useEffect(() => {
    setCompany();
  }, []);

  const setCompany = useCallback(() => {
    companyId && getAOByCompany({ company_id: companyId });
  }, [companyId]);

  const getPdf = (fileId?: string, openPdf: boolean = false) => {
    if (!fileId) return;
    getFileStorage(endpoints.getStorageFileUrl(fileId), undefined, undefined, undefined, openPdf);
  };

  const downloadPdf = (e: MouseEvent<HTMLImageElement>, fileId?: string) => {
    e.stopPropagation();
    getPdf(fileId);
  };

  const preparedAOByCompanyData = useMemo(() => {
    if (!currentProductFilter) return aOByCompanyData;
    return aOByCompanyData.filter((ao) => ao.ao_type === currentProductFilter);
  }, [aOByCompanyData, currentProductFilter]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filter}>
        <Select
          label="Document type"
          value="activation_order"
          onChange={() => {}}
          options={[
            {
              key: 'activation_order',
              value: 'Activation order ',
            },
          ]}
          withoutAllOptions
          width={200}
        />
        <Select
          label="Product"
          value={currentProductFilter}
          onChange={setCurrentProductFilter}
          options={typeAo
            .filter((type) => type.key !== AoTypeEnum.APPLAUD_FIX)
            .map((type) => ({
              key: type.key,
              value: type.name,
            }))}
          width={200}
        />
      </div>
      <TableWrapWithPagination tableContainerStyle={{ backgroundColor: 'white' }} isShowPagination={false}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableHeadCell}>{t('company.type')}</TableCell>
            <TableCell className={styles.tableHeadCell}>{t('company.aoTab.no')}</TableCell>
            <TableCell className={styles.tableHeadCell}>{t('company.aoTab.product')}</TableCell>
            <TableCell className={styles.tableHeadCell}>{t('company.aoTab.signingDate')}</TableCell>
            <TableCell className={classNames(styles.tableHeadCell, styles.tableCellSmall)} />
            <TableCell className={classNames(styles.tableHeadCell, styles.tableCellSmall)} />
          </TableRow>
        </TableHead>
        <TableBody>
          {preparedAOByCompanyData.map((item) => (
            <TableRow
              style={{ height: 56 }}
              className={styles.tableRowStyle}
              onClick={() => history.push(`${urls.getActivationOrder()}/${item.id}`)}
            >
              <TableCell>{t('company.aoTab.ao')}</TableCell>
              <TableCell>
                <span className={styles.link}>{item.name}</span>
              </TableCell>
              <TableCell>{typeAo.find((type) => type.key === item.ao_type)?.name}</TableCell>
              <TableCell style={{ width: 180 }}>{item.sign_date ? formatDate(item.sign_date) : '-'}</TableCell>
              <TableCell className={styles.tableCellSmall} />
              <TableCell className={styles.tableCellSmall}>
                {item.files[0]?.id && (
                  <img
                    className={styles.tableIconBtn}
                    src={downloadSvg}
                    alt="download-icon"
                    onClick={(e) => downloadPdf(e, item.files[0]?.id)}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableWrapWithPagination>
    </div>
  );
};

export default AOClient;

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DropZoneDocument from 'components/forms/CompanyForm/DropZoneDocument';
import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { SwitcherText } from 'components/uiKit/SwitcherText';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import useContract, { useContractType } from 'hooks/api/useContract';
import { InputKit } from 'components/uiKit/InputKit';
import SwitchCustomSettings, { typeInputEnum } from 'components/forms/ActivationOrderForm/CardsAo/SwitchCustomSettings';
import { getCurrencyWithAmount } from 'helpers/formatters';
import useAlert from 'hooks/api/useAlert';
import useDebounce from 'hooks/useDebounce';
import Textarea from 'components/uiKit/TextareaKit';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import CheckboxKit from 'components/uiKit/CheckboxKit';
import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import CompanySelect from 'components/CompanySelect';

import {
  choices_grace,
  fileAssociation,
  getAoNumber,
  getCurrencyList,
  getPenaltyList,
  getValueCurrency,
  getValuePaymentDays,
  getValuePaymentPlan,
  getValuePenalty,
  getValuePeriodDisable,
  getValuePolicy,
  getValueRepaymentPlan,
  getVatPolicyList,
  initialDistributionPlatform,
  initialPaymentsDays,
  initialPeriodDisable,
  KeyCardsEnum,
  listRepaymentPlan,
  newTypeAo,
  statusParamsEnum,
  typeAo,
  valueType,
} from './data';
import TemplateGrid from './TemplateGrid';
import useErrors from './useErrors';
import DistributionPlatform from './DistributionPlatform';
import useCurrentRequiredKeyOfCard from './useCurrentRequiredKeyOfCard';
import Status from './Status';
import FactorAccountsSelect from 'components/FactorAccountsSelect';

const keyObj = 'name';

interface ItemsAoType {
  types: KeyCardsEnum[];
  style?: React.CSSProperties;
}

const commonHeight = { height: 60 };

const ItemsAo: FC<ItemsAoType> = ({ types, style }) => {
  const {
    companiesData: { names = [] },
    aoFormData,
    setAOFormData,
    listSwitch,
    setIsSwitchList,
  }: useCompanyType = useCompany();
  const {
    contractsData: { namesContracts = [] },
    getContracts,
    clearContractsData,
  }: useContractType = useContract();
  const { t } = useTranslation();
  const { status }: { status: statusParamsEnum } = useParams();

  const valueContract = useMemo(
    () => namesContracts.find((item) => item.id === aoFormData.contract_id),
    [aoFormData, namesContracts],
  );
  const { getParamsItem } = useCurrentRequiredKeyOfCard();
  const { errors, setErrors, addErrors, clearErrorsOfKey } = useErrors();
  const { setAlert } = useAlert();

  const changeSwitch = useCallback(
    (key: KeyCardsEnum, isSwitch?: boolean) => {
      setIsSwitchList({ ...listSwitch, [key]: isSwitch });
    },
    [setIsSwitchList, KeyCardsEnum, listSwitch],
  );

  const checkNumber = useDebounce((value) => {
    checkNumberFetch(value);
  }, 500);

  const getNumber = useCallback(
    (key: KeyCardsEnum, value) => {
      (async () => {
        const resp = await getAoNumber({ company_id: aoFormData[KeyCardsEnum.COMPANY_ID], document_id: value }, false);
        let objKey = { [key]: value };
        objKey = { ...objKey, [KeyCardsEnum.NAME]: resp.next_ao_number || '' };
        setAOFormData({ ...aoFormData, ...objKey });
      })();
    },
    [aoFormData],
  );

  const checkNumberFetch = useCallback(
    (value) => {
      (async () => {
        const resp = await getAoNumber(
          {
            company_id: aoFormData[KeyCardsEnum.COMPANY_ID],
            document_id: aoFormData[KeyCardsEnum.CONTRACT_ID],
            ao_number: value,
          },
          true,
        );

        if (!resp.is_valid) {
          setAlert({
            type: 'error',
            message: `${value} ${resp?.description?.ao_number || 'error'}`,
          });
          setErrors('', KeyCardsEnum.NAME, { required: true });
        }
        resp.is_valid && setAOFormData({ ...aoFormData, [KeyCardsEnum.NAME]: value });
      })();
    },
    [aoFormData],
  );

  const setInitialFilter = useCallback(
    (
      key: KeyCardsEnum,
      value,
      error?: { keyError; valueError: string; params: { date?: boolean; required?: boolean } },
    ) => {
      let objKey = { [key]: value };

      if (key === KeyCardsEnum.COMPANY_ID && value) {
        getContracts({ id: value });
        objKey = { ...objKey, [KeyCardsEnum.NAME]: '' };
      }

      if (key === KeyCardsEnum.COMPANY_ID && !value) {
        clearContractsData();
        objKey = { ...objKey, [KeyCardsEnum.CONTRACT_ID]: value, [KeyCardsEnum.NAME]: '' };
      }

      if (key === KeyCardsEnum.CONTRACT_ID && !value) {
        objKey = { ...objKey, [KeyCardsEnum.NAME]: '' };
      }
      setAOFormData({ ...aoFormData, ...objKey });

      if (key === KeyCardsEnum.CONTRACT_ID && value) {
        getNumber(key, value);
      }

      if (key === KeyCardsEnum.NAME && value) {
        checkNumber(value);
      }

      if (error && error?.keyError && error?.params) {
        setErrors(error?.valueError, error?.keyError, error.params);
      }
    },
    [aoFormData],
  );

  return (
    <TemplateGrid style={{ marginTop: 30, ...style }}>
      {Children.toArray(
        types.map((item) => {
          if (item === KeyCardsEnum.DISTRIBUTION_PLATFORMS) {
            return (
              <DistributionPlatform
                itemKey={item}
                data={aoFormData.distribution_platforms}
                onChange={(key, value, column) => {
                  aoFormData.distribution_platforms[column][key] = value;
                  setInitialFilter(item, [...aoFormData.distribution_platforms], {
                    keyError: `${key}_${column}`,
                    valueError: value,
                    params: getParamsItem(item, key),
                  });
                }}
                callback={() => {
                  setInitialFilter(item, [...aoFormData.distribution_platforms, { ...initialDistributionPlatform }]);
                }}
                deleteCallback={(i) => {
                  const copy = [...aoFormData.distribution_platforms];
                  copy.splice(i, 1);
                  setInitialFilter(item, copy);
                }}
              />
            );
          }

          if (item === KeyCardsEnum.REGISTRATION_NUMBER) {
            return (
              <InputKit
                label={t('forms.activationOrder.accountNumber')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                value={aoFormData.registration_number}
                required={getParamsItem(KeyCardsEnum.ROOT, item).required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
                disabled
              />
            );
          }

          if (item === KeyCardsEnum.FACTOR_BANK_ACCOUNT) {
            return (
              <FactorAccountsSelect
                needSetDefaultValue
                currency={aoFormData.currency}
                value={aoFormData?.sender?.account}
                onChange={(account, { source: { bank_name, swift } }) =>
                  setInitialFilter('sender', {
                    currency: aoFormData.currency,
                    account,
                    bank_name,
                    swift,
                  })
                }
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              />
            );
          }

          if (item === KeyCardsEnum.CHECKBOX) {
            return (
              <CheckboxKit
                label={t('forms.transaction.recipientIsTheSameAsClient')}
                checked={aoFormData.checkbox}
                onClick={() => {
                  setInitialFilter(item, !aoFormData.checkbox, {
                    keyError: item,
                    valueError: '',
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
              />
            );
          }

          if (item === KeyCardsEnum.RECIPIENT) {
            return (
              <DropdownKit
                label={t('forms.transaction.recipient')}
                options={names}
                keyObj="name"
                value={names.find((val) => val.id === aoFormData.recipient) || ''}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.id || '', {
                    keyError: item,
                    valueError: data.id,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.COMMENT) {
            return (
              <Textarea
                value={aoFormData.comment}
                onChange={(e) => {
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.COMMENT, item),
                  });
                }}
                required={aoFormData?.ao_type !== AoTypeEnum.FUTURE_RECEIVABLES}
                maxLength={4000}
                placeholder={t('forms.activationOrder.comment')}
                isError={!!errors[item]}
                helperText={errors[item]}
              />
            );
          }

          if (item === KeyCardsEnum.AO_TYPE) {
            return (
              <DropdownKit
                label={t('forms.activationOrder.aoType')}
                options={valueType[aoFormData.ao_type].key === AoTypeEnum.APPLAUD_FIX ? typeAo : newTypeAo}
                keyObj={keyObj}
                value={valueType[aoFormData.ao_type]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                disabled={status === statusParamsEnum.EDIT}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
                isHiddenMenuAll
              />
            );
          }

          if (item === KeyCardsEnum.REPAYMENT_PLAN) {
            return (
              <DropdownKit
                label={t('forms.activationOrder.repaymentPlan')}
                options={listRepaymentPlan}
                keyObj={keyObj}
                value={getValueRepaymentPlan[aoFormData.repayment_plan]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.TX_DATE) {
            return (
              <DateInput
                labelKey={t('forms.activationOrder.date')}
                commonStyle={{ marginBottom: 32 }}
                date={aoFormData.tx_date}
                isNotErrorLocal
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                errorText={errors[item]}
                isError={!!errors[item]}
                required={getParamsItem(KeyCardsEnum.ROOT, KeyCardsEnum.TX_DATE).required}
              />
            );
          }

          if (item === KeyCardsEnum.STATUS) {
            return <Status item={item} setInitialFilter={setInitialFilter} wrapProps={commonHeight} />;
          }

          if (item === KeyCardsEnum.COMPANY_ID) {
            return (
              <CompanySelect
                label={t('forms.activationOrder.client')}
                size="large"
                onChange={(id) => {
                  setInitialFilter(item, id || '', {
                    keyError: item,
                    valueError: id,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                value={aoFormData.company_id || undefined}
                error={!!errors[item]}
                required
              />
            );
          }

          if (item === KeyCardsEnum.CONTRACT_ID) {
            return (
              <DropdownKit
                label={t('tables.agreement')}
                options={namesContracts}
                keyObj={keyObj}
                value={valueContract}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.id || '', {
                    keyError: item,
                    valueError: data.id,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.NAME) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('forms.activationOrder.number')}
                onChange={(e) =>
                  (Number(e.target.value) >= 1 || e.target.value === '') &&
                  e.target.value.length < 6 &&
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData.name}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
                decimalScale={3}
                fontSize={16}
                disabled={!aoFormData.company_id || !aoFormData.contract_id}
              />
            );
          }

          if (item === KeyCardsEnum.WITHHOLD_PERCENTAGE) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('forms.activationOrder.minimal_principal_repayment')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData[item]}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
                decimalScale={3}
                fontSize={16}
              />
            );
          }

          if (item === KeyCardsEnum.PURCHASED_RECEIVABLES) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('forms.activationOrder.purchasedReceivables')}
                onChange={(e) => {
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });

                  if (
                    Number(e.target.value) !==
                    aoFormData?.schedules?.reduce((prev, { amount }) => Number(amount || 0) + prev, 0)
                  ) {
                    addErrors({
                      [KeyCardsEnum.PURCHASED_RECEIVABLES]: 'Total Amount is not equal Purchased Receivables',
                      [KeyCardsEnum.PAYMENT_PLAN]: 'Total Amount is not equal Purchased Receivables',
                    });
                    return;
                  }
                  clearErrorsOfKey(item);
                  clearErrorsOfKey(KeyCardsEnum.PAYMENT_PLAN);
                }}
                amount={aoFormData?.purchased_receivables}
                currency={aoFormData.currency}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
              />
            );
          }

          if (item === KeyCardsEnum.VAT_POLICY) {
            return (
              <DropdownKit
                label={t('selectFiledValues.vatPolicy')}
                options={getVatPolicyList()}
                keyObj={keyObj}
                value={getValuePolicy()[aoFormData.vat_policy]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.PENALTY_POLICY) {
            return (
              <DropdownKit
                label={t('forms.activationOrder.defaultInterest')}
                options={getPenaltyList()}
                keyObj={keyObj}
                value={getValuePenalty()[aoFormData.penalty_policy]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.AMOUNT) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('tables.purchasePrice')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData.amount}
                currency={aoFormData.currency}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
              />
            );
          }

          if (item === KeyCardsEnum.DAYS) {
            return (
              <AmountKit
                decimalScale={0}
                isNeedHandlePaste
                label={t('forms.activationOrder.days')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData.days}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
              />
            );
          }

          if (item === KeyCardsEnum.REPAYMENT_PLAN_DURATION) {
            return (
              <AmountKit
                decimalScale={0}
                isNeedHandlePaste
                label={t('forms.activationOrder.duration')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData.repayment_plan_duration}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
                fontSize={16}
              />
            );
          }

          if (item === KeyCardsEnum.CURRENCY) {
            return (
              <DropdownKit
                label={t('Currency')}
                options={getCurrencyList()}
                keyObj={keyObj}
                value={getValueCurrency()[aoFormData.currency]}
                changeSelectItem={(_, data) => {
                  setInitialFilter(item, data.key || '', {
                    keyError: item,
                    valueError: data.key,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isErrors={!!errors[item]}
                helperText={errors[item]}
                positionType={DropdownSizeEnum.SIZE_60}
              />
            );
          }

          if (item === KeyCardsEnum.DROPZONE) {
            return (
              <DropZoneDocument
                acceptFormat={fileAssociation}
                setAccessFiles={(access) => {
                  setInitialFilter(item, access);
                }}
                accessFiles={aoFormData[item]}
                title={t('forms.activationOrder.dropZoneTitle')}
                text={[t('forms.activationOrder.dropZoneText'), t('forms.activationOrder.dropZoneText1')]}
                buttonText={t('forms.activationOrder.dropZoneButton')}
              />
            );
          }

          if (item === KeyCardsEnum.INTEREST) {
            return (
              <AmountKit
                isNeedHandlePaste
                label={t('forms.activationOrder.commission')}
                onChange={(e) =>
                  setInitialFilter(item, e.target.value, {
                    keyError: item,
                    valueError: e.target.value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  })
                }
                amount={aoFormData.interest}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                isError={!!errors[item]}
                helperText={errors[item]}
                wrapProps={commonHeight}
              />
            );
          }

          if (item === KeyCardsEnum.PURCHASE_DATE) {
            return (
              <DateInput
                isNotErrorLocal
                date={aoFormData.purchase_date}
                labelKey="tables.purchaseDate"
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                stylingLabel={{ paddingLeft: 8 }}
                inputStyle={{ paddingLeft: 17 }}
                commonStyle={commonHeight}
                isError={!!errors[item]}
                errorText={errors[item]}
              />
            );
          }

          if (item === KeyCardsEnum.CLOSING_DATE) {
            return (
              <DateInput
                isNotErrorLocal
                date={aoFormData.closing_date}
                labelKey="forms.activationOrder.closingDateModal.closingDate"
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                stylingLabel={{ paddingLeft: 8 }}
                inputStyle={{ paddingLeft: 17 }}
                commonStyle={commonHeight}
                isError={!!errors[item]}
                errorText={errors[item]}
              />
            );
          }

          if (item === KeyCardsEnum.SIGN_DATE) {
            return (
              <DateInput
                isNotErrorLocal
                date={aoFormData.sign_date}
                labelKey="tables.signingDate"
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                stylingLabel={{ paddingLeft: 8 }}
                inputStyle={{ paddingLeft: 17 }}
                commonStyle={commonHeight}
                isError={!!errors[item]}
                errorText={errors[item]}
              />
            );
          }

          if (item === KeyCardsEnum.REPAYMENT_DATE) {
            return (
              <DateInput
                isNotErrorLocal
                date={aoFormData.repayment_date}
                labelKey="tables.repaymentDate"
                onChangeDateHandler={({ target: { value } }) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
                stylingLabel={{ paddingLeft: 8 }}
                inputStyle={{ paddingLeft: 17 }}
                commonStyle={commonHeight}
                isError={!!errors[item]}
                errorText={errors[item]}
              />
            );
          }

          if (item === KeyCardsEnum.PAYMENT_DAYS) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                itemKey={item}
                typeButton="text"
                isSwitch={aoFormData[KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY]}
                handleChangeSwitch={() => {
                  changeSwitch(item, !aoFormData[KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY]);
                  setInitialFilter(KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY, !aoFormData[KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY]);
                }}
                deleteCallback={(i) => {
                  const copy = [...aoFormData.payment_days];
                  copy.splice(i, 1);
                  setInitialFilter(item, copy);
                }}
                switchText={t('forms.activationOrder.paymentDay')}
                onChange={(key, value, column) => {
                  aoFormData.payment_days[column][key] = value;
                  setInitialFilter(item, [...aoFormData.payment_days], {
                    keyError: `${key}_${column}`,
                    valueError: value,
                    params: getParamsItem(item, key),
                  });
                }}
                data={getValuePaymentDays(aoFormData.payment_days)}
                callback={() => setInitialFilter(item, [...aoFormData.payment_days, { ...initialPaymentsDays }])}
              />
            );
          }

          if (item === KeyCardsEnum.GRACE_PERIOD) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                isSwitch={listSwitch.grace_period}
                handleChangeSwitch={() => {
                  changeSwitch(item, !listSwitch.grace_period);
                }}
                onChange={(_, value) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                switchText={t('forms.activationOrder.gracePeriod')}
                data={[
                  {
                    row: [
                      {
                        key: item,
                        type: typeInputEnum.SELECT,
                        label: t('forms.activationOrder.gracePeriod'),
                        val: aoFormData.grace_period,
                        options: choices_grace,
                      },
                    ],
                  },
                ]}
              />
            );
          }

          if (item === KeyCardsEnum.COMMISSION_DISABLE_DATE) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                isSwitch={listSwitch.commission_disable_date}
                handleChangeSwitch={() => {
                  changeSwitch(item, !listSwitch.commission_disable_date);
                }}
                onChange={(_, value) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                switchText={t('forms.activationOrder.turnOffCommission')}
                data={[
                  {
                    row: [
                      {
                        key: item,
                        label: t('forms.activationOrder.commissionDisableDate'),
                        val: aoFormData.commission_disable_date,
                      },
                    ],
                  },
                ]}
              />
            );
          }

          if (item === KeyCardsEnum.CAPITALIZATION_PERIOD_DISABLED) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                itemKey={item}
                typeButton="button"
                isSwitch={listSwitch.capitalization_period_disabled}
                handleChangeSwitch={() => {
                  changeSwitch(item, !listSwitch.capitalization_period_disabled);
                }}
                deleteCallback={(i) => {
                  const copy = [...aoFormData.capitalization_period_disabled];
                  copy.splice(i, 1);
                  setInitialFilter(item, copy);
                }}
                switchText={t('forms.activationOrder.turnOffCapitalizationPeriod')}
                onChange={(key, value, column) => {
                  aoFormData.capitalization_period_disabled[column][key] = value;
                  setInitialFilter(item, [...aoFormData.capitalization_period_disabled], {
                    keyError: `${key}_${column}`,
                    valueError: value,
                    params: getParamsItem(item, key),
                  });
                }}
                data={getValuePeriodDisable(aoFormData.capitalization_period_disabled)}
                callback={() =>
                  setInitialFilter(item, [...aoFormData.capitalization_period_disabled, { ...initialPeriodDisable }])
                }
              />
            );
          }

          if (item === KeyCardsEnum.PAYMENT_PLAN) {
            const totalAmount = aoFormData[KeyCardsEnum.PAYMENT_PLAN]?.reduce(
              (acc, { amount }) => acc + Number(amount),
              0,
            );

            const errorText = errors[item];

            return (
              <SwitchCustomSettings
                style={{ border: '1px solid #CED0D7', padding: 12, borderRadius: 8 }}
                styleButton={{ marginBottom: 0 }}
                getParamsItem={getParamsItem}
                isSwitch
                withoutSwitch
                itemKey={item}
                typeButton="button"
                deleteCallback={(i) => {
                  const copy = [...aoFormData[KeyCardsEnum.PAYMENT_PLAN]];

                  if (aoFormData[KeyCardsEnum.PAYMENT_PLAN][i][KeyCardsEnum.PAYMENT_ID]) {
                    return;
                  }
                  copy.splice(i, 1);
                  setInitialFilter(item, copy);

                  if (
                    Number(aoFormData?.purchased_receivables) !==
                    copy.reduce((prev, { amount }) => Number(amount || 0) + prev, 0)
                  ) {
                    addErrors({
                      [KeyCardsEnum.PURCHASED_RECEIVABLES]: 'Total Amount is not equal Purchased Receivables',
                      [KeyCardsEnum.PAYMENT_PLAN]: 'Total Amount is not equal Purchased Receivables',
                    });
                    return;
                  }
                  clearErrorsOfKey(item);
                  clearErrorsOfKey(KeyCardsEnum.PURCHASED_RECEIVABLES);
                }}
                onChange={(key, value, column) => {
                  aoFormData[KeyCardsEnum.PAYMENT_PLAN][column][key] = value;
                  setInitialFilter(item, [...aoFormData[KeyCardsEnum.PAYMENT_PLAN]], {
                    keyError: `${key}_${column}`,
                    valueError: value,
                    params: getParamsItem(item, key),
                  });
                  if (key !== 'amount') return;

                  const newValues =
                    aoFormData?.schedules?.map((plan, index) =>
                      index === column ? { ...plan, amount: value } : plan,
                    ) || [];

                  if (
                    Number(aoFormData?.purchased_receivables) !==
                    newValues.reduce((prev, { amount }) => Number(amount || 0) + prev, 0)
                  ) {
                    addErrors({
                      [KeyCardsEnum.PURCHASED_RECEIVABLES]: 'Total Amount is not equal Purchased Receivables',
                      [KeyCardsEnum.PAYMENT_PLAN]: 'Total Amount is not equal Purchased Receivables',
                    });
                    return;
                  }
                  clearErrorsOfKey(item);
                  clearErrorsOfKey(KeyCardsEnum.PURCHASED_RECEIVABLES);
                }}
                data={getValuePaymentPlan(aoFormData[KeyCardsEnum.PAYMENT_PLAN])}
                currency={aoFormData[KeyCardsEnum.CURRENCY]}
                callback={() =>
                  setInitialFilter(item, [...aoFormData[KeyCardsEnum.PAYMENT_PLAN], { amount: '', payment_date: '' }])
                }
                componentBetweenInputsAndButton={
                  totalAmount ? (
                    <div
                      style={{
                        background: errorText ? '#FEECF1' : '#ECF0F5',
                        padding: '12px 18px',
                        marginBottom: 32,
                        borderRadius: 8,
                      }}
                    >
                      <span style={{ color: '#707A87' }}>{`${t('forms.activationOrder.totalAmount')}: `}</span>
                      <b style={{ color: errorText ? '#EC3E72' : 'inherit' }}>
                        {getCurrencyWithAmount(totalAmount, aoFormData[KeyCardsEnum.CURRENCY] || 'EUR')}
                      </b>
                      {errorText && <span style={{ color: '#EC3E72' }}> {errorText}</span>}
                    </div>
                  ) : null
                }
              />
            );
          }

          if (item === KeyCardsEnum.CAPITALIZATION_DISABLE_DATE) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                isSwitch={listSwitch.capitalization_disable_date}
                handleChangeSwitch={() => {
                  changeSwitch(item, !listSwitch.capitalization_disable_date);
                }}
                onChange={(_, value) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                switchText={t('forms.activationOrder.turnOffCapitalizationUntil')}
                data={[
                  {
                    row: [
                      {
                        key: item,
                        label: t('forms.activationOrder.capitalizationDisableDate'),
                        val: aoFormData.capitalization_disable_date,
                      },
                    ],
                  },
                ]}
              />
            );
          }

          if (item === KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE) {
            return (
              <SwitchCustomSettings
                getParamsItem={getParamsItem}
                isSwitch={listSwitch.default_interest_disable_date}
                handleChangeSwitch={() => {
                  changeSwitch(item, !listSwitch.default_interest_disable_date);
                }}
                onChange={(_, value) => {
                  setInitialFilter(item, value, {
                    keyError: item,
                    valueError: value,
                    params: getParamsItem(KeyCardsEnum.ROOT, item),
                  });
                }}
                switchText={t('forms.activationOrder.turnOffDefaultInterest')}
                data={[
                  {
                    row: [
                      {
                        key: item,
                        label: t('forms.activationOrder.defaultInterestDisableDate'),
                        val: aoFormData.default_interest_disable_date,
                      },
                    ],
                  },
                ]}
              />
            );
          }

          if (item === KeyCardsEnum.CUSTOM_SETTINGS) {
            return (
              <SwitcherText
                title={t('forms.activationOrder.customSettings')}
                isOpen={listSwitch.custom_settings}
                callback={() => changeSwitch(KeyCardsEnum.CUSTOM_SETTINGS, !listSwitch.custom_settings)}
              />
            );
          }

          return <div />;
        }),
      )}
    </TemplateGrid>
  );
};

export default ItemsAo;

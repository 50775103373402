// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState, Children, useRef, useEffect, MouseEventHandler } from 'react';
import { isEmpty } from 'lodash';

import { InputKit, InputKitType } from '../InputKit';

import ColorPass from './ColorPass';
import styles from './styles.module.scss';

const arrowSvg = require('./img/arrow.svg').default;

const styleActive = { backgroundColor: '#F3F3F4', color: '#000000' };

export type SelectItemType = string | number | object;
export interface SelectKitType extends InputKitType {
  options?: SelectItemType[];
  withAllOption?: boolean;
  customOption?: (
    item: SelectItemType,
    isActive: boolean,
    change: MouseEventHandler<HTMLDivElement>,
  ) => React.ReactElement;
  keyObj?: string;
  changeSelectItem?: (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>,
    data?: any,
  ) => void;
  val: string | object;
  disabled?: boolean;
  dataCy?: string;
}

export const SelectKit: FC<SelectKitType> = (props) => {
  const {
    options = [],
    keyObj,
    changeSelectItem,
    val,
    onChange,
    disabled,
    customOption,
    withAllOption = true,
    dataCy,
  } = props;
  const [isSwitch, setIsSwitch] = useState(false);
  const [inputRef, setInputRef] = useState<{ focus?: Function }>({});
  const [localValue, setLocalValue] = useState('');
  const rootRef = useRef(null);

  let styleArrow = { cursor: 'pointer' } as { cursor: 'pointer'; transform: string };

  if (isSwitch) {
    styleArrow = { ...styleArrow, transform: ' rotateX(180deg)' };
  }
  let valLocal = val as string;

  if (typeof val === 'object' && keyObj) {
    valLocal = val[keyObj];
  }

  if (typeof val === 'object' && !keyObj) {
    valLocal = '';
  }

  useEffect(() => {
    setLocalValue(valLocal);
  }, [valLocal]);

  const element = (
    <div className={styles.selectIconStyle}>
      <span
        onClick={() => {
          if (disabled) return;

          if (!isSwitch) {
            inputRef?.focus();
            setIsSwitch(true);

            return;
          }
          setIsSwitch(false);
        }}
        style={{
          ...styleArrow,
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          width: 24,
          height: 24,
        }}
      >
        <img src={arrowSvg} alt="open" />
      </span>
    </div>
  );

  let list = options;

  if (localValue) {
    list = list.filter((item) => {
      const current = (keyObj ? item[keyObj] : typeof item === 'object' ? '' : item).toString();

      return current.toLowerCase().includes(String(localValue).toLocaleLowerCase());
    });
  }

  useEffect(() => {
    const onClick = (event) => {
      if (!rootRef.current?.contains(event.target)) {
        setIsSwitch(false);
      }
    };

    if (isSwitch) {
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
    }

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isSwitch]);

  return (
    <div ref={rootRef} className={styles.selectWrap} {...(dataCy && { 'data-cy': dataCy })}>
      <InputKit
        {...props}
        isSelect
        element={element}
        getInputRef={({ current }) => setInputRef(current)}
        onChange={(e) => {
          setLocalValue(e.target.value);
          changeSelectItem && !e.target.value && changeSelectItem(e, {});
          setIsSwitch(true);
          onChange && onChange(e);
        }}
        onFocus={() => {
          setIsSwitch(true);
        }}
        value={localValue}
        disabled={disabled}
        dataCy={undefined}
      />
      {isSwitch && list && (
        <div className={styles.menuSelectStyle}>
          {!localValue && withAllOption && (
            <div style={{ borderBottom: '1px solid rgba(229, 229, 229, 0.5)', paddingBottom: '5px' }}>
              <div
                onClick={(e) => {
                  changeSelectItem(e, typeof list[0] === 'object' ? {} : '');
                }}
                className={styles.menuSelectItemStyle}
                style={styleActive}
              >
                All
              </div>
            </div>
          )}
          {Children.toArray(
            list.map((item) => {
              const current = keyObj ? item[keyObj] : typeof item === 'object' ? '' : item;
              let styleActiveItem = {};

              if (typeof val === 'object') {
                const commonKey = Object.keys(val).filter((key) => item[key] !== undefined);
                const el = commonKey.find((key) => item[key] !== val[key]);

                if (!el && commonKey.length > 0 && localValue) {
                  styleActiveItem = styleActive;
                }
              }

              if (typeof val !== 'object' && current === val) {
                styleActiveItem = styleActive;
              }

              const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
                changeSelectItem(e, item);
                setIsSwitch(false);
                setLocalValue('');
              };

              if (customOption) {
                return customOption(item, isEmpty(styleActiveItem), handleMouseDown);
              }

              return (
                current && (
                  <div
                    style={styleActiveItem}
                    className={styles.menuSelectItemStyle}
                    onMouseDown={(e) => {
                      changeSelectItem(e, item);
                      setIsSwitch(false);
                    }}
                  >
                    <ColorPass splitText={localValue}>{current}</ColorPass>
                  </div>
                )
              );
            }),
          )}
        </div>
      )}
    </div>
  );
};

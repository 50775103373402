import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ModalComponent from '@material-ui/core/Modal';
import { Backdrop, Fade, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import useModal from 'hooks/api/useModal';

const Modal = () => {
  const classes = useStyles();
  const { modalData, setModal } = useModal();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setOpen(!!modalData.show);
    setTitle(modalData.title);
  }, [modalData]);

  const handleClose = () => {
    setOpen(false);
    setModal({ show: false, confirm: false });
  };
  const handleConfirm = () => {
    setOpen(false);
    setModal({ show: false, confirm: true });
  };

  return (
    <div>
      <ModalComponent
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.titleBlock}>
              <h2 id="transition-modal-title" className={classes.title}>
                {title}
              </h2>
            </div>
            <div className={classes.descriptionBlock}>
              <p id="transition-modal-description" className={classes.description}>
                {modalData.description || t('global.clarification')}
              </p>
            </div>
            <div className={classes.buttonsBlock}>
              <Button onClick={handleClose}>
                <ErrorOutlineIcon className={classes.icon} />
                {t('global.cancel')}
              </Button>
              <Button onClick={handleConfirm}>
                <CheckCircleIcon color="primary" className={classes.icon} />
                {t('global.confirm')}
              </Button>
            </div>
          </div>
        </Fade>
      </ModalComponent>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRight: '4px',
    paddingBottom: '0',
    paddingRight: '0',
    overflow: 'hidden',
  },
  titleBlock: {
    flex: '0 0 auto',
    margin: '0',
    paddingRight: '15px',
    paddingBottom: '16px',
    overflow: 'hidden',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '1.6',
  },
  descriptionBlock: {
    flex: '1 1 auto',
    paddingTop: '8px',
    overflowY: 'auto',
    marginBottom: '12px',
  },
  description: {
    minWidth: '400px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5',
  },
  buttonsBlock: {
    flex: '0 0 auto',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  icon: {
    paddingRight: '0.5em',
  },
}));

export default Modal;

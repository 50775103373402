import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface monthlyInvoiceParamsReportsType {
  year: number;
  month?: number;
  report_type?: 'monthly_report' | 'invoice_report';
  show_deleted?: boolean;
}
export interface monthlyInvoiceReportsType {
  id: string;
  errorCallback?: Function;
  resultKey?: string;
  successCallback?: Function;
  params?: monthlyInvoiceParamsReportsType;
}

export default ({
  id = '',
  errorCallback,
  successCallback,
  resultKey = 'monthlyInvoiceReportsData',
  params,
}: monthlyInvoiceReportsType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getMonthlyInvoiceReportsUrl(id, params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getMonthlyInvoiceReportsUrl(id, params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

import { CreateCompanyParamsType } from './createCompany';

export type editCompanyDataType = {
  id: string;
  params: CreateCompanyParamsType;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
  method?: 'PUT' | 'PATCH';
};

export default ({
  id,
  resultKey = 'editCompanyData',
  params,
  successCallback,
  errorCallback,
  method = 'PUT',
}: editCompanyDataType) =>
  requestAsync({
    url: endpoints.getCompanyUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompanyUrl(id),
    body: params,
    options: {
      method,
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { ChangeEvent } from 'react';

export type ActivationOrderFormPropsType = {
  editType?: boolean;
};

export type ErrorType = {
  companyId: { status: boolean; text: string };
  name: { status: boolean; text: string };
  amount: { status: boolean; text: string };
  currency: { status: boolean; text: string };
  vat_policy: { status: boolean; text: string };
  interest: { status: boolean; text: string };
  purchaseDate: { status: boolean; text: string };
  repaymentDate: { status: boolean; text: string };
  apply_from_date?: { status: boolean; text: string };
  contractDate: { status: boolean; text: string };
  status?: { status: boolean; text: string };
  commission_disable_date?: { status: boolean; text: string };
  capitalization_disable_date?: { status: boolean; text: string };
  default_interest_disable_date?: { status: boolean; text: string };
  grace_period: { status: boolean; text: string };
  days: { status: boolean; text: string };
  penalty_policy: { status: boolean; text: string };
  date_start_0: { status: boolean; text: string };
  date_end_0: { status: boolean; text: string };
};

export type CompanyType = {
  name: string;
  id: string;
  active?: boolean;
};

export type StatusSelectPropsType = {
  status: string;
  deletedActivationOrder: boolean;
  error: { status: boolean; text: string };
  onBlurHandler: (field: string) => void;
  setFormValue: (e: ChangeEvent<HTMLInputElement>) => void;
  selectStyles?: React.CSSProperties;
  toolTip?: string;
  wrapperStyle?: React.CSSProperties;
};

export type VatType = 'included' | 'not_included' | 'no_vat' | null;
export type PenaltyType = 'penalty_15_per_year' | 'penalty_40_per_year' | 'penalty_011_per_day' | null;

export type ActivationOrderFormDataType = {
  companyId: string;
  name: string;
  amount: string;
  currency: string;
  interest: string;
  purchaseDate: string;
  repaymentDate: string;
  is_custom_payment_day: boolean;
  payment_days: PaymentDay[];
  status: string;
  contract_id: string;
  contractNumber: string;
  contractDate: string;
  accounts: any[];
  deletion: {
    can_be_deleted: boolean;
    reason: string;
  };
  active: boolean;
  custom_grace_period: boolean;
  grace_period: number;
  turn_off_commission: boolean;
  commission_disable_date: string;
  turn_off_capitalization: boolean;
  capitalization_disable_date: string;
  turn_off_default_interest: boolean;
  default_interest_disable_date: string;
  vat_policy: VatType;
  auto_debiting: boolean;
  has_transaction?: boolean;
  days?: string;
  ao_type: string;
  penalty_policy: PenaltyType;
  commission_daily?: string | null;
  plan_commission_net?: string | null;
  plan_commission_vat?: string | null;
  plan_commission_gross?: string | null;
  commission_gross?: string | null;
  ao_document?: Ao_document;
  is_capitalization_period_disabled: boolean;
  capitalization_period_disabled: { date_start: string; date_end: string }[];
};

export type PaymentDay = {
  payment_day: string;
  apply_from_date: string;
  editable: boolean;
};

export type Ao_document = {
  id?: string;
  name?: string;
  type?: boolean;
  number?: string;
  date?: string;
  link?: boolean;
  invoice_num?: string;
  file_name?: string;
  deleted?: string;
};

export type Account = {
  company_id?: string;
  company_name?: string;
  id: string;
  account: string;
  balance: string;
  balance_date: string;
  created: string;
  currency: string;
  modified: string;
  order_id: string;
  order_number: string;
  type: string;
};

export const enum AOKeyEnum {
  SCHEDULES = 'schedules',
  ORDER_ID = 'order_id',
  PURCHASED_RECEIVABLES = 'purchased_receivables',
  EFFECTIVE_DATE = 'effective_date',
  PAYMENT_PLAN = 'payment_plan',
  PAYMENT_DATE = 'payment_date',
  AMOUNT = 'amount',
  DELETED = 'deleted',
  ID = 'id',
}

export interface RepaymentPlan {
  [AOKeyEnum.AMOUNT]: string;
  [AOKeyEnum.DELETED]: boolean;
  [AOKeyEnum.ID]: string;
  [AOKeyEnum.PAYMENT_DATE]: string;
}

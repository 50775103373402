import React, { FC } from 'react';
import { Table, TableContainer, TablePagination, makeStyles } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import ArrowScope from 'components/ArrowScope';
import UseParamsOfRefElement from 'hooks/UseParamsOfRefElement';

interface propsType {
  count?: number;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, page: number) => void;
  page?: number;
  rowsPerPage?: number;
  styleTop?: React.CSSProperties;
  styleLeft?: React.CSSProperties;
  tableContainerStyle?: React.CSSProperties;
  isShowPagination?: boolean;
  wrapStyle?: React.CSSProperties;
  rowsPerPageOptions?: number[];
  titleCount?: string;
  disabled?: boolean;
  withoutArrow?: boolean;
}

const TableWrapWithPagination: FC<propsType> = ({
  children,
  count = 100,
  onPageChange = () => {},
  page = 1,
  rowsPerPage = 10,
  onRowsPerPageChange = () => {},
  styleTop,
  styleLeft,
  tableContainerStyle,
  isShowPagination = true,
  wrapStyle,
  rowsPerPageOptions,
  disabled,
  titleCount,
  withoutArrow,
}) => {
  const { ref, showArrow, onChangePositionArrow } = UseParamsOfRefElement<HTMLDivElement>();
  const classes = useTableWrapWithPaginationStyles();

  return (
    <div style={{ ...wrapStyle }}>
      <div style={{ height: `calc(100% - ${isShowPagination ? 49 : 0}px)`, position: 'relative' }}>
        {!withoutArrow && (
          <ArrowScope
            onChangePositionArrow={onChangePositionArrow}
            arrowList={showArrow}
            styleTop={{ ...styleTop }}
            styleLeft={{ left: 192, boxShadow: 'rgb(89 94 100 / 5%) 2px -1px 1px inset', ...styleLeft }}
          />
        )}
        <TableContainer
          style={tableContainerStyle}
          ref={ref}
          onClick={() => {}}
          classes={{ root: `${classes.rootTableContainer} ${classes.scrollTable}` }}
        >
          <Table size="small" aria-label="wrap pagination" stickyHeader>
            {children}
          </Table>
        </TableContainer>
      </div>
      {isShowPagination && (
        <TablePagination
          style={{ height: 49 }}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to, ...props }) => (
            <div>{`${from}-${to} of ${props.count} ${titleCount || ''}`}</div>
          )}
          ActionsComponent={() => (
            <Pagination
              disabled={disabled}
              onChange={(_, value) => {
                onPageChange(value - 1);
              }}
              renderItem={(item) => (
                <PaginationItem
                  classes={{
                    root: classes.itemPaginationRoot,
                    selected: classes.itemPaginationSelected,
                    icon: classes.itemPaginationIcon,
                  }}
                  {...item}
                />
              )}
              page={page + 1}
              classes={{ root: classes.rootPagination }}
              count={Math.ceil(count / rowsPerPage)}
              shape="rounded"
            />
          )}
          component="div"
          SelectProps={{ MenuProps: { classes: { list: classes.list } } }}
          classes={{
            spacer: classes.spacer,
            toolbar: classes.toolbar,
            caption: classes.captionRoot,
            select: classes.select,
            selectIcon: classes.icon,
            menuItem: classes.menuItem,
          }}
          onPageChange={() => {}}
          count={count}
          page={page}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e, Number(e.target.value));
          }}
          rowsPerPage={rowsPerPage}
        />
      )}
    </div>
  );
};

export const useTableWrapWithPaginationStyles = makeStyles(() => ({
  itemPaginationIcon: {
    color: '#838998',
  },
  itemPaginationRoot: {
    width: 24,
    height: 24,
    color: '#838998',
    fontSize: 14,
  },
  itemPaginationSelected: {
    border: '1px solid #ECF0F5',
    borderRadius: 4,
    backgroundColor: '#FFFFFF !important',
  },
  list: {
    padding: '8px 4px',
  },
  menuItem: {
    borderRadius: 4,
    '&.Mui-selected': {
      backgroundColor: '#FBFBFB',
    },
  },
  icon: {
    color: '#838998',
  },
  select: {
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #ECF0F5',
    color: '#838998',
    fontSize: 14,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 8,
    },
  },
  captionRoot: {
    color: '#838998',
    fontSize: 14,
  },
  toolbar: {
    minHeight: 24,
    height: 24,
    marginTop: 21,
    padding: 0,
  },
  rootPagination: {
    flex: '1 1 100%',
    display: 'flex',
    justifyContent: 'end',
  },
  spacer: {
    display: 'none',
  },
  rootTableContainer: {
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  scrollTable: {
    scrollbarWidth: 'thin',
    /* width */
    '&::-webkit-scrollbar': {
      width: 10,
      height: 10,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      border: '1px solid #ECEEF2',
      borderRadius: 8,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#CED0D7',
      borderRadius: 8,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#c6c8ce',
    },
  },
}));

export default TableWrapWithPagination;

// '2px 0px 4px rgba(89, 94, 100, 0.1)'

import { typeSortEnum } from 'components/SortTable';

interface ITableData {
  title: string;
  dataKey: string;
  bodyAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export enum dataAccrualKey {
  EUR = 'tables.clientAmounts.eur',
  USD = 'tables.clientAmounts.usd',
  COMPANY_NAME_TITLE = 'contentHeader.companyName',
  CONTRACT_NUMBER_TITLE = 'tables.clientAmounts.contractNumber',
  COMMISSION_ACCRUED_GROSS_TITLE = 'tables.clientAmounts.commissionAccruedGross',
  COMMISSION_ACCRUED_NET_TITLE = 'tables.clientAmounts.commissionAccruedNet',
  VAT_TITLE = 'tables.clientAmounts.vat',
  DEFAULT_INTEREST_ACCRUED_TITLE = 'tables.clientAmounts.defaultInterestAccrued',
  BANK_FEE_TITLE = 'tables.clientAmounts.bankFee',
  INVOICE_TITLE = 'tables.clientAmounts.invoice',

  COMMISSION_ACCRUED_GROSS_EUR = 'commission_accrued_gross_eur',
  COMMISSION_ACCRUED_GROSS_USD = 'commission_accrued_gross_usd',
  COMMISSION_ACCRUED_NET_EUR = 'commission_accrued_net_eur',
  COMMISSION_ACCRUED_NET_USD = 'commission_accrued_net_usd',
  VAT_EUR = 'vat_eur',
  VAT_USD = 'vat_usd',
  DEFAULT_INTEREST_ACCRUED_EUR = 'default_interest_eur',
  DEFAULT_INTEREST_ACCRUED_USD = 'default_interest_usd',
  BANK_FEE_EUR = 'bank_fee_eur',
  BANK_FEE_USD = 'bank_fee_usd',
  FILE_ID_INVOICE_IN_EUR = 'file_id_invoice_in_eur',
  FILE_ID_INVOICE_IN_USD = 'file_id_invoice_in_usd',
  COMPANY_NAME = 'company_name',
  CONTRACT_NUMBER = 'contract_number',
}

export const tableNameHead = [
  { key: dataAccrualKey.COMPANY_NAME, value: dataAccrualKey.COMPANY_NAME_TITLE, typeSort: typeSortEnum.ALPHABET },
  { key: dataAccrualKey.CONTRACT_NUMBER, value: dataAccrualKey.CONTRACT_NUMBER_TITLE, isHiddenSort: true },
  {
    key: dataAccrualKey.COMMISSION_ACCRUED_GROSS_EUR,
    value: dataAccrualKey.COMMISSION_ACCRUED_GROSS_TITLE,
    props: { colSpan: 2 },
    isHiddenSort: true,
  },
  {
    key: dataAccrualKey.COMMISSION_ACCRUED_NET_EUR,
    value: dataAccrualKey.COMMISSION_ACCRUED_NET_TITLE,
    props: { colSpan: 2 },
    isHiddenSort: true,
  },
  { key: dataAccrualKey.VAT_EUR, value: dataAccrualKey.VAT_TITLE, props: { colSpan: 2 }, isHiddenSort: true },
  {
    key: dataAccrualKey.DEFAULT_INTEREST_ACCRUED_EUR,
    value: dataAccrualKey.DEFAULT_INTEREST_ACCRUED_TITLE,
    props: { colSpan: 2 },
    isHiddenSort: true,
  },
  { key: dataAccrualKey.BANK_FEE_EUR, value: dataAccrualKey.BANK_FEE_TITLE, props: { colSpan: 2 }, isHiddenSort: true },
  {
    key: dataAccrualKey.FILE_ID_INVOICE_IN_EUR,
    value: dataAccrualKey.INVOICE_TITLE,
    props: { colSpan: 2 },
    isHiddenSort: true,
  },
];

export const tableCellData: ITableData[] = [
  {
    title: '',
    dataKey: dataAccrualKey.COMPANY_NAME,
    bodyAlign: 'left',
  },
  {
    title: '',
    dataKey: dataAccrualKey.CONTRACT_NUMBER,
    bodyAlign: 'left',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.COMMISSION_ACCRUED_GROSS_EUR,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.COMMISSION_ACCRUED_GROSS_USD,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.COMMISSION_ACCRUED_NET_EUR,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.COMMISSION_ACCRUED_NET_USD,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.VAT_EUR,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.VAT_USD,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.DEFAULT_INTEREST_ACCRUED_EUR,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.DEFAULT_INTEREST_ACCRUED_USD,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.BANK_FEE_EUR,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.BANK_FEE_USD,
    bodyAlign: 'right',
  },
  {
    title: dataAccrualKey.EUR,
    dataKey: dataAccrualKey.FILE_ID_INVOICE_IN_EUR,
    bodyAlign: 'left',
  },
  {
    title: dataAccrualKey.USD,
    dataKey: dataAccrualKey.FILE_ID_INVOICE_IN_USD,
    bodyAlign: 'right',
  },
];

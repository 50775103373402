import React, { FC, useEffect, useMemo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { CIcon, Button, DownloadIcon, CloseBigIcon } from '@gamesb42/ui-kit';

import { tableStyleAo } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import useMonthlyReport, {
  metaReportsEnum,
  monthlyInvoiceReportsRecordsType,
  useMonthlyReportType,
} from 'hooks/api/useMonthlyReport';
import { formatDate, getCurrentYear } from 'helpers/formatters';
import endpoints from 'consts/endpoints';
import documentSvg from 'assets/img/document.svg';
import documentErrorSvg from 'assets/img/document_error.svg';
import uploadSvg from 'assets/img/uploadRevenue.svg';
import downloadSvg from 'assets/img/newDownload.svg';
import crossSvg from 'assets/img/backPopUp.svg';
import reloadSvg from 'assets/img/reload.svg';
import { convertFileToBase64 } from 'helpers/file';
import baseRequest from 'queries/baseRequest';

import { getMonthlyReportRecreate } from '../../reportsData';

import ActionButtons from './ActionButtons';
import { getFileStorage, getMonthlyInvoiceRecreate, getMonthlyInvoiceReportDelete } from './data';
import styles from './styles.module.scss';
import UploadModal from './UploadModal';
import { getToken } from 'helpers/token';

type PropsT = {
  companyId: string;
  current: 0 | 1;
};

export type UploadInvoiceParamsProps = {
  id: string;
  name: string;
  invoiceNumber?: string;
};

const ReportingDocuments: FC<PropsT> = ({ companyId, current }) => {
  const [uploadInvoiceParams, setUploadInvoiceParams] = useState<UploadInvoiceParamsProps>();
  const { t } = useTranslation();
  const classes = tableStyleAo();
  const { getMonthlyInvoiceReports, monthlyInvoiceReportsData }: useMonthlyReportType = useMonthlyReport();
  const { invoice, monthly } = useMemo(() => {
    let invoiceLocal = [] as monthlyInvoiceReportsRecordsType[];
    let monthlyLocal = [] as monthlyInvoiceReportsRecordsType[];
    monthlyInvoiceReportsData.records &&
      monthlyInvoiceReportsData.records.forEach((item) => {
        if (item.meta.type === metaReportsEnum.MONTHLY_REPORT) {
          monthlyLocal = [...monthlyLocal, item];

          return;
        }
        invoiceLocal = [...invoiceLocal, item];
      });

    return { invoice: invoiceLocal, monthly: monthlyLocal };
  }, [monthlyInvoiceReportsData]);

  const fetchMonthlyInvoiceReport = useCallback(() => {
    getMonthlyInvoiceReports({
      id: companyId,
    });
  }, [companyId]);

  useEffect(() => {
    if (!companyId) return;
    fetchMonthlyInvoiceReport();
  }, []);

  const currentList = current === 0 ? invoice : monthly;

  const onSaveFile = async ({ file, documentNumber }: { file: File; documentNumber?: string }) => {
    const invoiceId = (uploadInvoiceParams as UploadInvoiceParamsProps).id;
    setUploadInvoiceParams(undefined);
    const fileBlob = await convertFileToBase64(file);

    await baseRequest({
      path: endpoints.getUploadInvoicesUrl(invoiceId),
      options: {
        method: 'POST',
        body: {
          file_content: fileBlob,
          ...(documentNumber && { invoice_alias: documentNumber }),
        },
      },
    });

    await fetchMonthlyInvoiceReport();
  };

  return (
    <div className={styles.wrapper}>
      <UploadModal
        uploadInvoiceParams={uploadInvoiceParams}
        setUploadInvoiceParams={setUploadInvoiceParams}
        onSaveFile={onSaveFile}
      />
      <div>
        <ActionButtons company_id={companyId} tab={current === 0 ? 'invoice' : 'monthly'} />
      </div>

      <TableWrapWithPagination
        withoutArrow
        tableContainerStyle={{ backgroundColor: 'inherit', overflowX: 'hidden' }}
        isShowPagination={false}
      >
        {current === 0 && (
          <TableBody>
            {currentList.map(({ file_id, id, meta, invoice_number, invoice_alice }) => {
              const hasFile = !!file_id;

              return (
                <TableRow
                  className={classNames(styles.tableRow, hasFile && styles.tableRowPointer)}
                  onClick={() => {
                    if (hasFile)
                      getFileStorage(endpoints.getStorageFileUrl(file_id), undefined, undefined, undefined, true);
                  }}
                >
                  <TableCell className={classNames(styles.cell, styles.iconCell, styles.leftIconCell)}>
                    <img className={styles.icon} src={hasFile ? documentSvg : documentErrorSvg} alt="icon" />
                  </TableCell>
                  <TableCell className={classNames(styles.cell, styles.nameCell)}>
                    <span className={classNames(hasFile && styles.underline)}>{meta.friendly_name}</span>
                    {!hasFile && <span className={styles.noFile}> no file</span>}
                  </TableCell>
                  <TableCell align="right" className={classNames(styles.cell, styles.iconCell)}>
                    {hasFile ? (
                      <div style={{ display: 'flex', gap: '24px' }}>
                        <Tooltip title="Create CN" placement="top">
                          <Button
                            type="iconBtn"
                            icon={<DownloadIcon size={24} />}
                            className={styles.iconButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              getFileStorage(endpoints.getStorageFileUrl(file_id));
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Create CN" placement="top">
                          <Button
                            type="iconBtn"
                            icon={<CIcon size={24} />}
                            className={styles.iconButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              const myHeaders = new Headers();
                              myHeaders.append('authorization', `Bearer ${getToken()}`);

                              fetch(`${process.env.REACT_APP_BASE_URL}/invoice/${id}/cancel`, {
                                method: 'POST',
                                headers: myHeaders,
                                redirect: 'follow',
                              })
                                .then((response) => response.text())
                                .then((result) => console.log(result))
                                .then(() => fetchMonthlyInvoiceReport())
                                .catch((error) => console.error(error));
                            }}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip title="Upload" placement="top">
                        <div className={styles.uploadBlock}>
                          <img
                            className={styles.iconButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              setUploadInvoiceParams({
                                id,
                                invoiceNumber: invoice_alice || invoice_number,
                                name: meta.friendly_name,
                              });
                            }}
                            src={uploadSvg}
                            alt="upload"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="right" className={classNames(styles.cell, styles.iconCell)} />
                  <TableCell align="right" className={classNames(styles.cell, styles.iconCell, styles.rightIconCell)}>
                    {hasFile ? (
                      <Tooltip title="Delete" placement="top">
                        <Button
                          type="iconBtn"
                          icon={<CloseBigIcon />}
                          className={styles.iconButton}
                          onClick={async (e) => {
                            e.stopPropagation();
                            await getMonthlyInvoiceReportDelete(companyId, id);
                            await fetchMonthlyInvoiceReport();
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Generate New" placement="top">
                        <img
                          className={styles.iconButton}
                          onClick={async (e) => {
                            e.stopPropagation();

                            if (meta.type === metaReportsEnum.MONTHLY_REPORT) {
                              await getMonthlyReportRecreate(companyId, {
                                report_date: meta.start_date,
                                document_id: id,
                              });
                            } else {
                              await getMonthlyInvoiceRecreate({
                                invoice_id: id,
                              });
                            }
                            await fetchMonthlyInvoiceReport();
                          }}
                          src={reloadSvg}
                          alt="regeneration"
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
        {current === 1 && (
          <>
            <TableHead style={{ height: '40px' }} classes={{ root: classes.documentHeaderRowRoot }}>
              <TableRow>
                <TableCell style={{ width: 184 }} classes={{ root: styles.clientHeader }}>
                  {t('clientTabs.date')}
                </TableCell>
                <TableCell classes={{ root: styles.clientHeader }}>{t('clientTabs.documentName')}</TableCell>
                <TableCell classes={{ root: styles.clientHeader }} style={{ width: 100 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentList.map(({ file_id, id, meta, name, report_date }) => (
                <TableRow style={{ height: 56 }} className={styles.tableRowStyle}>
                  <TableCell style={{ width: 184 }}>{formatDate(report_date)}</TableCell>
                  <TableCell>
                    <span style={{ borderBottom: '1px solid #29292C' }}>{name}</span>
                  </TableCell>
                  <TableCell align="right" style={{ opacity: 0, paddingRight: 51 }}>
                    <div className={styles.actionButtonWrapper}>
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          if (meta.type === metaReportsEnum.MONTHLY_REPORT) {
                            await getMonthlyReportRecreate(companyId, {
                              report_date: meta.start_date,
                              document_id: id,
                            });
                          } else {
                            await getMonthlyInvoiceRecreate({
                              invoice_id: id,
                            });
                          }
                          await fetchMonthlyInvoiceReport();
                        }}
                        src={reloadSvg}
                        alt="regeneration"
                      />
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => getFileStorage(endpoints.getStorageFileUrl(file_id))}
                        src={downloadSvg}
                        alt="download"
                      />
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          await getMonthlyInvoiceReportDelete(companyId, id);
                          await fetchMonthlyInvoiceReport();
                        }}
                        src={crossSvg}
                        alt="delete"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableWrapWithPagination>
    </div>
  );
};

export default ReportingDocuments;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';

import updateUtils from 'actions/updateUtils';
import getPurchaseRequestList, {
  PurchaseRequestParamsT,
  PurchaseRequestT,
} from 'queries/purchaseRequest/getPurchaseRequestList';
import getPurchaseRequestListForFilter, {
  PurchaseRequestForFilterT,
} from 'queries/purchaseRequest/getPurchaseRequestListForFilter';
import getPurchaseRequest from 'queries/purchaseRequest/getPurchaseRequest';
import deletePurchaseRequest, { DeletePurchaseRequestT } from 'queries/purchaseRequest/deletePurchaseRequest';
import getNextNumber, { NextNumberT } from 'queries/purchaseRequest/getNextNumber';
import setNewStatus, { PurchaseRequestSetStatusT } from 'queries/purchaseRequest/setNewStatus';
import getAvailableAdvance, { AvailableAdvanceT } from 'queries/purchaseRequest/getAvailableAdvance';
import editPurchaseRequest, { EditPurchaseRequestT } from 'queries/purchaseRequest/editPurchaseRequest';
import createPurchaseRequest, { CreatePurchaseRequestT } from 'queries/purchaseRequest/createPurchaseRequest';
import { CurrencyType } from 'components/uiKit/InputKit';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

export const initialPurchaseRequestParams: PurchaseRequestParamsT = {
  company_id: undefined,
  page: 0,
  limit: 15,
  order_id: undefined,
  request_number: undefined,
  currency: undefined,
  status: undefined,
};

export enum PurchaseRequestStatus {
  NEW = 'new',
  REJECTED = 'rejected',
  PENDING = 'pending',
  SIGNED = 'signed',
  FUNDED = 'funded',
  REPAID = 'repaid',
}

export enum PurchaseRequestStatusToNumber {
  rejected,
  new,
  pending,
  signed,
  funded,
  repaid,
}

export interface PurchaseRequestTableData {
  id: string;
  company: string;
  ao: number;
  request: number;
  purchase_date: string;
  funded: number;
  outstanding: number;
  repayment: string;
  currency: CurrencyType;
  status: PurchaseRequestStatus;
  purchase_price: string;
}

interface PurchaseRequestList {
  records: PurchaseRequestTableData[];
  limit: number;
  total: number;
  page: number;
}

export interface UsePurchaseRequestType {
  availableAdvanceData: AvailableAdvanceType;
  purchaseRequestList: PurchaseRequestList;
  purchaseRequestListForFilter: number[];
  purchaseRequestParams?: PurchaseRequestParamsT;
  getPurchaseRequestList?: (data?: PurchaseRequestT) => void;
  getPurchaseRequestListForFilter?: (data?: PurchaseRequestForFilterT) => void;
  getAvailableAdvance?: (data: AvailableAdvanceT) => void;
  clearPurchaseRequestList?: () => void;
  setPurchaseRequestParams?: (params: PurchaseRequestParamsT) => void;
  setNewStatus?: (data: PurchaseRequestSetStatusT) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getPurchaseRequest?: ({ id: any }) => void;
  deletePurchaseRequest?: (data: DeletePurchaseRequestT) => void;
  purchaseRequestData?: any;
  nextPurchaseRequestNumber?: {
    next_pr_number: number;
  };
  getNextNumber?: (data: NextNumberT) => void;
  createPurchaseRequest?: (data: CreatePurchaseRequestT) => void;
  editPurchaseRequest?: (data: EditPurchaseRequestT) => void;
  clearPurchaseRequestData?: () => void;
}

export enum PurchaseRequestEnum {
  'APPLAUD_FIX' = 'applaud_fix',
  'CLASSIC' = 'classic',
  'FUTURE_RECEIVABLES' = 'future_receivables',
  'AVAILABLE_RECEIVABLES' = 'available_receivables',
}

export interface PlatformType {
  platform_id?: string;
  platform?: any;
  platform_logo?: any;
  ar?: any;
  purchased?: any;
  purchase_price?: any;
  due_date?: any;
  outstanding?: string;
  currency?: string;
  expected_date?: string;
  claim_id?: string;
}

export interface AvailableAdvanceType {
  available_advance: string;
  available_receivable: string;
  currency: string;
  funding_amount: string;
  min_threshold: number;
  ransom_amount: string;
  platforms: {
    available_advance: string;
    available_receivable: string;
    currency: string;
    funding_amount: string;
    platform: string;
    platform_id: string;
    platform_logo: string;
    purchase_percentage: string;
    purchase_price_percentage: string;
    claim_id: string;
  }[];
}

export interface PurchaseRequestDataType {
  purchaseRequestData?: any;
  ao_document?: any;
  contract?: any;
  account_number?: any;
  currency?: any;
  id?: any;
  company?: any;
  company_id?: any;
  ao?: any;
  ao_id?: any;
  request?: any;
  request_date?: any;
  purchase_date?: any;
  status?: any;
  purchased_receivables?: any;
  purchase_price?: any;
  purchase_amount?: any;
  file?: any;
  platforms: PlatformType[];
  file_id?: any;
  file_name?: any;
  comment?: string | null;
  pr_date?: string | null;
}

const usePurchaseRequest = (): UsePurchaseRequestType => {
  const selector = useCallback(
    (state) => ({
      purchaseRequestListForFilter: arraySelector(state, 'purchaseRequestListForFilter'),
      purchaseRequestList: arraySelector(state, 'purchaseRequestList'),
      purchaseRequestData: arraySelector(state, 'purchaseRequestData'),
      availableAdvanceData: objectSelector(state, 'availableAdvanceData'),
      purchaseRequestParams: utilsSelector(state, 'purchaseRequestParams', initialPurchaseRequestParams),
      nextPurchaseRequestNumber: objectSelector(state, 'nextPurchaseRequestNumber'),
      deletePurchaseRequest: objectSelector(state, 'deletePurchaseRequest'),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getPurchaseRequestList,
      getPurchaseRequestListForFilter,
      getPurchaseRequest,
      deletePurchaseRequest,
      getAvailableAdvance,
      createPurchaseRequest,
      editPurchaseRequest,
      getNextNumber,
      setNewStatus,
    }),
    [],
  );

  const activationOrdersTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          clearPurchaseRequestList: () =>
            updateEntities({
              purchaseRequestList: () => {},
            }),
          clearPurchaseRequestData: () =>
            updateEntities({
              purchaseRequestData: () => {},
            }),
          setPurchaseRequestParams: (purchaseRequestParams: PurchaseRequestParamsT) =>
            updateUtils({
              purchaseRequestParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(() => bindActionCreators({}, dispatch), [dispatch]);

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...actionWithoutIsFetching,
    ...activationOrdersTableActions,
  };
};

export default usePurchaseRequest;

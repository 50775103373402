import _ from 'lodash';

import { formatDate, formatDateIsoShort } from './formatters';

export function firstDayInPreviousMonth(yourDate: Date) {
  return new Date(yourDate.getFullYear(), yourDate.getMonth() - 1, 1);
}

export const firstDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);
export const firstDayOfNextMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 1);
export const lastDayOfNextMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 2, 0);
export const firstDayOfPrevMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() - 1, 1);
export const truncateDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const datePlusDays = (date: Date, countDays: number): number => {
  const oneDaySeconds = 24 * 60 * 60 * 1000;

  return new Date().setTime(new Date(date).getTime() + oneDaySeconds * countDays);
};
export const lastDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
export const lastDayOfPrevMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 0);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const dateRangeValidator = ({ date, min = '1900-01-01', max = '2099-12-31' }) => {
  const dateMilliseconds = Date.parse(date);
  const dateMinMilliseconds = Date.parse(min);
  const dateMaxMilliseconds = Date.parse(max);

  if (dateMilliseconds > dateMaxMilliseconds) {
    return { validate: false, type: 'max' };
  }

  if (dateMilliseconds < dateMinMilliseconds) {
    return { validate: false, type: 'min' };
  }

  return { validate: true, type: undefined };
};

export const getErrorTextByErrorType = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  type,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  minText,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  maxText,
  limit = { min: '1900-01-01', max: '2099-12-31' },
}) => {
  let text;

  if (type === 'min') {
    text = `${minText} (${formatDate(limit.min)})`;
  }

  if (type === 'max') {
    text = `${maxText} (${formatDate(limit.max)})`;
  }

  return text;
};

export const getDateByYearsAgo = (years: number) => {
  const dateNow = new Date();

  return formatDateIsoShort(new Date(dateNow.setFullYear(dateNow.getFullYear() - years)));
};

export const removeEmptyValuesFromObject = <T extends unknown>(object: {}) =>
  _.omitBy<T>(object, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

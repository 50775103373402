import React, { FC } from 'react';

import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import GeneralInformation from 'components/forms/ActivationOrderForm/General/common/GeneralInformation';
import CustomSettings from 'components/forms/ActivationOrderForm/General/common/SettingCustom';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';
import { Account } from 'types/ActivationOrderTypes';

type PropsT = {
  activationOrderData: activationOrderDataType;
  accounts: Account[];
};

const ApplaudAO: FC<PropsT> = ({ activationOrderData, accounts }) => {
  const isHasCustomSettings =
    activationOrderData[GeneralEnum.GRACE_PERIOD] ||
    activationOrderData[GeneralEnum.TURN_OFF_CAPITALIZATION_UNTILL] ||
    activationOrderData[GeneralEnum.DEFAULT_INTEREST_DISABLE_DATE];

  return (
    <>
      <GeneralInformation type="applaud_fix" activationOrderData={activationOrderData} accounts={accounts} />
      {isHasCustomSettings && (
        <CustomSettings
          activationOrderData={activationOrderData}
          shouldCustomSettings={['gracePeriod', 'turnOffCapitalizationUntill', 'defaultInterestDisableDate']}
        />
      )}
    </>
  );
};

export default ApplaudAO;

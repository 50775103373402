import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';
import { bindActionCreators } from 'redux';

import updateUtils from 'actions/updateUtils';
import { CompanyType } from 'types/CompanyTypes';
import getCommissionReports from 'queries/commissionReport/getCommissionReports';
import { formatDateIsoShort } from 'helpers/formatters';
import useActionsWithFetchingState from 'hooks/useActionsWithFetchingState';
import { NameKeyCommissionReport } from 'components/tables/CommissionReportTable/commissionReportData';
import { TOrder } from 'types/tableTypes';

import { arraySelector, utilsSelector } from './selectors';

type commissionReportTableParamsType = {
  company_id: string;
  report_date: string;
  company: CompanyType[];
  contract_number?: string;
  currency?: string;
  _sort?: NameKeyCommissionReport;
  _order?: TOrder;
  status?: string;
  activation_order_id?: string;
  _start?: number;
  _end?: number;
};

const initialCommissionReportTableParams: commissionReportTableParamsType = {
  company_id: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  report_date: formatDateIsoShort(new Date()),
  company: [],
  contract_number: '',
  currency: '',
  status: '',
  activation_order_id: '',
  _start: 0,
  _end: 10,
};

const useCommissionReport = () => {
  const selector = useCallback(
    (state) => ({
      commissionReportsData: arraySelector(state, 'commissionReportsData'),
      commissionReportTableParams: utilsSelector(
        state,
        'commissionReportTableParams',
        initialCommissionReportTableParams,
      ),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getCommissionReports,
    }),
    [],
  );

  const commissionReportTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setCommissionReportTableParams: (commissionReportTableParams: commissionReportTableParamsType) =>
            updateUtils({
              commissionReportTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearCommissionReportsData: () =>
            updateEntities({
              commissionReportsData: () => [],
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...commissionReportTableActions,
    ...actionWithoutIsFetching,
  };
};

export default useCommissionReport;

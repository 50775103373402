import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from '@gamesb42/ui-kit';

import { BankAccount, CompanyKeyEnum } from 'types/CompanyTypes';

const DescriptionBankAccount: FC<{ account: BankAccount }> = ({ account }) => {
  const { t } = useTranslation();

  return (
    <Description
      dataSource={[
        {
          title: t('forms.companies.bankDetails.accountCurrency'),
          content: account[CompanyKeyEnum.CURRENCY],
        },
        {
          title: t('forms.companies.bankDetails.bankName'),
          content: account[CompanyKeyEnum.BANK_NAME],
        },
        {
          title: t('forms.companies.bankDetails.SWIFTCode'),
          content: account[CompanyKeyEnum.SWIFT],
        },
        {
          title: t('forms.companies.bankDetails.IBANOrAccountNumber'),
          content: account[CompanyKeyEnum.ACCOUNT],
        },
        {
          title: t('forms.companies.bankDetails.correspondentBank'),
          content: account[CompanyKeyEnum.CORRESPONDENT_BANK_NAME],
        },
        {
          title: t('forms.companies.bankDetails.correspondentSWIFT'),
          content: account[CompanyKeyEnum.CORRESPONDENT_SWIFT],
        },
        {
          title: t('forms.companies.bankDetails.correspondentAccount'),
          content: account[CompanyKeyEnum.CORRESPONDENT_ACCOUNT],
        },
        {
          title: t('forms.companies.bankDetails.bankAddress'),
          content: account[CompanyKeyEnum.BANK_ADDRESS],
        },
        {
          title: t('forms.companies.bankDetails.specialInstructions'),
          content: account[CompanyKeyEnum.SPECIAL_INSTRUCTIONS],
        },
      ]}
    />
  );
};

export default DescriptionBankAccount;

import { formatAmount } from 'helpers/formatters';
import { alignRight, alignLeft } from 'consts/dict';
import { typeSortEnum } from 'components/SortTable';

const alignHeader = alignRight;

export enum NameKeyPivotTransactionsReport {
  DAY = 'day',
  AMOUNT_EUR = 'amount_eur',
  AMOUNT_USD = 'amount_usd',
  AMOUNT_IN_EUR = 'amount_in_eur',
  AMOUNT_USD_PLUS_EUR = 'amount_usd_plus_eur',
  SUM_COMMISSIONS_IN_EUR = 'sum_commissions_in_eur',
  EFF_COM_RATE = 'eff_com_rate',
  LOAN_COST = 'loan_cost',
  RATE = 'rate',
}

export const dataPivotTransactionsReport = [
  {
    key: NameKeyPivotTransactionsReport.DAY,
    translate: 'tables.pivotTransaction.day',
    align: alignLeft,
    dateLocal: false,
    typeSort: typeSortEnum.ALPHABET,
  },
  {
    key: NameKeyPivotTransactionsReport.AMOUNT_EUR,
    translate: 'tables.pivotTransaction.principalAmountEur',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.AMOUNT_USD,
    translate: 'tables.pivotTransaction.principalAmountUsd',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.AMOUNT_IN_EUR,
    translate: 'tables.pivotTransaction.principalAmountUsdEur',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.AMOUNT_USD_PLUS_EUR,
    translate: 'tables.pivotTransaction.principalAmountEurPlusUsd',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.SUM_COMMISSIONS_IN_EUR,
    translate: 'tables.pivotTransaction.percentAmountEurPlusUsd',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.EFF_COM_RATE,
    translate: 'tables.pivotTransaction.resultingCommissionRate',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.LOAN_COST,
    translate: 'tables.pivotTransaction.loanCostsEur',
    align: alignHeader,
  },
  {
    key: NameKeyPivotTransactionsReport.RATE,
    translate: 'tables.pivotTransaction.eurUsdFx',
    align: alignHeader,
  },
];

export const getKeyPivotTransactionsReporting = (key: NameKeyPivotTransactionsReport, value: string | null) => {
  switch (key) {
    case NameKeyPivotTransactionsReport.DAY:
      return value;
    case NameKeyPivotTransactionsReport.AMOUNT_EUR:
      return formatAmount(Number(value), null, true);
    case NameKeyPivotTransactionsReport.AMOUNT_USD:
      return formatAmount(Number(value), null, true);
    case NameKeyPivotTransactionsReport.AMOUNT_IN_EUR:
      return formatAmount(Number(value), null, true);
    case NameKeyPivotTransactionsReport.AMOUNT_USD_PLUS_EUR:
      return value ? formatAmount(Number(value), null, true) : null;
    case NameKeyPivotTransactionsReport.SUM_COMMISSIONS_IN_EUR:
      return formatAmount(Number(value), null, true);
    case NameKeyPivotTransactionsReport.EFF_COM_RATE:
      return `${value} %`;
    case NameKeyPivotTransactionsReport.LOAN_COST:
      return formatAmount(Number(value), null, true);
    case NameKeyPivotTransactionsReport.RATE:
      return value
        ? formatAmount<{ minimumFractionDigits: number }>(Number(value), null, true, {
            minimumFractionDigits: 4,
          })
        : null;
    default:
      return value;
  }
};

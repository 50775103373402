// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

import useActivationOrder from 'hooks/api/useActivationOrder';
import useErrorCallback from 'hooks/useErrorCallback';
import useTransaction from 'hooks/api/useTransaction';
import { formatAo, formatDateIsoShort } from 'helpers/formatters';
import { TargetRowPropsType } from 'types/TransactionFormTypes';
import { round2 } from 'helpers/num';
import {
  needRepaymentDateField,
  getTargetRowAutocompleteOption,
  getInitialRepaymentDate,
} from 'helpers/transactionFormHelpers';
import { getErrorTextByErrorType, dateRangeValidator } from 'helpers/dt';
import AmountFormat from 'components/inputs/AmountFormat';
import DateInput from 'components/inputs/DateInput';
import AmountInput from 'components/inputs/AmountInput';
import { InputKit } from 'components/uiKit/InputKit';

import LoanBalanceAlert from './LoanBalanceAlert';
import { Claim, getPlatformByClaims } from './initialValues';

const TargetRow = ({
  target,
  index,
  activationOrders,
  formData,
  removeHandler,
  handleTargetsAddInFormData,
  isCheckErrors,
  disabled,
  toolTip = '',
}: TargetRowPropsType) => {
  const { t } = useTranslation();
  const requiredText = t('auth.required');
  const classes = useStyles();
  const { getActivationOrder } = useActivationOrder();
  const { createOrderAmount } = useTransaction();
  const { errorCallback } = useErrorCallback();
  const [claim, setClaim] = useState('');
  const [error, setError] = useState({
    order_id: false,
    target_type: false,
    rate: false,
    amount: false,
    tx_currency_amount: false,
    repayment_date: { error: false, text: requiredText },
  });
  const [activationOrderValue, setActivationOrderValue] = useState(null);
  const [needExchange, setNeedExchange] = useState<boolean>(
    target.order_currency && formData.currency && target.order_currency !== formData.currency,
  );
  const [repaymentDate, setRepaymentDate] = useState<string>(getInitialRepaymentDate(target.repayment_date));
  const [lastChangedAmount, setLastChangedAmount] = useState('amount');
  const amountRef = useRef(null);
  const txAmountRef = useRef(null);
  let noSuchToolTip = t('forms.transaction.targetRow.notChosenCompany');

  if (formData.company_id) {
    noSuchToolTip = t('forms.transaction.targetRow.noAOCompany');
  }
  useMemo(() => {
    if (activationOrders.length && target.target_type !== 'BALANCE_PAYMENT' && target.target_type !== 'TRANSIT') {
      setActivationOrderValue(activationOrders.filter((ao) => ao.id === target.order_id)[0]);
    }
  }, [activationOrders, target]);

  useEffect(() => {
    if (isCheckErrors) {
      const errors = {};
      Object.keys(target).forEach((key) => {
        errors[key] = !target[key];
      });
      setError((prev) => ({ ...prev, ...errors, repayment_date: prev.repayment_date }));
    }
  }, [isCheckErrors, target]);

  useEffect(() => {
    if (target?.purchase_request_claim_id) {
      const getClaims = async () => {
        const result = await getPlatformByClaims({ claims: [target.purchase_request_claim_id] });
        setClaim((Object.values(result)[0] as Claim).name);
      };
      getClaims();
    }
  }, [target.purchase_request_claim_id]);

  const updateAmount = useCallback(
    (date) => {
      if (repaymentDate) {
        createOrderAmount({
          id: target.order_id || '',
          getterId: target.keyId,
          date: formatDateIsoShort(date),
          errorCallback,
        });
      }
    },
    [repaymentDate, errorCallback, createOrderAmount, target],
  );

  const setFocus = (item?: string) => {
    if (item === 'amount') {
      amountRef?.current?.focus();
    } else {
      txAmountRef?.current?.focus();
    }
  };

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const newFormValue = { ...target };

      if (error[name]) {
        setError((prev) => ({ ...prev, [name]: !value }));
      }

      if (!newFormValue.order_currency && formData.currency) {
        newFormValue.order_currency = formData.currency;
      }

      if (name === 'target_type') {
        if (value === 'BALANCE_PAYMENT' || value === 'TRANSIT') {
          setActivationOrderValue(null);
          delete newFormValue.order_id;
        } else if (activationOrderValue?.id && !newFormValue?.order_id) {
          newFormValue.order_id = activationOrderValue.id;
        }

        if (value === 'INTEREST_REPAY' || value === 'PENALTY_REPAY') {
          setLastChangedAmount('amount');
          updateAmount(repaymentDate);
          newFormValue.repayment_date = repaymentDate;
        } else if (newFormValue.repayment_date) {
          delete newFormValue.repayment_date;
        }
      }

      if (name === 'tx_currency_amount') {
        setFocus('amount');
        newFormValue.tx_currency_amount = Number(value);
        newFormValue.amount = round2(Number(value) / newFormValue.rate || 1);
      } else if (name === 'amount') {
        newFormValue.amount = Number(e.target.value);
      } else if (name === 'rate') {
        const localRate = Number(value);
        setFocus('amount');

        if (newFormValue.target_type === 'INTEREST_REPAY' || newFormValue.target_type === 'PENALTY_REPAY') {
          if (lastChangedAmount === 'amount') {
            newFormValue.tx_currency_amount = round2(newFormValue.amount * (localRate || 1));
          } else {
            newFormValue.amount = round2(newFormValue.tx_currency_amount / (localRate || 1));
          }
        } else {
          newFormValue.amount = round2(newFormValue.tx_currency_amount / (localRate || 1));
        }
        newFormValue.rate = localRate;
      } else {
        newFormValue[e.target.name] = e.target.value;
      }
      handleTargetsAddInFormData(newFormValue, index);
    },
    [error, handleTargetsAddInFormData, index, target, updateAmount, repaymentDate, lastChangedAmount],
  );

  const handleActivationOrderChange = useCallback(
    (_, values) => {
      const newFormValue = { ...target };

      if (values) {
        setError((prev) => ({ ...prev, order_id: false }));
        const { id, currency } = values;
        setNeedExchange(currency && formData.currency && currency !== formData.currency);
        setActivationOrderValue(values);
        getActivationOrder({ id, getterIndex: index, errorCallback });
        newFormValue.order_id = id;
        newFormValue.order_currency = currency;
      } else {
        setError((prev) => ({ ...prev, order_id: false }));
        newFormValue.order_id = '';
        newFormValue.order_currency = '';
        setNeedExchange(false);
      }
      handleTargetsAddInFormData(newFormValue, index);
    },
    [getActivationOrder, errorCallback, index, formData, handleTargetsAddInFormData, target],
  );

  const handleRepaymentDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLastChangedAmount('amount');
      setRepaymentDate(e.target.value);
      const newFormValue = { ...target };
      newFormValue.repayment_date = e.target.value;
      handleTargetsAddInFormData(newFormValue, index);
      updateAmount(e.target.value);
      const dError = dateRangeValidator({ date: e.target.value });
      const dataErrorText = getErrorTextByErrorType({
        type: dError.type,
        minText: t('dateErrors.min'),
        maxText: t('dateErrors.max'),
      });
      setError((prev) => ({ ...prev, repayment_date: { error: !dError.validate, text: dataErrorText } }));
    },
    [updateAmount, handleTargetsAddInFormData, index, target, t],
  );

  const setDateErrors = useCallback(
    (dateField: string) => {
      setError((prev) => ({ ...prev, [dateField]: { status: true, text: prev[dateField].text } }));
      const newFormValue = { ...target };
      newFormValue.repayment_date = '';
      handleTargetsAddInFormData(newFormValue, index);
    },
    [handleTargetsAddInFormData, index, target],
  );

  const onBlurHandler = (field: string, fieldValue: string | number) => {
    if (field === 'repayment_date') {
      if (fieldValue) {
        const dError = dateRangeValidator({ date: fieldValue });

        if (!dError.validate) {
          const dataErrorText = getErrorTextByErrorType({
            type: dError.type,
            minText: t('dateErrors.min'),
            maxText: t('dateErrors.max'),
          });
          setError((prev) => ({ ...prev, [field]: { error: true, text: dataErrorText } }));
        } else {
          setError((prev) => ({ ...prev, [field]: { error: false, text: requiredText } }));
        }
      }
    } else {
      if (field === 'amount' || field === 'tx_currency_amount') {
        setLastChangedAmount(field);
      }
      setError({ ...error, [field]: !fieldValue });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftBlock}>
        <div className={classes.targetNumber}>{index + 1}</div>
        <div className={classes.inputsWrap}>
          {activationOrders.length ? (
            <div data-tip={disabled ? toolTip : ''}>
              {target.target_type !== 'BALANCE_PAYMENT' && target.target_type !== 'TRANSIT' && (
                <FormControl variant="filled" error={error.order_id}>
                  <Autocomplete
                    key={activationOrderValue}
                    id="combo-box-demo"
                    className={classes.autocompleteWrapper}
                    options={activationOrders}
                    data-tip={disabled ? toolTip : ''}
                    getOptionLabel={(option) =>
                      getTargetRowAutocompleteOption(option) ? getTargetRowAutocompleteOption(option) : ''
                    }
                    renderOption={(option) => (
                      <div className={classes.autocompleteOptionWrapper}>
                        <div>{formatAo(option)}</div>
                        {option.contract_number && <span style={{ color: 'gray' }}>{option.contract_number}</span>}
                      </div>
                    )}
                    disabled={disabled}
                    defaultValue={activationOrderValue}
                    onChange={handleActivationOrderChange}
                    onBlur={() => onBlurHandler('order_id', target.order_id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={error.order_id || target.order_currency !== formData.currency}
                        variant="filled"
                        required
                        className={classes.autocompleteInput}
                        label={t('forms.transaction.ao')}
                      />
                    )}
                  />
                  <FormHelperText
                    id="component-error-text"
                    style={{ visibility: !error.order_id ? 'hidden' : 'visible' }}
                    className={classes.requiredText}
                  >
                    {requiredText}
                  </FormHelperText>
                </FormControl>
              )}
            </div>
          ) : (
            <div className={classes.noSuch} data-tip={noSuchToolTip}>
              {t('forms.transaction.targetRow.noSuchAO')}
            </div>
          )}
          <FormControl className={classes.typeWrap} variant="filled" error={error.target_type}>
            <InputLabel id="demo-simple-select-filled-label">
              {`${t('forms.transaction.targetRow.targetType')} *`}
            </InputLabel>
            {formData.type === 'IN' ? (
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={target.target_type}
                disabled={disabled}
                defaultValue={target.target_type}
                required
                data-tip={disabled ? toolTip : ''}
                name="target_type"
                onBlur={() => onBlurHandler('targetType', target.target_type)}
                error={error.target_type}
                onChange={setFormValue}
                className={classes.selectInput}
              >
                <MenuItem value="INTEREST_REPAY">{t('forms.transaction.targetRow.commission')}</MenuItem>
                {!formData.adjusting_transaction && (
                  <MenuItem value="BODY_REPAY">{t('forms.transaction.targetRow.principalRepaymentAmount')}</MenuItem>
                )}
                <MenuItem value="PENALTY_REPAY">{t('forms.transaction.targetRow.defaultInterest')}</MenuItem>
                {!formData.adjusting_transaction && (
                  <MenuItem value="REVERSE_INCOME">{t('forms.transaction.targetRow.reverse')}</MenuItem>
                )}
                {!formData.adjusting_transaction && (
                  <MenuItem value="BANK_FEE_IN">{t('forms.transaction.targetRow.bankFee')}</MenuItem>
                )}
                {!formData.adjusting_transaction && (
                  <MenuItem value="BALANCE_PAYABLE">{t('forms.transaction.targetRow.balance_payable')}</MenuItem>
                )}
              </Select>
            ) : formData.type === 'OUT' ? (
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={target.target_type}
                required
                data-tip={disabled ? toolTip : ''}
                disabled={disabled}
                name="target_type"
                onBlur={() => onBlurHandler('targetType', target.target_type)}
                error={error.target_type}
                onChange={setFormValue}
                className={classes.selectInput}
              >
                <MenuItem value="LOAN_ISSUE">{t('forms.transaction.targetRow.purchasePrice')}</MenuItem>
                <MenuItem value="REVERSE_RETURN">{t('forms.transaction.targetRow.reverse')}</MenuItem>
                <MenuItem value="BANK_FEE_OUT">{t('forms.transaction.targetRow.bankFee')}</MenuItem>
                <MenuItem value="BALANCE_PAYMENT">{t('tables.balancePayment')}</MenuItem>
                <MenuItem value="TRANSIT">{t('tables.transit')}</MenuItem>
                <MenuItem value="PURCHASE_REVENUE">{t('tables.purchase_revenue')}</MenuItem>
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                required
                data-tip={disabled ? toolTip : ''}
                disabled={disabled}
                onBlur={() => onBlurHandler('targetType', target.target_type)}
                error={error.target_type}
                className={classes.selectInput}
              />
            )}
            <FormHelperText
              id="component-error-text"
              style={{ visibility: !error.target_type ? 'hidden' : 'visible' }}
              className={classes.requiredText}
            >
              {requiredText}
            </FormHelperText>
          </FormControl>
          {needExchange && (
            <>
              <FormControl error={error.tx_currency_amount} style={{ minWidth: 120 }}>
                <AmountInput
                  isNeedHandlePaste
                  amount={target.tx_currency_amount || null}
                  error={error.tx_currency_amount}
                  required
                  toolTip={disabled ? toolTip : ''}
                  allowNegative={formData.adjusting_transaction}
                  label={`${t('forms.transaction.targetRow.amount')}`}
                  handleAmountChange={setFormValue}
                  onBlurHandler={() => onBlurHandler('tx_currency_amount', target.tx_currency_amount)}
                  name="tx_currency_amount"
                  inputWidth="120"
                  isNeedBorder
                />
              </FormControl>
              <FormControl error={error.rate} style={{ minWidth: 120 }}>
                <TextField
                  id="filled-basic"
                  autoComplete="off"
                  label={`${target.order_currency}/${formData.currency}`}
                  variant="filled"
                  // disabled={disableRateField}
                  data-tip={disabled ? toolTip : ''}
                  onBlur={() => onBlurHandler('rate', target.rate)}
                  defaultValue={parseFloat(String(target.rate)) || ''}
                  onChange={setFormValue}
                  name="rate"
                  className={classes.currencyInput}
                  disabled
                  InputProps={{
                    inputComponent: AmountFormat,
                    inputProps: { allowNegative: false, decimalScale: 8 },
                    autoComplete: 'off',
                    className: classes.input,
                  }}
                  error={error.rate}
                />
                <FormHelperText id="component-error-text" style={{ visibility: !error.rate ? 'hidden' : 'visible' }}>
                  {requiredText}
                </FormHelperText>
              </FormControl>
            </>
          )}
          {needRepaymentDateField(target.target_type) && (
            <div>
              <DateInput
                date={repaymentDate}
                required
                disabled={disabled}
                toolTip={disabled ? toolTip : ''}
                labelKey="forms.transaction.targetRow.paymentPeriodDateLabel"
                onChangeDateHandler={handleRepaymentDateChange}
                name="repayment_date"
                commonStyle={{ width: 220, marginRight: 8 }}
                setError={() => setDateErrors('repayment_date')}
              />
            </div>
          )}
          <div>
            <FormControl error={error.amount && !needExchange} style={{ minWidth: 120 }}>
              <AmountInput
                isNeedHandlePaste
                amount={target.amount || null}
                error={error.amount && !needExchange}
                required
                toolTip={disabled ? toolTip : ''}
                allowNegative={formData.adjusting_transaction}
                label={`${t('forms.transaction.targetRow.amount')} ${
                  target?.order_currency || formData?.currency || ''
                }`}
                handleAmountChange={setFormValue}
                onBlurHandler={() => onBlurHandler('amount', target.amount)}
                name="amount"
                inputWidth="120"
                isNeedBorder
                disabled={needExchange || disabled}
              />
            </FormControl>
          </div>
          {formData.type === 'IN' && claim && (
            <InputKit
              label="Purchase request claim"
              value={claim}
              wrapProps={{ width: 200, height: 56, marginBottom: 20 }}
              tooltip={toolTip}
            />
          )}
          <div>
            <Button onClick={() => removeHandler(target.keyId)} disabled={disabled} data-tip={disabled ? toolTip : ''}>
              <RemoveIcon className={classes.removeIcon} />
              {t('forms.transaction.targetRow.remove')}
            </Button>
          </div>
        </div>
      </div>
      <LoanBalanceAlert target={target} />
      <ReactTooltip />
    </div>
  );
};

export default TargetRow;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    listStyleType: 'none',
    marginBottom: '8px',
  },
  input: {
    backgroundColor: '#eef2f7 !important',
  },
  leftBlock: {
    display: 'flex',
  },
  inputsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectInput: {
    minWidth: '160px',
    marginRight: '8px',
    backgroundColor: '#eef2f7 !important',
  },
  typeWrap: {
    minWidth: '160px',
  },
  amountInput: {
    maxWidth: '120px',
    marginRight: '8px',
  },
  currencyInput: {
    maxWidth: '120px',
    marginRight: '8px',
  },
  targetNumber: {
    width: '3em',
    paddingTop: '1em',
  },
  paymentPeriodDate: {
    minWidth: '150px',
    marginRight: '8px',
    width: '210px',
  },
  removeIcon: {
    marginRight: '8px',
  },
  noSuch: {
    color: 'red',
    marginTop: '18px',
    marginRight: '8px',
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.6,
  },
  requiredText: {
    marginLeft: 0,
  },
  autocompleteWrapper: {
    marginRight: '16px!important',
  },
  autocompleteOptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  autocompleteInput: {
    minWidth: '300px!important',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: '#eef2f7 !important',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    '& *:before': {
      borderColor: 'white !important',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#eef2f7',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#eef2f7',
    },
  },
}));

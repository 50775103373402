// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { activationOrdersV2DataType } from 'hooks/api/useActivationOrder';

export const filterCompanies = (companiesData) =>
  companiesData
    .filter((item) => item.company_name)
    .map((item) => ({
      name: item.company_name,
      id: item.id,
    }));

export const filterAo = (data: activationOrdersV2DataType[] = []) =>
  data
    .filter((item) => item.name)
    .map((item) => ({
      name: item.name,
      id: item.id,
    }));

export const filterData = (companiesData, key) =>
  companiesData
    .filter((item) => item[key])
    .sort((a, b) => {
      const x = a[key].toLowerCase();
      const y = b[key].toLowerCase();

      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    })
    .map((item) => ({
      name: item[key].trim(),
      id: item.id,
      active: item.active,
    }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import urls from 'constants/urls';
import { statusParamsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import { commonFormattingParams, initialObjTransactions } from './data';
import useRequiredKeyOfCardsTransactions from './useRequiredKeyOfCardsTransactions';
import CreateAndEditTransactions, { isEditCreateCard } from './CreateAndEditTransactions';
import AllocateConversionTr, { isEditAllocateConversionCard } from './AllocateConversionTr';

interface TransactionsFormPropsType {
  requestParams?: object;
}

const TransactionsForm: FC<TransactionsFormPropsType> = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { type, status, transaction_id } = useParams();
  const {
    createListTransactions,
    setTransactionFormData,
    transactionsFormData,
    setTransactions,
    getTransactionsById,
    transactionsRelatedById,
    transactionsDataById,
    setSyncTr,
  }: useTransactionType = useTransaction();
  const { listRequired, errorsList, getValidErrors } = useRequiredKeyOfCardsTransactions();
  const history = useHistory();
  const { changeErrors } = useErrors();

  const startSubmittingForm = useCallback(() => setIsSubmittingForm(true), [setIsSubmittingForm]);
  const finishSubmittingForm = useCallback(() => setIsSubmittingForm(false), [setIsSubmittingForm]);

  useEffect(
    () => () => {
      setTransactionFormData(initialObjTransactions);
      changeErrors({});
    },
    [],
  );

  const editTransaction = useCallback(
    (id, isPush = true, item?, isEdit?: boolean) => {
      startSubmittingForm();
      setTransactions({
        transaction_id: id,
        body: commonFormattingParams(transactionsFormData, item, isEdit),
        successCallback: (res) => {
          finishSubmittingForm();
          (res.type === KeyTransactions.ADJUSTMENT && !res.factoring_order_id) ||
            setSyncTr({ params: { id_transactions: res.id } });
          isPush && history.push(`${urls.getTransactions()}/${res.id}`);
        },
        errorCallback: finishSubmittingForm,
      });
    },
    [transactionsFormData, transaction_id, urls, finishSubmittingForm, startSubmittingForm],
  );

  const createTransaction = useCallback(
    (isPush = true, item?) => {
      startSubmittingForm();
      createListTransactions({
        body: commonFormattingParams(transactionsFormData, item),
        successCallback: (res) => {
          finishSubmittingForm();
          (res.type === KeyTransactions.ADJUSTMENT && !res.factoring_order_id) ||
            setSyncTr({ params: { id_transactions: res.id } });
          isPush && history.push(`${urls.getTransactions()}/${res.id}`);
        },
        errorCallback: finishSubmittingForm,
      });
    },
    [transactionsFormData, urls, finishSubmittingForm, startSubmittingForm],
  );

  const saveTransFc = useCallback(
    (e) => {
      e.preventDefault();

      if (!getValidErrors()) return;

      if (isEditCreateCard[transactionsFormData[KeyTransactions.TYPE]]) {
        status === statusParamsEnum.EDIT && editTransaction(transaction_id);
        status === statusParamsEnum.CREATE && createTransaction();
      }

      if (isEditAllocateConversionCard[transactionsFormData[KeyTransactions.TYPE]]) {
        if (transactionsFormData[KeyTransactions.TYPE] === KeyTransactions.CONVERSION_INCOMING) {
          createTransaction();
        }
        history.push(`${urls.getTransactions()}/${transaction_id}`);
      }
    },
    [listRequired, errorsList, changeErrors, transactionsFormData, transactionsDataById, transactionsRelatedById],
  );

  return (
    <form onSubmit={saveTransFc} style={{ width: 607 }}>
      {isEditCreateCard[transactionsFormData[KeyTransactions.TYPE] || type] && (
        <CreateAndEditTransactions
          transaction_id={transaction_id}
          type={type}
          transactionsFormData={transactionsFormData}
          status={status}
          getTransactionsById={getTransactionsById}
          setTransactionFormData={setTransactionFormData}
          isSubmittingForm={isSubmittingForm}
        />
      )}
      {isEditAllocateConversionCard[transactionsFormData[KeyTransactions.TYPE] || type] && (
        <AllocateConversionTr
          type={type}
          setTransactionFormData={setTransactionFormData}
          transactionsFormData={transactionsFormData}
          transaction_id={transaction_id}
        />
      )}
    </form>
  );
};

export default TransactionsForm;

import React, { useState, useEffect } from 'react';

import TransactionsTable from 'components/tables/TransactionsTable';
import TransactionsTableHeader from 'components/tables/TransactionsTable/Header';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const TransactionsListScreen: any = ({ ...props }) => {
  const { requestParams } = props;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    updateTitle('Transactions');
  });

  return (
    <MainContent type="flexibleLimit" flexColumn>
      <TransactionsTableHeader isChecked={isChecked} setIsChecked={setIsChecked} requestParams={requestParams} />
      <TransactionsTable isChecked={isChecked} requestParams={requestParams} />
    </MainContent>
  );
};

export default TransactionsListScreen;

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, EditIcon, PageHeader, Select, Status, Tooltip, Skeleton, Table } from '@gamesb42/ui-kit';
import classNames from 'classnames';

import MainContent from 'components/MainContent';
import RevenueSourcesStatus from 'components/RevenueSourcesStatus';
import urls from 'constants/urls';
import { getRevenueSources } from 'api/revenueSources';
import {
  RevenueSource,
  RevenueSourceKey,
  RevenueSourceStatus,
  RevenueSourceConnectionEnum,
} from 'types/RevenueSources';
import { REVENUE_SOURCES_STATUS_NAME, REVENUE_SOURCES_STATUS_COLOR } from 'constants/revenueSources';
import { getNextSort } from 'helpers';
import { FullNameSort } from 'types/Sort';

import styles from './styles.module.scss';

const RevenueSources = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [revenueSources, setRevenueSources] = useState<RevenueSource[]>([]);
  const [filterId, setFilterId] = useState();
  const [filterConnection, setFilterConnection] = useState();
  const [filterStatus, setStatus] = useState();
  const [sortByName, setSortByName] = useState<null | FullNameSort>(null);

  const revenueSourcesNamesOptions = useMemo(
    () => revenueSources.map((e) => ({ label: e[RevenueSourceKey.NAME], value: e[RevenueSourceKey.ID] })),
    [revenueSources],
  );

  const prepareRevenueSources = useMemo(
    () =>
      revenueSources.filter((e) => {
        const equalFilterId = filterId ? e[RevenueSourceKey.ID] === filterId : true;
        let equalConnection = true;

        if (filterConnection === RevenueSourceConnectionEnum.NO) {
          equalConnection = !e[RevenueSourceKey.CODE];
        }

        if (filterConnection === RevenueSourceConnectionEnum.YES) {
          equalConnection = !!e[RevenueSourceKey.CODE];
        }

        const statusFilter = filterStatus ? e[RevenueSourceKey.STATUS] === filterStatus : true;

        return equalFilterId && equalConnection && statusFilter;
      }),
    [revenueSources, filterId, filterConnection, filterStatus],
  );

  const columns = useMemo(
    () => [
      {
        title: t('revenueSources.revenueSourceName'),
        dataIndex: RevenueSourceKey.NAME,
        sorter: (a: RevenueSource, b: RevenueSource) =>
          a[RevenueSourceKey.NAME].localeCompare(b[RevenueSourceKey.NAME]),
        sortOrder: sortByName,
        onHeaderCell: () => ({
          onClick: () => setSortByName(getNextSort({ value: sortByName, type: 'full' }) as FullNameSort | null),
        }),
        skeletonRender: (
          <span className={classNames(styles.block, styles.blockSkeleton)}>
            <Skeleton type="icon24" />
            <Skeleton type="text14" width="78px" />
          </span>
        ),
        render: (value: string, row: RevenueSource) => (
          <span className={styles.block}>
            <span className={styles.icon} style={{ backgroundImage: `url(${row[RevenueSourceKey.LOGO]})` }} />
            {value}
          </span>
        ),
      },
      {
        title: t('revenueSources.connection'),
        dataIndex: RevenueSourceKey.CODE,
        align: 'center' as const,
        width: '232px',
        skeletonProps: {
          width: '78px',
          type: 'text14' as const,
        },
        render: (value: null | string) =>
          value ? (
            <span className={styles.yes}>{RevenueSourceConnectionEnum.YES}</span>
          ) : (
            <span className={styles.no}>{RevenueSourceConnectionEnum.NO}</span>
          ),
      },
      {
        title: t('global.status'),
        dataIndex: RevenueSourceKey.STATUS,
        align: 'center' as const,
        width: '232px',
        skeletonProps: {
          type: 'status' as const,
        },
        render: (value: RevenueSourceStatus) => (
          <Status color={REVENUE_SOURCES_STATUS_COLOR[value]} className={styles.statusTable}>
            {REVENUE_SOURCES_STATUS_NAME[value]}
          </Status>
        ),
      },
      {
        dataIndex: RevenueSourceKey.ID,
        align: 'center' as const,
        width: '56px',
        noSkeleton: true,
        render: (id: string) => (
          <Tooltip title={t('global.edit')}>
            <Button
              className={styles.edit}
              icon={<EditIcon />}
              type="iconBtn"
              onClick={() => history.push(`${urls.getRevenueSourcesJustEdit()}/${id}`)}
            />
          </Tooltip>
        ),
      },
    ],
    [history, sortByName, t],
  );

  useEffect(() => {
    getRevenueSources()
      .then(setRevenueSources)
      // eslint-disable-next-line no-console
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <MainContent type="flexibleLimit" flexColumn title={t('title.revenueSources')}>
      <PageHeader
        title={t('title.revenueSources')}
        rightContent={
          <Button type="primary" onClick={() => history.push(urls.getRevenueSourcesCreate())}>
            {t('global.create')} {t('revenueSources.revenueSource')}
          </Button>
        }
      />
      <div className={styles.filters}>
        <Select
          label={t('revenueSources.revenueSource')}
          width="320px"
          options={revenueSourcesNamesOptions}
          value={filterId}
          onChange={setFilterId}
          allowClear
        />
        <Select
          label={t('revenueSources.connection')}
          width="200px"
          value={filterConnection}
          onChange={setFilterConnection}
          allowClear
        >
          <Select.Option value={RevenueSourceConnectionEnum.YES} className={styles.yes}>
            {RevenueSourceConnectionEnum.YES}
          </Select.Option>
          <Select.Option value={RevenueSourceConnectionEnum.NO} className={styles.no}>
            {RevenueSourceConnectionEnum.NO}
          </Select.Option>
        </Select>
        <RevenueSourcesStatus width="200px" value={filterStatus} onChange={setStatus} allowClear />
      </div>
      <Table
        rowKey={RevenueSourceKey.ID}
        rowClassName={styles.row}
        loadingRowCount={30}
        dataSource={prepareRevenueSources}
        columns={columns}
        loading={loading}
        className={styles.table}
      />
    </MainContent>
  );
};

export default RevenueSources;

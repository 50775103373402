import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/ModalConfirm';

type PropsT = {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  handleConfirmClick: () => void;
};

const ConfirmationDeleteModal: FC<PropsT> = ({ isOpen, onClose, title, handleConfirmClick }) => {
  const { t } = useTranslation();

  return (
    <ModalConfirm
      closeFc={onClose}
      open={isOpen}
      wrap={{
        width: 444,
        height: 199,
      }}
      leftCallback={handleConfirmClick}
      rightCallback={onClose}
      leftButtonText={t('global.deleteD')}
      rightButtonText={t('global.cancelC')}
      title={[t('global.areYouSureDoYouWantDelete'), title]}
    />
  );
};

export default ConfirmationDeleteModal;

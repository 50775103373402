import React, { FC, useRef, useEffect, useState, CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface TooltipType {
  isShow?: boolean;
  title: string | undefined;
  tooltipWithoutArrow?: boolean;
  styleTooltip?: CSSProperties;
}

const Tooltip: FC<TooltipType> = ({ children, isShow, styleTooltip, title, tooltipWithoutArrow }) => {
  const classes = useStyles();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [sizeToolltip, setSizeToolltip] = useState({ width: 0, height: 0 });
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setIsHover(isShow);
  }, [isShow]);

  const ref = useRef(null);
  const refTooltip = useRef(null);
  useEffect(() => {
    if (ref.current && title) {
      const { offsetHeight, offsetWidth } = ref.current;
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [ref]);

  useEffect(() => {
    if (refTooltip.current && title) {
      const { offsetHeight, offsetWidth } = refTooltip.current;
      setSizeToolltip({ width: offsetWidth, height: offsetHeight });
    }
  }, [refTooltip]);

  const styleHover = isHover ? { opacity: 1, zIndex: 999 } : { opacity: 0, zIndex: -1 };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        ref={refTooltip}
        className={tooltipWithoutArrow ? classes.tooltipWithoutArrow : classes.tooltipTitle}
        style={{
          bottom: size.height + 4,
          right: -(sizeToolltip.width / 2 - size.width / 2),
          ...styleHover,
          ...styleTooltip,
        }}
      >
        {title}
      </div>
      <div
        ref={ref}
        className={classes.tooltip}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={isHover ? { cursor: 'pointer' } : {}}
      >
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  tooltip: {
    position: 'relative',
    display: 'inline-block',
  },
  tooltipTitle: {
    background: 'rgba(41, 41, 44, 0.8)',
    transition: '0.5s opacity',
    borderRadius: 4,
    padding: '3px 12px',
    position: 'absolute',
    fontSize: 14,
    color: '#FFFFFF',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '4px solid rgba(41, 41, 44, 0.8)',
      borderRight: '4px solid transparent',
      borderLeft: '4px solid transparent',
      position: 'absolute',
      bottom: -4,
      left: 'calc(50% - 4px)',
    },
  },
  tooltipWithoutArrow: {
    background: 'rgba(41, 41, 44, 0.8)',
    transition: '0.5s opacity',
    borderRadius: 8,
    padding: '3px 12px',
    position: 'absolute',
    fontSize: 14,
    color: '#FFFFFF',
  },
}));

export default Tooltip;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type detailedTransactionReportsType = {
  companyId?: string;
  currency?: string;
  errorCallback: Function;
  resultKey?: string;
};

export default ({
  companyId = '',
  currency = '',
  errorCallback,
  resultKey = 'detailedTransactionReportsData',
}: detailedTransactionReportsType) =>
  requestAsync({
    url: endpoints.getDetailedTransactionReportsUrl(companyId, currency),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getDetailedTransactionReportsUrl(companyId, currency),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

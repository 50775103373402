import React, { FC } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';

import CustomInput from 'components/inputs/CustomInput';
import PasswordInput from 'components/inputs/PasswordInput';

type GeneralTabGroupDataType = {
  host: string;
  username: string;
  password: string;
};

type GeneralTabPanelPropsType = {
  groupData: GeneralTabGroupDataType;
  setValue: any;
};

const JustControlItPanel: FC<GeneralTabPanelPropsType> = ({ groupData, setValue }: GeneralTabPanelPropsType) => {
  const styles = useStyles();

  return (
    <TabPanel value="justcontrolit" style={{ padding: 0 }}>
      <div className={styles.tabBlock}>
        <CustomInput
          labelKey="systemSetting.hostname"
          name="host"
          onChange={setValue}
          disabled={false}
          defaultValue={groupData.host}
          stylesProp={{
            marginBottom: 20,
          }}
        />
        <CustomInput
          labelKey="systemSetting.username"
          name="username"
          onChange={setValue}
          disabled={false}
          defaultValue={groupData.username}
          stylesProp={{
            marginBottom: 20,
          }}
        />
        <PasswordInput password={groupData.password} handlePasswordChange={setValue} deleted={false} required={false} />
      </div>
    </TabPanel>
  );
};

export default JustControlItPanel;

const useStyles = makeStyles(() => ({
  tabBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '500px',
    marginBottom: '23px',
    height: '48px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

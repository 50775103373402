import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type editScheduleFutureReceivablesDataType = {
  body: {
    order_id: string;
    schedules: { payment_date?: string; amount?: string; id?: string; deleted?: boolean }[];
  };
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  successCallback,
  errorCallback,
  resultKey = 'EditScheduleFutureReceivables',
}: editScheduleFutureReceivablesDataType) =>
  requestAsync({
    url: endpoints.getCreateScheduleFutureReceivablesUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getCreateScheduleFutureReceivablesUrl()}`,
    body,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

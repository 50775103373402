import i18next from 'i18next';

import { ItemT } from 'components/common/Tabs';
import urls from 'constants/urls';

import { DocumentTypeEnum } from '../ViewsForm/data';

export enum TabsTransactionsType {
  Transaction,
  ENTRIES,
}

export const getTitleOfDocumentType = {
  [DocumentTypeEnum.DEFAULT]: 'forms.transaction.activationOrder',
  [DocumentTypeEnum.ORDER]: 'forms.transaction.activationOrder',
  [DocumentTypeEnum.REQUEST]: 'forms.transaction.purchaseRequest',
  [DocumentTypeEnum.CLAIM]: 'forms.transaction.claim',
};

export const getPathOfDocumentType = {
  [DocumentTypeEnum.DEFAULT]: urls.getActivationOrder(),
  [DocumentTypeEnum.ORDER]: urls.getActivationOrder(),
  [DocumentTypeEnum.REQUEST]: urls.getPurchaseRequestList(),
  [DocumentTypeEnum.CLAIM]: '',
};

export const TABS = (): ItemT[] => [
  { id: TabsTransactionsType.Transaction, label: i18next.t('forms.transaction.transaction') },
  { id: TabsTransactionsType.ENTRIES, label: i18next.t('forms.transaction.entries') },
];

import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import { decimal } from 'helpers/location';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AmountFormat = ({ inputRef, onChange, allowNegative, decimalScale, ...props }) => {
  const [lastZero, setLastZero] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleChange = (values) => {
    let { value } = values;
    value = value.replaceAll(',', '.');

    if (lastZero) {
      value = value.slice(0, -1);
      setLastZero(false);
    }
    onChange({
      target: {
        name: props.name,
        value,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onSelectHandle = (e) => {
    const { selectionStart } = e.target;
    const { value } = e.target;

    if (allowNegative) {
      if (value === '-0' && selectionStart === 1) {
        setLastZero(true);
      } else {
        setLastZero(false);
      }
    } else if (value === '0' && selectionStart === 0) {
      setLastZero(true);
    } else {
      setLastZero(false);
    }
  };

  return (
    <NumberFormat
      {...props}
      getInputRef={inputRef}
      onValueChange={handleChange}
      unselectable="off"
      onSelect={onSelectHandle}
      thousandSeparator=" "
      decimalSeparator={decimal}
      decimalScale={decimalScale !== undefined ? decimalScale : 2}
      allowNegative={allowNegative}
    />
  );
};

export default AmountFormat;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react';

import PopUpLayout from 'helpers/PopUpLayout';

import ConfirmPopUp from './ConfirmPopUp';

interface ConfirmProps {
  wrap?: React.CSSProperties;
  leftCallback?: () => void;
  rightCallback?: () => void;
  open?: boolean;
  leftButtonText: string;
  rightButtonText: string;
  leftButtonStyle?: React.CSSProperties;
  rightButtonStyle?: React.CSSProperties;
  title: string | string[];
  closeFc?: () => void;
}
const ModalConfirm: FC<ConfirmProps> = ({ open, closeFc, ...props }) => (
  <PopUpLayout open={open} onClose={closeFc}>
    <ConfirmPopUp {...props} open />
  </PopUpLayout>
);

export default ModalConfirm;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TemplateGrid from 'components/forms/ActivationOrderForm/CardsAo/TemplateGrid';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import { AmountKit } from 'components/uiKit/AmountKit';
import { formatAmount, getNameCurrencyOfCode, getCurrencyWithCode, getYYYYMMDD } from 'helpers/formatters';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';

import { setInitialFilterType } from './ConstructorTransForm';
import useRequiredKeyOfCardsTransactions from './useRequiredKeyOfCardsTransactions';

const plusSvg = require('assets/img/arrowConversion.svg').default;

const TEMPLATE_GRID = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '193px auto 193px',
  padding: '0px 14px',
};
const CONVERT_USD_TO_EUR = (rate: number, count: number) => (count / rate).toFixed(2);
const CONVERT_EUR_TO_USD = (rate: number, count: number) => (count * rate).toFixed(2);

export const getConvertFcOfCodeCurrencySell = {
  '840': CONVERT_USD_TO_EUR,
  '978': CONVERT_EUR_TO_USD,
};

const getConvertFcOfCodeCurrencyBuy = {
  '840': CONVERT_EUR_TO_USD,
  '978': CONVERT_USD_TO_EUR,
};

export const getDifferentCurrencyOfCode = {
  '840': '978',
  '978': '840',
};

export const GET_COMMISSION_COEFFICIENT = (commission) => Number(commission) / 100 + 1;
export const GET_CONVERSION_RATE = (rate_ecb, commission, currency) => {
  const COMMISSION = GET_COMMISSION_COEFFICIENT(commission);
  const SET_RATE = {
    '840': rate_ecb * COMMISSION,
    '978': rate_ecb / COMMISSION,
    default: 0,
  };

  return Number(SET_RATE[currency || 'default']).toFixed(4);
};
interface RelatedTransactionsType {
  setInitialFilter: setInitialFilterType;
}
const SellBuy: FC<RelatedTransactionsType> = ({ setInitialFilter }) => {
  const { transactionsFormData, getCurrencyRate, setTransactionFormData }: useTransactionType = useTransaction();
  const { getParamsItem } = useRequiredKeyOfCardsTransactions();
  const { accountsByIdCompany, getAccountsByIdCompany }: useCompanyType = useCompany();
  const { errors, setErrors, addErrors } = useErrors();
  const { t } = useTranslation();
  useEffect(() => {
    if (transactionsFormData.value_datetime) {
      const date = new Date(transactionsFormData.value_datetime);

      if (date.toDateString() === 'Invalid Date') return;
      date.setDate(date.getDate() - 1);
      getCurrencyRate({
        params: { on_date: getYYYYMMDD(date) },
        successCallback: (res) => {
          setTransactionFormData({ [KeyTransactions.RATE_ECB]: (res?.rate || 0).toFixed(4) });
        },
      });
    }
  }, [transactionsFormData.value_datetime]);

  useEffect(() => {
    if (transactionsFormData?.recipient?.company_id) {
      const date = new Date(transactionsFormData.value_datetime);
      // NOTE: чтобы расчеты делалились по 23:59:59 от date, нужно передать апишке следующую за этим днем дату
      date.setDate(date.getDate() + 1);
      if (isNaN(date.getTime()) === false) {
        const balanceDate = getYYYYMMDD(date);
        getAccountsByIdCompany({
          company_id: transactionsFormData.recipient.company_id,
          balanceDate,
        });
      }
    }
  }, [transactionsFormData.recipient, transactionsFormData.value_datetime]);

  const CONVERSION_RATE = GET_CONVERSION_RATE(
    transactionsFormData.rate_ecb,
    transactionsFormData.commission,
    transactionsFormData.currency,
  );

  const balanceData = accountsByIdCompany.find(
    (item) =>
      Number(item.currency) === Number(transactionsFormData.currency) &&
      transactionsFormData?.recipient?.company_id &&
      item.type === 'counterparty-client',
  );

  const balance = balanceData
    ? getCurrencyWithCode(Number(balanceData.currency)) + formatAmount(balanceData.balance)
    : '0,00';

  useEffect(() => {
    if (Number(transactionsFormData.amount_sell) > Number(balanceData?.balance || 0)) {
      addErrors({ [KeyTransactions.AMOUNT_SELL]: 'Amount is exceeding Available Balance' });

      return;
    }
    addErrors({ [KeyTransactions.AMOUNT_SELL]: '' });
  }, [balanceData?.balance, transactionsFormData.amount_sell]);

  return (
    <div>
      <TemplateGrid style={{ height: 60, marginBottom: 0 }}>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={plusSvg} alt="arrow" />
        </div>
        <AmountKit
          isNeedHandlePaste
          label={t('forms.transaction.sell')}
          onChange={(e) => {
            if (!transactionsFormData.currency || !transactionsFormData.value_datetime) {
              setErrors(
                transactionsFormData.currency,
                KeyTransactions.CURRENCY,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.CURRENCY),
              );
              setErrors(
                transactionsFormData.value_datetime,
                KeyTransactions.VALUE_DATE_TIME,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.VALUE_DATE_TIME),
              );
              setErrors(
                transactionsFormData.commission,
                KeyTransactions.COMMISSION,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.COMMISSION),
              );

              return;
            }
            setInitialFilter(
              KeyTransactions.AMOUNT_SELL,
              e.target.value,
              {
                keyError: KeyTransactions.AMOUNT_SELL,
                valueError: e.target.value,
                params: getParamsItem(KeyTransactions.ROOT, KeyTransactions.AMOUNT_SELL),
              },
              {
                [KeyTransactions.AMOUNT_BUY]: getConvertFcOfCodeCurrencySell[transactionsFormData.currency](
                  Number(CONVERSION_RATE),
                  Number(e.target.value),
                ),
              },
            );
          }}
          amount={transactionsFormData.amount_sell}
          required={getParamsItem(KeyTransactions.ROOT, KeyTransactions.AMOUNT_SELL)?.required}
          isError={!!errors[KeyTransactions.AMOUNT_SELL]}
          helperText={errors[KeyTransactions.AMOUNT_SELL]}
          decimalScale={2}
          currency={getNameCurrencyOfCode(Number(transactionsFormData[KeyTransactions.CURRENCY]))}
        />
        <AmountKit
          isNeedHandlePaste
          label={t('forms.transaction.buy')}
          onChange={(e) => {
            if (!transactionsFormData.currency || !transactionsFormData.value_datetime) {
              setErrors(
                transactionsFormData.currency,
                KeyTransactions.CURRENCY,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.CURRENCY),
              );
              setErrors(
                transactionsFormData.value_datetime,
                KeyTransactions.VALUE_DATE_TIME,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.VALUE_DATE_TIME),
              );
              setErrors(
                transactionsFormData.commission,
                KeyTransactions.COMMISSION,
                getParamsItem(KeyTransactions.ROOT, KeyTransactions.COMMISSION),
              );

              return;
            }
            setInitialFilter(
              KeyTransactions.AMOUNT_BUY,
              e.target.value,
              {
                keyError: KeyTransactions.AMOUNT_BUY,
                valueError: e.target.value,
                params: getParamsItem(KeyTransactions.ROOT, KeyTransactions.AMOUNT_SELL),
              },
              {
                [KeyTransactions.AMOUNT_SELL]: getConvertFcOfCodeCurrencyBuy[transactionsFormData.currency](
                  Number(CONVERSION_RATE),
                  Number(e.target.value),
                ),
              },
            );
          }}
          amount={transactionsFormData.amount_buy}
          required={getParamsItem(KeyTransactions.ROOT, KeyTransactions.AMOUNT_BUY)?.required}
          isError={!!errors[KeyTransactions.AMOUNT_BUY]}
          helperText={errors[KeyTransactions.AMOUNT_BUY]}
          decimalScale={2}
          currency={getNameCurrencyOfCode(getDifferentCurrencyOfCode[transactionsFormData[KeyTransactions.CURRENCY]])}
        />
      </TemplateGrid>
      {!!transactionsFormData.rate_ecb && (
        <div
          style={{
            backgroundColor: '#ECF0F5',
            borderRadius: 8,
            marginTop: 24,
          }}
        >
          <div
            style={{
              ...TEMPLATE_GRID,
              boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.05)',
              height: 40,
              fontWeight: 500,
              color: '#838998',
              lineHeight: '17px',
              fontSize: 14,
            }}
          >
            <div>{t('forms.transaction.availableBalance')}</div>
            <div>{t('forms.transaction.ecbRate')}</div>
            <div>{t('forms.transaction.conversionRate')}</div>
          </div>
          <div style={{ ...TEMPLATE_GRID, color: '#29292C', lineHeight: '24px', height: 56 }}>
            <div>{balance}</div>
            <div>{transactionsFormData.rate_ecb}</div>
            <div>{CONVERSION_RATE}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SellBuy;

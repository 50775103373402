import React, { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: Array<{
    key: string;
    value: string;
  }>;
  error?: boolean;
  disabled?: boolean;
  withoutAllOptions?: boolean;
  width?: string | number;
}

const ALL_KEY = 'All';

const Select: FC<Props> = ({
  options,
  label,
  onChange,
  value,
  error = false,
  disabled = false,
  withoutAllOptions = false,
  width = '100%',
}) => {
  const currentValue = !value && !withoutAllOptions ? ALL_KEY : value;

  return (
    <div style={{ width: typeof width === 'number' ? `${width}px` : width }}>
      <FormControl fullWidth className={styles.root} error={error} disabled={disabled}>
        <InputLabel id={`id-${label}`} className={styles.label}>
          {label}
        </InputLabel>
        <SelectMui
          value={currentValue}
          className={classNames(styles.select, currentValue === ALL_KEY && styles.selectAll)}
          labelId={`id-${label}`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(event) => onChange(event.target.value === ALL_KEY ? undefined : event.target.value)}
        >
          {!withoutAllOptions && (
            <MenuItem key={ALL_KEY} className={classNames(styles.option, styles.optionAll)} value={ALL_KEY}>
              {ALL_KEY}
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.key} className={styles.option} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </SelectMui>
      </FormControl>
    </div>
  );
};

export default Select;

import React, { FC } from 'react';

import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import ConstructorTransForm from '../../ConstructorTransForm';

interface IncomingCardType {}
const IncomingCard: FC<IncomingCardType> = () => {
  const { transactionsFormData }: useTransactionType = useTransaction();

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ConstructorTransForm types={[KeyTransactions.TYPE, null]} />
      <ConstructorTransForm types={[KeyTransactions.VALUE_DATE_TIME, KeyTransactions.TRANSACTION_DATE]} />
      <ConstructorTransForm types={[KeyTransactions.CURRENCY, KeyTransactions.AMOUNT]} />
      <ConstructorTransForm types={[KeyTransactions.RECIPIENT]} />
      <ConstructorTransForm types={[KeyTransactions.CHECKBOX]} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {!transactionsFormData[KeyTransactions.CHECKBOX] && <ConstructorTransForm types={[KeyTransactions.SENDER]} />}
      <ConstructorTransForm types={[KeyTransactions.FACTOR_BANK_ACCOUNT]} />
      <ConstructorTransForm types={[KeyTransactions.REFERENCE]} />
      <ConstructorTransForm types={[KeyTransactions.DETAILS]} />
      <ConstructorTransForm types={[KeyTransactions.COMMENT]} />
    </>
  );
};
export default IncomingCard;

import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import { updateTitle } from 'helpers/common';

import styles from './styles.module.scss';

export interface MainContentProps {
  children?: JSX.Element | JSX.Element[];
  type?: 'fix' | 'form' | 'flexibleLimit' | 'flexibleFull';
  flexColumn?: boolean;
  className?: string;
  title?: string;
}

const MainContent: FC<MainContentProps> = ({ children, type = 'fix', flexColumn = false, className, title }) => {
  useEffect(() => {
    if (title) {
      updateTitle(title);
    }
  }, [title]);

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.fix]: type === 'fix',
          [styles.form]: type === 'form',
          [styles.flexibleLimit]: type === 'flexibleLimit',
          [styles.flexibleFull]: type === 'flexibleFull',
          [styles.flexColumn]: flexColumn,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default MainContent;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import useTransaction from 'hooks/api/useTransaction';
import { formatAmount } from 'helpers/formatters';
import { tableBodyStyles } from 'theme/styles';

const EntriesTable: FC = () => {
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const { entriesData, getEntriesIsFetching } = useTransaction();

  if (getEntriesIsFetching) {
    return null;
  }

  if (!entriesData.data) {
    return <h3 style={{ color: 'gray' }}>{t('forms.transaction.transactionNotFound')}</h3>;
  }

  return (
    <TableContainer className={styles.paper} style={{ overflow: 'hidden' }}>
      <Table className={styles.table} size="small" aria-label="a dense table" style={{ overflow: 'hidden' }}>
        <TableHead>
          <TableRow className={styles.tHeadRow}>
            <TableCell align="left">{t('tables.debit')}</TableCell>
            <TableCell align="left">{t('tables.credit')}</TableCell>
            <TableCell align="right">{t('tables.amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entriesData.data &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            entriesData.data.map((entry) => (
              <TableRow key={entry.id} className={styles.tBodyRow}>
                <TableCell align="left">{`${entry.debit_name} [${entry.debit_type_name}]`}</TableCell>
                <TableCell align="left">{`${entry.credit_name} [${entry.credit_type_name}]`}</TableCell>
                <TableCell align="right">{formatAmount(entry.amount, entry.currency)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EntriesTable;

import React, { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import pencilSvg from 'assets/img/newPencil.svg';
import delSvg from 'assets/img/newDelete.svg';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import useTransaction, { ItemTransactionsType, useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
};

const ActionButton: FC<PropsT> = ({ transactionsDataById }) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { id } = useParams();
  const [isDeleted, setIsDeleted] = useState(false);
  const { deleteTransactionsById, setSyncTr }: useTransactionType = useTransaction();

  const editObj = {
    image: pencilSvg,
    title: 'forms.transaction.editTransaction',
    style: { color: '#838998' },
    onClick: () => history.push(`${urls.getTransactions()}/edit/${transactionsDataById?.type}/${id}`),
  };
  const delObj = {
    image: delSvg,
    title: 'forms.transaction.deleteTransaction',
    style: { color: '#EC3E72' },
    onClick: () => setIsDeleted(true),
  };
  const arrayMenu = [];

  if (transactionsDataById.type !== KeyTransactions.CONVERSION) {
    arrayMenu.push(delObj);
  }

  if (
    transactionsDataById.type === KeyTransactions.INCOMING ||
    transactionsDataById.type === KeyTransactions.CONVERSION
  ) {
    arrayMenu.unshift(editObj);
  }

  return (
    <div>
      {transactionsDataById.status !== 'deleted' && (
        <ViewGeneralActions buttonList={transactionsDataById.category_dsc === 'capitalization' ? [] : arrayMenu} />
      )}
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title=""
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          deleteTransactionsById({
            transaction_id: id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            successCallback: (res) => {
              // eslint-disable-next-line no-unused-expressions
              (res.type === KeyTransactions.ADJUSTMENT && !res.factoring_order_id) ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setSyncTr({ params: { id_transactions: res.id } });

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const newPath = history.location.state?.prevPath
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  history.location.state.prevPath
                : urls.getTransactions();

              history.replace(newPath);
            },
          });
        }}
      />
    </div>
  );
};

export default ActionButton;

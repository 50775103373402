import { FullNameSort, ShortNameSort } from 'types/Sort';

export const shortToFullNameSort = (value: ShortNameSort): FullNameSort => {
  if (value === ShortNameSort.ASC) return FullNameSort.ASCEND;

  if (value === ShortNameSort.DESC) return FullNameSort.DESCEND;

  throw new Error(`Value ${value} don't equal 'asc' or 'desc'`);
};

export const fullToShortNameSort = (value: FullNameSort): ShortNameSort => {
  if (value === FullNameSort.ASCEND) return ShortNameSort.ASC;

  if (value === FullNameSort.DESCEND) return ShortNameSort.DESC;

  throw new Error("Value don't equal 'ascend' or 'descend'");
};

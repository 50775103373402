import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import { AddIcon, Avatar, AvatarSkeleton, Button, PageHeader, Pagination, Table } from '@gamesb42/ui-kit';

import MainContent from 'components/MainContent';
import urls from 'constants/urls';
import { getUsers } from 'api/users';
import { UserKeyEnum, User } from 'types/UserTypes';

import styles from './styles.module.scss';

const GAME_BOOST42_ID = '2df533a0-6f77-46d3-b047-ae7cb85143ff';

interface QueryParams {
  size?: number;
  page?: number;
}

interface UsersData {
  total: number;
  items: User[];
}

const Users = ({
  history: {
    push,
    replace,
    location: { search, pathname },
  },
}: RouteComponentProps) => {
  const { t } = useTranslation();
  const [usersData, setUsersData] = useState<UsersData>({
    items: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const query: QueryParams = queryString.parse(search, { parseNumbers: true });
  const { page = 1, size = 30 } = query;

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      replace(`${pathname}?${queryString.stringify(newValues, { skipEmptyString: true })}`);
    },
    [pathname, replace],
  );

  const columns = [
    {
      title: t('users.userName'),
      dataIndex: UserKeyEnum.FIRST_NAME,
      skeletonRender: <AvatarSkeleton />,
      width: '426px',
      render: (firstName: string, row: User) => (
        <Avatar name={firstName} surname={row[UserKeyEnum.LAST_NAME]} email={row[UserKeyEnum.EMAIL]} />
      ),
    },
    {
      title: t('users.activeCompany'),
      dataIndex: UserKeyEnum.COMPANY_NAME,
      skeletonProps: {
        width: '78px',
        type: 'text14' as const,
      },
      render: (companyName: string, row: User) =>
        row[UserKeyEnum.COMPANY_ID] === GAME_BOOST42_ID ? (
          <span className={styles.gameBoost42}>{companyName}</span>
        ) : (
          companyName
        ),
    },
  ];

  useEffect(() => {
    const params = {
      page,
      size,
    };

    setLoading(true);
    getUsers(params)
      .then((res) => setUsersData({ items: res.items, total: res.total }))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [page, size]);

  return (
    <MainContent title={t('users.users')} type="flexibleLimit" flexColumn>
      <PageHeader
        title={t('users.users')}
        rightContent={
          <span className={styles.rightContent}>
            <Button type="primary" icon={<AddIcon size={16} />} onClick={() => push(urls.getCreateAdmin())}>
              {t('users.createAdmin')}
            </Button>
            <Button type="primary" icon={<AddIcon size={16} />} onClick={() => push(urls.getCreateUser())}>
              {t('users.inviteUser')}
            </Button>
          </span>
        }
      />
      <Table
        columns={columns}
        loading={loading}
        loadingRowCount={30}
        dataSource={usersData.items}
        className={styles.table}
        rowKey={UserKeyEnum.ID}
        rowClassName={(row) => classNames(styles.row, row.status === 'deleted' && styles.deleted)}
        onRow={(record) => {
          return {
            onClick: () => push(`${urls.getUsers()}/${record[UserKeyEnum.ID]}`),
          };
        }}
      />
      <Pagination
        total={usersData.total}
        current={page}
        pageSize={size}
        onChange={(newCurrent: number, newPageSize: number) => onChangeQuery({ page: newCurrent, size: newPageSize })}
      />
    </MainContent>
  );
};

export default Users;

import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

export const requiredApplaudKeys = [
  KeyCardsEnum.AO_TYPE,
  KeyCardsEnum.COMPANY_ID,
  KeyCardsEnum.NAME,
  KeyCardsEnum.CONTRACT_ID,
  KeyCardsEnum.AMOUNT,
  KeyCardsEnum.VAT_POLICY,
  KeyCardsEnum.CURRENCY,
  KeyCardsEnum.INTEREST,
  KeyCardsEnum.PURCHASE_DATE,
  KeyCardsEnum.REPAYMENT_DATE,
  KeyCardsEnum.PENALTY_POLICY,
  KeyCardsEnum.DAYS,
  KeyCardsEnum.SIGN_DATE,
  KeyCardsEnum.CLOSING_DATE,
];

export const requiredApplaudEditKeys = [...requiredApplaudKeys, KeyCardsEnum.STATUS];

export const errorsApplaud = {
  [KeyCardsEnum.ROOT]: [
    { key: KeyCardsEnum.AO_TYPE, parameters: { required: true } },
    { key: KeyCardsEnum.COMPANY_ID, parameters: { required: true } },
    { key: KeyCardsEnum.NAME, parameters: { required: true } },
    { key: KeyCardsEnum.CONTRACT_ID, parameters: { required: true } },
    { key: KeyCardsEnum.AMOUNT, parameters: { required: true } },
    { key: KeyCardsEnum.VAT_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.CURRENCY, parameters: { required: true } },
    { key: KeyCardsEnum.INTEREST, parameters: { required: true } },
    { key: KeyCardsEnum.PURCHASE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.REPAYMENT_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.PENALTY_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.DAYS, parameters: { required: true } },
    { key: KeyCardsEnum.SIGN_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.STATUS, parameters: { required: true } },
    { key: KeyCardsEnum.GRACE_PERIOD, parameters: { required: true } },
    { key: KeyCardsEnum.CAPITALIZATION_DISABLE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.CLOSING_DATE, parameters: { required: false, date: true } },
  ],
};

export type errorsApplaudType = typeof errorsApplaud;

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useState, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

import DateInput from 'components/inputs/DateInput';
import { formatAmount, formatDateIsoShort, getCurrency } from 'helpers/formatters';
import { UseStylingButton, UseStylingDropZoneButton, UseStylingTextField } from 'hooks/useStylingForMaterialUi';
import PopUpLayout from 'helpers/PopUpLayout';
import DropZoneDocument from 'components/forms/CompanyForm/DropZoneDocument';
import AutoCompleteNew from 'components/inputs/AutoCompleteNew';
import useErrorCallback from 'hooks/useErrorCallback';
import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import SwitchButton from 'components/buttons/SwitchButton';
import { AmountKit } from 'components/uiKit/AmountKit';
import { convertFileToBase64 } from 'helpers/file';

import { initialAdjustment, setAdjustment, amountCellAdjustment, getListPopUpAdjustmentTypes } from './data';

const backSvg = require('assets/img/backPopUp.svg').default;

enum AdjustmentKeyEnum {
  DATE = 'date',
  TYPE = 'type',
  COMMENT = 'comment',
  AMOUNT = 'amount',
  DOCUMENTS = 'documents',
  ORDER = 'order',
  MODIFY_DAILY = 'modify_daily',
}
interface CreateCorrectionPopUpProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  leftCallback?: () => void;
  ao: activationOrderDataType;
}

const CreateCorrectionPopUp: FC<CreateCorrectionPopUpProps> = ({ isOpen = false, setIsOpen, leftCallback, ao }) => {
  const stylingTextField = UseStylingTextField();
  const { activationOrderId } = useParams();
  const dropZoneStylingButtonPopUp = UseStylingDropZoneButton();
  const stylingButtonPopUp = UseStylingButton();
  const styles = useStyle();
  const { t } = useTranslation();
  const [initial, setInitial] = useState(initialAdjustment);
  const { getAlert } = useErrorCallback();
  const [helperText, setHelperText] = useState(null);

  const closeFC = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setInitialFc(AdjustmentKeyEnum.ORDER, activationOrderId);
  }, []);

  useEffect(() => {
    if (initial.date.length === 0 || initial.type.length === 0) {
      setHelperText(null);
    }
  }, [initial]);

  const setInitialFc = useCallback(
    (key: AdjustmentKeyEnum, value: string | File[]) => {
      const setAmountAdjustment = async (order_id, date, adjustment_type) => {
        const response = await amountCellAdjustment({
          order_id,
          date,
          adjustment_type,
        });

        if (response) {
          setHelperText(response.result);
        }
      };

      if (key === AdjustmentKeyEnum.TYPE && typeof value === 'string') {
        if (initial.date.length > 0 && value.length > 0) {
          setAmountAdjustment(initial.order, initial.date, value);
        }
      }

      if (key === AdjustmentKeyEnum.DATE) {
        if (value.length > 0 && initial.type.length > 0) {
          setAmountAdjustment(initial.order, value, initial.type);
        }
      }
      setInitial((prev) => ({ ...prev, [key]: value }));
    },
    [initial, amountCellAdjustment],
  );

  const handleFileChange = useCallback(async (access: File[]) => {
    setInitialFc(AdjustmentKeyEnum.DOCUMENTS, access);
  }, []);

  const helperCount =
    Number(initial.amount) > 0
      ? Number(helperText) + Number(initial.amount)
      : Number(helperText) - Math.abs(Number(initial.amount));

  return (
    <PopUpLayout open={isOpen} onClose={closeFC}>
      <div className={styles.wrapperContract}>
        <img onClick={closeFC} className={styles.backContract} src={backSvg} alt="back" />
        <div className={styles.titleContract}>{t('tables.createAdjustment')}</div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            marginBottom: 40,
            columnGap: 24,
            alignItems: 'center',
          }}
        >
          <DateInput
            date={formatDateIsoShort(initial[AdjustmentKeyEnum.DATE])}
            labelKey="tables.dateTo"
            name="date_to"
            commonStyle={{ height: 60 }}
            onChangeDateHandler={(e) => setInitialFc(AdjustmentKeyEnum.DATE, e.target.value)}
          />
          <SwitchButton
            isSwitchNewStyle
            inputSwitch={initial.modify_daily}
            handleChangeSwitch={({ target: { checked } }) => {
              setInitialFc(AdjustmentKeyEnum.MODIFY_DAILY, checked);
            }}
            name="show_deleted"
            buttonName="contentHeader.modifyDaily"
          />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 24, marginBottom: 40 }}>
          <div style={{ position: 'relative' }}>
            <AutoCompleteNew
              autoCompleteProps={{
                getOptionSelected: () => true,
                renderOption: (p) => t(p?.translate),
                getOptionLabel: (option) => t(option?.translate),
                options: getListPopUpAdjustmentTypes(),
                renderInput: null,
                onChange: (_, value) => {
                  setInitialFc(AdjustmentKeyEnum.TYPE, value?.key ? value?.key : '');
                },
              }}
              textFieldProps={{
                style: { height: 60 },
                placeholder: 'Type',
              }}
            />
            <div className={styles.helperText}>
              {helperText &&
                `${t('tables.correctionValue')} ${getCurrency(ao.currency)}${formatAmount<{
                  maximumFractionDigits: number;
                }>(Number(helperText), null, false, {
                  maximumFractionDigits: 2,
                })}`}
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <AmountKit
              label={t('tables.amount')}
              onChange={(e) => setInitialFc(AdjustmentKeyEnum.AMOUNT, e.target.value)}
              amount={initial.amount}
              wrapProps={{ width: 257, height: 60 }}
              currency={ao.currency}
              decimalScale={8}
            />
            <div className={styles.helperText}>
              {helperText && initial.amount.length > 0
                ? `${t('tables.newValue')} ${getCurrency(ao.currency)}${formatAmount<{
                    maximumFractionDigits: number;
                  }>(helperCount, null, false, {
                    maximumFractionDigits: 2,
                  })}`
                : null}
            </div>
          </div>
        </div>
        <TextField
          {...stylingTextField}
          style={{
            width: '100%',
            marginBottom: 24,
          }}
          className={styles.scrollTable}
          multiline
          minRows="6"
          maxRows="6"
          onChange={(e) => {
            setInitialFc(AdjustmentKeyEnum.COMMENT, e.target.value);
          }}
          value={initial.comment}
          onBlur={() => {}}
          placeholder="Comment"
          inputProps={{
            maxLength: 500,
          }}
        />
        <DropZoneDocument
          acceptFormat={['application/pdf', 'image/jpeg', 'image/png']}
          setAccessFiles={handleFileChange}
          accessFiles={initial.documents}
          title={t('clientTabs.dropZoneTitle')}
          text={t('clientTabs.dropZoneText')}
          buttonText={t('clientTabs.dropZoneButton')}
          multiple
        />
        <div style={{ marginTop: 32 }}>
          <Button
            onClick={async () => {
              let documents = [];

              if (initial.documents.length > 0) {
                const files = initial.documents.map(async (item) => {
                  const result = (await convertFileToBase64(item)) as unknown as string;

                  return { name: item.name, file_data: result };
                });
                documents = await Promise.all(files);
              }
              const result = await setAdjustment({ ...initial, documents });

              if (result?.description) {
                getAlert('error', Object.values(result?.description)[0]);
              }

              if (result?.name) {
                getAlert('error', result?.message);
              }
              leftCallback();
              setInitial(initialAdjustment);
              closeFC();
            }}
            style={{ width: 180, height: 40, marginRight: 24 }}
            {...stylingButtonPopUp}
          >
            {t('tables.saveAdjustment')}
          </Button>
          <Button
            onClick={() => {
              closeFC();
            }}
            style={{ width: 89, height: 40, padding: '20px 8px' }}
            {...dropZoneStylingButtonPopUp}
          >
            {t('clientTabs.cancel')}
          </Button>
        </div>
      </div>
    </PopUpLayout>
  );
};

const useStyle = makeStyles(() => ({
  helperText: {
    position: 'absolute',
    color: '#838998',
    fontSize: 12,
    lineHeight: '12px',
    left: 20,
    bottom: -15,
  },
  wrapperContract: {
    overflowY: 'auto',
    boxSizing: 'border-box',
    width: 687,
    maxHeight: '95vh',
    backgroundColor: '#F7F8FA',
    borderRadius: 8,
    padding: '68px 40px 40px 40px',
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 32,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CECECE',
      borderRadius: 8,
    },
  },
  titleContract: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    lineHeight: '29px',
    color: '#606176',
    marginBottom: 40,
    position: 'relative',
  },
  backContract: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20,
    '&:hover': {
      opacity: 0.8,
    },
  },
  scrollTable: {
    '& textarea': {
      scrollbarWidth: 'thin',
      /* width */
      '&::-webkit-scrollbar': {
        width: 6,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#fff',
        border: '1px solid #ECEEF2',
        borderRadius: 8,
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#CED0D7',
        borderRadius: 8,
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#c6c8ce',
      },
    },
  },
}));

export default CreateCorrectionPopUp;

/* eslint-disable no-console */
import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { Company, CompanyStatus } from 'types/CompanyTypes';
import { getFrontendCompany, getBackendCompany } from 'helpers/mappers/company';

export const getCompanyById = (companyId: string) =>
  baseRequest<Company>({ path: endpoints.getCompaniesScaUrl(companyId), mapper: getFrontendCompany });

export const syncCompany = (companyId: string) =>
  baseRequest<Company>({ path: endpoints.getCompanySyncSca(companyId), mapper: getFrontendCompany }).catch(
    console.error,
  );

export const getCompanies = (params: { page?: number; size?: number; name?: string; type?: string[] | string }) =>
  baseRequest({ path: endpoints.getCompaniesScaUrl(undefined, params) });

export const createCompany = (body: Company) =>
  baseRequest<Company>({
    path: endpoints.getCompanyUrlV1(),
    mapper: getFrontendCompany,
    mapperToBackend: getBackendCompany,
    options: {
      method: 'POST',
      body,
    },
  }).then((result) => {
    syncCompany(result.id);

    return result;
  });

export const editCompany = (body: Company) =>
  baseRequest<Company>({
    path: endpoints.getCompaniesScaUrl(body.id),
    mapper: getFrontendCompany,
    mapperToBackend: getBackendCompany,
    options: { method: 'PUT', body },
  }).then((result) => {
    syncCompany(body.id);

    return result;
  });

export const getFactorCompany = () =>
  baseRequest<Company>({
    path: endpoints.getFactorCompanyScaUrl(),
    mapper: getFrontendCompany,
  });

export const changeStatusCompany = (companyId: string, newStatus: CompanyStatus) =>
  baseRequest<Company>({
    path: endpoints.getStatusCompanyUrl(companyId, { status: newStatus }),
    options: {
      method: 'PATCH',
    },
  }).then((result) => {
    syncCompany(companyId);

    return result;
  });

export const deleteCompanyById = (companyId: string) =>
  baseRequest({ path: endpoints.getCompanyUrlV1(companyId), options: { method: 'DELETE' } }).then((result) => {
    syncCompany(companyId);

    return result;
  });

import React, {
  ButtonHTMLAttributes,
  FC,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import styles from './styles.module.scss';

interface PropsT extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  title?: string;
  titleComponent?: ReactElement;
  initialState?: boolean;
  isOpen?: boolean;
  onClick?: (open: boolean) => void;
  closeImg?: ReactNode;
  openImg?: ReactNode;
}

const Accordion: FC<PropsT> = ({
  title,
  openImg,
  closeImg,
  initialState,
  titleComponent,
  onClick,
  children,
  isOpen,
  ...restProps
}) => {
  const [isShow, setIsShow] = useState(initialState);

  const showContentHandler: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (isOpen === undefined) {
      setIsShow((currentState) => !currentState);
    }

    if (onClick) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick(isOpen);
    }
  }, [isOpen]);

  return (
    <>
      <button className={styles.button} type="button" onClick={showContentHandler} {...restProps}>
        {titleComponent && titleComponent}
        {!titleComponent && (
          <>
            {isShow || isOpen ? closeImg || '-' : openImg || '+'}
            <span className={styles.buttonTextCustomSwitch}>{title}</span>
          </>
        )}
      </button>
      {(isShow || isOpen) && children}
    </>
  );
};

export default Accordion;

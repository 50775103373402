import { createStore, applyMiddleware } from 'redux';
import { queryMiddleware } from 'redux-query';
import superagentInterface from 'redux-query-interface-superagent';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers, { getQueries, getEntities } from 'reducers';

import requestStartMiddleware from './middlewares/requestStartMiddleware';
import authTokenMiddleware from './middlewares/authTokenMiddleware';
import requestFailureMiddleware from './middlewares/requestFailureMiddleware';
import requestSuccessMiddleware from './middlewares/requestSuccessMiddleware';

const configureStore = () => {
  const customMiddlewares = [
    requestStartMiddleware,
    authTokenMiddleware,
    requestFailureMiddleware,
    requestSuccessMiddleware,
    queryMiddleware(superagentInterface, getQueries, getEntities),
  ];

  const middlewares = composeWithDevTools(applyMiddleware(...customMiddlewares));

  return createStore(reducers, middlewares);
};

export const history = createBrowserHistory();
export const store = configureStore();

import React, { useEffect, useState } from 'react';
import { PageHeader, Input, Button } from '@gamesb42/ui-kit';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import MainContent from 'components/MainContent';
import { User, UserKeyEnum, UserRole } from 'types/UserTypes';
import { createUser } from 'api/users';
import { getFactorCompany } from 'api/company';
import urls from 'constants/urls';

const CreateEditAdmin = ({ history: { goBack, push } }: RouteComponentProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [factorCompanyId, setFactorCompanyId] = useState<string>();

  const onFinish = (values: Partial<User>) => {
    setLoading(true);

    createUser({
      ...values,
      [UserKeyEnum.ROLE]: UserRole.FACTOR_ADMIN,
      [UserKeyEnum.COMPANY_ID]: factorCompanyId,
    })
      .then(() => {
        setLoading(false);
        push(urls.getUsers());
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFactorCompany().then((company) => {
      setFactorCompanyId(company.id);
      setLoading(false);
    });
  }, []);

  return (
    <MainContent type="form" title={t('users.createAdmin')}>
      <PageHeader title={t('users.createAdmin')} back />
      <Form form={form} disabled={loading} initialValues={{ [UserKeyEnum.COMPANY]: [] }} onFinish={onFinish}>
        <Form.Item
          name={UserKeyEnum.FIRST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.firstName')}!`,
            },
          ]}
        >
          <Input label={t('users.firstName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.LAST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.lastName')}!`,
            },
          ]}
        >
          <Input label={t('users.lastName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.EMAIL}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.email')}!`,
            },
            { type: 'email', message: 'Please input correct email!' },
          ]}
        >
          <Input label={t('global.email')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.PASSWORD}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.password')}!`,
            },
            {
              min: 8,
              pattern: /^.*(?=.{8,32})(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()-+_=]).*$/,
              message: 'At least 8 characters: upper and lower case Latin letters, special characters',
            },
          ]}
        >
          <Input label={t('global.password')} size="large" />
        </Form.Item>
        <Space size="large">
          <Button type="primary" htmlType="submit">
            {t('global.create')}
          </Button>
          <Button onClick={() => goBack()}>{t('global.cancel')}</Button>
        </Space>
      </Form>
    </MainContent>
  );
};

export default CreateEditAdmin;

import React, { useEffect } from 'react';

import ViewTransactions from 'components/tables/TransactionsTable/View';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const ViewTransactionListScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Transaction details');
  });

  return (
    <MainContent>
      <ViewTransactions requestParams={requestParams} />
    </MainContent>
  );
};

export default ViewTransactionListScreen;

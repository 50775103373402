export const FormControlCustomInput = [
  {
    labelKey: 'tables.agreementNumber',
    name: 'number',
    defaultValue: 'number',
    required: true,
    onBlurHandler: 'number',
    isError: 'number',
    errorText: 'number',
    stylesProp: { width: '100%', height: '60px' },
    error: 'number',
  },
  {
    labelKey: 'tables.agreementName',
    name: 'name',
    defaultValue: 'name',
    required: true,
    onBlurHandler: 'name',
    isError: 'name',
    errorText: 'name',
    stylesProp: { width: '100%', height: '60px' },
    error: 'name',
  },
];

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import setAlertAction from 'actions/setAlert';

import { alertSelector } from './selectors';

const useAlert = () => {
  const selector = useCallback(
    (state) => ({
      alertData: alertSelector(state),
    }),
    [],
  );

  const dispatch = useDispatch();

  const data = useSelector(selector);

  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          setAlert: (payload) => setAlertAction(payload),
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    ...data,
    ...actions,
  };
};

export default useAlert;

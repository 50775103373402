// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useContext, useMemo, useCallback, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useAmount from 'hooks/api/useAmount';
import { tableSettingsStyles } from 'theme/styles';
import useRouteTitle from 'hooks/useRouteTitle';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';
import { firstDayOfMonth, firstDayOfNextMonth, firstDayOfPrevMonth } from 'helpers/dt';
import { formatDateIsoShort, formatMonthYear } from 'helpers/formatters';
import { currencyChoicesAutocomplete } from 'consts/dict';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';
import { RefreshContext } from 'contexts/RefreshContext';
import { accrualReportObjType } from 'queries/amount/getAccrualReport';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { CommissionReportFilter } from 'consts/tableFilters';
import getUrlParams from 'helpers/getUrlParams';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import Select from 'components/uiKit/Select';

const downloadSvg = require('assets/img/download.svg').default;

const getFirstDayOfMonth = (value: string) => formatDateIsoShort(firstDayOfMonth(new Date(value)));
export const accrualTableParams = 'accrualTableParams';
const AccrualReportTableTableHeader: FC | any = () => {
  const classes = tableSettingsStyles();
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const refreshContext = useContext(RefreshContext);
  const { dateValidationByRange } = useFilterDateValidate();
  const [errors, setErrors] = useState({
    date_for: { status: false, text: `${t('dateErrors.dateMax')} ${formatMonthYear(new Date())}` },
  });
  const { getAccrualReport, clearAccrualReportData } = useAmount();

  const { requestParams } = useMemo(
    () => getUrlParams(window.location.href, CommissionReportFilter),
    [window.location.search],
  );
  const setParams = useCallback(
    (paramsObj: accrualReportObjType) => {
      refreshContext.setParams({ datakey: accrualTableParams, params: paramsObj });
      setUrlStringParams({ filters: CommissionReportFilter, currentScreenParams: paramsObj });
    },
    [getAccrualReport, refreshContext, CommissionReportFilter, accrualTableParams],
  );

  useEffect(() => {
    if (!requestParams.date_for) {
      setParams({ date_for: formatDateIsoShort(firstDayOfPrevMonth(new Date())) });
    }
    const validated = dateValidationByRange(requestParams?.date_for, undefined, firstDayOfNextMonth(new Date()));

    if (requestParams.date_for && validated) {
      setErrors(({ date_for }) => ({
        date_for: { ...date_for, status: false },
      }));
      getAccrualReport({ params: { ...requestParams } });

      return;
    }
    setErrors(({ date_for }) => ({
      date_for: { ...date_for, status: true },
    }));
  }, [requestParams]);

  const onChangeDateForHandler = useCallback(
    ({ target: { name, value } }) => {
      const newParams = { ...requestParams, [name]: getFirstDayOfMonth(value) };
      const validated = dateValidationByRange(value, undefined, firstDayOfNextMonth(new Date()));

      if (validated) {
        setErrors(({ date_for }) => ({
          date_for: { ...date_for, status: false },
        }));
      } else {
        clearAccrualReportData();
        setErrors(({ date_for }) => ({
          date_for: { ...date_for, status: true },
        }));
      }
      setParams(newParams);
    },
    [dateValidationByRange, requestParams, setParams],
  );

  return (
    <div>
      <div className={classes.titleSettings}>{title}</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr', marginBottom: 100 }}>
        <DateInput
          disableFutureMonth
          disableFutureYears
          type={DatePikerEnum.WITHOUT}
          name="date_for"
          required
          date={requestParams?.date_for || ''}
          commonStyle={{ width: 170, height: 40, marginRight: 20 }}
          onChangeDateHandler={onChangeDateForHandler}
          errorText={errors.date_for.text}
          isError={errors.date_for.status}
        />
        <div style={{ minWidth: '150px', marginBottom: '20px' }}>
          <Select
            label={t('contentHeader.currency')}
            options={currencyChoicesAutocomplete.map((e) => ({ key: e.id, value: e.name }))}
            value={requestParams?.currency}
            onChange={(currency) => {
              const obj = { ...requestParams, currency };
              setParams(obj);
            }}
          />
        </div>
        <Button
          onClick={async () => {
            getFileStorage(
              endpoints.getAccrualReportUrlcsv(
                getUrlParams(window.location.href, ['currency', 'date_for']).requestParams as object,
              ),
              undefined,
              'application/octet-stream',
              {
                method: 'POST',
              },
            );
          }}
          variant="text"
          style={{ minWidth: 40, height: 40, justifySelf: 'end', border: '1px solid #A7AFB7' }}
        >
          <img src={downloadSvg} alt="download" />
        </Button>
      </div>
    </div>
  );
};

export default AccrualReportTableTableHeader;

import React, { useEffect } from 'react';

import ActivationOrderForm from 'components/forms/ActivationOrderForm/ActivationOrderForm';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AOCreateScreen: any = ({ requestParams, match: { params } }) => {
  useEffect(() => {
    updateTitle('Create Activation Order');
  });

  return (
    <MainContent type="form">
      <ActivationOrderForm requestParams={{ requestParams, params }} />
    </MainContent>
  );
};

export default AOCreateScreen;

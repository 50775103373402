//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react';

import s from './workingCapital.module.scss';
import { DatePicker, InputAmount, InputNumber, Input, Button, DatePickerProps, Select } from '@gamesb42/ui-kit';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import urls from 'constants/urls';
import { formatPercent } from 'helpers/formatters';
import { InputRef } from 'antd';

export default function PartialRepaymentWorkingCapital() {
  const history = useHistory();
  //@ts-ignore
  const { workingCapitalId } = useParams();
  const [issueDate, setIssueDate] = useState<DatePickerProps['value']>(null);
  const [amount, setAmount] = useState(undefined);
  const [currency, setCurrency] = useState(undefined);
  const input = useRef();
  const [needSend, setNeedSend] = useState(false);

  const handleRepayment = async () => {
    input?.current?.focus();

    // console.log({ issueDate });
    // console.log({ amount });

    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      repayment_date: issueDate?.format('YYYY-MM-DD'),
      repayment_amount: amount,
    });

    await fetch(`${process.env.REACT_APP_BASE_URL}/tranche/${workingCapitalId}/partial_repayment`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch((error) => console.log('error', error));

    history.push(urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate');
  };

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);

    fetch(`${process.env.REACT_APP_BASE_URL}/tranche/${workingCapitalId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      //@ts-ignore
      .then((result) => {
        setCurrency(result.currency);
      })
      .catch((error) => console.log('error', error));
  }, []);

  useEffect(() => {
    if (needSend) {
      handleRepayment();
      setNeedSend(false);
    }
  }, [needSend, setNeedSend]);

  return (
    <div className={s.contentWrapper}>
      <div className={s.createForm}>
        <div className={s.title}>Create new item</div>
        <div className={s.row}>
          <DatePicker
            value={issueDate}
            onChange={setIssueDate}
            style={{ width: '343px' }}
            label={'Select date'}
            size="large"
            format="DD.MM.YYYY"
          />
          <InputAmount
            value={amount}
            //@ts-ignore
            onChange={setAmount}
            style={{ width: '343px' }}
            label="Amount"
            currency={currency}
            size="large"
          />
        </div>

        <div className={s.footer}>
          <Button
            onClick={(e) => {
              input?.current?.focus();
              setNeedSend(true);
            }}
            className={s.save}
          >
            Save
          </Button>
          <Button onClick={() => history.push(urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate')}>
            Cancel
          </Button>
        </div>
        <input style={{ width: '0px' }} ref={input} type="text" defaultValue="default" />
      </div>
    </div>
  );
}

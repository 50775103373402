// TODO: Method is deprecated use getParamsFromQuery method
const getUrlParams = <T extends unknown>(url: string, searchParams: Array<string>) => {
  const requestParams = {} as T;

  const newUrl = new URL(url);

  searchParams.forEach((param) => {
    if (newUrl.searchParams.get(param) !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestParams[`${param}`] = newUrl.searchParams.get(param);
    }
  });

  return {
    requestParams,
  };
};

export default getUrlParams;

import React, { useEffect, useState } from 'react';
import { PageHeader, Input, Select, Button } from '@gamesb42/ui-kit';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import MainContent from 'components/MainContent';
import { User, UserKeyEnum, UserRole } from 'types/UserTypes';
import useLoadCompanies from 'hooks/useLoadCompanies';
import NoFoundContent from 'components/NoFoundContent';
import { getUser, createUser, editUser, deleteUser } from 'api/users';
import { getCompanyById, getFactorCompany } from 'api/company';
import urls from 'constants/urls';
import { Company, CompanyKeyEnum } from 'types/CompanyTypes';

const CreateEditUser = ({ match: { params }, history: { goBack, push } }: RouteComponentProps<{ userId?: string }>) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [factorCompanyId, setFactorCompanyId] = useState<string>();
  const { onSearch, companies, setCompanies, onClear, loading: loadingCompanies } = useLoadCompanies();
  const { userId } = params;

  const isCreateUser = !userId;
  const title = isCreateUser ? t('users.createUser') : t('users.editUser');

  const onFinish = ({ company, ...other }: Omit<User, 'company'> & { company: string[] }) => {
    const [activeCompanyId, ...otherCompanies] = company;
    const api = isCreateUser ? createUser : editUser;
    setLoading(true);

    api(
      {
        ...other,
        [UserKeyEnum.ROLE]: activeCompanyId === factorCompanyId ? UserRole.FACTOR_ADMIN : UserRole.CLIENT_ADMIN,
        [UserKeyEnum.COMPANY_ID]: activeCompanyId,
        [UserKeyEnum.OTHER_COMPANIES]:
          otherCompanies.length > 0
            ? otherCompanies.map((companyId) => ({
                [UserKeyEnum.ROLE]: companyId === factorCompanyId ? UserRole.FACTOR_ADMIN : UserRole.CLIENT_ADMIN,
                [UserKeyEnum.COMPANY_ID]: companyId,
              }))
            : [],
      },
      userId as string,
    )
      .then(() => {
        setLoading(false);
        push(urls.getUsers());
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  const onDelete = () => {
    setLoading(true);

    deleteUser(userId as string)
      .then(() => {
        setLoading(false);
        push(urls.getUsers());
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFactorCompany().then((company) => setFactorCompanyId(company.id));

    if (!isCreateUser) {
      getUser(userId)
        .then((user) => {
          const userCompanies = [user[UserKeyEnum.COMPANY][UserKeyEnum.ID]];

          if (Array.isArray(user[UserKeyEnum.OTHER_COMPANIES]) && user[UserKeyEnum.OTHER_COMPANIES].length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            userCompanies.push(...user[UserKeyEnum.OTHER_COMPANIES].map((e: any) => e.company.id));
          }

          form.setFieldsValue({
            [UserKeyEnum.FIRST_NAME]: user[UserKeyEnum.FIRST_NAME],
            [UserKeyEnum.LAST_NAME]: user[UserKeyEnum.LAST_NAME],
            [UserKeyEnum.EMAIL]: user[UserKeyEnum.EMAIL],
          });

          return Promise.all(userCompanies.map((companyId) => getCompanyById(companyId)));
        })
        .then((result) => {
          setCompanies(
            result.map((e: Company) => ({
              label: e[CompanyKeyEnum.NAME] as string,
              value: e[CompanyKeyEnum.ID],
              source: e,
            })),
          );

          form.setFieldsValue({
            [UserKeyEnum.COMPANY]: result.map((e) => e[CompanyKeyEnum.ID]),
          });
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }

    if (isCreateUser) {
      setLoading(false);
    }
  }, [form, userId, isCreateUser, setCompanies]);

  return (
    <MainContent type="form" title={title}>
      <PageHeader title={title} back />
      <Form form={form} disabled={loading} initialValues={{ [UserKeyEnum.COMPANY]: [] }} onFinish={onFinish}>
        <Form.Item
          name={UserKeyEnum.FIRST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.firstName')}!`,
            },
          ]}
        >
          <Input label={t('users.firstName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.LAST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.lastName')}!`,
            },
          ]}
        >
          <Input label={t('users.lastName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.EMAIL}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.email')}!`,
            },
            { type: 'email', message: 'Please input correct email!' },
          ]}
        >
          <Input label={t('global.email')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.PASSWORD}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.password')}!`,
            },
            {
              min: 8,
              pattern: /^.*(?=.{8,32})(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()-+_=]).*$/,
              message: 'At least 8 characters: upper and lower case Latin letters, special characters',
            },
          ]}
        >
          <Input label={t('global.password')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.COMPANY}
          rules={[
            {
              required: true,
              message: `Please select ${t('global.companies')}!`,
            },
          ]}
          shouldUpdate
        >
          <Select
            mode="multiple"
            label={t('global.companies')}
            options={companies}
            onSearch={onSearch}
            filterOption={false}
            size="large"
            notFoundContent={loadingCompanies ? <NoFoundContent /> : null}
            showSearch
            onClear={onClear}
          />
        </Form.Item>
        <Space size="large">
          <Button type="primary" htmlType="submit">
            {t('global.save')}
          </Button>
          <Button onClick={() => goBack()}>{t('global.cancel')}</Button>
          {userId && (
            <Button type="primary" danger onClick={onDelete}>
              {t('global.delete')}
            </Button>
          )}
        </Space>
      </Form>
    </MainContent>
  );
};

export default CreateEditUser;

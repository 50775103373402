import { requestAsync } from 'redux-query';
import type { Dictionary } from 'lodash';

import endpoints from 'consts/endpoints';

import { bodyCreateTransactionsType } from './createListTransactions';

export interface setTransactionsDataType {
  transaction_id: string;
  body: bodyCreateTransactionsType | Dictionary<bodyCreateTransactionsType>;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({
  errorCallback,
  resultKey = 'setTransactionData',
  successCallback,
  body,
  transaction_id,
}: setTransactionsDataType) =>
  requestAsync({
    url: endpoints.setTransactionsUrl(transaction_id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.setTransactionsUrl(transaction_id),
    body,
    options: {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, useEffect, useState, useCallback, FC, useContext } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import lodash from 'lodash';

import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import DateInput from 'components/inputs/DateInput';
import { formatAmount, formatDate, getCurrency } from 'helpers/formatters';
import { UseStylingButton } from 'hooks/useStylingForMaterialUi';
import { tableStyleAo } from 'theme/styles';
import AutoCompleteNew from 'components/inputs/AutoCompleteNew';
import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import { removeEmptyValuesFromObject } from 'helpers/dt';
import { RefreshContext } from 'contexts/RefreshContext';
import setUrlStringParams from 'helpers/setUrlStringParams';
import useFilterDateValidate from 'hooks/useFilterDateValidate';

import CreateCorrectionPopUp from './CreateCorrectionPopUp';
import CorrectionDetailsPopUp from './CorrectionDetailsPopUp';
import {
  AdjustmentCalcInitial,
  AdjustmentKeyArray,
  AdjustmentKeyEnum,
  getAdjustmentCalc,
  getAdjustmentCalcType,
  getlistAdjustmentTypes,
  initialFilterData,
  initialKeyFilterEnum,
  initialParams,
} from './data';
import FullNameField from './FullNameField';

const downloadSvg = require('assets/img/download.svg').default;
const plusSvg = require('assets/img/plusWhite.svg').default;

const auditLogHeader = [
  {
    id: '0',
    translate: 'Activity date',
  },
  {
    id: '1',
    translate: 'Type',
  },
  {
    id: '2',
    translate: 'Description',
  },
  {
    id: '3',
    translate: 'User name',
  },
];

interface AuditLogProps {
  ao: activationOrderDataType;
}

const datakey = 'adjustmentParams';
const AuditLog: FC<AuditLogProps> = ({ ao }) => {
  const stylingButton = UseStylingButton();
  const classes = tableStyleAo();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<getAdjustmentCalcType>(AdjustmentCalcInitial);
  const [adjustment, setAdjustment] = useState<getAdjustmentCalcType[]>([]);
  const [filterAdjustment, setAdjustmentFilter] = useState(initialFilterData);
  const [userList, setUserList] = useState<{ translate: string; key: string }[]>([]);
  const [adjustmentList, setAdjustmentList] = useState<getAdjustmentCalcType[]>([]);
  const { activationOrderId } = useParams();
  const refreshContext = useContext(RefreshContext);
  const [errors, setErrors] = useState({
    date_from: { status: false, text: '' },
    date_to: { status: false, text: '' },
  });
  const { dateValidator } = useFilterDateValidate();
  const getAdjustmentCalcAsync = useCallback(
    async (params = filterAdjustment, isUser?: boolean) => {
      if (!activationOrderId) return;
      const result = await getAdjustmentCalc(activationOrderId, removeEmptyValuesFromObject(params));

      if (result && Array.isArray(result?.adjustments)) {
        if (isUser) {
          const arr = result?.adjustments.map((item) => ({ translate: item.user_email, key: item.user }));
          setUserList(lodash.uniqBy(arr, (o) => o.key));
          setAdjustmentList(result?.adjustments);

          return;
        }
        setAdjustment(result.adjustments);
      }
    },
    [filterAdjustment, activationOrderId],
  );

  useEffect(() => {
    getAdjustmentCalcAsync(initialParams, true);
    const sessionParams = refreshContext.getNewParams({ datakey });

    if (!lodash.isEmpty(sessionParams)) {
      setAdjustmentFilter(sessionParams);
      checkValidate('date_from', sessionParams.date_from, sessionParams);

      return;
    }
    checkValidate('date_from', filterAdjustment.date_from);
  }, []);

  useEffect(() => {
    if (!initialFilterData) return;
    getAdjustmentCalcAsync();
    refreshContext.setParams({
      datakey,
      params: filterAdjustment,
    });
    setUrlStringParams({
      filters: Object.values(initialKeyFilterEnum),
      currentScreenParams: removeEmptyValuesFromObject(filterAdjustment),
    });
  }, [filterAdjustment]);

  const tableCellStyle = useCallback((load: boolean, item: getAdjustmentCalcType, key: AdjustmentKeyEnum) => {
    let style = {};

    if (!load) {
      style = { cursor: 'pointer' };
    }

    if (!item.active && key !== AdjustmentKeyEnum.USER && key !== AdjustmentKeyEnum.DATE) {
      style = { ...style, textDecoration: 'line-through' };
    }

    return {
      ...style,
    };
  }, []);

  const getBodyValue = useCallback(
    (key: AdjustmentKeyEnum, item: getAdjustmentCalcType) => {
      if (key === AdjustmentKeyEnum.USER) {
        return <FullNameField name={item.user_fullname} email={item.user_email} />;
      }

      if (key === AdjustmentKeyEnum.DESCRIPTION) {
        return `${t(item.type)}, ${t('tables.detailsAmount')}: ${getCurrency(ao.currency)}${formatAmount<{
          maximumFractionDigits: number;
        }>(Number(item.amount), null, false, {
          maximumFractionDigits: 2,
        })}`;
      }

      if (key === AdjustmentKeyEnum.TYPE) {
        return t('tables.adjustment');
      }

      if (key === AdjustmentKeyEnum.DATE) {
        return formatDate(item[key]);
      }

      return item[key];
    },
    [AdjustmentKeyEnum, ao],
  );

  const setInitialFilter = useCallback((key: initialKeyFilterEnum, value: string | null) => {
    setAdjustmentFilter((prev) => ({ ...prev, [key]: value || '' }));
  }, []);

  const getUserName = useCallback((list: getAdjustmentCalcType[], id: string) => {
    const result = list.find((item) => item.user === id);

    if (result) {
      return result.user_email;
    }

    return null;
  }, []);

  const checkValidate = useCallback(
    (name: string, value: string, filter = filterAdjustment) => {
      const { validatedErrors } = dateValidator({
        name,
        value,
        filterParams: filter,
        errors,
      });
      setErrors(validatedErrors);
    },
    [dateValidator, filterAdjustment],
  );

  return (
    <>
      <CorrectionDetailsPopUp
        confirmCallBack={getAdjustmentCalcAsync}
        data={currentItem}
        isOpenDetails={isOpenDetails}
        setIsOpenDetails={setIsOpenDetails}
      />
      <CreateCorrectionPopUp ao={ao} leftCallback={getAdjustmentCalcAsync} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <DateInput
            date={filterAdjustment.date_from}
            name="date_from"
            labelKey="tables.dateFrom"
            commonStyle={{ width: 170, height: 40, marginRight: 20 }}
            onChangeDateHandler={({ target: { value, name } }) => {
              setInitialFilter(initialKeyFilterEnum.DATE_FROM, value);
              checkValidate(name, value);
            }}
            isError={errors.date_from.status}
            errorText={errors.date_from.text}
          />
          <DateInput
            name="date_to"
            date={filterAdjustment.date_to}
            labelKey="tables.dateTo"
            commonStyle={{ width: 170, height: 40, marginRight: 20 }}
            onChangeDateHandler={({ target: { value, name } }) => {
              setInitialFilter(initialKeyFilterEnum.DATE_TO, value);
              checkValidate(name, value);
            }}
            isError={errors.date_to.status}
            errorText={errors.date_to.text}
          />
          <AutoCompleteNew
            autoCompleteProps={{
              value: { translate: getUserName(adjustmentList, filterAdjustment.user) },
              getOptionSelected: () => true,
              renderOption: (p) => t(p?.translate),
              getOptionLabel: (option) => t(option?.translate),
              options: userList,
              renderInput: null,
              onChange: (_, value) => {
                setInitialFilter(initialKeyFilterEnum.USER, value?.key ? value?.key : '');
              },
            }}
            textFieldProps={{
              style: { width: 240, height: 40, marginRight: 20 },
              placeholder: 'User',
            }}
          />
          <AutoCompleteNew
            autoCompleteProps={{
              value: { translate: filterAdjustment.type },
              getOptionSelected: () => true,
              renderOption: (p) => t(p?.translate),
              getOptionLabel: (option) => t(option?.translate),
              options: getlistAdjustmentTypes(),
              renderInput: null,
              onChange: (_, value) => {
                setInitialFilter(initialKeyFilterEnum.TYPE, value?.key ? value?.key : '');
              },
            }}
            textFieldProps={{
              style: { width: 240, height: 40, marginRight: 20 },
              placeholder: 'Type',
            }}
          />
          <Button variant="text" style={{ minWidth: 40, height: 40, border: '1px solid #A7AFB7' }}>
            <img src={downloadSvg} alt="download" />
          </Button>
        </div>
        <Button
          startIcon={<img src={plusSvg} alt="plus" />}
          onClick={() => setIsOpen(true)}
          style={{ height: 40, width: 220 }}
          {...stylingButton}
        >
          {t('tables.createAdjustment')}
        </Button>
      </div>
      <TableWrapWithPagination tableContainerStyle={{ backgroundColor: 'inherit' }} styleTop={{ top: 61 }} count={100}>
        <TableHead classes={{ root: classes.documentHeaderRowRoot }}>
          <TableRow>
            {auditLogHeader.map(({ id, translate }) => (
              <TableCell align="left" key={id} classes={{ root: classes.documentHederRoot }}>
                {t(translate)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {adjustment.length > 0 &&
            Children.toArray(
              adjustment?.map((item) => (
                <TableRow
                  style={isLoading ? { backgroundColor: '#dfe1e4' } : {}}
                  classes={{ root: classes.bodyTableRowRoot }}
                >
                  {Children.toArray(
                    AdjustmentKeyArray.map(({ key }) => (
                      <TableCell
                        style={tableCellStyle(isLoading, item, key)}
                        onClick={() => {
                          setCurrentItem(item);
                          setIsOpenDetails(true);
                        }}
                        classes={{ root: classes.bodyTableCellRoot }}
                      >
                        {getBodyValue(key, item)}
                      </TableCell>
                    )),
                  )}
                </TableRow>
              )),
            )}
        </TableBody>
      </TableWrapWithPagination>
    </>
  );
};
export default AuditLog;

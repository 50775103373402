import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import LoadDataForm from 'components/forms/LoadDataForm';
import { updateTitle } from 'helpers/common';

const LoadAllDataScreen = () => {
  useEffect(() => {
    updateTitle('Load All Data');
  }, []);

  return (
    <ContentWrapper>
      <LoadDataForm />
    </ContentWrapper>
  );
};

export default LoadAllDataScreen;

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const useRouteTitle = (): { title: string } => {
  const { t } = useTranslation();

  const location = useLocation();

  const locationArr = location.pathname.split('/');
  const [locationPath] = locationArr.splice(-1, 1);
  // const subPath = type === 'create' ? type : 'edit'; add after make route to create or edit, type === locationArr[1]
  const tPath = `menu.${locationPath}`;

  return { title: t(tPath) };
};

export default useRouteTitle;

import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import AdminForm from 'components/forms/AdminForm';
import { updateTitle } from 'helpers/common';

const AdminCreateScreen = () => {
  useEffect(() => {
    updateTitle('Create Admin');
  }, []);

  return (
    <ContentWrapper>
      <AdminForm />
    </ContentWrapper>
  );
};

export default AdminCreateScreen;

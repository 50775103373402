import i18next from 'i18next';

export enum KeyTransactions {
  AVAILABLE_BALANCE = 'available_balance',
  BANK_NAME = 'bank_name',
  SWIFT = 'swift',
  FACTORING_ORDER_ID = 'factoring_order_id',
  FACTORING_ORDER_NUMBER = 'factoring_order_number',
  SWIFT_EUR = 'swift_eur',
  SWIFT_USD = 'swift_usd',
  BANK_NAME_EUR = 'bank_name_eur',
  BANK_NAME_USD = 'bank_name_usd',
  FACTOR_ID = 'factor_id',
  TRADING_NAME = 'trading_name',
  COMMISSION = 'commission',
  RATE = 'rate',
  RATE_ECB = 'rate_ecb',
  AMOUNT_SELL = 'amount_sell',
  AMOUNT_BUY = 'amount_buy',
  CONVERSION = 'conversion',
  ADJUSTMENT = 'adjustment',
  FEE = 'fee',
  PARENT_ID = 'parent_id',
  COMMON = 'common',
  ID = 'id',
  INTEREST_REPAY = 'INTEREST_REPAY',
  BODY_REPAY = 'BODY_REPAY',
  PENALTY_REPAY = 'PENALTY_REPAY',
  BANK_FEE_IN = 'BANK_FEE_IN',
  DATE = 'date',
  TARGET_TYPE = 'target_type',
  ROOT = 'root',
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  INTERNAL = 'internal',
  CONVERSION_INCOMING = 'conversion_incoming',
  TYPE = 'type',
  VALUE_DATE_TIME = 'value_datetime',
  TRANSACTION_DATE = 'transaction_date',
  CURRENCY = 'currency',
  CURRENCY_BUY = 'currency_buy',
  CURRENCY_SELL = 'currency_sell',
  AMOUNT = 'amount',
  EUR = 978,
  USD = 840,
  RECIPIENT = 'recipient',
  CLAIMS = 'claims',
  SENDER = 'sender',
  COMPANY_ID = 'company_id',
  COMPANY_NAME = 'company_name',
  CHECKBOX = 'checkbox',
  REFERENCE = 'reference',
  DETAILS = 'details',
  COMMENT = 'comment',
  CATEGORY = 'category',
  OUTGOING_PURCHASE_PRICE = 'outgoing-purchase-price',
  OUTGOING_BALANCE_PAYABLE = 'outgoing-balance-payable',
  OUTGOING_TRANSIT = 'outgoing-transit',
  INTERNAL_COMMISSION = 'internal-commission',
  INTERNAL_PRINCIPAL_AMOUNT = 'internal-principal-amount',
  INTERNAL_DEFAULT_INTEREST = 'internal-default-interest',
  INTERNAL_CAPITALIZATION = 'internal-capitalization',
  INTERNAL_FACTORING_SERVICES_FEE = 'internal-factoring-services-fee',
  DOCUMENT_ID = 'document_id',
  DOCUMENT_NUMBER = 'document_number',
  DOCUMENT_TYPE = 'document_type',
  DOCUMENT_AO = 'document_ao',
  DOCUMENT_REQUEST = 'document_request',
  FACTORING_COMPANY_ID = 'factoring_company_id',
  FACTORING_COMPANY_NAME = 'factoring_company_name',
  ACCOUNT = 'account',
  COMMISSION_COEFFICIENT = 'commissionCoefficient',
  FACTOR_BANK_ACCOUNT = 'factorBankAccount',
}

enum listTypeTransactionsEnum {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  FEE = 'fee',
  INTERNAL = 'internal',
  ADJUSTMENT = 'adjustment',
  CONVERSION = 'conversion',
}

enum currencyTrCodeEnum {
  EUR = '978',
  USD = '840',
}

export const getListValueTypeTransactions = () => [
  { key: listTypeTransactionsEnum.INCOMING, value: i18next.t('tables.incoming') },
  { key: listTypeTransactionsEnum.OUTGOING, value: i18next.t('tables.outdoing') },
  { key: listTypeTransactionsEnum.INTERNAL, value: i18next.t('tables.internal') },
  { key: listTypeTransactionsEnum.FEE, value: i18next.t('tables.fee') },
  { key: listTypeTransactionsEnum.ADJUSTMENT, value: i18next.t('forms.transaction.adjusting') },
  { key: listTypeTransactionsEnum.CONVERSION, value: i18next.t('forms.transaction.conversion') },
];

export const getObjectTransactions = () => ({
  [listTypeTransactionsEnum.INCOMING]: getListValueTypeTransactions()[0],
  [listTypeTransactionsEnum.OUTGOING]: getListValueTypeTransactions()[1],
  [listTypeTransactionsEnum.INTERNAL]: getListValueTypeTransactions()[2],
  [listTypeTransactionsEnum.FEE]: getListValueTypeTransactions()[3],
  [listTypeTransactionsEnum.ADJUSTMENT]: getListValueTypeTransactions()[4],
  [listTypeTransactionsEnum.CONVERSION]: getListValueTypeTransactions()[5],
});

export const getCurrencyListTransactions = [
  { key: currencyTrCodeEnum.EUR, value: 'EUR' },
  { key: currencyTrCodeEnum.USD, value: 'USD' },
];

export const getCurrentObjCurrency = {
  [currencyTrCodeEnum.EUR]: getCurrencyListTransactions[0],
  [currencyTrCodeEnum.USD]: getCurrencyListTransactions[1],
};

export const getTypeTransactions = () => [
  i18next.t('tables.incoming'),
  i18next.t('tables.outdoing'),
  i18next.t('forms.transaction.internal'),
  i18next.t('forms.transaction.fee'),
  i18next.t('forms.transaction.adjustment'),
  i18next.t('forms.transaction.Conversion'),
];

export const getSymbolAndColorTransactions = (amount: string | number | undefined, type: string) => {
  const amountLocal = Number(amount || 0);
  let symbol = amountLocal ? '−' : '';
  let color = '';

  if (type === 'incoming') {
    symbol = '+';
    color = '#26B634';
  }

  if (type === 'adjustment') {
    symbol = amountLocal < 0 ? '−' : '+';
    color = amountLocal > 0 ? '#26B634' : '';
  }

  if (type === 'fee') {
    symbol = amountLocal < 0 ? '+' : '-';
    color = amountLocal < 0 ? '#26B634' : '';
  }

  return {
    color,
    symbol,
  };
};

import React, { FC } from 'react';

import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import ConstructorTransForm from '../../ConstructorTransForm';

interface AdjustmentCardType {}
const AdjustmentCard: FC<AdjustmentCardType> = () => (
  <>
    <ConstructorTransForm types={[KeyTransactions.TYPE, KeyTransactions.VALUE_DATE_TIME]} />
    <ConstructorTransForm types={[KeyTransactions.RECIPIENT]} />
    <ConstructorTransForm types={[KeyTransactions.CURRENCY, KeyTransactions.AMOUNT]} />
    <ConstructorTransForm types={[KeyTransactions.DOCUMENT_AO]} />
    <ConstructorTransForm types={[KeyTransactions.COMMENT]} />
  </>
);
export default AdjustmentCard;

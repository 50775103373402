import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getCurrencyRateType {
  params?: { on_date?: string; currency?: string };
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({ params, errorCallback, resultKey = 'currencyRate', successCallback }: getCurrencyRateType) =>
  requestAsync({
    url: endpoints.getCurrencyRateUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCurrencyRateUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC } from 'react';

import { paramsObjAO } from '../../ActivationOrderForm';
import { KeyCardsEnum } from '../data';
import ItemsAo from '../ItemsAo';

interface CardAvailableReceivablesType {
  params?: paramsObjAO;
}

const CardAvailableReceivables: FC<CardAvailableReceivablesType> = React.memo(() => (
  <>
    <ItemsAo types={[KeyCardsEnum.AO_TYPE, KeyCardsEnum.SIGN_DATE]} />
    <ItemsAo types={[KeyCardsEnum.COMPANY_ID]} />
    <ItemsAo types={[KeyCardsEnum.CONTRACT_ID]} />
    <ItemsAo types={[KeyCardsEnum.NAME, KeyCardsEnum.PENALTY_POLICY]} />
    <ItemsAo types={[KeyCardsEnum.DROPZONE]} />
    <ItemsAo types={[KeyCardsEnum.DISTRIBUTION_PLATFORMS]} />
  </>
));

export default CardAvailableReceivables;

import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

type CreateButtonPropsType = {
  createHandler: (e: MouseEvent<HTMLButtonElement>) => void;
};

const CreateButton: FC<CreateButtonPropsType> = ({ createHandler }: CreateButtonPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Button color="primary" onClick={createHandler}>
      <AddIcon fontSize="small" />
      <span className={styles.buttonText}>{t('contentHeader.create')}</span>
    </Button>
  );
};

export default CreateButton;

const useStyles = createUseStyles({
  buttonText: {
    paddingLeft: '0.5em',
    fontSize: '13px',
    marginRight: 25,
  },
});

import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { downloadFile } from 'helpers/file';
import { deleteActivationOrderDataType } from 'queries/activationOrder/deleteActivationOrder';
import useAlert from 'hooks/api/useAlert';
import { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import pencilSvg from 'assets/img/newPencil.svg';
import downloadSvg from 'assets/img/newDownload.svg';
import delSvg from 'assets/img/newDelete.svg';
import useErrorCallback from 'hooks/useErrorCallback';

type PropsT = {
  type: AoTypeEnum;
  id: activationOrderDataType['id'];
  deleteActivationOrder: (data: deleteActivationOrderDataType) => void;
  title: string;
};

const ActionsButton: FC<PropsT> = ({ id, deleteActivationOrder, type, title }) => {
  const { setAlert } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const { getAlert } = useErrorCallback();
  const history = useHistory();

  return (
    <div style={{ position: 'absolute', top: -83, right: 0 }}>
      <ViewGeneralActions
        buttonList={[
          {
            image: downloadSvg,
            title: 'forms.activationOrder.downloadXLSX',
            style: { color: '#838998' },
            onClick: async () => {
              if (type !== AoTypeEnum.CLASSIC) return;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const res = await fetch(endpoints.getAODocumentXLSX(id), {
                headers: {
                  Authorization: `Bearer ${getToken()}`,
                },
              });
              const { bs64_data, filename } = await res.json();

              if (bs64_data && res.status === 200) {
                const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                downloadFile(bs64_data, contentType, filename);

                return;
              }
              getAlert('error', res.statusText || 'Internal server error');
            },
          },
          {
            image: pencilSvg,
            title: 'forms.activationOrder.editAo',
            style: { color: '#838998' },
            onClick: () => history.push(`${urls.getActivationOrder()}/edit/${type}/${id}`),
          },
          {
            image: delSvg,
            title: 'forms.activationOrder.deleteAo',
            style: { color: '#EC3E72' },
            onClick: () => setIsDeleted(true),
          },
        ]}
      />
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title={title}
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          deleteActivationOrder({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id,
            successCallback: () => {
              history.push(urls.getActivationOrder());
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errorCallback: (error) => {
              setAlert({
                type: 'error',
                message: error.body.description,
              });
            },
          });
        }}
      />
    </div>
  );
};

export default ActionsButton;

import React, { FC } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { CompanyInfoTabPanelGroupDataType } from 'types/SettingsTabTypes';
import { systemSettingsFieldsEnum } from 'components/forms/SystemSettingsForm/SystemSettingsFormData';
import { InputKit } from 'components/uiKit/InputKit';

type CompanyInfoTabPanelPropsType = {
  groupData: CompanyInfoTabPanelGroupDataType;
  setValue: any;
  errors?: any;
};
const CompanyInfoTabPanel: FC<CompanyInfoTabPanelPropsType> = ({
  groupData,
  setValue,
  errors,
}: CompanyInfoTabPanelPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <TabPanel value="company_info" style={{ padding: 0 }}>
      {groupData && (
        <div className={styles.tabBlock}>
          <InputKit
            label={t('systemSetting.legalName')}
            name={systemSettingsFieldsEnum.legal_name}
            onChange={setValue}
            value={groupData.legal_name}
            isError={errors?.legal_name?.isError}
            helperText={errors?.legal_name?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.tradingName')}
            name={systemSettingsFieldsEnum.trading_name}
            onChange={setValue}
            value={groupData.trading_name}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.companyRegistration')}
            name={systemSettingsFieldsEnum.company_registration}
            onChange={setValue}
            value={groupData.company_registration}
            isError={errors?.company_registration?.isError}
            helperText={errors?.company_registration?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.taxNumber')}
            name={systemSettingsFieldsEnum.tax_number}
            onChange={setValue}
            value={groupData.tax_number}
            isError={errors?.tax_number?.isError}
            helperText={errors?.tax_number?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.website')}
            name={systemSettingsFieldsEnum.website}
            onChange={setValue}
            value={groupData.website}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.address')}
            name={systemSettingsFieldsEnum.address}
            onChange={setValue}
            value={groupData.address}
            isError={errors?.address?.isError}
            helperText={errors?.address?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.city')}
            name={systemSettingsFieldsEnum.city}
            onChange={setValue}
            value={groupData.city}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.country')}
            name={systemSettingsFieldsEnum.country}
            onChange={setValue}
            value={groupData.country}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.address2')}
            name={systemSettingsFieldsEnum.address2}
            onChange={setValue}
            value={groupData.address2}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.zip')}
            name={systemSettingsFieldsEnum.zip}
            onChange={setValue}
            value={groupData.zip}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.directorFullName')}
            name={systemSettingsFieldsEnum.director_full_name}
            onChange={setValue}
            value={groupData.director_full_name}
            isError={errors?.director_full_name?.isError}
            helperText={errors?.director_full_name?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.directorTitle')}
            name={systemSettingsFieldsEnum.director_title}
            onChange={setValue}
            value={groupData.director_title}
            isError={errors?.director_title?.isError}
            helperText={errors?.director_title?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.representativeFullName')}
            name={systemSettingsFieldsEnum.representative_full_name}
            onChange={setValue}
            value={groupData.representative_full_name}
            isError={errors?.representative_full_name?.isError}
            helperText={errors?.representative_full_name?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.representativeTitle')}
            name={systemSettingsFieldsEnum.representative_title}
            onChange={setValue}
            value={groupData.representative_title}
            isError={errors?.representative_title?.isError}
            helperText={errors?.representative_title?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.representativeEmail')}
            name={systemSettingsFieldsEnum.representative_email}
            onChange={setValue}
            value={groupData.representative_email}
            isError={errors?.representative_email?.isError}
            helperText={errors?.representative_email?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.representativePhoneNumber')}
            name={systemSettingsFieldsEnum.representative_phone_number}
            onChange={setValue}
            value={groupData.representative_phone_number}
            isError={errors?.representative_phone_number?.isError}
            helperText={errors?.representative_phone_number?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <div style={{ marginBottom: 20, marginLeft: 15 }}>{t('selectFiledValues.usd')}</div>
          <InputKit
            label={t('systemSetting.bank_usd')}
            name={systemSettingsFieldsEnum.bank_usd}
            onChange={setValue}
            value={groupData.bank_usd}
            isError={errors?.bank_usd?.isError}
            helperText={errors?.bank_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.swift_usd')}
            name={systemSettingsFieldsEnum.swift_usd}
            onChange={setValue}
            value={groupData.swift_usd}
            isError={errors?.swift_usd?.isError}
            helperText={errors?.swift_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.iban_usd')}
            name={systemSettingsFieldsEnum.iban_usd}
            onChange={setValue}
            value={groupData.iban_usd}
            isError={errors?.iban_usd?.isError}
            helperText={errors?.iban_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.beneficiary_usd')}
            name={systemSettingsFieldsEnum.beneficiary_usd}
            onChange={setValue}
            value={groupData.beneficiary_usd}
            isError={errors?.beneficiary_usd?.isError}
            helperText={errors?.beneficiary_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.beneficiary_bank_usd')}
            name={systemSettingsFieldsEnum.beneficiary_bank_usd}
            onChange={setValue}
            value={groupData.beneficiary_bank_usd}
            isError={errors?.beneficiary_bank_usd?.isError}
            helperText={errors?.beneficiary_bank_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.reference_usd')}
            name={systemSettingsFieldsEnum.reference_usd}
            onChange={setValue}
            value={groupData.reference_usd}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
          />
          <InputKit
            label={t('systemSetting.bank_address_usd')}
            name={systemSettingsFieldsEnum.bank_address_usd}
            onChange={setValue}
            value={groupData.bank_address_usd}
            isError={errors?.bank_address_usd?.isError}
            helperText={errors?.bank_address_usd?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <div style={{ marginBottom: 20, marginLeft: 15 }}>{t('selectFiledValues.eur')}</div>
          <InputKit
            label={t('systemSetting.bank_eur')}
            name={systemSettingsFieldsEnum.bank_eur}
            onChange={setValue}
            value={groupData.bank_eur}
            isError={errors?.bank_eur?.isError}
            helperText={errors?.bank_eur?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.swift_eur')}
            name={systemSettingsFieldsEnum.swift_eur}
            onChange={setValue}
            value={groupData.swift_eur}
            isError={errors?.swift_eur?.isError}
            helperText={errors?.swift_eur?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.iban_eur')}
            name={systemSettingsFieldsEnum.iban_eur}
            onChange={setValue}
            value={groupData.iban_eur}
            isError={errors?.iban_eur?.isError}
            helperText={errors?.iban_eur?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.bank_address_eur')}
            name={systemSettingsFieldsEnum.bank_address_eur}
            onChange={setValue}
            value={groupData.bank_address_eur}
            isError={errors?.bank_address_eur?.isError}
            helperText={errors?.bank_address_eur?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
          <InputKit
            label={t('systemSetting.beneficiary_eur')}
            name={systemSettingsFieldsEnum.beneficiary_eur}
            onChange={setValue}
            value={groupData.beneficiary_eur}
            isError={errors?.beneficiary_eur?.isError}
            helperText={errors?.beneficiary_eur?.errorText}
            wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
            required
          />
        </div>
      )}
    </TabPanel>
  );
};

export default CompanyInfoTabPanel;

const useStyles = makeStyles(() => ({
  tabBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '500px',
    marginBottom: '23px',
    height: '48px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const getParamsFromQuery = <T extends unknown>(query: string, searchParams: Array<string>): T => {
  const requestParams = {} as T;

  const urlSearchParams = new URLSearchParams(query);

  searchParams.forEach((param) => {
    const foundParam = urlSearchParams.get(param);

    if (foundParam) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestParams[`${param}`] = foundParam;
    }
  });

  return requestParams;
};

export default getParamsFromQuery;

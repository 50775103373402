import React, { useEffect } from 'react';
import { H1, GBIcon } from '@gamesb42/ui-kit';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const NoAccess = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={styles.root}>
      <GBIcon className={styles.gb} />
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <circle cx="60" cy="60" r="56" fill="#E02A61" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.5858 38.4142C41.3668 37.6332 42.6332 37.6332 43.4142 38.4142L80.1838 75.1838C80.9648 75.9648 80.9648 77.2311 80.1838 78.0122C79.4027 78.7932 78.1364 78.7932 77.3553 78.0122L40.5858 41.2426C39.8047 40.4616 39.8047 39.1953 40.5858 38.4142Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.4142 78.4142C39.6332 77.6332 39.6332 76.3668 40.4142 75.5858L77.1838 38.8162C77.9648 38.0352 79.2311 38.0352 80.0122 38.8162C80.7932 39.5973 80.7932 40.8636 80.0122 41.6447L43.2426 78.4142C42.4616 79.1953 41.1953 79.1953 40.4142 78.4142Z"
          fill="white"
        />
      </svg>
      <H1>{t('title.noAccess')}</H1>
    </div>
  );
};

export default NoAccess;

import React, { useEffect } from 'react';

import PurchaseRequestForm from 'components/forms/PurchaseRequestForm/Form';
import { VariantEnum } from 'components/forms/PurchaseRequestForm/Form/data';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

export const wrapperPurchaseRequestStyle = {
  padding: '20px 40px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto minmax(100px, 1fr)',
};

const PurchaseRequestCreateScreen = () => {
  useEffect(() => {
    updateTitle('Create Purchase Request');
  }, []);

  return (
    <MainContent type="form">
      <PurchaseRequestForm variant={VariantEnum.CREATE} />
    </MainContent>
  );
};

export default PurchaseRequestCreateScreen;

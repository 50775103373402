// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import { SelectKit } from 'components/uiKit/SelectKit';
import { AmountKit } from 'components/uiKit/AmountKit';
import { CurrencyType } from 'components/uiKit/InputKit';
import Button from 'components/uiKit/Button';
import DateInput from 'components/inputs/DateInput';
import { ErrorT } from 'components/forms/PurchaseRequestForm/Form/data';

import styles from './styles.module.scss';

const basketSvg = require('assets/img/basket.svg').default;

export type ClaimT = {
  platform_id: string;
  purchase_price: string;
  purchased: string;
  currency: CurrencyType | '';
  due_date: string;
};

type PropsT = {
  errors: Partial<Record<keyof ClaimT, ErrorT>>;
  currentClaim: ClaimT;
  currentIndex: number;
  setErrors: any;
  setParams: any;
  isHideDeleteButton: boolean;
};

const Claim: FC<PropsT> = (props) => {
  const { currentClaim, currentIndex, errors, setErrors, setParams, isHideDeleteButton } = props;
  const { availableAdvanceData } = usePurchaseRequest();
  const { t } = useTranslation();
  const platformData = availableAdvanceData.platforms.find(
    ({ platform_id }) => platform_id === currentClaim.platform_id,
  );

  const changeClaim = (value: string, key: keyof ClaimT) => {
    setErrors((prevErrors) => {
      const claimErrors = prevErrors.available_advance;
      claimErrors[currentIndex] = { [key]: !value ? { message: 'Required', isError: true } : undefined };

      return { ...prevErrors, available_advance: claimErrors };
    });
    setParams((prevData) => ({
      ...prevData,
      purchase_price:
        key === 'purchase_price'
          ? String(
              prevData.available_advance.reduce(
                (acc, claim, index) =>
                  index === currentIndex ? acc + Number(value) * 100 : acc + Number(claim.purchase_price || 0) * 100,
                0,
              ) / 100,
            )
          : prevData.purchase_price,
      available_advance: prevData.available_advance.map((claim, index) =>
        currentIndex === index
          ? {
              ...claim,
              [key]: value,
            }
          : claim,
      ),
    }));
  };

  const deleteClaim = () => {
    setParams((params) => {
      const clone = [...params.available_advance];
      clone.splice(currentIndex, 1);

      return { ...params, available_advance: clone };
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumn}>
        <SelectKit
          label={t('forms.purchaseRequest.createForm.distributionPlatform')}
          options={availableAdvanceData.platforms}
          val={platformData}
          keyObj="platform"
          changeSelectItem={(_, data) => changeClaim(data.platform_id, 'platform_id')}
          isError={errors?.platform_id?.isError}
          helperText={errors?.platform_id?.message}
          wrapProps={{ height: 60 }}
          startAdornment={
            platformData?.platform_logo ? (
              <img className={styles.inputImg} src={platformData.platform_logo} alt={platformData.platform} />
            ) : undefined
          }
        />
        <AmountKit
          isNeedHandlePaste
          label={t('forms.purchaseRequest.createForm.purchased')}
          onChange={(e) => changeClaim(e.target.value, 'purchased')}
          amount={currentClaim.purchased}
          currency={currentClaim.currency || null}
          wrapProps={{ height: 60 }}
          isError={errors?.purchased?.isError}
          helperText={errors?.purchased?.message}
        />
        <DateInput
          isNotErrorLocal
          date={currentClaim.due_date}
          labelKey="forms.purchaseRequest.createForm.dueDate"
          onChangeDateHandler={({ target: { value } }) => changeClaim(value, 'due_date')}
          stylingLabel={{ paddingLeft: 8 }}
          inputStyle={{ paddingLeft: 17 }}
          commonStyle={{ height: 60 }}
          isError={errors?.due_date?.isError}
          errorText={errors?.due_date?.message}
        />
        <AmountKit
          isNeedHandlePaste
          label={t('forms.purchaseRequest.createForm.purchasePrice')}
          onChange={(e) => changeClaim(e.target.value, 'purchase_price')}
          amount={currentClaim.purchase_price}
          currency={currentClaim.currency || null}
          wrapProps={{ height: 60 }}
          isError={errors?.purchase_price?.isError}
          helperText={errors?.purchase_price?.message}
        />
      </div>
      {!isHideDeleteButton && (
        <Button gutters={[10]} className={styles.deleteButton} onClick={() => deleteClaim()}>
          <img src={basketSvg} alt="basket" onClick={() => {}} />
        </Button>
      )}
    </div>
  );
};

export default Claim;

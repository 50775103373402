import React, { FC } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

type ContentWrapperPropsType = {
  style?: React.CSSProperties;
  maxWidthContent?: boolean;
  fullHeight?: boolean;
  customClass?: string;
};

const ContentWrapper: FC<ContentWrapperPropsType> = ({ children, style, customClass, maxWidthContent, fullHeight }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.container, customClass, {
        [classes.maxWidthContent]: maxWidthContent,
        [classes.fullHeight]: fullHeight,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

const useStyles = createUseStyles({
  container: {
    paddingRight: '40px',
    paddingLeft: '40px',
    paddingBottom: '24px',
  },
  maxWidthContent: {
    maxWidth: '1294px',
    margin: '0 auto',
  },
  fullHeight: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto minmax(100px, 1fr)',
    height: 'calc(100% - 40px)',
  },
});

export default ContentWrapper;

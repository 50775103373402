import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface accrualReportObjType {
  date_for?: string;
  currency?: string;
}

export interface accrualReportType {
  params?: accrualReportObjType;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
}

export default ({ params = {}, errorCallback, resultKey = 'accrualReportData', successCallback }: accrualReportType) =>
  requestAsync({
    url: endpoints.getAccrualReportUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAccrualReportUrl(params),
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PurchaseRequestDataType, PurchaseRequestEnum } from 'hooks/api/usePurchaseRequest';
import ModalConfirm from 'components/ModalConfirm';

import ClassicView from './ClassicView';
import styles from './styles.module.scss';

type PropsT = {
  type: PurchaseRequestEnum;
  purchaseRequestData: PurchaseRequestDataType;
  title?: string;
};

const General: FC<PropsT> = ({ purchaseRequestData, title, type }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const closeFC = useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div style={{ position: 'absolute', top: -83, right: 0 }}>
        <ModalConfirm
          closeFc={closeFC}
          open={isActive}
          wrap={{
            width: 444,
            height: 199,
          }}
          rightCallback={() => {
            closeFC();
          }}
          leftButtonText={t('global.deleteD')}
          rightButtonText={t('global.cancelC')}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title={[t('global.areYouSureDoYouWantDelete'), title]}
        />
      </div>
      {PurchaseRequestEnum.CLASSIC !== type && <ClassicView purchaseRequestData={purchaseRequestData} />}
    </div>
  );
};

export default General;

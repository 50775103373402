import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { ICompanyParamsType } from 'types/CompanyTypes';

interface companiesDataType extends ICompanyParamsType {
  errorCallback?: Function;
  resultKey?: string;
}

const getCompaniesV2 = ({
  showDeleted = true,
  page = 0,
  limit = 25,
  sortedBy,
  order,
  errorCallback,
  resultKey = 'companiesTableDataV2',
}: companiesDataType = {}) =>
  requestAsync({
    url: endpoints.getCompaniesV2Url({ showDeleted, page, limit, sortedBy, order }),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompaniesV2Url({ showDeleted, page, limit, sortedBy, order }),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

export default getCompaniesV2;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';

import updateUtils from 'actions/updateUtils';
import { CompanyType } from 'types/CompanyTypes';
import { formatDateIsoShort } from 'helpers/formatters';
import { getDateSixMonthsAgo } from 'helpers/transactionReportTableHelpers';
import getTransactions from 'queries/transaction/getTransactions';
import getTransactionsForAdjusting from 'queries/transaction/getTransactionsForAdjusting';
import getTransactionsV2 from 'queries/transaction/getTransactionsV2';
import getTransaction from 'queries/transaction/getTransaction';
import getEntries from 'queries/transaction/getEntries';
import getTransactionReports from 'queries/transaction/getTransactionReports';
import getDetailedTransactionReportTargets from 'queries/transaction/getDetailedTransactionReportTargets';
import getDetailedTransactionReports from 'queries/transaction/getDetailedTransactionReports';
import getTransactionTargetsReports from 'queries/transaction/getTransactionTargetsReports';
import getPivotTransactionReports from 'queries/transaction/getPivotTransactionReports';
import createSubTransaction, { subTransactionDataType } from 'queries/transaction/createSubTransaction';
import createTransaction from 'queries/transaction/createTrasaction';
import editTransaction from 'queries/transaction/editTrasaction';
import deleteTransaction from 'queries/transaction/deleteTransaction';
import createOrderAmount from 'queries/transaction/createOrderAmount';
import getListTransactions, { listTransactionsDataType } from 'queries/transaction/getListTransactions';
import { initialObjTransactionsType, initialObjTransactions } from 'components/tables/TransactionsTable/ViewsForm/data';
import createListTransactions, { createListTransactionsType } from 'queries/transaction/createListTransactions';
import getTransactionsById, { getTransactionsByIdDataType } from 'queries/transaction/getTransactionsById';
import setTransactions, { setTransactionsDataType } from 'queries/transaction/setTransactions';
import getTransactionsRelatedById, {
  getTransactionsRelatedByIdType,
} from 'queries/transaction/getTransactionsRelatedById';
import { CurrencyType } from 'components/uiKit/InputKit';
import getCurrencyRate, { getCurrencyRateType } from 'queries/transaction/getCurrencyRate';
import setSyncTr, { setSyncTrType } from 'queries/transaction/setSyncTr';
import deleteTransactionsById, { deleteTransactionsByIdType } from 'queries/transaction/deleteTransactionsById';
import getFactorDetails, { factorDetailsType } from 'queries/transaction/getFactorDetails';
import getArchiveTransactions, { getArchiveTransactionsType } from 'queries/transaction/getArchiveTransactions';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';
import { getInitialDate } from './useAmount';

interface ITableParamsType {
  companyId: string;
  company: CompanyType[];
}

export interface ITransactionTableParams extends ITableParamsType {
  currency: string;
  date_from: string;
  date_to: string;
  show_deleted: boolean;
  tx_type?: string;
  page: number;
  limit: number;
  order_id: string;
  status: string;
  contract_number?: string;
  urlFiltersIsSet?: boolean;
}

interface ITransactionReportTableParams extends ITableParamsType {
  currency: string;
  dateFrom: string;
  dateTo: string;
}

interface IDetailedAndTargetTransactionReportTableParams extends ITableParamsType {
  currency: string;
}
interface IPivotTransactionReportsTableParams {
  report_date: string;
  company_id: string;
  company: CompanyType[];
}

const dtFrom = new Date();
dtFrom.getMonth();

export const initialTransactionTableParams: ITransactionTableParams = {
  companyId: '',
  date_from: '',
  date_to: '',
  currency: '',
  show_deleted: false,
  company: [],
  tx_type: '',
  page: 0,
  limit: 50,
  order_id: '',
  contract_number: '',
  status: '',
  urlFiltersIsSet: false,
};

const initialTransactionReportTableParams: ITransactionReportTableParams = {
  companyId: '',
  company: [],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dateFrom: getDateSixMonthsAgo(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dateTo: formatDateIsoShort(new Date()),
  currency: '',
};

const initialDetailedAndTargetTransactionReportTableParams: IDetailedAndTargetTransactionReportTableParams = {
  companyId: '',
  company: [],
  currency: '',
};

const initialPivotTransactionReportsTableParams: IPivotTransactionReportsTableParams = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  report_date: getInitialDate('last'),
  company_id: '',
  company: [],
};

export interface SenderTransactionType {
  account_id?: any;
  company_id: string;
  company_name: string;
  currency: string;
  bank_name: string;
  swift: string;
  account: string;
  correspondent_bank_name?: any;
  correspondent_swift?: any;
  correspondent_account?: any;
  related_bank_account: {
    bank_name: string;
  };
}

export interface RecipientTransactionsType {
  account_id?: any;
  company_id: string;
  company_name: string;
  currency: string;
  bank_name: string;
  swift: string;
  account: string;
  correspondent_bank_name?: any;
  correspondent_swift?: any;
  correspondent_account?: any;
  related_bank_account: {
    bank_name: string;
  };
}

export interface AmountTransactionsType {
  currency?: number;
  amount?: number;
  amount_buy?: number;
  amount_sell?: number;
  commission?: number;
  currency_buy?: string;
  currency_sell?: string;
  rate?: number;
  rate_ecb?: number;
}

export interface ItemTransactionsType {
  id: string;
  type: string;
  status: string;
  factoring_company_id?: string;
  factoring_company_name?: string;
  details: string;
  comment: string;
  value_datetime: string;
  transaction_date?: null | string;
  parent_id?: string;
  related: any[];
  created: string;
  modified: Date;
  sender: SenderTransactionType;
  recipient: RecipientTransactionsType;
  amount: AmountTransactionsType;
  reference: string;
  payment_details: string;
  document_id?: string;
  document_number?: string;
  document_type?: string;
  category?: string;
  category_dsc?: string;
  factoring_order_id?: string;
  factoring_order_number?: string;
}

export interface TransactionData {
  items: ItemTransactionsType[];
  total: number;
  page: number;
  size: number;
}

export interface TargetSubTransactionType {
  order_id: string;
  target_type: string;
  order_name: string;
  amount: string;
  tx_currency_amount: string;
  order_currency: CurrencyType;
  rate: string;
}
export interface SubTransactionDataType {
  date: string;
  company_id: string;
  currency: string;
  amount: string;
  type: string;
  targets: TargetSubTransactionType[];
}

interface currencyRateType {
  rate: number;
  currency: CurrencyType;
}

export interface BankAccountType {
  bank_name: string;
  swift: string;
}

export interface FactoDetailsObjType {
  trading_name: string;
  legal_name: string;
  company_registration: string;
  tax_number: string;
  country: string;
  zip: string;
  city: string;
  state: string;
  address: string;
  website: string;
  company_phone_number: string;
  company_email: string;
  signer_full_name: string;
  signer_title: string;
  representative_full_name: string;
  representative_title: string;
  representative_email: string;
  bank_name_eur: string;
  swift_eur: string;
  iban_eur: string;
  bank_name_usd: string;
  swift_usd: string;
  iban_usd: string;
  reference_usd: string;
  correspondent_bank_usd: string;
  correspondent_swift_usd: string;
  correspondent_account_usd: string;
  bank_accounts: BankAccountType[];
  factor_id: string;
  emission_usd: string;
  revenue_usd: string;
  conv_in_usd: string;
  conv_out_usd: string;
  capitalization_usd: string;
  emission_eur: string;
  revenue_eur: string;
  conv_in_eur: string;
  conv_out_eur: string;
  capitalization_eur: string;
}

export interface useTransactionType {
  getListTransactions?: (data: listTransactionsDataType) => void;
  listTransactionQuery: any;
  archiveTransactionsQuery: any;
  listTransactions?: TransactionData;
  transactionsFormData?: initialObjTransactionsType;
  setTransactionFormData?: (data: initialObjTransactionsType) => void;
  createListTransactions: (data: createListTransactionsType) => void;
  getTransactionsById?: (data: getTransactionsByIdDataType) => void;
  transactionsDataById?: ItemTransactionsType;
  setTransactions?: (data: setTransactionsDataType) => void;
  getTransactionsRelatedById?: (data: getTransactionsRelatedByIdType) => void;
  transactionsRelatedById?: ItemTransactionsType[];
  createSubTransaction?: (data: subTransactionDataType) => void;
  subTransactionData?: SubTransactionDataType;
  getCurrencyRate?: (data: getCurrencyRateType) => void;
  setSyncTr: (data?: setSyncTrType) => void;
  currencyRate?: currencyRateType;
  obj?: string;
  deleteTransactionsById: (data: deleteTransactionsByIdType) => void;
  getFactorDetails?: (data: factorDetailsType) => void;
  factorDetails?: FactoDetailsObjType;
  getArchiveTransactions?: (data: getArchiveTransactionsType) => void;
}

const useTransaction = () => {
  const selector = useCallback(
    (state) => ({
      factorDetails: objectSelector(state, 'factorDetails'),
      currencyRate: objectSelector(state, 'currencyRate'),
      obj: objectSelector(state, 'obj'),
      transactionsRelatedById: arraySelector(state, 'transactionsDataRelatedById'),
      transactionsDataById: objectSelector(state, 'TransactionsDataById'),
      transactionsFormData: objectSelector(state, 'transactionFormData'),
      listTransactions: objectSelector(state, 'listTransactionData'),
      listTransactionQuery: state.queries.listTransactionQuery || {},
      archiveTransactionsQuery: state.queries.archiveTransactionsQuery || {},
      transactionsData: arraySelector(state, 'transactionsData'),
      transactionsForAdjustingData: arraySelector(state, 'transactionsForAdjustingData'),
      transactionsDataV2: objectSelector(state, 'transactionsDataV2'),
      transactionData: objectSelector(state, 'transactionData'),
      entriesData: objectSelector(state, 'entriesData'),
      transactionReportsData: arraySelector(state, 'transactionReportsData'),
      detailedTransactionReportTargetsData: objectSelector(state, 'detailedTransactionReportTargetsData'),
      detailedTransactionReportsData: arraySelector(state, 'detailedTransactionReportsData'),
      transactionTargetsReportsData: arraySelector(state, 'transactionTargetsReportsData'),
      subTransactionData: objectSelector(state, 'subTransactionData'),
      creatTransactionData: objectSelector(state, 'creatTransactionData'),
      pivotTransactionReportsData: arraySelector(state, 'pivotTransactionReportsData'),
      exportPivotTransactionReportsData: objectSelector(state, 'exportPivotTransactionReportsData'),
      orderAmountData: objectSelector(state, 'orderAmountData'),
      transactionTableParams: utilsSelector(state, 'transactionTableParams', initialTransactionTableParams),
      transactionReportTableParams: utilsSelector(
        state,
        'transactionReportTableParams',
        initialTransactionReportTableParams,
      ),
      detailedTransactionReportTableParams: utilsSelector(
        state,
        'detailedTransactionReportTableParams',
        initialDetailedAndTargetTransactionReportTableParams,
      ),
      transactionTargetsReportTableParams: utilsSelector(
        state,
        'transactionTargetsReportTableParams',
        initialDetailedAndTargetTransactionReportTableParams,
      ),
      pivotTransactionReportsTableParams: utilsSelector(
        state,
        'pivotTransactionReportsTableParams',
        initialPivotTransactionReportsTableParams,
      ),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getArchiveTransactions,
      deleteTransactionsById,
      getFactorDetails,
      getTransactionsRelatedById,
      getTransactions,
      getTransactionsById,
      getTransactionsForAdjusting,
      getTransactionsV2,
      getTransaction,
      getEntries,
      getTransactionReports,
      getDetailedTransactionReportTargets,
      getDetailedTransactionReports,
      getTransactionTargetsReports,
      getPivotTransactionReports,
      createSubTransaction,
      createTransaction,
      editTransaction,
      deleteTransaction,
      createOrderAmount,
      getListTransactions,
      createListTransactions,
      setTransactions,
      getCurrencyRate,
      setSyncTr,
    }),
    [],
  );

  const transactionTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setTransactionTableParams: (transactionTableParams: ITransactionTableParams) =>
            updateUtils({
              transactionTableParams,
            }),
          setTransactionReportTableParams: (transactionReportTableParams: ITransactionReportTableParams) =>
            updateUtils({
              transactionReportTableParams,
            }),
          setDetailedTransactionReportTableParams: (
            detailedTransactionReportTableParams: IDetailedAndTargetTransactionReportTableParams,
          ) =>
            updateUtils({
              detailedTransactionReportTableParams,
            }),
          setTransactionTargetsReportTableParams: (
            transactionTargetsReportTableParams: IDetailedAndTargetTransactionReportTableParams,
          ) =>
            updateUtils({
              transactionTargetsReportTableParams,
            }),
          setPivotTransactionReportsTableParams: (
            pivotTransactionReportsTableParams: IPivotTransactionReportsTableParams,
          ) =>
            updateUtils({
              pivotTransactionReportsTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearSubTransactionData: () =>
            updateEntities({
              subTransactionData: () => ({}),
            }),
          setTransactionFormData: (obj) =>
            updateEntities({
              transactionFormData: (prev: Object = initialObjTransactions) => ({ ...prev, ...obj }),
            }),
          clearTransactionData: () =>
            updateEntities({
              transactionData: () => ({}),
            }),
          clearTransactionDataV2: () =>
            updateEntities({
              transactionsDataV2: () => ({}),
            }),
          clearPivotTransactionReportsData: () =>
            updateEntities({
              pivotTransactionReportsData: () => [],
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...actionWithoutIsFetching,
    ...transactionTableActions,
  };
};

export default useTransaction;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ChangeEvent, Children, Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import urls from 'constants/urls';
import minusIcon from 'assets/img/minus-black.svg';
import plusIcon from 'assets/img/plus-black.svg';
import useAlert from 'hooks/api/useAlert';
import useCompany from 'hooks/api/useCompany';
import usePurchaseRequest, { AvailableAdvanceType } from 'hooks/api/usePurchaseRequest';
import useActivationOrder, { useActivationOrderType } from 'hooks/api/useActivationOrder';
import Button from 'components/uiKit/Button';
import Accordion from 'components/uiKit/Accordion';
import DateInput from 'components/inputs/DateInput';
import { InputKit } from 'components/uiKit/InputKit';
import { SelectKit } from 'components/uiKit/SelectKit';
import { AmountKit } from 'components/uiKit/AmountKit';
import CompanySelect from 'components/CompanySelect';

import { checkNumber, DataErrorsT, DataT, VariantEnum } from '../data';

import Claim from './Claim';
import styles from './styles.module.scss';

type PropsT = {
  setFormData: Dispatch<SetStateAction<DataT>>;
  formData: DataT;
  errors: Partial<DataErrorsT>;
  setErrors: Dispatch<SetStateAction<DataErrorsT>>;
  checkRequiredError: (key: keyof DataT, value: string) => void;
  variant: VariantEnum;
  isSubmittingForm: boolean;
};

const View: FC<PropsT> = ({
  setFormData,
  formData,
  checkRequiredError,
  errors,
  setErrors,
  variant,
  isSubmittingForm,
}) => {
  const { t } = useTranslation();
  const { purchaseRequestId } = useParams();
  const {
    companiesData: { companiesData = [] },
  } = useCompany();
  const {
    availableReceivablesList: { available_currencies, orders },
  }: useActivationOrderType = useActivationOrder();
  const { availableAdvanceData, getAvailableAdvance, getNextNumber } = usePurchaseRequest();
  const isEdit = variant === VariantEnum.EDIT;
  const history = useHistory();
  const { setAlert } = useAlert();

  const setValueByKey = (key: keyof DataT, value: string) => {
    checkRequiredError(key, value);
    const newParams =
      key === 'company_id'
        ? { ...formData, [key]: value || '', request_number: '', order_id: '' }
        : { ...formData, [key]: value };
    setFormData(newParams);
  };

  const handleRequestNumberChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setValueByKey('request_number', event.target.value);
    const resp = await checkNumber({
      company_id: formData.company_id,
      pr_number: event.target.value,
    });

    if (!resp.is_unique) {
      setAlert({
        type: 'error',
        message: `${event.target.value}  || 'error'`,
      });
    }
  };

  useEffect(() => {
    if (formData.company_id && formData.currency && formData.order_id) {
      getAvailableAdvance({
        params: { company_id: formData.company_id, currency: formData.currency, order_id: formData.order_id },
        successCallback: (body: AvailableAdvanceType) => {
          if (variant === VariantEnum.CREATE) {
            setFormData((prevParams) => ({
              ...prevParams,
              purchase_price: '',
              maximum_price: body.available_receivable,
              available_advance: body.platforms.map(({ platform_id }) => ({
                platform_id,
                purchase_price: '',
                purchased: '',
                due_date: '',
                currency: prevParams.currency,
              })),
            }));
          }
        },
      });

      if (!isEdit) {
        getNextNumber({
          params: { company_id: formData.company_id },
          successCallback: ({ next_pr_number }) => {
            setValueByKey('request_number', next_pr_number);
          },
        });
      }
    }
  }, [formData.company_id, formData.currency, formData.order_id]);

  return (
    <>
      <div className={styles.initialForm}>
        <div>
          <CompanySelect
            label={t('forms.purchaseRequest.createForm.client')}
            disabled={isEdit}
            onChange={(id) => {
              setValueByKey('company_id', id);
            }}
            status={errors.company_id?.isError ? 'error' : undefined}
            value={formData.company_id || undefined}
            size="large"
          />
        </div>
        <div className={styles.row}>
          <DateInput
            isNotErrorLocal
            date={formData.request_date}
            labelKey="forms.purchaseRequest.createForm.requestDate"
            onChangeDateHandler={({ target: { value } }) => {
              setValueByKey('request_date', value);
            }}
            disabled={isEdit}
            stylingLabel={{ paddingLeft: 8, opacity: isEdit ? 0.4 : 1 }}
            inputStyle={{
              paddingLeft: 17,
              backgroundColor: isEdit ? '#f7f7f7' : '#ffffff',
              pointerEvents: isEdit ? 'none' : 'initial',
              border: isEdit ? '1px solid #6B7B83' : undefined,
              color: '#29292C',
              opacity: isEdit ? 0.4 : 1,
            }}
            commonStyle={{ height: 60 }}
            isError={errors.request_date?.isError}
            errorText={errors.request_date?.message}
          />
          <SelectKit
            label={t('forms.purchaseRequest.createForm.selectAO')}
            options={orders}
            val={(orders || []).find(({ id }) => formData.order_id === id)}
            changeSelectItem={(_, data) => {
              setValueByKey('order_id', data.id);
            }}
            keyObj="name"
            isError={errors.order_id?.isError}
            helperText={errors.order_id?.message}
            wrapProps={{ height: 60 }}
            disabled={isEdit || !formData.company_id}
          />
          <SelectKit
            label={t('forms.purchaseRequest.createForm.currency')}
            options={available_currencies?.map((value) => ({ name: value?.toUpperCase(), value }))}
            keyObj="name"
            val={formData.currency?.toUpperCase()}
            changeSelectItem={(_, data) => {
              setValueByKey('currency', data.value);
            }}
            isError={errors.currency?.isError}
            helperText={errors.currency?.message}
            wrapProps={{ height: 60 }}
            disabled={isEdit || !formData.company_id}
          />
        </div>
      </div>
      {!isEmpty(availableAdvanceData) && (
        <>
          <div className={styles.additionalForm}>
            <div className={styles.twoColumn}>
              <InputKit
                label={t('forms.purchaseRequest.createForm.requestNumber')}
                onChange={handleRequestNumberChange}
                value={formData.request_number}
                wrapProps={{ height: 60 }}
                isError={errors.request_number?.isError}
                helperText={errors.request_number?.message}
                disabled={isEdit}
              />
              <AmountKit
                disabled
                isNeedHandlePaste
                label={t('forms.purchaseRequest.createForm.purchasePrice')}
                onChange={(e) => setValueByKey('purchase_price', e.target.value)}
                currency={formData.currency || null}
                amount={formData.purchase_price || ''}
                isError={errors.purchase_price?.isError}
                helperText={errors.purchase_price?.message}
                wrapProps={{ height: 60 }}
              />
            </div>
          </div>
          <Accordion
            initialState
            title={t('forms.purchaseRequest.createForm.claims')}
            closeImg={<img src={minusIcon} alt="close rejection reason" />}
            openImg={<img src={plusIcon} alt="open rejection reason" />}
            className={styles.accordionButton}
          >
            <div className={styles.accordionContent}>
              {Children.toArray(
                (formData.available_advance || []).map((claim, index) => (
                  <Claim
                    isHideDeleteButton={formData.available_advance.length === 1}
                    errors={errors.available_advance[index]}
                    setErrors={setErrors}
                    setParams={setFormData}
                    currentClaim={claim}
                    currentIndex={index}
                  />
                )),
              )}
            </div>
            {availableAdvanceData.platforms?.length !== formData.available_advance?.length && (
              <Button
                style={{ height: 40, marginTop: 15 }}
                startAdornment={<img src={plusIcon} alt="plus" />}
                onClick={() =>
                  setFormData((prevParams) => ({
                    ...prevParams,
                    available_advance: [
                      ...prevParams.available_advance,
                      {
                        platform_id: '',
                        purchase_price: '',
                        purchased: '',
                        due_date: '',
                        currency: prevParams.currency,
                      },
                    ],
                  }))
                }
              >
                Add Claim
              </Button>
            )}
          </Accordion>
        </>
      )}
      <div className={styles.actionButtons}>
        <Button type="submit" gutters={[8, 20]} variant="fill" loading={isSubmittingForm}>
          {t(`forms.purchaseRequest.createForm.${!isEdit ? 'createSubmit' : 'editSubmit'}`)}
        </Button>
        <Button
          type="button"
          onClick={() => {
            history.push(`${urls.getPurchaseRequestList()}/${isEdit ? purchaseRequestId : ''}`);
          }}
          gutters={[8, 20]}
          variant="outline"
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default View;

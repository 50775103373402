import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import updateUtils from 'actions/updateUtils';
import getCompanies, { companiesDataType } from 'queries/company/getCompanies';
import getCompaniesV2 from 'queries/company/getCompaniesV2';
import getCompaniesV3 from 'queries/company/getCompaniesV3';
import getCompanyList, { ListCompanyDataType } from 'queries/company/getCompanyList';
import getCompany, { getCompanyType } from 'queries/company/getCompany';
import editGroupCompany from 'queries/company/editGroupCompany';
import createCompany, { CreateCompanyDataType } from 'queries/company/createCompany';
import editCompany, { editCompanyDataType } from 'queries/company/editCompany';
import deleteCompany from 'queries/company/deleteCompany';
import getAOByCompany, { clientAoDataType } from 'queries/company/getAOByCompany';
import getCountries from 'queries/company/getCountries';
import getPurchaseClaims, { PurchaseClaimsParams } from 'queries/company/getPurchaseClaims';
import getPurchaseNowPlatforms, { PurchaseNowPlatforms } from 'queries/company/getPurchaseNowPlatforms';
import addAOFile from 'queries/company/addAOFile';
import { TOrder } from 'types/tableTypes';
import { EGroups } from 'types/CompanyTypes';
import { initialOrderCardsData, switchListInitial } from 'components/forms/ActivationOrderForm/CardsAo/data';
import { StatusEnumTitle } from 'components/common/Status';
import getPdsAccounts, { PdsAccountsParams } from 'queries/company/getPdsAccounts';
import getNeedRepaymentPenalty, { getNeedRepaymentPenaltyParamsType } from 'queries/company/getNeedRepaymentPenalty';
import getRevenueByDays, { RevenueByDaysParamsType } from 'queries/company/getRevenueByDays';
import uploadRevenueFile, { uploadRevenueFileParamsType } from 'queries/company/uploadRevenueFile';
import createTransactionInToClaim, { createTransactionInToClaimType } from 'queries/company/createTransactionInToClaim';
import getStatusRevenueUploadFile, {
  statusRevenueUploadFileParamsType,
} from 'queries/company/getStatusRevenueUploadFile';
import getAccountsByIdCompany, { getAccountsByIdCompanyType } from 'queries/company/getAccountsByIdCompany';
import useActionsWithFetchingState from '../useActionsWithFetchingState';
import { arraySelector, objectSelector, utilsSelector, getSimpleResult } from './selectors';
import { AoTypeEnum } from './useActivationOrder';
import { RevenueSourceSyncStatusEnum } from 'types/RevenueSources';

export type CompanyDataT = {
  id: string;
  companyName: string;
  balances: { amount: string; currency: string }[];
  vat: string;
  company_info: {
    company: {
      groups: string[];
      signature_full_name: string;
      signature_position: string;
      type: string;
      details?: {
        operating_currency?: string;
      };
      contacts?: {
        type: 'signer' | 'contact';
        email: string;
        full_name: string;
        position: string;
      }[];
    };
    company_data: {
      correspondent_swift_usd?: string;
      company_phone_number: string | number;
      signature_full_name: string;
      bank_eur: string;
      bank_usd: string;
      address: string;
      address2: string;
      country: string;
      tax_number: string;
      website: string;
      director_full_name: string;
      representative_email: string;
      representative_full_name: string;
      representative_phone_number: string;
      representative_title: string;
      company_email: string;
      company_registration: string;
      legal_name: string;
      zip: string;
      city: string;
      contact_persons: {
        email: string;
        full_name: string;
        job_position: string;
        contact_phone: string;
      }[];
      swift_eur: string;
      swift_usd: string;
      iban_eur: string;
      iban_usd: string;
      correspondent_bank_usd: string;
      factor_account_eur: string;
      factor_account_usd: string;
      correspondent_account_usd: string;
      signature_position: string;
      signer_email: string;
    };
  };
};

enum companyLabels {
  id = 'id',
  company_name = 'company_name',
  status = 'status',
  groups = 'groups',
}

type companyTableParamsType = {
  type: string;
  status: string;
  companyName: string;
  group: EGroups | '';
  page: number;
  limit: number;
  sortedBy?: companyLabels;
  order: TOrder;
};

interface companyType {
  active: boolean;
  app_store_email: null | string;
  app_store_email_status: string;
  app_store_password: null | string;
  created: Date;
  groups: ['applaud'];
  id: string;
  is_custom_payment_day: boolean;
  last_store_transaction_date: null | string;
  name: string;
  payment_day: number;
  signature_full_name: null | string;
  signature_position: null | string;
  status: string;
  updated: Date;
}

interface CompanyDataType {
  address: string;
  address2: string;
  appstore_email: null | string;
  bank_eur: null | string;
  bank_usd: null | string;
  city: string;
  company_registration: string;
  correspondent_account_usd: null | string;
  correspondent_bank_usd: null | string;
  country: string;
  director_full_name: string;
  director_title: string;
  iban_eur: null | string;
  iban_subaccount: string;
  iban_usd: null | string;
  invoices_count: number;
  legal_name: string;
  representative_email: string;
  representative_full_name: string;
  representative_phone_number: string;
  representative_title: string;
  swift_eur: null | string;
  swift_usd: null | string;
  tax_number: string;
  trading_name: string;
  website: string;
  zip: string;
}

export interface CommonCompanyDataType {
  accounts: [];
  active: boolean;
  app_store_email: null | string;
  app_store_email_status: string;
  apple_store_status: null | string;
  apps: [];
  appsalesummary: [];
  balances: [];
  company_info: {
    company: companyType;
    company_data: CompanyDataType;
    created: Date;
    id: string;
    updated: string;
  };
  company_name: string;
  deletion: { can_be_deleted: boolean; reason: null | string };
  id: string;
  is_custom_payment_day: boolean;
  last_store_transaction_date: null | string;
  payment_day: number;
  signature_full_name: null | string;
  signature_position: null | string;
  status: string;
  vat: string;
}

export const initialCompanyTableParams: companyTableParamsType = {
  type: '',
  status: '',
  companyName: '',
  group: '',
  page: 0,
  limit: 25,
  order: 'asc',
};
export interface companiesTableDataV3RecordsType {
  active: boolean;
  company_name: string | null;
  created: string | null;
  groups: string[];
  id: string;
  payment_day: number;
  status: string;
  user_id: null | string;
}
export interface companiesTableDataV3Type {
  limit: number;
  page: number;
  records: companiesTableDataV3RecordsType[];
  total: number;
}

interface namesCompanyType {
  name: string;
  id: string;
}

export interface AoCompanyDataType {
  active: boolean;
  amount: string;
  contract_date: null | string;
  contract_number: null | string;
  currency: string;
  files: { name: string; id: string; size: string }[];
  file_id: string;
  id: string;
  name: string;
  purchase_date: string;
  ao_type: AoTypeEnum;
  sign_date: Date | null;
  status: StatusEnumTitle;
}

export interface ClaimsType {
  available_receivables: number;
  currency: string;
  expected_date: string;
  id: string;
  logo: string;
  name: string;
  outstanding: null | string;
  pr_status: string;
  purchase_price: string;
  purchased: string;
  request_number?: string;
}

export interface ItemPdsAccountType {
  storeId: string;
  companyId: string;
  credentials: object;
  currency: string;
  activationDate: string;
  lastSync: Date;
  syncStatus: RevenueSourceSyncStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  storeName: string;
}

export interface listPdsAccountType {
  items: ItemPdsAccountType[];
  total: number;
  page: number;
  size: number;
}

export interface listRevenueByDaysType {
  proceeds: [
    {
      date: Date;
      sum: number;
    },
  ];
  currency: string;
}

interface USD {
  id: string;
  name: string;
  logo: string;
  penalty: string;
}
interface EUR {
  id: string;
  name: string;
  logo: string;
  penalty: string;
}
interface List {
  USD: USD[];
  EUR: EUR[];
}

interface Total {
  currency: string;
  amount: string;
}

export interface needRepaymentPenaltyDataType {
  list: List;
  total: Total[];
}

export interface ErrorsStatusRevenueUploadFileType {
  'Account not exist': number[];
}
export interface statusRevenueUploadFileDataType {
  errorsByTypes: ErrorsStatusRevenueUploadFileType;
  taskId: string;
  state: string;
  companyId: string;
  fileName: string;
  rowsTotal: number;
  rowsProcessed: number;
  rowsSuccessful: number;
  rowsErroneous: number;
}

export interface AccountsByIdCompanyType {
  account: string;
  balance: number;
  balance_date: string;
  company_id: string;
  company_name: string;
  created: string;
  currency: string;
  id: string;
  modified: string;
  type: string;
}

export interface useCompanyType {
  companiesData?: {
    names: namesCompanyType[];
    companiesData: companiesTableDataV3RecordsType[];
  };
  getCompanies?: (data: companiesDataType) => void;
  getCompanyList?: (data: ListCompanyDataType) => void;
  aoFormData?: typeof initialOrderCardsData;
  setAOFormData?: (data: typeof initialOrderCardsData) => void;
  listSwitch?: typeof switchListInitial;
  setIsSwitchList?: (data: typeof switchListInitial) => void;
  createCompany?: (data: CreateCompanyDataType) => void;
  editCompany?: (data: editCompanyDataType) => void;
  getCompany?: (data: getCompanyType) => void;
  companyData?: CommonCompanyDataType;
  getAOByCompany: (data: clientAoDataType) => void;
  aOByCompanyData: AoCompanyDataType[];
  addAOFile?: any;
  purchaseClaims?: ClaimsType[];
  getPurchaseClaims?: (data?: PurchaseClaimsParams) => void;
  getPurchaseNowPlatforms?: (data?: PurchaseNowPlatforms) => void;
  getPdsAccounts?: (data?: PdsAccountsParams) => void;
  listPdsAccounts?: listPdsAccountType;
  listPdsRevenueByDays?: listRevenueByDaysType;
  getRevenueByDays?: (data: RevenueByDaysParamsType) => void;
  uploadRevenueFile?: (data: uploadRevenueFileParamsType) => void;
  createTransactionInToClaim?: (data: createTransactionInToClaimType) => void;
  getNeedRepaymentPenalty?: (data: getNeedRepaymentPenaltyParamsType) => void;
  needRepaymentPenaltyData?: needRepaymentPenaltyDataType;
  getStatusRevenueUploadFile?: (data: statusRevenueUploadFileParamsType) => void;
  statusRevenueUploadFileData?: statusRevenueUploadFileDataType;
  getAccountsByIdCompany: (data: getAccountsByIdCompanyType) => void;
  accountsByIdCompany?: AccountsByIdCompanyType[];
}

const useCompany = () => {
  const selector = useCallback(
    (state) => ({
      accountsByIdCompany: arraySelector(state, 'getAccountsByIdCompany'),
      listPdsRevenueByDays: objectSelector(state, 'revenueByDays'),
      needRepaymentPenaltyData: objectSelector(state, 'needRepaymentPenalty'),
      statusRevenueUploadFileData: objectSelector(state, 'statusRevenueUploadFileData'),
      listPdsAccounts: objectSelector(state, 'pdsAccounts'),
      listSwitch: utilsSelector(state, 'listSwitch', switchListInitial),
      aoFormData: utilsSelector(state, 'aoFormData', initialOrderCardsData),
      purchaseClaims: arraySelector(state, 'purchaseDataClaims'),
      companiesData: objectSelector(state, 'companiesData'),
      companiesTableDataV2: arraySelector(state, 'companiesTableDataV2'),
      companiesTableDataV3: getSimpleResult({
        limit: 25,
        page: 0,
        records: [],
        total: 0,
      })(state, 'companiesTableDataV3') as companiesTableDataV3Type,
      companyData: objectSelector(state, 'companyData'),
      aOByCompanyData: arraySelector(state, 'aOByCompanyData'),
      addAOFileData: arraySelector(state, 'addAOFileData'),
      createCompanyData: objectSelector(state, 'createCompanyData'),
      editCompanyData: objectSelector(state, 'editCompanyData'),
      deleteCompanyData: objectSelector(state, 'deleteCompanyData'),
      countriesData: arraySelector(state, 'countriesData'),
      companyTableParams: utilsSelector(state, 'companyTableParams', initialCompanyTableParams),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getCompanies,
      getCompaniesV2,
      getCompaniesV3,
      getCompany,
      createCompany,
      editCompany,
      deleteCompany,
      getAOByCompany,
      addAOFile,
      getCountries,
      editGroupCompany,
      getPurchaseClaims,
      getCompanyList,
      getPurchaseNowPlatforms,
      getPdsAccounts,
      getRevenueByDays,
      uploadRevenueFile,
      createTransactionInToClaim,
      getNeedRepaymentPenalty,
      getStatusRevenueUploadFile,
      getAccountsByIdCompany,
    }),
    [],
  );

  const companyTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setCompanyTableParams: (companyTableParams: companyTableParamsType) =>
            updateUtils({
              companyTableParams,
            }),
          setIsSwitchList: (listSwitch: typeof switchListInitial) =>
            updateUtils({
              listSwitch,
            }),
          setAOFormData: (aoFormData: typeof initialOrderCardsData) =>
            updateUtils({
              aoFormData,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);
  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...companyTableActions,
  };
};

export default useCompany;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { parseAmount, parseAmountWithSign } from './parsers';
import { formatDateIsoShort } from './formatters';
import { firstDayOfMonth } from './dt';

export const calcMonthTotal = (month, curr, txType, reportData) => {
  const total = reportData
    .filter((row) => row.currency === curr && row.month === month && row.tx_type === txType)
    .map((row) => parseAmount(row.amount))
    .reduce((a, b) => a + b, 0);

  return total || 0;
};

export const calcMonthTotalByType = (month, type, curr, reportData) => {
  const total = reportData
    .filter((row) => row.currency === curr && row.month === month && row.type === type)
    .map((row) => parseAmountWithSign(row.amount, row.tx_type))
    .reduce((a, b) => a + b, 0);

  return total || 0;
};

export const calcCompanyTotal = (month, companyId, curr, txType, reportData) => {
  const total = reportData
    .filter(
      (row) => row.currency === curr && row.month === month && row.company_id === companyId && row.tx_type === txType,
    )
    .map((row) => parseAmount(row.amount))
    .reduce((a, b) => a + b, 0);

  return total || 0;
};

export const calcCompanyTotalByType = (month, companyId, type, curr, reportData) => {
  const total = reportData
    .filter((row) => row.currency === curr && row.month === month && row.company_id === companyId && row.type === type)
    .map((row) => parseAmountWithSign(row.amount, row.tx_type))
    .reduce((a, b) => a + b, 0);

  return total || 0;
};

export const getCompanies = (data) => {
  const companies = {};
  data.forEach((row) => (companies[row.company_id] = row.company_name));

  return companies;
};

export const getTypeOrders = (data) => {
  const typeOrders = {};
  data.forEach((row) => (typeOrders[row.type] = row.type_order));

  return typeOrders;
};

export const getCurrencies = (data) =>
  data.map((row) => row.currency).filter((value, index, self) => self.indexOf(value) === index);

export const getMonths = (data) =>
  data.map((row) => row.month).filter((value, index, self) => self.indexOf(value) === index);

export const getTypes = (data, currencies, typeOrders) => {
  const types = data.map((row) => row.type).filter((value, index, self) => self.indexOf(value) === index);
  const typeDistinct = [];
  types.forEach((type) => {
    currencies.forEach((cur) => {
      const total = data
        .filter((row) => row.currency === cur && row.type === type)
        .map((row) => parseAmount(row.amount))
        .reduce((a, b) => a + b, 0);

      if (total !== 0) {
        typeDistinct.push({ type, order: typeOrders[type], currency: cur });
      }
    });
  });

  return typeDistinct.sort((a, b) => {
    if (a.order > b.order) return 1;

    if (a.order < b.order) return -1;

    return 0;
  });
};

export const getCompaniesByMonth = (data) => {
  const companies = {};
  data.forEach((row) => {
    const cmpList = companies[row.month] || [];

    if (cmpList.indexOf(row.company_id) < 0) {
      cmpList.push(row.company_id);
    }
    companies[row.month] = cmpList;
  });

  return companies;
};

export const getDateSixMonthsAgo = () => {
  const dtFrom = new Date();
  dtFrom.setDate(dtFrom.getDate() - 180);

  return formatDateIsoShort(firstDayOfMonth(dtFrom));
};

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type companiesDataType = {
  showDeleted?: boolean;
  errorCallback?: Function;
  resultKey?: string;
};

export default ({ showDeleted = false, errorCallback, resultKey = 'companiesData' }: companiesDataType = {}) =>
  requestAsync({
    url: endpoints.getCompaniesUrl(showDeleted),
    transform: (response) => ({
      [resultKey]: {
        names: response
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .filter((item) => item.company_name)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map((item) => ({
            name: item.company_name,
            id: item.id,
          })),
        companiesData: response,
      },
    }),
    queryKey: endpoints.getCompaniesUrl(showDeleted),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

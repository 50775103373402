import React, { Children, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useTransaction from 'hooks/api/useTransaction';
import { formatDate } from 'helpers/formatters';
import { tableBodyStyles, tableSettingsStyles } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import {
  dataPivotTransactionsReport,
  getKeyPivotTransactionsReporting,
  NameKeyPivotTransactionsReport,
} from 'components/tables/PivotTransactionReportTable/data';
import SortTable from 'components/SortTable';

const PivotTransactionReportTable = () => {
  const classes = tableSettingsStyles();
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const localClasses = useStyles();
  const { pivotTransactionReportsData, pivotTransactionReportsTableParams, setPivotTransactionReportsTableParams } =
    useTransaction();
  const pivotTransactionReports = useMemo(() => {
    if (pivotTransactionReportsData[pivotTransactionReportsData.length - 1]?.day === 'Total') {
      const { amount_usd_plus_eur, rate, ...otherProps } =
        pivotTransactionReportsData[pivotTransactionReportsData.length - 1];
      return [...pivotTransactionReportsData.slice(0, -1), otherProps];
    }
    return pivotTransactionReportsData;
  }, [pivotTransactionReportsData]);

  const { _sort, _order } = useMemo(() => pivotTransactionReportsTableParams, [pivotTransactionReportsTableParams]);
  const dtFrom = new Date(pivotTransactionReportsTableParams.report_date);
  dtFrom.setDate(dtFrom.getDate() - 1);
  const date = formatDate(dtFrom);

  const handleChangeSorting = useCallback(
    (label, orderLocal) => {
      if (!label || !orderLocal) return;
      setPivotTransactionReportsTableParams({
        ...pivotTransactionReportsTableParams,
        _order: orderLocal,
        _sort: label,
      });
    },
    [pivotTransactionReportsTableParams],
  );

  const headerAddClasses = useMemo(
    () =>
      pivotTransactionReports && pivotTransactionReports.length > 0
        ? ` ${styles.tHeadRowth1stSticky} ${styles.th2ndSticky} ${localClasses.th2ndSticky}`
        : '',
    [pivotTransactionReports],
  );

  const headerHeight = 83;

  return (
    <TableWrapWithPagination
      styleTop={{ top: headerHeight }}
      count={100}
      onPageChange={() => {}}
      onRowsPerPageChange={() => {}}
      styleLeft={{ left: 312 }}
      isShowPagination={false}
    >
      <TableHead>
        <TableRow
          classes={{
            root: `${classes.headTableRowRoot} ${classes.headTableRowRootIcon} ${headerAddClasses}`,
          }}
          style={{ height: headerHeight }}
        >
          {Children.toArray(
            dataPivotTransactionsReport.map(({ align, key, dateLocal, translate, typeSort }) => (
              <TableCell
                classes={{ root: classes.headTableCellRoot }}
                align={align || 'inherit'}
                style={{ verticalAlign: 'bottom' }}
              >
                <SortTable
                  onClickHandler={(order) => handleChangeSorting(key, order)}
                  isActive={_sort === key}
                  order={_order}
                  type={typeSort}
                >
                  {t(translate, dateLocal ? { date } : undefined)}
                  {_sort === key ? (
                    <span className={styles.visuallyHidden}>
                      {_order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </SortTable>
              </TableCell>
            )),
          )}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: `${classes.bodyTableRoot}` }}>
        {Children.toArray(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pivotTransactionReports.map((item) => (
            <TableRow
              classes={{
                root: `${classes.bodyTableRowRoot} ${localClasses.bodyTableRowRoot} ${classes.bodyTableRowRootTd2} ${styles.tBodyRowSticky} ${styles.td2ndSticky} ${localClasses.td2ndSticky}`,
              }}
              hover
            >
              {Children.toArray(
                dataPivotTransactionsReport.map(({ align, key }) => (
                  <TableCell
                    align={align || 'inherit'}
                    className={key === NameKeyPivotTransactionsReport.DAY ? styles.fixedCell : ''}
                    classes={{ root: classes.bodyTableCellRoot }}
                  >
                    {getKeyPivotTransactionsReporting(key, item[key])}
                  </TableCell>
                )),
              )}
            </TableRow>
          )),
        )}
      </TableBody>
    </TableWrapWithPagination>
  );
};

const useStyles = makeStyles(() => ({
  bodyTableRowRoot: {
    '&>td:first-child': {
      minWidth: 52,
    },
  },
  td2ndSticky: {
    '& td:nth-child(2)': {
      left: 92,
    },
  },
  th2ndSticky: {
    '& th:nth-child(2)': {
      left: 92,
    },
  },
}));

export default PivotTransactionReportTable;

/* eslint-disable array-callback-return */
import React from 'react';
import { Switch, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import { store, history } from 'configureStore';
import AppRoute from 'appRoutes';
import { RefreshProvider } from 'contexts/RefreshContext';
import './localization';
import '@gamesb42/ui-kit/dist/const.css';
import '@gamesb42/ui-kit/dist/antd.css';
import '@gamesb42/ui-kit/dist/main.css';
import './main.css';
import { UserProvider } from 'contexts/UserProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getQueries = (state) => state.queries;

const App = () => {
  return (
    <Provider store={store}>
      <RefreshProvider>
        <UserProvider>
          <ReduxQueryProvider queriesSelector={getQueries}>
            <Router history={history}>
              <Switch>
                <AppRoute />
              </Switch>
            </Router>
          </ReduxQueryProvider>
        </UserProvider>
      </RefreshProvider>
    </Provider>
  );
};

export default App;

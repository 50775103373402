// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, InputHTMLAttributes } from 'react';

import styles from './styles.module.scss';

interface PropsT extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  sizeWrap?: { width?: number; height?: number };
  wrapperClassName?: string;
  markerClassName?: string;
}

const concatClassNames = (classNames: string[]) => classNames.join(' ');

const Switcher: FC<PropsT> = (props) => {
  const { label, sizeWrap, wrapperClassName, markerClassName, checked, ...restProps } = props;

  const stylesInline = {
    '--left-position': checked ? `calc(100% - ${sizeWrap?.height || 16}px)` : 0,
    '--size-marker': `${sizeWrap?.height || 20 - 4}px`,
    backgroundColor: checked ? '#606176' : '#CED0D7',
  } as React.CSSProperties;

  return (
    <label className={concatClassNames([styles.wrapper, wrapperClassName])}>
      <span className={concatClassNames([styles.switchMark, markerClassName])} style={stylesInline} />
      <input type="checkbox" {...restProps} checked={checked} />
      {label}
    </label>
  );
};

export default Switcher;

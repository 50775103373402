import { PropTypes } from '@material-ui/core';

import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';

export enum SystemSettingsPath {
  KEYCLOAK = 'keycloak',
  DISTRIBUTION_PLATFORMS = 'distribution_platforms',
  FACTOR_DETAILS = 'factor_details',
}

export enum SystemSettingsEnum {
  GENERAL = 'general',
  RECALCULATE = 'recalculate',
  AUTH = 'auth',
  GOOGLE_API = 'google_api',
  JUSTCONTROLIT = 'justcontrolit',
  SIGNATURE = 'signature',
  COMPANY_INFO = 'company_info',
  PLATFORM = 'platform',
}

export type GeneralTabPropsType = {};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getInitialError = (text): ErrorType => ({
  csvFile: { status: false, text },
  name: { status: false, text },
  logo: { status: false, text },
  file: false,
});

export type platformDataType = {
  id: string;
  errorCallback: Function;
  resultKey?: string;
  name: string;
  logo: string;
  link: string;
  file_name: string;
  file: {
    rawFile: string;
    src: string;
    url: string;
  };
  successCallback: Function;
  file_data: string;
  deleted: boolean;
};

export const initialPlatformFormData: platformDataType = {
  name: '',
  id: '',
  errorCallback: Function,
  successCallback: Function,
  resultKey: '',
  link: '',
  file_name: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  file: null,
  file_data: '',
  logo: '',
  deleted: false,
};

export type ErrorType = {
  name: { status: boolean; text: string };
  csvFile?: { status: boolean; text: string };
  status?: { status: boolean; text: string };
  file?: boolean;
  file_data?: { status: boolean; text: string };
  logo?: { status: boolean; text: string };
};

interface documentTabFunc {
  link?: string;
  id?: string;
  getDocuments?: (data: Object) => void;
}

type VariantType = 'text' | 'outlined' | 'contained';
const alignCommon = 'left' as PropTypes.Alignment;
const variant = 'contained' as VariantType;
const color = 'primary' as PropTypes.Color;
const endpoint = 'getPlatforms';
const resultKey = 'platformData';
export enum DocumentTabKeys {
  NAME = 'name',
  LOGO = 'logo',
  LINK = 'link',
  DELETE = 'delete',
  SAVE = 'save',
  CANCEL = 'cancel',
  CUTOFF = 'cutoff',
}
export const documentTabData = [
  { props: { align: alignCommon }, key: DocumentTabKeys.NAME },
  {
    props: { align: alignCommon },
    key: DocumentTabKeys.LOGO,
  },
  {
    props: { align: alignCommon },
    propsButton: { variant, color },
    key: DocumentTabKeys.DELETE,
    translate: 'systemSetting.delete',
    func: async ({ id, getDocuments }: documentTabFunc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = await setCustomerPlatformByIdAndMetod(id, 'DELETE');

      if (result && id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await getDocuments({ endpoint, resultKey, params: { show_deleted: false } });
      }
    },
  },
  {
    props: { align: alignCommon },
    propsButton: { variant, color },
    key: DocumentTabKeys.SAVE,
    translate: 'systemSetting.save',
    func: async ({ id, getDocuments }: documentTabFunc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = await setCustomerPlatformByIdAndMetod(id, 'PUT');

      if (result && id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await getDocuments({
          endpoint,
          resultKey,
          params: { show_deleted: false },
        });
      }
    },
  },
  {
    props: { align: alignCommon },
    propsButton: { variant, color },
    key: DocumentTabKeys.CANCEL,
    translate: 'systemSetting.cancel',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    func: ({ cancelEditHandler }) => {
      cancelEditHandler();
    },
  },
  { props: { align: alignCommon }, key: DocumentTabKeys.CUTOFF },
];

export const setCustomerPlatformByIdAndMetod = async (id: string, method: string) => {
  if (!id) return false;
  try {
    const res = await fetch(endpoints.getPlatformByIdUrl(id), {
      method,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<any>;
  } catch (error) {
    return false;
  }
};

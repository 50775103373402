import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputKit } from '../../../uiKit/InputKit';
import DropZoneDocument from '../../CompanyForm/DropZoneDocument';

import classes from './styles.module.scss';

type PropsT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData: Dispatch<SetStateAction<any>>;
};

const DATA = [
  { type: 'input', name: 'keyId', label: 'keyId' },
  { type: 'input', name: 'issuerId', label: 'issuerId' },
  { type: 'input', name: 'vendorNumber', label: 'vendorId' },
  { type: 'file', name: 'key', label: 'apiKey' },
];

const Form: FC<PropsT> = (props) => {
  const { setFormData, formData } = props;
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  const onChangeFile = (name: string, file: File) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFormData((prevState) => ({ ...prevState, [name]: reader.result }));
    };
  };

  useEffect(() => {
    setFormData({
      keyId: '',
      issuerId: '',
      vendorNumber: '',
      key: '',
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      {DATA.map(({ type, name, label }) => (
        <React.Fragment key={name}>
          {type === 'input' && (
            <InputKit
              wrapProps={{ height: 60 }}
              label={t(`forms.revenue.fields.${label}`)}
              value={formData[name]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e) => setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))}
            />
          )}
          {type === 'file' && (
            <div>
              <span className={classes.labelFile}>{t(`forms.revenue.fields.${label}`)}</span>
              <DropZoneDocument
                acceptFormat={['text/plain', '.p8']}
                setAccessFiles={(access) => {
                  if (access.length !== 0) onChangeFile(name, access[0]);

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  if (access.length === 0) setFormData((prevState) => ({ ...prevState, [name]: '' }));
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setFiles(access);
                }}
                accessFiles={files}
                title={t('forms.revenue.dragAndDrop')}
                text={[t('forms.activationOrder.dropZoneText'), t('forms.activationOrder.dropZoneText1')]}
                buttonText={t('forms.activationOrder.dropZoneButton')}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Form;

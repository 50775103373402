import { actionTypes } from 'redux-query';

import urls from 'constants/urls';

/**
 * Если передан meta.errorCallback, он будет вызван.
 *
 * @return {void}
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default () =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (next) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (action) => {
    if (action.type === actionTypes.REQUEST_FAILURE) {
      if (action.status === 401 || action.status === 403) {
        localStorage.clear();
        window.location.href = `${window.location.origin}${urls.getAuth()}${window.location.pathname}`;

        return;
      }
      const { errorCallback } = action.meta;

      if (errorCallback) {
        errorCallback({ body: action.responseBody, text: action.responseText, unauthorized: action.status === 401 });
      }
    }

    return next(action);
  };

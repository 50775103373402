import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useUser from 'hooks/api/useUser';
import useErrorCallback from 'hooks/useErrorCallback';
import urls from 'constants/urls';
import { tableHeaderStyles } from 'theme/styles';
import { RefreshContext } from 'contexts/RefreshContext';
import useRouteTitle from 'hooks/useRouteTitle';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { AdminsTableFilters } from 'consts/tableFilters';
import SwitchButton from 'components/buttons/SwitchButton';
import CreateButton from 'components/buttons/CreateButton';

import { AdminTableHeaderPropsType } from './data';

const AdminTableHeader: FC<AdminTableHeaderPropsType> = ({ wrapperStyle, requestParams }) => {
  const classes = tableHeaderStyles();
  const history = useHistory();
  const { title } = useRouteTitle();
  const { getAdmins, adminTableParams, setAdminTableParams } = useUser();
  const { errorCallback } = useErrorCallback();
  const refreshContext = useContext(RefreshContext);

  useEffect(() => {
    getAdmins({ ...adminTableParams, errorCallback });
    refreshContext.clear();
    refreshContext.add({
      func: getAdmins,
      params: { ...adminTableParams, errorCallback },
    });
  }, [getAdmins, adminTableParams, refreshContext, errorCallback]);

  useEffect(
    () => () => {
      refreshContext.clear();
    },
    [refreshContext],
  );

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'adminTableParams',
      params: adminTableParams,
      requestParams,
    });
    setAdminTableParams(newParams);
    getAdmins({ ...newParams, errorCallback });
  }, []);

  useEffect(() => {
    refreshContext.setParams({ datakey: 'adminTableParams', params: adminTableParams });
    setUrlStringParams({ filters: AdminsTableFilters, currentScreenParams: adminTableParams });
  }, [adminTableParams]);

  const handleChangeSwitch = useCallback(() => {
    setAdminTableParams({ show_deleted: !adminTableParams.show_deleted });
  }, [setAdminTableParams, adminTableParams]);

  const createHandler = useCallback(
    (event) => {
      event.preventDefault();
      history.push(urls.getAdminCreate());
    },
    [history],
  );

  return (
    <div className={classes.container} style={wrapperStyle}>
      <div className={classes.title}>{title}</div>
      <div className={classes.leftBlockWrapper}>
        <SwitchButton
          inputSwitch={adminTableParams.show_deleted}
          handleChangeSwitch={handleChangeSwitch}
          buttonName="contentHeader.showDeleted"
        />
      </div>
      <div className={classes.buttonWrapper}>
        <CreateButton createHandler={createHandler} />
      </div>
    </div>
  );
};

export default AdminTableHeader;

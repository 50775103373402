import React, { FC } from 'react';

import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { activationOrdersV2DataType } from 'hooks/api/useActivationOrder';

import { KeyTransactions } from '../../data';

import IncomingTransactionView from './IncomingTransactionView';
import OutgoingTransactionView from './OutgoingTransactionView';
import InternalTransactionView from './InternalTransactionView/index';
import FeeTransactionView from './FeeTransactionView';
import AdjustmentTransactionView from './AdjustmentTransactionView';
import ConversionTransactionView from './ConversionTransactionView';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
  transactionsRelatedById: ItemTransactionsType[];
  isShowButtonAllocated: boolean;
  currentAo?: activationOrdersV2DataType;
  aoType: string;
};

const TransactionMenu: FC<PropsT> = ({
  transactionsDataById,
  transactionsRelatedById,
  isShowButtonAllocated,
  currentAo,
  aoType,
}) => (
  <div>
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.INCOMING && (
      <IncomingTransactionView
        transactionsDataById={transactionsDataById}
        transactionsRelatedById={transactionsRelatedById}
        isShowButtonAllocated={isShowButtonAllocated}
      />
    )}
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.OUTGOING && (
      <OutgoingTransactionView aoType={aoType} transactionsDataById={transactionsDataById} />
    )}
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.INTERNAL && (
      <InternalTransactionView aoType={aoType} transactionsDataById={transactionsDataById} currentAo={currentAo} />
    )}
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.FEE && (
      <FeeTransactionView transactionsDataById={transactionsDataById} />
    )}
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.ADJUSTMENT && (
      <AdjustmentTransactionView aoType={aoType} transactionsDataById={transactionsDataById} />
    )}
    {transactionsDataById[KeyTransactions.TYPE] === KeyTransactions.CONVERSION && (
      <ConversionTransactionView transactionsDataById={transactionsDataById} />
    )}
  </div>
);

export default TransactionMenu;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import urls from 'constants/urls';
import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { getCurrencyWithCode, formatAmount } from 'helpers/formatters';

import styles from './styles.module.scss';

const rightArrowSvg = require('assets/img/rightArrow.svg').default;

type PropsT = {
  transactionsDataById: ItemTransactionsType;
};

const ConversionTransactionView: FC<PropsT> = ({ transactionsDataById }) => {
  const { t } = useTranslation();

  const RATE = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>
        {`${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency_sell))}${formatAmount(
          Number(transactionsDataById?.amount?.amount_sell) || 0,
        )}`}
      </span>
      <img src={rightArrowSvg} alt="arrow" />
      <span>
        {`${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency_buy))}${formatAmount(
          Number(transactionsDataById?.amount?.amount_buy) || 0,
        )}`}
      </span>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item title={`${t('forms.transaction.amount')} `} content={RATE} additionalStyle={{ fontWeight: 700 }} />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')} `}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')} `}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            title={`${t('forms.transaction.client')} `}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.transactionRate')} `}
            value={transactionsDataById?.amount.rate}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.comment')} `}
            value={transactionsDataById?.comment}
          />
        </Card>
      </div>
    </div>
  );
};

export default ConversionTransactionView;

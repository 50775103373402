import React, { Children, FC } from 'react';

import styles from './styles.module.scss';

export type ItemT = {
  id: number | string;
  label: string;
};

type PropsT = {
  items: ItemT[];
  setItem: (id: number | string) => void;
  activeItem: ItemT['id'];
};

const Tabs: FC<PropsT> = (props) => {
  const { setItem, activeItem, items } = props;

  return (
    <div className={styles.wrapper}>
      {Children.toArray(
        items.map(({ id, label }) => (
          <button
            type="button"
            className={`${styles.item} ${activeItem === id ? styles.itemActive : ''}`}
            onClick={() => setItem(id)}
          >
            {label}
          </button>
        )),
      )}
    </div>
  );
};

export default Tabs;

import React, { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';

interface IPublicRoute {
  component: () => ReactElement;
  exact?: boolean;
  computedMatch?: object;
  path: string;
  url?: string;
}

const PublicRoute: FC<IPublicRoute> = ({ component: Component, ...rest }: IPublicRoute) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <Route {...rest} render={(props: any) => <Component {...props} />} />
);

export default PublicRoute;

import endpoints from 'consts/endpoints';
import { formatDateIsoShort } from 'helpers/formatters';
import { getToken } from 'helpers/token';
import {
  conversionTransactionErrorType,
  conversionTransactionFormDataType,
  DefaultTransactionErrorType,
  initialTransactionFormDataType,
  TargetOrderDataType,
} from 'types/TransactionFormTypes';

export const initialConversionTransactionFormData: conversionTransactionFormDataType = {
  company_id: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date: formatDateIsoShort(new Date()),
  type: 'CONVERSION',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  amount: undefined,
  currency: '',
  mode: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  targets: [{ amount: undefined, currency: '', rate: undefined, target_type: 'CONVERSION' }],
  comment: '',
};

export const getInitialConversionTransactionError = (requiredText: string): conversionTransactionErrorType => ({
  date: { status: false, text: requiredText },
  company_id: { status: false, text: requiredText },
  amount: { status: false, text: requiredText },
  currency: { status: false, text: requiredText },
  target: {
    amount: { status: false, text: requiredText },
    currency: { status: false, text: requiredText },
    rate: { status: false, text: requiredText },
  },
});

export const initialCurrency = [
  { value: 'EUR', label: 'selectFiledValues.eur' },
  { value: 'USD', label: 'selectFiledValues.usd' },
  { value: 'RUB', label: 'selectFiledValues.rub' },
];

export const initialTransactionFormData: initialTransactionFormDataType = {
  adjusting_transaction: false,
  reference_id: '',
  amount: '',
  comment: '',
  company_id: '',
  currency: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  date: formatDateIsoShort(new Date()),
  file: {
    rawFile: '',
    src: '',
    url: '',
  },
  file_data: '',
  file_name: '',
  reason: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  receipt_date: null,
  targets: [],
  type: '',
};

export const initialTargetOrder: TargetOrderDataType = {
  order_id: '',
  amount: 0,
  order_amount: '',
  order_currency: '',
  order_issued_amount: '',
  target_type: '',
  rate: 1,
  tx_currency_amount: undefined,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  keyId: undefined,
};

export const getInitialDefaultTransactionError = (requiredText: string): DefaultTransactionErrorType => ({
  company: false,
  type: false,
  amount: false,
  currency: false,
  file: false,
  reference_id: false,
  date: { error: false, text: requiredText },
  receipt_date: { error: false, text: '' },
});

export interface Claim {
  id: string;
  name: string;
  logo: string;
  closed_payment_date: null | Date;
  deleted: boolean;
}
interface responseClaimsType {
  [key: string]: Claim;
}

export const getPlatformByClaims = async (body: { claims: string[] }) => {
  try {
    const res = await fetch(endpoints.getPlatformByClaimsUrl(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(body),
    });

    return (await res.json()) as Promise<responseClaimsType>;
  } catch (error) {
    return error;
  }
};

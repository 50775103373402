import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type commissionReportParamsType = {
  report_date: string;
  company_id?: string;
  contract_number?: string;
  currency?: string;
  _sort?: string;
  _order?: string;
  status?: string;
  activation_order_id?: string;
  _end?: string;
  _start?: string;
};

type commissionReportsType = {
  params?: commissionReportParamsType;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ params, errorCallback, resultKey = 'commissionReportsData' }: commissionReportsType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getCommissionReportsUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getCommissionReportsUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

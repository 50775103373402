import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type PivotTransactionParamsT = {
  report_date: string;
  company_id?: string;
  exportXls?: boolean;
};

type pivotTransactionsDataType = {
  params: PivotTransactionParamsT;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ params, errorCallback, resultKey = 'pivotTransactionReportsData' }: pivotTransactionsDataType) =>
  requestAsync({
    url: endpoints.getPivotTransactionsUrl(params),
    transform: (response) => ({
      [resultKey]:
        resultKey === 'pivotTransactionReportsData'
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response.map((item) => ({
              ...item,
            }))
          : response,
    }),
    queryKey: endpoints.getPivotTransactionsUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, InputHTMLAttributes } from 'react';

import styles from './styles.module.scss';

interface PropsT extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  sizeWrap?: { width?: number; height?: number };
  sizeIcon?: { width?: number; height?: number; bottom: number; borderWidth: number };
  wrapperClassName?: string;
  markerClassName?: string;
}

const concatClassNames = (classNames: string[]) => classNames.join(' ');

const CheckboxKit: FC<PropsT> = (props) => {
  const { label, sizeWrap, wrapperClassName, markerClassName, checked, sizeIcon, ...restProps } = props;
  const stylesInline = {
    '--size-opacity-icon': checked ? 1 : 0,
    '--size-width-icon': `${sizeIcon?.width || 4}px`,
    '--size-height-icon': `${sizeIcon?.height || 7}px`,
    '--size-position-bottom-icon': `${sizeIcon?.bottom || 1}px`,
    '--size-border-width-icon': sizeIcon?.borderWidth
      ? `0 ${sizeIcon?.borderWidth}px ${sizeIcon?.borderWidth}px 0`
      : '0 2px 2px 0',
  } as React.CSSProperties;

  return (
    <label className={concatClassNames([styles.wrapper, wrapperClassName])}>
      <span className={concatClassNames([styles.switchMark, markerClassName])} style={stylesInline} />
      <input type="checkbox" {...restProps} defaultChecked={checked} />
      {label}
    </label>
  );
};

export default CheckboxKit;

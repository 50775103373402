import React, { FC, Children } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, TableBody, TableContainer, Table } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import plusIcon from 'assets/img/plus-black.svg';
import minusIcon from 'assets/img/minus-black.svg';
import Accordion from 'components/uiKit/Accordion';
import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { getCurrencyWithCode, formatAmount, formatDate } from 'helpers/formatters';
import SortTable from 'components/SortTable';
import Button from 'components/uiKit/Button';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const arrowConversationSvg = require('assets/img/arrowConversation.svg').default;

type PropsT = {
  transactionsDataById: ItemTransactionsType;
  transactionsRelatedById: ItemTransactionsType[];
  isShowButtonAllocated: boolean;
};

const IncomingTransactionView: FC<PropsT> = ({
  transactionsDataById,
  transactionsRelatedById,
  isShowButtonAllocated,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.amount')}`}
            value={`+ ${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency))}${formatAmount(
              Number(transactionsDataById?.amount?.amount) || 0,
            )}`}
            additionalStyle={{ color: '#26B634', fontWeight: 700 }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')}`}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')}`}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.receiptDate')}`}
            valueFormatType="date"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={transactionsDataById?.transaction_date}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            title={`${t('forms.transaction.client')}`}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            type={TypeEnum.HREF}
            title={`${t('forms.transaction.sender')}`}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.sender.company_id}` }}
            value={transactionsDataById?.sender?.company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            title={t('factorDetails.factorBankAccount')}
            type={TypeEnum.TEXT}
            value={
              transactionsDataById.recipient?.related_bank_account?.bank_name && transactionsDataById.recipient.account
                ? `${
                    transactionsDataById.recipient?.related_bank_account?.bank_name
                  } *${transactionsDataById.recipient.account.slice(-4)}`
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.comment')}`}
            value={transactionsDataById?.comment}
          />
        </Card>
      </div>
      <Accordion
        initialState
        title={t('forms.transaction.paymentDetails')}
        closeImg={<img src={minusIcon} alt="close" />}
        openImg={<img src={plusIcon} alt="open" />}
        className={styles.accordionButton}
      >
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.referenceNumber')}`}
            value={transactionsDataById?.reference}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.paymentDetails')}`}
            value={transactionsDataById?.details}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.accountNumber')}`}
            value={transactionsDataById?.recipient?.account}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.bankName')}`}
            value={transactionsDataById?.recipient?.bank_name}
          />
        </Card>
      </Accordion>
      {transactionsRelatedById.length > 0 && (
        <Accordion
          initialState
          title={t('forms.transaction.relatedTransaction')}
          closeImg={<img src={minusIcon} alt="close" />}
          openImg={<img src={plusIcon} alt="open" />}
          className={styles.accordionButton}
        >
          <Card style={{ padding: 0 }}>
            <TableContainer>
              <Table size="small" aria-label="wrap pagination" stickyHeader>
                <TableHead>
                  <TableRow style={{ height: 40 }} className={styles.tableRowStyle}>
                    <TableCell style={{ minWidth: 100, width: 100, color: '#29292C' }} align="left">
                      <SortTable isActive order="asc" onClickHandler={() => {}}>
                        {t('forms.transaction.date')}
                      </SortTable>
                    </TableCell>
                    <TableCell align="left">{t('forms.transaction.aoNumber')}</TableCell>
                    <TableCell align="left">{t('forms.transaction.type')}</TableCell>
                    <TableCell align="right">{t('forms.transaction.amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Children.toArray(
                    transactionsRelatedById.map((item) => (
                      <TableRow style={{ height: 40 }} className={styles.tableRowStyle}>
                        <TableCell style={{ minWidth: 100, width: 100 }} align="left">
                          {formatDate(item.value_datetime)}
                        </TableCell>
                        <TableCell align="left">{item.document_number}</TableCell>
                        <TableCell align="left">{item.category_dsc}</TableCell>
                        <TableCell align="right">
                          {`- ${getCurrencyWithCode(Number(item?.amount?.currency))}${item?.amount?.amount}`}
                        </TableCell>
                      </TableRow>
                    )),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Accordion>
      )}
      {isShowButtonAllocated && (
        <div style={{ display: 'flex', marginTop: 8 }}>
          <Button
            startAdornment={<img src={arrowConversationSvg} alt="arrowConversation" />}
            style={{ height: 40, width: 146 }}
            variant="fill"
            color="grey_light"
            onClick={() => {
              history.push(
                `${urls.getTransactionsCreate()}/${KeyTransactions.CONVERSION_INCOMING}/${transactionsDataById.id}`,
              );
            }}
          >
            {t('forms.transaction.Conversion')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default IncomingTransactionView;

import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  className: string;
}

const PdfIcon = ({ className }: Props) => (
  <div className={classNames(styles.root, className)}>
    <div className={styles.text}>PDF</div>
  </div>
);

export default PdfIcon;

import React, { FC } from 'react';

import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import ConstructorTransForm from '../../ConstructorTransForm';

interface FeeCardType {}
const FeeCard: FC<FeeCardType> = () => (
  <>
    <ConstructorTransForm types={[KeyTransactions.TYPE, KeyTransactions.VALUE_DATE_TIME]} />
    <ConstructorTransForm types={[KeyTransactions.CURRENCY, KeyTransactions.AMOUNT]} />
    <ConstructorTransForm types={[KeyTransactions.RECIPIENT]} />
    <ConstructorTransForm types={[KeyTransactions.DETAILS]} />
    <ConstructorTransForm types={[KeyTransactions.COMMENT]} />
  </>
);
export default FeeCard;

import React, { Children, FC } from 'react';

import { formatDate } from 'helpers/formatters';
import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import Card from 'components/common/GeneralView/Card';
import Accordion from 'components/forms/ActivationOrderForm/General/common/Accordion';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';

import styles from './styles.module.scss';

type PropsT = {
  activationOrderData: activationOrderDataType;
  shouldCustomSettings: (
    | 'paymentsDays'
    | 'gracePeriod'
    | 'commissionDisableDate'
    | 'turnOffCapitalizationUntill'
    | 'defaultInterestDisableDate'
    | 'capitalizationPeriodDisabled'
  )[];
};

const CustomSettings: FC<PropsT> = ({ activationOrderData, shouldCustomSettings }) => {
  const customSettings = {
    paymentsDays: activationOrderData.is_custom_payment_day && (
      <Card>
        {Children.toArray(
          activationOrderData.payment_days.map(({ payment_day, apply_from_date }, index, array) => (
            <>
              <Item
                type={TypeEnum.TEXT}
                title="forms.activationOrder.customPaymentDay"
                value={payment_day}
                widthLeft={224}
              />
              <Item
                type={TypeEnum.TEXT}
                title="forms.activationOrder.applyPaymentDayFrom"
                value={apply_from_date}
                valueFormatType="date"
                widthLeft={224}
              />
              {array.length - 1 !== index && <div className={styles.divider} />}
            </>
          )),
        )}
      </Card>
    ),
    gracePeriod: activationOrderData[GeneralEnum.GRACE_PERIOD] && (
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.gracePeriod"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={activationOrderData[GeneralEnum.GRACE_PERIOD]}
          widthLeft={224}
        />
      </Card>
    ),
    commissionDisableDate: activationOrderData[GeneralEnum.COMMISSION_DISABLE_DATE] && (
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.commissionDisableDate"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={activationOrderData[GeneralEnum.COMMISSION_DISABLE_DATE]}
          valueFormatType="date"
          widthLeft={224}
        />
      </Card>
    ),
    turnOffCapitalizationUntill: activationOrderData[GeneralEnum.TURN_OFF_CAPITALIZATION_UNTILL] && (
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.turnOffCapitalizationUntill"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={activationOrderData[GeneralEnum.TURN_OFF_CAPITALIZATION_UNTILL]}
          valueFormatType="date"
          widthLeft={224}
        />
      </Card>
    ),
    defaultInterestDisableDate: activationOrderData[GeneralEnum.DEFAULT_INTEREST_DISABLE_DATE] && (
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.defaultInterestDisableDate"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={activationOrderData[GeneralEnum.DEFAULT_INTEREST_DISABLE_DATE]}
          valueFormatType="date"
          widthLeft={224}
        />
      </Card>
    ),
    capitalizationPeriodDisabled: activationOrderData[GeneralEnum.CAPITALIZATION_PERIOD_DISABLED]?.length > 0 && (
      <Card>
        <Item
          type={TypeEnum.TEXT}
          title="forms.activationOrder.capitalizationPeriodDisabled"
          content={(() => (
            <span>
              {Children.toArray(
                activationOrderData[GeneralEnum.CAPITALIZATION_PERIOD_DISABLED].map(({ date_start, date_end }) => (
                  <span>{`${formatDate(date_start)} - ${formatDate(date_end)}`}</span>
                )),
              )}
            </span>
          ))()}
          valueFormatType="date"
          widthLeft={224}
        />
      </Card>
    ),
  };

  return (
    <Accordion title="forms.activationOrder.customSettings">
      <div className={styles.accordionContent}>
        {Children.toArray(shouldCustomSettings.map((setting) => customSettings[setting]))}
      </div>
    </Accordion>
  );
};

export default CustomSettings;

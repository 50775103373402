import { Company } from './CompanyTypes';

export type ErrorType = {
  username: boolean;
  password?: boolean;
  email?: boolean;
};

export type UserFormFetchBodyType = {
  id?: string;
  fullName: string;
  username: string;
  password?: string;
  email: string;
  companyId: string;
  authProvider: string;
  active: boolean;
  successCallback: () => void;
  errorCallback: () => void;
};

export type UserFormPropsType = {
  editType?: boolean;
  requestParams?: object;
};

export type CompanyType = {
  name: string;
  id: string;
};

interface IApps {
  icon: string;
  id: string;
  link: string;
  name: string;
  sku: string;
  store: string;
  store_id: string;
}

export type UserStateType = {
  id?: string;
  fullName: string;
  username: string;
  email: string;
  authProvider: string;
  companyId: string;
  active: boolean;
  password?: string;
  apps?: IApps[];
};

export const enum UserKeyEnum {
  ID = 'id',
  EMAIL = 'email',
  STATUS = 'status',
  ROLE = 'role',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  COMPANY = 'company',
  COMPANY_NAME = 'company_name',
  COMPANY_ID = 'company_id',
  OTHER_COMPANIES = 'other_companies',
  PASSWORD = 'password',
}

export const enum UserRole {
  FACTOR_ADMIN = 'factor-admin',
  CLIENT_ADMIN = 'client-admin',
}

export interface User {
  [UserKeyEnum.ID]: string;
  [UserKeyEnum.EMAIL]: string;
  [UserKeyEnum.STATUS]: string;
  [UserKeyEnum.ROLE]: UserRole;
  [UserKeyEnum.FIRST_NAME]: string;
  [UserKeyEnum.LAST_NAME]: string;
  [UserKeyEnum.COMPANY_NAME]: string;
  [UserKeyEnum.COMPANY_ID]: string;
  [UserKeyEnum.COMPANY]: Company;
  [UserKeyEnum.PASSWORD]?: string;
  [UserKeyEnum.OTHER_COMPANIES]: {
    [UserKeyEnum.ROLE]: UserRole;
    [UserKeyEnum.COMPANY_ID]: string;
    [UserKeyEnum.COMPANY]?: Company;
  }[];
}

export interface UserCompany {
  [UserKeyEnum.ROLE]: UserRole;
  [UserKeyEnum.COMPANY]: Company;
}

// TODO: Simplify
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import endpoints, {
  getMonthlyInvoiceReportCreateParamsType,
  getMonthlyReportRecreateParamsType,
} from 'consts/endpoints';
import { getToken } from 'helpers/token';

interface FuncTypeButton {
  companyId?: string;
  getMonthlyInvoiceReports?: Function;
  setVisibleButton?: (value: string | null) => void;
  visibleButton?: string | null;
  showAlert?: Function;
  showErrorAlert?: Function;
  year?: number;
  errorCallback?: Function;
}

export type AlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export enum metaReportsEnum {
  MONTHLY_REPORT = 'monthly_report',
  INVOICE_REPORT = 'invoice_report',
  INVOICE_REPORT_USD = 'invoice_report_usd',
  INVOICE_REPORT_EUR = 'invoice_report_eur',
}

export enum reportsKeyEnum {
  NAME = 'name',
  TYPE = 'type',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  LINK = 'link',
  RECALC = 'recalc',
  DELETE = 'delete',
  MONTHLY_REPORT = 'monthly_report',
  INVOICE_REPORT = 'invoice_report',
  MONTHLY_REPORT_CURRENT = 'monthly_report_current',
  INVOICE_REPORT_CURRENT = 'invoice_report_current',
}

export interface monthlyInvoiceReportsRecordsType {
  currency: string;
  id: string;
  name: string;
  size: number;
  owner_id: string;
  updated: Date;
  meta: {
    type: metaReportsEnum;
    end_date: Date;
    start_date: Date;
  };
}

export interface monthlyInvoiceReportsDataType {
  records: monthlyInvoiceReportsRecordsType[];
  total: number;
  page: number;
  limit: null | number;
}

interface reportsDataTypeButton {
  id?: number;
  title?: string;
  dataKey?: string;
  headAlign?: AlignType;
  bodyAlign?: AlignType;
  fixedCell?: boolean;
  isAmount?: boolean;
  func: (data: FuncTypeButton) => void;
  titleButton?: string;
}

export const getMonthlyReportRecreate = async (companyId, params: getMonthlyReportRecreateParamsType) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyReportRecreateUrl({ companyId, params }), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getMonthlyInvoiceReportDelete = async (companyId, id) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceReportDeleteUrl({ companyId, id }), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getMonthlyInvoiceReportCreate = async (companyId, params: getMonthlyInvoiceReportCreateParamsType) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceReportCreateUrl({ companyId, params }), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

const common = { headAlign: 'left', bodyAlign: 'left' } as {
  headAlign: AlignType;
  bodyAlign: AlignType;
};

export const reportsDataButtons: reportsDataTypeButton[] = [
  {
    ...common,
    id: 1,
    title: '',
    func: async ({
      companyId,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
      errorCallback,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton('monthly_report');
      }
      const res = await getMonthlyInvoiceReportCreate(companyId, { report_type: 'monthly_report' });

      if ((res && res.file_id) || (res && res.files) || res) {
        await getMonthlyInvoiceReports({
          id: companyId,
          params: { year },
          errorCallback,
        });
        setVisibleButton(null);
        showAlert('reports.created');
      }
    },
    dataKey: reportsKeyEnum.MONTHLY_REPORT,
    titleButton: 'reports.createMonthlyReport',
  },
  {
    ...common,
    id: 3,
    title: '',
    func: async ({
      companyId,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton(reportsKeyEnum.MONTHLY_REPORT_CURRENT);
      }
      const res = await getMonthlyInvoiceReportCreate(companyId, {
        report_type: 'monthly_report',
        current_month: true,
      });

      if ((res && res.file_id) || (res && res.files) || res) {
        await getMonthlyInvoiceReports({
          id: companyId,
          params: { year },
        });
        setVisibleButton(null);
        showAlert('reports.created');
      }
    },
    dataKey: reportsKeyEnum.MONTHLY_REPORT_CURRENT,
    titleButton: 'reports.createMonthlyCurrent',
  },
];

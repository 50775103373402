import React from 'react';

import SystemSettingsForm from 'components/forms/SystemSettingsForm';
import MainContent from 'components/MainContent';

const SystemSettingsScreen = () => (
  <MainContent type="form">
    <SystemSettingsForm />
  </MainContent>
);

export default SystemSettingsScreen;

import React from 'react';

const MailtoLink = ({ email }: { email: string }) => (
  <a
    href={`mailto:${email}`}
    style={{
      color: '-webkit-link',
      cursor: 'pointer',
      textDecoration: 'underline',
    }}
  >
    {email}
  </a>
);

export default MailtoLink;

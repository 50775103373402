import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateEntities } from 'redux-query';
import lodash from 'lodash';

import { objectSelector } from 'hooks/api/selectors';
import { store } from 'configureStore';
import { phoneValidator, emailValidator } from 'helpers/validators';

export interface setErrorsParamsType {
  required?: boolean;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const deleteKey = (prev, key) => {
  let res = {};
  Object.keys(prev).forEach((item) => {
    if (item !== key) {
      res = { ...res, [item]: prev[item] };
    }
  });

  return res;
};
const minValueDate = '01.01.2019';
const maxValueDate = '31.12.2029';
const min = new Date(minValueDate).getTime();
const max = new Date('12.31.2029').getTime();

const useErrors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useCallback(
    (state) => ({
      errors: objectSelector(state, 'errors'),
    }),
    [],
  );
  const data = useSelector(selector);
  const getErrors = useCallback(() => store.getState()?.entities?.errors, [store]);
  const validateErrors = useCallback(
    (value, { required, date, phone, email, range = {} } = {}) => {
      if (required && !value) {
        return t('auth.required');
      }

      if (date && value) {
        if (new Date(value).toString() === 'Invalid Date') {
          return 'Invalid Date';
        }

        if (new Date(value).getTime() > max) {
          return `${t('dateErrors.max')} ${maxValueDate}`;
        }

        if (new Date(value).getTime() < min) {
          return `${t('dateErrors.min')} ${minValueDate}`;
        }
      }

      if (phone && value) {
        return !phoneValidator(value) ? t('errors.phoneNotValid') : '';
      }

      if (email && value) {
        return !emailValidator(value) ? t('errors.emailNotValid') : '';
      }
      const { minRange, maxRange } = range;

      if (minRange && maxRange && value && (value < minRange || value > maxRange)) {
        return `Must be between ${minRange} and ${maxRange}`;
      }

      return '';
    },
    [t],
  );
  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          setErrors: (value, key, params = {}) => {
            const error = validateErrors(value, params);

            if (!error) {
              return updateEntities({
                errors: (prev = {}) => ({ ...deleteKey(prev, key) }),
              });
            }

            return updateEntities({
              errors: (prev: Object) => ({ ...prev, [key]: error }),
            });
          },
          changeErrors: (obj) =>
            updateEntities({
              errors: () => obj,
            }),
          addErrors: (obj) =>
            updateEntities({
              errors: (prev: Object) => ({ ...prev, ...obj }),
            }),
          clearErrorsOfKey: (key) =>
            updateEntities({
              errors: (prev = {}) => ({ ...deleteKey(prev, key) }),
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    isErrors: !lodash.isEmpty(data.errors),
    ...data,
    getErrors,
    ...actionWithoutIsFetching,
  };
};

export default useErrors;

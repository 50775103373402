import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface GetUserParamsType {
  show_deleted?: boolean;
  _order?: string;
  _sort?: string;
}

type usersDataType = {
  params?: GetUserParamsType;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({ errorCallback, resultKey = 'usersData', params, successCallback }: usersDataType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getUsersUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getUsersUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination, Table } from '@gamesb42/ui-kit';

import useActivationOrder, { useActivationOrderType } from 'hooks/api/useActivationOrder';
import { formatDate } from 'helpers/formatters';
import urls from 'constants/urls';
import { AOFilters } from 'consts/tableFilters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import Status, { StatusEnumTitle } from 'components/common/Status';

import TableWrapper from 'components/TableWrapper';

const ActivationTable: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    activationOrdersV2Data,
    activationOrdersTableParams,
    setActivationOrdersTableParams,
  }: useActivationOrderType = useActivationOrder();

  const columns = [
    {
      title: t('tables.activationOrder.header.client'),
      dataIndex: 'company_name',
      width: '280px',
    },
    {
      title: t('tables.activationOrder.header.agreement'),
      dataIndex: 'contract_number',
    },
    {
      title: t('tables.activationOrder.header.ao'),
      dataIndex: 'name',
    },
    {
      title: t('tables.activationOrder.header.type'),
      dataIndex: 'ao_type',
      render: (type: string) => t(`tables.${type}`),
    },
    {
      title: t('tables.activationOrder.header.sign_date'),
      dataIndex: 'sign_date',
      render: (date: string) => formatDate(date),
    },
    {
      title: t('tables.activationOrder.header.status'),
      dataIndex: 'status',
      width: '132px',
      render: (status: string) => <Status style={{ width: 88 }} status={status as StatusEnumTitle} />,
    },
  ];

  const onChangePagination = (newCurrent: number, newPageSize: number) => {
    setActivationOrdersTableParams?.({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...activationOrdersTableParams!,
      limit: newPageSize,
      page: newCurrent - 1,
    });
  };

  const pageSize = activationOrdersTableParams?.limit ? activationOrdersTableParams.limit : 10;

  useEffect(() => {
    setUrlStringParams({ filters: AOFilters, currentScreenParams: activationOrdersTableParams });
  }, [activationOrdersTableParams]);

  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={activationOrdersV2Data?.records || []}
        onRow={({ id }) => ({
          onClick: () => history.push(`${urls.getActivationOrder()}/${id}`),
        })}
      />
      <Pagination
        total={activationOrdersV2Data?.total || 0}
        current={(activationOrdersTableParams?.page || 0) + 1}
        pageSize={pageSize}
        onChange={onChangePagination}
      />
    </TableWrapper>
  );
};

export default ActivationTable;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import urls from 'constants/urls';
import plusIcon from 'assets/img/plus-black.svg';
import minusIcon from 'assets/img/minus-black.svg';
import Accordion from 'components/uiKit/Accordion';
import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { formatAmount, getCurrencyWithCode } from 'helpers/formatters';
import { DocumentTypeEnum, getListTransactionCategory } from 'components/tables/TransactionsTable/ViewsForm/data';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import getParams from 'helpers/getParams';

import { getPathOfDocumentType, getTitleOfDocumentType } from '../../data';

import styles from './styles.module.scss';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
  aoType: string;
};

const OutgoingTransactionView: FC<PropsT> = ({ transactionsDataById, aoType }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.amount')}`}
            value={`- ${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency))}${formatAmount(
              Number(transactionsDataById?.amount?.amount) || 0,
            )}`}
            additionalStyle={{ color: '#29292C', fontWeight: 700 }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')}`}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.category')}`}
            value={
              getListTransactionCategory[KeyTransactions.OUTGOING]().find(
                ({ key }) => key === transactionsDataById?.category,
              )?.value
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')}`}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.HREF}
            title={`${t('forms.transaction.client')}`}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
          />
          <Item
            title={`${t('forms.transaction.recipient')}`}
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.recipient.company_id}` }}
            value={transactionsDataById?.recipient?.company_name}
            additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
          />
          <Item
            title={t('factorDetails.factorBankAccount')}
            type={TypeEnum.TEXT}
            value={
              transactionsDataById.sender?.related_bank_account?.bank_name && transactionsDataById.sender.account
                ? `${
                    transactionsDataById.sender?.related_bank_account?.bank_name
                  } *${transactionsDataById.sender.account.slice(-4)}`
                : ''
            }
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              link: `${getPathOfDocumentType[transactionsDataById.document_type || DocumentTypeEnum.DEFAULT]}/${
                transactionsDataById.document_id || transactionsDataById?.factoring_order_id
              }${getParams({ type: aoType })}`,
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            title={`${t(getTitleOfDocumentType[transactionsDataById.document_type || DocumentTypeEnum.DEFAULT])} `}
            value={transactionsDataById?.document_number || transactionsDataById?.factoring_order_number}
            additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.comment')}`}
            value={transactionsDataById?.comment}
          />
        </Card>
      </div>
      <Accordion
        initialState
        title={t('forms.transaction.paymentDetails')}
        closeImg={<img src={minusIcon} alt="close" />}
        openImg={<img src={plusIcon} alt="open" />}
        className={styles.accordionButton}
      >
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.paymentDetails')}`}
            value={transactionsDataById?.details}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.accountNumber')}`}
            value={transactionsDataById?.recipient?.account}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.bankName')}`}
            value={transactionsDataById?.recipient?.bank_name}
          />
        </Card>
      </Accordion>
    </div>
  );
};

export default OutgoingTransactionView;

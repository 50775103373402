// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useContext, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { RefreshContext } from 'contexts/RefreshContext';
import useTransaction, { initialTransactionTableParams } from 'hooks/api/useTransaction';
import useCompany from 'hooks/api/useCompany';
import useActivationOrder from 'hooks/api/useActivationOrder';
import useContract from 'hooks/api/useContract';
import useErrorCallback from 'hooks/useErrorCallback';
import { filterCompanies } from 'helpers/companies';
import { currencyChoicesAutocomplete } from 'consts/dict';
import useRouteTitle from 'hooks/useRouteTitle';
import { autocompleteContractData, autocompleteAOData } from 'helpers/transformDataForAutocomplete';
import { TransactionTableFilters } from 'consts/tableFilters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import DateInput from 'components/inputs/DateInput';
import useFilterDateValidate from 'hooks/useFilterDateValidate';
import Title from 'components/common/Title';
import { getBooleanValue } from 'helpers/formatters';
import urls from 'constants/urls';
import SelectButton from 'components/SelectButton';
import EditGeneral from 'components/tables/TransactionTableOld/EditGeneral';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';

import { CompanyType, TransactionTableHeaderPropsType, statusChoicesAutocomplete } from './data';

const downloadSvg = require('assets/img/download.svg').default;

const buttonStyles = {
  cursor: 'pointer',
  border: '1px solid #A7AFB7',
  width: 40,
  height: 40,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute' as const,
};

const TransactionTableHeaderOld: FC<TransactionTableHeaderPropsType> = ({ wrapperStyle, requestParams }) => {
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const { dateValidator } = useFilterDateValidate();
  const {
    companiesData: { companiesData = [] },
    getCompanies,
  } = useCompany();
  const { contractsData, getContracts, clearContractsData } = useContract();
  const history = useHistory();
  const { getTransactionsV2, transactionTableParams, setTransactionTableParams, clearTransactionDataV2 } =
    useTransaction();
  const { activationOrderListData, getActivationOrderList, clearActivationOrderListData } = useActivationOrder();
  const { errorCallback } = useErrorCallback();
  const [validate, setValidate] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const refreshContext = useContext(RefreshContext);
  const [company, setCompany] = useState<CompanyType>({ name: '', id: '' });
  const [activationOrder, setActivationOrder] = useState<CompanyType>({ name: '', id: '' });
  const [contract, setContract] = useState<CompanyType>({ name: '', id: '' });
  const [currency, setCurrency] = useState<CompanyType>({ name: '', id: '' });
  const [txType, setTxType] = useState<CompanyType>({ name: '', id: '' });
  const [txStatus, setTxStatus] = useState<CompanyType>({ name: '', id: '' });
  const companies: CompanyType[] = useMemo(() => filterCompanies(companiesData), [companiesData]);
  const { contracts } = useMemo(() => contractsData, [contractsData]);
  const txTypeAutocomplete = [
    { id: 'IN', name: t('tables.incoming') },
    { id: 'OUT', name: t('tables.outdoing') },
    { id: 'ADJUSTING', name: t('tables.adjusting') },
    { id: 'CONVERSION', name: t('tables.exchange') },
  ];

  useEffect(() => {
    getCompanies({ errorCallback });
  }, [getCompanies, errorCallback]);

  useEffect(() => {
    const newParams = refreshContext.getNewParams({
      datakey: 'transactionTableParams',
      params: transactionTableParams,
      requestParams,
    });
    setValidate(true);

    if (newParams.company_id) {
      getContracts({ id: newParams.company_id, errorCallback });
      getActivationOrderList({ company_id: newParams.company_id, errorCallback });
    }
    setTransactionTableParams({
      ...newParams,
      show_deleted: getBooleanValue(newParams.show_deleted),
      urlFiltersIsSet: true,
    });
  }, []);

  useEffect(() => {
    if (!!transactionTableParams.company_id && transactionTableParams.urlFiltersIsSet) {
      if (company.id !== transactionTableParams.company_id) {
        const currentCompany = companies.find((item) => item.id === transactionTableParams.company_id);

        if (currentCompany) {
          setCompany(currentCompany);
          getContracts({ id: transactionTableParams.company_id, errorCallback });
          getActivationOrderList({ company_id: transactionTableParams.company_id, errorCallback });
        }
      }
    }

    if (
      !!transactionTableParams.order_id &&
      !!transactionTableParams.company_id &&
      transactionTableParams.urlFiltersIsSet
    ) {
      const currentAO = autocompleteAOData(activationOrderListData).find(
        (item) => item.id === transactionTableParams.order_id,
      );

      if (currentAO) {
        setActivationOrder(currentAO);
      }
    }

    if (
      !!transactionTableParams.contract_number &&
      !!transactionTableParams.company_id &&
      transactionTableParams.urlFiltersIsSet
    ) {
      const currentContract = autocompleteContractData(contractsData.contracts).find(
        (item) => item.id === transactionTableParams.contract_number,
      );

      if (currentContract) {
        setContract(currentContract);
      }
    }

    if (!!transactionTableParams.currency && transactionTableParams.urlFiltersIsSet) {
      const currentCurrency = currencyChoicesAutocomplete.find((item) => item.id === transactionTableParams.currency);
      setCurrency(currentCurrency);
    }

    if (!!transactionTableParams.tx_type && transactionTableParams.urlFiltersIsSet) {
      const currentTxType = txTypeAutocomplete.find((item) => item.id === transactionTableParams.tx_type);
      setTxType(currentTxType);
    }

    if (!!transactionTableParams.status && transactionTableParams.urlFiltersIsSet) {
      const currentTxStatus = statusChoicesAutocomplete.find((item) => item.id === transactionTableParams.status);
      setTxStatus(currentTxStatus);
    }
  }, [companies, contractsData, activationOrderListData, transactionTableParams, errorCallback]);

  useEffect(() => {
    if (validate) {
      if (transactionTableParams.urlFiltersIsSet) {
        getTransactionsV2({ ...transactionTableParams, errorCallback });
      }
      refreshContext.clear();
      refreshContext.add({ func: getCompanies, params: { errorCallback } });

      if (transactionTableParams.company_id) {
        refreshContext.setParams({ datakey: 'transactionTableParams', params: transactionTableParams });
        refreshContext.add({
          func: getActivationOrderList,
          params: { company_id: transactionTableParams.company_id, errorCallback },
        });
      }
      refreshContext.add({
        func: getTransactionsV2,
        params: { ...transactionTableParams, errorCallback },
      });
    }
  }, [
    getTransactionsV2,
    getCompanies,
    transactionTableParams,
    refreshContext,
    errorCallback,
    getActivationOrderList,
    validate,
  ]);

  useEffect(
    () => () => {
      refreshContext.clear();
    },
    [refreshContext],
  );

  useEffect(() => {
    refreshContext.setParams({ datakey: 'transactionTableParams', params: transactionTableParams });
    setUrlStringParams({ filters: TransactionTableFilters, currentScreenParams: transactionTableParams });
  }, [transactionTableParams]);

  const onChangeCompanyHandler = useCallback(
    (_, values: CompanyType | null) => {
      clearActivationOrderListData();
      clearContractsData();

      if (values?.id) {
        getContracts({ id: values.id, errorCallback });
        getActivationOrderList({ company_id: values.id, errorCallback });
      }
      const newParams = {
        ...transactionTableParams,
        company_id: values ? values.id : '',
        company: values,
        order_id: '',
        page: 0,
        contract_number: '',
      };
      setCompany(values);
      setActivationOrder({ name: '', id: '' });
      setContract({ name: '', id: '' });
      setTransactionTableParams(newParams);

      if (validate) {
        getTransactionsV2({ ...newParams, errorCallback });
      }
    },
    [setTransactionTableParams, getContracts, transactionTableParams, errorCallback, getTransactionsV2, validate],
  );

  const handleContractChange = useCallback(
    (_, values) => {
      const newParams = { ...transactionTableParams, contract_number: values?.id || '' };
      setTransactionTableParams(newParams);

      if (values) {
        getActivationOrderList({
          company_id: transactionTableParams.company_id,
          contract_number: values?.id,
          _sort: 'name',
          errorCallback,
        });
      }
      setContract(values);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  const handleActivationOrderChange = useCallback(
    (_, values) => {
      const newParams = { ...transactionTableParams, order_id: values?.id || '', contract_number: '', page: 0 };
      setTransactionTableParams(newParams);
      setActivationOrder(values);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  const handleTxTypeChange = useCallback(
    (_, values) => {
      const newParams = {
        ...transactionTableParams,
        tx_type: values !== null ? values.id : '',
        page: 0,
      };
      setTransactionTableParams(newParams);
      setTxType(values);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  const handleChangeCurrency = useCallback(
    (_, values) => {
      const newParams = {
        ...transactionTableParams,
        currency: values !== null ? values.id : '',
        page: 0,
      };
      setTransactionTableParams(newParams);
      setCurrency(values);
    },
    [setTransactionTableParams, transactionTableParams],
  );
  const handleChangeStatus = useCallback(
    (_, values) => {
      const newParams = {
        ...transactionTableParams,
        status: values !== null ? values.id : '',
        page: 0,
      };
      setTransactionTableParams(newParams);
      setTxStatus(values);
    },
    [setTransactionTableParams, transactionTableParams],
  );

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.target;

      if (name === 'showDeleted') {
        const newParams = { ...transactionTableParams, show_deleted: checked };
        getCompanies({ showDeleted: checked, errorCallback });

        if (!checked) {
          newParams.page = 0;
        }
        setTransactionTableParams(newParams);
      } else if (name === 'date_from' || name === 'date_to') {
        const { validatedErrors, validatedStatus } = dateValidator({
          name,
          value,
          filterParams: transactionTableParams,
          errors: {},
          isNotRequired: true,
        });
        setValidate(validatedStatus);

        if (!validatedStatus) {
          clearTransactionDataV2();
        }
        setErrorDate(validatedErrors?.date_from?.status || validatedErrors?.date_to?.status);
        setTransactionTableParams({ ...transactionTableParams, [name]: value, page: 0 });
      } else {
        setTransactionTableParams({ ...transactionTableParams, [name]: value, page: 0 });
      }
    },
    [setTransactionTableParams, transactionTableParams, dateValidator, clearTransactionDataV2],
  );

  const createHandler = useCallback(
    (index) => {
      let route;

      if (index === 0) {
        route = `${urls.getTransactionCreate()}?_type=in`;
      }

      if (index === 1) {
        route = `${urls.getTransactionCreate()}?_type=out`;
      }

      if (index === 2) {
        route = urls.getAdjustingTransactionCreate();
      }

      if (index === 3) {
        route = urls.getConversionTransactionCreate();
      }
      history.push(route);
    },
    [history],
  );

  useEffect(
    () => () => {
      setTransactionTableParams({ ...initialTransactionTableParams, ...transactionTableParams });
    },
    [],
  );

  const dateError = useCallback(() => {
    setValidate(false);
  }, []);

  const aoDateLength = autocompleteAOData(activationOrderListData).length;
  const contractLength = autocompleteContractData(contracts).length;

  let styleBlock = 'repeat(auto-fit, minmax(100px, 348px))';

  if (aoDateLength && contractLength) {
    styleBlock = '348px 1fr 1fr';
  }

  return (
    <div style={{ ...wrapperStyle, position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: -7 }}>
        <SelectButton
          title={t('tables.createTransaction')}
          list={[
            t('tables.incoming'),
            t('tables.outdoing'),
            t('forms.transaction.adjusting'),
            t('forms.transaction.conversion'),
          ]}
          callback={createHandler}
        />
      </div>
      <Title text={title} />
      <div style={{ marginRight: 60, position: 'relative' }}>
        <EditGeneral
          callback={(target, action) => {
            const { checked } = target;

            if (action === 'show_deleted') {
              const newParams = { ...transactionTableParams, show_deleted: checked };
              getCompanies({ ...newParams, errorCallback });

              if (!checked) {
                newParams.page = 0;
              }
              setTransactionTableParams(newParams);
            }
          }}
          transactionTableParams={transactionTableParams}
        />
        <button type="button" style={{ ...buttonStyles, right: -60, top: 52 }}>
          <img src={downloadSvg} alt="download" />
        </button>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '164px 164px 164px minmax(140px, 348px) minmax(140px, 348px)',
            alignItems: 'center',
            marginTop: 14,
            columnGap: 20,
            marginBottom: 12,
          }}
        >
          <DateInput
            isNotErrorLocal
            isError={errorDate}
            date={transactionTableParams.date_from}
            labelKey="tables.dateFrom"
            name="date_from"
            onChangeDateHandler={setFormValue}
            commonStyle={{ height: 40 }}
            inputStyle={{ paddingLeft: 17 }}
            stylingLabel={{ paddingLeft: 8 }}
            setError={dateError}
          />
          <DateInput
            isNotErrorLocal
            isError={errorDate}
            date={transactionTableParams.date_to}
            labelKey="tables.dateTo"
            name="date_to"
            onChangeDateHandler={setFormValue}
            commonStyle={{ height: 40 }}
            inputStyle={{ paddingLeft: 17 }}
            stylingLabel={{ paddingLeft: 8 }}
            setError={dateError}
          />
          <DropdownKit
            label={t('contentHeader.currency')}
            options={currencyChoicesAutocomplete}
            value={currency}
            keyObj="name"
            positionType={DropdownSizeEnum.SIZE_40}
            changeSelectItem={handleChangeCurrency}
          />
          <DropdownKit
            label={t('forms.transaction.type')}
            options={txTypeAutocomplete}
            keyObj="name"
            value={txType}
            positionType={DropdownSizeEnum.SIZE_40}
            changeSelectItem={handleTxTypeChange}
          />
          <DropdownKit
            options={statusChoicesAutocomplete}
            keyObj="name"
            value={txStatus}
            positionType={DropdownSizeEnum.SIZE_40}
            changeSelectItem={handleChangeStatus}
            label={t('contentHeader.status')}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: styleBlock,
            alignItems: 'center',
            marginTop: 14,
            columnGap: 20,
            marginBottom: 12,
          }}
        >
          <DropdownKit
            options={companies}
            keyObj="name"
            value={company}
            positionType={DropdownSizeEnum.SIZE_40}
            changeSelectItem={onChangeCompanyHandler}
            label={t('tables.client')}
          />
          {contractLength > 0 && (
            <DropdownKit
              options={autocompleteContractData(contracts)}
              keyObj="name"
              value={contract}
              positionType={DropdownSizeEnum.SIZE_40}
              changeSelectItem={handleContractChange}
              label={t('tables.contracts')}
            />
          )}
          {aoDateLength > 0 && (
            <DropdownKit
              options={autocompleteAOData(activationOrderListData)}
              keyObj="name"
              value={activationOrder}
              positionType={DropdownSizeEnum.SIZE_40}
              changeSelectItem={handleActivationOrderChange}
              label={t('forms.transaction.ao')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionTableHeaderOld;

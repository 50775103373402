import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/uiKit/Button';
import PopUpLayout from 'helpers/PopUpLayout';
import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';

import ItemsAo from '../../CardsAo/ItemsAo';

interface TitleWithDateType {
  isOpen: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
  leftCallback?: () => void;
  rightCallback?: () => void;
  isSubmittingForm: boolean;
}

const wrapStyle = {
  backgroundColor: '#F7F8FA',
  borderRadius: 16,
  padding: 40,
  boxSizing: 'border-box' as const,
};

const CreateTransactionPopUp: FC<TitleWithDateType> = ({
  isOpen,
  onClose,
  style,
  leftCallback,
  rightCallback,
  isSubmittingForm,
}) => {
  const { t } = useTranslation();
  const { aoFormData }: useCompanyType = useCompany();

  return (
    <PopUpLayout style={{ ...wrapStyle, ...style }} open={isOpen} onClose={onClose}>
      <div style={{ color: '#606176', fontSize: 24, marginBottom: 30, marginTop: 6 }}>
        {t('forms.activationOrder.createTransaction')}
      </div>
      <ItemsAo style={{ height: 60 }} types={[KeyCardsEnum.TX_DATE, KeyCardsEnum.AMOUNT]} />
      <ItemsAo style={{ height: 60 }} types={[KeyCardsEnum.FACTOR_BANK_ACCOUNT]} />
      <ItemsAo style={{ height: 24 }} types={[KeyCardsEnum.CHECKBOX]} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {!aoFormData.checkbox && <ItemsAo style={{ height: 60 }} types={[KeyCardsEnum.RECIPIENT]} />}
      <div style={{ display: 'flex', marginTop: 32 }}>
        <Button
          variant="fill"
          color="grey_light"
          onClick={leftCallback}
          style={{ marginRight: 40, height: 60, width: 173 }}
          loading={isSubmittingForm}
        >
          {t('forms.activationOrder.createTransaction')}
        </Button>
        <Button onClick={rightCallback} style={{ height: 60, width: 89 }}>
          {t('forms.activationOrder.cancel')}
        </Button>
      </div>
    </PopUpLayout>
  );
};

export default CreateTransactionPopUp;

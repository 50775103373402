export function parseAmount(amount: string | number | null): number {
  if (amount === null) return 0;

  return typeof amount === 'string' ? Number.parseFloat(amount) : amount;
}

export function parseDate(date: string | Date) {
  if (typeof date === 'string') {
    return new Date(date);
  }

  if (date instanceof Date) {
    return date;
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function parseAmountWithSign(amount, txType) {
  const num = parseAmount(amount);

  return txType === 'OUT' || num === 0 ? num : -num;
}

export function acronymForAvatar(name: string, ifEmpty: string): string {
  if (!name) return ifEmpty;

  return name.split(/\s/).reduce((akk, word) => akk + word.slice(0, 1), '');
}

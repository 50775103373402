import endpoints from 'consts/endpoints';
import { getYYYYMMDD } from 'helpers/formatters';
import { requestAsync } from 'redux-query';

export interface listTransactionsDataType {
  params?: any;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

// special for beck-end
const parseDate = (value: string) => {
  const date = new Date(value);
  // NOTE: чтобы расчеты делались по 23:59:59 от date, нужно передать апишке следующую за этим днем дату
  date.setDate(date.getDate() + 1);
  // eslint-disable-next-line consistent-return
  if (date.toDateString() === 'Invalid Date') return;
  // eslint-disable-next-line consistent-return
  return getYYYYMMDD(date);
};

export default ({
  params,
  errorCallback,
  resultKey = 'listTransactionData',
  successCallback,
}: listTransactionsDataType) => {
  // special for beck-end
  const newObj = params?.filter
    ? () =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        JSON.parse(decodeURI(params.filter)).map((item) => {
          if (item.property === 'date_from') {
            return { property: 'value_datetime', operator: item?.operator, value: item?.value };
          }

          if (item.property === 'date_to') {
            return { property: 'value_datetime', operator: item?.operator, value: parseDate(item?.value) };
          }

          if (item.property === 'amount') {
            return { property: 'amount', operator: item?.operator, value: Number(item?.value) };
          }

          return item;
        })
    : () => params;

  return requestAsync({
    url: endpoints.getListTransactionsUrl(params.filter ? { ...params, filter: JSON.stringify(newObj()) } : params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: 'listTransactionQuery',
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });
};

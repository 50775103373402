import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type ParamsT = {
  credentials: any;
  companyId: string;
  storeId: string;
};

export type connectPlatformType = {
  data: ParamsT;
  resultKey?: string;
  errorCallback?: Function;
  successCallback?: Function;
};

export default ({ data, errorCallback, resultKey = 'connectPlatform', successCallback }: connectPlatformType) =>
  requestAsync({
    url: endpoints.connectPlatformUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.connectPlatformUrl(),
    body: data,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

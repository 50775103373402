import React, { ButtonHTMLAttributes, CSSProperties, FC, ReactNode } from 'react';
import classnames from 'classnames';

import loadingSvgPath from 'assets/img/loading.svg';

import styles from './styles.module.scss';

type VariantT = 'outline' | 'fill' | 'text';
type ColorT = 'gray' | 'red' | 'grey_light';

const MAP_COLOR_TO_CSS_COLOR: Record<ColorT, { color: string; hover: string; focus: string }> = {
  red: {
    focus: '#EC3E72',
    hover: '#D31A53',
    color: '#EC3E72',
  },
  gray: {
    focus: '#8E9BA7',
    hover: '#8995A0',
    color: '#606176',
  },
  grey_light: {
    focus: '#8995A0',
    hover: '#8E9BA7',
    color: '#A7AFB7',
  },
};

export interface PropsT extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: VariantT;
  color?: ColorT;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  gutters?: number[];
  loading?: boolean;
  onlyLoadingIcon?: boolean;
}

const Button: FC<PropsT> = (props) => {
  const {
    startAdornment,
    endAdornment,
    gutters,
    children,
    className,
    color = 'gray',
    variant = 'outline',
    loading = false,
    onlyLoadingIcon = false,
    disabled,
    style,
    ...restProps
  } = props;
  const padding = gutters?.map((gutter) => `${gutter}px`).join(' ') || '';
  const styleColor = MAP_COLOR_TO_CSS_COLOR[color];

  const newStyle = {
    padding,
    '--bg-color': styleColor.color,
    '--bg-color-hover': styleColor.hover,
    '--bg-color-focus': styleColor.focus,
    ...style,
  } as CSSProperties;
  const isShowContent = !(onlyLoadingIcon && loading);

  return (
    <button
      style={newStyle}
      type="button"
      className={classnames(styles.button, styles[`button_${variant}`], className, {
        [styles['button--onlyLoadingIcon']]: onlyLoadingIcon,
      })}
      disabled={loading || disabled}
      {...restProps}
    >
      {loading && <img alt="loading-icon" src={loadingSvgPath} width="18" height="18" />}
      {isShowContent && (
        <>
          {startAdornment && startAdornment}
          {children}
          {endAdornment && endAdornment}
        </>
      )}
    </button>
  );
};

export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Status, SelectProps } from '@gamesb42/ui-kit';

import { REVENUE_SOURCES_STATUS_OPTIONS, REVENUE_SOURCES_STATUS_COLOR } from 'constants/revenueSources';

import styles from './styles.module.scss';

const RevenueSourcesStatus = (props: Partial<SelectProps>) => {
  const { t } = useTranslation();

  return (
    <Select label={t('global.status')} className={styles.root} {...props}>
      {REVENUE_SOURCES_STATUS_OPTIONS.map((e) => (
        <Select.Option value={e.value} key={e.value}>
          <Status color={REVENUE_SOURCES_STATUS_COLOR[e.value]} className={styles.status}>
            {e.label}
          </Status>
        </Select.Option>
      ))}
    </Select>
  );
};

export default RevenueSourcesStatus;

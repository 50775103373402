import React from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import { history } from 'configureStore';
import urls from 'constants/urls';
import TransactionList from 'pages/transaction/ListScreen';
import TransactionCreateScreen from 'pages/transaction/CreateScreen';
import TransactionEditScreen from 'pages/transaction/EditScreen';
import AdjustingTransactionCreateScreen from 'pages/transaction/AdjustingTransactionCreateScreen';
import AdjustingTransactionEditScreen from 'pages/transaction/AdjustingTransactionEditScreen';
import ConversionTransactionCreateScreen from 'pages/transaction/ConversionTransactionCreateScreen';
import ConversionTransactionEditScreen from 'pages/transaction/ConversionTransactionEditScreen';
import ActivationListScreen from 'pages/activation/ListScreen';
import ActivationCreateScreen from 'pages/activation/AOCreateScreen';
import AOEditScreen from 'pages/activation/AOEditScreen';
import InterestsReportScreen from 'pages/InterestsReportScreen';
import ActivationOrderEditScreen from 'pages/activation/ActivationOrderEditScreen';
import PurchaseRequestScreen from 'pages/purchaseRequest/PurchaseRequestScreen';
import ExpectedPaymentsScreen from 'pages/ExpectedPaymentsScreen';
import PivotTransactionReportsScreen from 'pages/transaction/PivotTransactionReportsScreen';
import ClientAmountsReportScreen from 'pages/amount/ClientAmountsReportScreen';
import CompanyList from 'pages/Company/CompanyList';
import CompanyCreateScreen from 'pages/Company/CreateScreen';
import CompanyEditScreen from 'pages/Company/EditScreen';
import CompanyScreen from 'pages/Company/GeneralScreen';
// Users
import Users from 'pages/Users';
import CreateEditUser from 'pages/Users/CreateEditUser';
import CreateEditAdmin from 'pages/Users/CreateEditAdmin';
import AdminListScreen from 'pages/admin/ListScreen';
import AdminCreateScreen from 'pages/admin/CreateScreen';
import AdminEditScreen from 'pages/admin/EditScreen';
import LoadAllDataScreen from 'pages/LoadAllDataScreen';
import ImportDataScreen from 'pages/ImportDataScreen';
import SystemSettingsScreen from 'pages/SystemSettingsScreen';
import PurchaseRequestListScreen from 'pages/purchaseRequest/ListScreen';
import {
  AOFilters,
  PurchaseFilters,
  CommissionReportFilter,
  ExpectedPaymentsFilters,
  PivotTransactionFilters,
  ClientsAmountReportFilters,
  TransactionTableFilters,
  AdminsTableFilters,
  clientClaimsFilters,
  revenueByDays,
} from 'consts/tableFilters';
import AccrualReportScreen from 'pages/AccrualReportScreen';
import PurchaseRequestEditScreen from 'pages/purchaseRequest/EditScreen';
import PurchaseRequestCreateScreen from 'pages/purchaseRequest/CreateScreen';
import ReconciliationReportScreen from 'pages/reports/reconciliation';
import SellerAccountBalance from 'pages/reports/SellerAccountBalance';
import RevenueSources from 'pages/RevenueSources';
import RevenueSourcesCreateEdit from 'pages/RevenueSources/RevenueSourcesCreateEdit';
import TransactionsListScreen from 'pages/transaction/TransactionsListScreen';
import TransactionsFormScreen from 'pages/transaction/TransactionsFormScreen';
import ViewTransactionListScreen from 'pages/transaction/ViewTransactionListScreen';
import LogIn from 'pages/LogIn';
import FactorDetails from 'pages/FactorDetails';
import Dashboard from 'pages/reports/dashboard';

import NoAccess from 'pages/NoAccess';

import AddNewPlatform from '../components/forms/AddNewPlatform';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import WorkingCapital from 'pages/reports/WorkingCapital';
import CreateWorkingCapital from 'pages/reports/WorkingCapital/CreateWorkingCapital';
import CardWorkingCapital from 'pages/reports/WorkingCapital/CardWorkingCapital';
import EditWorkingCapital from 'pages/reports/WorkingCapital/EditWorkingCapital';
import PartialRepaymentWorkingCapital from 'pages/reports/WorkingCapital/PartialRepaymentWorkingCapital';

const AppRoutes = () => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Router history={history}>
    <Switch>
      <PublicRoute path={`${urls.getAuth()}/:path`} component={LogIn} />
      {/* /Transactions pages / */}
      <PrivateRoute
        exact
        styles={{ backgroundColor: '#F7F8FA' }}
        path={urls.getTransaction()}
        component={TransactionList}
        filters={TransactionTableFilters}
      />
      <PrivateRoute
        exact
        styles={{ backgroundColor: '#F7F8FA' }}
        path={urls.getTransactions()}
        component={TransactionsListScreen}
        filters={TransactionTableFilters}
      />
      <PrivateRoute
        exact
        styles={{ backgroundColor: '#F7F8FA' }}
        path={urls.getViewTransactions()}
        component={ViewTransactionListScreen}
        filters={['deleted']}
      />
      <PrivateRoute exact path={urls.getTransactionsFormEdit()} component={TransactionsFormScreen} />
      <PrivateRoute exact path={urls.getTransactionsFormCreate()} component={TransactionsFormScreen} />
      {/* old */}
      <PrivateRoute exact path={urls.getTransactionCreate()} component={TransactionCreateScreen} />
      <PrivateRoute exact path={urls.getTransactionEdit()} component={TransactionEditScreen} />
      <PrivateRoute exact path={urls.getAdjustingTransactionCreate()} component={AdjustingTransactionCreateScreen} />
      <PrivateRoute exact path={urls.getAdjustingTransactionEdit()} component={AdjustingTransactionEditScreen} />
      <PrivateRoute exact path={urls.getConversionTransactionCreate()} component={ConversionTransactionCreateScreen} />
      <PrivateRoute exact path={urls.getConversionTransactionEdit()} component={ConversionTransactionEditScreen} />
      {/* /AO pages / */}
      <PrivateRoute
        exact
        path={urls.getActivationOrder()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={ActivationListScreen}
        filters={AOFilters}
      />
      <PrivateRoute exact path={urls.getActivationOrderCreate()} component={ActivationCreateScreen} />
      <PrivateRoute
        exact
        path={urls.getActivationOrderEdit()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={AOEditScreen}
      />
      <PrivateRoute exact path={urls.getActivationOrderEditForm()} component={ActivationOrderEditScreen} />
      {/* Purchase Request screen */}
      <PrivateRoute
        exact
        path={urls.getPurchaseRequestList()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={PurchaseRequestListScreen}
        filters={PurchaseFilters}
      />
      <PrivateRoute
        exact
        path={urls.getPurchaseRequest()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={PurchaseRequestScreen}
      />
      <PrivateRoute
        exact
        path={urls.getPurchaseRequestCreate()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={PurchaseRequestCreateScreen}
      />
      <PrivateRoute
        exact
        path={urls.getPurchaseRequestEdit()}
        styles={{ backgroundColor: '#F7F8FA' }}
        component={PurchaseRequestEditScreen}
      />
      {/* /Expected Payments screen / */}
      <PrivateRoute
        exact
        path={urls.getExpectedPayments()}
        component={ExpectedPaymentsScreen}
        filters={ExpectedPaymentsFilters}
      />
      {/* /Report / */}
      <PrivateRoute
        exact
        path={urls.getCommissionReport()}
        component={InterestsReportScreen}
        filters={CommissionReportFilter}
      />
      <PrivateRoute
        exact
        path={urls.getPivotTransactions()}
        component={PivotTransactionReportsScreen}
        filters={PivotTransactionFilters}
      />
      <PrivateRoute
        exact
        path={urls.getClientAmountsReport()}
        component={ClientAmountsReportScreen}
        filters={ClientsAmountReportFilters}
      />
      <PrivateRoute exact path={urls.getAccrualReport()} component={AccrualReportScreen} />
      <PrivateRoute exact path={urls.getReconciliationReport()} component={ReconciliationReportScreen} />
      <PrivateRoute exact path={urls.getSellerAccountBalance()} component={SellerAccountBalance} />
      {/* /Clients pages / */}
      {/* TODO: Create and edit company - сделать одним компонентом */}
      <PrivateRoute exact path={urls.getCompany()} component={CompanyList} />
      <PrivateRoute exact path={urls.getCompanyCreate()} component={CompanyCreateScreen} />
      <PrivateRoute exact path={urls.getCompanyEdit()} component={CompanyEditScreen} />
      <PrivateRoute
        exact
        path={urls.getCompanyById()}
        filters={[...clientClaimsFilters, ...revenueByDays]}
        component={CompanyScreen}
      />
      <PrivateRoute
        exact
        path={urls.getCompanyByIdSecond()}
        filters={[...clientClaimsFilters, ...revenueByDays]}
        component={CompanyScreen}
      />
      {/* /Users pages / */}
      <PrivateRoute exact path={urls.getUsers()} component={Users} />
      <PrivateRoute exact path={urls.getCreateUser()} component={CreateEditUser} />
      <PrivateRoute exact path={urls.getCreateAdmin()} component={CreateEditAdmin} />
      <PrivateRoute exact path={urls.getUser()} component={CreateEditUser} />
      {/* <PrivateRoute exact path={urls.getCheckInQueueList()} component={CheckInQueueListScreen} /> */}
      {/* <PrivateRoute exact path={urls.getCheckInQueueEdit()} component={CheckInQueueEditScreen} /> */}
      {/* /Load Data (sett) / */}
      <PrivateRoute exact path={urls.getLoadOldData()} component={LoadAllDataScreen} />
      {/* /Import Data (sett) / */}
      <PrivateRoute exact path={urls.getImportData()} component={ImportDataScreen} />
      {/* /Admins pages / */}
      <PrivateRoute exact path={urls.getAdmin()} component={AdminListScreen} filters={AdminsTableFilters} />
      <PrivateRoute exact path={urls.getAdminCreate()} component={AdminCreateScreen} />
      <PrivateRoute exact path={urls.getAdminEdit()} component={AdminEditScreen} />
      {/* /System Settings Data (sett) / */}
      <PrivateRoute exact path={urls.getSettingsFactorDetails()} component={FactorDetails} />
      <PrivateRoute exact path={urls.getSettingsPath()} component={SystemSettingsScreen} />
      <PrivateRoute exact path={urls.getSystemPath()} component={SystemSettingsScreen} />
      <PrivateRoute exact path={urls.getDirectoriesPath()} component={SystemSettingsScreen} />
      {/* Revenue Sources */}
      <PrivateRoute exact path={urls.getRevenueSourceList()} component={RevenueSources} />
      <PrivateRoute exact path={urls.getRevenueSourcesCreate()} component={RevenueSourcesCreateEdit} />
      <PrivateRoute exact path={urls.getRevenueSourcesEdit()} component={RevenueSourcesCreateEdit} />
      {/* <PrivateRoute exact path={urls.getRevenueSourcesCreate()} component={RevenueSourcesCreateEdit} />
      <PrivateRoute exact path={urls.getRevenueSourcesEdit()} component={RevenueSourcesCreateEdit} /> */}
      <PrivateRoute exact path={urls.getAddNewPlatformForCompany()} component={AddNewPlatform} />
      <PrivateRoute exact path={urls.getDashboard()} component={Dashboard} />
      <PrivateRoute exact path={urls.getWorkingCapital()} component={WorkingCapital} />
      <PrivateRoute exact path={urls.getWorkingCapitalCreate()} component={CreateWorkingCapital} />
      <PrivateRoute exact path={urls.getWorkingCapitalById()} component={CardWorkingCapital} />
      <PrivateRoute exact path={urls.getWorkingCapitalEdit()} component={EditWorkingCapital} />
      <PrivateRoute exact path={urls.getWorkingCapitalPartialRepayment()} component={PartialRepaymentWorkingCapital} />
      {/* No Access */}
      <PublicRoute exact path={urls.getNoAccess()} component={NoAccess} />
      {/* <Route path="*"> */}
      <Redirect to={urls.getTransactions()} />
      {/* </Route> */}
    </Switch>
  </Router>
);
export default AppRoutes;

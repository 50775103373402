import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import useAmount, { useAmountType } from 'hooks/api/useAmount';
import { formatAmount } from 'helpers/formatters';
import { tableBodyStyles, tableSettingsStyles } from 'theme/styles';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import SortTable from 'components/SortTable';

import { tableCellData } from './config';
import ClientAmountReportTableSkeleton from './ClientAmountReportTableSkeleton';

const getColorTableCell = (key: string) => {
  switch (key) {
    case 'Total':
      return '#ECEEF2';
    default:
      return undefined;
  }
};

const ClientAmountReportTable = () => {
  const classes = tableSettingsStyles();
  const styles = tableBodyStyles();
  const { t } = useTranslation();
  const {
    clientAmountsReportData,
    clientAmountsTableParams,
    setClientAmountsTableParams,
    isPending = true,
  }: any = useAmount();

  const clientAmountsReport = useMemo(() => clientAmountsReportData, [clientAmountsReportData]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { _sort, _order } = useMemo(() => clientAmountsTableParams, [clientAmountsTableParams]);

  const handleChangeSorting = useCallback(
    (label, orderLocal) => {
      if (!label || !orderLocal) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setClientAmountsTableParams({
        ...clientAmountsTableParams,
        _order: orderLocal,
        _sort: label,
      });
    },
    [clientAmountsTableParams],
  );

  return (
    <TableWrapWithPagination
      styleTop={{ top: 100 }}
      count={100}
      onPageChange={() => {}}
      onRowsPerPageChange={() => {}}
      isShowPagination={false}
    >
      <TableHead>
        <TableRow
          classes={{ root: `${classes.headTableRowRoot} ${classes.headTableRowRootIcon}` }}
          style={{ height: 101 }}
        >
          {tableCellData.map(({ id, title, dataKey, typeSort, isHiddenSort }) => (
            <TableCell
              classes={{ root: classes.headTableCellRoot }}
              key={id}
              align="right"
              style={{ verticalAlign: 'bottom' }}
            >
              <SortTable
                isActive={_sort === dataKey}
                order={_order}
                onClickHandler={(order) => handleChangeSorting(dataKey, order)}
                type={typeSort}
                isHiddenSort={isHiddenSort}
              >
                {t(title)}
              </SortTable>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.bodyTableRoot }}>
        {!isPending &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          clientAmountsReport.map((item, i) => (
            <TableRow classes={{ root: classes.bodyTableRowRoot }} key={item.id}>
              {tableCellData.map(({ id, bodyAlign, dataKey, isAmount }) => (
                <TableCell
                  style={{
                    backgroundColor:
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      i === clientAmountsReport.length - 1 ? getColorTableCell(item.company_name) : undefined,
                  }}
                  classes={{ root: `${classes.bodyTableCellRoot} ${id === 1 ? styles.nameWidth : ''}` }}
                  key={id}
                  align={bodyAlign || 'inherit'}
                >
                  {isAmount
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      formatAmount<{ maximumFractionDigits: number }>(Number(item[dataKey]), null, false, {
                        maximumFractionDigits: 2,
                      })
                    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item[dataKey]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        {isPending && (
          <>
            {Array(15)
              .fill(0)
              .map((_e, index) => (
                <ClientAmountReportTableSkeleton />
              ))}
          </>
        )}
      </TableBody>
    </TableWrapWithPagination>
  );
};

export default ClientAmountReportTable;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { minusSymbol } from 'consts/dict';

import { parseDate } from './parsers';
import { firstDayOfMonth } from './dt';

const intlFormatterDate = new Intl.DateTimeFormat('en', { month: 'short', year: 'numeric', day: '2-digit' });

export const monthNames: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getBooleanValue = (param: string | undefined) => !(!param || param === 'false');

export const getDecimal = (local: string) => {
  switch (local) {
    case 'ru':
      return '.';
    case 'ru-RU':
      return '.';
    case 'en':
      return ',';
    case 'en-US':
      return ',';
    default:
      return ',';
  }
};

export const getCurrency = (currency: string) => {
  if (!currency) return '';

  if (currency.toUpperCase() === 'EUR') {
    return '€';
  }

  if (currency.toUpperCase() === 'USD') {
    return '$';
  }

  if (currency.toUpperCase() === 'RUB') {
    return '₽';
  }

  return '';
};

export const getNameCurrencyOfCode = (code) => {
  if (!code) return '';
  const codeLocal = Number(code);

  if (codeLocal === 978) {
    return 'EUR';
  }

  if (codeLocal === 840) {
    return 'USD';
  }

  if (codeLocal === 643) {
    return 'RUB';
  }

  return '';
};

export const getCodeCurrencyOfName = (name = '') => {
  if (!name) return 0;
  const nameLocal = name.toString().toUpperCase();

  if (nameLocal === 'EUR') {
    return 978;
  }

  if (nameLocal === 'USD') {
    return 840;
  }

  return 0;
};

export const getCurrencyWithCode = (num: number) => {
  if (!num) return '';

  if (num === 978) {
    return '€';
  }

  if (num === 840) {
    return '$';
  }

  if (num === 643) {
    return '₽';
  }

  return '';
};

export function formatDate(date: Date | string) {
  const dt: Date = parseDate(date);

  if (dt == null) return null;
  let locale = navigator.language || 'ru-RU';

  if (navigator.language === 'en-US' || navigator.language === 'en') {
    locale = 'es-PA';
  }

  const newDate = dt.toLocaleDateString(locale);

  // NOTE: DD/MM/YYYY - этот формат заменяем на Month(short) DD, YYYY
  if (newDate.includes('/')) return intlFormatterDate.format(dt);

  return newDate;
}
// example format  08:05 | 14.04.2022
export const formatDateWithHour = (date: Date | string) => {
  const dt = new Date(date);

  return `${`0${dt.getUTCHours()}`.slice(-2)}:${`0${dt.getUTCMinutes()}`.slice(-2)} | ${formatDate(date)}`;
};

// format  yyyy-mm-dd
export const getYYYYMMDD = (date: Date | string) => new Date(date).toISOString().slice(0, 10);

export function formatDateIsoShort(date: Date | string) {
  const dt: Date = parseDate(date);

  if (dt == null) return null;
  const mm: number = dt.getMonth() + 1;
  const dd: number = dt.getDate();

  return [formatDateFullYear(String(dt.getFullYear())), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
}

function formatDateFullYear(year: string) {
  if (year.length < 4) {
    return formatDateFullYear(`0${year}`);
  }

  return year;
}

export const getCurrentYear = () => {
  const dtFrom = new Date();

  return dtFrom.getFullYear();
};

export function formatDateByMinute(date) {
  if (!date) {
    return '';
  }
  const formatDateTime = formatDate(date);
  let hours: string | number = new Date(date).getHours();
  let minutes: string | number = new Date(date).getMinutes();
  let seconds: string | number = new Date(date).getSeconds();
  let milliSeconds: string | number = new Date(date).getMilliseconds();

  if (Number(hours) < 10) {
    hours = String(`0${hours}`);
  }

  if (Number(minutes) < 10) {
    minutes = String(`0${minutes}`);
  }

  if (Number(seconds) < 10) {
    seconds = String(`0${seconds}`);
  }

  if (Number(milliSeconds) < 10) {
    milliSeconds = String(`0${milliSeconds}`);
  }

  return `${formatDateTime}  ${hours}:${minutes}:${seconds}:${milliSeconds}`;
}

export function getMonthName(date: Date | string) {
  const dt: Date = parseDate(date);

  if (dt == null) return null;

  return monthNames[dt.getMonth()];
}

export function formatMonthYear(date: Date | string) {
  const dt: Date = parseDate(date);

  if (dt == null) return null;

  return `${getMonthName(dt)} ${dt.getFullYear()}`;
}

type displayOptionType = {
  style?: string;
  useGrouping?: boolean;
  minimumFractionDigits?: number;
  currency?: string;
  currencyDisplay?: string;
};

export const formatAmount = <T>(amount: number, currency?: string | null, notZero?: boolean, options?: T) => {
  if (amount === null) return null;
  const displayOptions: displayOptionType & T = {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    ...options,
  };

  if (currency != null) {
    displayOptions.style = 'currency';
    displayOptions.currency = currency;
    displayOptions.currencyDisplay = 'code';
  }
  const result = Intl.NumberFormat('ru-RU', displayOptions).format(amount);

  if (notZero && result === '0,00') {
    return null;
  }

  return result;
};

export const newFormatAmount = (amount: number, currency?: number | string) => {
  let result = '';

  if (currency && typeof currency === 'string') result += getCurrency(currency);

  if (currency && typeof currency === 'number') result += getCurrencyWithCode(currency);

  result += formatAmount(amount);

  return result;
};

export const formatAmountNoZero = (amount: number, currency?: string) => {
  if (amount === 0) {
    return '';
  }

  return formatAmount(amount, currency);
};

export const formatStringEllipsis = (comment: string, length: number) => {
  if (comment != null) {
    if (comment.length <= length) return comment;

    return `${comment.slice(0, length)}\u2026`; // u2026 - "…"
  }

  return comment;
};

export const formatCurrencyNumber = ({ amount = 0, format = 'en-IN', currency = '' }) => {
  if (!amount) {
    return 0;
  }
  let options = {};

  if (currency) {
    options = {
      style: 'currency',
      currency,
    };
  }

  return Intl.NumberFormat(format, options).format(amount);
};

export const formatPercent = (amount: number) => {
  if (amount == null) {
    return null;
  }
  const displayOptions: displayOptionType = {
    style: 'decimal',
    useGrouping: true,
  };

  return Intl.NumberFormat('ru-RU', displayOptions).format(amount);
};

const statusMap = {
  ACTIVE: 'Active',
  REPAID: 'Repaid',
  CANCELLED: 'Cancelled',
  BLOCKED: 'Blocked',
};

export const formatAOStatus = (status) => {
  const statusName: string = statusMap[status];

  return statusName || status;
};

export const getDateSomeMonthsAgo = (monthCount: number) => {
  const dtFrom = new Date();
  dtFrom.setMonth(dtFrom.getMonth() - monthCount);

  return formatDateIsoShort(firstDayOfMonth(dtFrom));
};

export const formatBalanceDebitAmount = (amount: number) => {
  if (amount < 0) {
    return formatAmount(-amount);
  }

  return null;
};

export const formatBalanceCreditAmount = (amount: number) => {
  if (amount >= 0) {
    return formatAmount(amount);
  }

  return null;
};

export const formatAccount = (accName, accType) => `${accName} [${accType}]`;

export const formatAccAmount = (accId, stAccId, amountLocal) => {
  if (accId === stAccId) {
    return formatAmount(amountLocal);
  }

  return null;
};

export const formatDayMonth = (date) => {
  const dt = parseDate(date);

  if (dt == null) return null;

  return `${dt.getDate()} ${getMonthName(dt)}`;
};

export const formatDayMonthNameFullYear = (date) => {
  const dt = parseDate(date);

  if (dt == null) return null;

  return `${dt.getDate()} ${getMonthName(dt)} ${dt.getFullYear()}`;
};

export const formatAo = (ao) => {
  if (ao === null) {
    return null;
  }
  const date: string = new Date(ao.purchase_date).toLocaleDateString();
  const amount: string = formatAmountNoZero(ao.amount, ao.currency);

  return `${ao.name} [${date}] ${amount}`;
};

export const formatAoShort = ({ purchase_date, name }: { purchase_date: string | null | Date; name: string }) => {
  if (purchase_date === null) {
    return null;
  }
  const date = new Date(purchase_date).toLocaleDateString();

  return `${name} [${date}]`;
};

export const formatTransaction = (transaction) => {
  if (transaction === null) {
    return null;
  }
  const date: string = new Date(transaction.date).toLocaleDateString();
  const amount: string = formatAmountNoZero(Number(transaction.amount), transaction.currency);

  return `${transaction.company_name} [${date}] ${amount}`;
};

export const createEndpoint = (defaultEndpoint: string, params: any): string => {
  let endpoint = defaultEndpoint;
  Object.keys(params).forEach((param) => {
    if (params[param]) {
      if (param === 'companyId') {
        endpoint += `&company_id=${params[param]}`;
      } else {
        endpoint += `&${param}=${params[param]}`;
      }
    }
  });

  return endpoint;
};

export const getAmountWithCorrectSign = (amount, currency = null) => {
  if (amount === null) {
    return amount;
  }

  if (amount >= 0) {
    return formatAmount(amount, currency);
  }

  return `${minusSymbol}${formatAmount(Math.abs(amount), currency)}`;
};

export const getCurrencyWithAmount = (value, currency) =>
  value !== null && value !== undefined ? `${getCurrency(currency)}${formatAmount(Number(value))}` : '';

function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const isDateLessNow = (date) => new Date(date).getTime() < Date.now();

const listColorsIcon = [
  '#5D65DA',
  '#53C4A7',
  '#ED6262',
  '#FAC02C',
  '#69C6DD',
  '#B264EA',
  '#EC4B63',
  '#F3A23C',
  '#82D1CE',
  '#5D65DA',
  '#68CD67',
  '#80D0FA',
  '#A78F6D',
  '#3785F7',
  '#FF9377',
  '#94B9F1',
  '#94A1B4',
];

export const getColorIcon = (i: number) => {
  const list = [...listColorsIcon, ...listColorsIcon, ...listColorsIcon];

  if (i <= list.length - 1) {
    return list[i];
  }

  return list[getRandomNumberBetween(0, list.length - 1)];
};

export const getLocaleDateString = () => {
  const formats = {
    'af-ZA': 'yyyy/MM/dd',
    'am-ET': 'd/M/yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'ar-IQ': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'ar-SA': 'dd/MM/yy',
    'ar-SY': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'as-IN': 'dd-MM-yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'ba-RU': 'dd.MM.yy',
    'be-BY': 'dd.MM.yyyy',
    'bg-BG': 'dd.M.yyyy',
    'bn-BD': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'bo-CN': 'yyyy/M/d',
    'br-FR': 'dd/MM/yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'bs-Latn-BA': 'd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'cs-CZ': 'd.M.yyyy',
    'cy-GB': 'dd/MM/yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-AT': 'dd.MM.yyyy',
    'de-CH': 'dd.MM.yyyy',
    'de-DE': 'dd.MM.yyyy',
    'de-LI': 'dd.MM.yyyy',
    'de-LU': 'dd.MM.yyyy',
    'dsb-DE': 'd. M. yyyy',
    'dv-MV': 'dd/MM/yy',
    'el-GR': 'd/M/yyyy',
    'en-029': 'MM/dd/yyyy',
    'en-AU': 'd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'en-CA': 'dd/MM/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'en-IE': 'dd/MM/yyyy',
    'en-IN': 'dd-MM-yyyy',
    'en-JM': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'en-NZ': 'd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'en-SG': 'd/M/yyyy',
    'en-TT': 'dd/MM/yyyy',
    // 'en-US': 'M/d/yyyy',
    'en-US': 'MM/dd/yyyy',
    en: 'MM/dd/yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'en-ZW': 'M/d/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'es-CO': 'dd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'es-DO': 'dd/MM/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-US': 'M/d/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'et-EE': 'd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'fa-IR': 'MM/dd/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fil-PH': 'M/d/yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'fr-BE': 'd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'fr-CH': 'dd.MM.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'fy-NL': 'd-M-yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'gd-GB': 'dd/MM/yyyy',
    'gl-ES': 'dd/MM/yy',
    'gsw-FR': 'dd/MM/yyyy',
    'gu-IN': 'dd-MM-yy',
    'ha-Latn-NG': 'd/M/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'hr-BA': 'd.M.yyyy.',
    'hr-HR': 'd.M.yyyy',
    'hsb-DE': 'd. M. yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'hy-AM': 'dd.MM.yyyy',
    'id-ID': 'dd/MM/yyyy',
    'ig-NG': 'd/M/yyyy',
    'ii-CN': 'yyyy/M/d',
    'is-IS': 'd.M.yyyy',
    'it-CH': 'dd.MM.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'km-KH': 'yyyy-MM-dd',
    'kn-IN': 'dd-MM-yy',
    'ko-KR': 'yyyy-MM-dd',
    'kok-IN': 'dd-MM-yyyy',
    'ky-KG': 'dd.MM.yy',
    'lb-LU': 'dd/MM/yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'lt-LT': 'yyyy.MM.dd',
    'lv-LV': 'yyyy.MM.dd.',
    'mi-NZ': 'dd/MM/yyyy',
    'mk-MK': 'dd.MM.yyyy',
    'ml-IN': 'dd-MM-yy',
    'mn-MN': 'yy.MM.dd',
    'mn-Mong-CN': 'yyyy/M/d',
    'moh-CA': 'M/d/yyyy',
    'mr-IN': 'dd-MM-yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'ne-NP': 'M/d/yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nl-NL': 'd-M-yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'nso-ZA': 'yyyy/MM/dd',
    'oc-FR': 'dd/MM/yyyy',
    'or-IN': 'dd-MM-yy',
    'pa-IN': 'dd-MM-yy',
    'pl-PL': 'yyyy-MM-dd',
    'prs-AF': 'dd/MM/yy',
    'ps-AF': 'dd/MM/yy',
    'pt-BR': 'd/M/yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'quz-BO': 'dd/MM/yyyy',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    ru: 'dd.MM.yyyy',
    'rw-RW': 'M/d/yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'se-FI': 'd.M.yyyy',
    'se-NO': 'dd.MM.yyyy',
    'se-SE': 'yyyy-MM-dd',
    'si-LK': 'yyyy-MM-dd',
    'sk-SK': 'd. M. yyyy',
    'sl-SI': 'd.M.yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'smj-NO': 'dd.MM.yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'smn-FI': 'd.M.yyyy',
    'sms-FI': 'd.M.yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'sr-Latn-BA': 'd.M.yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sr-Latn-ME': 'd.M.yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'sw-KE': 'M/d/yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'th-TH': 'd/M/yyyy',
    'tk-TM': 'dd.MM.yy',
    'tn-ZA': 'yyyy/MM/dd',
    'tr-TR': 'dd.MM.yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'ug-CN': 'yyyy-M-d',
    'uk-UA': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'wo-SN': 'dd/MM/yyyy',
    'xh-ZA': 'yyyy/MM/dd',
    'yo-NG': 'd/M/yyyy',
    'zh-CN': 'yyyy/M/d',
    'zh-HK': 'd/M/yyyy',
    'zh-MO': 'd/M/yyyy',
    'zh-SG': 'd/M/yyyy',
    'zh-TW': 'yyyy/M/d',
    'zu-ZA': 'yyyy/MM/dd',
  };

  return formats[navigator.language] || 'dd/MM/yyyy';
};

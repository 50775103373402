import React, { Children, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import urls from 'constants/urls';
import { PurchaseFilters } from 'consts/tableFilters';
import { formatDate } from 'helpers/formatters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { tableBodyStyles, tableSettingsStyles } from 'theme/styles';
import CurrencyCell from 'components/CurrencyCell';
import Status, { StatusEnumTitle } from 'components/common/Status';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';

import { HEADER_DATA } from './data';

const PurchaseRequestTable: FC | any = () => {
  const styles = tableBodyStyles();
  const classes = tableSettingsStyles();
  const { t } = useTranslation();
  const { purchaseRequestList, setPurchaseRequestParams, purchaseRequestParams } = usePurchaseRequest();
  const [transactionsTotal, setTransactionsTotal] = useState(0);
  const [localPage, setLocalPage] = useState(purchaseRequestParams?.page || 0);
  const [disabled, setDisable] = useState(false);
  const history = useHistory();

  useMemo(() => {
    if (Object.keys(purchaseRequestList).length) {
      setTransactionsTotal(purchaseRequestList.total);
      setLocalPage(purchaseRequestList.page);
      setDisable(false);
    }
  }, [purchaseRequestList]);

  const handleChangePage = useCallback(
    (page) => {
      setUrlStringParams({
        filters: PurchaseFilters,
        currentScreenParams: { ...purchaseRequestParams, page },
      });
      setLocalPage(page);
    },
    [purchaseRequestParams, setPurchaseRequestParams],
  );

  const handleChangeRowPerPage = useCallback(
    (event) => {
      setUrlStringParams({
        filters: PurchaseFilters,
        currentScreenParams: { ...purchaseRequestParams, limit: event.target.value, page: 0 },
      });
      setLocalPage(0);
      setDisable(true);
    },
    [purchaseRequestParams, setPurchaseRequestParams],
  );

  const rowClickHandler = (id: string) => {
    history.push(`${urls.getPurchaseRequestList()}/${id}`);
  };

  return (
    <TableWrapWithPagination
      styleTop={{ top: 50 }}
      count={transactionsTotal}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowPerPage}
      page={localPage}
      rowsPerPageOptions={[15, 25, 50]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rowsPerPage={+purchaseRequestParams?.limit || 15}
      disabled={disabled}
    >
      <TableHead>
        <TableRow classes={{ root: classes.headTableRowRoot }}>
          {Children.toArray(
            HEADER_DATA.map(({ title, align, style }) => (
              <TableCell classes={{ root: classes.headTableCellRoot }} align={align} style={style}>
                {t(title)}
              </TableCell>
            )),
          )}
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.bodyTableRootWithoutChangeColor }}>
        {Children.toArray(
          purchaseRequestList?.records?.map(
            ({ id, status, purchase_date, ao, request, purchase_price, company, currency }) => (
              <TableRow
                hover
                onClick={() => rowClickHandler(id)}
                classes={{ root: `${classes.bodyTableRowRoot} ${classes.bodyRowTrHoverAo}` }}
              >
                <TableCell
                  classes={{ root: classes.bodyTableCellRoot }}
                  align="left"
                  className={`${styles.fixedCell} ${styles.nameWidth}`}
                  style={{ wordBreak: 'break-all' }}
                >
                  {company}
                </TableCell>
                <TableCell
                  classes={{ root: classes.bodyTableCellRoot }}
                  align="center"
                  className={`${styles.fixedCell} ${styles.nameWidth}`}
                  style={{ wordBreak: 'break-all' }}
                >
                  {ao}
                </TableCell>
                <TableCell
                  style={{ wordBreak: 'break-all' }}
                  classes={{ root: classes.bodyTableCellRoot }}
                  align="center"
                >
                  {request}
                </TableCell>
                <TableCell align="right" classes={{ root: classes.bodyTableCellRoot }}>
                  {formatDate(purchase_date)}
                </TableCell>
                <TableCell align="right" classes={{ root: classes.bodyTableCellRoot }}>
                  <CurrencyCell
                    amount={Number(purchase_price)}
                    currency={currency}
                    type="gray"
                    wrapperStyle={{ justifyContent: 'flex-end' }}
                  />
                </TableCell>
                <TableCell style={{ borderBottom: 'none', padding: '12px 24px 12px 64px' }}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <Status style={{ maxWidth: 86, width: '100%' }} status={StatusEnumTitle[status.toUpperCase()]} />
                </TableCell>
              </TableRow>
            ),
          ),
        )}
      </TableBody>
    </TableWrapWithPagination>
  );
};

export default PurchaseRequestTable;

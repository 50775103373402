import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Switch from '@material-ui/core/Switch';

type SwitchPropsType = {
  inputSwitch: boolean;
  buttonName: string;
  handleChangeSwitch: Function;
  name?: string;
  disabled?: boolean;
  toolTip?: string;
  wrapperStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  isSwitchNewStyle?: boolean;
};

const SwitchButton: FC<SwitchPropsType> = ({
  inputSwitch,
  name,
  buttonName,
  handleChangeSwitch,
  disabled = false,
  toolTip = '',
  wrapperStyle,
  textStyle,
  isSwitchNewStyle,
}: SwitchPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(inputSwitch);

  useEffect(() => {
    setChecked(inputSwitch);
  }, [inputSwitch]);

  const onClickSpanHandler = () => {
    handleChangeSwitch({ target: { name, checked: !checked } });
    setChecked(!checked);
  };

  return (
    <div
      className={isSwitchNewStyle ? styles.switchToggle : styles.checkWrapper}
      data-tip={toolTip}
      style={wrapperStyle}
    >
      <Switch
        disabled={disabled}
        checked={checked}
        data-tip={toolTip}
        onClick={onClickSpanHandler}
        color="primary"
        name={name}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span style={{ padding: 8, paddingLeft: 2, ...textStyle }} onClick={disabled ? () => {} : onClickSpanHandler}>
        {t(buttonName)}
      </span>
    </div>
  );
};

export default SwitchButton;

const useStyles = createUseStyles({
  checkWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '-11px',
    marginRight: '16px',
    verticalAlign: 'middle',
    // minHeight: '80px',
    marginTop: '10px',
    minWidth: '180px',
    '& span': {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5',
      cursor: 'pointer',
    },
  },
  switchToggle: {
    '& .MuiSwitch-root': {
      padding: '7px 8px',
      width: '54px',
      height: '34px',
      marginRight: 12,
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#606176',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
    },
    '& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: '#606176',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFFFFF',
      width: '16px',
      height: '16px',
    },
    '& .MuiSwitch-track': {
      borderRadius: '20px',
      opacity: 1,
      backgroundColor: '#CED0D7',
    },
  },
});

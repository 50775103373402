import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { acronymForAvatar } from 'helpers/parsers';

interface FullNameFieldProps {
  name: string;
  email?: string;
  styleIcon?: React.CSSProperties;
  isFirstSymbol?: boolean;
}
const FullNameField: FC<FullNameFieldProps> = ({ name, email, styleIcon, isFirstSymbol }) => {
  const classes = useStyle();

  return (
    <div className={classes.wrap}>
      <div className={classes.title} style={styleIcon}>
        <span>{isFirstSymbol ? name.slice(0, 1) : acronymForAvatar(name, 'A')}</span>
      </div>
      <div>
        <div className={classes.name}>
          <span>{name}</span>
        </div>
        {email && (
          <div className={classes.email}>
            <span>{email}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyle = makeStyles(() => ({
  wrap: {
    display: 'grid',
    columnGap: 12,
    gridTemplateColumns: '36px 1fr',
    alignItems: 'center',
  },
  title: {
    borderRadius: 200,
    color: '#ffff',
    backgroundColor: '#999CE8',
    width: 36,
    height: 36,
    fontSize: 16,
    lineHeight: '36px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    color: '#29292C',
    fontSize: 16,
    lineHeight: '19.2px',
    marginBottom: 4,
  },
  email: {
    color: '#838998',
    fontSize: 14,
    lineHeight: '17px',
  },
}));

export default FullNameField;

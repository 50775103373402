// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import RemoveFileIcon from '@material-ui/icons/RemoveCircle';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

import useTransaction from 'hooks/api/useTransaction';
import useErrorCallback from 'hooks/useErrorCallback';
import { formatTransaction } from 'helpers/formatters';
import { AdjustingTransactionFormPropsType } from 'types/TransactionFormTypes';
import CustomInput from 'components/inputs/CustomInput';

const AdjustingTransactionForm: FC<AdjustingTransactionFormPropsType> = ({
  fileName,
  src,
  handleChange,
  handleFileChange,
  handleTransactionIdChange,
  handleRemoveFile,
  error,
  fileSizeError,
  setError,
  disabled = false,
  dataTip = '',
  companyId,
  currency,
  formData,
}: AdjustingTransactionFormPropsType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { transactionsForAdjustingData, getTransactionsForAdjusting } = useTransaction();
  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText }) =>
    errorCallbackView({ errorField, errorCallbackText }),
  );
  const errorCallbackView = useCallback(({ errorField, errorCallbackText }) => {
    if (Object.keys(error).includes(errorField)) {
      setError((prevState) => ({
        ...prevState,
        [errorField]: { status: true, text: errorCallbackText },
      }));

      return false;
    }

    return true;
  }, []);

  const [transactionId, setTransactionId] = useState<string>(formData.reference_id);
  const toolTipReferenceTransactionInput =
    formData.company_id && !transactionsForAdjustingData.length ? t('forms.transaction.noTransactionByCompany') : '';

  useEffect(() => {
    getTransactionsForAdjusting({ companyId, currency, errorCallback });
  }, [getTransactionsForAdjusting, errorCallback, companyId, currency]);

  const onClickInput = (e) => {
    e.target.value = '';
  };

  const handleActivationOrderChange = useCallback(
    (_, child) => {
      const { id } = child.props.datatype;
      setError((prev) => ({ ...prev, reference_id: !id }));
      setTransactionId(id);
      handleTransactionIdChange((prev) => ({ ...prev, reference_id: id }));
    },
    [handleTransactionIdChange, setError],
  );

  return (
    <div className={classes.uploadBlock}>
      <div data-tip={disabled ? dataTip : toolTipReferenceTransactionInput} style={{ width: 260 }}>
        <FormControl variant="filled" error={error.reference_id}>
          <InputLabel id="demo-simple-select-filled-label">
            {`${t('forms.transaction.referenceTransaction')} *`}
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={transactionId}
            disabled={disabled}
            required
            name="transaction_id"
            data-tip={disabled ? dataTip : toolTipReferenceTransactionInput}
            onChange={handleActivationOrderChange}
            className={classes.selectInput}
            // onBlur={() => onBlurHandler('transactionId', transactionId)}
          >
            {transactionsForAdjustingData.map((item, i) => (
              <MenuItem value={item.id} datatype={{ ...item, i }}>
                {formatTransaction(item)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            id="component-error-text"
            style={{ visibility: !error.reference_id ? 'hidden' : 'visible' }}
            className={classes.transactionSelectRequiredText}
          >
            {t('auth.required')}
          </FormHelperText>
        </FormControl>
      </div>
      <CustomInput
        labelKey={t('forms.transaction.reasonAdjustment')}
        name="reason"
        onChange={handleChange}
        disabled={disabled}
        defaultValue={formData.reason}
        toolTip={dataTip}
        stylesProp={{ margin: '10px 0px' }}
        inputWidth={500}
      />
      <div>
        <p className={classes.uploadLabel}>{t('file.relatedFilesMaxSize')}</p>
        <input
          style={{ display: 'none' }}
          id="contained-button-file"
          multiple
          disabled={disabled}
          type="file"
          onClick={onClickInput}
          onChange={handleFileChange}
          data-tip={dataTip}
        />
        <label htmlFor="contained-button-file" data-tip={dataTip}>
          <Button variant="contained" className={classes.uploadButton} component="span" disabled={disabled}>
            {t('file.dropFileText')}
          </Button>
        </label>
        <p
          className={classes.uploadLabel}
          style={{ visibility: error.file ? 'visible' : 'hidden', color: error.file && 'red' }}
          data-tip={dataTip}
        >
          {fileSizeError ? t('file.sizeError') : t('auth.required')}
        </p>
      </div>
      {fileName && (
        <div data-tip={disabled ? dataTip : ''}>
          <IconButton onClick={handleRemoveFile} disabled={disabled}>
            <RemoveFileIcon style={{ color: 'red' }} />
          </IconButton>
          <a
            href={formData.file.rawFile || src}
            target="_blank"
            download={fileName}
            // onClick={downloadImg}
            style={{
              color: '-webkit-link',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            rel="noreferrer"
          >
            {fileName}
          </a>
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

export default AdjustingTransactionForm;

const useStyles = makeStyles(() => ({
  reason: {
    minWidth: '330px',
    marginBottom: '32px',
  },
  uploadBlock: {
    marginBottom: '32px',
  },
  uploadLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '1',
    marginBottom: '8px',
  },
  uploadButton: {
    width: '100%',
    height: '66px',
    background: '#fafafa',
    marginBottom: '8px',
  },
  selectInput: {
    backgroundColor: '#eef2f7',
    minWidth: '260px',
    '& .MuiFilledInput-root': {
      backgroundColor: '#eef2f7',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#eef2f7',
    },
    '& *': {
      backgroundColor: '#eef2f7 !important',
    },
    '&:hover': {
      backgroundColor: '#eef2f7 !important',
    },
  },
  transactionSelectRequiredText: {
    marginLeft: '0!important',
  },
}));

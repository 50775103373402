import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import updateUtils from 'actions/updateUtils';
import getUsers from 'queries/user/getUsers';
import getUser from 'queries/user/getUser';
import createUser from 'queries/user/createUser';
import editUser from 'queries/user/editUser';
import deleteUser from 'queries/user/deleteUser';
import getAdmins from 'queries/user/getAdmins';
import createAdmin from 'queries/user/createAdmin';
import editAdmin from 'queries/user/editAdmin';
import deleteAdmin from 'queries/user/deleteAdmin';
import getAdmin from 'queries/user/getAdmin';
import setUsersFilter, { usersDataType } from 'queries/user/setUsersFilter';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

export enum Fields {
  USER_NAME = 'user_name',
  FULL_NAME = 'full_name',
  EMAIL = 'email',
  COMPANY_NAME = 'company_name',
  ACTIVE = 'active',
  CREATED = 'created',
}
export interface UserType {
  active: boolean;
  auth_provider: string;
  company_name: string;
  company_status: string;
  created: Date;
  email: string;
  full_name: string;
  id: string;
  user_name: string;
}
export interface UserFilterType {
  limit: number;
  page: number;
  records: UserType[];
  total: number;
}

export type tableParamsType = {
  limit: number;
  page: number;
  total?: number;
  user_name?: string;
  email?: string;
  company_name?: string;
  _sort?: Fields;
  _order?: 'asc' | 'desc';
};

const tableParams: tableParamsType = {
  limit: 15,
  page: 0,
  _sort: Fields.USER_NAME,
  email: '',
  user_name: '',
  company_name: '',
  _order: 'asc',
};

export interface UseUserType {
  getUsers?: (data: usersDataType) => void;
  usersData?: UserType[];
  userTableParams?: tableParamsType;
  setUserTableParams?: (data: tableParamsType) => void;
  usersDataFilter?: UserFilterType;
  setUsersFilter?: (data: usersDataType) => void;
  usersDataList?: UserFilterType;
}

const useUser = () => {
  const selector = useCallback(
    (state) => ({
      usersDataFilter: objectSelector(state, 'usersDataFilter'),
      usersDataList: objectSelector(state, 'usersDataList'),
      usersData: arraySelector(state, 'usersData'),
      userData: arraySelector(state, 'userData'),
      createUserData: arraySelector(state, 'createUserData'),
      editUserData: objectSelector(state, 'editUserData'),
      deleteUserData: objectSelector(state, 'deleteUserData'),
      adminsData: arraySelector(state, 'adminsData'),
      adminData: objectSelector(state, 'adminData'),
      createAdminData: arraySelector(state, 'usersData'),
      editAdminData: objectSelector(state, 'editAdminData'),
      deleteAdminData: objectSelector(state, 'deleteAdminData'),
      userTableParams: utilsSelector(state, 'userTableParams', {}),
      adminTableParams: utilsSelector(state, 'adminTableParams', tableParams),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getUsers,
      getUser,
      createUser,
      editUser,
      deleteUser,
      getAdmins,
      getAdmin,
      createAdmin,
      editAdmin,
      deleteAdmin,
      setUsersFilter,
    }),
    [],
  );

  const tableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setUserTableParams: (userTableParams: tableParamsType) =>
            updateUtils({
              userTableParams,
            }),
          setAdminTableParams: (adminTableParams: tableParamsType) =>
            updateUtils({
              adminTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...tableActions,
  };
};

export default useUser;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type editCompanyDataType = {
  id: string;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
  group: string;
};

export default ({ id, successCallback, group, errorCallback, resultKey = 'GroupCompany' }: editCompanyDataType) =>
  requestAsync({
    url: endpoints.getCompanyGroupUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getCompanyGroupUrl(id),
    body: { group },
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

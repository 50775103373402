import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useContracts, { contractDataBaseType } from 'hooks/api/useContracts';
import Button from 'components/uiKit/Button';
import PopUpContract from 'components/forms/CompanyForm/PopUpContract';
import { delCustomerContract, setCustomerContract } from 'components/forms/CompanyForm/data';

const plusSvg = require('assets/img/plus.svg').default;

type PropsT = {
  company_id: string;
  setCurrentDocument: (data: contractDataBaseType) => void;
  setIsOpen: (data: boolean) => void;
  currentDocument: contractDataBaseType;
  isOpen: boolean;
};

const AgreementButton: FC<PropsT> = ({ company_id, setCurrentDocument, setIsOpen, currentDocument, isOpen }) => {
  const { t } = useTranslation();
  const { getDocumentsByIdV2, createContract } = useContracts();

  const getDocuments = useCallback(() => {
    getDocumentsByIdV2({
      id: company_id,
      endpoint: 'getContracts',
      resultKey: 'contractsData',
    });
  }, [company_id]);

  return (
    <div>
      <Button
        style={{ width: 240, height: 24 }}
        startAdornment={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
        onClick={() => setIsOpen(true)}
      >
        {t('forms.companies.agreements.createNewAgreement')}
      </Button>
      <PopUpContract
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignores
        setCurrentDocument={setCurrentDocument}
        currentDocument={currentDocument}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteCallback={async ({ id }) => {
          const result = await delCustomerContract(id);

          if (result) {
            getDocuments();
          }
        }}
        successCallback={async (contract, document) => {
          if (!document) {
            createContract({
              body: { ...contract, company_id },
              successCallback: getDocuments,
            });

            return;
          }
          const changeContract = await setCustomerContract(document.id, {
            ...contract,
            company_id,
          });

          if (changeContract) {
            getDocuments();
          }
        }}
      />
    </div>
  );
};

export default AgreementButton;

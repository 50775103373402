import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Table, Pagination, Avatar, Button, PageHeader, AddIcon, Input, Select } from '@gamesb42/ui-kit';
// eslint-disable-next-line import/order
import { SortOrder } from 'antd/lib/table/interface';
import { Table as MaterialTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

// import CompanyTableHeader from 'components/tables/CompanyTable/Header';
import { debounce } from 'lodash';

import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';
import urls from 'constants/urls';
import Status from 'components/common/Status';
import { getNextSort } from 'helpers';
import { shortToFullNameSort } from 'helpers/mappers';
import TableWrapper from 'components/TableWrapper';
import { getCompanies } from 'api/company';
import { ShortNameSort } from 'types/Sort';
import { CompanyKeyEnum, CompanyStatus } from 'types/CompanyTypes';
import { COMPANY_TYPE_OPTIONS, COMPANY_STATUS_OPTIONS } from 'constants/company';

import styles from './styles.module.scss';

interface QueryParams {
  name?: string;
  size?: number;
  page?: number;
  order?: string;
  sortedBy?: string;
  type?: string;
  status?: string;
}

const CompanyList: FC = () => {
  const history = useHistory();
  const query: QueryParams = queryString.parse(history.location.search, { parseNumbers: true });
  const { size = 10, page = 1, order, sortedBy, type = undefined, status, name } = query;
  const [draftName, setDraftName] = useState(name || '');
  const [companyData, setCompanyData] = useState({
    items: [],
    total: 0,
  });
  const { t } = useTranslation();
  const [pending, setPending] = useState(true);

  const onClickRow = (id: string) => {
    if (id) {
      history.push(`${urls.getCompany()}/${id}`);
    }
  };

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      history.replace(
        `${history.location.pathname}?${queryString.stringify(
          { size, page, order, sortedBy, type, status, name, ...newValues },
          { skipEmptyString: true },
        )}`,
      );
    },
    [history, size, page, order, sortedBy, type, status, name],
  );

  const onChangeName = useMemo(() => debounce((newName) => onChangeQuery({ name: newName }), 200), [onChangeQuery]);

  const getSort = (currentSortedBy: string) => {
    if (order && sortedBy === currentSortedBy) return shortToFullNameSort(order as ShortNameSort);

    return null;
  };

  const onSort = (newSortedBy: string) => {
    return history.replace(
      `${history.location.pathname}?${queryString.stringify({
        order: getNextSort({ value: order as ShortNameSort }) || '',
        sortedBy: getNextSort({ value: order as ShortNameSort }) === null ? '' : newSortedBy,
      })}`,
    );
  };

  const columns = [
    {
      title: t('tables.companyName'),
      dataIndex: CompanyKeyEnum.NAME,
      sorter: true,
      sortOrder: getSort(CompanyKeyEnum.NAME) as SortOrder,
      onHeaderCell: () => ({
        onClick: () => onSort(CompanyKeyEnum.NAME),
      }),
      render: (companyName: string) => <Avatar name={companyName} />,
    },
    {
      title: t('tables.type'),
      dataIndex: CompanyKeyEnum.TYPE,
      width: '232px',
      render: (value: string) => t(`company.types.${value}`),
    },
    {
      title: t('tables.status'),
      dataIndex: CompanyKeyEnum.STATUS,
      width: '232px',
      render: (value: CompanyStatus) => <Status status={value} />,
    },
  ];

  useEffect(() => {
    const params = {
      size: size as number,
      page: page as number,
      type: type ? type : ['revenue-source', 'client', 'third-party'],
      status,
      name,
    };

    setPending(true);

    getCompanies(params)
      .then(setCompanyData)
      .catch(() => {})
      .finally(() => {
        setPending(false);
      });
  }, [size, page, status, type, name, sortedBy]);

  useEffect(() => {
    updateTitle('Companies');
  }, []);

  return (
    <MainContent type="flexibleLimit" flexColumn>
      <PageHeader
        title="Companies"
        rightContent={
          <Button icon={<AddIcon size={16} />} onClick={() => history.push(urls.getCompanyCreate())}>
            {t('company.createCompany')}
          </Button>
        }
      />
      <div className={styles.filter}>
        <Input
          label={t('contentHeader.company')}
          className={styles.company}
          value={draftName}
          onChange={(e) => {
            const newName = e.target.value;
            setDraftName(e.target.value);

            if (newName === '') {
              onChangeQuery({ name: newName });

              return;
            }

            if (newName.length < 3) return;

            onChangeName(e.target.value);
          }}
          allowClear
        />
        <Select
          label={t('contentHeader.type')}
          className={styles.type}
          options={COMPANY_TYPE_OPTIONS}
          value={type}
          onChange={(newType) => onChangeQuery({ type: newType })}
          allowClear
        />
        <Select
          label={t('contentHeader.status')}
          className={styles.status}
          options={COMPANY_STATUS_OPTIONS}
          value={status}
          onChange={(newStatus) => onChangeQuery({ status: newStatus })}
          allowClear
        />
      </div>
      <TableWrapper>
        <Table
          loading={pending}
          dataSource={companyData.items}
          columns={columns}
          rowClassName={styles.row}
          onRow={({ id }) => ({
            onClick: () => onClickRow(id),
          })}
        />
        <Pagination
          total={companyData.total}
          current={page as number}
          pageSize={size as number}
          onChange={(newCurrent: number, newPageSize: number) => onChangeQuery({ page: newCurrent, size: newPageSize })}
        />
      </TableWrapper>
    </MainContent>
  );
};

export default CompanyList;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer, QueriesState } from 'redux-query';

import utilsReducer from './utilsReducer';
import alertReducer from './alertReducer';
import modalReducer from './modalReducer';
import routeReducer from './routeReducer';

export const getQueries: any = (state: QueriesState) => state.queries;
export const getEntities: any = (state: QueriesState) => state.entities;
export const getUtils: any = (state: any) => state.utils;
export const getAlert: any = (state: any) => state.alert;
export const getModal: any = (state: any) => state.modal;
export const getRoute: any = (state: any) => state.route;

export default combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  utils: utilsReducer,
  alert: alertReducer,
  modal: modalReducer,
  route: routeReducer,
});

import { getEntities, getUtils, getAlert, getModal, getRoute } from 'reducers';

export const getSimpleResult = (initialValue: any) => (state: object, resultKey: string) =>
  getEntities(state)[resultKey] ?? initialValue;

export const objectSelector = getSimpleResult({});
export const arraySelector = getSimpleResult([]);
export const stringSelector = getSimpleResult('');

export const primitiveSelector = (state: object, resultKey: string, initialValue?: any) =>
  getEntities(state)[resultKey] ?? initialValue;

export const utilsSelector = (state: object, resultKey: string, initialValue?: any) =>
  getUtils(state)[resultKey] ?? initialValue;

export const alertSelector = (state: object, initialValue?: any) => getAlert(state) ?? initialValue;
export const modalSelector = (state: object, initialValue?: any) => getModal(state) ?? initialValue;
export const routeSelector = (state: object, initialValue?: any) => getRoute(state) ?? initialValue;

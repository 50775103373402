import React, { useCallback, useRef, useEffect, useState } from 'react';

const initial = {
  top: false,
  right: false,
  bottom: false,
  left: false,
};

const speed = 40;

enum EventsEnum {
  MOUSEDOWN = 'mousedown',
  MOUSEUP = 'mouseup',
}

export enum ArrowParamsEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum ScrollparamsEnum {
  SCROLL_TOP = 'scrollTop',
  SCROLL_LEFT = 'scrollLeft',
}

const setArrowParams = (el?: HTMLElement) => {
  const copy = { ...initial };
  const { clientWidth, clientHeight, scrollWidth, scrollHeight, scrollTop, scrollLeft } = el || {};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (clientWidth < scrollWidth) {
    copy.right = true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (clientHeight < scrollHeight) {
    copy.bottom = true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (scrollWidth - clientWidth < scrollLeft + 1) {
    copy.right = false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (scrollHeight - clientHeight < scrollTop + 1) {
    copy.bottom = false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (scrollTop > 0) {
    copy.top = true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (scrollLeft > 0) {
    copy.left = true;
  }

  return { initial, copy };
};
// set scroll event on the element ref
const UseParamsOfRefElement = <T>() => {
  const ref = useRef<HTMLElement & T>(null);
  const [scrollEl, setScrollEl] = useState<HTMLElement | null>(null);
  const [, setValue] = useState<number | null>(null);
  const [showArrow, setShowArrow] = useState(initial);
  const [, setTimeoutLocal] = useState(null);

  const setScroll = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, params: ScrollparamsEnum, value: number) => {
      if (e.type === EventsEnum.MOUSEDOWN) {
        const count = setInterval(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref.current[params] += value;
          setValue((prev) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (prev === ref.current[params]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setTimeoutLocal((prevInt) => clearInterval(prevInt));

              return prev;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return ref.current[params];
          });
        }, 100);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setTimeoutLocal(() => count);
      }

      if (e.type === EventsEnum.MOUSEUP) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setTimeoutLocal((prev) => clearInterval(prev));
      }
    },
    [setTimeoutLocal, ref],
  );

  const onChangePositionArrow = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, arrow: ArrowParamsEnum) => {
      if (arrow === ArrowParamsEnum.TOP) {
        setScroll(e, ScrollparamsEnum.SCROLL_TOP, -speed);

        return;
      }

      if (arrow === ArrowParamsEnum.LEFT) {
        setScroll(e, ScrollparamsEnum.SCROLL_LEFT, -speed);

        return;
      }

      if (arrow === ArrowParamsEnum.RIGHT) {
        setScroll(e, ScrollparamsEnum.SCROLL_LEFT, speed);

        return;
      }

      if (arrow === ArrowParamsEnum.BOTTOM) {
        setScroll(e, ScrollparamsEnum.SCROLL_TOP, speed);
      }
    },
    [],
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let timeoutID = null;

    if (ref.current) {
      timeoutID = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { copy } = setArrowParams(ref.current);
        setShowArrow(copy);
      }, 2000);
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearTimeout(timeoutID);
    };
  }, [ref]);

  if (ref?.current) {
    ref.current.onscroll = () => {
      setScrollEl(ref.current);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { copy } = setArrowParams(ref.current);
      setShowArrow(copy);
    };
  }

  return {
    ref,
    scrollEl,
    showArrow,
    onChangePositionArrow,
  };
};
export default UseParamsOfRefElement;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type editAdminDataType = {
  id: string;
  fullName: string;
  username: string;
  email?: string;
  authProvider: string;
  password?: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

type editAdminBodyType = {
  full_name: string;
  user_name: string;
  email?: string;
  auth_provider: string;
  password?: string;
  deletion: any;
};

export default ({
  id,
  fullName,
  username,
  email,
  authProvider,
  password,
  successCallback,
  errorCallback,
  resultKey = 'editAdminData',
}: editAdminDataType) => {
  const bodyObj: editAdminBodyType = {
    full_name: fullName,
    user_name: username,
    auth_provider: authProvider,
    deletion: { can_be_deleted: true },
  };

  if (email) {
    bodyObj.email = email;
  }

  if (password) {
    bodyObj.password = password;
  }

  return requestAsync({
    url: endpoints.getAdminUrl(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAdminUrl(id),
    body: bodyObj,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });
};

import endpoints, { amountCellAdjustmentType } from 'consts/endpoints';
import { formatDateIsoShort } from 'helpers/formatters';
import { getToken } from 'helpers/token';

export const listAdjustmentTypes = ['principal_repayment_amount', 'commission_accrued', 'default_interest_accrued'];

export const getlistAdjustmentTypes = () => [
  { translate: listAdjustmentTypes[0], key: listAdjustmentTypes[0] },
  { translate: listAdjustmentTypes[1], key: listAdjustmentTypes[1] },
  { translate: listAdjustmentTypes[2], key: listAdjustmentTypes[2] },
];

export const getListPopUpAdjustmentTypes = () => [
  { translate: listAdjustmentTypes[1], key: listAdjustmentTypes[1] },
  { translate: listAdjustmentTypes[2], key: listAdjustmentTypes[2] },
];

export type AdjustmentTypes =
  | 'principal_repayment_amount'
  | 'commission_accrued'
  | 'commission_net_accrued'
  | 'commission_vat_accrued'
  | 'default_interest_accrued'
  | '';

export enum CorrectionDetailsEnum {
  STATUS = 'active',
  DATE = 'date',
  TYPE = 'type',
  AMOUNT = 'amount',
  AUTHOR = 'user_fullname',
  COMMENT = 'comment',
  DOCUMENT = 'title',
}

export enum initialKeyFilterEnum {
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
  TYPE = 'type',
  SORT = '_sort',
  ORDER = '_order',
  SHOW_DELETED = 'show_deleted',
  USER = 'user',
}

export const initialFilterData = {
  [initialKeyFilterEnum.DATE_FROM]: '',
  [initialKeyFilterEnum.DATE_TO]: '',
  [initialKeyFilterEnum.TYPE]: '',
  [initialKeyFilterEnum.SORT]: 'created',
  [initialKeyFilterEnum.ORDER]: 'DESC',
  [initialKeyFilterEnum.SHOW_DELETED]: true,
  [initialKeyFilterEnum.USER]: '',
};

export const initialParams = {
  [initialKeyFilterEnum.SORT]: 'created',
  [initialKeyFilterEnum.ORDER]: 'DESC',
  [initialKeyFilterEnum.SHOW_DELETED]: true,
};

export const dataKeyDetails = [
  { key: CorrectionDetailsEnum.STATUS, name: 'tables.detailsStatus', id: 0 },
  { key: CorrectionDetailsEnum.DATE, name: 'tables.detailsDate', id: 1 },
  { key: CorrectionDetailsEnum.AMOUNT, name: 'tables.detailsAmount', id: 2 },
  { key: CorrectionDetailsEnum.AUTHOR, name: 'tables.detailsAuthor', id: 3 },
  { key: CorrectionDetailsEnum.COMMENT, name: 'tables.detailsComment', id: 4 },
  { key: CorrectionDetailsEnum.DOCUMENT, name: 'tables.detailsAttached', id: 5 },
];
export interface getAdjustmentCalcType {
  id?: string;
  comment: string;
  date: Date | string;
  amount: string;
  type: AdjustmentTypes;
  active: boolean;
  created?: Date;
  order?: string;
  updated?: Date;
  user?: string;
  user_email: string;
  user_fullname: string;
  description: string;
  documents: {
    id: string;
    title: string;
    file_id: string;
  }[];
}

export const AdjustmentCalcInitial = {
  comment: '',
  date: '',
  amount: '',
  active: false,
  type: '' as AdjustmentTypes,
  user_email: '',
  user_fullname: '',
  description: '',
  documents: [],
};

export enum AdjustmentKeyEnum {
  DATE = 'date',
  TYPE = 'type',
  DESCRIPTION = 'description',
  USER = 'user',
}

export const AdjustmentKeyArray = [
  { key: AdjustmentKeyEnum.DATE },
  { key: AdjustmentKeyEnum.TYPE },
  { key: AdjustmentKeyEnum.DESCRIPTION },
  { key: AdjustmentKeyEnum.USER },
];

export const getAdjustmentCalc = async (id: string, params?: Object) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await fetch(endpoints.getAdjustmentCalc(id, params), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ adjustments: getAdjustmentCalcType[] }>;
  } catch (error) {
    return false;
  }
};

export interface DocumentAdjustmentType {
  file_data: string;
  name: string;
}

export interface AoAdjustmentType {
  active: boolean;
  company_name: string;
  groups: string[];
  id: string;
  payment_day: number;
  status: string;
  user_id: null | string;
}

export const aoAdjustmentInit = {
  active: false,
  company_name: '',
  groups: [],
  id: '',
  payment_day: 0,
  status: '',
  user_id: null,
};

interface BodyRequestAdjustment {
  comment: string;
  date: string;
  amount: string;
  type: AdjustmentTypes | '';
  order: string;
  active?: boolean;
  documents: File[];
  modify_daily: boolean;
}

export const initialAdjustment = {
  comment: '',
  date: formatDateIsoShort(new Date()),
  amount: '',
  type: '',
  order: '',
  documents: [],
  modify_daily: true,
} as BodyRequestAdjustment;

interface ErrorTypes {
  name?: string;
  message?: string;
  stack?: string;
}
interface ResponceSetAdjustment extends ErrorTypes {
  adjustment?: string;
  description?: { adjustment: string };
  title?: string;
}

export const setAdjustment = async (body: BodyRequestAdjustment, params?: Object) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await fetch(endpoints.setAdjustment(params), {
      method: 'Post',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const status = await res;

    if (!status.ok) {
      return (await res.json()) as Promise<ResponceSetAdjustment>;
    }

    return (await res.json()) as Promise<ResponceSetAdjustment>;
  } catch (error) {
    return error as ResponceSetAdjustment;
  }
};

export const getAdjustmentItem = async (id: string) => {
  try {
    const res = await fetch(endpoints.delAdjustment(id), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    const status = await res;

    if (!status.ok) {
      return (await res.json()) as Promise<ResponceSetAdjustment>;
    }

    return (await res.json()) as Promise<ResponceSetAdjustment>;
  } catch (error) {
    return error as ResponceSetAdjustment;
  }
};

export const delAdjustment = async (id: string) => {
  try {
    const res = await fetch(endpoints.delAdjustment(id), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{
      adjustment: string;
    }>;
  } catch (error) {
    return false;
  }
};

export const amountCellAdjustment = async (params: amountCellAdjustmentType) => {
  try {
    const res = await fetch(endpoints.getAmountCellAdjustment(params), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{
      result: string | null;
    }>;
  } catch (error) {
    return false;
  }
};

export const FormControlCustomInput = [
  {
    labelKey: 'tables.platform',
    name: 'name',
    defaultValue: 'name',
    required: true,
    onBlurHandler: 'name',
    isError: 'name',
    errorText: 'name',
    stylesProp: { width: 500 },
    error: 'name',
  },
];

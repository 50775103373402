/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import {
  FormLayout,
  Input,
  Modal,
  Button,
  TrashIcon,
  AddIcon,
  DatePicker,
  InputAmount,
  Body2,
  formatterAmountCurrency,
  Checkbox,
  notification,
} from '@gamesb42/ui-kit';
import { Form, FormInstance } from 'antd';
import type { Rule } from 'antd/lib/form';

import { AOKeyEnum, RepaymentPlan } from 'types/ActivationOrderTypes';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Currency } from 'types/Currency';
import moment, { Moment } from 'moment';
import FormItem from 'antd/es/form/FormItem';
import { editSchedules } from 'api/ao';
import useActivationOrder from 'hooks/api/useActivationOrder';

interface RepaymentPlanForm extends Omit<RepaymentPlan, AOKeyEnum.PAYMENT_DATE> {
  [AOKeyEnum.PAYMENT_DATE]: Moment;
}

interface FormTypes {
  [AOKeyEnum.PAYMENT_PLAN]: RepaymentPlanForm[];
  [AOKeyEnum.PURCHASED_RECEIVABLES]: string;
  [AOKeyEnum.EFFECTIVE_DATE]: string;
}

interface EditModalProps {
  onClose: () => void;
  repaymentPlan: RepaymentPlan[];
  purchasedReceivables: string;
  effectiveDate: string;
  aoId: string;
  currency: Currency;
}

const getTotal = (amounts: Array<RepaymentPlanForm>) =>
  amounts.reduce((prevVal, curVal) => {
    const amount = curVal[AOKeyEnum.DELETED] ? 0 : Number(curVal[AOKeyEnum.AMOUNT] || 0);
    return prevVal + amount;
  }, 0);

const EditModal: FC<EditModalProps> = ({
  onClose,
  repaymentPlan,
  purchasedReceivables,
  effectiveDate,
  currency,
  aoId,
}) => {
  const { getScheduleForFutureReceivables, getActivationOrder } = useActivationOrder();
  const [hasValueAmount, setHasValueAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const initialValues: FormTypes = {
    [AOKeyEnum.PAYMENT_PLAN]: repaymentPlan.map((plan) => ({
      ...plan,
      [AOKeyEnum.PAYMENT_DATE]: moment(plan[AOKeyEnum.PAYMENT_DATE]),
    })),
    [AOKeyEnum.PURCHASED_RECEIVABLES]: purchasedReceivables,
    [AOKeyEnum.EFFECTIVE_DATE]: effectiveDate,
  };

  const onFinish = () => {
    setLoading(true);
    form
      .validateFields()
      .then((res) => {
        const body = {
          [AOKeyEnum.ORDER_ID]: aoId,
          [AOKeyEnum.PURCHASED_RECEIVABLES]: res[AOKeyEnum.PURCHASED_RECEIVABLES],
          [AOKeyEnum.EFFECTIVE_DATE]: res[AOKeyEnum.EFFECTIVE_DATE].format('YYYY-MM-DD'),
          [AOKeyEnum.SCHEDULES]: res[AOKeyEnum.PAYMENT_PLAN]
            .filter(
              (plan: RepaymentPlanForm) =>
                plan[AOKeyEnum.ID] || (plan[AOKeyEnum.PAYMENT_DATE] && plan[AOKeyEnum.AMOUNT]),
            )
            .map((plan: RepaymentPlanForm) => ({
              ...plan,
              [AOKeyEnum.PAYMENT_DATE]: plan[AOKeyEnum.PAYMENT_DATE].format('YYYY-MM-DD'),
            })),
        };

        return editSchedules(aoId, body);
      })
      .then(() => {
        getScheduleForFutureReceivables({ id: aoId });
        getActivationOrder({ id: aoId });
        notification.success({ message: 'The Repayment Plan has been changed successfully', theme: 'dark' });
        onClose();
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const getCustomValidatorPayment =
    (value: string) =>
    ({ getFieldValue }: FormInstance) => ({
      validator: (_: Rule, value: string) => {
        const total = getTotal(getFieldValue([AOKeyEnum.PAYMENT_PLAN]));

        if (total === Number(value)) return Promise.resolve();
        setHasValueAmount(true);
        return Promise.reject(new Error('Total Amount is not equal Purchased Receivables'));
      },
      validateTrigger: 'submit',
    });

  return (
    <Modal
      open
      title="Edit Repayment Plan"
      onOk={onFinish}
      okButtonProps={{
        onMouseDown: () => {},
      }}
      onCancel={onClose}
      okText="Save"
      confirmLoading={loading}
      cancelText="Cancel"
      width="838px"
    >
      <Form
        form={form}
        initialValues={initialValues}
        className={styles.form}
        onValuesChange={() => setHasValueAmount(false)}
      >
        <FormLayout>
          <Form.Item
            name={AOKeyEnum.PURCHASED_RECEIVABLES}
            rules={[
              {
                required: true,
                message: 'Please input Purchased Receivables!',
              },
              getCustomValidatorPayment(AOKeyEnum.PURCHASED_RECEIVABLES) as Rule,
            ]}
          >
            <InputAmount label={t('forms.activationOrder.purchasedReceivables')} currency={currency} size="large" />
          </Form.Item>
          <Form.Item
            name={AOKeyEnum.EFFECTIVE_DATE}
            rules={[{ required: true, message: 'Please input effective date ' }]}
          >
            <DatePicker label={t('forms.activationOrder.effectiveDate')} size="large" format="DD.MM.YYYY" />
          </Form.Item>
        </FormLayout>
        <Form.List name={AOKeyEnum.PAYMENT_PLAN}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue, setFieldValue }) => {
                    const isDeleted = getFieldValue([AOKeyEnum.PAYMENT_PLAN, name, AOKeyEnum.DELETED]);

                    if (isDeleted) return null;

                    return (
                      <div className={styles.row} key={key}>
                        <FormLayout>
                          <Form.Item
                            {...restField}
                            name={[name, AOKeyEnum.PAYMENT_DATE]}
                            rules={[{ required: true, message: 'Please input payment date!' }]}
                          >
                            <DatePicker label="Payment date" size="large" format="DD.MM.YYYY" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, AOKeyEnum.AMOUNT]}
                            rules={[{ required: true, message: 'Please input amount!' }]}
                          >
                            <InputAmount label="Amount" currency={currency} size="large" />
                          </Form.Item>
                        </FormLayout>
                        <FormItem name={[name, AOKeyEnum.ID]} hidden>
                          <Input label={AOKeyEnum.ID} />
                        </FormItem>
                        <FormItem name={[name, AOKeyEnum.DELETED]} hidden>
                          <Checkbox />
                        </FormItem>
                        {name > 0 && (
                          <Button
                            icon={<TrashIcon size={16} />}
                            type="primary"
                            onClick={() => {
                              if (!getFieldValue([AOKeyEnum.PAYMENT_PLAN, name, AOKeyEnum.ID])) {
                                remove(name);
                                return;
                              }
                              setFieldValue([AOKeyEnum.PAYMENT_PLAN, name, AOKeyEnum.DELETED], true);
                            }}
                            className={styles.remove}
                          />
                        )}
                      </div>
                    );
                  }}
                </Form.Item>
              ))}

              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const total = getTotal(getFieldValue([AOKeyEnum.PAYMENT_PLAN]));

                  return (
                    <div className={classNames(styles.total, hasValueAmount && styles.totalError)}>
                      <Body2 className={styles.totalTitle}>Total Amount: </Body2>
                      <Body2 className={styles.totalAmount}>
                        {formatterAmountCurrency(total, currency)}{' '}
                        {hasValueAmount && (
                          <span className={styles.error}>Total Amount is not equal Purchased Receivables</span>
                        )}
                      </Body2>
                    </div>
                  );
                }}
              </Form.Item>

              <Button
                onClick={() =>
                  add({
                    [AOKeyEnum.DELETED]: false,
                  })
                }
                icon={<AddIcon size={16} />}
              >
                Add Payment Date
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default EditModal;

import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'components/uiKit/Field';
import DateInput from 'components/inputs/DateInput';
import useSystemSetting from 'hooks/api/useSystemSetting';
import useThrottle from 'hooks/useThrottle';

import { Button, DatePicker, DatePickerProps } from '@gamesb42/ui-kit';

import styles from './styles.module.scss';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  name: string;
  loading: boolean;
  disabled: boolean;
}

interface Props {
  errorCallback: (props: any) => void;
}

const RecalculatePage: FC<Props> = ({ errorCallback }) => {
  const [dateFrom, setDateFrom] = useState<DatePickerProps['value']>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExch, setIsLoadingExch] = useState(false);
  const [isLoadingAggregate, setIsLoadingAggregate] = useState(false);
  const [error, setError] = useState(false);

  const { activationOrdersRecalculate, updateAggregationTable, getExchangeRates, getAggregationStatus } =
    useSystemSetting();
  const { t } = useTranslation();

  const fetchThrottleData = useThrottle(() => {
    setIsLoadingExch(false);
  }, 40000);

  const getRecalculateHandler = useCallback(() => {
    setIsLoading(true);

    if (!error) {
      activationOrdersRecalculate({
        date_from: dateFrom?.format('YYYY-MM-DD'),
        errorCallback: (values: any) => {
          setIsLoading(false);
          errorCallback(values);
        },
        successCallback: () => setIsLoading(false),
      });
    }
  }, [errorCallback, dateFrom, error]);

  const getExchangeRatesHandler = useCallback(() => {
    setIsLoadingExch(true);
    !isLoadingExch && getExchangeRates({ errorCallback });
    fetchThrottleData();
  }, [errorCallback]);

  const getAggregation = useCallback((statusCount = null) => {
    getAggregationStatus({
      successCallback: (res: any) => {
        if (res.status === 'done') {
          statusCount && clearInterval(statusCount);
          setIsLoadingAggregate(false);

          return;
        }
        if (res.status === 'started') setIsLoadingAggregate(true);
      },
    });
  }, []);

  const getAggregationApi = useCallback(() => {
    updateAggregationTable({
      successCallback: () => {
        getAggregation();
        const statusCount = setInterval(() => {
          getAggregation(statusCount);
        }, 10000);
      },
      errorCallback: () => setIsLoadingAggregate(false),
    });
  }, [setIsLoadingAggregate, getAggregation]);

  useEffect(() => {
    getAggregation();
    const statusCount = setInterval(() => {
      getAggregation(statusCount);
    }, 10000);
  }, []);

  return (
    <div>
      <Field title={t('forms.activationOrder.modalTable.aggregationTablesRecalculation')} className={styles.field}>
        <Button loading={isLoadingAggregate} onClick={getAggregationApi} type="primary">
          {t('forms.activationOrder.modalTable.recalculate')}
        </Button>
      </Field>
      <Field title={t('tables.aoRecalculation')} className={styles.field}>
        <DatePicker
          aria-required
          label={t('tables.beginningDate')}
          size="large"
          value={dateFrom}
          onChange={setDateFrom}
          className={styles.datePicker}
          format="DD.MM.YYYY"
        />
        <Button loading={isLoading} disabled={!dateFrom} onClick={getRecalculateHandler} type="primary">
          {t('forms.activationOrder.modalTable.recalculate')}
        </Button>
      </Field>
      <Field title={t('systemSetting.get_exchange_rates')} className={styles.field}>
        <Button onClick={getExchangeRatesHandler} loading={isLoadingExch} type="primary">
          Update
        </Button>
      </Field>
    </div>
  );
};

export default RecalculatePage;

import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';

import styles from './styles.module.scss';

type TargetPropsType = {
  firstCell?: ReactNode;
  secondCell?: ReactNode;
  thirdCell: ReactNode;
  thirdCellClassNames?: string;
  needBottomBorder?: boolean;
};

const Target: FC<TargetPropsType> = ({
  firstCell,
  secondCell,
  thirdCell,
  thirdCellClassNames,
  needBottomBorder,
}: TargetPropsType) => (
  <TableRow className={classNames(styles.row, needBottomBorder && styles.bottomBorder)}>
    <TableCell align="left" className={styles.cell} width="35%">
      {firstCell}
    </TableCell>
    <TableCell align="left" className={styles.cell} width="35%">
      {secondCell}
    </TableCell>
    <TableCell className={classNames(styles.cell, styles.totalCell, thirdCellClassNames)}>{thirdCell}</TableCell>
    <TableCell width={28} className={styles.cell} />
  </TableRow>
);

export default Target;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type contractDataType = {
  contract_id: string;
  errorCallback: Function;
  resultKey?: string;
};

export default ({ contract_id, errorCallback, resultKey = 'aOByContractData' }: contractDataType) =>
  requestAsync({
    url: endpoints.getAOByContractUrl(contract_id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAOByContractUrl(contract_id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { FC } from 'react';
import { Body4, countries } from '@gamesb42/ui-kit';

import styles from './styles.module.scss';

interface Props {
  countryCode: string;
}

const Country: FC<Props> = ({ countryCode }) => {
  const currentCountry = countries.find(({ value }) => value === countryCode);

  if (!currentCountry) return null;

  const { label, Flag } = currentCountry;

  return (
    <span className={styles.root}>
      <Flag size={24} />
      <Body4>{label}</Body4>
    </span>
  );
};

export default Country;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { tableSettingsStyles } from 'theme/styles';
import { activationOrderDataType } from 'hooks/api/useActivationOrder';
import { formatDate, formatPercent } from 'helpers/formatters';
import Accordion from 'components/forms/ActivationOrderForm/General/common/Accordion';
import { getCurrencyWithAmount } from 'components/common/GeneralView/Item';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';

import { tableDistributionPlatformData } from './data';
import styles from './styles.module.scss';

type PropsT = {
  activationOrderData: activationOrderDataType;
};

const DistributionPlatformList: FC<PropsT> = ({ activationOrderData: { distribution_platforms } }) => {
  const classes = tableSettingsStyles();
  const { t } = useTranslation();

  return (
    <Accordion title="forms.activationOrder.distributionPlatforms">
      <div>
        <TableWrapWithPagination styleTop={{ top: 37 }} isShowPagination={false}>
          <TableHead>
            <TableRow>
              {tableDistributionPlatformData.map(({ id, title, headAlign, color, style }) => (
                <TableCell
                  classes={{ root: classes.headTableCellRoot }}
                  key={id}
                  align={headAlign}
                  style={{
                    verticalAlign: 'center',
                    height: 40,
                    padding: '0 12px',
                    lineHeight: 1,
                    color,
                    fontSize: 16,
                    ...style,
                  }}
                >
                  {t(title)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {distribution_platforms.map(
              ({
                platform,
                platform_logo,
                platform_id,
                purchase_price_percentage,
                funding_limit,
                purchase_percentage,
                activation_date,
                currency,
              }) => (
                <TableRow classes={{ root: `${classes.bodyTableRowRoot}` }} key={platform_id}>
                  <TableCell style={{ wordBreak: 'break-word', border: 'none', padding: '17px 12px', width: 190 }}>
                    <span className={styles.nameBlock}>
                      <img src={platform_logo} alt={platform} className={styles.img} />
                      <span>{platform}</span>
                    </span>
                  </TableCell>
                  <TableCell style={{ width: 257, border: 'none', padding: '17px 12px' }}>
                    {`${formatPercent(Number(purchase_percentage))}%`}
                  </TableCell>
                  <TableCell style={{ width: 257, border: 'none', padding: '17px 12px' }}>
                    {`${formatPercent(Number(purchase_price_percentage))}%`}
                  </TableCell>
                  <TableCell style={{ width: 257, border: 'none', textAlign: 'right', padding: '17px 12px' }}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {formatDate(activation_date)}
                  </TableCell>
                  <TableCell style={{ width: 257, border: 'none', textAlign: 'right', padding: '17px 24px 17px 12px' }}>
                    {getCurrencyWithAmount(funding_limit, currency)}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </TableWrapWithPagination>
      </div>
    </Accordion>
  );
};

export default DistributionPlatformList;

import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import TransactionTableHeaderOld from 'components/tables/TransactionTableOld/Header';
import TransactionTableOld from 'components/tables/TransactionTableOld';
import { updateTitle } from 'helpers/common';

const wrapperAOEditStyle = {
  padding: '20px 40px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto minmax(100px, 1fr)',
};

const TransactionListScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Transactions');
  });

  return (
    <ContentWrapper style={{ ...wrapperAOEditStyle, paddingTop: 26, height: 'calc(100% - 46px)' }}>
      <TransactionTableHeaderOld requestParams={requestParams} />
      <TransactionTableOld />
    </ContentWrapper>
  );
};

export default TransactionListScreen;

import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import { PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';
import pencilSvg from 'assets/img/newPencil.svg';
import delSvg from 'assets/img/del.svg';
import twoRoundArrowSvg from 'assets/img/twoRoundArrow.svg';

type PropsT = {
  id: string;
  title: string;
  status: PurchaseRequestStatus;
  changeStatusToNew: () => void;
  onDeletePurchaseRequest: () => void;
};

const ActionsButton: FC<PropsT> = ({ id, title, status, changeStatusToNew, onDeletePurchaseRequest }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const history = useHistory();

  const getButtonsList = () => {
    switch (status) {
      case PurchaseRequestStatus.NEW:
        return [
          {
            image: pencilSvg,
            title: 'forms.purchaseRequest.editRequest',
            style: { color: '#838998' },
            onClick: () => history.push(`${urls.getPurchaseRequestList()}/edit/${id}`),
          },
          {
            image: delSvg,
            title: 'forms.purchaseRequest.deleteRequest',
            style: { color: '#EC3E72' },
            onClick: () => setIsDeleted(true),
          },
        ];
      case PurchaseRequestStatus.REPAID:
      case PurchaseRequestStatus.FUNDED:
      case PurchaseRequestStatus.PENDING:
      case PurchaseRequestStatus.REJECTED:
      case PurchaseRequestStatus.SIGNED:
        return [
          {
            image: twoRoundArrowSvg,
            title: 'forms.purchaseRequest.resetToNew',
            style: { color: '#838998' },
            onClick: changeStatusToNew,
          },
        ];
      default:
        return [];
    }
  };

  return (
    <div>
      <ViewGeneralActions buttonList={getButtonsList()} />
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title={title}
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          onDeletePurchaseRequest();
          setIsDeleted(false);
        }}
      />
    </div>
  );
};

export default ActionsButton;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getTransactionsRelatedByIdType {
  transaction_id: string;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({
  transaction_id,
  errorCallback,
  resultKey = 'transactionsDataRelatedById',
  successCallback,
}: getTransactionsRelatedByIdType) =>
  requestAsync({
    url: endpoints.getTransactionsRelatedByIdUrl(transaction_id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getTransactionsRelatedByIdUrl(transaction_id),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type subTransactionDataType = {
  date: string;
  company_id: string;
  currency: string;
  amount: string;
  type: string;
  resultKey?: string;
  successCallback?: Function;
};

export default ({
  date,
  company_id,
  currency,
  amount,
  type,
  resultKey = 'subTransactionData',
  successCallback,
}: subTransactionDataType) =>
  requestAsync({
    url: endpoints.getSubTransactionUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getSubTransactionUrl(),
    body: {
      date,
      company_id,
      currency,
      amount,
      type,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

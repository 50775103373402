import { useMemo, useCallback } from 'react';
import { updateEntities } from 'redux-query';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import uploadAllData from 'queries/load/uploadAllData';
import cancelUploadAllData from 'queries/load/cancelUploadAllData';
import importedData from 'queries/load/importedData';
import cancelImportedData from 'queries/load/cancelImportedData';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { objectSelector } from './selectors';

const useUploadData = () => {
  const selector = useCallback(
    (state) => ({
      uploadAllDataResponse: objectSelector(state, 'uploadAllDataResponse'),
      cancelUploadAllDataResponse: objectSelector(state, 'cancelUploadAllDataResponse'),
      importedDataResponse: objectSelector(state, 'importedDataResponse'),
      cancelImportedDataResponse: objectSelector(state, 'cancelImportedDataResponse'),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      uploadAllData,
      cancelUploadAllData,
      importedData,
      cancelImportedData,
    }),
    [],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearUploadAllDataResponse: () =>
            updateEntities({
              uploadAllDataResponse: () => ({}),
            }),
          clearImportedDataResponse: () =>
            updateEntities({
              importedDataResponse: () => ({}),
            }),
          clearCancelUploadAllDataResponse: () =>
            updateEntities({
              cancelUploadAllDataResponse: () => ({}),
            }),
          clearCancelImportedDataResponse: () =>
            updateEntities({
              cancelImportedDataResponse: () => ({}),
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...actionWithoutIsFetching,
  };
};

export default useUploadData;

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateEntities } from 'redux-query';

import getProcessStatus from 'queries/process/getProcessStatus';
import updateUtils from 'actions/updateUtils';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { objectSelector, utilsSelector } from './selectors';

type initialProcessParamsType = {
  load_old_data: boolean;
  import_data: boolean;
  sync_data: boolean;
};

const initialProcessParams: initialProcessParamsType = {
  load_old_data: false,
  import_data: false,
  sync_data: false,
};

const useProcess = () => {
  const selector = useCallback(
    (state) => ({
      load_old_data: objectSelector(state, 'load_old_data'),
      import_data: objectSelector(state, 'import_data'),
      sync_data: objectSelector(state, 'sync_data'),
      processParams: utilsSelector(state, 'processParams', initialProcessParams),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getProcessStatus,
    }),
    [],
  );

  const processActions = useMemo(
    () =>
      bindActionCreators(
        {
          setProcessParams: (processParams: initialProcessParamsType) =>
            updateUtils({
              processParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearProcessData: (process) =>
            updateEntities({
              [process]: () => ({}),
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...actionWithoutIsFetching,
    ...processActions,
  };
};

export default useProcess;

import React, { FC } from 'react';
import { Button } from '@gamesb42/ui-kit';

import CommonModal from 'components/uiKit/CommonModal';

import styles from './styles.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const CloseModal: FC<Props> = ({ open, onClose, onLeave }) => (
  <CommonModal
    open={open}
    onClose={onClose}
    title="Leave the Form"
    actionsClassName={styles.modalActions}
    actions={
      <Button danger onClick={onLeave} size="large" type="primary">
        Leave the Form
      </Button>
    }
  >
    <div className={styles.modalText}>
      The Company was not created because some required fields were not filled. Are you sure you want to leave the form?
    </div>
  </CommonModal>
);

export default CloseModal;

import React, { FC, useState } from 'react';

import { formatDate } from 'helpers/formatters';
import { ScheduleFutureReceivables } from 'hooks/api/useActivationOrder';
import { getCurrencyWithAmount } from 'components/common/GeneralView/Item';
import { CurrencyType } from 'components/uiKit/InputKit';
import { Tooltip } from '@gamesb42/ui-kit';

import styles from './styles.module.scss';
import classNames from 'classnames';
import EditModal from './EditModal';
import { Currency } from 'types/Currency';

type PropsT = {
  purchasedReceivables: string;
  schedule: ScheduleFutureReceivables;
  currency: CurrencyType;
  aoId: string;
  effectiveDate: string;
};

const Schedule: FC<PropsT> = (props) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const {
    purchasedReceivables,
    schedule: { total_amount, records },
    currency,
  } = props;

  return (
    <>
      {isOpenEditModal && (
        <EditModal
          aoId={props.aoId}
          currency={currency as Currency}
          repaymentPlan={records}
          purchasedReceivables={purchasedReceivables}
          effectiveDate={props.effectiveDate}
          onClose={() => {
            setIsOpenEditModal(false);
          }}
        />
      )}
      <table className={styles.wrapper}>
        <thead>
          <tr>
            <th className={`${styles.cell} ${styles.headCell}`} style={{ textAlign: 'left' }}>
              Payment Date
            </th>
            <th className={`${styles.cell} ${styles.headCell}`} style={{ textAlign: 'right' }}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody className={classNames(styles.tbody)}>
          {records?.map(({ amount, payment_date }) => (
            <Tooltip title="Edit Repayment Plan">
              <tr className={classNames(styles.row)} onClick={() => setIsOpenEditModal(true)}>
                <td className={styles.cell} style={{ textAlign: 'left' }}>
                  {formatDate(payment_date)}
                </td>
                <td className={styles.cell} style={{ textAlign: 'right' }}>
                  {getCurrencyWithAmount(amount, currency)}
                </td>
              </tr>
            </Tooltip>
          ))}
          <tr>
            <td className={`${styles.cell} ${styles.footerCell}`} style={{ textAlign: 'left' }}>
              Total amount:
            </td>
            <td className={`${styles.cell} ${styles.footerCell}`} style={{ textAlign: 'right' }}>
              {getCurrencyWithAmount(total_amount, currency)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Schedule;

import { KeyCardsEnum, StatusEnumAo } from '../data';

const initialClassic = [
  KeyCardsEnum.AO_TYPE,
  KeyCardsEnum.COMPANY_ID,
  KeyCardsEnum.NAME,
  KeyCardsEnum.CONTRACT_ID,
  KeyCardsEnum.AMOUNT,
  KeyCardsEnum.VAT_POLICY,
  KeyCardsEnum.CURRENCY,
  KeyCardsEnum.INTEREST,
  KeyCardsEnum.PURCHASE_DATE,
  KeyCardsEnum.REPAYMENT_DATE,
  KeyCardsEnum.PENALTY_POLICY,
  KeyCardsEnum.SIGN_DATE,
  KeyCardsEnum.STATUS,
  KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY,
];

export const requiredClassicKeys = (status?: StatusEnumAo) =>
  status === StatusEnumAo.REPAID ? [...initialClassic, KeyCardsEnum.CLOSING_DATE] : initialClassic;

export const requiredClassicEditKeys = (status?: StatusEnumAo) => [...requiredClassicKeys(status)];

export const errorsClassic = {
  [KeyCardsEnum.ROOT]: [
    { key: KeyCardsEnum.AO_TYPE, parameters: { required: true } },
    { key: KeyCardsEnum.COMPANY_ID, parameters: { required: true } },
    { key: KeyCardsEnum.NAME, parameters: { required: true } },
    { key: KeyCardsEnum.CONTRACT_ID, parameters: { required: true } },
    { key: KeyCardsEnum.AMOUNT, parameters: { required: true } },
    { key: KeyCardsEnum.VAT_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.CURRENCY, parameters: { required: true } },
    { key: KeyCardsEnum.INTEREST, parameters: { required: true } },
    { key: KeyCardsEnum.PURCHASE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.REPAYMENT_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.PENALTY_POLICY, parameters: { required: true } },
    { key: KeyCardsEnum.SIGN_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.STATUS, parameters: { required: true } },
    { key: KeyCardsEnum.GRACE_PERIOD, parameters: { required: true } },
    { key: KeyCardsEnum.CAPITALIZATION_DISABLE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.COMMISSION_DISABLE_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.CLOSING_DATE, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.IS_CUSTOM_PAYMENT_DAY, parameters: { required: false } },
  ],
  [KeyCardsEnum.CAPITALIZATION_PERIOD_DISABLED]: [
    { key: KeyCardsEnum.DATE_START, parameters: { required: true, date: true } },
    { key: KeyCardsEnum.DATE_END, parameters: { required: true, date: true } },
  ],
  [KeyCardsEnum.PAYMENT_DAYS]: [
    { key: KeyCardsEnum.PAYMENT_DAY, parameters: { required: true } },
    { key: KeyCardsEnum.APPLY_FROM_DATE, parameters: { required: true, date: true } },
  ],
};

export type errorsApplaudType = typeof errorsClassic;

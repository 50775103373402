import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface getTransactionsByIdDataType {
  params?: { deleted: string };
  transaction_id: string;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({
  params,
  transaction_id,
  errorCallback,
  resultKey = 'TransactionsDataById',
  successCallback,
}: getTransactionsByIdDataType) =>
  requestAsync({
    url: endpoints.getTransactionsByIdUrl(transaction_id, params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getTransactionsByIdUrl(transaction_id, params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Input, Button, AddIcon, Select, Radio, TrashIcon } from '@gamesb42/ui-kit';
import { Form, FormInstance } from 'antd';
import type { Rule } from 'antd/lib/form';
import classNames from 'classnames';

import { CompanyKeyEnum, BankAccount } from 'types/CompanyTypes';
import { CURRENCY_OPTIONS } from 'constants/currency';
import { Currency } from 'types/Currency';

import styles from './styles.module.scss';

const BankAccounts: FC = () => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();

  const onClearCurrencyMaster = (value: boolean, name: number) => {
    if (!value) return;

    const currency = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.CURRENCY]);
    const bankAccounts = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS]);
    const indexAccountSameCurrency: Array<number> = [];
    bankAccounts.forEach((account: BankAccount | undefined, index: number) => {
      if (account?.currency === currency && index !== name) {
        indexAccountSameCurrency.push(index);
      }
    });

    if (indexAccountSameCurrency.length) {
      form.setFieldValue(
        CompanyKeyEnum.BANK_DETAILS,
        bankAccounts.map((account: BankAccount | undefined, index: number) => {
          return indexAccountSameCurrency.includes(index) ? { ...account, master: false } : account;
        }),
      );
    }
  };

  const checkIsOnlyCurrency = (currency: Currency) => {
    const bankAccounts = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS]);
    const bankAccountsWithCurrentCurrency = bankAccounts.filter(
      (account: BankAccount | undefined) => account?.[CompanyKeyEnum.CURRENCY] === currency,
    );

    if (bankAccountsWithCurrentCurrency.length === 1) {
      form.setFieldValue(
        CompanyKeyEnum.BANK_DETAILS,
        bankAccounts.map((account: BankAccount | undefined) => {
          return account?.[CompanyKeyEnum.CURRENCY] === currency ? { ...account, master: 'true' } : account;
        }),
      );
    }
  };

  const clearMaster = (name: number) => {
    const bankAccounts = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS]);
    form.setFieldValue(
      CompanyKeyEnum.BANK_DETAILS,
      bankAccounts.map((account: BankAccount, index: number) => {
        return index === name ? { ...account, master: '' } : account;
      }),
    );
  };

  const onChangeCurrency = (name: number, currency: Currency) => {
    clearMaster(name);
    const value = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.MASTER]);
    checkIsOnlyCurrency(currency);

    if (value) onClearCurrencyMaster(value, name);
  };

  const getCustomValidatorForMainAccount =
    (name: number) =>
    ({ getFieldValue }: FormInstance) => ({
      validator: (_: Rule, value: boolean) => {
        if (value) return Promise.resolve();
        const currency = getFieldValue([CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.CURRENCY]);

        if (!currency) return Promise.resolve();
        const bankAccounts = form.getFieldValue([CompanyKeyEnum.BANK_DETAILS]);
        let hasMaster = false;
        hasMaster = bankAccounts.some(
          (account: { currency: string; master: boolean }) => account.currency === currency && account.master,
        );

        if (hasMaster) return Promise.resolve();

        return Promise.reject(new Error(`Don't have main account for ${currency} currency`));
      },
      validateTrigger: 'submit',
    });

  return (
    <Form.List name={CompanyKeyEnum.BANK_DETAILS}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <div className={classNames(fields.length - 1 !== index && styles.bankDetails)} key={key}>
              <FormLayout>
                <Form.Item
                  {...restField}
                  name={[name, CompanyKeyEnum.CURRENCY]}
                  rules={[{ required: true, message: 'Please select currency!' }]}
                >
                  <Select
                    label={t('Currency')}
                    options={CURRENCY_OPTIONS}
                    size="large"
                    onChange={(value) => onChangeCurrency(name, value)}
                  />
                </Form.Item>
                <div className={styles.wrapper}>
                  <Form.Item dependencies={[[CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.CURRENCY]]} noStyle>
                    {({ getFieldValue }) => {
                      const currentCurrency = getFieldValue([
                        CompanyKeyEnum.BANK_DETAILS,
                        name,
                        CompanyKeyEnum.CURRENCY,
                      ]);

                      return currentCurrency ? (
                        <Form.Item
                          {...restField}
                          name={[name, CompanyKeyEnum.MASTER]}
                          rules={[getCustomValidatorForMainAccount(name) as Rule]}
                        >
                          <Radio.Group>
                            <Radio value="true" onChange={(e) => onClearCurrencyMaster(e.target.value, name)}>
                              Main {currentCurrency} Account
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) : (
                        <div className={styles.empty} />
                      );
                    }}
                  </Form.Item>
                  <Button
                    icon={<TrashIcon size={16} />}
                    type="primary"
                    onClick={() => remove(name)}
                    className={styles.remove}
                  />
                </div>
              </FormLayout>
              <FormLayout>
                <Form.Item
                  {...restField}
                  name={[name, CompanyKeyEnum.BANK_NAME]}
                  rules={[{ required: true, message: 'Please input bank name!' }]}
                >
                  <Input label={t('systemSetting.bankName')} size="large" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, CompanyKeyEnum.SWIFT]}
                  rules={[{ required: true, message: 'Please input swift!' }]}
                >
                  <Input label={t('systemSetting.swiftCode')} size="large" />
                </Form.Item>
              </FormLayout>
              <Form.Item
                {...restField}
                name={[name, CompanyKeyEnum.ACCOUNT]}
                rules={[
                  { required: true, message: 'Please input IBAN or account number!' },
                  { min: 8, message: 'Ensure this value has at least 8 character!' },
                ]}
              >
                <Input label={t('systemSetting.ibanOrAccountNumber')} size="large" />
              </Form.Item>
              <Form.Item {...restField} name={[name, CompanyKeyEnum.BANK_ADDRESS]}>
                <Input label={t('systemSetting.bankAddress')} size="large" />
              </Form.Item>
              <Form.Item dependencies={[[CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.CURRENCY]]} noStyle>
                {({ getFieldValue }) => {
                  const currency = getFieldValue([CompanyKeyEnum.BANK_DETAILS, name, CompanyKeyEnum.CURRENCY]);

                  if (currency !== Currency.USD) return null;

                  return (
                    <>
                      <FormLayout>
                        <Form.Item {...restField} name={[name, CompanyKeyEnum.CORRESPONDENT_BANK_NAME]}>
                          <Input label={t('forms.companies.bankDetails.correspondentBank')} size="large" />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, CompanyKeyEnum.CORRESPONDENT_SWIFT]}>
                          <Input label={t('forms.companies.bankDetails.correspondentSWIFT')} size="large" />
                        </Form.Item>
                      </FormLayout>
                      <Form.Item {...restField} name={[name, CompanyKeyEnum.CORRESPONDENT_ACCOUNT]}>
                        <Input label={t('forms.companies.bankDetails.correspondentAccount')} size="large" />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item {...restField} name={[name, CompanyKeyEnum.SPECIAL_INSTRUCTIONS]}>
                <Input.TextArea label={t('systemSetting.specialInstructions')} />
              </Form.Item>
            </div>
          ))}
          <Form.Item className={styles.add}>
            <Button onClick={() => add()} icon={<AddIcon size={16} />}>
              Create bank account
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default BankAccounts;

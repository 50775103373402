import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type createCompanyDataType = {
  file: {
    fileRow: {
      path: string;
    };
    src: string;
    url: string;
  };
  fileData: string;
  fileName: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

export default ({
  file,
  fileData,
  fileName,
  successCallback,
  errorCallback,
  resultKey = 'uploadAllDataResponse',
}: createCompanyDataType) =>
  requestAsync({
    url: endpoints.getUploadAllDataUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getUploadAllDataUrl(),
    body: {
      file,
      file_data: fileData,
      file_name: fileName,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type PurchaseRequestParamsT = {
  company_id?: string;
  order_id?: string;
  request_number?: string;
  currency?: string;
  status?: string;
  page?: number;
  limit?: number;
};

export type PurchaseRequestT = {
  params?: PurchaseRequestParamsT;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({
  params = {},
  errorCallback = () => {},
  successCallback = () => {},
  resultKey = 'purchaseRequestList',
}: PurchaseRequestT) =>
  requestAsync({
    url: endpoints.getPurchaseRequestListURL(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getPurchaseRequestListURL(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

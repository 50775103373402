import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type principalAmountsReportType = {
  date_from: string;
  date_to: string;
  errorCallback: Function;
  resultKey?: string;
};

export default ({
  date_from,
  date_to,
  errorCallback,
  resultKey = 'principalAmountsReportData',
}: principalAmountsReportType) =>
  requestAsync({
    url: endpoints.getPrincipalAmountsUrl(date_from, date_to),
    transform: (response) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: response.map((item) => ({
        principalAmounts: item.principal_amounts,
        principalDate: item.principal_date,
        ...item,
      })),
    }),
    queryKey: endpoints.getPrincipalAmountsUrl(date_from, date_to),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

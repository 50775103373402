// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import urls from 'constants/urls';
import useCompany from 'hooks/api/useCompany';
import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import useActivationOrder, { useActivationOrderType } from 'hooks/api/useActivationOrder';
import Title from 'components/common/Title';
import useAlert from 'hooks/api/useAlert';

import { DataErrorsT, DataT, INITIAL_DATA, INITIAL_ERRORS, VariantEnum } from './data';
import View from './View';

type PropsT = {
  variant: VariantEnum;
};

const PurchaseRequestForm: FC<PropsT> = (props) => {
  const { variant } = props;
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [formData, setFormData] = useState<DataT>(INITIAL_DATA);
  const [errors, setErrors] = useState<Partial<DataErrorsT>>(INITIAL_ERRORS);
  const { getCompanies } = useCompany();
  const { getAvailableReceivablesList }: useActivationOrderType = useActivationOrder();
  const {
    createPurchaseRequest,
    purchaseRequestData,
    getPurchaseRequest,
    clearPurchaseRequestData,
    editPurchaseRequest,
  } = usePurchaseRequest();
  const history = useHistory();
  const { purchaseRequestId } = useParams();
  const { setAlert } = useAlert();
  const startSubmittingForm = () => setIsSubmittingForm(true);
  const finishSubmittingForm = () => setIsSubmittingForm(false);

  const checkRequiredError = (key: keyof DataT, value: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: !value ? { message: 'Required', isError: true } : undefined,
    }));
  };

  const successCallback = (e?: { id: string }) => {
    finishSubmittingForm();

    const message =
      variant === VariantEnum.CREATE
        ? t('forms.purchaseRequest.successMessageCreate')
        : t('forms.purchaseRequest.successMessageEdit');
    const newId = variant === VariantEnum.CREATE ? e.id : purchaseRequestId;

    setAlert({
      type: 'success',
      message,
    });

    // TODO: переписать после переделки тостеров
    setTimeout(() => history.push(`${urls.getPurchaseRequestList()}/${newId}`), 0);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (variant === VariantEnum.CREATE) {
      startSubmittingForm();
      createPurchaseRequest({
        body: {
          company_id: formData.company_id,
          currency: formData.currency,
          request_number: Number(formData.request_number),
          order_id: formData.order_id,
          available_advance: formData.available_advance,
          pr_date: formData.request_date,
        },
        successCallback,
        errorCallback: finishSubmittingForm,
      });
    }

    if (variant === VariantEnum.EDIT) {
      startSubmittingForm();
      editPurchaseRequest({
        body: {
          id: purchaseRequestId,
          available_advance: formData.available_advance,
        },
        successCallback,
        errorCallback: finishSubmittingForm,
      });
    }
  };

  useEffect(() => {
    if (variant === VariantEnum.EDIT && purchaseRequestData) {
      setFormData({
        purchase_price: purchaseRequestData.purchase_price || '',
        request_number: purchaseRequestData.request || '',
        order_id: purchaseRequestData.ao_id || '',
        currency: purchaseRequestData.currency || '',
        request_date: purchaseRequestData.pr_date || '',
        company_id: purchaseRequestData.company_id || '',
        maximum_price: '',
        available_advance: purchaseRequestData.platforms?.map((platform) => ({
          platform_id: platform?.platform_id || '',
          currency: purchaseRequestData.currency || '',
          purchased: platform.purchased || '',
          purchase_price: platform.purchase_price || '',
          due_date: platform.due_date || '',
        })),
      });
    }
  }, [purchaseRequestData]);

  useEffect(() => {
    if (formData.company_id) {
      if (variant === VariantEnum.CREATE) {
        setFormData((prevParams) => ({
          ...prevParams,
          order_id: '',
          currency: '',
          available_advance: [],
          purchase_price: '',
          request_number: '',
        }));
      }
      getAvailableReceivablesList({ id: formData.company_id });
    }
  }, [formData.company_id]);

  useEffect(() => {
    getCompanies();

    if (variant === VariantEnum.EDIT && purchaseRequestId) {
      getPurchaseRequest({ id: purchaseRequestId });
    }

    return () => clearPurchaseRequestData();
  }, []);

  return (
    <div style={{ width: 608, display: 'grid', gap: 30 }}>
      <Title
        text={t(`forms.purchaseRequest.createForm.${variant === VariantEnum.CREATE ? 'titleCreate' : 'titleEdit'}`)}
      />
      <form onSubmit={onSubmit}>
        <View
          variant={variant}
          setFormData={setFormData}
          formData={formData}
          errors={errors}
          checkRequiredError={checkRequiredError}
          setErrors={setErrors}
          isSubmittingForm={isSubmittingForm}
        />
      </form>
    </div>
  );
};

export default PurchaseRequestForm;

import React, { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import useCompany from 'hooks/api/useCompany';
import pencilSvg from 'assets/img/newPencil.svg';
import partialSvg from 'assets/img/partial.svg';
import delSvg from 'assets/img/newDelete.svg';
import reloadSvg from 'assets/img/reload.svg';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import ChangeStatusModal from 'components/modal/ChangeStatusModal';
import { changeStatusCompany, deleteCompanyById } from 'api/company';
import { CompanyStatus } from 'types/CompanyTypes';
import { Modal } from '@gamesb42/ui-kit';
import waring from './assets/warning.svg';

import s from './workingCapital.module.scss';

type PropsT = {
  name: string;
  updateCompany: () => void;
};

const ActionButton: FC<PropsT> = ({ name, updateCompany }) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { workingCapitalId } = useParams();
  const { companyData } = useCompany();
  const [isDeleted, setIsDeleted] = useState(false);

  const onDelete = () => {
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);

    return fetch(`${process.env.REACT_APP_BASE_URL}/tranche/${workingCapitalId}`, {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    });
  };

  const getButtonList = () => {
    const buttonList = [
      {
        image: pencilSvg,
        title: 'Edit tranche',
        style: { color: '#838998' },
        onClick: () => {
          history.push(`/workingCapital/edit/${workingCapitalId}`);
        },
      },
      {
        image: partialSvg,
        title: 'Partial repayment',
        style: { color: '#838998' },
        onClick: () => {
          history.push(`/workingCapital/partialRepayment/${workingCapitalId}`);
        },
      },
    ];

    if (companyData.status !== 'deleted') {
      buttonList.push({
        image: delSvg,
        title: 'Delete tranche',
        style: { color: '#EC3E72' },
        onClick: () => {
          Modal.warning({
            title: 'Are you sure delete the item?',
            okText: 'Delete',
            okButtonProps: {
              className: s.del,
            },
            onOk: () =>
              onDelete()
                .then(() => history.push(urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate'))
                .catch(() => {}),
          });
        },
      });
    }

    return buttonList;
  };

  return <ViewGeneralActions buttonList={getButtonList()} />;
};

export default ActionButton;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type createOrderAmountDataType = {
  id: string;
  date: string;
  getterId: string;
  resultKey?: string;
};

export default ({ id, date, getterId, resultKey = 'orderAmountData' }: createOrderAmountDataType) =>
  requestAsync({
    url: endpoints.getCreateOrderAmountUrl(),
    transform: (response) => ({
      [resultKey]: { ...response, getterId },
    }),
    queryKey: endpoints.getCreateOrderAmountUrl(),
    body: {
      id,
      date,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from '@gamesb42/ui-kit';

import usePlatform, { PlatformDataType, usePlatformType } from 'hooks/api/usePlatform';
import Button from 'components/uiKit/Button';
import stylesSelect from 'components/uiKit/SelectKit/styles.module.scss';
import styles from 'components/forms/PurchaseRequestForm/Form/View/Claim/styles.module.scss';
import {
  getListDistributionPlatform,
  getValueDistributionPlatform,
} from 'components/forms/ActivationOrderForm/CardsAo/data';
import { SelectKit } from 'components/uiKit/SelectKit';
import backIcon from 'assets/img/leftArrow.svg';
import { updateTitle } from 'helpers/common';

import Form from './Form';
import PopupErrorCreate from './PopupErrorCreate';
import classes from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddNewPlatform: any = () => {
  const [showForm, setShowForm] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [platformId, setPlatformId] = useState('');
  const [formData, setFormData] = useState({});
  const { connectPlatform }: usePlatformType = usePlatform();
  const history = useHistory();
  const { companyId } = useParams();
  const { t } = useTranslation();
  const { platformData, getDocuments }: usePlatformType = usePlatform();

  const platformName = platformData.find(({ id }) => platformId === id)?.name || 'Unknown';

  useEffect(() => {
    getDocuments({
      endpoint: 'getCreatePlatform',
      resultKey: 'platformData',
      params: { show_deleted: false },
    });

    updateTitle('Add New Platform');
  }, []);

  return (
    <div className={classes.wrapper}>
      {!showForm && (
        <Button
          onClick={() => history.goBack()}
          className={classes.backButton}
          variant="text"
          startAdornment={<img src={backIcon} />}
        >
          Add new Platform
        </Button>
      )}
      {showForm && (
        <Button
          onClick={() => setShowForm(false)}
          className={classes.backButton}
          variant="text"
          startAdornment={<img src={backIcon} />}
        >
          {`Connect ${platformName} account`}
        </Button>
      )}
      <div className={classes.content}>
        {!showForm && (
          <SelectKit
            label={t('forms.revenue.selectPlatform')}
            options={getListDistributionPlatform(platformData)}
            val={getValueDistributionPlatform(platformId, platformData)?.name}
            changeSelectItem={(_, value) => {
              setPlatformId(value.id);
            }}
            keyObj="name"
            withAllOption={false}
            wrapProps={{ height: 60 }}
            customOption={(item: PlatformDataType, isActive, handleMouseDown) => (
              <div
                onMouseDown={handleMouseDown}
                className={stylesSelect.menuSelectItemStyle}
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  justifyContent: 'start',
                  gridTemplateColumns: '20px 1fr',
                  gap: 15,
                  backgroundColor: !isActive ? '#F3F3F4' : undefined,
                }}
              >
                <img src={item?.logo} alt="" style={{ width: 24 }} />
                {item.name}
              </div>
            )}
            startAdornment={
              getValueDistributionPlatform(platformId, platformData)?.logo ? (
                <img
                  style={{
                    zIndex: 2,
                    width: 24,
                    height: 24,
                    marginTop: 25,
                    marginLeft: 18,
                  }}
                  className={styles.inputImg}
                  src={getValueDistributionPlatform(platformId, platformData)?.logo}
                  alt={getValueDistributionPlatform(platformId, platformData)?.name}
                />
              ) : undefined
            }
          />
        )}
        {showForm && <Form formData={formData} setFormData={setFormData} />}
      </div>
      {!showForm && (
        <div className={classes.actions}>
          <Button disabled={!platformId} className={classes.button} variant="fill" onClick={() => setShowForm(true)}>
            Continue
          </Button>
          <Button className={classes.button} onClick={() => history.back()}>
            Cancel
          </Button>
        </div>
      )}
      {showForm && (
        <div className={classes.actions}>
          <Button
            disabled={Object.keys(formData).some((key) => !formData[key])}
            className={classes.button}
            variant="fill"
            onClick={() => {
              connectPlatform({
                data: { credentials: formData, storeId: platformId, companyId },
                errorCallback: () => setErrorModal(true),
                successCallback: () => {
                  notification.success({
                    theme: 'dark',
                    message: `${platformName} Connection Success`,
                  });
                  history.push(`/company/revenue-sources/${companyId}`);
                },
              });
            }}
          >
            Connect
          </Button>
          <Button className={classes.button} onClick={() => setShowForm(false)}>
            Cancel
          </Button>
        </div>
      )}
      <PopupErrorCreate isOpen={errorModal} onClose={() => setErrorModal(false)} />
    </div>
  );
};

export default AddNewPlatform;

import React, { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import { Fade } from '@material-ui/core';
import { createUseStyles } from 'react-jss';

interface Props {
  open: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
}

const PopUpLayout: FC<Props> = ({ open, onClose, children, style }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.wrapper}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        style: {
          opacity: 0.4,
          backgroundColor: '#29292C',
        },
      }}
    >
      <Fade in={open}>
        <div style={style} className={classes.modal}>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    boxSizing: 'border-box',
    animation: '$fadeBlock 0.6s',
    position: 'relative',
    outline: 'none',
  },
  '@keyframes fadeBlock': {
    from: {
      top: '240px',
    },
    to: {
      top: '0px',
    },
  },
});

export default PopUpLayout;

import { KeyTransactions } from 'components/tables/TransactionsTable/data';

const initialFeeRequiredKey = [
  KeyTransactions.TYPE,
  KeyTransactions.CURRENCY,
  KeyTransactions.AMOUNT,
  KeyTransactions.RECIPIENT,
  KeyTransactions.DETAILS,
  KeyTransactions.COMMENT,
];

export const requiredFeeKeys = () => initialFeeRequiredKey;

export const errorsFee = {
  [KeyTransactions.ROOT]: [
    { key: KeyTransactions.TYPE, parameters: { required: true } },
    { key: KeyTransactions.VALUE_DATE_TIME, parameters: { required: true } },
    { key: KeyTransactions.CURRENCY, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT, parameters: { required: true } },
    { key: KeyTransactions.RECIPIENT, parameters: { required: true } },
  ],
};

export type errorsIncomingType = typeof errorsFee;

export const enum RevenueSourceKey {
  ID = 'id',
  LOGO = 'logo',
  STATUS = 'status',
  CODE = 'code',
  NAME = 'name',
  CALENDAR_TYPE = 'calType',
  ACTIVATION_CUTOFF_PERIOD = 'activationCutoffPeriod',
  ADVANCE_CUTOFF_PERIOD = 'advanceCutoffPeriod',
  NET_DAY = 'netDay',
  SYNC_TYPE = 'syncType',
}

export const enum RevenueSourceStatus {
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive',
  DELETED = 'deleted',
}

export const enum RevenueSourceCalendarType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export const enum RevenueSourceConnectionEnum {
  YES = 'YES',
  NO = 'NO',
}

export const enum RevenueSourceSyncTypeEnum {
  AUTO = 'auto',
  FILE = 'file',
}

export const enum RevenueSourceSyncStatusEnum {
  CREDENTIALS_NOT_FILLED = 'credentials_not_filled',
  NOT_SYNCHRONIZED = 'not_synchronized',
  SYNCHRONIZED = 'synchronized',
  TIMEOUT = 'timeout',
  CREDENTIALS_ERROR = 'credentials_error',
  CONNECTION_ERROR = 'connection_error',
}

export interface RevenueSource {
  [RevenueSourceKey.ID]: string;
  [RevenueSourceKey.NAME]: string;
  [RevenueSourceKey.STATUS]: RevenueSourceStatus;
  [RevenueSourceKey.LOGO]: string;
  [RevenueSourceKey.CODE]: null | string;
  [RevenueSourceKey.CALENDAR_TYPE]: RevenueSourceCalendarType;
  [RevenueSourceKey.SYNC_TYPE]: RevenueSourceSyncTypeEnum;
  [RevenueSourceKey.ACTIVATION_CUTOFF_PERIOD]: number;
  [RevenueSourceKey.ADVANCE_CUTOFF_PERIOD]: number;
  [RevenueSourceKey.NET_DAY]?: number;
}

export type RevenueSourceForm = Omit<RevenueSource, 'id' | 'status' | 'code'>;

import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  children?: JSX.Element[];
}

const TableWrapper: FC<Props> = ({ children }) => <div className={styles.root}>{children}</div>;

export default TableWrapper;

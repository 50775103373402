import endpoints, { getMonthlyInvoiceRecreateType } from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { downloadFileOfBlob } from 'helpers/file';

const nameLocal = 'file';
const typeLocal = 'application/pdf';

export const getMonthlyInvoiceRecreate = async (data: getMonthlyInvoiceRecreateType) => {
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceRecreateUrl(data), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ reports?: string[]; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getFileStorage = async (
  url: string,
  fileName?: string,
  type = typeLocal,
  initRequest?: { method?: string },
  openFile?: boolean,
) => {
  let name = fileName || nameLocal;
  const userToken = getToken();
  try {
    const blob = await fetch(url, { headers: { Authorization: `Bearer ${userToken}` }, ...initRequest })
      .then((res) => {
        const xFileName = res.headers.get('x-file-name');

        if (xFileName && !fileName) {
          name = xFileName;
        }

        return res;
      })
      .then((r) => r.blob());
    downloadFileOfBlob(blob, type, name, openFile);
    return true;
  } catch (error) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getMonthlyInvoiceReportDelete = async (companyId, id) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceReportDeleteUrl({ companyId, id }), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

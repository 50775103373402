import React, { FC, useCallback } from 'react';
import { TableCell, TableRow, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'helpers/formatters';
import { minusSymbol } from 'consts/dict';
import CurrencyCell from 'components/CurrencyCell';
import { tableSettingsStyles } from 'theme/styles';

const StyledTableRow = withStyles(() => ({
  root: {
    '&': {
      cursor: 'pointer',
      '&:hover td': {
        backgroundColor: '#FAFBFC !important',
      },
    },
  },
}))(TableRow);

type RowPropsType = {
  transaction: any;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onClick: (id) => void;
};

const Row: FC<RowPropsType> = ({ transaction, onClick }: RowPropsType) => {
  const classes = tableSettingsStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getParamsByTransaction = (item) => {
    let color;

    if (item.type === 'IN' && item.amount >= 0) {
      color = item.status === 'completed' ? 'green' : 'red';

      return {
        sign: '+',
        color,
      };
    }
    color = item.status === 'completed' ? 'gray' : 'red';

    return {
      sign: minusSymbol,
      color,
    };
  };

  const { sign, color } = getParamsByTransaction(transaction);

  const styleByTransactionParams: any = { backgroundColor: '#fff' };

  if (!transaction.active) {
    styleByTransactionParams.backgroundColor = 'rgba(255, 181, 181, 0.15)';
  }

  const getTypeValue = useCallback((type: string) => {
    if (type === 'IN') {
      return t('tables.incoming');
    }

    if (type === 'OUT') {
      return t('tables.outdoing');
    }

    if (type === 'CONVERSION') {
      return t('tables.exchange');
    }

    return type;
  }, []);

  return (
    <StyledTableRow classes={{ root: classes.bodyTableRowRoot }}>
      <TableCell
        align="left"
        onClick={() => onClick(transaction)}
        classes={{ root: classes.bodyTableCellRoot }}
        style={{ minWidth: 100, width: 100, ...styleByTransactionParams }}
      >
        {formatDate(transaction.date)}
      </TableCell>
      <TableCell
        align="left"
        onClick={() => onClick(transaction)}
        classes={{ root: classes.bodyTableCellRoot }}
        style={{ ...styleByTransactionParams, wordBreak: 'break-all' }}
      >
        {transaction.company_name}
      </TableCell>
      <TableCell
        align="left"
        onClick={() => onClick(transaction)}
        classes={{ root: classes.bodyTableCellRoot }}
        style={{ ...styleByTransactionParams }}
      >
        {getTypeValue(transaction.type)}
      </TableCell>
      <TableCell
        align="right"
        onClick={() => onClick(transaction)}
        classes={{ root: classes.bodyTableCellRoot }}
        style={{ ...styleByTransactionParams }}
      >
        <CurrencyCell
          amount={Math.abs(transaction.amount)}
          currency={transaction.currency}
          type={color}
          sign={sign}
          wrapperStyle={{ justifyContent: 'flex-end' }}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default Row;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { PenaltyType, VatType } from 'types/ActivationOrderTypes';

export type editActivationOrderApplaudType = {
  id: string;
  resultKey?: string;
  successCallback?: Function;
  errorCallback?: Function;
  body: {
    companyId?: string;
    name?: string;
    amount?: string;
    currency?: string;
    interest?: string;
    purchase_date?: string;
    repayment_date?: string;
    status?: string;
    contract_id?: string;
    grace_period?: number;
    capitalization_disable_date?: string;
    default_interest_disable_date?: string;
    vat_policy?: VatType;
    penalty_policy?: PenaltyType;
    ao_document_id?: string;
  };
};

export default ({
  resultKey = 'editActivationOrderData',
  successCallback,
  errorCallback,
  id,
  body,
}: editActivationOrderApplaudType) =>
  requestAsync({
    url: endpoints.getActivationOrderUrlApplaud(id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: `${endpoints.getActivationOrderUrlApplaud(id)}`,
    body,
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

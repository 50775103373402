import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { GBLoaderIcon } from '@gamesb42/ui-kit';

import styles from './styles.module.scss';
import { useUserContext } from 'contexts/UserProvider';
import urls from 'constants/urls';

const LogIn = () => {
  const history = useHistory();
  const { logIn } = useUserContext();

  useEffect(() => {
    const newPath = history.location.pathname.replace(urls.getAuth(), '') || urls.getBase();

    logIn(() => history.push(newPath));
  }, []);

  return (
    <div className={styles.root}>
      <GBLoaderIcon />
    </div>
  );
};

export default LogIn;

import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import updateUtils from 'actions/updateUtils';
import getExpectedPayments, { expectedPaymentsParamsType } from 'queries/expectedPayments/getExpectedPayments';
import { TargetsType } from 'types/CompanyTypes';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, utilsSelector } from './selectors';

export interface expectedOrderType {
  bank_fee: string;
  id: string;
  interests: string;
  is_overdue: boolean;
  loan_body: string;
  name: string;
  next_payment_date: Date;
  penalty: string;
  purchase_date: Date;
  repayment_date: Date;
  tt_amount: string;
  tx_type: Date;
  total: string;
  is_grace_period: boolean;
  isLoading: boolean;
}

export interface expectedOrderListType {
  id: string;
  name: string;
  purchase_date: Date;
}

export interface expectedPaymentsDataType {
  amount_owed_body: string;
  amount_owed_interest: string;
  bank_fee: string;
  company_active: boolean;
  company_id: string;
  company_name: string;
  currency: string;
  id: string;
  next_payment_date: Date;
  orders: expectedOrderType[];
  penalty: string;
  total: string;
}

export interface expectedPaymentsAllDataType {
  report: expectedPaymentsDataType[];
  orders: expectedOrderListType[];
}

const dtFrom = new Date();
dtFrom.getMonth();

const initialExpectedPaymentsTableParams: expectedPaymentsParamsType = {};

export interface TargetType {
  bankFee: string;
  interests: string;
  loan_body: string;
  penalty: string;
}

const latePayments = [
  { key: 'false', name: 'tables.expectedPaymentsTable.overduePayments', active: true },
  { key: 'true', name: 'tables.expectedPaymentsTable.currentPayments', active: true },
] as TargetsType[];

const listTarget = [
  { key: 'bank_fee', name: 'tables.expectedPaymentsTable.bankFee', active: true },
  { key: 'interest', name: 'tables.expectedPaymentsTable.commission', active: true },
  { key: 'loan_body', name: 'tables.expectedPaymentsTable.principalAmount', active: true },
  { key: 'penalty', name: 'tables.expectedPaymentsTable.defaultInterest', active: true },
] as TargetsType[];

const useExpectedPayments = () => {
  const selector = useCallback(
    (state) => ({
      listTarget,
      latePayments,
      isPending: state.queries.expectedPaymentsData?.isPending,
      expectedPaymentsData: arraySelector(state, 'expectedPaymentsData') as expectedPaymentsAllDataType,
      expectedPaymentsTableParams: utilsSelector(
        state,
        'expectedPaymentsTableParams',
        initialExpectedPaymentsTableParams,
      ) as expectedPaymentsParamsType,
    }),
    [],
  );

  const dispatch = useDispatch();

  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getExpectedPayments,
    }),
    [],
  );

  const expectedPaymentsTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setExpectedPaymentsTableParams: (expectedPaymentsTableParams: expectedPaymentsParamsType) =>
            updateUtils({
              expectedPaymentsTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...expectedPaymentsTableActions,
  };
};

export default useExpectedPayments;

import React, { FC, useState } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

import { emailValidator } from 'helpers/validators';
import { UseStylingTextField } from 'hooks/useStylingForMaterialUi';
import { systemSettingsFieldsEnum } from 'components/forms/SystemSettingsForm/SystemSettingsFormData';
import { InputKit } from 'components/uiKit/InputKit';

type SignatureTabGroupDataType = {
  private_key: string;
  client_email: string;
  gsuite_domain: string;
};
// todo не приходят 2 последние поля
type GoogleApiCredentialsTabPropsType = {
  groupData: SignatureTabGroupDataType;
  setValue: any;
};

const GoogleApiCredentialsTab: FC<GoogleApiCredentialsTabPropsType> = ({
  groupData,
  setValue,
}: GoogleApiCredentialsTabPropsType) => {
  const styles = useStyles();
  const [error, setError] = useState({
    client_email: false,
  });
  const { t } = useTranslation();
  const stylingTextField = UseStylingTextField();

  const onBlurHandler = (field: string, fieldValue: string) => {
    if (field === 'client_email') {
      if (groupData.client_email?.length !== 0) {
        setError({ ...error, client_email: !emailValidator(fieldValue) });
      } else {
        setError({ ...error, client_email: false });
      }
    }
  };

  return (
    <TabPanel value="google_api" style={{ padding: 0 }}>
      <div className={styles.tabBlock}>
        <TextField
          {...stylingTextField}
          label={t('systemSetting.privateKey')}
          name={systemSettingsFieldsEnum.private_key}
          style={{
            width: 500,
            height: 200,
            boxSizing: 'border-box',
            borderRadius: '8px',
            color: '#838998',
            marginRight: `24px`,
            marginBottom: 20,
            padding: '0px 12px !important',
          }}
          inputProps={{ style: { height: 155 } }}
          onChange={setValue}
          value={groupData.private_key}
          multiline
          rows={20}
        />
        <InputKit
          label={t('systemSetting.clientEmail')}
          name={systemSettingsFieldsEnum.client_email}
          onChange={setValue}
          value={groupData.client_email}
          onBlurHandler={() => onBlurHandler('client_email', groupData.client_email)}
          isError={error?.client_email}
          helperText={error.client_email ? t('errors.emailNotValid') : ''}
          wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
        />
        <InputKit
          label={t('systemSetting.gsuiteDomain')}
          name={systemSettingsFieldsEnum.gsuite_domain}
          onChange={setValue}
          value={groupData.gsuite_domain}
          onBlurHandler={() => onBlurHandler('gsuite_domain', groupData.gsuite_domain)}
          wrapProps={{ width: 500, height: 60, marginBottom: 20 }}
        />
      </div>
    </TabPanel>
  );
};

export default GoogleApiCredentialsTab;

const useStyles = makeStyles(() => ({
  tabBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '500px',
    marginBottom: '23px',
    height: '48px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  formControl: {
    height: '48px',
    marginBottom: '35px',
  },
}));

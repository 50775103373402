import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type activationOrderListType = {
  company_id?: string;
  show_deleted?: boolean;
  contract_number?: string;
  currency?: string;
  errorCallback: Function;
  resultKey?: string;
  _sort?: null | string;
};

export default ({
  company_id = '',
  contract_number = '',
  currency = '',
  show_deleted = false,
  _sort,
  errorCallback,
  resultKey = 'activationOrderListData',
}: activationOrderListType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getActivationOrderListUrl(company_id, show_deleted, contract_number, currency, _sort),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getActivationOrderListUrl(company_id, show_deleted, contract_number, currency, _sort),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import type { Dictionary } from 'lodash';
import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface SenderType {
  company_id: string;
  company_name: string;
  currency: string;
}

export interface RecipientType {
  company_id: string;
  company_name: string;
  currency: string;
}

export interface AmountTransactionType {
  currency: string;
  amount: number;
}

export interface bodyCreateTransactionsType {
  type: string;
  value_datetime?: string;
  transaction_date?: string;
  reference?: string;
  details?: string;
  comment?: string;
  sender: SenderType;
  recipient: RecipientType;
  amount: AmountTransactionType;
  document_id?: string;
  document_number?: string;
  document_type?: string;
  category?: string;
  factoring_company_id?: string;
  factoring_company_name?: string;
  factoring_order_id?: string;
  factoring_order_number?: string;
}

export interface createListTransactionsType {
  body: bodyCreateTransactionsType | Dictionary<bodyCreateTransactionsType>;
  successCallback?: Function;
  errorCallback?: Function;
  resultKey?: string;
}

export default ({
  body,
  errorCallback,
  resultKey = 'listTransactionData',
  successCallback,
}: createListTransactionsType) =>
  requestAsync({
    url: endpoints.createListTransactionsUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.createListTransactionsUrl(),
    body,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';

import ContentWrapper from 'components/ContentWrapper';
import Title from 'components/common/Title';
import DateInput, { DatePikerEnum } from 'components/inputs/DateInput';
import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { Account } from 'types/ActivationOrderTypes';
import SortTable from 'components/SortTable';
import useCompany from 'hooks/api/useCompany';
import { ShortNameSort } from 'types/Sort';
import useQuery from 'hooks/useQuery';
import { formatAmount, getCodeCurrencyOfName, getCurrency } from 'helpers/formatters';
import { updateTitle } from 'helpers/common';
import CompanySelect from 'components/CompanySelect';

import styles from './styles.module.scss';

enum companyLabels {
  id = 'id',
  company_name = 'company_name',
  status = 'status',
  groups = 'groups',
}

const SellerAccountBalance = () => {
  const history = useHistory();
  const query = useQuery();
  const [companiesForTable, setCompaniesForTable] = useState([]);
  const [total, setTotal] = useState({
    eur: `${getCurrency('EUR')}${formatAmount(0)}`,
    usd: `${getCurrency('USD')}${formatAmount(0)}`,
  });
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { getCompaniesV3, companiesTableDataV3 } = useCompany();

  const [filter, setFilter] = useState<any>({
    date: moment().format('YYYY-MM-DD'),
    companyId: '',
    order: ShortNameSort.ASC,
    sortBy: companyLabels.company_name,
    ...query,
  });
  const { t } = useTranslation();

  const companiesTableDataV3Client = useMemo(
    () => companiesTableDataV3.records.filter((company) => company.type === 'client'),
    [companiesTableDataV3],
  );

  const updateFilter = (value) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      ...value,
    }));
  };

  useEffect(() => {
    if (companiesTableDataV3Client.length > 0 && accounts.length > 0) {
      const accountsWithCompanyId = accounts.map((account) => ({
        ...account,
        company_id: account?.company_id.replaceAll('-', ''),
      }));

      let newTotalUsd = 0;
      let newTotalEur = 0;

      let companiesForSearch = companiesTableDataV3Client;

      if (filter.order === ShortNameSort.DESC) {
        companiesForSearch = companiesForSearch.map((e) => e).reverse();
      }

      if (filter.companyId) {
        companiesForSearch = companiesForSearch.filter((e) => e.id === filter.companyId);
      }

      const newCompaniesForTable = companiesForSearch.map((company) => {
        const companyAccounts = accountsWithCompanyId.filter((account) => account.company_id === company.id);

        if (companyAccounts.length > 0) {
          const balanceEur = companyAccounts.find(
            (account) => account.currency === String(getCodeCurrencyOfName('EUR')),
          );
          const balanceUsd = companyAccounts.find(
            (account) => account.currency === String(getCodeCurrencyOfName('USD')),
          );

          if (balanceEur?.balance) {
            newTotalEur += Number(balanceEur.balance);
          }

          if (balanceUsd?.balance) {
            newTotalUsd += Number(balanceUsd.balance);
          }

          return {
            ...company,
            balanceEur: balanceEur?.balance
              ? `${getCurrency('EUR')}${formatAmount(Number(balanceEur.balance))}`
              : 'N/A',
            balanceUsd: balanceUsd?.balance
              ? `${getCurrency('USD')}${formatAmount(Number(balanceUsd.balance))}`
              : 'N/A',
          };
        }

        return {};
      });

      setTotal({
        eur: `${getCurrency('EUR')}${formatAmount(newTotalEur)}`,
        usd: `${getCurrency('USD')}${formatAmount(newTotalUsd)}`,
      });

      setCompaniesForTable(newCompaniesForTable.filter((company) => Object.keys(company).length > 0));
    }
  }, [companiesTableDataV3Client, accounts, filter]);

  useEffect(() => {
    history.push(`${history.location.pathname}?${queryString.stringify(filter, { skipEmptyString: true })}`);
  }, [filter, history]);

  useEffect(() => {
    getCompaniesV3({ limit: -1 });

    updateTitle('Seller Account Balance');
  }, []);

  useEffect(() => {
    baseRequest({
      path: endpoints.getAccountsUrl({
        size: 999,
        type: 'counterparty-client',
        'balance-date': filter.date,
      }),
    }).then(({ items }) => setAccounts(items));
  }, [filter.date]);

  return (
    <ContentWrapper style={{ padding: '20px 40px', position: 'relative' }} maxWidthContent fullHeight>
      <div>
        <div className={styles.titleWrapper}>
          <Title text={t('menu.sellerAccountBalance')} />
        </div>
        <div className={styles.container}>
          <div className={styles.filter}>
            <div className={styles.date}>
              <DateInput
                type={DatePikerEnum.ALL}
                date={filter.date}
                onChangeDateHandler={(e) => updateFilter({ date: e.target.value })}
                labelKey="global.date"
                commonStyle={{ height: 40 }}
                disableFutureYears
                disableFutureMonth
                disableFutureDay
              />
            </div>
            <div className={styles.company}>
              <CompanySelect
                width="320px"
                label={t('contentHeader.company')}
                onChange={(id) => updateFilter({ companyId: id })}
                value={filter.companyId}
                allowClear
              />
            </div>
          </div>
        </div>
      </div>
      <Table className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow className={classNames(styles.tableRow, styles.tableHeaderRow)}>
            <TableCell align="left" className={styles.tableHeaderCell}>
              <SortTable
                isActive
                onClickHandler={(order) => {
                  setFilter((oldFilter) => ({
                    ...oldFilter,
                    order,
                  }));
                }}
              >
                {t('global.client')}
              </SortTable>
            </TableCell>
            <TableCell align="right" width="200" className={styles.tableHeaderBalance}>
              {t('global.balanceEur')}
            </TableCell>
            <TableCell align="right" width="200" className={styles.tableHeaderBalance}>
              {t('global.balanceUsd')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {companiesForTable.map((item) => (
            <TableRow className={classNames(styles.tableRow, styles.tableBodyRow)} key={item.id}>
              <TableCell align="left">{item.company_name}</TableCell>
              <TableCell align="right" width="200">
                {item.balanceEur}
              </TableCell>
              <TableCell align="right" width="200">
                {item.balanceUsd}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter className={styles.tableFooter}>
          <TableRow className={classNames(styles.tableRow, styles.tableFooterRow)}>
            <TableCell align="left">{t('global.total')}</TableCell>
            <TableCell align="right" width="200">
              {total.eur}
            </TableCell>
            <TableCell align="right" width="200">
              {total.usd}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </ContentWrapper>
  );
};

export default SellerAccountBalance;

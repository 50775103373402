import React, { FC, TextareaHTMLAttributes } from 'react';

import styles from './styles.module.scss';

export type CurrencyType = 'EUR' | 'USD' | 'RUB';

export interface PropsT extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onBlurHandler?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string;
  wrapProps?: React.CSSProperties;
  isError?: boolean;
  helperText?: string;
  offsetLeft?: number;
  currency?: CurrencyType;
  fontSize?: number;
  element?: React.ReactNode;
  selectOffsetRight?: number;
}

const red = '#EC3E72';

const Textarea: FC<PropsT> = ({
  wrapProps,
  isError = false,
  helperText = '',
  offsetLeft = 20,
  element,
  maxLength,
  value,
  ...restProps
}) => (
  <label className={styles.wrapper} style={{ ...wrapProps, border: isError ? '1px solid rgb(236, 62, 114)' : '' }}>
    <textarea
      className={styles.textarea}
      value={value}
      maxLength={maxLength}
      {...restProps}
      placeholder={`${restProps.placeholder}${restProps.required ? '*' : ''}`}
    />
    {maxLength && (
      <p className={styles.textareaLength} style={{ color: String(value).length > maxLength ? red : '#A7AFB7' }}>
        {`${value?.length || 0}/${maxLength}`}
      </p>
    )}
    {helperText.length > 0 && isError && (
      <div className={styles.helperText} style={{ color: isError ? red : '#A7AFB7', bottom: -16, left: offsetLeft }}>
        {helperText}
      </div>
    )}
    {element}
  </label>
);

export default Textarea;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import useCompany from 'hooks/api/useCompany';
import useTransaction from 'hooks/api/useTransaction';
import useErrorCallback from 'hooks/useErrorCallback';
import useSuccessCallback from 'hooks/useSuccessCallback';
import { filterCompanies } from 'helpers/companies';
import {
  getConversionTransactionNewErrorValue,
  getConversionTransactionNewFormValue,
} from 'helpers/transactionFormHelpers';
import { RefreshContext } from 'contexts/RefreshContext';
import urls from 'constants/urls';
import {
  CompanyType,
  conversionTransactionFormDataType,
  conversionTransactionErrorType,
} from 'types/TransactionFormTypes';
import { formatDateIsoShort } from 'helpers/formatters';
import { validateConversionTransaction } from 'helpers/validateTransaction';
import DateInput from 'components/inputs/DateInput';
import AmountInput from 'components/inputs/AmountInput';
import CustomInput from 'components/inputs/CustomInput';
import useModal from 'hooks/api/useModal';

import FormBottomBlock from '../FormBottomBlock';

import { AutocompletedInput } from './ui';
import {
  initialCurrency,
  getInitialConversionTransactionError,
  initialConversionTransactionFormData,
} from './initialValues';

const ConversionTransaction = ({ editType = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { transactionId = '' } = useParams();
  const requiredText = t('auth.required');
  const refreshContext = useContext(RefreshContext);
  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText }) => {
    setIsPending(false);

    return errorCallbackView({ errorField, errorCallbackText });
  });
  const errorCallbackView = useCallback(({ errorField, errorCallbackText }) => {
    if (Object.keys(error).includes(errorField)) {
      setError((prevState) => ({
        ...prevState,
        [errorField]: { status: true, text: errorCallbackText },
      }));

      return false;
    }

    return true;
  }, []);

  const { successCallback } = useSuccessCallback({
    editType,
    route: urls.getTransaction(),
    messageKey: 'alert.transaction',
  });
  const {
    companiesData: { companiesData = [] },
    getCompanies,
  } = useCompany();
  const {
    transactionData,
    createTransaction,
    editTransaction,
    getTransaction,
    getEntries,
    clearTransactionData,
    getTransactionIsFetching,
    deleteTransaction,
  } = useTransaction();
  const [formData, setFormData] = useState<conversionTransactionFormDataType>(initialConversionTransactionFormData);
  const [company, setCompany] = useState<CompanyType>({ name: '', id: '' });
  const [canByDeletedTransaction, setCanByDeletedTransaction] = useState<boolean>(true);
  const [toolTip, setToolTip] = useState<string>('');
  const [error, setError] = useState<conversionTransactionErrorType>(
    getInitialConversionTransactionError(requiredText),
  );
  const [isPending, setIsPending] = useState<boolean>(false);
  const [debitCurrencyArr, setDebitCurrencyArr] = useState(initialCurrency);
  const [replenishCurrencyArr, setReplenishCurrencyArr] = useState(initialCurrency);
  const [lastMode, setLastMode] = useState('');
  const companies = useMemo(() => filterCompanies(companiesData), [companiesData]);
  const { modalData, setModal } = useModal();

  useMemo(() => {
    if (modalData.confirm) {
      deleteTransaction({
        id: transactionId,
        successCallback: successCallback.bind(null, { deleted: true }),
        errorCallback,
      });
      setModal({ show: false, confirm: false, type: '', description: '' });
    }
  }, [modalData, transactionId, deleteTransaction, successCallback, errorCallback, setModal, formData]);

  useMemo(() => {
    if (Object.keys(transactionData).length && editType && transactionData.type === 'CONVERSION') {
      setIsPending(false);
      setCanByDeletedTransaction(transactionData.deletion.can_be_deleted);
      setCompany({
        name: transactionData.company.name,
        id: transactionData.company.id,
        active: transactionData.company?.active,
      });
      setLastMode(transactionData.mode);

      if (transactionData.deletion.reason) {
        setToolTip(transactionData.deletion.reason);
      }
      setFormData((prev) => {
        const newFormData = Object.assign(
          {},
          ...Object.keys(prev).map((key) => ({ [key]: (key in transactionData ? transactionData : prev)[key] })),
        );

        return { ...newFormData, date: formatDateIsoShort(transactionData.date) };
      });
    }
  }, [transactionData, editType]);

  useEffect(() => {
    refreshContext.clear();
    getCompanies({ showDeleted: false, errorCallback });
    refreshContext.add({ func: getCompanies, params: { showDeleted: false, errorCallback } });

    if (editType) {
      getTransaction({ id: transactionId, errorCallback });
      getEntries({ id: transactionId, errorCallback });
      refreshContext.add({ func: getTransaction, params: { id: transactionId, errorCallback } });
      refreshContext.add({ func: getEntries, params: { id: transactionId, errorCallback } });
    }
  }, [editType, getCompanies, getEntries, refreshContext, errorCallback, transactionId, getTransaction]);

  useEffect(
    () => () => {
      refreshContext.clear();
      clearTransactionData();
    },
    [refreshContext, clearTransactionData],
  );

  const setErrorHelper = useCallback(
    (field: string, fieldValue: string | number, target: boolean) => {
      if (target) {
        setError((prev) => ({
          ...prev,
          target: { ...prev.target, [field]: { status: !fieldValue, text: requiredText } },
        }));
      } else {
        setError((prev) => ({ ...prev, [field]: { status: !fieldValue, text: requiredText } }));
      }
    },
    [requiredText],
  );

  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (name === 'currency' && value) {
        setReplenishCurrencyArr(initialCurrency.filter((item) => item.value !== value));
      }

      if (name === 'currency_replenish' && value) {
        setDebitCurrencyArr(initialCurrency.filter((item) => item.value !== value));
        setFormData((prev) => ({ ...prev, targets: [{ ...prev.targets[0], currency: value }] }));
        setError((prev) => ({
          ...prev,
          target: { ...prev.target, currency: { status: !value, text: prev.amount.text } },
        }));

        return;
      }

      if (name === 'rate' || name === 'amount_replenish' || name === 'amount') {
        const newData = getConversionTransactionNewFormValue(formData, name, value, lastMode);
        const newError = getConversionTransactionNewErrorValue(error, name, value);
        setFormData(newData);
        setLastMode((prev) => (prev !== newData.mode ? newData.mode : prev));
        setError(newError);

        return;
      }
      setErrorHelper(name, value, false);
      setFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setErrorHelper, formData, lastMode, error],
  );

  const onChangeCompanyHandler = useCallback((_, values: CompanyType | null) => {
    setCompany({ name: values?.name, id: values?.id });
    setFormData((prev) => ({ ...prev, company_id: values?.id }));
    setError((prev) => ({ ...prev, company_id: { status: !values, text: prev.company_id.text } }));
  }, []);

  const cancelHandler = useCallback(() => {
    history.push(urls.getTransaction());
  }, [history]);

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const { validated, errors } = validateConversionTransaction(formData, requiredText);

      if (validated) {
        setIsPending(true);

        if (editType) {
          editTransaction({ ...formData, id: transactionId, successCallback, errorCallback });
        } else {
          createTransaction({ ...formData, successCallback, errorCallback });
        }
      }
      setError(errors);
    },
    [
      formData,
      requiredText,
      errorCallback,
      createTransaction,
      editTransaction,
      editType,
      successCallback,
      transactionId,
    ],
  );

  if (getTransactionIsFetching) {
    return null;
  }

  if (editType && !transactionData.id) {
    return <h3 style={{ color: 'gray', paddingTop: 60 }}>{t('forms.transaction.transactionNotFound')}</h3>;
  }

  return (
    <form className={classes.container} noValidate onSubmit={onSubmit}>
      <div className={classes.topBlock}>
        <h1 style={{ marginBottom: 30 }}>{t('forms.transaction.conversion')}</h1>
        <div className={classes.block}>
          <div style={{ marginRight: 260 }}>
            <DateInput
              date={formData.date}
              required
              labelKey="tables.date"
              onChangeDateHandler={setFormValue}
              name="date"
              commonStyle={{ width: 500 }}
              disabled={!canByDeletedTransaction}
              toolTip={toolTip}
            />
          </div>
          <AutocompletedInput
            error={error.company_id.status}
            label={t('contentHeader.companyName')}
            defaultValue={company}
            onBlurHandler={() => setErrorHelper('company_id', formData.company_id, false)}
            list={companies}
            onChangeHandler={onChangeCompanyHandler}
            inputWidth={300}
            disabled={!canByDeletedTransaction}
            dataTip={toolTip}
          />
        </div>
        <div className={classes.block}>
          <div className={classes.amountBlock}>
            <AmountInput
              isNeedHandlePaste
              amount={formData.amount}
              error={error.amount.status}
              required
              label={t('forms.transaction.conversionTransaction.amountDebit')}
              handleAmountChange={setFormValue}
              onBlurHandler={() => setErrorHelper('amount', formData.amount, false)}
              name="amount"
              inputWidth="500"
              disabled={formData.mode === 'FIX_AMOUNT1' || !canByDeletedTransaction}
              toolTip={toolTip}
            />
            <FormControl error={error.currency.status}>
              <CustomInput
                labelKey="forms.transaction.conversionTransaction.currencyDebit"
                name="currency"
                onChange={setFormValue}
                onBlurHandler={() => setErrorHelper('currency', formData.currency, false)}
                defaultValue={formData.currency}
                select
                required
                selectItems={debitCurrencyArr}
                isError={error.currency.status}
                errorText={error.currency.text}
                inputWidth={200}
                disabled={!canByDeletedTransaction}
                toolTip={toolTip}
                stylesProp={{
                  marginRight: 8,
                }}
              />
            </FormControl>
          </div>
          <AmountInput
            isNeedHandlePaste
            amount={formData.targets[0].rate}
            error={error.target.rate.status}
            required
            label={`${t('forms.transaction.rate')} ${formData.currency}/${formData.targets[0].currency}`}
            handleAmountChange={setFormValue}
            onBlurHandler={() => setErrorHelper('rate', formData.targets[0].rate, true)}
            name="rate"
            inputWidth="300"
            decimalScale={4}
            disabled={formData.mode === 'FIX_RATE' || !canByDeletedTransaction}
            toolTip={toolTip}
          />
        </div>
        <div className={classes.amountBlock}>
          <AmountInput
            isNeedHandlePaste
            amount={formData?.targets[0]?.amount ? Number(formData?.targets[0]?.amount).toFixed(2) : ''}
            error={error.target.amount.status}
            required
            label={t('forms.transaction.conversionTransaction.amountReplenish')}
            handleAmountChange={setFormValue}
            onBlurHandler={() => setErrorHelper('amount', formData.targets[0].amount, true)}
            name="amount_replenish"
            inputWidth="500"
            disabled={formData.mode === 'FIX_AMOUNT2' || !canByDeletedTransaction}
            toolTip={toolTip}
          />
          <FormControl error={error.target.currency.status}>
            <CustomInput
              labelKey="forms.transaction.conversionTransaction.currencyReplenish"
              name="currency_replenish"
              onChange={setFormValue}
              onBlurHandler={() => setErrorHelper('currency', formData.targets[0].currency, true)}
              defaultValue={formData.targets[0].currency}
              select
              required
              selectItems={replenishCurrencyArr}
              isError={error.target.currency.status}
              errorText={error.target.currency.text}
              inputWidth={200}
              disabled={!canByDeletedTransaction}
              toolTip={toolTip}
              stylesProp={{
                marginRight: 8,
              }}
            />
          </FormControl>
        </div>
        <CustomInput
          labelKey="tables.comment"
          name="comment"
          onChange={setFormValue}
          defaultValue={formData.comment}
          multiline
          rows={6}
          stylesProp={{ width: 500 }}
          disabled={!canByDeletedTransaction}
          toolTip={toolTip}
        />
      </div>
      <FormBottomBlock cancelHandler={cancelHandler} disabled={isPending} />
      <ReactTooltip />
    </form>
  );
};

export default ConversionTransaction;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingBottom: '0',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    marginTop: '16px',
    borderRadius: '4px',
    overflowX: 'auto',
  },
  topBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    flexWrap: 'wrap',
  },
  block: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  amountBlock: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '60px',
    flexWrap: 'wrap',
  },
  formControl: {
    height: '48px',
    marginBottom: '35px',
  },
}));

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface GetParamsType {
  show_deleted?: boolean;
  _order?: string;
  _sort?: string;
  page?: number;
  limit?: number;
  user_name?: string;
  email?: string;
  company_name?: string;
}

export type usersDataType = {
  params?: GetParamsType;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({ errorCallback, resultKey = 'usersDataFilter', params, successCallback }: usersDataType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getUsersFilterUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getUsersFilterUrl(params),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React from 'react';

import Alert from '../Alert';
import Modal from '../Modal';

import Menu from 'components/Menu';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Layout = ({ children, styles }) => (
  <>
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'max-content 1fr',

        height: '100%',
        backgroundColor: 'rgb(247, 248, 250)',
        ...styles,
      }}
    >
      <Menu />
      <div style={{ width: '100%', overflowY: 'auto', overflowX: 'auto' }}>{children}</div>
      {/* TODO: удалить */}
      <Alert />
      <Modal />
    </div>
  </>
);

export default Layout;

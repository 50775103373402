import { SET_ALERT } from 'actions/types';

const initialState = {};

export default (state = initialState, action: { type: string; payload: object }) => {
  if (action.type === SET_ALERT) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

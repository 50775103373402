import React, { FC, useCallback } from 'react';

import { getDecimal } from 'helpers/formatters';

import { InputKit, InputKitType } from '../InputKit';

interface AmountKitType extends InputKitType {
  decimalScale?: number;
  amount: string;
  allowNegative?: boolean;
  isNeedHandlePaste?: boolean;
}

export const AmountKit: FC<AmountKitType> = (props) => {
  const {
    decimalScale = 2,
    onBlurHandler,
    onChange = () => {},
    isNeedHandlePaste = true,
    amount,
    allowNegative = true,
    helperText,
    isError,
    fontSize = 24,
  } = props;
  const checkValue = useCallback(
    (value: string) => {
      const local = String(value).replaceAll(',', '.').replaceAll(' ', '').replaceAll(' ', '');
      let error = '';

      if ((!allowNegative && Number(local) < 0) || (local === '-' && !allowNegative)) {
        error = 'the value must be positive';

        return { isNumber: false, value: local, error };
      }

      if (Number.isNaN(Number(local)) && local !== '-') {
        return { isNumber: false, value: local, error };
      }
      const array = local.split('.');

      if (array.length === 1) {
        return { isNumber: true, value: array[0], num: local, error };
      }

      return {
        isNumber: true,
        value: `${array[0]}${getDecimal(navigator.language)}${array[1].slice(0, decimalScale)}`,
        num: `${array[0]}.${array[1].slice(0, decimalScale)}`,
        error,
      };
    },
    [decimalScale],
  );

  const handlePaste = useCallback(
    (e) => {
      const current = e.clipboardData.getData('Text').replaceAll(',', '.');
      const { isNumber } = checkValue(current);

      if (!isNumber) {
        return;
      }
      onChange(e);
    },
    [checkValue, onChange],
  );

  const { value, error } = checkValue(amount);

  return (
    <div>
      <InputKit
        {...props}
        onBlurHandler={onBlurHandler}
        onChange={(e) => {
          const { isNumber, num } = checkValue(e.target.value);

          if (!isNumber) {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          e.target.value = num;
          onChange(e);
        }}
        value={value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        onPaste={
          isNeedHandlePaste
            ? (event) => {
                handlePaste(event);
              }
            : () => {}
        }
        isError={error.length > 0 ? true : isError}
        helperText={error.length > 0 ? error : helperText}
        fontSize={fontSize}
      />
    </div>
  );
};

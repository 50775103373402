// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import i18next from 'i18next';

import { removeEmptyValuesFromObject } from 'helpers/dt';
import { getYYYYMMDD } from 'helpers/formatters';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { bodyCreateTransactionsType } from 'queries/transaction/createListTransactions';
import { KeyTransactions, getTypeTransactions } from 'components/tables/TransactionsTable/data';

import { getDifferentCurrencyOfCode, GET_COMMISSION_COEFFICIENT, GET_CONVERSION_RATE } from './SellBuy';

export const initialAllocate = {
  [KeyTransactions.DOCUMENT_ID]: '',
  [KeyTransactions.DOCUMENT_NUMBER]: '',
  [KeyTransactions.CURRENCY]: '',
  [KeyTransactions.AMOUNT]: '',
  [KeyTransactions.CATEGORY]: '',
  [KeyTransactions.ID]: '',
};

interface typeInitialAllocate {
  document_id: string;
  document_number?: string;
  currency: string;
  amount: string;
  category: string;
  id?: string;
}

export const initialObjTransactions = {
  [KeyTransactions.ID]: '',
  [KeyTransactions.TYPE]: '',
  [KeyTransactions.VALUE_DATE_TIME]: getYYYYMMDD(new Date()),
  [KeyTransactions.TRANSACTION_DATE]: '',
  [KeyTransactions.CURRENCY]: '',
  [KeyTransactions.AMOUNT]: '',
  [KeyTransactions.RECIPIENT]: {
    [KeyTransactions.COMPANY_ID]: '',
    [KeyTransactions.COMPANY_NAME]: '',
    [KeyTransactions.CURRENCY]: '',
  },
  [KeyTransactions.SENDER]: {
    [KeyTransactions.COMPANY_ID]: '',
    [KeyTransactions.COMPANY_NAME]: '',
    [KeyTransactions.CURRENCY]: '',
  },
  [KeyTransactions.CHECKBOX]: true,
  [KeyTransactions.REFERENCE]: '',
  [KeyTransactions.DETAILS]: '',
  [KeyTransactions.COMMENT]: '',
  [KeyTransactions.CATEGORY]: '',
  [KeyTransactions.DOCUMENT_AO]: '',
  [KeyTransactions.DOCUMENT_ID]: '',
  [KeyTransactions.DOCUMENT_NUMBER]: '',
  [KeyTransactions.DOCUMENT_TYPE]: '',
  [KeyTransactions.CLAIMS]: '',
  [KeyTransactions.DOCUMENT_REQUEST]: '',
  [KeyTransactions.AMOUNT_SELL]: '',
  [KeyTransactions.AMOUNT_BUY]: '',
  [KeyTransactions.COMMISSION]: '3.5',
  [KeyTransactions.RATE_ECB]: 0,
  [KeyTransactions.FACTOR_ID]: '',
  [KeyTransactions.TRADING_NAME]: '',
  [KeyTransactions.BANK_NAME]: '',
  [KeyTransactions.SWIFT]: '',
  [KeyTransactions.BANK_NAME_EUR]: '',
  [KeyTransactions.BANK_NAME_USD]: '',
  [KeyTransactions.SWIFT_EUR]: '',
  [KeyTransactions.SWIFT_USD]: '',
};

export type initialObjTransactionsType = {
  [KeyTransactions.ID]?: string;
  [KeyTransactions.TYPE]?: string;
  [KeyTransactions.VALUE_DATE_TIME]?: string;
  [KeyTransactions.TRANSACTION_DATE]?: string;
  [KeyTransactions.CURRENCY]?: string;
  [KeyTransactions.AMOUNT]?: number | string;
  [KeyTransactions.RECIPIENT]?: {
    [KeyTransactions.COMPANY_ID]: string;
    [KeyTransactions.COMPANY_NAME]: string;
    [KeyTransactions.CURRENCY]: string;
  };
  [KeyTransactions.SENDER]?: {
    [KeyTransactions.COMPANY_ID]: string;
    [KeyTransactions.COMPANY_NAME]: string;
    [KeyTransactions.CURRENCY]: string;
  };
  [KeyTransactions.CHECKBOX]?: boolean;
  [KeyTransactions.REFERENCE]?: string;
  [KeyTransactions.DETAILS]?: string;
  [KeyTransactions.COMMENT]?: string;
  [KeyTransactions.CATEGORY]?: string;
  [KeyTransactions.DOCUMENT_AO]?: string;
  [KeyTransactions.DOCUMENT_ID]?: string;
  [KeyTransactions.DOCUMENT_NUMBER]?: string;
  [KeyTransactions.DOCUMENT_TYPE]?: string;
  [KeyTransactions.CLAIMS]?: string;
  [KeyTransactions.DOCUMENT_REQUEST]?: string;
  [KeyTransactions.AMOUNT_SELL]?: string;
  [KeyTransactions.AMOUNT_BUY]?: string;
  [KeyTransactions.COMMISSION]?: string;
  [KeyTransactions.RATE_ECB]?: number;
  [KeyTransactions.FACTOR_ID]?: string;
  [KeyTransactions.TRADING_NAME]?: string;
  [KeyTransactions.BANK_NAME]?: string;
  [KeyTransactions.SWIFT]?: string;
  [KeyTransactions.BANK_NAME_EUR]?: string;
  [KeyTransactions.BANK_NAME_USD]?: string;
  [KeyTransactions.SWIFT_EUR]?: string;
  [KeyTransactions.SWIFT_USD]?: string;
};

export enum DocumentTypeEnum {
  ORDER = 'order',
  REQUEST = 'request',
  CLAIM = 'claim',
  DEFAULT = 'default',
}

const getCompanyOfType = (data: ItemTransactionsType) => {
  const obj = {
    [KeyTransactions.INCOMING]: data?.sender,
    [KeyTransactions.OUTGOING]: data?.recipient,
  };

  return obj[data.type] || data?.sender;
};

export const setInitialKey = (data: ItemTransactionsType) => {
  const documentId = (data.document_id || data.factoring_order_id || '').replaceAll('-', '');
  const common = Object.keys(initialObjTransactions).reduce(
    (prev, key) => ({ ...prev, [key]: data[key] || initialObjTransactions[key] }),
    {},
  ) as initialObjTransactionsType;
  const obj = {
    ...removeEmptyValuesFromObject(common),
    [KeyTransactions.CURRENCY]:
      data.type === KeyTransactions.CONVERSION
        ? String(data?.amount?.currency_sell || '')
        : String(data?.amount?.currency || ''),
    [KeyTransactions.AMOUNT]: data?.amount.amount || 0,
    [KeyTransactions.CHECKBOX]: data.factoring_company_id === getCompanyOfType(data).company_id,
    [KeyTransactions.DOCUMENT_AO]: documentId,
    [KeyTransactions.DOCUMENT_ID]: documentId,
    [KeyTransactions.RECIPIENT]: {
      [KeyTransactions.COMPANY_ID]: (data.factoring_company_id || '').replaceAll('-', ''),
      [KeyTransactions.COMPANY_NAME]: data.factoring_company_name,
      [KeyTransactions.CURRENCY]: String(data?.amount?.currency || ''),
    },
    [KeyTransactions.SENDER]: {
      [KeyTransactions.COMPANY_ID]: (getCompanyOfType(data)?.company_id || '').replaceAll('-', ''),
      [KeyTransactions.COMPANY_NAME]: getCompanyOfType(data)?.company_name,
      [KeyTransactions.CURRENCY]: String(data?.amount?.currency || ''),
    },
    [KeyTransactions.AMOUNT_SELL]: String(data?.amount.amount_sell || ''),
    [KeyTransactions.AMOUNT_BUY]: String(data?.amount.amount_buy || ''),
  };

  return obj;
};

export const setInitialOutgoingIncomingKey = (data: ItemTransactionsType) => {
  const documentId = (data.document_id || data.factoring_order_id || '').replaceAll('-', '');

  const common = Object.keys(initialObjTransactions).reduce(
    (prev, key) => ({ ...prev, [key]: data[key] || initialObjTransactions[key] }),
    {},
  ) as initialObjTransactionsType;
  const obj = {
    ...removeEmptyValuesFromObject(common),
    [KeyTransactions.CURRENCY]:
      data.type === KeyTransactions.CONVERSION
        ? String(data?.amount?.currency_sell || '')
        : String(data?.amount?.currency || ''),
    [KeyTransactions.AMOUNT]: data?.amount.amount || 0,
    [KeyTransactions.CHECKBOX]: data.factoring_company_id === getCompanyOfType(data).company_id,
    [KeyTransactions.DOCUMENT_AO]: documentId,
    [KeyTransactions.DOCUMENT_ID]: documentId,
    [KeyTransactions.FACTORING_COMPANY_ID]: (data.factoring_company_id || '').replaceAll('-', ''),
    [KeyTransactions.FACTORING_COMPANY_NAME]: data.factoring_company_name,
    [KeyTransactions.RECIPIENT]: {
      [KeyTransactions.COMPANY_ID]: (data.recipient.company_id || '').replaceAll('-', ''),
      [KeyTransactions.COMPANY_NAME]: data.recipient.company_name,
      [KeyTransactions.CURRENCY]: String(data?.amount?.currency || ''),
    },
    [KeyTransactions.SENDER]: {
      [KeyTransactions.COMPANY_ID]: (data.sender.company_id || '').replaceAll('-', ''),
      [KeyTransactions.COMPANY_NAME]: data.sender.company_name,
      [KeyTransactions.CURRENCY]: String(data?.amount?.currency || ''),
    },
    [KeyTransactions.AMOUNT_SELL]: String(data?.amount.amount_sell || ''),
    [KeyTransactions.AMOUNT_BUY]: String(data?.amount.amount_buy || ''),
  };

  return obj;
};

export const getCurrencyTransactions = () => [
  { key: KeyTransactions.EUR, value: i18next.t('selectFiledValues.eur') },
  { key: KeyTransactions.USD, value: i18next.t('selectFiledValues.usd') },
];

export const getValueCurrencyTransactions = () => ({
  [KeyTransactions.EUR]: getCurrencyTransactions()[0],
  [KeyTransactions.USD]: getCurrencyTransactions()[1],
});

export const formattingParamsForAdjusting = (data: initialObjTransactionsType) => {
  const obj = {
    ...getOrderObj(data),
    [KeyTransactions.TYPE]: data[KeyTransactions.TYPE],
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    [KeyTransactions.FACTORING_COMPANY_ID]: data.recipient.company_id,
    [KeyTransactions.FACTORING_COMPANY_NAME]: data.recipient.company_name,
    amount: {
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.AMOUNT]: Number(data[KeyTransactions.AMOUNT]),
    },
  };

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

export const formattingParamsForFee = (data: initialObjTransactionsType) => {
  const obj = {
    [KeyTransactions.TYPE]: data[KeyTransactions.TYPE],
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    [KeyTransactions.DETAILS]: data[KeyTransactions.DETAILS],
    [KeyTransactions.FACTORING_COMPANY_ID]: data.recipient.company_id,
    [KeyTransactions.FACTORING_COMPANY_NAME]: data.recipient.company_name,
    recipient: {
      [KeyTransactions.COMPANY_ID]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID],
      [KeyTransactions.COMPANY_NAME]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_NAME],
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
    },
    amount: {
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.AMOUNT]: Number(data[KeyTransactions.AMOUNT]),
    },
  };

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

export const formattingParamsForInternal = (data: initialObjTransactionsType) => {
  const obj = {
    [KeyTransactions.TYPE]: data[KeyTransactions.TYPE],
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    [KeyTransactions.DETAILS]: data[KeyTransactions.DETAILS],
    [KeyTransactions.FACTORING_ORDER_ID]: data[KeyTransactions.DOCUMENT_ID],
    [KeyTransactions.FACTORING_ORDER_NUMBER]: data[KeyTransactions.DOCUMENT_NUMBER],
    [KeyTransactions.CATEGORY]: data[KeyTransactions.CATEGORY],
    [KeyTransactions.FACTORING_COMPANY_ID]: data.recipient.company_id,
    [KeyTransactions.FACTORING_COMPANY_NAME]: data.recipient.company_name,
    amount: {
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.AMOUNT]: Number(data[KeyTransactions.AMOUNT]),
    },
  };

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

const getOrderObj = (data: initialObjTransactionsType) => {
  const factoringOrder = {
    [KeyTransactions.FACTORING_ORDER_NUMBER]: data[KeyTransactions.DOCUMENT_NUMBER],
  };

  if (
    !(
      KeyTransactions.OUTGOING === data[KeyTransactions.TYPE] &&
      [KeyTransactions.OUTGOING_BALANCE_PAYABLE, KeyTransactions.OUTGOING_TRANSIT].includes(
        data[KeyTransactions.CATEGORY] as KeyTransactions,
      )
    )
  ) {
    factoringOrder[KeyTransactions.FACTORING_ORDER_ID] = data[KeyTransactions.DOCUMENT_ID];
  }

  const orderDocument = {
    [KeyTransactions.DOCUMENT_ID]: data[KeyTransactions.DOCUMENT_ID],
    [KeyTransactions.DOCUMENT_NUMBER]: data[KeyTransactions.DOCUMENT_NUMBER],
    [KeyTransactions.DOCUMENT_TYPE]: data[KeyTransactions.DOCUMENT_TYPE],
  } as {
    [KeyTransactions.DOCUMENT_ID]?: string;
    [KeyTransactions.DOCUMENT_NUMBER]?: string;
    [KeyTransactions.DOCUMENT_TYPE]?: string;
    [KeyTransactions.FACTORING_ORDER_ID]?: string;
    [KeyTransactions.FACTORING_ORDER_NUMBER]?: string;
  };
  let order = orderDocument;

  if (data[KeyTransactions.DOCUMENT_TYPE] === 'order') {
    order = factoringOrder;
  }

  return order;
};

export const formattingParamsForOutgoing = (data: initialObjTransactionsType) => {
  let obj = {
    ...getOrderObj(data),
    [KeyTransactions.TYPE]: data[KeyTransactions.TYPE],
    [KeyTransactions.REFERENCE]: data[KeyTransactions.REFERENCE],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.DETAILS]: data[KeyTransactions.DETAILS],
    [KeyTransactions.CATEGORY]: data[KeyTransactions.CATEGORY],
    [KeyTransactions.FACTORING_COMPANY_ID]: data[KeyTransactions.FACTORING_COMPANY_ID],
    [KeyTransactions.FACTORING_COMPANY_NAME]: data[KeyTransactions.FACTORING_COMPANY_NAME],
    [KeyTransactions.SENDER]: {
      [KeyTransactions.CURRENCY]: data.sender?.currency,
      [KeyTransactions.BANK_NAME]: data.sender.bank_name,
      [KeyTransactions.SWIFT]: data.sender.swift,
      [KeyTransactions.CURRENCY]: data.sender?.currency,
      [KeyTransactions.COMPANY_ID]: data.sender?.[KeyTransactions.COMPANY_ID],
      [KeyTransactions.COMPANY_NAME]: data.sender?.[KeyTransactions.COMPANY_NAME],
      [KeyTransactions.ACCOUNT]: data.sender?.account,
    },
    [KeyTransactions.RECIPIENT]: {
      [KeyTransactions.COMPANY_ID]: data[KeyTransactions.FACTORING_COMPANY_ID],
      [KeyTransactions.COMPANY_NAME]: data[KeyTransactions.FACTORING_COMPANY_NAME],
    },
    amount: {
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.AMOUNT]: Number(data[KeyTransactions.AMOUNT]),
    },
  } as object;

  if (!data[KeyTransactions.CHECKBOX]) {
    obj = {
      ...obj,
      [KeyTransactions.RECIPIENT]: {
        [KeyTransactions.COMPANY_ID]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID],
        [KeyTransactions.COMPANY_NAME]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_NAME],
        [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
        [KeyTransactions.BANK_NAME]: data.bank_name,
      },
    };
  }
  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

export const formattingParamsForIncoming = (data: initialObjTransactionsType) => {
  let obj = {
    [KeyTransactions.TYPE]: data[KeyTransactions.TYPE],
    [KeyTransactions.REFERENCE]: data[KeyTransactions.REFERENCE],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    [KeyTransactions.TRANSACTION_DATE]: data[KeyTransactions.TRANSACTION_DATE]
      ? getYYYYMMDD(data[KeyTransactions.TRANSACTION_DATE])
      : '',
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.DETAILS]: data[KeyTransactions.DETAILS],
    [KeyTransactions.FACTORING_COMPANY_ID]: data[KeyTransactions.FACTORING_COMPANY_ID],
    [KeyTransactions.FACTORING_COMPANY_NAME]: data[KeyTransactions.FACTORING_COMPANY_NAME],
    [KeyTransactions.RECIPIENT]: {
      [KeyTransactions.COMPANY_ID]: data.recipient.company_id,
      [KeyTransactions.COMPANY_NAME]: data.recipient.company_name,
      [KeyTransactions.BANK_NAME]: data.recipient.bank_name,
      [KeyTransactions.SWIFT]: data.recipient.swift,
      [KeyTransactions.CURRENCY]: data.recipient?.currency,
      [KeyTransactions.ACCOUNT]: data.recipient?.account,
    },
    [KeyTransactions.SENDER]: {
      [KeyTransactions.COMPANY_ID]: data[KeyTransactions.FACTORING_COMPANY_ID],
      [KeyTransactions.COMPANY_NAME]: data[KeyTransactions.FACTORING_COMPANY_NAME],
    },
    amount: {
      [KeyTransactions.CURRENCY]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.AMOUNT]: Number(data[KeyTransactions.AMOUNT]),
    },
  } as object;

  if (!data[KeyTransactions.CHECKBOX]) {
    obj = {
      ...obj,
      [KeyTransactions.SENDER]: {
        [KeyTransactions.COMPANY_ID]: data.sender.company_id,
        [KeyTransactions.COMPANY_NAME]: data.sender.company_name,
      },
    };
  }

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

export const formattingParamsForConversionIncoming = (data: initialObjTransactionsType) => {
  const obj = {
    [KeyTransactions.TYPE]: KeyTransactions.CONVERSION,
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.FACTORING_COMPANY_NAME]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_NAME],
    [KeyTransactions.FACTORING_COMPANY_ID]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    amount: {
      [KeyTransactions.CURRENCY_BUY]: getDifferentCurrencyOfCode[data[KeyTransactions.CURRENCY]],
      [KeyTransactions.CURRENCY_SELL]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.RATE_ECB]: data[KeyTransactions.RATE_ECB],
      [KeyTransactions.AMOUNT_BUY]: Number(data[KeyTransactions.AMOUNT_BUY]),
      [KeyTransactions.AMOUNT_SELL]: Number(data[KeyTransactions.AMOUNT_SELL]),
      [KeyTransactions.COMMISSION]: GET_COMMISSION_COEFFICIENT(data.commission),
      [KeyTransactions.RATE]: GET_CONVERSION_RATE(data.rate_ecb, data.commission, data.currency),
    },
  };

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

export const formattingParamsForConversion = (data: initialObjTransactionsType) => {
  const obj = {
    [KeyTransactions.TYPE]: KeyTransactions.CONVERSION,
    [KeyTransactions.COMMENT]: data[KeyTransactions.COMMENT],
    [KeyTransactions.FACTORING_COMPANY_NAME]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_NAME],
    [KeyTransactions.FACTORING_COMPANY_ID]: data[KeyTransactions.RECIPIENT][KeyTransactions.COMPANY_ID],
    [KeyTransactions.VALUE_DATE_TIME]: new Date(data[KeyTransactions.VALUE_DATE_TIME]).toISOString(),
    amount: {
      [KeyTransactions.CURRENCY_BUY]: getDifferentCurrencyOfCode[data[KeyTransactions.CURRENCY]],
      [KeyTransactions.CURRENCY_SELL]: data[KeyTransactions.CURRENCY],
      [KeyTransactions.RATE_ECB]: data[KeyTransactions.RATE_ECB],
      [KeyTransactions.AMOUNT_BUY]: Number(data[KeyTransactions.AMOUNT_BUY]),
      [KeyTransactions.AMOUNT_SELL]: Number(data[KeyTransactions.AMOUNT_SELL]),
      [KeyTransactions.COMMISSION]: GET_COMMISSION_COEFFICIENT(data.commission),
      [KeyTransactions.RATE]: GET_CONVERSION_RATE(data.rate_ecb, data.commission, data.currency),
    },
  };

  return removeEmptyValuesFromObject<bodyCreateTransactionsType>(obj);
};

const getFormattingParamsOfType = {
  [KeyTransactions.FEE]: formattingParamsForFee,
  [KeyTransactions.INCOMING]: formattingParamsForIncoming,
  [KeyTransactions.OUTGOING]: formattingParamsForOutgoing,
  [KeyTransactions.INTERNAL]: formattingParamsForInternal,
  [KeyTransactions.ADJUSTMENT]: formattingParamsForAdjusting,
  [KeyTransactions.CONVERSION_INCOMING]: formattingParamsForConversionIncoming,
  [KeyTransactions.CONVERSION]: formattingParamsForConversion,
};

export const commonFormattingParams = (
  data: initialObjTransactionsType,
  item?: typeInitialAllocate,
  isEdit?: boolean,
) => getFormattingParamsOfType[data[KeyTransactions.TYPE]](data, item, isEdit);

export const getListTransactionType = () => [
  { key: KeyTransactions.INCOMING, value: getTypeTransactions()[0] },
  { key: KeyTransactions.OUTGOING, value: getTypeTransactions()[1] },
  { key: KeyTransactions.INTERNAL, value: getTypeTransactions()[2] },
  { key: KeyTransactions.FEE, value: getTypeTransactions()[3] },
  { key: KeyTransactions.ADJUSTMENT, value: getTypeTransactions()[4] },
  { key: KeyTransactions.CONVERSION, value: getTypeTransactions()[5] },
];

export const allocationIncomingCategory = () => [
  { key: KeyTransactions.INTERNAL_COMMISSION, value: i18next.t('forms.transaction.commission') },
  { key: KeyTransactions.INTERNAL_PRINCIPAL_AMOUNT, value: i18next.t('forms.transaction.principalAmount') },
  { key: KeyTransactions.INTERNAL_DEFAULT_INTEREST, value: i18next.t('forms.transaction.defaultInterest') },
  {
    key: KeyTransactions.INTERNAL_FACTORING_SERVICES_FEE,
    value: i18next.t('forms.transaction.factoringServicesFee'),
  },
];

export const getListTransactionCategory = {
  [KeyTransactions.OUTGOING]: () => [
    { key: KeyTransactions.OUTGOING_PURCHASE_PRICE, value: i18next.t('forms.transaction.purchasePrice') },
    { key: KeyTransactions.OUTGOING_BALANCE_PAYABLE, value: i18next.t('forms.transaction.balancePayable') },
    { key: KeyTransactions.OUTGOING_TRANSIT, value: i18next.t('forms.transaction.transit') },
  ],
  [KeyTransactions.INTERNAL]: () => [
    { key: KeyTransactions.INTERNAL_COMMISSION, value: i18next.t('forms.transaction.commission') },
    { key: KeyTransactions.INTERNAL_PRINCIPAL_AMOUNT, value: i18next.t('forms.transaction.principalAmount') },
    { key: KeyTransactions.INTERNAL_DEFAULT_INTEREST, value: i18next.t('forms.transaction.defaultInterest') },
  ],
  [KeyTransactions.INCOMING]: () => [],
  [KeyTransactions.FEE]: () => [],
  [KeyTransactions.ADJUSTMENT]: () => [],
  [KeyTransactions.CONVERSION]: () => [],
};

export const convertTransactionType = {
  [KeyTransactions.INTEREST_REPAY]: KeyTransactions.INTERNAL_COMMISSION,
  [KeyTransactions.PENALTY_REPAY]: KeyTransactions.INTERNAL_DEFAULT_INTEREST,
  [KeyTransactions.BODY_REPAY]: KeyTransactions.INTERNAL_PRINCIPAL_AMOUNT,
};

export const getTitleCheckbox = {
  [KeyTransactions.INCOMING]: 'forms.transaction.senderIsTheSameAsClient',
  [KeyTransactions.OUTGOING]: 'forms.transaction.recipientIsTheSameAsClient',
};

export const getTitleSender = {
  [KeyTransactions.INCOMING]: 'forms.transaction.sender',
  [KeyTransactions.OUTGOING]: 'forms.transaction.recipient',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button } from '@material-ui/core';
import React, { FC, Children } from 'react';

import SwitchButton from 'components/buttons/SwitchButton';
import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { SelectKit } from 'components/uiKit/SelectKit';
import { CurrencyType } from 'components/uiKit/InputKit';
import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';
import TemplateGrid from 'components/forms/ActivationOrderForm/CardsAo/TemplateGrid';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';

const plusSvg = require('assets/img/plus.svg').default;
const basketSvg = require('assets/img/basket.svg').default;

export enum typeInputEnum {
  DATE = 'date',
  TEXT = 'text',
  SELECT = 'select',
}

interface dataObjType {
  val: string;
  key: KeyCardsEnum;
  type?: typeInputEnum;
  label?: string;
  options?: string[];
  isError?: boolean;
  errorText?: string;
}

interface SwitchCustomSettingsType {
  style?: React.CSSProperties;
  switchText?: string;
  handleChangeSwitch?: () => void;
  isSwitch?: boolean;
  data?: { row: dataObjType[]; isShowButtonDelete?: boolean }[];
  onChange?: (key, value, column) => void;
  typeButton?: 'text' | 'button';
  callback?: () => void;
  deleteCallback?: (i: number) => void;
  itemKey?: KeyCardsEnum;
  getParamsItem: Function;
  withoutSwitch?: boolean;
  styleButton?: React.CSSProperties;
  currency?: CurrencyType;
  componentBetweenInputsAndButton?: React.ReactNode;
}
const SwitchCustomSettings: FC<SwitchCustomSettingsType> = ({
  style,
  handleChangeSwitch,
  switchText,
  isSwitch,
  data,
  onChange = () => {},
  typeButton,
  callback = () => {},
  deleteCallback = () => {},
  itemKey = KeyCardsEnum.ROOT,
  getParamsItem,
  withoutSwitch,
  styleButton,
  currency,
  componentBetweenInputsAndButton,
}) => {
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const { errors } = useErrors();

  return (
    <div style={{ ...style }}>
      {!withoutSwitch && (
        <SwitchButton
          isSwitchNewStyle
          inputSwitch={isSwitch}
          handleChangeSwitch={handleChangeSwitch}
          buttonName={switchText}
          wrapperStyle={{ marginBottom: 17, color: '#838998', fontSize: 20, position: 'relative', left: -6 }}
        />
      )}

      {isSwitch &&
        Array.isArray(data) &&
        Children.toArray(
          data.map(({ row, isShowButtonDelete = true }, column) => (
            <TemplateGrid>
              {Children.toArray(
                row.map(({ val, key, type = typeInputEnum.DATE, label, options = [] }) => {
                  const errorKey = row.length === 1 ? key : `${key}_${column}`;

                  return (
                    <>
                      {type === typeInputEnum.DATE && (
                        <DateInput
                          isNotErrorLocal
                          date={val}
                          labelKey={label}
                          onChangeDateHandler={({ target: { value } }) => {
                            onChange(key, value, column);
                          }}
                          commonStyle={{ height: 60 }}
                          isError={!!errors[errorKey]}
                          errorText={errors[errorKey]}
                          required={getParamsItem(itemKey, key)?.required}
                          stylingLabel={{ paddingLeft: 8 }}
                          inputStyle={{ paddingLeft: 17 }}
                        />
                      )}
                      {type === typeInputEnum.TEXT && (
                        <AmountKit
                          amount={val}
                          label={label}
                          onChange={(e) => {
                            onChange(key, e.target.value, column);
                          }}
                          isError={!!errors[errorKey]}
                          helperText={errors[errorKey]}
                          wrapProps={{ height: 60 }}
                          required={getParamsItem(itemKey, key)?.required}
                          currency={currency}
                        />
                      )}
                      {type === typeInputEnum.SELECT && (
                        <SelectKit
                          label={label}
                          options={options}
                          val={val}
                          changeSelectItem={(_, value) => {
                            onChange(key, value || '', column);
                          }}
                          isError={!!errors[errorKey]}
                          helperText={errors[errorKey]}
                          wrapProps={{ height: 60 }}
                          required={getParamsItem(itemKey, key)?.required}
                        />
                      )}
                      {row.length === 1 && <div />}
                    </>
                  );
                }),
              )}
              {data.length > 1 && row.length > 1 && isShowButtonDelete && (
                <img
                  src={basketSvg}
                  alt="basket"
                  onClick={() => deleteCallback(column)}
                  style={{
                    alignSelf: 'center',
                    position: 'absolute',
                    padding: 10,
                    border: '1px solid #A7AFB7',
                    borderRadius: 8,
                    cursor: 'pointer',
                    right: -61,
                  }}
                />
              )}
            </TemplateGrid>
          )),
        )}
      {componentBetweenInputsAndButton && componentBetweenInputsAndButton}
      {typeButton === 'text' && isSwitch && (
        <button
          onClick={callback}
          type="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            color: '#606176',
            cursor: 'pointer',
            marginBottom: 32,
            ...styleButton,
          }}
        >
          <img src={plusSvg} alt="plus" style={{ marginRight: 8 }} />
          <span style={{ textDecorationLine: 'underline' }}>Add Payment Day</span>
        </button>
      )}
      {typeButton === 'button' && isSwitch && (
        <Button
          onClick={callback}
          {...dropZoneStylingButton}
          style={{ width: 144, marginBottom: 32, ...styleButton }}
          startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
        >
          Add Period
        </Button>
      )}
    </div>
  );
};

export default SwitchCustomSettings;

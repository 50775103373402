import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type processDataType = {
  process_name: string;
  errorCallback: Function;
};

export default ({ process_name, errorCallback }: processDataType) =>
  requestAsync({
    url: endpoints.getProcessStatusUrl(process_name),
    transform: (response) => ({
      [process_name]: response,
    }),
    queryKey: endpoints.getProcessStatusUrl(process_name),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [process_name]: (_prevEntities, newEntities) => newEntities,
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext, useEffect } from 'react';

import useCompany from 'hooks/api/useCompany';
import { PurchaseFilters } from 'consts/tableFilters';
import usePurchaseRequest from 'hooks/api/usePurchaseRequest';
import useActivationOrder from 'hooks/api/useActivationOrder';
import getUrlParams from 'helpers/getUrlParams';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { RefreshContext } from 'contexts/RefreshContext';
import { PurchaseRequestParamsT } from 'queries/purchaseRequest/getPurchaseRequestList';
import ContentWrapper from 'components/ContentWrapper';
import PurchaseRequestTableHeader from 'components/tables/PurchaseRequests/Header/Header';
import PurchaseRequestTable from 'components/tables/PurchaseRequests';
import { updateTitle } from 'helpers/common';

const SEARCH_PARAMS_BY_QUERY = ['company_id', 'page', 'limit', 'order_id', 'request_number', 'currency', 'status'];

const PurchaseRequestListScreen: any = ({ ...props }) => {
  const { requestParams } = props;
  const refreshContext = useContext(RefreshContext);
  const { purchaseRequestParams, getPurchaseRequestListForFilter, getPurchaseRequestList, setPurchaseRequestParams } =
    usePurchaseRequest();
  const { getActivationOrderList } = useActivationOrder();
  const { getCompanies } = useCompany();

  useEffect(() => {
    if (purchaseRequestParams.company_id) {
      getActivationOrderList({ company_id: purchaseRequestParams.company_id });
      getPurchaseRequestListForFilter({ company_id: purchaseRequestParams.company_id });
    }
  }, [purchaseRequestParams.company_id]);

  useEffect(() => {
    getCompanies();
    const newParams = refreshContext.getNewParams({ datakey: 'purchaseRequestParams' });
    setUrlStringParams({
      filters: PurchaseFilters,
      currentScreenParams: { ...purchaseRequestParams, ...newParams, ...requestParams },
    });

    updateTitle('Purchase Requests');
  }, []);

  useEffect(() => {
    const { requestParams: newParams } = getUrlParams(window.location.href, SEARCH_PARAMS_BY_QUERY);
    setPurchaseRequestParams(newParams);
    refreshContext.setParams({
      datakey: 'purchaseRequestParams',
      params: newParams as PurchaseRequestParamsT,
    });
    getPurchaseRequestList({ params: newParams as PurchaseRequestParamsT });

    return () => {
      refreshContext.setParams({
        datakey: 'purchaseRequestParams',
        params: newParams as PurchaseRequestParamsT,
      });
    };
  }, [window.location.search]);

  return (
    <ContentWrapper
      style={{
        padding: '20px 40px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto minmax(100px, 1fr)',
        paddingTop: 26,
        height: '100%',
      }}
    >
      <PurchaseRequestTableHeader requestParams={requestParams} />
      <PurchaseRequestTable requestParams={requestParams} />
    </ContentWrapper>
  );
};

export default PurchaseRequestListScreen;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { CSSProperties, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import Modal from 'helpers/PopUpLayout';
import Button from 'components/uiKit/Button';
import DateInput from 'components/inputs/DateInput';
import { getValueStatus, KeyCardsEnum, listStatusAo } from 'components/forms/ActivationOrderForm/CardsAo/data';
import useCurrentRequiredKeyOfCard from 'components/forms/ActivationOrderForm/CardsAo/useCurrentRequiredKeyOfCard';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';

import styles from './styles.module.scss';

type PropsT = {
  item: KeyCardsEnum;
  setInitialFilter: (
    key: KeyCardsEnum,
    value,
    error?: { keyError; valueError: string; params: { date?: boolean; required?: boolean } },
  ) => void;
  wrapProps: CSSProperties;
};

const Status: FC<PropsT> = (props) => {
  const { t } = useTranslation();
  const { item, setInitialFilter, wrapProps } = props;
  const { getParamsItem } = useCurrentRequiredKeyOfCard();
  const { aoFormData }: useCompanyType = useCompany();
  const { errors } = useErrors();
  const [prevStatus, setPrevStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setInitialFilter(item, prevStatus || '');
    setIsOpen(false);
  };

  return (
    <>
      <DropdownKit
        label={t('forms.activationOrder.aoStatus')}
        options={listStatusAo}
        keyObj="name"
        value={getValueStatus[aoFormData.status]}
        changeSelectItem={(_, data) => {
          if (data.key === 'REPAID') {
            setPrevStatus(aoFormData.status);
            setIsOpen(true);
          }
          setInitialFilter(item, data.key, {
            keyError: item,
            valueError: data.key,
            params: getParamsItem(KeyCardsEnum.ROOT, item),
          });
        }}
        required={getParamsItem(KeyCardsEnum.ROOT, item)?.required}
        isErrors={!!errors[item]}
        helperText={errors[item]}
        wrapStyle={wrapProps}
        positionType={DropdownSizeEnum.SIZE_60}
      />
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.paper}>
          <p className={styles.modalTitle}>{t('forms.activationOrder.closingDateModal.title')}</p>
          <DateInput
            isNotErrorLocal
            date={aoFormData[KeyCardsEnum.CLOSING_DATE]}
            labelKey="forms.activationOrder.closingDateModal.closingDate"
            onChangeDateHandler={({ target: { value } }) => {
              setInitialFilter(KeyCardsEnum.CLOSING_DATE, value, {
                keyError: KeyCardsEnum.CLOSING_DATE,
                valueError: value,
                params: getParamsItem(KeyCardsEnum.ROOT, KeyCardsEnum.CLOSING_DATE),
              });
            }}
            required
            stylingLabel={{ paddingLeft: 8 }}
            inputStyle={{ paddingLeft: 17 }}
            commonStyle={wrapProps}
            isError={!!errors[item]}
            errorText={errors[item]}
          />
          <div className={styles.actionButton}>
            <Button
              disabled={!aoFormData[KeyCardsEnum.CLOSING_DATE]}
              variant="fill"
              style={{ height: 40 }}
              gutters={[0, 20]}
              onClick={() => setIsOpen(false)}
            >
              {t('forms.activationOrder.closingDateModal.save')}
            </Button>
            <Button style={{ height: 40 }} gutters={[0, 20]} onClick={onClose}>
              {t('forms.activationOrder.closingDateModal.cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Status;

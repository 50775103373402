import React, { Children, FC } from 'react';
import { Button, makeStyles } from '@material-ui/core';

import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';

interface ConfirmProps {
  wrap?: React.CSSProperties;
  leftCallback?: () => void;
  rightCallback?: () => void;
  open?: boolean;
  leftButtonText: string;
  rightButtonText: string;
  leftButtonStyle?: React.CSSProperties;
  rightButtonStyle?: React.CSSProperties;
  title: string | string[];
  titleStyle?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ConfirmPopUp: FC<ConfirmProps> = ({
  wrap,
  leftCallback,
  rightCallback,
  open = false,
  leftButtonText,
  rightButtonText,
  leftButtonStyle,
  rightButtonStyle,
  title,
  titleStyle,
}) => {
  const dropZoneStylingButtonPopUp = UseStylingDropZoneButton();
  const stylingButtonPopUp = UseStylingButton();
  const classes = useStyle();

  return (
    open && (
      <div className={classes.confirmWrap} style={wrap}>
        <div className={classes.confirmTitle} style={titleStyle}>
          {Array.isArray(title) ? Children.toArray(title.map((txt) => <div>{txt}</div>)) : <span>{title}</span>}
        </div>
        <div>
          <Button
            onClick={leftCallback}
            style={{ width: 88, height: 60, marginRight: 40, backgroundColor: '#EC3E72', ...leftButtonStyle }}
            {...stylingButtonPopUp}
          >
            {leftButtonText}
          </Button>
          <Button
            onClick={rightCallback}
            style={{ width: 89, height: 60, ...rightButtonStyle }}
            {...dropZoneStylingButtonPopUp}
          >
            {rightButtonText}
          </Button>
        </div>
      </div>
    )
  );
};

const useStyle = makeStyles(() => ({
  confirmWrap: {
    zIndex: 20,
    boxSizing: 'border-box',
    padding: 40,
    boxShadow: '0px 16px 32px rgba(59, 66, 103, 0.08)',
    backgroundColor: '#F7F8FA',
    borderRadius: 8,
  },
  confirmTitle: {
    fontSize: 16,
    lineHeight: '19px',
    marginBottom: 40,
    color: '#29292C',
  },
}));

export default ConfirmPopUp;

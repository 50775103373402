import React, { FC, useEffect, useState } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import { FormLayout, Input, Select } from '@gamesb42/ui-kit';

import { systemSettingsFieldsEnum } from 'components/forms/SystemSettingsForm/SystemSettingsFormData';
import { InputKit } from 'components/uiKit/InputKit';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';

import styles from './styles.module.scss';

type GeneralTabGroupDataType = {
  investor_interest: string;
  grace_period: string;
  customer_contract_template: string;
  auto_debiting: boolean;
};

type GeneralTabPanelPropsType = {
  groupData: GeneralTabGroupDataType;
  setValue: any;
};

type GracePeriodTypes = {
  value: number;
  label: number;
};

const GeneralPage: FC<GeneralTabPanelPropsType> = ({ groupData, setValue }: GeneralTabPanelPropsType) => {
  const [gracePeriodValues, setGracePeriodValues] = useState<GracePeriodTypes[]>([]);

  const { t } = useTranslation();

  const defaultGracePeriodValues = () => {
    const newGracePeriodValues = [];
    for (let i = 2; i <= 26; i += 1) {
      newGracePeriodValues.push({ value: i, label: i });
    }
    setGracePeriodValues(newGracePeriodValues);
  };

  useEffect(() => {
    defaultGracePeriodValues();
  }, []);

  return (
    <TabPanel value="general" className={styles.root}>
      <FormLayout className={styles.row}>
        <Input
          label={t('systemSetting.investorInterest')}
          name={systemSettingsFieldsEnum.investor_interest}
          onChange={setValue}
          value={groupData.investor_interest}
          size="large"
        />
        <Select
          label={t('forms.activationOrder.repaymentPlan')}
          value={`${gracePeriodValues.find((item) => item.value === +groupData.grace_period)?.value}`}
          options={gracePeriodValues}
          onChange={(value) => {
            setValue({ target: { value, name: systemSettingsFieldsEnum.grace_period } });
          }}
          size="large"
          allowClear={false}
        />
      </FormLayout>
      <Input
        label={t('systemSetting.customerContractTemplate')}
        name={systemSettingsFieldsEnum.customer_contract_template}
        onChange={setValue}
        value={groupData.customer_contract_template}
        size="large"
        className={styles.row}
      />
    </TabPanel>
  );
};

export default GeneralPage;

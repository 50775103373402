// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Title from 'components/common/Title';
import Tabs from 'components/common/Tabs';
import useTransaction, { ItemTransactionsType, useTransactionType } from 'hooks/api/useTransaction';
import useActivationOrder, { useActivationOrderType, activationOrdersV2DataType } from 'hooks/api/useActivationOrder';
import { StatusEnumTitle } from 'components/common/Status';

import { TABS, TabsTransactionsType } from './data';
import ActionButton from './ActionButton';
import styles from './styles.module.scss';
import TransactionMenu from './TransactionMenu';

export interface CompanyTypeParams {
  period_from: string;
  platform_ids: string;
  tab: string;
  date_from: string;
  date_to: string;
  stores_ids: string;
  deleted: string;
}
interface ViewType {
  requestParams: CompanyTypeParams;
}

const ViewTransactions: FC<ViewType> = ({ requestParams }) => {
  const [activeItem, setActiveItem] = useState(TabsTransactionsType.Transaction);
  const [isShowButtonAllocated, setIsShowButtonAllocated] = useState(false);
  const [currentAo, setCurrentAo] = useState<activationOrdersV2DataType>();
  const {
    getTransactionsById,
    transactionsDataById,
    getTransactionsRelatedById,
    transactionsRelatedById,
  }: useTransactionType = useTransaction();
  const { getActivationOrderV2, activationOrdersV2Data }: useActivationOrderType = useActivationOrder();
  const aoType =
    (activationOrdersV2Data?.records || []).find(
      (item) =>
        item.id ===
        (transactionsDataById?.document_id || transactionsDataById?.factoring_order_id || '').replaceAll('-', ''),
    )?.ao_type || '';
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getTransactionsById({
      params: requestParams,
      transaction_id: id,
      successCallback: (res: ItemTransactionsType) => {
        getTransactionsRelatedById({
          transaction_id: id,
          successCallback: (relatedResult: ItemTransactionsType[]) => {
            const count = relatedResult.reduce((prev, current) => prev + current.amount.amount, 0);
            getActivationOrderV2({
              params: {
                company_id: res.factoring_company_id,
                limit: 10000,
              },
              successCallback: ({ records = [] }) => {
                const el = records.find((elem) => elem.ao_type === 'classic');

                if (el && Math.abs(res.amount.amount) > Math.abs(count)) {
                  setIsShowButtonAllocated(true);
                }
                const newCurrentAo = records.find((item) => item.id === res.factoring_order_id?.replaceAll('-', ''));

                if (newCurrentAo) setCurrentAo(newCurrentAo);
              },
            });
          },
        });
      },
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Title
        text={t('forms.transaction.transactionDetails')}
        isShowBackButton
        clickCallback={() => {
          history.goBack();
        }}
        status={transactionsDataById.status === StatusEnumTitle.deleted ? StatusEnumTitle.deleted : undefined}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 20,
          marginBottom: 13,
          maxWidth: 894,
        }}
      >
        <Tabs items={TABS()} setItem={(idType: number) => setActiveItem(idType)} activeItem={activeItem} />
        {transactionsDataById.category_dsc !== 'capitalization' && (
          <ActionButton transactionsDataById={transactionsDataById} />
        )}
      </div>
      <main>
        {activeItem === TabsTransactionsType.Transaction && (
          <TransactionMenu
            aoType={aoType}
            currentAo={currentAo}
            transactionsRelatedById={transactionsRelatedById}
            transactionsDataById={transactionsDataById}
            isShowButtonAllocated={isShowButtonAllocated}
          />
        )}
      </main>
    </div>
  );
};

export default ViewTransactions;

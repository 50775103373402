// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext } from 'react';

type RefreshType = {
  func: (data: any) => void;
  params?: object;
};
type RefreshDataType = {
  datakey: string;
  params?: object;
};

type RefreshNewDataType = {
  datakey: string;
  params?: object;
  requestParams?: object;
};

const refresh = {
  refreshFunction: [],
  add({ func, params }: RefreshType): void {
    this.refreshFunction.push({ func, params });
  },
  addFunction(func: () => void): void {
    this.refreshFunction.push({ func });
  },
  set({ func, params }: RefreshType) {
    this.clear();
    this.refreshFunction.push({ func, params });
  },
  refresh(): void {
    this.refreshFunction.map((fun) => (fun.params ? fun.func({ ...fun.params }) : fun.func()));
  },
  clear(): void {
    const arhiveParams = this.refreshFunction.pageSettings;
    this.refreshFunction = [];
    this.refreshFunction.pageSettings = arhiveParams;
  },
  setParams({ datakey, params }: RefreshDataType) {
    const localPageSettings = JSON.parse(window.sessionStorage.getItem('localPageSettings'));

    if (!this.refreshFunction.pageSettings) {
      this.refreshFunction.pageSettings = localPageSettings || {};
    }
    this.refreshFunction.pageSettings[datakey] = params;
    const tosave = { ...this.refreshFunction.pageSettings };
    window.sessionStorage.setItem('localPageSettings', JSON.stringify(tosave));
  },
  getParams(datakey: string) {
    const localPageSettings = JSON.parse(window.sessionStorage.getItem('localPageSettings'));

    if (!this.refreshFunction.pageSettings) {
      this.refreshFunction.pageSettings = localPageSettings || {};
    }

    if (!this.refreshFunction.pageSettings[datakey]) {
      this.refreshFunction.pageSettings[datakey] = {};
    }

    return this.refreshFunction.pageSettings[datakey];
  },
  getNewParams({ datakey, params, requestParams }: RefreshNewDataType) {
    const localPageSettings = JSON.parse(window.sessionStorage.getItem('localPageSettings'));

    if (!this.refreshFunction.pageSettings) {
      this.refreshFunction.pageSettings = localPageSettings || {};
    }

    if (!this.refreshFunction.pageSettings[datakey]) {
      this.refreshFunction.pageSettings[datakey] = {};
    }
    let newParams = {
      ...params,
    };
    newParams = {
      ...newParams,
      ...this.refreshFunction.pageSettings[datakey],
    };

    if (requestParams) {
      newParams = {
        ...newParams,
        ...requestParams,
      };
    }
    this.refreshFunction.pageSettings[datakey] = newParams;
    const tosave = { ...this.refreshFunction.pageSettings };
    window.sessionStorage.setItem('localPageSettings', JSON.stringify(tosave));

    return this.refreshFunction.pageSettings[datakey];
  },
  setByKey({ datakey = 'pageSettings', params }: RefreshDataType) {
    this.refreshFunction[datakey] = params;
  },
  getByKey(datakey = 'pageSettings'): RefreshDataType {
    return this.refreshFunction[datakey];
  },
};

export const RefreshContext = createContext(refresh);

export const RefreshProvider = ({ children }) => (
  <RefreshContext.Provider value={refresh}>{children}</RefreshContext.Provider>
);

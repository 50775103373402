import React, { useMemo, FC } from 'react';
import { Status, StatusProps } from '@gamesb42/ui-kit';

import { CompanyStatus } from 'types/CompanyTypes';

interface Props extends Partial<StatusProps> {
  status: CompanyStatus;
}

const CompanyStatusLabel: FC<Props> = ({ status, ...props }) => {
  const optionsForStatus = useMemo(() => {
    switch (status) {
      case CompanyStatus.NEW:
        return {
          color: 'purple' as const,
          children: status.toLowerCase(),
        };
      case CompanyStatus.IN_REVIEW:
        return {
          color: 'orange' as const,
          children: status.replace('_', '').toLowerCase(),
        };
      case CompanyStatus.ACTIVE:
        return {
          color: 'green' as const,
          children: status.toLowerCase(),
        };
      case CompanyStatus.REJECTED:
      case CompanyStatus.BLOCKED:
      case CompanyStatus.DELETED:
        return {
          color: 'red' as const,
          children: status.toLowerCase(),
        };
      case CompanyStatus.ARCHIVE:
        return {
          color: 'gray' as const,
          children: status.toLowerCase(),
        };

      default:
        return null;
    }
  }, [status]);

  if (optionsForStatus === null) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Status color={optionsForStatus.color} {...props}>
      {optionsForStatus.children}
    </Status>
  );
};

export default CompanyStatusLabel;

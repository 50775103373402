import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { CurrencyType } from 'components/uiKit/InputKit';

export type EditParamsT = {
  id: string;
  available_advance: {
    platform_id: string;
    purchase_price: string;
    currency: CurrencyType | '';
    due_date: string;
    purchased: string;
  }[];
};

export type EditPurchaseRequestT = {
  body?: EditParamsT;
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({
  body,
  errorCallback = () => {},
  successCallback = () => {},
  resultKey = 'purchaseRequestEdit',
}: EditPurchaseRequestT) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.editPurchaseRequestUrl(body.id),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.editPurchaseRequestUrl(body.id),
    body: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      available_advance: body.available_advance,
    },
    options: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

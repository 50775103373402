import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Pagination, ColumnsType, Table } from '@gamesb42/ui-kit';

import useTransaction, {
  AmountTransactionsType,
  ItemTransactionsType,
  useTransactionType,
} from 'hooks/api/useTransaction';
import { formatAmount, formatDate, getCurrencyWithCode } from 'helpers/formatters';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { TransactionTableFilters } from 'consts/tableFilters';
import urls from 'constants/urls';
import { shortToFullNameSort } from 'helpers/mappers';
import { getNextSort } from 'helpers';

import { getSymbolAndColorTransactions } from './data';
import styles from './styles.module.scss';
import TableWrapper from 'components/TableWrapper';

interface TransactionTablePropsType {
  requestParams: {
    sort?: string;
  };
  isChecked: boolean;
}

const TransactionsTable: FC<TransactionTablePropsType> = ({ requestParams, isChecked: isShowOnlyDeleted }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sort } = requestParams;
  const currentSort = sort ? JSON.parse(decodeURI(sort))[0].direction : null;
  const {
    getArchiveTransactions,
    getListTransactions,
    listTransactions,
    listTransactionQuery,
    archiveTransactionsQuery,
  }: useTransactionType = useTransaction();

  const getListOfTransactions = useCallback(
    (params) =>
      isShowOnlyDeleted
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getArchiveTransactions({ resultKey: 'listTransactionData', params })
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getListTransactions({ params }),
    [getArchiveTransactions, getListTransactions, isShowOnlyDeleted],
  );

  const setPaginationParams = useCallback(
    (param: { size?: number; page?: number }) => {
      setUrlStringParams({ filters: TransactionTableFilters, currentScreenParams: { ...requestParams, ...param } });
      getListOfTransactions({ ...requestParams, ...param });
    },
    [getListOfTransactions, requestParams],
  );
  const dateSort = useCallback(
    (param) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sort: oldSort, ...otherParams } = requestParams;
      const newSort = param ? { sort: encodeURI(JSON.stringify([...param])) } : {};
      setUrlStringParams({
        filters: TransactionTableFilters,
        currentScreenParams: { ...otherParams, ...newSort },
      });
      getListOfTransactions({ ...otherParams, ...newSort });
    },
    [getListOfTransactions, requestParams],
  );

  const onChangePagination = (newCurrent: number, newPageSize: number) => {
    setPaginationParams({
      page: newCurrent,
      size: newPageSize,
    });
  };

  const dataList: ItemTransactionsType[] =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    listTransactionQuery.isPending || archiveTransactionsQuery.isPending ? [] : listTransactions.items || [];

  const columns: ColumnsType<ItemTransactionsType> = [
    {
      title: t('tables.date'),
      dataIndex: 'value_datetime',
      width: '140px',
      render: (date: string) => formatDate(date),
      sorter: true,
      sortOrder: currentSort === null ? null : shortToFullNameSort(currentSort),
      onHeaderCell: () => ({
        onClick: () => {
          if (getNextSort({ value: currentSort }) === null) return dateSort(null);
          dateSort([{ property: 'value_datetime', direction: getNextSort({ value: currentSort }) }]);
        },
      }),
    },
    {
      title: t('tables.client'),
      dataIndex: 'factoring_company_name',
    },
    {
      title: t('tables.type'),
      dataIndex: 'type',
      render: (type: string) => type && type.charAt(0).toUpperCase() + type.slice(1),
    },
    {
      title: t('tables.details'),
      dataIndex: 'details',
    },
    {
      title: t('tables.amount'),
      dataIndex: 'amount',
      width: '208px',
      align: 'right',
      render: (amount: AmountTransactionsType, record: ItemTransactionsType) => {
        const { symbol, color } = getSymbolAndColorTransactions(amount?.amount, record.type);

        return (
          <span style={{ color }}>
            {record.type !== 'conversion' &&
              `${symbol} ${getCurrencyWithCode(Number(amount.currency))}${formatAmount(Math.abs(amount?.amount || 0))}`}
            {record.type === 'conversion' && (
              <>
                <span>{`${getCurrencyWithCode(Number(amount.currency_sell))}${formatAmount(
                  amount?.amount_sell || 0,
                )}`}</span>
                <br />
                <span>
                  →{`${getCurrencyWithCode(Number(amount.currency_buy))}${formatAmount(amount?.amount_buy || 0)}`}
                </span>
              </>
            )}
          </span>
        );
      },
    },
  ];

  return (
    <TableWrapper>
      <Table<ItemTransactionsType>
        dataSource={dataList}
        columns={columns}
        rowKey="id"
        rowClassName={classNames(styles.row, isShowOnlyDeleted && styles.rowDeleted)}
        onRow={({ id }) => ({
          onClick: () =>
            history.push({
              pathname: `${urls.getTransactions()}/${id}`,
              state: { prevPath: history.location },
            }),
        })}
      />
      <Pagination
        total={listTransactions?.total || 0}
        current={listTransactions?.page || 0}
        pageSize={listTransactions?.size || 20}
        onChange={onChangePagination}
      />
    </TableWrapper>
  );
};

export default TransactionsTable;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, DownloadIcon, Radio, Table } from '@gamesb42/ui-kit';

import downloadSvg from 'assets/img/download.svg';
import { getToken } from 'helpers/token';
import documentSvg from './assets/doc.svg';
import s from './styles.module.scss';
import { downloadFileOfBlob } from 'helpers/file';
import { getFileStorage } from '../../reportsData';
import endpoints from 'consts/endpoints';
import { changeStatusCompany } from 'api/company';
import { CompanyStatus } from 'types/CompanyTypes';

export function AMLKYC() {
  //@ts-ignore
  const { companyId } = useParams();
  const [filesData, setFilesData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingZip, setLoadingZip] = useState(false);
  const [status, setStatus] = useState('');

  const columns = [
    {
      title: 'Document type',
      dataIndex: 'displayed_doc_type',
    },
    {
      title: 'File',
      render: (_: any, record: any) => {
        const isFileName = record.file?.name;

        function renderColumn() {
          if (isFileName) {
            return (
              <div className={s.fileRow}>
                {isFileName && <img src={documentSvg} alt="icon" />}
                <div
                  onClick={() => {
                    if (isFileName) handleDownloadClick(record.file.id, isFileName);
                  }}
                >
                  {isFileName}
                </div>
              </div>
            );
          } else {
            return <div className={s.fileRowNoData}>-</div>;
          }
        }

        return renderColumn();
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (_: any, record: any) => {
        if (record?.comment?.length > 0) {
          return <div>{record?.comment}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
  ];

  const handleDownloadClick = async (fileId: string, fileNameIn: string) => {
    // console.log({ fileId });
    // getFileStorage(
    //   endpoints.getStorageFileUrl('743d9fe4d92e4be48bd9a617c1bf9827'),
    //   undefined,
    //   undefined,
    //   undefined,
    //   true,
    // );

    let myHeaders = new Headers();
    myHeaders.append('accept', '*/*');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const blob = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/file/${fileId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => response.blob());

    downloadFileOfBlob(blob, 'application/pdf', fileNameIn, true);
  };

  const handleDownloadAllClick = async () => {
    setLoadingZip(true);
    let fileName = 'files.zip';

    let myHeaders = new Headers();
    myHeaders.append('accept', '*/*');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const blob = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/company/${companyId}/zip`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => {
        const xFileName = response.headers.get('x-file-name');

        if (xFileName) {
          fileName = xFileName;
        }

        return response;
      })
      .then((response) => response.blob());
    downloadFileOfBlob(blob, 'typeLocal', fileName, false);
    setLoadingZip(false);
  };

  useEffect(() => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/company/${companyId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => setFilesData(result))
      .catch((error) => console.log('error', error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={s.wrapper}>
      <div style={{ marginBottom: '20px' }}>
        {/* <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Radio.Group value={status}>
          <Radio
            onChange={() => {
              setStatus('verified');
              changeStatusCompany('b0717ed5-a056-44a1-8569-fc1ad15b2530', 'active' as CompanyStatus);
            }}
            value={'verified'}
          >
            Verified
          </Radio>
          <Radio
            onChange={() => {
              setStatus('rejected');
              changeStatusCompany('b0717ed5-a056-44a1-8569-fc1ad15b2530', 'rejected' as CompanyStatus);
            }}
            value={'rejected'}
          >
            Rejected
          </Radio>
        </Radio.Group> */}
        <Button
          style={{ cursor: 'pointer' }}
          onClick={handleDownloadAllClick}
          disabled={loadingZip}
          icon={<DownloadIcon size={24} />}
        >
          Download
        </Button>
      </div>
      <Table className={s.table} columns={columns} dataSource={filesData} loading={loading} />
    </div>
  );
}

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export interface createTransactionInToClaimType {
  resultKey?: string;
  successCallback?: Function;
  errorCallback?: Function;
  body: {
    purchaseRequestClaimId: string;
    amount: string;
    date: string;
  };
}

export default ({
  resultKey = 'createTransactionInToClaimData',
  successCallback,
  errorCallback,
  body,
}: createTransactionInToClaimType) =>
  requestAsync({
    url: endpoints.createTransactionInToClaimUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.createTransactionInToClaimUrl(),
    body,
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type useFilterDateValidateType = {
  name: string;
  value: string;
  filterParams: any;
  errors?: { date_from?: { status: boolean; text: string }; date_to?: { status: boolean; text: string } };
  min?: string;
  max?: string;
  isNotRequired?: boolean;
};

const useFilterDateValidate = () => {
  const { t } = useTranslation();

  const dateValidator = useCallback(
    ({
      name,
      value,
      filterParams,
      errors,
      min = '1900-01-01',
      max = '2099-12-31',
      isNotRequired,
    }: useFilterDateValidateType) => {
      let validate = true;
      let localErrors;
      const firstDateErrorStatus = !value;
      let firstDateErrorText = !value ? t('auth.required') : '';
      const secondDateFieldName = name === 'date_from' ? 'date_to' : 'date_from';
      const secondDateErrorStatus = !filterParams[secondDateFieldName];
      const secondDateErrorText = !filterParams[secondDateFieldName] ? t('auth.required') : '';

      if (isNotRequired && secondDateErrorStatus) {
        return {
          validatedStatus: true,
          validatedErrors: errors,
        };
      }

      if (value && !secondDateErrorStatus) {
        let dateFrom;
        let dateTo;

        if (name === 'date_from') {
          dateFrom = new Date(value);
          dateTo = new Date(filterParams.date_to);
        } else {
          dateFrom = new Date(filterParams.date_from);
          dateTo = new Date(value);
        }
        const minDate = new Date(min);
        const maxDate = new Date(max);

        if (value && (dateFrom < minDate || dateFrom > maxDate)) {
          localErrors = {
            ...errors,
            date_from: { status: true, text: errors?.date_from?.text },
          };
          validate = false;
        }

        if (value && (dateTo < minDate || dateTo > maxDate)) {
          localErrors = {
            ...errors,
            date_to: { status: true, text: errors?.date_to?.text },
          };
          validate = false;
        }

        if (!validate) {
          return { validatedStatus: validate, validatedErrors: localErrors };
        }

        if (dateTo < dateFrom) {
          firstDateErrorText = name === 'date_from' ? t('filters.dateErrors.dateFrom') : t('filters.dateErrors.dateTo');
          localErrors = {
            ...errors,
            [name]: { status: true, text: firstDateErrorText },
            [secondDateFieldName]: { status: secondDateErrorStatus, text: secondDateErrorText },
          };
          validate = false;
        } else {
          localErrors = {
            ...errors,
            [name]: { status: firstDateErrorStatus, text: firstDateErrorText },
            [secondDateFieldName]: { status: secondDateErrorStatus, text: secondDateErrorText },
          };
          validate = true;
        }
      } else {
        validate = false;
        localErrors = {
          ...errors,
          [name]: { status: firstDateErrorStatus, text: firstDateErrorText },
          [secondDateFieldName]: { status: secondDateErrorStatus, text: secondDateErrorText },
        };
      }

      return { validatedStatus: validate, validatedErrors: localErrors };
    },
    [t],
  );

  const dateValidationByRange = useCallback((value, min = '1900-01-01', max = '2099-12-31') => {
    let validate = true;

    if (value) {
      const date = new Date(value);
      const minDate = new Date(min);
      const maxDate = new Date(max);

      if (date < minDate || date > maxDate) {
        validate = false;
      }
    } else {
      validate = false;
    }

    return validate;
  }, []);

  return { dateValidator, dateValidationByRange };
};

export default useFilterDateValidate;

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ArrowParamsEnum } from 'hooks/UseParamsOfRefElement';

const arrowSvg = require('assets/img/right.svg').default;
const topSvg = require('assets/img/top.svg').default;
const leftSvg = require('assets/img/left.svg').default;
const bottomSvg = require('assets/img/bottom.svg').default;

interface arrowScopeType {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
}

interface ArrowScopePropsType {
  arrowList?: arrowScopeType;
  styleLeft?: React.CSSProperties;
  styleTop?: React.CSSProperties;
  onChangePositionArrow: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, arrow: ArrowParamsEnum) => void;
}

const ArrowScope: FC<ArrowScopePropsType> = ({ arrowList, styleLeft, onChangePositionArrow, styleTop }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const classes = useStyles({ bottom: arrowList.bottom, right: arrowList.right, left: arrowList.left });

  return (
    <>
      {arrowList?.top && (
        <div
          style={styleTop}
          className={classes.topArrow}
          onMouseDown={(e) => onChangePositionArrow(e, ArrowParamsEnum.TOP)}
          onMouseUp={(e) => onChangePositionArrow(e, ArrowParamsEnum.TOP)}
        >
          <img src={topSvg} alt={ArrowParamsEnum.TOP} />
        </div>
      )}
      {arrowList?.right && (
        <div
          className={classes.rightArrow}
          onMouseDown={(e) => onChangePositionArrow(e, ArrowParamsEnum.RIGHT)}
          onMouseUp={(e) => onChangePositionArrow(e, ArrowParamsEnum.RIGHT)}
        >
          <img src={arrowSvg} alt={ArrowParamsEnum.RIGHT} />
        </div>
      )}
      {arrowList?.bottom && (
        <div
          className={classes.bottomArrow}
          onMouseDown={(e) => onChangePositionArrow(e, ArrowParamsEnum.BOTTOM)}
          onMouseUp={(e) => onChangePositionArrow(e, ArrowParamsEnum.BOTTOM)}
        >
          <img src={bottomSvg} alt={ArrowParamsEnum.BOTTOM} />
        </div>
      )}
      {arrowList?.left && (
        <div
          style={styleLeft}
          className={classes.leftArrow}
          onMouseDown={(e) => onChangePositionArrow(e, ArrowParamsEnum.LEFT)}
          onMouseUp={(e) => onChangePositionArrow(e, ArrowParamsEnum.LEFT)}
        >
          <img src={leftSvg} alt={ArrowParamsEnum.LEFT} />
        </div>
      )}
    </>
  );
};
const useStyles = makeStyles<{}, arrowScopeType>(() => ({
  '@keyframes image': {
    '0%': {
      opacity: 0.9,
      transform: 'scale(1)',
    },
    '50%': {
      opacity: 1,
      transform: 'scale(0.9)',
    },
    '100%': {
      opacity: 0.8,
      transform: 'scale(1.1)',
    },
  },
  wrapArrow: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  bottomArrow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    zIndex: 990,
    position: 'absolute',
    right: 0,
    bottom: ({ left, right }) => (!left && !right ? 0 : 10),
    background: 'linear-gradient(0deg, #FFFFFF 33.32%, rgba(255, 255, 255, 0) 100%)',
    width: '100%',
    height: 40,
    '&>img': {
      width: 13,
      height: 14,
      marginBottom: 5,
      animation: `$image 2000ms infinite`,
    },
  },
  topArrow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 990,
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'linear-gradient(180deg, #FFFFFF 33.32%, rgba(255, 255, 255, 0) 100%)',
    width: 'calc(100% - 10px)',
    height: 40,
    '&>img': {
      position: 'relative',
      left: 5,
      width: 13,
      height: 14,
      marginTop: 5,
      animation: `$image 2000ms infinite`,
    },
  },
  rightArrow: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    zIndex: 990,
    position: 'absolute',
    right: ({ bottom }) => (bottom ? 10 : 0),
    top: 0,
    background: 'linear-gradient(270deg, #FFFFFF 33.32%, rgba(255, 255, 255, 0) 100%)',
    width: 50,
    height: '100%',
    '&>img': {
      width: 13,
      height: 14,
      marginRight: 5,
      animation: `$image 2000ms infinite`,
    },
  },
  leftArrow: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    zIndex: 990,
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'linear-gradient(90deg, #FFFFFF 33.32%, rgba(255, 255, 255, 0) 100%)',
    width: 50,
    height: 'calc(100% - 10px)',
    '&>img': {
      marginLeft: 5,
      animation: `$image 2000ms infinite`,
      position: 'relative',
      top: 10,
    },
  },
}));

export default ArrowScope;

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import endpoints, {
  getMonthlyInvoiceRecreateType,
  getMonthlyInvoiceReportCreateParamsType,
  getMonthlyReportRecreateParamsType,
} from 'consts/endpoints';
import { downloadFileOfBlob } from 'helpers/file';
import { getToken } from 'helpers/token';
import { metaReportsEnum, monthlyInvoiceReportsDataType } from 'hooks/api/useMonthlyReport';

interface reportsDataType {
  id: number;
  title: string;
  dataKey: string;
  headAlign?: AlignType;
  bodyAlign?: AlignType;
  fixedCell?: boolean;
  isAmount?: boolean;
  func?: (data: FuncType) => void;
  titleButton?: string;
}

interface FuncType {
  currency: string;
  id?: string;
  companyId?: string;
  params?: getMonthlyReportRecreateParamsType;
  owner_id?: string;
  type?: metaReportsEnum;
  setMonthlyInvoiceReports?: React.Dispatch<React.SetStateAction<monthlyInvoiceReportsDataType>>;
  getMonthlyInvoiceReports?: Function;
  setVisibleButton?: React.Dispatch<React.SetStateAction<string>>;
  visibleButton?: string;
  showAlert?: Function;
  showErrorAlert?: Function;
  year?: number;
  errorCallback?: Function;
}

interface reportsDataTypeButton {
  id?: number;
  title?: string;
  dataKey?: string;
  headAlign?: AlignType;
  bodyAlign?: AlignType;
  fixedCell?: boolean;
  isAmount?: boolean;
  func?: (data: FuncTypeButton) => void;
  titleButton?: string;
}

interface FuncTypeButton {
  companyId?: string;
  setMonthlyInvoiceReports?: React.Dispatch<React.SetStateAction<monthlyInvoiceReportsDataType>>;
  getMonthlyInvoiceReports?: Function;
  setVisibleButton?: React.Dispatch<React.SetStateAction<string>>;
  visibleButton?: string;
  showAlert?: Function;
  showErrorAlert?: Function;
  year?: number;
  errorCallback?: Function;
}

export type AlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export enum reportsKeyEnum {
  NAME = 'name',
  TYPE = 'type',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  LINK = 'link',
  RECALC = 'recalc',
  DELETE = 'delete',
  MONTHLY_REPORT = 'monthly_report',
  INVOICE_REPORT = 'invoice_report',
  MONTHLY_REPORT_CURRENT = 'monthly_report_current',
  INVOICE_REPORT_CURRENT = 'invoice_report_current',
}

const common = { headAlign: 'left', bodyAlign: 'left' } as {
  headAlign: AlignType;
  bodyAlign: AlignType;
};

export const reportsData: reportsDataType[] = [
  {
    ...common,
    id: 1,
    title: 'reports.name',
    dataKey: reportsKeyEnum.NAME,
    fixedCell: false,
  },
  {
    ...common,
    id: 2,
    title: 'reports.type',
    dataKey: reportsKeyEnum.TYPE,
  },
  {
    ...common,
    id: 3,
    title: 'reports.start',
    dataKey: reportsKeyEnum.START_DATE,
  },
  {
    ...common,
    id: 4,
    title: 'reports.end',
    dataKey: reportsKeyEnum.END_DATE,
  },
  {
    ...common,
    id: 5,
    title: 'reports.link',
    dataKey: reportsKeyEnum.LINK,
    func: ({ id }: FuncType) => {
      getFileStorage(endpoints.getStorageFileUrl(id));
    },
    titleButton: 'reports.button',
  },
  {
    ...common,
    id: 6,
    title: '',
    func: async ({
      companyId,
      params,
      type,
      id,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      owner_id,
      currency,
      setVisibleButton,
      visibleButton,
      showAlert,
      showErrorAlert,
      year,
      errorCallback,
    }: FuncType) => {
      if (!visibleButton) {
        setVisibleButton(id);
      }
      const curr = new Date().getTime();

      if (type === 'monthly_report') {
        const res = await getMonthlyReportRecreate(companyId, params);

        if ((res && res.file_id) || (res && res.files)) {
          setTimeout(
            () => {
              getMonthlyInvoiceReports({
                id: companyId,
                params: { year },
                successCallback: (newres) => {
                  setMonthlyInvoiceReports(newres);
                },
                errorCallback,
              });
              setVisibleButton(null);
              showAlert('reports.updated');
            },
            new Date().getTime() - curr > 2000 ? 100 : 2000,
          );
        } else {
          const message = (res && res.description) || 'Unknown Error';
          setVisibleButton(null);
          showErrorAlert(message);

          return;
        }

        return;
      }

      if (type === 'invoice_report_usd' || type === 'invoice_report' || type === 'invoice_report_eur') {
        const documentParams = companyId !== owner_id ? { document_id: owner_id } : { company_id: owner_id, currency };
        const resInv = await getMonthlyInvoiceRecreate({ on_date: params.report_date, ...documentParams });

        if (
          (resInv && resInv.reports && resInv.reports.length !== 0) ||
          (resInv && resInv.files && resInv.files.length > 0)
        ) {
          setTimeout(
            () => {
              getMonthlyInvoiceReports({
                id: companyId,
                params: { year },
                successCallback: (newres) => {
                  setMonthlyInvoiceReports(newres);
                },
                errorCallback,
              });
              setVisibleButton(null);
              showAlert('reports.updated');
            },
            new Date().getTime() - curr > 2000 ? 100 : 2000,
          );
        } else {
          const message = (resInv && resInv.description) || 'Unknown Error';
          setVisibleButton(null);
          showErrorAlert(message);
        }
      }
    },
    dataKey: reportsKeyEnum.RECALC,
    titleButton: 'reports.regenerate',
  },
  {
    ...common,
    id: 7,
    title: '',
    func: async ({
      companyId,
      id,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
      errorCallback,
    }: FuncType) => {
      if (!visibleButton) {
        setVisibleButton(id);
      }
      const curr = new Date().getTime();
      const res = await getMonthlyInvoiceReportDelete(companyId, id);

      if ((res && res.file_id) || (res && res.files) || res) {
        setTimeout(
          () => {
            getMonthlyInvoiceReports({
              id: companyId,
              params: { year },
              successCallback: (newres) => {
                setMonthlyInvoiceReports(newres);
              },
              errorCallback,
            });
            setVisibleButton(null);
            showAlert('reports.deleted');
          },
          new Date().getTime() - curr > 2000 ? 100 : 2000,
        );
      }
    },
    dataKey: reportsKeyEnum.DELETE,
    titleButton: 'reports.delete',
  },
];

export const reportsDataButtons: reportsDataTypeButton[] = [
  {
    ...common,
    id: 1,
    title: '',
    func: async ({
      companyId,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
      errorCallback,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton('monthly_report');
      }
      const curr = new Date().getTime();
      const res = await getMonthlyInvoiceReportCreate(companyId, { report_type: 'monthly_report' });

      if ((res && res.file_id) || (res && res.files) || res) {
        setTimeout(
          () => {
            getMonthlyInvoiceReports({
              id: companyId,
              params: { year },
              successCallback: (newres) => {
                setMonthlyInvoiceReports(newres);
              },
              errorCallback,
            });
            setVisibleButton(null);
            showAlert('reports.created');
          },
          new Date().getTime() - curr > 2000 ? 100 : 2000,
        );
      }
    },
    dataKey: reportsKeyEnum.MONTHLY_REPORT,
    titleButton: 'reports.createMonthlyReport',
  },
  {
    ...common,
    id: 2,
    title: '',
    func: async ({
      companyId,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
      errorCallback,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton('invoice_report');
      }
      const curr = new Date().getTime();
      const res = await getMonthlyInvoiceReportCreate(companyId, { report_type: 'invoice_report' });

      if ((res && res.file_id) || (res && res.files) || res) {
        setTimeout(
          () => {
            getMonthlyInvoiceReports({
              id: companyId,
              params: { year },
              successCallback: (newres) => {
                setMonthlyInvoiceReports(newres);
              },
              errorCallback,
            });
            setVisibleButton(null);
            showAlert('reports.created');
          },
          new Date().getTime() - curr > 2000 ? 100 : 2000,
        );
      }
    },
    dataKey: reportsKeyEnum.INVOICE_REPORT,
    titleButton: 'reports.createInvoice',
  },
  {
    ...common,
    id: 3,
    title: '',
    func: async ({
      companyId,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton(reportsKeyEnum.MONTHLY_REPORT_CURRENT);
      }
      const curr = new Date().getTime();
      const res = await getMonthlyInvoiceReportCreate(companyId, {
        report_type: 'monthly_report',
        current_month: true,
      });

      if ((res && res.file_id) || (res && res.files) || res) {
        setTimeout(
          () => {
            getMonthlyInvoiceReports({
              id: companyId,
              params: { year },
              successCallback: (newres) => {
                setMonthlyInvoiceReports(newres);
              },
            });
            setVisibleButton(null);
            showAlert('reports.created');
          },
          new Date().getTime() - curr > 2000 ? 100 : 2000,
        );
      }
    },
    dataKey: reportsKeyEnum.MONTHLY_REPORT_CURRENT,
    titleButton: 'reports.createMonthlyCurrent',
  },
  {
    ...common,
    id: 4,
    title: '',
    func: async ({
      companyId,
      setMonthlyInvoiceReports,
      getMonthlyInvoiceReports,
      setVisibleButton,
      visibleButton,
      showAlert,
      year,
    }: FuncTypeButton) => {
      if (!visibleButton) {
        setVisibleButton(reportsKeyEnum.INVOICE_REPORT_CURRENT);
      }
      const curr = new Date().getTime();
      const res = await getMonthlyInvoiceReportCreate(companyId, {
        report_type: 'invoice_report',
        current_month: true,
      });

      if ((res && res.file_id) || (res && res.files) || res) {
        setTimeout(
          () => {
            getMonthlyInvoiceReports({
              id: companyId,
              params: { year },
              successCallback: (newres) => {
                setMonthlyInvoiceReports(newres);
              },
            });
            setVisibleButton(null);
            showAlert('reports.created');
          },
          new Date().getTime() - curr > 2000 ? 100 : 2000,
        );
      }
    },
    dataKey: reportsKeyEnum.INVOICE_REPORT_CURRENT,
    titleButton: 'reports.createInvoiceCurrent',
  },
];
const nameLocal = 'file';
const typeLocal = 'application/pdf';

export const getMonthlyReportRecreate = async (companyId, params: getMonthlyReportRecreateParamsType) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyReportRecreateUrl({ companyId, params }), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getMonthlyInvoiceRecreate = async (data: getMonthlyInvoiceRecreateType) => {
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceRecreateUrl(data), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ reports?: string[]; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getFileStorage = async (
  url: string,
  fileName?: string,
  type = typeLocal,
  initRequest?: { method?: string },
  openFile?: boolean,
) => {
  let name = fileName || nameLocal;
  const userToken = getToken();
  try {
    const blob = await fetch(url, { headers: { Authorization: `Bearer ${userToken}` }, ...initRequest })
      .then((res) => {
        const xFileName = res.headers.get('x-file-name');

        if (xFileName && !fileName) {
          name = xFileName;
        }

        return res;
      })
      .then((r) => r.blob());
    downloadFileOfBlob(blob, type, name, openFile);

    return true;
  } catch (error) {
    return false;
  }
};

export const getMonthlyInvoiceReportDelete = async (companyId, id) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceReportDeleteUrl({ companyId, id }), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

export const getMonthlyInvoiceReportCreate = async (companyId, params: getMonthlyInvoiceReportCreateParamsType) => {
  if (!companyId) return false;
  try {
    const res = await fetch(endpoints.getMonthlyInvoiceReportCreateUrl({ companyId, params }), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<{ file_id?: string; files?: string[]; description?: string }>;
  } catch (error) {
    return false;
  }
};

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Button, PageHeader, Modal, notification } from '@gamesb42/ui-kit';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';
import { getFactorCompany, editCompany } from 'api/company';
import { Company, TabEnum, CompanyKeyEnum } from 'types/CompanyTypes';
import { onFinishFailed } from 'components/CompanyForm/utils';
import General from 'components/CompanyForm/General';
import Contact from 'components/CompanyForm/Contact';
import BankAccounts from 'components/CompanyForm/BankAccounts';
import styles from 'components/CompanyForm/styles.module.scss';

import InternalAccounts from './InternalAccounts';

const FactorDetails = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [tabId, setTabId] = useState<TabEnum>(TabEnum.GENERAL);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<Partial<Company>>({
    [CompanyKeyEnum.CONTACTS]: [
      {
        [CompanyKeyEnum.FULL_NAME]: '',
        [CompanyKeyEnum.POSITION]: '',
        [CompanyKeyEnum.EMAIL]: '',
        [CompanyKeyEnum.CONTACT_PHONE]: '',
      },
    ],
  });
  const [factorCompany, setFactorCompany] = useState<Partial<Company>>();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const onFinish = (values: Company) => {
    setLoading(true);

    editCompany({
      ...values,
      id: (factorCompany as Company).id,
    })
      .then(() => {
        notification.success({
          message: 'Factor Details successfully edited',
        });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const tabItems = useMemo(
    () => [
      {
        label: TabEnum.GENERAL,
        key: TabEnum.GENERAL,
        children: <General isClientType isFactor />,
        forceRender: true,
      },
      {
        label: TabEnum.REPRESENTATIVE,
        key: TabEnum.REPRESENTATIVE,
        children: <Contact />,
        forceRender: true,
      },
      {
        label: TabEnum.BANK_ACCOUNT,
        key: TabEnum.BANK_ACCOUNT,
        children: <BankAccounts />,
        forceRender: true,
      },
      {
        label: TabEnum.INTERNAL_ACCOUNTS,
        key: TabEnum.INTERNAL_ACCOUNTS,
        children: <InternalAccounts factorCompanyId={factorCompany?.[CompanyKeyEnum.ID] as string} />,
      },
    ],
    [factorCompany, loadingData],
  );

  useEffect(() => {
    getFactorCompany()
      .then((result) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        result[CompanyKeyEnum.BANK_DETAILS] = result[CompanyKeyEnum.BANK_DETAILS]?.map((accounts) => ({
          ...accounts,
          ...(accounts[CompanyKeyEnum.MASTER] === true && { [CompanyKeyEnum.MASTER]: 'true' }),
        }));

        setFactorCompany(result);
        setInitialValues(result);
        form.resetFields();
        setLoadingData(false);
      })
      .catch(console.error);
  }, [history, form]);

  useEffect(() => {
    updateTitle(t('title.factorDetails'));
  }, [t]);

  return (
    <MainContent type="form" flexColumn>
      <PageHeader title={t('title.factorDetails')} />
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={(error) =>
          onFinishFailed({ form, currentTabId: tabId, isFactor: true, setInitialValues, setTabId, error })
        }
        initialValues={initialValues}
        disabled={loading || loadingData}
        className={styles.form}
      >
        <Tabs
          items={tabItems}
          activeKey={tabId}
          onChange={(key: string) => setTabId(key as TabEnum)}
          className={styles.tabs}
        />
        {tabId !== TabEnum.INTERNAL_ACCOUNTS && (
          <div className={styles.actions}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t('global.saveS')}
            </Button>
            <Button
              onClick={() =>
                Modal.warning({
                  title: 'Leave the Form',
                  content: 'The Factor details was not edited. Are you sure you want to leave the form?',
                  onOk: () => history.push('/'),
                })
              }
            >
              {t('global.cancelC')}
            </Button>
          </div>
        )}
      </Form>
    </MainContent>
  );
};

export default FactorDetails;

import React, { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Button, PageHeader, notification } from '@gamesb42/ui-kit';
import { Form } from 'antd';

import urls from 'constants/urls';
import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { Company, CompanyKeyEnum, TabEnum, CompanyStatus } from 'types/CompanyTypes';
import { getFrontendCompany } from 'helpers/mappers/company';
import General from 'components/CompanyForm/General';
import Contact from 'components/CompanyForm/Contact';
import BankAccounts from 'components/CompanyForm/BankAccounts';
import { onFinishFailed } from 'components/CompanyForm/utils';
import styles from 'components/CompanyForm/styles.module.scss';
import { createCompany, editCompany } from 'api/company';

import CloseModal from './CloseModal';
import { CompanyType } from './data';

const CompanyForm: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { companyId } = useParams<{ companyId?: string }>();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<Partial<Company>>({
    [CompanyKeyEnum.CONTACTS]: [
      {
        [CompanyKeyEnum.FULL_NAME]: '',
        [CompanyKeyEnum.POSITION]: '',
        [CompanyKeyEnum.EMAIL]: '',
        [CompanyKeyEnum.CONTACT_PHONE]: '',
      },
    ],
  });
  const [isClientType, setIsClientType] = useState(false);
  const [tabId, setTabId] = useState<TabEnum>(TabEnum.GENERAL);
  const [isOpenCloseModal, setIsOpenCloseModal] = useState(false);
  const [companyData, setCompanyData] = useState<Partial<Company>>();

  const onFinish = (values: Company) => {
    const isCreateCompany = !companyId;
    setLoading(true);

    const api = isCreateCompany ? createCompany : editCompany;
    const body = { ...values, ...(isCreateCompany ? { status: CompanyStatus.ACTIVE } : { id: companyId }) } as Company;

    api(body)
      .then((newValues) => {
        notification.success({
          message: `Company ${newValues.name} successfully ${companyId ? 'edited' : 'created'}`,
        });
        setLoading(false);
        history.push(`${urls.getCompany()}/${newValues.id}`);
      })
      .catch(() => setLoading(false));
  };

  const title = companyId ? `Edit ${companyData?.name || 'Unknown name company'}` : t('company.createNewCompany');

  const onValuesChange = (updateValue: { [CompanyKeyEnum.TYPE]?: CompanyType }) => {
    if (CompanyKeyEnum.TYPE in updateValue) {
      setIsClientType(updateValue[CompanyKeyEnum.TYPE] === CompanyType.client);
    }
  };

  const tabItems = useMemo(() => {
    let tabs = [
      {
        label: TabEnum.GENERAL,
        key: TabEnum.GENERAL,
        children: <General isClientType={isClientType} />,
        forceRender: true,
      },
      {
        label: TabEnum.CONTACT,
        key: TabEnum.CONTACT,
        children: <Contact />,
        forceRender: true,
      },
      {
        label: TabEnum.BANK_ACCOUNT,
        key: TabEnum.BANK_ACCOUNT,
        children: <BankAccounts />,
        forceRender: true,
      },
    ];

    if (!isClientType) {
      tabs = tabs.filter((tab) => ![TabEnum.CONTACT].includes(tab.key));
    }

    return tabs;
  }, [isClientType]);

  useEffect(() => {
    if (!companyId) {
      setLoadingData(false);
    }

    if (companyId) {
      baseRequest({ path: endpoints.getCompanyUrlV1(companyId) })
        .then((result) => {
          const newCompanyData = getFrontendCompany(result);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newCompanyData[CompanyKeyEnum.BANK_DETAILS] = newCompanyData[CompanyKeyEnum.BANK_DETAILS]?.map(
            (accounts) => ({
              ...accounts,
              ...(accounts[CompanyKeyEnum.MASTER] === true && { [CompanyKeyEnum.MASTER]: 'true' }),
            }),
          );
          setCompanyData(newCompanyData);
          setInitialValues(newCompanyData);
          form.resetFields();
          setIsClientType(newCompanyData.type === 'client');
          setLoadingData(false);
        })
        .catch(() => history.goBack());
    }
  }, [companyId, history, form]);

  return (
    <>
      <PageHeader title={title} />
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        onFinishFailed={(error) => onFinishFailed({ form, currentTabId: tabId, setInitialValues, setTabId, error })}
        initialValues={initialValues}
        disabled={loading || loadingData}
        className={styles.form}
      >
        <Tabs
          items={tabItems}
          activeKey={tabId}
          onChange={(key: string) => setTabId(key as TabEnum)}
          className={styles.tabs}
        />
        <div className={styles.actions}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('global.saveS')}
          </Button>
          <Button onClick={() => setIsOpenCloseModal(true)}>{t('global.cancelC')}</Button>
        </div>
      </Form>
      <CloseModal
        open={isOpenCloseModal}
        onClose={() => setIsOpenCloseModal(false)}
        onLeave={() =>
          companyId ? history.push(`${urls.getCompany()}/${companyId}`) : history.push(urls.getCompany())
        }
      />
    </>
  );
};

export default CompanyForm;

/*
 * Function which transforms object in string with url query params
 *
 * @param {object} body - Object with params, which need to be transformed
 *
 * @returns {string} - String with query params
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default (body): string => {
  if (typeof body !== 'object') {
    return '';
  }

  return Object.keys(body).reduce((accumulator, item) => {
    if (body[item] === undefined || body[item] === '' || `${body[item]}` === '[object Object]') {
      return accumulator;
    }

    return `${accumulator}${accumulator === '' ? '?' : '&'}${item}=${body[item]}`;
  }, '');
};

import React, { FC, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, FilledInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

type PasswordInputType = {
  error?: boolean;
  password: string;
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  deleted?: boolean;
  onBlurHandler?: Function;
  toolTip?: string;
  required?: boolean;
};

const PasswordInput: FC<PasswordInputType> = ({
  error,
  password,
  handlePasswordChange,
  deleted,
  onBlurHandler,
  toolTip = '',
  required = true,
}: PasswordInputType) => {
  const useStyles = makeStyles(() => ({
    formControl: {
      height: '48px',
      marginBottom: '35px',
    },
    label: {
      paddingLeft: 12,
      top: password ? 8 : -3,
    },
    input: {
      width: '500px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      backgroundColor: '#eef2f7 !important',
      '&:before': {
        borderColor: 'white !important',
      },
      '& input:focus + label': {
        top: 8,
      },
    },
  }));

  const styles = useStyles();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl error={error} className={styles.formControl} disabled={deleted}>
      <FilledInput
        id="standard-adornment-password"
        autoComplete="off"
        type={showPassword ? 'text' : 'password'}
        value={password}
        required={required}
        data-tip={toolTip}
        name="password"
        onChange={handlePasswordChange}
        onBlur={onBlurHandler ? () => onBlurHandler('password', password) : () => {}}
        className={styles.input}
        endAdornment={
          // eslint-disable-next-line
          <>
            <InputLabel htmlFor="standard-adornment-password" className={styles.label}>
              {required ? `${t('auth.input.password')} *` : t('auth.input.password')}
            </InputLabel>
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          </>
        }
      />
      <FormHelperText id="component-error-text" style={{ visibility: !error ? 'hidden' : 'visible' }}>
        {t('auth.required')}
      </FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;

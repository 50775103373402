import React, { Children, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { tableStyleAo } from 'theme/styles';
import { formatDate, getCurrency } from 'helpers/formatters';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import useCompany, { listPdsAccountType, useCompanyType } from 'hooks/api/useCompany';

import { CompanyTypeParams } from '../index';

import styles from './styles.module.scss';
import HeaderDailyRevenue from './headerDailyRevenue';

export const listCurrency = ['EUR', 'USD'];
interface PropsT {
  listPdsAccounts: listPdsAccountType;
  requestParams: CompanyTypeParams;
}
const DailyRevenue: FC<PropsT> = ({ listPdsAccounts, requestParams }) => {
  const { t } = useTranslation();
  const classes = tableStyleAo();
  const { listPdsRevenueByDays }: useCompanyType = useCompany();
  const [currency, setCurrency] = useState(listCurrency[0]);

  return (
    <div className={styles.wrapper}>
      <HeaderDailyRevenue
        requestParams={requestParams}
        listPdsAccounts={listPdsAccounts}
        currency={currency}
        setCurrency={setCurrency}
      />
      <TableWrapWithPagination tableContainerStyle={{ backgroundColor: 'inherit' }} isShowPagination={false}>
        <TableHead style={{ height: 64, fontWeight: 500 }} classes={{ root: classes.documentHeaderRowRoot }}>
          <TableRow className={styles.headRow}>
            <TableCell
              style={{ width: 120, boxSizing: 'border-box' }}
              align="left"
              classes={{ root: classes.documentHederRoot }}
            >
              {t('forms.companies.agreements.date')}
            </TableCell>
            <TableCell
              style={{ width: 180, boxSizing: 'border-box' }}
              align="right"
              classes={{ root: classes.documentHederRoot }}
            >
              {t('forms.companies.agreements.totalAmount')}
            </TableCell>
            <TableCell classes={{ root: classes.documentHederRoot }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {Children.toArray(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            listPdsRevenueByDays.proceeds &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              listPdsRevenueByDays.proceeds.map((item) => (
                <TableRow style={{ height: 56 }} className={styles.tableRowStyle}>
                  <TableCell style={{ width: 120, boxSizing: 'border-box' }} align="left">
                    {formatDate(item.date)}
                  </TableCell>
                  <TableCell style={{ width: 180, boxSizing: 'border-box' }} align="right">
                    {`${getCurrency(currency)}${item.sum}`}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )),
          )}
        </TableBody>
      </TableWrapWithPagination>
    </div>
  );
};

export default DailyRevenue;

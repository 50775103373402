// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { conversionTransactionErrorType, conversionTransactionFormDataType } from 'types/TransactionFormTypes';
import { lastDayOfPrevMonth } from 'helpers/dt';

import { formatAo, formatDateIsoShort } from './formatters';

const getModeAndCalc = ({
  amount,
  amount_replenish,
  rate,
  mode,
  last,
  lastMode,
}): { modeType: string; calcResult: number } => {
  let modeType = mode;
  let calcResult = 0;

  if (last === 'amount' && amount) {
    if (amount_replenish && !rate) {
      modeType = 'FIX_RATE';
    }

    if (rate && !amount_replenish) {
      modeType = 'FIX_AMOUNT2';
    }

    if (rate && amount_replenish) {
      modeType = lastMode;
    }
  }

  if (last === 'amount_replenish' && amount_replenish) {
    if (amount && !rate) {
      modeType = 'FIX_RATE';
    }

    if (rate && !amount) {
      modeType = 'FIX_AMOUNT1';
    }

    if (rate && amount) {
      modeType = lastMode;
    }
  }

  if (last === 'rate' && rate) {
    if (amount && !amount_replenish) {
      modeType = 'FIX_AMOUNT2';
    }

    if (amount_replenish && !amount) {
      modeType = 'FIX_AMOUNT1';
    }

    if (amount_replenish && amount) {
      modeType = lastMode;
    }
  }

  if (modeType === 'FIX_RATE') {
    calcResult = amount / amount_replenish;
  }

  if (modeType === 'FIX_AMOUNT1') {
    calcResult = amount_replenish * rate;
  }

  if (modeType === 'FIX_AMOUNT2') {
    calcResult = amount / rate;
  }

  return { modeType, calcResult };
};

export const getConversionTransactionNewFormValue = (
  formData: conversionTransactionFormDataType,
  name: string,
  value: string,
  lastMode: string,
): conversionTransactionFormDataType => {
  let newFormValue: conversionTransactionFormDataType = { ...formData };

  if (name === 'rate') {
    const rate = value ? Number(value) : undefined;

    if (value) {
      if (formData.mode !== 'FIX_RATE') {
        const { modeType: mode, calcResult } = getModeAndCalc({
          amount: formData.amount,
          amount_replenish: formData.targets[0].amount,
          rate: value,
          mode: formData.mode,
          last: 'rate',
          lastMode,
        });

        if (mode) {
          if (mode === 'FIX_AMOUNT1') {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], rate }],
              mode,
              amount: calcResult,
            };
          } else {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], amount: calcResult, rate }],
              mode,
            };
          }
        } else {
          newFormValue = { ...formData, targets: [{ ...formData.targets[0], rate }], mode };
        }
      } else {
        newFormValue = { ...formData, targets: [{ ...formData.targets[0], rate }] };
      }
    } else {
      newFormValue = { ...formData, targets: [{ ...formData.targets[0], amount: undefined, rate }], mode: '' };
    }
  }

  if (name === 'amount_replenish') {
    const amount_replenish = value ? Number(value) : undefined;

    if (value) {
      if (formData.mode !== 'FIX_AMOUNT2') {
        const { modeType: mode, calcResult } = getModeAndCalc({
          amount: formData.amount,
          amount_replenish: value,
          rate: formData.targets[0].rate,
          mode: formData.mode,
          last: 'amount_replenish',
          lastMode,
        });

        if (mode) {
          if (mode === 'FIX_AMOUNT1') {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], amount: amount_replenish }],
              mode,
              amount: calcResult,
            };
          } else {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], amount: amount_replenish, rate: calcResult }],
              mode,
            };
          }
        } else {
          newFormValue = { ...formData, targets: [{ ...formData.targets[0], amount: amount_replenish }], mode };
        }
      }
    } else {
      newFormValue = {
        ...formData,
        targets: [{ ...formData.targets[0], amount: amount_replenish, rate: undefined }],
        mode: '',
      };
    }
  }

  if (name === 'amount') {
    const amount = value ? Number(value) : undefined;

    if (value) {
      if (formData.mode !== 'FIX_AMOUNT1') {
        const { modeType: mode, calcResult } = getModeAndCalc({
          amount: value,
          amount_replenish: formData.targets[0].amount,
          rate: formData.targets[0].rate,
          mode: formData.mode,
          last: 'amount',
          lastMode,
        });

        if (mode) {
          if (mode === 'FIX_AMOUNT2') {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], amount: calcResult }],
              mode,
              amount,
            };
          } else {
            newFormValue = {
              ...formData,
              targets: [{ ...formData.targets[0], rate: calcResult }],
              amount,
              mode,
            };
          }
        } else {
          newFormValue = { ...formData, amount, mode };
        }
      }
    } else {
      newFormValue = { ...formData, targets: [{ ...formData.targets[0], rate: undefined }], amount, mode: '' };
    }
  }

  return newFormValue;
};

export const getConversionTransactionNewErrorValue = (
  error: conversionTransactionErrorType,
  name: string,
  value: string,
): conversionTransactionErrorType => {
  let newError: conversionTransactionErrorType = { ...error };

  if (name === 'amount') {
    newError = { ...error, amount: { status: !value, text: error.amount.text } };
  }

  if (name === 'amount_replenish') {
    newError = {
      ...error,
      target: { ...error.target, amount: { status: !value, text: error.amount.text } },
    };
  }

  if (name === 'rate') {
    newError = {
      ...error,
      target: { ...error.target, rate: { status: !value, text: error.amount.text } },
    };
  }

  return newError;
};

export function needRepaymentDateField(targetType: string): boolean {
  return targetType === 'PENALTY_REPAY' || targetType === 'INTEREST_REPAY';
}

export function getTargetRowAutocompleteOption(option) {
  if (option) {
    if (option.contract_number) {
      return `${formatAo(option)} ${option.contract_number}`;
    }

    return formatAo(option);
  }

  return '';
}

export function getInitialRepaymentDate(repaymentDate) {
  if (repaymentDate) {
    return formatDateIsoShort(repaymentDate);
  }
  const dt = new Date();

  return formatDateIsoShort(lastDayOfPrevMonth(dt));
}

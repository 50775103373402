import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
  className: string;
}

const Field: FC<Props> = ({ title, children, className }) => (
  <div className={className}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
);

export default Field;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAlert from './api/useAlert';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useSuccessCallback = (params) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const successCallback = useCallback(
    (body) => {
      history.push(params.route);
      let actionName;

      if (params.editType) {
        if (body.deleted) {
          actionName = t('global.deleted');
        } else {
          actionName = t('global.updated');
        }
      } else {
        actionName = t('global.created');
      }
      setAlert({
        type: 'success',
        message: `${t(params.messageKey)} ${actionName}`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, setAlert],
  );

  return {
    successCallback,
  };
};

export default useSuccessCallback;

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Table } from '@gamesb42/ui-kit';

import { getAccountsByIdCompany } from 'api/accounts';
import { Currency } from 'types/Currency';
import { newFormatAmount } from 'helpers/formatters';

import styles from './styles.module.scss';

const InternalAccounts = ({ factorCompanyId }: { factorCompanyId: string }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: t('factorDetails.internalAccounts.alias'),
        dataIndex: 'company_name',
      },
      {
        title: t('accounts.accountNumber'),
        dataIndex: 'account',
      },
      {
        title: t('accounts.availableBalance'),
        dataIndex: 'balance',
        render: (balance: number, row: { currency: Currency }) => newFormatAmount(balance, Number(row.currency)),
      },
    ],
    [t],
  );

  useEffect(() => {
    setLoading(true);
    setAccounts([]);
    getAccountsByIdCompany(factorCompanyId)
      .then((result) =>
        setAccounts(result.filter((account: { type: string }) => account.type.includes('counterparty-'))),
      )
      .catch(() => notification.error({ message: 'Error loading accounts' }))
      .finally(() => setLoading(false));
  }, [factorCompanyId]);

  return (
    <Table
      columns={columns}
      dataSource={accounts}
      loading={loading}
      rowKey="id"
      loadingRowCount={20}
      className={styles.root}
    />
  );
};

export default InternalAccounts;

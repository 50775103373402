// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextFieldProps } from '@mui/material/TextField';
import CalendarPicker from '@mui/lab/CalendarPicker';
import MonthPicker from '@mui/lab/MonthPicker';
import YearPicker from '@mui/lab/YearPicker';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { enNZ } from 'date-fns/locale';
import ruLocale from 'date-fns/locale/ru';

import { useTableWrapWithPaginationStyles } from 'components/tables/TableWrapWithPagination';
import PopUpLayout from 'helpers/PopUpLayout';
import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import { formatDate, formatDateIsoShort } from 'helpers/formatters';

import { InputKit } from '../uiKit/InputKit';

const calendarSvg = require('assets/img/calendar.svg').default;
const okSvg = require('assets/img/ok.svg').default;

export enum DatePikerEnum {
  ALL = 'all',
  WITHOUT = 'withOutDay',
}

const localeMap = (local: string) => {
  switch (local) {
    case 'ru':
      return { mask: '__.__.____', eng: ruLocale };
    case 'ru-RU':
      return { mask: '__.__.____', eng: ruLocale };
    case 'en':
      return { mask: '__/__/____', eng: enNZ };
    case 'en-US':
      return { mask: '__/__/____', eng: enNZ };
    default:
      return { mask: '__/__/____', eng: enNZ };
  }
};

type DatePropsType = {
  type?: DatePikerEnum;
  textFieldProps?: TextFieldProps;
  commonStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
  stylingLabel?: React.CSSProperties;
  labelKey?: string;
  date?: string;
  toolTip?: string;
  onChangeDateHandler: (target: { target: { name: string; value: string } }) => void;
  name?: string;
  errorText?: string;
  isError?: boolean;
  setError?: () => void;
  onBlurHandler?: (name: string, value?: string) => void;
  required?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  isNotErrorLocal?: boolean;
  HelperTextComponent?: FC;
  countMonth?: number;
  disableFutureYears?: boolean;
  disableFutureMonth?: boolean;
  disableFutureDay?: boolean;
  setMin?: Date;
};

const invalid = 'Invalid Date';
const maxDay = '2029-12-31';
const minDay = '2019-01-01';
const maxDate = new Date(maxDay).getTime();
const minDate = new Date(minDay).getTime();
const max = new Date(maxDay);
const min = new Date(minDay);

const DateInput: FC<DatePropsType> = ({
  disabled,
  toolTip = '',
  type = DatePikerEnum.ALL,
  labelKey = '',
  commonStyle,
  date = null,
  onChangeDateHandler,
  name = '',
  errorText = '',
  isError = false,
  setError,
  onBlurHandler,
  required,
  isNotErrorLocal,
  countMonth,
  disableFutureYears,
  disableFutureMonth,
  disableFutureDay,
  setMin,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date(date?.length === 0 ? null : date));
  const [isOpen, setIsOpenPopUp] = useState(false);

  useEffect(() => {
    setSelectedDate(new Date(date?.length === 0 ? null : date));
  }, [date]);

  useEffect(() => {
    const timestamp = selectedDate?.getTime();

    if (isOpen && !timestamp) {
      onChange(new Date(), null);
    }
  }, [isOpen]);

  const onChange = useCallback(
    (localDate: Date, val: string) => {
      if (isNotErrorLocal) {
        onChangeDateHandler({ target: { name, value: val || '' } });
      }

      if (val === undefined) {
        onChangeDateHandler({ target: { name, value: '' } });

        return;
      }

      if (formatDate(localDate) !== invalid) {
        setSelectedDate(localDate);
        const value = formatDateIsoShort(localDate);

        if (val === null && onBlurHandler) {
          onBlurHandler(name, value);
        }
        onChangeDateHandler({ target: { name, value } });

        return;
      }

      if (formatDate(localDate) !== invalid && setError) {
        setError();
      }
    },
    [onChangeDateHandler, selectedDate, formatDate, setSelectedDate],
  );

  const onPaste = useCallback(
    (e) => {
      const paste = formatDate(e.clipboardData.getData('Text'));

      if (paste === invalid) {
        return;
      }
      onChange(new Date(e.clipboardData.getData('Text')), paste);
    },
    [onChange, formatDate],
  );

  const onBlur = useCallback(() => {
    if (onBlurHandler) {
      onBlurHandler(name, formatDateIsoShort(selectedDate));
    }
  }, [onBlurHandler, name]);

  const onClose = useCallback(() => {
    setIsOpenPopUp(false);
  }, [setIsOpenPopUp]);

  const pickerProps = { helperText: '', error: false };
  const value = formatDate(selectedDate);
  const timestamp = selectedDate?.getTime();

  if (timestamp !== 0 && !isNotErrorLocal) {
    if (value === invalid) {
      pickerProps.helperText = value;
      pickerProps.error = true;
    } else if (timestamp > maxDate) {
      pickerProps.helperText = `${t('dateErrors.max')} (${new Date(maxDay).toLocaleDateString()})`;
      pickerProps.error = true;
    } else if (timestamp < minDate) {
      pickerProps.helperText = `${t('dateErrors.min')} (${new Date(minDay).toLocaleDateString()})`;
      pickerProps.error = true;
    } else if (isError && errorText && errorText.length > 0) {
      pickerProps.helperText = errorText;
      pickerProps.error = isError;
    }
  }

  if (timestamp === 0 && !isNotErrorLocal) {
    if (required && isError) {
      pickerProps.helperText = errorText || t('auth.required');
      pickerProps.error = true;
    }
  }

  if (isNotErrorLocal) {
    pickerProps.helperText = errorText;
    pickerProps.error = isError;
  }
  const validDate = value === invalid || timestamp === 0 ? new Date() : selectedDate;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap(navigator.language).eng}>
      <PopUpLayout open={disabled ? false : isOpen} onClose={onClose}>
        <DateModalPopUp
          countMonth={countMonth}
          validDate={validDate}
          onChange={onChange}
          setIsOpenPopUp={setIsOpenPopUp}
          type={type}
          disableFutureYears={disableFutureYears}
          disableFutureMonth={disableFutureMonth}
          disableFutureDay={disableFutureDay}
          setMin={setMin}
        />
      </PopUpLayout>
      <DatePicker
        disabled={disabled}
        label={t(labelKey)}
        minDate={min}
        maxDate={max}
        mask={localeMap(navigator.language).mask}
        views={type === DatePikerEnum.WITHOUT ? ['year', 'month'] : undefined}
        value={timestamp === 0 ? '' : validDate}
        open={false}
        readOnly={type === DatePikerEnum.WITHOUT || disabled}
        renderInput={(params) => (
          <Tooltip
            title={toolTip}
            disableInteractive={!disabled}
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
            followCursor
          >
            <div>
              <InputKit
                {...params.inputProps}
                placeholder={params.inputProps.placeholder === 'dd.mm.y' ? 'dd.mm.yyyy' : params.inputProps.placeholder}
                value={params.inputProps.value || ''}
                isError={isError || (params.inputProps.value === '' ? false : params.error)}
                wrapProps={{ height: 60, ...commonStyle }}
                onPaste={onPaste}
                disabled={disabled}
                helperText={pickerProps.helperText}
                required={required}
                onBlurHandler={(e) => {
                  typeof params.inputProps.onBlur === 'function' && params.inputProps.onBlur(e);
                  onBlur();
                }}
                label={t(labelKey)}
                element={(() => (
                  <img
                    style={{ cursor: 'pointer', position: 'absolute', right: 10, zIndex: '1' }}
                    onClick={() => setIsOpenPopUp(true)}
                    src={calendarSvg}
                    alt="calendar"
                  />
                ))()}
              />
            </div>
          </Tooltip>
        )}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

interface DateModalPopUpType {
  countMonth: number;
  validDate: Date;
  setIsOpenPopUp: (data: boolean) => void;
  onChange: (localDate: Date, val: string) => void;
  type: DatePikerEnum;
  disableFutureYears: boolean;
  disableFutureMonth: boolean;
  disableFutureDay: boolean;
  setMin?: Date;
}
const DateModalPopUp: FC<DateModalPopUpType> = ({
  countMonth,
  validDate,
  onChange,
  setIsOpenPopUp,
  type,
  disableFutureYears,
  disableFutureMonth,
  disableFutureDay,
  setMin,
}) => {
  const stylingOk = UseStylingButton();
  const { t } = useTranslation();
  const classes = useStyles({});
  const stylingCancel = UseStylingDropZoneButton();
  const refMonth = useRef(null);
  const styles = useTableWrapWithPaginationStyles();

  useEffect(() => {
    if (refMonth?.current && countMonth !== undefined) {
      const { children } = refMonth.current;
      const array = Array.from(children) as HTMLButtonElement[];
      array.forEach((item, i) => {
        if (i > countMonth) {
          const { style } = item;
          style.display = 'none';
        }
      });
    }
  }, [refMonth]);

  return (
    <div className={classes.calendarWrap}>
      <div className={classes.calendarTitle}>
        <span>{`${moment(validDate).format('MMMM')} ${moment(validDate).format('YYYY')}`}</span>
      </div>
      <div className={classes.calendar}>
        {type !== DatePikerEnum.WITHOUT && (
          <CalendarPicker
            classes={{ root: classes.calendarRoot }}
            date={validDate}
            onChange={onChange}
            disableFuture={disableFutureDay}
          />
        )}
        <MonthPicker
          ref={refMonth}
          date={validDate}
          minDate={setMin || min}
          maxDate={max}
          onChange={onChange}
          disableFuture={disableFutureMonth}
          classes={{ root: classes.monthPickerRoot }}
        />
        <YearPicker
          classes={{ root: `${classes.yearPickerRoot} ${styles.scrollTable}` }}
          date={validDate}
          isDateDisabled={() => false}
          minDate={setMin || min}
          maxDate={max}
          onChange={onChange}
          disableFuture={disableFutureYears}
        />
      </div>
      <div className={classes.calendarBottom}>
        <Button
          onClick={() => {
            setIsOpenPopUp(false);
          }}
          style={{ width: 89, height: 40, marginRight: 20 }}
          {...stylingOk}
        >
          {t('clientTabs.ok')}
        </Button>
        <Button
          onClick={() => {
            setIsOpenPopUp(false);
          }}
          style={{ width: 89, height: 40 }}
          {...stylingCancel}
        >
          {t('clientTabs.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default DateInput;

const useStyles = createUseStyles<string, { top?: number; left?: number }>(() => ({
  calendarBottom: {
    height: 72,
    padding: '16px 20px',
    boxSizing: 'border-box',
  },
  calendarTitle: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      fontSize: 20,
      lineHeight: '18px',
    },
  },
  calendarWrap: {
    borderRadius: 8,
    background: '#FFFFFF',
  },
  calendar: {
    display: 'flex',
    borderTop: '1px solid #83899833',
    borderBottom: '1px solid #83899833',
    height: 322,
    overflow: 'hidden',
  },
  calendarRoot: {
    padding: '22px 0px',
    borderRight: '1px solid #83899833',
    '&>div:first-child': {
      display: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: '#838998 !important',
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      overflow: 'hidden',
    },
  },
  monthPickerRoot: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '312px !important',
    flexDirection: 'column',
    padding: '22px 0px',
    '&>button:disabled': {
      display: 'none',
    },
    '&>button': {
      maxHeight: '46px',
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        color: '#838998',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:before': {
          width: 24,
          height: 24,
          background: '#838998',
          backgroundImage: `url(${okSvg})`,
          backgroundPosition: '6.3px 9px',
          backgroundRepeat: 'no-repeat',
        },
      },
      marginLeft: 46,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'left',
      color: '#838998',
      fontSize: 16,
      lineHeight: '18px',
      height: '100%',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 24,
        height: 24,
        background: '#F7F8FA',
        borderRadius: 20,
        position: 'relative',
        right: 12,
      },
    },
  },
  yearPickerRoot: {
    width: 134,
    borderLeft: '1px solid #83899833',
    flexDirection: 'column !important',
    maxHeight: 273,
    flexWrap: 'nowrap !important',
    padding: '22px 0px',
    '& .Mui-disabled': {
      display: 'none',
    },
    '&>div': {
      width: '100%',
      height: '100%',
      '&>button': {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: '#838998',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&:before': {
            width: 24,
            height: 24,
            background: '#838998',
            backgroundImage: `url(${okSvg})`,
            backgroundPosition: '6.3px 9px',
            backgroundRepeat: 'no-repeat',
          },
        },
        display: 'flex',
        alignContent: 'center',
        color: '#838998',
        fontSize: 16,
        lineHeight: '18px',
        '&:before': {
          content: '""',
          display: 'inline-block',
          width: 24,
          height: 24,
          background: '#F7F8FA',
          borderRadius: 20,
          position: 'relative',
          right: 12,
        },
      },
      '&>button:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

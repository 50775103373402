import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

export type SyncPdsResponse = {
  status: string;
  timestamp: string;
  name: string;
  description: string;
};

export type getSyncPdsType = {
  errorCallback?: Function;
  successCallback?: Function;
  resultKey?: string;
};

export default ({
  errorCallback = () => {},
  successCallback = () => {},
  resultKey = 'syncPdsParams',
}: getSyncPdsType) =>
  requestAsync({
    url: endpoints.getSyncPdsUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getSyncPdsUrl(),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

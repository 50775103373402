import { useMemo, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntities } from 'redux-query';

import updateUtils from 'actions/updateUtils';
import { CompanyType } from 'types/CompanyTypes';
import getActivationOrders from 'queries/activationOrder/getActivationOrders';
import getActivationOrder, { activationOrderKeyDataType } from 'queries/activationOrder/getActivationOrder';
import getActivationOrderTechnicalInfo from 'queries/activationOrder/getActivationOrderTechnicalInfo';
import getActivationOrderCreationData from 'queries/activationOrder/getActivationOrderCreationData';
import getActivationOrderList from 'queries/activationOrder/getActivationOrderList';
import createActivationOrder, { createActivationOrderDataType } from 'queries/activationOrder/createActivationOrder';
import createActivationOrderApplaud, {
  createActivationOrderApplaudType,
} from 'queries/activationOrder/createActivationOrderApplaud';
import editActivationOrder, { editActivationOrderDataType } from 'queries/activationOrder/editActivationOrder';
import createAvailableReceivables, {
  createAvailableReceivablesDataType,
} from 'queries/activationOrder/createAvailableReceivables';
import createFutureReceivables, {
  createFutureReceivablesDataType,
} from 'queries/activationOrder/createFutureReceivables';
import createScheduleForFutureReceivables, {
  createScheduleFutureReceivablesDataType,
} from 'queries/activationOrder/createScheduleForFutureReceivables';
import editActivationOrderApplaud, {
  editActivationOrderApplaudType,
} from 'queries/activationOrder/editActivationOrderApplaud';
import editAvailableReceivables, {
  editAvailableReceivablesDataType,
} from 'queries/activationOrder/editAvailableReceivables';
import editFutureReceivables, { editFutureReceivablesDataType } from 'queries/activationOrder/editFutureReceivables';
import deleteActivationOrder, { deleteActivationOrderDataType } from 'queries/activationOrder/deleteActivationOrder';
import getActivationOrderV2, { activationOrdersV2Type } from 'queries/activationOrder/getActivationOrderV2';
import getActivationOrderCalculateStatus from 'queries/activationOrder/getActivationOrderCalculateStatus';
import recalculateAOTechnicalInfo from 'queries/activationOrder/recalculateAOTechnicalInfo';
import { CurrencyType } from 'components/uiKit/InputKit';
import getAvailableReceivables, {
  getAvailableReceivablesDataType,
} from 'queries/activationOrder/getAvailableReceivables';
import getFutureReceivables, { getFutureReceivablesDataType } from 'queries/activationOrder/getFutureReceivables';
import getScheduleForFutureReceivables, {
  getScheduleForFutureReceivablesDataType,
} from 'queries/activationOrder/getScheduleForFutureReceivales';
import editScheduleForFutureReceivables, {
  editScheduleFutureReceivablesDataType,
} from 'queries/activationOrder/editScheduleForFutureReceivables';
import getAvailableReceivablesList, {
  getAvailableReceivablesListDataType,
} from 'queries/activationOrder/getAvailableReceivablesList';

import useActionsWithFetchingState from '../useActionsWithFetchingState';
import { StatusEnumAo } from '../../components/forms/ActivationOrderForm/CardsAo/data';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

type activationOrderTableParamsType = {
  companyId: string;
  company: CompanyType;
  page: number;
  limit: number;
  contract_number?: string;
  urlFiltersIsSet?: boolean;
  company_id?: string;
  order_id?: string;
  status?: StatusEnumAo | '';
};

export const initialActivationOrderTableParams: activationOrderTableParamsType = {
  companyId: '',
  company: { name: '' },
  page: 0,
  limit: 15,
  contract_number: '',
  status: '',
  urlFiltersIsSet: false,
  order_id: '',
};

export enum StatusEnumTitle {
  ACTIVE = 'ACTIVE',
  REPAID = 'REPAID',
  FUNDED = 'FUNDED',
  BLOCKED = 'BLOCKED',
}

export enum AoTypeEnum {
  'APPLAUD_FIX' = 'applaud_fix',
  'CLASSIC' = 'classic',
  'FUTURE_RECEIVABLES' = 'future_receivables',
  'AVAILABLE_RECEIVABLES' = 'available_receivables',
}
export interface activationOrderDataType {
  accounts: [];
  active: boolean;
  amount: string;
  auto_debiting: boolean;
  capitalization_disable_date: null | string;
  commission_disable_date: null | string;
  capitalization_period_disabled: {
    date_start: string;
    date_end: string;
  }[];
  companyId: string;
  company_id: string;
  ao_type: AoTypeEnum;
  company_name: string;
  loan_balance: string;
  next_payment_date: string;
  contract: null | {
    date: string;
    file_name: string;
    id: string;
    invoice_num: number;
    link: string;
    name: null | string;
    number: string;
    type: string;
  };
  ao_document: null | {
    date: string;
    file_name: string;
    id: string;
    invoice_num: number;
    link: string;
    name: null | string;
    number: string;
    type: string;
  };
  contractDate: null | string;
  contractNumber: null | string;
  contract_date: null | string;
  contract_id: null | string;
  contract_number: null | string;
  currency: CurrencyType;
  default_interest_disable_date: null | string;
  deletion: { can_be_deleted: boolean; reason: null | string };
  getterIndex: undefined | string;
  grace_period: null | string;
  has_transaction: boolean;
  id: null | string;
  interest: null | string;
  isCustomPaymentDay: boolean;
  is_custom_payment_day: boolean;
  issued_amount: null | string;
  last_transaction_date: null | string;
  name: null | string;
  old_data: boolean;
  payment_day: number;
  payment_days: { payment_day: number; apply_from_date: string; editable: boolean }[];
  purchaseDate: null | string;
  purchase_date: null | string;
  repaymentDate: null | string;
  repayment_date: null | string;
  status: StatusEnumTitle;
  vat_policy: null | string;
  penalty_policy: null | string;
  days?: null | string;
  ao_document_id?: null | string;
  commission_daily?: string | null;
  plan_commission_net?: string | null;
  plan_commission_vat?: string | null;
  plan_commission_gross?: string | null;
  commission_gross?: string | null;
  sign_date?: string | null;
  account_number?: {
    account: string;
  }[];
  distribution_platforms?: {
    platform_id: string;
    platform: string;
    purchase_percentage: string;
    purchase_price_percentage: string;
    funding_limit: string;
    currency: CurrencyType;
    platform_logo: string;
    activation_date?: string;
  }[];
}

export interface AvailableReceivablesListT {
  orders: activationOrderDataType[];
  available_currencies: CurrencyType[];
}

export interface activationOrdersV2DataType {
  active: boolean;
  amount: string;
  company_id: string;
  company_name: string;
  contract_date: null | string;
  contract_id: null | string;
  contract_number: null | string;
  currency: null | string;
  id: null | string;
  interest: null | string;
  last_payment_date: null | string;
  loan_balance: null | string;
  name: null | string;
  next_payment_date: null | string;
  purchase_date: null | string;
  repayment_date: null | string;
  status: StatusEnumTitle;
  ao_type: null | string;
  agreement: null | string;
  sign_date: null | string;
  type: string;
}

export interface ScheduleFutureReceivables {
  total_amount: string;
  records: {
    amount: string;
    deleted: boolean;
    id: string;
    payment_date: string;
  }[];
}

export interface useActivationOrderType {
  activationOrderData?: activationOrderDataType;
  availableReceivablesList?: AvailableReceivablesListT;
  schedulesFutureReceivablesData?: ScheduleFutureReceivables;
  getActivationOrder?: (data: activationOrderKeyDataType) => void;
  getActivationOrderV2?: (data: activationOrdersV2Type) => void;
  activationOrdersV2Data?: { limit: null | string; page: number; total: number; records: activationOrdersV2DataType[] };
  deleteActivationOrder?: (data: deleteActivationOrderDataType) => void;
  setActivationOrdersTableParams?: (activationOrdersTableParams: activationOrderTableParamsType) => void;
  activationOrdersTableParams?: activationOrderTableParamsType;
  listOrderAo?: { limit: null | string; page: number; total: number; records: activationOrdersV2DataType[] };
  clearActivationOrdersV2Data?: () => void;
  clearActivationOrder?: () => void;
  createActivationOrder?: (data: createActivationOrderDataType) => void;
  createActivationOrderApplaud?: (data: createActivationOrderApplaudType) => void;
  editActivationOrder?: (data: editActivationOrderDataType) => void;
  editActivationOrderApplaud?: (data: editActivationOrderApplaudType) => void;
  createAvailableReceivables?: (data: createAvailableReceivablesDataType) => void;
  createFutureReceivables?: (data: createFutureReceivablesDataType) => void;
  editFutureReceivables?: (data: editFutureReceivablesDataType) => void;
  editAvailableReceivables?: (data: editAvailableReceivablesDataType) => void;
  getAvailableReceivables?: (data: getAvailableReceivablesDataType) => void;
  getFutureReceivables?: (data: getFutureReceivablesDataType) => void;
  clearActivationOrderData?: () => void;
  createScheduleForFutureReceivables?: (data: createScheduleFutureReceivablesDataType) => void;
  editScheduleForFutureReceivables?: (data: editScheduleFutureReceivablesDataType) => void;
  getScheduleForFutureReceivables?: (data: getScheduleForFutureReceivablesDataType) => void;
  getAvailableReceivablesList?: (data: getAvailableReceivablesListDataType) => void;
}

const useActivationOrder = () => {
  const selector = useCallback(
    (state) => ({
      listOrderAo: objectSelector(state, 'getListOrderAo'),
      activationOrdersData: arraySelector(state, 'activationOrdersData'),
      activationOrdersV2Data: objectSelector(state, 'activationOrdersDataV2'),
      activationOrderData: objectSelector(state, 'activationOrderData'),
      schedulesFutureReceivablesData: objectSelector(state, 'schedulesFutureReceivablesData'),
      activationOrderTechnicalInfoData: arraySelector(state, 'activationOrderTechnicalInfoData'),
      activationOrderCreationData: objectSelector(state, 'activationOrderCreationData'),
      createActivationOrderData: objectSelector(state, 'createActivationOrderData'),
      editActivationOrderData: objectSelector(state, 'editActivationOrderData'),
      deleteActivationOrderData: objectSelector(state, 'deleteActivationOrderData'),
      activationOrderListData: arraySelector(state, 'activationOrderListData'),
      activationOrderCalculateStatusData: objectSelector(state, 'activationOrderCalculateStatusData'),
      recalculateAOTechnicalInfoData: objectSelector(state, 'recalculateAOTechnicalInfoData'),
      activationOrdersTableParams: utilsSelector(
        state,
        'activationOrdersTableParams',
        initialActivationOrderTableParams,
      ),
      availableReceivablesList: objectSelector(state, 'availableReceivablesList'),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getFutureReceivables,
      getAvailableReceivables,
      editFutureReceivables,
      editAvailableReceivables,
      createFutureReceivables,
      createAvailableReceivables,
      getActivationOrders,
      getActivationOrderV2,
      getActivationOrder,
      getActivationOrderTechnicalInfo,
      getActivationOrderCreationData,
      getActivationOrderList,
      createActivationOrder,
      createActivationOrderApplaud,
      editActivationOrder,
      editActivationOrderApplaud,
      deleteActivationOrder,
      getActivationOrderCalculateStatus,
      recalculateAOTechnicalInfo,
      createScheduleForFutureReceivables,
      editScheduleForFutureReceivables,
      getScheduleForFutureReceivables,
      getAvailableReceivablesList,
    }),
    [],
  );

  const activationOrdersTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          clearActivationOrdersV2Data: () =>
            updateEntities({
              activationOrdersDataV2: () => {},
            }),
          clearActivationOrder: () =>
            updateEntities({
              activationOrdersData: () => {},
            }),
          setActivationOrdersTableParams: (activationOrdersTableParams: activationOrderTableParamsType) =>
            updateUtils({
              activationOrdersTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const actionWithoutIsFetching = useMemo(
    () =>
      bindActionCreators(
        {
          clearActivationOrderCreationData: () =>
            updateEntities({
              activationOrderCreationData: () => ({}),
            }),
          clearActivationOrderTechnicalInfoData: () =>
            updateEntities({
              activationOrderTechnicalInfoData: () => [],
            }),
          clearActivationOrderData: () =>
            updateEntities({
              activationOrderData: () => {},
            }),
          clearActivationOrderListData: () =>
            updateEntities({
              activationOrderListData: () => [],
            }),
          clearScheduleForFutureReceivables: () =>
            updateEntities({
              schedulesFutureReceivablesData: () => ({}),
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...actionWithoutIsFetching,
    ...activationOrdersTableActions,
  };
};

export default useActivationOrder;

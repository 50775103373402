import { firstLetterUpperCase } from 'helpers';
import {
  RevenueSourceStatus,
  RevenueSourceCalendarType,
  RevenueSourceSyncTypeEnum,
  RevenueSourceSyncStatusEnum,
} from 'types/RevenueSources';

export const REVENUE_SOURCES_STATUS_ARRAY = [
  RevenueSourceStatus.ACTIVE,
  RevenueSourceStatus.IN_ACTIVE,
  RevenueSourceStatus.DELETED,
];

export const REVENUE_SOURCES_STATUS_NAME = {
  [RevenueSourceStatus.ACTIVE]: 'ACTIVE',
  [RevenueSourceStatus.IN_ACTIVE]: 'INACTIVE',
  [RevenueSourceStatus.DELETED]: 'DELETED',
};

export const REVENUE_SOURCES_STATUS_COLOR = {
  [RevenueSourceStatus.ACTIVE]: 'green' as const,
  [RevenueSourceStatus.IN_ACTIVE]: 'orange' as const,
  [RevenueSourceStatus.DELETED]: 'red' as const,
};

export const REVENUE_SOURCES_STATUS_OPTIONS = REVENUE_SOURCES_STATUS_ARRAY.map((value) => ({
  label: REVENUE_SOURCES_STATUS_NAME[value],
  value,
}));

export const REVENUE_SOURCES_CALENDAR_TYPE_ARRAY = [
  RevenueSourceCalendarType.DEFAULT,
  RevenueSourceCalendarType.CUSTOM,
];

export const REVENUE_SOURCES_CALENDAR_TYPE_NAME = {
  [RevenueSourceCalendarType.DEFAULT]: firstLetterUpperCase(RevenueSourceCalendarType.DEFAULT),
  [RevenueSourceCalendarType.CUSTOM]: firstLetterUpperCase(RevenueSourceCalendarType.CUSTOM),
};

export const REVENUE_SOURCES_CALENDAR_TYPE_OPTIONS = REVENUE_SOURCES_CALENDAR_TYPE_ARRAY.map((value) => ({
  label: REVENUE_SOURCES_CALENDAR_TYPE_NAME[value],
  value,
}));

export const REVENUE_SOURCES_SYNC_TYPE_ARRAY = [RevenueSourceSyncTypeEnum.AUTO, RevenueSourceSyncTypeEnum.FILE];

export const REVENUE_SOURCES_SYNC_TYPE_NAME = {
  [RevenueSourceSyncTypeEnum.AUTO]: firstLetterUpperCase(RevenueSourceSyncTypeEnum.AUTO),
  [RevenueSourceSyncTypeEnum.FILE]: firstLetterUpperCase(RevenueSourceSyncTypeEnum.FILE),
};

export const REVENUE_SOURCES_SYNC_TYPE_OPTIONS = REVENUE_SOURCES_SYNC_TYPE_ARRAY.map((value) => ({
  label: REVENUE_SOURCES_SYNC_TYPE_NAME[value],
  value,
}));

export const REVENUE_SOURCES_SYNC_STATUS_ARRAY = [
  RevenueSourceSyncStatusEnum.SYNCHRONIZED,
  RevenueSourceSyncStatusEnum.CREDENTIALS_NOT_FILLED,
  RevenueSourceSyncStatusEnum.CREDENTIALS_ERROR,
  RevenueSourceSyncStatusEnum.TIMEOUT,
  RevenueSourceSyncStatusEnum.CONNECTION_ERROR,
];

export const REVENUE_SOURCES_SYNC_STATUS_NAME = {
  [RevenueSourceSyncStatusEnum.SYNCHRONIZED]: 'SYNCHRONIZED',
  [RevenueSourceSyncStatusEnum.CREDENTIALS_NOT_FILLED]: 'NO CREDENTIALS',
  [RevenueSourceSyncStatusEnum.CREDENTIALS_ERROR]: 'CREDENTIALS ERROR',
  [RevenueSourceSyncStatusEnum.TIMEOUT]: 'INTERNAL SERVER ERROR',
  [RevenueSourceSyncStatusEnum.CONNECTION_ERROR]: 'TIMEOUT',
  [RevenueSourceSyncStatusEnum.NOT_SYNCHRONIZED]: 'SYNCHRONIZED',
};

export const REVENUE_SOURCES_SYNC_STATUS_OPTIONS = REVENUE_SOURCES_SYNC_STATUS_ARRAY.map((value) => ({
  label: REVENUE_SOURCES_SYNC_STATUS_NAME[value],
  value,
}));

export const REVENUE_SOURCES_SYNC_STATUS_COLOR = {
  [RevenueSourceSyncStatusEnum.SYNCHRONIZED]: 'green' as const,
  [RevenueSourceSyncStatusEnum.CREDENTIALS_NOT_FILLED]: 'gray' as const,
  [RevenueSourceSyncStatusEnum.CREDENTIALS_ERROR]: 'red' as const,
  [RevenueSourceSyncStatusEnum.TIMEOUT]: 'red' as const,
  [RevenueSourceSyncStatusEnum.CONNECTION_ERROR]: 'red' as const,
  [RevenueSourceSyncStatusEnum.NOT_SYNCHRONIZED]: 'red' as const,
};

import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { CurrencyType } from 'components/uiKit/InputKit';

import { ClaimT } from './View/Claim';

export enum VariantEnum {
  CREATE,
  EDIT,
}

export interface ResponseCheckNumber {
  is_unique?: string;
}

type CheckNumberParams = {
  company_id: string;
  pr_number: string;
};

export const checkNumber = async (params?: CheckNumberParams) => {
  try {
    const res = await fetch(endpoints.checkNumberUrl(params), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return (await res.json()) as Promise<ResponseCheckNumber>;
  } catch (error) {
    return error as ResponseCheckNumber;
  }
};

export type DataT = {
  company_id: string;
  order_id: string;
  currency: CurrencyType | '';
  request_date: string;
  request_number: string;
  purchase_price: string;
  maximum_price: string;
  available_advance: ClaimT[];
};

export const INITIAL_DATA: DataT = {
  company_id: '',
  order_id: '',
  currency: '',
  available_advance: [],
  request_date: '',
  purchase_price: '',
  maximum_price: '',
  request_number: '',
};

export type ErrorT = {
  message: string;
  isError: boolean;
};

export type DataErrorsT = {
  company_id: ErrorT;
  order_id: ErrorT;
  currency: ErrorT;
  request_date: ErrorT;
  request_number: ErrorT;
  purchase_price: ErrorT;
  maximum_price: ErrorT;
  available_advance: Partial<Record<keyof ClaimT, ErrorT>>[];
};

export const INITIAL_ERRORS: Partial<DataErrorsT> = {
  company_id: undefined,
  order_id: undefined,
  currency: undefined,
  request_date: undefined,
  request_number: undefined,
  purchase_price: undefined,
  maximum_price: undefined,
  available_advance: [],
};

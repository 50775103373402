import React, { FC, Children, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SwitchButton from 'components/buttons/SwitchButton';
import { ITransactionTableParams } from 'hooks/api/useTransaction';

import styles from './styles.module.scss';

type actionMenuType = 'show_deleted' | 'edit' | 'bank_transactions';
type targetType = { checked?: boolean; name?: string };
type actionElementType = 'switch' | 'text';

interface GenerelList {
  callback?: (e: targetType, data: actionMenuType) => void;
  transactionTableParams?: ITransactionTableParams;
}

const dotsSvg = require('assets/img/dots.svg').default;
const pencilSvg = require('assets/img/pencil.svg').default;
const delSvg = require('assets/img/del.svg').default;

const menuList = [
  {
    img: pencilSvg,
    name: 'contentHeader.bank_transactions',
    style: { color: '#838998' },
    action: 'bank_transactions' as actionMenuType,
    type: 'switch' as actionElementType,
    active: false,
  },
  {
    img: delSvg,
    name: 'contentHeader.deleted_only',
    style: { color: '#EC3E72' },
    action: 'show_deleted' as actionMenuType,
    type: 'switch' as actionElementType,
    active: true,
  },
];

const EditGeneral: FC<GenerelList> = ({ callback, transactionTableParams }) => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const wrapperRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const checkIfClickedOutside = (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (isActive && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div style={{ position: 'absolute', right: '-60px', top: '0px' }}>
      <button type="button" className={styles.editWrap} onClick={() => setIsActive(!isActive)}>
        <img src={dotsSvg} alt="dots" />
      </button>
      {isActive && (
        <div className={styles.editMenu} ref={wrapperRef}>
          {Children.toArray(
            menuList.map(({ name, img, style, action, type, active }) => (
              <>
                {type === 'switch' && (
                  <SwitchButton
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    inputSwitch={transactionTableParams[action] || false}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    handleChangeSwitch={({ target }) => callback(target, action)}
                    name={type}
                    buttonName={t(name)}
                    wrapperStyle={{
                      display: 'inline-block',
                      marginRight: '10px',
                      marginTop: '8px',
                      marginBottom: '2px',
                    }}
                    textStyle={{
                      color: '#838998',
                      fontSize: '16px',
                      paddingBottom: '2px',
                      paddingRight: '4px',
                      marginLeft: '-10px',
                    }}
                    isSwitchNewStyle
                    key={action}
                    disabled={!active}
                  />
                )}
                {type === 'text' && (
                  <div
                    className={styles.item}
                    onClick={() => {
                      setIsActive(false);
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      callback(null, action);
                    }}
                  >
                    <img src={img} alt="img" />
                    <span style={style}>{t(name)}</span>
                  </div>
                )}
              </>
            )),
          )}
        </div>
      )}
    </div>
  );
};

export default EditGeneral;

//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import { Table, DatePicker, EurIcon, UsdIcon } from '@gamesb42/ui-kit';

import { formatAmount } from 'helpers/formatters';
import ContentWrapper from 'components/ContentWrapper';

import s from './dashboard.module.scss';

const columns1 = [
  {
    title: 'Сurrent portfolio',
    dataIndex: 'money',
    width: '279px',
    align: 'right' as 'right',
    render: (text: string) => (
      <div className={s.row}>
        {text.includes('€') && <EurIcon size={24} />}
        {text.includes('$') && <UsdIcon size={24} />}
        {text.slice(1, text.length)}
      </div>
    ),
  },
];
const columns2 = [
  {
    title: 'Accrued commission',
    dataIndex: 'money',
    width: '279px',
    align: 'right' as 'right',
    render: (text: string) => (
      <div className={s.row}>
        {text.includes('€') && <EurIcon size={24} />}
        {text.includes('$') && <UsdIcon size={24} />}
        <div>{text.slice(1, text.length)}</div>
      </div>
    ),
  },
];
const columns3 = [
  {
    title: 'Cost of money',
    dataIndex: 'money',
    width: '279px',
    align: 'right' as 'right',
    render: (text: string) => (
      <div className={s.row}>
        {text.includes('€') && <EurIcon size={24} />}
        {text.includes('$') && <UsdIcon size={24} />}
        {text.slice(1, text.length)}
      </div>
    ),
  },
];

interface DashboardDataRes {
  sum_loan_body_eur: string;
  sum_loan_body_usd: string;
  sum_commission_eur: string;
  sum_commission_usd: string;
  value_of_money_eur: string;
  value_of_money_usd: string;
}

const dashboardDatadefault = {
  sum_loan_body_eur: '0',
  sum_loan_body_usd: '0',
  sum_commission_eur: '0',
  sum_commission_usd: '0',
  value_of_money_eur: '0',
  value_of_money_usd: '0',
};

export default function Dashboard() {
  const [dashboardData, setDashboardDate] = useState<DashboardDataRes>(dashboardDatadefault);
  const [dateFrom, setDateFrom] = useState<DatePickerProps['value']>(moment());

  useEffect(() => {
    if (dateFrom) {
      let myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);

      fetch(`${process.env.REACT_APP_BASE_URL}/reports/dashboard?report_date=${dateFrom.format('yyyy-MM-DD')}`, {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      })
        .then((response) => response.json())
        .then((result) => {
          setDashboardDate(result);
        })
        .catch((error) => console.log('error', error));
    }
  }, [dateFrom, setDateFrom]);

  return (
    <ContentWrapper style={{ padding: '20px 40px', position: 'relative' }} maxWidthContent fullHeight>
      <div className={s.contentWrapper}>
        <div className={s.center}>
          <div className={s.title}>Dashboard</div>
          <div className={s.calendarWrapper}></div>
          {Object.keys(dashboardData).length > 0 && (
            <div className={s.tablesWrapper}>
              <div className={s.firstRow}>
                <DatePicker
                  placeholder="Select month"
                  label={dateFrom ? 'Selected month' : 'Select month'}
                  onChange={setDateFrom}
                  picker="month"
                  format="MMMM yyyy"
                  style={{ width: '290px', border: '1px solid red!important' }}
                  defaultValue={moment()}
                />
                <Table
                  syle={{overflowY: "hidden"}}
                  className={s.table1}
                  columns={columns1}
                  dataSource={[
                    { money: `€ ${formatAmount(parseFloat(dashboardData.sum_loan_body_eur))}` },
                    { money: `$ ${formatAmount(parseFloat(dashboardData.sum_loan_body_usd))}` },
                  ]}
                />
              </div>
              <Table
                className={s.table2}
                columns={columns2}
                dataSource={[
                  { money: `€ ${formatAmount(parseFloat(dashboardData.sum_commission_eur))}` },
                  { money: `$ ${formatAmount(parseFloat(dashboardData.sum_commission_usd))}` },
                ]}
              />
              <Table
                className={s.table3}
                columns={columns3}
                dataSource={[
                  { money: `€ ${formatAmount(parseFloat(dashboardData.value_of_money_eur))}` },
                  { money: `$ ${formatAmount(parseFloat(dashboardData.value_of_money_usd))}` },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </ContentWrapper>
  );
}

import React, { FC, useState } from 'react';

import endpoints from 'consts/endpoints';
import downloadSvg from 'assets/img/download.svg';
import useAlert from 'hooks/api/useAlert';

import styles from './styles.module.scss';

const parseDate = (value: string) => {
  const date = new Date(value);

  date.setDate(date.getDate() + 1);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  return [date.getFullYear(), month > 9 ? month : `0${month}`, day > 9 ? day : `0${day}`].join('-');
};

interface Props {
  filter?: string;
  sort: any;
}

const DownloadLink: FC<Props> = ({ filter, sort }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAlert } = useAlert();

  const parseFilter = filter
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      JSON.parse(decodeURI(filter)).map((item) => {
        if (item.property === 'date_from') {
          return { property: 'value_datetime', operator: item?.operator, value: item?.value };
        }

        if (item.property === 'date_to') {
          return { property: 'value_datetime', operator: item?.operator, value: parseDate(item?.value) };
        }

        if (item.property === 'amount') {
          return { property: 'amount', operator: item?.operator, value: Number(item?.value) };
        }

        return item;
      })
    : {};

  const startLoading = () => {
    setAlert({
      type: 'success',
      message: 'Start loading archive',
    });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <a
      href={endpoints.downloadArchiveTransactionURL({
        format: 'xlsx',
        ...(filter && { filter: JSON.stringify(parseFilter) }),
        ...(sort && { sort }),
      })}
      className={styles.downloadHeaderButton}
      download
      onClick={(e) => {
        if (isLoading) e.preventDefault();
        startLoading();
      }}
    >
      <img src={downloadSvg} alt="download" />
    </a>
  );
};

export default DownloadLink;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { formatAo, formatDateIsoShort } from 'helpers/formatters';

export const autocompleteContractData = (data) => {
  if (!Array.isArray(data)) return [];
  const filteredContracts = [];
  data.reduce((acc, item) => {
    if (item.number && !acc.includes(item.number)) {
      acc.push(item.number);
    }

    return acc;
  }, filteredContracts);

  return filteredContracts.map((item) => ({
    id: item,
    name: item,
    active: true,
  }));
};

export const autocompleteContractsData = (data) => {
  if (!Array.isArray(data)) return [];
  const filteredContracts = [];
  data.reduce((acc, item) => {
    if (item.id && !acc.includes(item.id)) {
      acc.push({ id: item.id, name: `${item.name} ${item.number}`, number: item.number, date: item.date });
    }

    return acc;
  }, filteredContracts);

  return filteredContracts.map((item) => ({
    id: item.id,
    name: item.name,
    number: item.number,
    date: formatDateIsoShort(item.date),
    active: true,
  }));
};

export const autocompleteAOData = (data) =>
  data
    .filter((item) => !item.name.includes('deleted'))
    .map((item) => ({ id: item.id, name: formatAo(item), active: true }));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import {
  errorsApplaud,
  errorsApplaudType,
  requiredApplaudEditKeys,
  requiredApplaudKeys,
} from 'components/forms/ActivationOrderForm/CardsAo/CardApplaud/data';
import {
  errorsAvailableReceivable,
  errorsAvailableReceivableType,
  requiredAvailableReceivablesEditKeys,
  requiredAvailableReceivablesKeys,
} from 'components/forms/ActivationOrderForm/CardsAo/CardAvailableReceivables/data';
import {
  requiredClassicEditKeys,
  requiredClassicKeys,
  errorsClassic,
} from 'components/forms/ActivationOrderForm/CardsAo/CardClassic/data';
import {
  errorsFutureReceivables,
  requiredFutureReceivablesEditKeys,
  requiredFutureReceivablesKeys,
} from 'components/forms/ActivationOrderForm/CardsAo/CardFutureReceivables/data';

import { initialOrderCardsData, KeyCardsEnum, statusParamsEnum } from './data';

export interface CustomParamsKeyOfCardType {
  isOpenClosingDatePopUp?: boolean;
}

const getCurrentRequiredKeyOfCard = (
  aoFormData: typeof initialOrderCardsData,
  customParams?: CustomParamsKeyOfCardType,
) => ({
  [statusParamsEnum.CREATE]: {
    [AoTypeEnum.CLASSIC]: { listRequired: requiredClassicKeys(aoFormData.status), errorsList: errorsClassic },
    [AoTypeEnum.APPLAUD_FIX]: { listRequired: requiredApplaudKeys, errorsList: errorsApplaud },
    [AoTypeEnum.AVAILABLE_RECEIVABLES]: {
      listRequired: requiredAvailableReceivablesKeys(aoFormData.status),
      errorsList: errorsAvailableReceivable,
    },
    [AoTypeEnum.FUTURE_RECEIVABLES]: {
      listRequired: requiredFutureReceivablesKeys({ status: aoFormData.status }),
      errorsList: errorsFutureReceivables,
    },
  },
  [statusParamsEnum.EDIT]: {
    [AoTypeEnum.CLASSIC]: { listRequired: requiredClassicEditKeys(aoFormData.status), errorsList: errorsClassic },
    [AoTypeEnum.APPLAUD_FIX]: { listRequired: requiredApplaudEditKeys, errorsList: errorsApplaud },
    [AoTypeEnum.AVAILABLE_RECEIVABLES]: {
      listRequired: requiredAvailableReceivablesEditKeys(aoFormData.status),
      errorsList: errorsAvailableReceivable,
    },
    [AoTypeEnum.FUTURE_RECEIVABLES]: {
      listRequired: requiredFutureReceivablesEditKeys({ status: aoFormData.status, customParams }),
      errorsList: errorsFutureReceivables,
    },
  },
});

const useCurrentRequiredKeyOfCard = (
  customParams?: CustomParamsKeyOfCardType,
): {
  listRequired?: KeyCardsEnum[];
  errorsList?:
    | errorsApplaudType
    | errorsAvailableReceivableType
    | errorsAvailableReceivableType
    | errorsAvailableReceivableType
    | [];
  getParamsItem?: (path: KeyCardsEnum, key: KeyCardsEnum) => { required?: boolean };
} => {
  const { aoFormData }: useCompanyType = useCompany();
  const { status } = useParams();
  const { listRequired = [], errorsList = {} } =
    getCurrentRequiredKeyOfCard(aoFormData, customParams)[status][aoFormData.ao_type] || {};
  const getParamsItem = useCallback(
    (path: KeyCardsEnum, key: KeyCardsEnum) => {
      let params = {};
      errorsList &&
        Array.isArray(errorsList[path]) &&
        errorsList[path].forEach((item) => {
          if (item.key === key) {
            params = item?.parameters;
          }
        });

      return params || {};
    },
    [errorsList],
  );

  return { listRequired, errorsList, getParamsItem };
};

export default useCurrentRequiredKeyOfCard;

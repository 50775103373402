// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Title from 'components/common/Title';
import Button from 'components/uiKit/Button';
import useTransaction, { ItemTransactionsType, useTransactionType } from 'hooks/api/useTransaction';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';
import urls from 'constants/urls';

import { initialObjTransactionsType, setInitialKey } from './data';
import ConversionIncomingCard from './Cards/ConversionIncoming/ConversionIncoming';

interface TransactionsFormPropsType {
  transactionsFormData: initialObjTransactionsType;
  transaction_id: string;
  setTransactionFormData: (data: initialObjTransactionsType) => void;
  type: string;
}

export const isEditAllocateConversionCard = {
  [KeyTransactions.CONVERSION_INCOMING]: true,
};

const GET_TITLE = {
  [KeyTransactions.CONVERSION_INCOMING]: 'forms.transaction.conversionIncoming',
};

const GET_BUTTON_NAME = {
  [KeyTransactions.CONVERSION_INCOMING]: 'forms.transaction.saveConversion',
};

const currentCard = {
  [KeyTransactions.CONVERSION_INCOMING]: <ConversionIncomingCard />,
};
const AllocateConversionTr: FC<TransactionsFormPropsType> = ({
  transactionsFormData,
  // eslint-disable-next-line camelcase
  transaction_id,
  setTransactionFormData,
  type,
}) => {
  const { t } = useTranslation();
  const { getTransactionsById }: useTransactionType = useTransaction();
  const history = useHistory();

  useEffect(() => {
    getTransactionsById({
      // eslint-disable-next-line camelcase
      transaction_id,
      successCallback: (res: ItemTransactionsType) => {
        const initialKey = setInitialKey(res);

        if (type) {
          initialKey[KeyTransactions.TYPE] = type;
        }

        if (type === KeyTransactions.CONVERSION_INCOMING) {
          setTransactionFormData({
            ...initialKey,
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Title text={t(GET_TITLE[transactionsFormData[KeyTransactions.TYPE]])} styles={{ marginBottom: 24 }} />
      {currentCard[transactionsFormData[KeyTransactions.TYPE]]}
      {transactionsFormData[KeyTransactions.TYPE] && (
        <div style={{ display: 'flex' }}>
          <Button type="submit" style={{ height: 40, width: 150, marginRight: 32 }} variant="fill" color="grey_light">
            {t(GET_BUTTON_NAME[transactionsFormData[KeyTransactions.TYPE]])}
          </Button>
          <Button
            style={{ height: 40 }}
            onClick={() => {
              // eslint-disable-next-line camelcase
              history.push(`${urls.getTransactions()}/${transaction_id}`);
            }}
          >
            {t('forms.transaction.cancel')}
          </Button>
        </div>
      )}
    </>
  );
};

export default AllocateConversionTr;

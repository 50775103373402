import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@gamesb42/ui-kit';

import { getFactorCompany } from 'api/company';
import { BankAccount, CompanyKeyEnum } from 'types/CompanyTypes';
import { Currency } from 'types/Currency';

interface Option {
  label: string;
  value: string;
  source: {
    [CompanyKeyEnum.BANK_NAME]: string;
    [CompanyKeyEnum.SWIFT]: string;
    [CompanyKeyEnum.CURRENCY]: string;
    [CompanyKeyEnum.ID]: string;
    [CompanyKeyEnum.NAME]: string;
  };
}

const FactorAccountsSelect = ({
  currency,
  value,
  needSetDefaultValue,
  ...props
}: SelectProps & { currency: Currency; needSetDefaultValue?: boolean }) => {
  const { t } = useTranslation();
  const [factorAccounts, setFactorAccounts] = useState<Option[]>([]);

  const currencyFactorAccounts = factorAccounts.filter(
    (account) => account.source[CompanyKeyEnum.CURRENCY] === currency,
  );

  const setDefaultValue = useCallback(
    (newResult: Option[]) => {
      const newResultWithCurrency = newResult.filter((account) => account.source[CompanyKeyEnum.CURRENCY] === currency);

      if (currency && newResultWithCurrency.length === 1) {
        const newDefaultValue = newResultWithCurrency[0];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (value !== newDefaultValue.value) props.onChange(newDefaultValue.value, { source: newDefaultValue.source });
      }
    },
    [currency, props, value],
  );

  useEffect(() => {
    if (needSetDefaultValue) {
      setDefaultValue(factorAccounts);
    }
  }, [currency, factorAccounts, needSetDefaultValue, setDefaultValue]);

  useEffect(() => {
    getFactorCompany()
      .then((result) => {
        const newResult =
          result[CompanyKeyEnum.BANK_DETAILS]?.map((bankAccount: BankAccount) => ({
            label: `${bankAccount[CompanyKeyEnum.BANK_NAME]} *${bankAccount[CompanyKeyEnum.ACCOUNT]?.slice(-4)}`,
            value: bankAccount[CompanyKeyEnum.ACCOUNT] as string,
            source: {
              [CompanyKeyEnum.BANK_NAME]: bankAccount[CompanyKeyEnum.BANK_NAME] as string,
              [CompanyKeyEnum.SWIFT]: bankAccount[CompanyKeyEnum.SWIFT] as string,
              [CompanyKeyEnum.CURRENCY]: bankAccount[CompanyKeyEnum.CURRENCY] as string,
              [CompanyKeyEnum.ID]: result[CompanyKeyEnum.ID]!,
              [CompanyKeyEnum.NAME]: result[CompanyKeyEnum.NAME]!,
            },
          })) || [];

        setFactorAccounts(newResult);

        if (needSetDefaultValue) setDefaultValue(newResult);
      })
      .catch(console.error);
  }, []);

  return (
    <Select
      {...props}
      size="large"
      label={t('factorDetails.factorBankAccount')}
      options={currencyFactorAccounts}
      value={factorAccounts.length > 0 ? value : undefined}
      aria-required
    />
  );
};

export default FactorAccountsSelect;

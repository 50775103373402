export enum NameEnum {
  URL = 'url',
  ADDRESSES = 'addresses',
  OTHER = 'other',
  EARLY_REPAYMENT = 'early_repayment',
  APP_STORE_DETAILS_CHANGED = 'app_store_details_changed',
  USER_REGISTRATION = 'user_registration',
  COMPANY_REGISTRATION = 'company_registration',
  CONTRACT_SIGN = 'contract_sign',
  HTML_CONTENT = 'html_content',
  TEXT_CONTENT = 'text_content',
}

export enum SystemFieldsEnum {
  representative_email = 'representative_email',
  representative_phone_number = 'representative_phone_number',
}
const translateEnum = {
  URL: 'systemSetting.notification_url',
  ADDRESSES: 'systemSetting.addresses',
  OTHER: 'systemSetting.other',
  EARLY_REPAYMENT: 'systemSetting.early_repayment',
  APP_STORE_DETAILS_CHANGED: 'systemSetting.app_store_details_changed',
  USER_REGISTRATION: 'systemSetting.user_registration',
  COMPANY_REGISTRATION: 'systemSetting.company_registration',
  CONTRACT_SIGN: 'systemSetting.contract_sign',
  HTML_CONTENT: 'systemSetting.html_content',
  TEXT_CONTENT: 'systemSetting.text_content',
};

const common = {
  labelKey: '',
  disabled: false,
  rows: 5,
  stylesProp: {
    width: '100%',
    marginBottom: '20px',
  },
};

const commonMenu = [
  {
    ...common,
    multiline: true,
    name: NameEnum.HTML_CONTENT,
    labelKey: translateEnum.HTML_CONTENT,
    defaultValue: translateEnum.HTML_CONTENT,
    stylesProp: { width: '100%', marginBottom: '3px', marginTop: '3px' },
  },
  {
    ...common,
    labelKey: translateEnum.TEXT_CONTENT,
    multiline: true,
    name: NameEnum.TEXT_CONTENT,
    stylesProp: { width: '100%', marginBottom: '20px' },
  },
];

export enum systemSettingsFieldsEnum {
  server_url = 'server_url',
  realm_name = 'realm_name',
  client_id = 'client_id',
  INTERESTS = 'INTERESTS',
  BANK_FEE = 'BANK_FEE',
  PENALTY = 'PENALTY',
  CONV_IN = 'CONV_IN',
  CONV_OUT = 'CONV_OUT',
  INCOME = 'INCOME',
  OUTCOME = 'OUTCOME',
  EMISSION = 'EMISSION',
  investor_interest = 'investor_interest',
  grace_period = 'grace_period',
  customer_contract_template = 'customer_contract_template',
  legal_name = 'legal_name',
  trading_name = 'trading_name',
  company_registration = 'company_registration',
  tax_number = 'tax_number',
  website = 'website',
  address = 'address',
  city = 'city',
  country = 'country',
  address2 = 'address2',
  zip = 'zip',
  director_full_name = 'director_full_name',
  director_title = 'director_title',
  representative_full_name = 'representative_full_name',
  representative_title = 'representative_title',
  representative_email = 'representative_email',
  representative_phone_number = 'representative_phone_number',
  gsuite_domain = 'gsuite_domain',
  client_email = 'client_email',
  private_key = 'private_key',
  bank_address_usd = 'bank_address_usd',
  bank_address_eur = 'bank_address_eur',
  reference_usd = 'reference_usd',
  beneficiary_bank_usd = 'beneficiary_bank_usd',
  beneficiary_eur = 'beneficiary_eur',
  beneficiary_usd = 'beneficiary_usd',
  bank_usd = 'bank_usd',
  swift_usd = 'swift_usd',
  iban_usd = 'iban_usd',
  bank_eur = 'bank_eur',
  swift_eur = 'swift_eur',
  iban_eur = 'iban_eur',
}

import React, { CSSProperties, FC, ReactNode, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import endpoints from 'consts/endpoints';
import { formatAmount, formatDate, getCurrency } from 'helpers/formatters';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';

import styles from './styles.module.scss';

const clipSvg = require('assets/img/clip.svg').default;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getCurrencyWithAmount = (value, currency) =>
  value !== null && value !== undefined ? `${getCurrency(currency)}${formatAmount(Number(value))}` : '';

enum MAP_AO_TYPE_TO_STRING {
  classic = 'forms.activationOrder.classic',
  applaud_fix = 'forms.activationOrder.applaud',
  future_receivables = 'forms.activationOrder.futureReceivables',
  available_receivables = 'forms.activationOrder.availableReceivables',
}

export enum TypeEnum {
  TITLE,
  TEXT,
  FILE,
  CAPTION,
  HREF,
}

export type ColorT = 'green' | 'orange' | 'violet' | 'blue';
export type ValueFormatT = 'date' | 'money' | 'percent' | 'policy' | 'repaymentPlan';

export type PropsT = {
  type?: TypeEnum;
  valueFormatType?: ValueFormatT;
  color?: ColorT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addComponent?: any;
  title?: string;
  currency?: string;
  additionalStyle?: CSSProperties;
  value?: string | number;
  content?: ReactNode;
  fileProps?: {
    isFileStile?: boolean;
    link?: string;
    name?: string;
  };
  widthLeft?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  wrapStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  rightContent?: ReactNode;
};

const Item: FC<PropsT> = ({
  type,
  title,
  color,
  value,
  fileProps,
  currency,
  valueFormatType = 'default',
  additionalStyle,
  widthLeft = 164,
  content,
  align = 'inherit',
  wrapStyle,
  titleStyle,
  rightContent,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const download = async (link: string) => {
    setIsLoading(true);
    await getFileStorage(endpoints.getStorageFileUrl(link));
    setIsLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const getFormattedValue: Record<ValueFormatT, (value: string) => string> = useMemo(
    () => ({
      date: (oldValue) => formatDate(oldValue),
      money: (oldValue) => getCurrencyWithAmount(oldValue, currency),
      percent: (oldValue) => `${formatAmount(Number(oldValue))}%`,
      policy: (oldValue) => t(`selectFiledValues.${oldValue}`),
      repaymentPlan: (oldValue) => t(`selectFiledValues.${oldValue}`),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      default: (oldValue) => oldValue,
    }),
    [currency],
  );

  return (
    <div
      style={{
        gridTemplateColumns: `${widthLeft}px ${title ? '1fr' : 'auto'}`,
        textAlign: align || 'inherit',
        ...wrapStyle,
      }}
      className={styles.item}
    >
      {title && type !== TypeEnum.CAPTION && <div style={titleStyle} className={styles.title}>{`${t(title)}:`}</div>}
      {content && content}
      {value !== undefined && type === TypeEnum.TITLE && (
        <div style={additionalStyle} className={`${styles.typeAo} ${styles[`${color}TypeAo`]}`}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {t(MAP_AO_TYPE_TO_STRING[value])}
        </div>
      )}
      {value !== undefined && type === TypeEnum.TEXT && (
        <div style={{ ...additionalStyle, wordBreak: 'break-word' }}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {getFormattedValue[valueFormatType](value)}
          {Boolean(rightContent) && rightContent}
        </div>
      )}
      {title && type === TypeEnum.CAPTION && <div className={styles.title}>{`${t(title)}`}</div>}
      {fileProps && type === TypeEnum.FILE && fileProps?.link && (
        <span className={fileProps.isFileStile ? styles.contentFile : undefined}>
          {fileProps.isFileStile && <img src={clipSvg} alt="clip" />}
          <button
            style={additionalStyle}
            className={styles.buttonDownload}
            type="button"
            onClick={() => {
              if (isLoading || !fileProps.link) return;
              download(fileProps.link).then();
            }}
          >
            {fileProps.name}
          </button>
        </span>
      )}
      {fileProps && type === TypeEnum.HREF && fileProps?.link && (
        <span className={fileProps.isFileStile ? styles.contentFile : undefined}>
          {fileProps.isFileStile && <img src={clipSvg} alt="clip" />}
          <button
            style={additionalStyle}
            className={styles.buttonDownload}
            type="button"
            onClick={() => {
              if (isLoading || !fileProps.link) return;
              history.push(fileProps.link);
            }}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {value && <div>{getFormattedValue[valueFormatType](value)}</div>}
          </button>
        </span>
      )}
    </div>
  );
};

export default Item;

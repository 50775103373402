import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import lodash from 'lodash';

import useActivationOrder, { AoTypeEnum } from 'hooks/api/useActivationOrder';
import { getCurrencyWithAmount } from 'helpers/formatters';
import MenuItemAo from 'components/MenuItemAo';
import Title from 'components/common/Title';
import urls from 'constants/urls';
import getAccountsByOrderId from 'queries/getAccountsByOrderId';
import { Account } from 'types/ActivationOrderTypes';

import AuditLog from './AuditLog/AuditLog';
import Calculations from './Calculations';
import General from './General';

const wrapContainer = {
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '60px calc(100% - 60px)',
};

interface AOEditProps {
  setFixLayoutType: () => void;
  setFlexibleLimitType: () => void;
}

const AOEdit = ({ setFixLayoutType, setFlexibleLimitType }: AOEditProps) => {
  const {
    getActivationOrder,
    activationOrderData,
    deleteActivationOrder,
    clearActivationOrderData,
    getScheduleForFutureReceivables,
    clearScheduleForFutureReceivables,
    schedulesFutureReceivablesData: { total_amount },
  } = useActivationOrder();
  const [accounts, setAccounts] = useState<Account[]>([]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { activationOrderId } = useParams();
  const history = useHistory();
  const type = activationOrderData?.ao_type;
  const [order, setOrder] = useState(0);
  const isUseQueryType = type === AoTypeEnum.FUTURE_RECEIVABLES || type === AoTypeEnum.AVAILABLE_RECEIVABLES;

  const title = `AO ${activationOrderData.name} ${activationOrderData.company_name} ${
    activationOrderData.currency
      ? getCurrencyWithAmount(total_amount || activationOrderData.amount, activationOrderData.currency)
      : ''
  }`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updateAccounts = (id) =>
    getAccountsByOrderId(id)
      .then(setAccounts)
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Error loading activationOrderId', error));

  useEffect(() => {
    if (activationOrderId) {
      getActivationOrder({ id: activationOrderId, type: isUseQueryType ? type : undefined });
    }

    updateAccounts(activationOrderId);
  }, [activationOrderId, isUseQueryType]);

  useEffect(
    () => () => {
      clearActivationOrderData();
    },
    [],
  );

  useEffect(() => {
    if (type === AoTypeEnum.FUTURE_RECEIVABLES) {
      getScheduleForFutureReceivables({ id: activationOrderId });
    }

    return () => {
      clearScheduleForFutureReceivables();
    };
  }, [type, activationOrderId]);

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        {!lodash.isEmpty(activationOrderData) && (
          <Title
            clickCallback={() => {
              history.push(urls.getActivationOrder());
            }}
            text={title}
            aoType={type}
            isShowBackButton
            status={activationOrderData.status}
          />
        )}
        <MenuItemAo
          setValue={(value) => {
            if (type === AoTypeEnum.AVAILABLE_RECEIVABLES && value === 1) return;

            if (type === AoTypeEnum.FUTURE_RECEIVABLES) return;

            value === 2 ? setFlexibleLimitType() : setFixLayoutType();
            setOrder(value);
          }}
          order={order}
          styleTabs={{ marginTop: 33, marginBottom: 20 }}
          items={['General', 'Calculations', 'Audit log']}
        />
      </div>
      {order === 2 && !lodash.isEmpty(activationOrderData) && (
        <div style={wrapContainer}>
          <AuditLog ao={activationOrderData} />
        </div>
      )}
      {order === 1 && <Calculations />}
      {order === 0 && !lodash.isEmpty(activationOrderData) && (
        <General
          type={type}
          activationOrderData={activationOrderData}
          deleteActivationOrder={deleteActivationOrder}
          title={title}
          accounts={accounts}
          updateAccounts={updateAccounts}
        />
      )}
    </>
  );
};

export default AOEdit;

import React, { useEffect } from 'react';

import CompanyView from 'components/forms/CompanyForm/View';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CompanyEditScreen: any = ({ requestParams }) => {
  useEffect(() => {
    updateTitle('Company');
  }, []);

  return (
    <MainContent>
      <CompanyView requestParams={requestParams} />
    </MainContent>
  );
};

export default CompanyEditScreen;

import React, { useEffect } from 'react';

import CompanyForm from 'components/forms/CompanyForm';
import { updateTitle } from 'helpers/common';
import MainContent from 'components/MainContent';

const CompanyCreateScreen = () => {
  useEffect(() => {
    updateTitle('Create New Company');
  }, []);

  return (
    <MainContent type="form">
      <CompanyForm />
    </MainContent>
  );
};

export default CompanyCreateScreen;

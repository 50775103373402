import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';

import Button from 'components/uiKit/Button';
import PopUpLayout from 'helpers/PopUpLayout';
import { statusRevenueUploadFileDataType } from 'hooks/api/useCompany';

import styles from './styles.module.scss';

const closeSvg = require('assets/img/closeRevenue.svg').default;

interface PropsT {
  uploadStatus: statusRevenueUploadFileDataType;
  callback: (state: 'normal' | 'withErrors') => void;
  onClose: () => void;
}
const UpdatedAndProcessedFilePopUp: FC<PropsT> = ({ callback, uploadStatus, onClose }) => {
  const { t } = useTranslation();
  const isErrorList = uploadStatus && !lodash.isEmpty(uploadStatus.errorsByTypes);

  return (
    <PopUpLayout
      style={{
        padding: 40,
        backgroundColor: '#F7F8FA',
        borderRadius: 16,
        position: 'relative',
        width: 616,
        minHeight: 329,
      }}
      open={!!uploadStatus}
      onClose={onClose}
    >
      <img
        onClick={onClose}
        style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }}
        src={closeSvg}
        alt="Close"
      />
      <div className={styles.titlePopUp}>{t('forms.companies.agreements.fileUploadedAndProcessed')}</div>
      <div className={styles.tableWrap}>
        <div
          className={styles.table}
          style={{ height: 40, boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.05)', color: '#838998' }}
        >
          <div>{t('forms.companies.agreements.rowsProcessed')}</div>
          <div>{t('forms.companies.agreements.rowsWritten')}</div>
          <div>{t('forms.companies.agreements.errorRowsFound')}</div>
        </div>
        {uploadStatus && (
          <div className={styles.table} style={{ height: 56, color: '#29292C' }}>
            <div>{`${uploadStatus.rowsProcessed}/${uploadStatus.rowsTotal}`}</div>
            <div>{`${uploadStatus.rowsSuccessful}/${uploadStatus.rowsTotal}`}</div>
            <div>
              <span style={{ color: '#EC3E72' }}>{`${uploadStatus.rowsErroneous}`}</span>
              {`/${uploadStatus.rowsTotal}`}
            </div>
          </div>
        )}
      </div>
      {uploadStatus && isErrorList && (
        <div style={{ marginTop: 40, color: '#29292C' }}>
          <div>{t('forms.companies.agreements.errorsFound')}</div>
          {Object.entries(uploadStatus.errorsByTypes).map(([key, value]) => {
            const text = value.length === 1 ? 'Row' : 'Rows';

            return (
              <div style={{ color: '#EC3E72', marginTop: 12 }}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {`${text}: ${value.map((item) => item)}
                ${key}`}
              </div>
            );
          })}
        </div>
      )}
      {!isErrorList && (
        <Button
          color="gray"
          style={{ height: 60, width: 86, marginTop: 40 }}
          onClick={() => {
            callback('normal');
          }}
        >
          {t('forms.companies.agreements.gotIt')}
        </Button>
      )}
      {isErrorList && (
        <div style={{ display: 'flex' }}>
          <Button
            variant="fill"
            color="grey_light"
            style={{ height: 60, width: 209, marginTop: 40, marginRight: 40 }}
            onClick={() => {
              callback('withErrors');
            }}
          >
            {t('forms.companies.agreements.reloadRevenueReport')}
          </Button>
          <Button
            color="gray"
            style={{ height: 60, width: 86, marginTop: 40 }}
            onClick={() => {
              onClose();
            }}
          >
            {t('forms.companies.agreements.cancel')}
          </Button>
        </div>
      )}
    </PopUpLayout>
  );
};

export default UpdatedAndProcessedFilePopUp;

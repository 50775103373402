import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type transactionTargetsReportsType = {
  companyId: string;
  currency: string;
  showDeleted: boolean;
  errorCallback: Function;
  resultKey?: string;
};

export default ({
  companyId,
  currency,
  errorCallback,
  resultKey = 'transactionTargetsReportsData',
}: transactionTargetsReportsType) =>
  requestAsync({
    url: endpoints.getTransactionTargetsReportsUrl(companyId, currency),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getTransactionTargetsReportsUrl(companyId, currency),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

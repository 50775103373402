import {
  initialTransactionFormDataType,
  conversionTransactionErrorType,
  conversionTransactionFormDataType,
} from 'types/TransactionFormTypes';

import { dateRangeValidator } from './dt';

export const validateTransaction = (data: initialTransactionFormDataType) => {
  const {
    amount,
    company_id,
    currency,
    date,
    type,
    adjusting_transaction,
    file_name,
    file_data,
    targets,
    reference_id,
  } = data;
  const errors = {
    amount: !amount || amount === '0',
    company: !company_id,
    currency: !currency,
    data: !date,
    type: !type,
    file: !file_name,
    reference_id: !reference_id,
  };

  if (!amount || amount === '0' || !company_id || !currency || !data || !type || !date) {
    return { validated: false, errors };
  }

  if (adjusting_transaction) {
    errors.reference_id = !reference_id;
    errors.file = !file_name || !file_data;

    return { validated: !(errors.reference_id || errors.file), errors };
  }

  if (targets.length) {
    const response = { validated: true, errors };

    response.validated = targets.every((target) => {
      const isWithoutTargets = target.target_type !== 'BALANCE_PAYMENT' && target.target_type !== 'TRANSIT';

      if (
        !target.amount ||
        (!target.order_id && isWithoutTargets) ||
        !target.target_type ||
        (!target.order_currency && !target.currency && isWithoutTargets)
      ) {
        return false;
      }

      if (target.order_currency !== currency && isWithoutTargets) {
        if (!target.rate || !target.tx_currency_amount) {
          return false;
        }
      }

      if (target.target_type === 'INTEREST_REPAY' || target.target_type === 'PENALTY_REPAY') {
        if (target.repayment_date) {
          const dError = dateRangeValidator({ date: target.repayment_date, min: '1900-01-01', max: '2099-12-31' });

          return dError.validate;
        }
      }

      return true;
    });

    return response;
  }

  return { validated: true, errors };
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const validateConversionTransaction = (data: conversionTransactionFormDataType, requiredText) => {
  const { date, company_id, amount, currency, targets } = data;
  const errors: conversionTransactionErrorType = {
    date: { status: !date, text: requiredText },
    company_id: { status: !company_id, text: requiredText },
    amount: { status: !amount, text: requiredText },
    currency: { status: !currency, text: requiredText },
    target: {
      amount: { status: !targets[0].amount, text: requiredText },
      currency: { status: !targets[0].currency, text: requiredText },
      rate: { status: !targets[0].rate, text: requiredText },
    },
  };

  if (
    !date ||
    !company_id ||
    !amount ||
    !currency ||
    !errors.target.amount ||
    !errors.target.currency ||
    !errors.target.rate
  ) {
    return { validated: false, errors };
  }

  return { validated: true, errors };
};

import React, { useEffect, useState } from 'react';

import AOEdit from 'components/forms/ActivationOrderForm/AOEdit';
import { updateTitle } from 'helpers/common';
import MainContent, { MainContentProps } from 'components/MainContent';

const AOEditScreen = () => {
  const [layoutType, setLayoutType] = useState<MainContentProps['type']>('fix');

  const setFixLayoutType = () => setLayoutType('fix');
  const setFlexibleLimitType = () => setLayoutType('flexibleLimit');

  useEffect(() => {
    updateTitle('Activation Order');
  }, []);

  return (
    <MainContent type={layoutType}>
      <AOEdit setFixLayoutType={setFixLayoutType} setFlexibleLimitType={setFlexibleLimitType} />
    </MainContent>
  );
};

export default AOEditScreen;

import { CompanyTypeREPLACE, CompanyStatus, CompanyForm } from 'types/CompanyTypes';

export const COMPANY_TYPE_ARRAY = [
  CompanyTypeREPLACE.CLIENT,
  CompanyTypeREPLACE.REVENUE_SOURCE,
  CompanyTypeREPLACE.THIRD_PARTY,
];

const COMPANY_TYPE_NAME = {
  [CompanyTypeREPLACE.CLIENT]: 'Client',
  [CompanyTypeREPLACE.REVENUE_SOURCE]: 'Revenue source',
  [CompanyTypeREPLACE.THIRD_PARTY]: 'Third party',
};

export const COMPANY_TYPE_OPTIONS = COMPANY_TYPE_ARRAY.map((value) => ({
  label: COMPANY_TYPE_NAME[value],
  value,
}));

export const COMPANY_STATUS_ARRAY = [
  CompanyStatus.NEW,
  CompanyStatus.IN_REVIEW,
  CompanyStatus.ACTIVE,
  CompanyStatus.REJECTED,
  CompanyStatus.BLOCKED,
  CompanyStatus.ARCHIVE,
  CompanyStatus.DELETED,
];

export const COMPANY_STATUS_NAME = {
  [CompanyStatus.NEW]: 'New',
  [CompanyStatus.IN_REVIEW]: 'In review',
  [CompanyStatus.ACTIVE]: 'Active',
  [CompanyStatus.REJECTED]: 'Rejected',
  [CompanyStatus.BLOCKED]: 'Blocked',
  [CompanyStatus.ARCHIVE]: 'Archive',
  [CompanyStatus.DELETED]: 'Deleted',
};

export const COMPANY_STATUS_OPTIONS = COMPANY_STATUS_ARRAY.map((value) => ({
  label: COMPANY_STATUS_NAME[value],
  value,
}));

export const COMPANY_FORM_ARRAY = [CompanyForm.SOLE_PROPRIETOR, CompanyForm.PARTNERSHIP, CompanyForm.CORPORATION];

export const COMPANY_FORM_NAME = {
  [CompanyForm.SOLE_PROPRIETOR]: 'Sole Proprietor',
  [CompanyForm.PARTNERSHIP]: 'Partnership',
  [CompanyForm.CORPORATION]: 'Corporation',
};

export const COMPANY_FORM_OPTIONS = COMPANY_FORM_ARRAY.map((value) => ({
  label: COMPANY_FORM_NAME[value],
  value,
}));

import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate, formatAOStatus, getAmountWithCorrectSign } from 'helpers/formatters';
import { alignRight, alignLeft } from 'consts/dict';
import { typeSortEnum } from 'components/SortTable';
import urls from 'constants/urls';

import styles from './styles.module.scss';

const alignCommon = alignRight;

export enum NameKeyCommissionReport {
  COMPANY_NAME = 'company_name',
  NAME = 'name',
  PURCHASE_DATE = 'purchase_date',
  LOAN_BODY = 'loan_body',
  REPAYMENT_DATE = 'repayment_date',
  REPAYMENT_NEXT_DATE = 'next_payment_date',
  NEXT_PAYMENT = 'next_payment',
  INTERESTS = 'interests',
  PENALTIES = 'penalties',
  BANK_FEE = 'bank_fee',
  STATUS = 'status',
  COMMISSION_ACCRUED = 'commission_total',
  DEFAULT_INTEREST_ACCRUED = 'default_interest_total',
  PURCHASE_PRICE = 'amount',
}

export const dataCommissionReport = [
  {
    key: NameKeyCommissionReport.COMPANY_NAME,
    translate: 'contentHeader.client',
    align: alignLeft,
    typeSort: typeSortEnum.ALPHABET,
  },
  {
    key: NameKeyCommissionReport.NAME,
    translate: 'tables.ao',
    align: alignLeft,
  },
  {
    key: NameKeyCommissionReport.PURCHASE_DATE,
    translate: 'tables.purchaseDate',
    align: alignRight,
  },
  {
    key: NameKeyCommissionReport.PURCHASE_PRICE,
    translate: 'tables.purchasePrice',
    align: alignRight,
  },
  {
    key: NameKeyCommissionReport.REPAYMENT_DATE,
    translate: 'tables.repaymentDate',
    align: alignRight,
  },
  {
    key: NameKeyCommissionReport.LOAN_BODY,
    translate: 'tables.principalRepaymentAmount',
    align: alignRight,
  },
  {
    key: NameKeyCommissionReport.COMMISSION_ACCRUED,
    translate: 'tables.commissionAccrued',
    align: alignRight,
    isHiddenSort: true,
  },
  {
    key: NameKeyCommissionReport.INTERESTS,
    translate: 'tables.commissionForToday',
    align: alignRight,
    dateData: true,
    isHiddenSort: true,
  },
  {
    key: NameKeyCommissionReport.DEFAULT_INTEREST_ACCRUED,
    translate: 'tables.defaultInterestAccrued',
    align: alignRight,
    isHiddenSort: true,
  },
  {
    key: NameKeyCommissionReport.PENALTIES,
    translate: 'tables.commissionReport.defaultInterest',
    align: alignRight,
    dateData: true,
    isHiddenSort: true,
  },
  {
    key: NameKeyCommissionReport.STATUS,
    translate: 'tables.status',
    align: alignRight,
    func: formatAOStatus,
    isHiddenSort: true,
  },
];
export const dataCommissionReportTotal = [
  {
    header: { translate: 'contentHeader.grandTotal', align: alignCommon },
    body: { key: NameKeyCommissionReport.COMPANY_NAME, align: alignCommon },
  },
  {
    header: { translate: 'tables.ao', align: alignCommon },
    body: { key: NameKeyCommissionReport.NAME, align: alignCommon },
  },
];
export const getKeyCommissionReporting = (
  key: NameKeyCommissionReport,
  value: string | null,
  currency: string,
  aoId: string,
) => {
  switch (key) {
    case NameKeyCommissionReport.COMPANY_NAME:
      return value;
    case NameKeyCommissionReport.NAME:
      return (
        <Link className={styles.link} to={`${urls.getActivationOrder()}/${aoId}`}>
          {value}
        </Link>
      );
    case NameKeyCommissionReport.PURCHASE_DATE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return formatDate(value);
    case NameKeyCommissionReport.REPAYMENT_DATE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return formatDate(value);
    case NameKeyCommissionReport.REPAYMENT_NEXT_DATE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return formatDate(value);
    case NameKeyCommissionReport.STATUS:
      return formatAOStatus(value);
    case NameKeyCommissionReport.LOAN_BODY:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return getAmountWithCorrectSign(value || 0, currency);
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return getAmountWithCorrectSign(value || null, currency);
  }
};

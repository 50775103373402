import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getSystemSettings from 'queries/systemSetting/getSystemSettings';
import editSystemSettings from 'queries/systemSetting/editSystemSettings';
import activationOrdersRecalculate from 'queries/systemSetting/activationOrdersRecalculate';
import getExchangeRates from 'queries/systemSetting/getExchangeRates';
import updateAggregationTable from 'queries/systemSetting/updateAggregationTable';
import getAggregationStatus from 'queries/systemSetting/getAggregationStatus';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { objectSelector } from './selectors';

const useSystemSetting = () => {
  const selector = useCallback(
    (state) => ({
      systemSettingsData: objectSelector(state, 'systemSettingsData'),
      systemSettingsEditData: objectSelector(state, 'systemSettingsEditData'),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getSystemSettings,
      editSystemSettings,
      activationOrdersRecalculate,
      getExchangeRates,
      updateAggregationTable,
      getAggregationStatus,
    }),
    [],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
  };
};

export default useSystemSetting;

import { initialObjTransactionsType } from 'components/tables/TransactionsTable/ViewsForm/data';
import { KeyTransactions } from 'components/tables/TransactionsTable/data';

const initialOutgoingRequiredKey = [
  KeyTransactions.TYPE,
  KeyTransactions.CATEGORY,
  KeyTransactions.VALUE_DATE_TIME,
  KeyTransactions.DOCUMENT_NUMBER,
  KeyTransactions.DOCUMENT_ID,
  KeyTransactions.DOCUMENT_TYPE,
  KeyTransactions.CURRENCY,
  KeyTransactions.AMOUNT,
  KeyTransactions.RECIPIENT,
  KeyTransactions.DETAILS,
  KeyTransactions.COMMENT,
];

export const requiredOutgoingKeys = (transactionsFormData: initialObjTransactionsType) => {
  let initial = [...initialOutgoingRequiredKey, KeyTransactions.DOCUMENT_AO, KeyTransactions.DOCUMENT_REQUEST];

  if (!transactionsFormData.checkbox) {
    initial = [...initial, KeyTransactions.SENDER];
  }

  if (transactionsFormData[KeyTransactions.DOCUMENT_AO] && initial.indexOf(KeyTransactions.DOCUMENT_REQUEST) !== -1) {
    initial.splice(initial.indexOf(KeyTransactions.DOCUMENT_REQUEST), 1);
  }

  if (transactionsFormData[KeyTransactions.DOCUMENT_REQUEST] && initial.indexOf(KeyTransactions.DOCUMENT_AO) !== -1) {
    initial.splice(initial.indexOf(KeyTransactions.DOCUMENT_AO), 1);
  }

  return initial;
};

export const errorsOutgoing = {
  [KeyTransactions.ROOT]: [
    { key: KeyTransactions.VALUE_DATE_TIME, parameters: { required: true } },
    { key: KeyTransactions.CATEGORY, parameters: { required: true } },
    { key: KeyTransactions.TYPE, parameters: { required: true } },
    { key: KeyTransactions.CURRENCY, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT, parameters: { required: true } },
    // { key: KeyTransactions.RECIPIENT, parameters: { required: true } },
    { key: KeyTransactions.SENDER, parameters: { required: true } },
  ],
};

export type errorsOutgoingType = typeof errorsOutgoing;

import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import updateUtils from 'actions/updateUtils';
import getDocuments, { documentsDataType } from 'queries/universal/getDocuments';
import getDocumentsById, { documentsByIdDataType } from 'queries/universal/getDocumentsById';
import getDocumentsByIdV2 from 'queries/universal/getDocumentsByIdV2';
import getSyncPds, { getSyncPdsType } from 'queries/purchaseRequest/getSyncPds';
import createPlatform, { createPlatformDataType } from 'queries/systemSetting/createPlatform';
import createAvailableAdvancePlatform from 'queries/systemSetting/createAvailableAdvancePlatform';
import editPlatform, { editPlatformDataType } from 'queries/systemSetting/editPlatform';
import deletePlatform from 'queries/systemSetting/deletePlatform';
import connectPlatform, { connectPlatformType } from 'queries/revenue/connectPlatform';
import { StatusEnumTitle } from 'components/common/Status';

import useActionsWithFetchingState from '../useActionsWithFetchingState';

import { arraySelector, objectSelector, utilsSelector } from './selectors';

type platformTableParamsType = {
  id: string;
};

const initialPlatformTableParams: platformTableParamsType = {
  id: '',
};

export enum calTypeEnum {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}
export interface PlatformDataType {
  name?: string;
  logo?: string;
  activationCutoffPeriod?: number;
  advanceCutoffPeriod?: number;
  calType?: string;
  netDay?: number;
  code?: string;
  syncType?: calTypeEnum;
  status?: StatusEnumTitle;
  createdAt?: Date;
  updatedAt?: Date;
  id?: string;
}

export interface usePlatformType {
  platformData?: PlatformDataType[];
  getDocuments?: (data: documentsDataType) => void;
  getDocumentsById?: (data: documentsByIdDataType) => void;
  platformDataById?: PlatformDataType;
  createPlatform?: (data: createPlatformDataType) => void;
  editPlatform?: (data: editPlatformDataType) => void;
  getSyncPds?: (data: getSyncPdsType) => void;
  connectPlatform?: (data: connectPlatformType) => void;
}

const usePlatform = () => {
  const selector = useCallback(
    (state) => ({
      platformDataById: objectSelector(state, 'platformDataById'),
      platformsData: utilsSelector(state, 'platformsData'),
      platformsDataV2: arraySelector(state, 'platformsDataV2'),
      platformsTableDataV2: arraySelector(state, 'platformsTableDataV2'),
      platformsTableDataV3: arraySelector(state, 'platformsTableDataV3'),
      platformData: arraySelector(state, 'platformData'),
      createPlatformData: objectSelector(state, 'createPlatformData'),
      createAvailableAdvancePlatform: objectSelector(state, 'createAvailableAdvancePlatform'),
      editPlatformData: objectSelector(state, 'editPlatformData'),
      deletePlatformData: objectSelector(state, 'deletePlatformData'),
      countriesData: arraySelector(state, 'countriesData'),
      platformTableParams: utilsSelector(state, 'platformTableParams', initialPlatformTableParams),
    }),
    [],
  );

  const dispatch = useDispatch();
  const data = useSelector(selector);

  const actionCreators = useMemo(
    () => ({
      getDocuments,
      getDocumentsById,
      getDocumentsByIdV2,
      createPlatform,
      createAvailableAdvancePlatform,
      editPlatform,
      deletePlatform,
      getSyncPds,
      connectPlatform,
    }),
    [],
  );

  const platformTableActions = useMemo(
    () =>
      bindActionCreators(
        {
          setPlatformTableParams: (platformTableParams: platformTableParamsType) =>
            updateUtils({
              platformTableParams,
            }),
        },
        dispatch,
      ),
    [dispatch],
  );

  const [actions, isFetchingState] = useActionsWithFetchingState(actionCreators, dispatch);

  return {
    ...data,
    ...actions,
    ...isFetchingState,
    ...platformTableActions,
  };
};

export default usePlatform;

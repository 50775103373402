import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';
import { ITransactionParamsType } from 'types/TransactionFormTypes';

interface transactionDataV2 extends ITransactionParamsType {
  errorCallback: Function;
  successCallback?: Function;
  resultKey: string;
}

export default ({
  company_id = undefined,
  currency = undefined,
  show_deleted = false,
  status = undefined,
  date_from = undefined,
  date_to = undefined,
  company_name = '',
  tx_type = undefined,
  contract_number = undefined,
  order_id = undefined,
  page = 0,
  limit = 25,
  errorCallback,
  successCallback,
  resultKey = 'transactionsDataV2',
}: transactionDataV2) =>
  requestAsync({
    url: endpoints.getTransactionsUrlV2({
      company_id,
      currency,
      show_deleted,
      tx_type,
      status,
      date_from,
      date_to,
      company_name,
      contract_number,
      limit,
      page,
      order_id,
    }),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getTransactionsUrlV2({
      company_id,
      currency,
      show_deleted,
      tx_type,
      status,
      date_from,
      date_to,
      company_name,
      contract_number,
      limit,
      page,
      order_id,
    }),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
      successCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { useEffect } from 'react';

import ActivationTableHeader from 'components/tables/ActivationTable/Header';
import ActivationTable from 'components/tables/ActivationTable';
import { updateTitle } from 'helpers/common';

import MainContent from 'components/MainContent';

const ActivationListScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Activation Orders');
  });

  return (
    <MainContent type="flexibleLimit" flexColumn>
      <ActivationTableHeader requestParams={requestParams} />
      <ActivationTable />
    </MainContent>
  );
};

export default ActivationListScreen;

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

interface PDSAccountsRequestParams {
  store_id?: string;
  store_code?: string;
  company_id?: string;
  show_deleted?: string;
  show_with_empty_currency?: string;
  page?: string;
  size?: string;
}

export interface PdsAccountsParams {
  resultKey?: string;
  successCallback?: Function;
  errorCallback?: Function;
  params?: PDSAccountsRequestParams;
}

export default ({ resultKey = 'pdsAccounts', successCallback, errorCallback, params }: PdsAccountsParams) =>
  requestAsync({
    url: endpoints.getPdsAccountsUrl(params),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getPdsAccountsUrl(params),
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    options: {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

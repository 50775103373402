import { CompanyBackend, Company, CompanyKeyEnum } from 'types/CompanyTypes';

export const getFrontendCompany = (company: CompanyBackend): Company => {
  const signer =
    Array.isArray(company[CompanyKeyEnum.CONTACTS]) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    company[CompanyKeyEnum.CONTACTS].find((contact) => contact.type === 'signer');

  const result: Company = {
    // general
    [CompanyKeyEnum.ID]: company[CompanyKeyEnum.ID],
    ...(company[CompanyKeyEnum.TYPE] && { [CompanyKeyEnum.TYPE]: company[CompanyKeyEnum.TYPE] }),
    ...(company[CompanyKeyEnum.NAME] && { [CompanyKeyEnum.NAME]: company[CompanyKeyEnum.NAME] }),
    ...(company[CompanyKeyEnum.STATUS] && { [CompanyKeyEnum.STATUS]: company[CompanyKeyEnum.STATUS] }),
    ...(company?.details?.[CompanyKeyEnum.LEGAL_NAME] && {
      [CompanyKeyEnum.LEGAL_NAME]: company.details[CompanyKeyEnum.LEGAL_NAME],
    }),
    ...(company?.details?.[CompanyKeyEnum.REGISTRATION_NUMBER] && {
      [CompanyKeyEnum.REGISTRATION_NUMBER]: company.details[CompanyKeyEnum.REGISTRATION_NUMBER],
    }),
    ...(company?.details?.[CompanyKeyEnum.TAX_NUMBER] && {
      [CompanyKeyEnum.TAX_NUMBER]: company.details[CompanyKeyEnum.TAX_NUMBER],
    }),
    ...(company?.details?.[CompanyKeyEnum.WEBSITE] && {
      [CompanyKeyEnum.WEBSITE]: company.details[CompanyKeyEnum.WEBSITE] as string,
    }),
    ...(company?.details?.[CompanyKeyEnum.EMAIL] && { [CompanyKeyEnum.EMAIL]: company.details[CompanyKeyEnum.EMAIL] }),
    ...(company?.details?.[CompanyKeyEnum.PHONE] && { [CompanyKeyEnum.PHONE]: company.details[CompanyKeyEnum.PHONE] }),
    ...(company?.details?.[CompanyKeyEnum.COUNTRY] && {
      [CompanyKeyEnum.COUNTRY]: company.details[CompanyKeyEnum.COUNTRY],
    }),
    ...(company?.details?.[CompanyKeyEnum.ZIP] && { [CompanyKeyEnum.ZIP]: company.details[CompanyKeyEnum.ZIP] }),
    ...(company?.details?.[CompanyKeyEnum.STATE] && { [CompanyKeyEnum.STATE]: company.details[CompanyKeyEnum.STATE] }),
    ...(company?.details?.[CompanyKeyEnum.CITY] && { [CompanyKeyEnum.CITY]: company.details[CompanyKeyEnum.CITY] }),
    ...(company?.[CompanyKeyEnum.SETTINGS]?.[CompanyKeyEnum.OPERATING_CURRENCY] && {
      [CompanyKeyEnum.OPERATING_CURRENCY]: company[CompanyKeyEnum.SETTINGS]?.[CompanyKeyEnum.OPERATING_CURRENCY],
    }),
    ...(company?.details?.[CompanyKeyEnum.ADDRESS] && {
      [CompanyKeyEnum.ADDRESS]: company.details[CompanyKeyEnum.ADDRESS],
    }),
    ...(company?.details?.[CompanyKeyEnum.COMPANY_FORM] && {
      [CompanyKeyEnum.COMPANY_FORM]: company.details[CompanyKeyEnum.COMPANY_FORM],
    }),
    // Contact
    ...(Array.isArray(company[CompanyKeyEnum.CONTACTS])
      ? {
          [CompanyKeyEnum.CONTACTS]: company?.[CompanyKeyEnum.CONTACTS]
            ?.filter((contact) => contact.type === 'contact')
            .map((contact) => ({
              ...(contact?.[CompanyKeyEnum.ID] && {
                [CompanyKeyEnum.ID]: contact[CompanyKeyEnum.ID],
              }),
              ...(contact?.[CompanyKeyEnum.FULL_NAME] && {
                [CompanyKeyEnum.FULL_NAME]: contact[CompanyKeyEnum.FULL_NAME],
              }),
              ...(contact?.[CompanyKeyEnum.POSITION] && {
                [CompanyKeyEnum.POSITION]: contact[CompanyKeyEnum.POSITION],
              }),
              ...(contact?.[CompanyKeyEnum.EMAIL] && { [CompanyKeyEnum.EMAIL]: contact[CompanyKeyEnum.EMAIL] }),
              ...(contact?.[CompanyKeyEnum.CONTACT_PHONE] && {
                [CompanyKeyEnum.CONTACT_PHONE]: contact[CompanyKeyEnum.CONTACT_PHONE],
              }),
            })),
        }
      : {
          [CompanyKeyEnum.CONTACTS]: [
            {
              [CompanyKeyEnum.FULL_NAME]: '',
              [CompanyKeyEnum.POSITION]: '',
              [CompanyKeyEnum.EMAIL]: '',
              [CompanyKeyEnum.CONTACT_PHONE]: '',
            },
          ],
        }),
    ...(signer && {
      [CompanyKeyEnum.SIGNER_FULL_NAME]: signer[CompanyKeyEnum.FULL_NAME],
      [CompanyKeyEnum.SIGNER_EMAIL]: signer[CompanyKeyEnum.EMAIL],
      [CompanyKeyEnum.SIGNER_POSITION]: signer[CompanyKeyEnum.POSITION],
      [CompanyKeyEnum.SIGNER_PHONE]: signer[CompanyKeyEnum.CONTACT_PHONE],
      [CompanyKeyEnum.SIGNER_ID]: signer[CompanyKeyEnum.ID],
    }),
    // BankAccount
    ...(Array.isArray(company[CompanyKeyEnum.BANK_DETAILS]) && {
      [CompanyKeyEnum.BANK_DETAILS]: company?.[CompanyKeyEnum.BANK_DETAILS]?.map((bankAccount) => ({
        ...(bankAccount?.[CompanyKeyEnum.ID] && {
          [CompanyKeyEnum.ID]: bankAccount[CompanyKeyEnum.ID],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CURRENCY] && {
          [CompanyKeyEnum.CURRENCY]: bankAccount[CompanyKeyEnum.CURRENCY],
        }),
        ...(bankAccount?.[CompanyKeyEnum.BANK_NAME] && {
          [CompanyKeyEnum.BANK_NAME]: bankAccount[CompanyKeyEnum.BANK_NAME],
        }),
        ...(bankAccount?.[CompanyKeyEnum.SWIFT] && { [CompanyKeyEnum.SWIFT]: bankAccount[CompanyKeyEnum.SWIFT] }),
        ...(bankAccount?.[CompanyKeyEnum.ACCOUNT] && { [CompanyKeyEnum.ACCOUNT]: bankAccount[CompanyKeyEnum.ACCOUNT] }),
        ...(bankAccount?.[CompanyKeyEnum.BANK_ADDRESS] && {
          [CompanyKeyEnum.BANK_ADDRESS]: bankAccount[CompanyKeyEnum.BANK_ADDRESS],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_ACCOUNT] && {
          [CompanyKeyEnum.CORRESPONDENT_ACCOUNT]: bankAccount[CompanyKeyEnum.CORRESPONDENT_ACCOUNT],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_BANK_NAME] && {
          [CompanyKeyEnum.CORRESPONDENT_BANK_NAME]: bankAccount[CompanyKeyEnum.CORRESPONDENT_BANK_NAME],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_SWIFT] && {
          [CompanyKeyEnum.CORRESPONDENT_SWIFT]: bankAccount[CompanyKeyEnum.CORRESPONDENT_SWIFT],
        }),
        [CompanyKeyEnum.MASTER]: Boolean(bankAccount[CompanyKeyEnum.MASTER]),
        [CompanyKeyEnum.SPECIAL_INSTRUCTIONS]: bankAccount[CompanyKeyEnum.SPECIAL_INSTRUCTIONS],
      })),
    }),
  };

  return result;
};

export const getBackendCompany = (company: Company): Partial<CompanyBackend> => {
  const result: Partial<CompanyBackend> = {
    // general
    ...(company[CompanyKeyEnum.ID] && {
      [CompanyKeyEnum.ID]: company[CompanyKeyEnum.ID],
    }),
    ...(company[CompanyKeyEnum.TYPE] && {
      [CompanyKeyEnum.TYPE]: company[CompanyKeyEnum.TYPE],
    }),
    ...(company[CompanyKeyEnum.NAME] && {
      [CompanyKeyEnum.NAME]: company[CompanyKeyEnum.NAME],
    }),
    ...(company[CompanyKeyEnum.STATUS] && {
      [CompanyKeyEnum.STATUS]: company[CompanyKeyEnum.STATUS],
    }),
    ...(company[CompanyKeyEnum.OPERATING_CURRENCY] && {
      [CompanyKeyEnum.SETTINGS]: { [CompanyKeyEnum.OPERATING_CURRENCY]: company[CompanyKeyEnum.OPERATING_CURRENCY] },
    }),
    details: {
      ...(company[CompanyKeyEnum.LEGAL_NAME] && {
        [CompanyKeyEnum.LEGAL_NAME]: company[CompanyKeyEnum.LEGAL_NAME],
      }),
      ...(company[CompanyKeyEnum.COMPANY_FORM] && {
        [CompanyKeyEnum.COMPANY_FORM]: company[CompanyKeyEnum.COMPANY_FORM],
      }),
      ...(company[CompanyKeyEnum.REGISTRATION_NUMBER] && {
        [CompanyKeyEnum.REGISTRATION_NUMBER]: company[CompanyKeyEnum.REGISTRATION_NUMBER],
      }),
      ...(company[CompanyKeyEnum.TAX_NUMBER] && {
        [CompanyKeyEnum.TAX_NUMBER]: company[CompanyKeyEnum.TAX_NUMBER],
      }),
      ...(company[CompanyKeyEnum.EMAIL] && {
        [CompanyKeyEnum.EMAIL]: company[CompanyKeyEnum.EMAIL],
      }),
      ...(company[CompanyKeyEnum.PHONE] && {
        [CompanyKeyEnum.PHONE]: company[CompanyKeyEnum.PHONE],
      }),
      ...(company[CompanyKeyEnum.COUNTRY] && {
        [CompanyKeyEnum.COUNTRY]: company[CompanyKeyEnum.COUNTRY],
      }),
      ...(company[CompanyKeyEnum.ZIP] && {
        [CompanyKeyEnum.ZIP]: company[CompanyKeyEnum.ZIP],
      }),
      ...(company[CompanyKeyEnum.STATE] && {
        [CompanyKeyEnum.STATE]: company[CompanyKeyEnum.STATE],
      }),
      ...(company[CompanyKeyEnum.CITY] && {
        [CompanyKeyEnum.CITY]: company[CompanyKeyEnum.CITY],
      }),
      ...(company[CompanyKeyEnum.ADDRESS] && {
        [CompanyKeyEnum.ADDRESS]: company[CompanyKeyEnum.ADDRESS],
      }),
      [CompanyKeyEnum.WEBSITE]: company[CompanyKeyEnum.WEBSITE] || '',
    },
    // Contact
    ...(Array.isArray(company[CompanyKeyEnum.CONTACTS]) && {
      [CompanyKeyEnum.CONTACTS]: [
        {
          type: 'signer' as const,
          ...(company?.[CompanyKeyEnum.SIGNER_ID] && {
            [CompanyKeyEnum.ID]: company[CompanyKeyEnum.SIGNER_ID],
          }),
          [CompanyKeyEnum.FULL_NAME]: company?.[CompanyKeyEnum.SIGNER_FULL_NAME],
          [CompanyKeyEnum.EMAIL]: company?.[CompanyKeyEnum.SIGNER_EMAIL],
          [CompanyKeyEnum.POSITION]: company?.[CompanyKeyEnum.SIGNER_POSITION],
          [CompanyKeyEnum.CONTACT_PHONE]: company?.[CompanyKeyEnum.SIGNER_PHONE],
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...company[CompanyKeyEnum.CONTACTS].map((contact) => ({
          type: 'contact' as const,
          ...(contact[CompanyKeyEnum.ID] && {
            [CompanyKeyEnum.ID]: contact[CompanyKeyEnum.ID],
          }),
          ...(contact[CompanyKeyEnum.FULL_NAME] && {
            [CompanyKeyEnum.FULL_NAME]: contact[CompanyKeyEnum.FULL_NAME],
          }),
          ...(contact[CompanyKeyEnum.POSITION] && {
            [CompanyKeyEnum.POSITION]: contact[CompanyKeyEnum.POSITION],
          }),
          ...(contact[CompanyKeyEnum.EMAIL] && { [CompanyKeyEnum.EMAIL]: contact[CompanyKeyEnum.EMAIL] }),
          ...(contact[CompanyKeyEnum.CONTACT_PHONE] && {
            [CompanyKeyEnum.CONTACT_PHONE]: contact[CompanyKeyEnum.CONTACT_PHONE],
          }),
        })),
      ],
    }),
    // BankAccount
    ...(Array.isArray(company[CompanyKeyEnum.BANK_DETAILS]) && {
      [CompanyKeyEnum.BANK_DETAILS]: company?.[CompanyKeyEnum.BANK_DETAILS]?.map((bankAccount) => ({
        [CompanyKeyEnum.MASTER]: Boolean(bankAccount[CompanyKeyEnum.MASTER]),
        ...(bankAccount?.[CompanyKeyEnum.ID] && {
          [CompanyKeyEnum.ID]: bankAccount[CompanyKeyEnum.ID],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CURRENCY] && {
          [CompanyKeyEnum.CURRENCY]: bankAccount[CompanyKeyEnum.CURRENCY],
        }),
        ...(bankAccount?.[CompanyKeyEnum.BANK_NAME] && {
          [CompanyKeyEnum.BANK_NAME]: bankAccount[CompanyKeyEnum.BANK_NAME],
        }),
        ...(bankAccount?.[CompanyKeyEnum.SWIFT] && { [CompanyKeyEnum.SWIFT]: bankAccount[CompanyKeyEnum.SWIFT] }),
        ...(bankAccount?.[CompanyKeyEnum.ACCOUNT] && { [CompanyKeyEnum.ACCOUNT]: bankAccount[CompanyKeyEnum.ACCOUNT] }),
        ...(bankAccount?.[CompanyKeyEnum.BANK_ADDRESS] && {
          [CompanyKeyEnum.BANK_ADDRESS]: bankAccount[CompanyKeyEnum.BANK_ADDRESS],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_ACCOUNT] && {
          [CompanyKeyEnum.CORRESPONDENT_ACCOUNT]: bankAccount[CompanyKeyEnum.CORRESPONDENT_ACCOUNT],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_BANK_NAME] && {
          [CompanyKeyEnum.CORRESPONDENT_BANK_NAME]: bankAccount[CompanyKeyEnum.CORRESPONDENT_BANK_NAME],
        }),
        ...(bankAccount?.[CompanyKeyEnum.CORRESPONDENT_SWIFT] && {
          [CompanyKeyEnum.CORRESPONDENT_SWIFT]: bankAccount[CompanyKeyEnum.CORRESPONDENT_SWIFT],
        }),
        ...(bankAccount?.[CompanyKeyEnum.SPECIAL_INSTRUCTIONS] && {
          [CompanyKeyEnum.SPECIAL_INSTRUCTIONS]: bankAccount[CompanyKeyEnum.SPECIAL_INSTRUCTIONS],
        }),
      })),
    }),
  };

  return result;
};

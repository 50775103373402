import { makeStyles } from '@material-ui/core/styles';

// hooks for styling Material UI components through props
// example:
// const stylingButton = UseUploadStylingButton();
// <Button {...stylingButton}></Button>

export const UseStylingButton = () => {
  const classes = useStyles();

  return {
    variant: 'contained' as const,
    color: 'inherit' as const,
    classes: { root: classes.rootUpload, label: classes.labelUpload },
  };
};

const useStyles = makeStyles(() => ({
  labelUpload: {
    color: '#FFFFFF',
    fontSize: 16,
    lineHeight: '24px',
  },
  rootUpload: {
    backgroundColor: '#A7AFB7',
    borderRadius: 8,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#8E9BA7',
    },
    '&:active': {
      backgroundColor: '#8995A0',
    },
  },
}));

export const UseStylingDropZoneButton = () => {
  const classes = useDropZoneStyles();

  return {
    disableRipple: true,
    disableFocusRipple: true,
    variant: 'outlined' as const,
    classes: { root: classes.dropZoneBottonRoot },
  };
};

const useDropZoneStyles = makeStyles(() => ({
  dropZoneBottonRoot: {
    border: '1px solid #A7AFB7',
    textTransform: 'inherit',
    color: '#606176',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #838998',
      backgroundColor: 'inherit',
    },
    '&:active': {
      border: '1px solid ##606176',
    },
  },
}));

// example  <TextField {...} />
export const UseStylingTextField = () => {
  const classes = useTextField();

  return {
    InputLabelProps: {
      classes: {
        standard: classes.standardLabel,
        required: classes.labelRequered,
        error: classes.labelError,
        shrink: classes.labelShrink,
      },
    },
    InputProps: {
      style: { backgroundColor: '#fff' },
      classes: {
        multiline: classes.multiline,
        root: classes.customInputRoot,
        input: classes.inputFilled,
        error: classes.inputError,
        focused: classes.inputFocus,
      },
      disableUnderline: true,
    },
    FormHelperTextProps: { classes: { error: classes.helperError } },
    variant: 'filled' as const,
  };
};

export const UseStylingAutocompleteField = () => {
  const classes = useTextField();

  return {
    autoComplete: {
      classes: {
        option: classes.optionAutoComplete,
        listbox: classes.listboxAutocomplete,
        popupIndicator: classes.popupIndicator,
      },
    },
    textField: {
      InputLabelProps: {
        classes: {
          required: classes.labelRequered,
          error: classes.labelError,
          shrink: classes.labelShrink,
        },
      },
      InputProps: {
        classes: {
          root: classes.customInputRoot,
          input: `${classes.inputFilled} ${classes.inputAutocomplete}`,
          error: classes.inputError,
          focused: classes.inputFocus,
        },
        disableUnderline: true,
      },
      FormHelperTextProps: { classes: { error: classes.helperError } },
      variant: 'standard' as const,
    },
  };
};

export const useTextField = makeStyles(() => ({
  multiline: {
    padding: '20px !important',
  },
  inputAutocomplete: {
    padding: '0px 12px !important',
  },
  popupIndicator: {
    marginRight: 12,
  },
  listboxAutocomplete: {
    boxShadow: '0px 14px 40px rgba(0, 0, 0, 0.07)',
    borderRadius: 8,
  },
  optionAutoComplete: {
    height: 36,
    padding: '0px 8px !important',
    margin: '0px 4px',
    color: '#838998 !important',
    fontSize: '16px !important',
    '&[aria-selected="true"]': {
      borderRadius: 4,
      backgroundColor: '#FAFBFE',
    },
    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: 'inherit',
    },
    '&:hover': {
      backgroundColor: '#FAFBFE!important',
      borderRadius: 4,
    },
  },
  labelShrink: {
    color: '#838998 !important',
    fontSize: '12px !important',
    lineHeight: '12px !important',
  },
  standardLabel: {
    '&[data-shrink="false"]': {
      top: 'calc(50% - 33px) !important',
      left: '12px !important',
    },
  },
  labelRequered: {
    color: '#838998',
    fontSize: 16,
    lineHeight: '18px',
  },
  inputFilled: {
    boxSizing: 'border-box',
    color: '#29292C',
    fontSize: 16,
    lineHeight: '18px',
    paddingLeft: '0px !important',
  },
  customInputRoot: {
    borderRadius: '8px !important',
    backgroundColor: '#fff',
    border: '1px solid #ECF0F5',
    padding: '0px 12px',
    '&:hover': {
      backgroundColor: '#FAFBFE !important',
    },
  },
  inputFocus: {
    border: '1px solid #A7AFB7',
    backgroundColor: '#fff!important',
  },
  helperError: {
    color: '#EC3E72 !important',
    margin: '0 !important',
    fontSize: '12px !important',
    zIndex: 99,
  },
  labelError: {
    zIndex: 1,
    color: '#838998 !important',
  },
  inputError: {
    border: '1px solid #EC3E72 !important',
    borderRadius: 8,
  },
}));

import { KeyTransactions } from 'components/tables/TransactionsTable/data';

const initialConversionIncomingRequiredKey = [
  KeyTransactions.VALUE_DATE_TIME,
  KeyTransactions.COMMISSION,
  KeyTransactions.AMOUNT_SELL,
  KeyTransactions.AMOUNT_BUY,
];

export const requiredConversionIncomingKeys = () => initialConversionIncomingRequiredKey;

export const errorsConversionIncoming = {
  [KeyTransactions.ROOT]: [
    { key: KeyTransactions.VALUE_DATE_TIME, parameters: { required: true } },
    { key: KeyTransactions.COMMISSION, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT_SELL, parameters: { required: true } },
    { key: KeyTransactions.AMOUNT_BUY, parameters: { required: true } },
  ],
};

export type errorsConversionIncomingType = typeof errorsConversionIncoming;

function getFirstBrowserLanguage() {
  const nav = window.navigator;
  const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  let i;
  let language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i += 1) {
      language = nav.languages[i];

      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i += 1) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    language = nav[browserLanguagePropertyKeys[i]];

    if (language && language.length) {
      return language;
    }
  }

  return null;
}

function getSeparator(locale: string, separator: 'decimal' | 'group') {
  const numberWithGroupAndDecimalSeparator = 1000.1;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separator).value;
}

export const Browserlang = getFirstBrowserLanguage();
export const decimal = getSeparator(Browserlang, 'decimal');

export const addDecimal = (value: string | number) => {
  if (decimal !== '.') {
    return String(value).replaceAll('.', ',');
  }

  return value;
};

import React, { FC } from 'react';
import TabPanel from '@material-ui/lab/TabPanel';
import { makeStyles } from '@material-ui/core/styles';

// import FormControl from '@material-ui/core/FormControl';
import CustomInput from 'components/inputs/CustomInput';

type SignatureTabGroupDataType = {
  position: string;
  full_name: string;
  factor_name: string;
};

type SignatureTabPanelPropsType = {
  groupData: SignatureTabGroupDataType;
  setValue: any;
};

const SignatureTabPanel: FC<SignatureTabPanelPropsType> = ({ groupData, setValue }: SignatureTabPanelPropsType) => {
  const styles = useStyles();

  return (
    <TabPanel value="signature" style={{ padding: 0 }}>
      <div className={styles.tabBlock}>
        <CustomInput
          labelKey="systemSetting.signaturePosition"
          name="position"
          onChange={setValue}
          disabled={false}
          defaultValue={groupData.position}
          stylesProp={{
            marginBottom: 20,
          }}
        />
        <CustomInput
          labelKey="systemSetting.signatureFullName"
          name="full_name"
          onChange={setValue}
          disabled={false}
          defaultValue={groupData.full_name}
          stylesProp={{
            marginBottom: 20,
          }}
        />
        <CustomInput
          labelKey="systemSetting.factorName"
          name="factor_name"
          onChange={setValue}
          disabled={false}
          defaultValue={groupData.factor_name}
        />
      </div>
    </TabPanel>
  );
};

export default SignatureTabPanel;

const useStyles = makeStyles(() => ({
  tabBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '500px',
    marginBottom: '23px',
    height: '48px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type transactionDataType = {
  companyId: string;
  currency: string;
  showDeleted: boolean;
  tx_type?: string;
  errorCallback: Function;
  resultKey: string;
};

export default ({
  companyId,
  currency,
  showDeleted = false,
  errorCallback,
  tx_type = undefined,
  resultKey = 'transactionsData',
}: transactionDataType) =>
  requestAsync({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url: endpoints.getTransactionsUrl(companyId, currency, showDeleted, tx_type),
    transform: (response) => ({
      [resultKey]: response,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryKey: endpoints.getTransactionsUrl(companyId, currency, showDeleted, tx_type),
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      authToken: true,
      errorCallback,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });

import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import PivotTransactionReportTableHeader from 'components/tables/PivotTransactionReportTable/Header';
import PivotTransactionReportTable from 'components/tables/PivotTransactionReportTable';
import { updateTitle } from 'helpers/common';

const wrapperStyle = {
  padding: '20px 40px 16px 40px',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '108px calc(100% - 108px)',
  height: 'calc(100% - 36px)',
  backgroundColor: '#F7F8FA',
};

const PivotTransactionReportsScreen: any = ({ ...props }) => {
  const { requestParams } = props;

  useEffect(() => {
    updateTitle('Pivot Transactions');
  }, []);

  return (
    <ContentWrapper style={wrapperStyle}>
      <PivotTransactionReportTableHeader requestParams={requestParams} />
      <PivotTransactionReportTable />
    </ContentWrapper>
  );
};

export default PivotTransactionReportsScreen;

import React, { FC } from 'react';

import { KeyTransactions } from 'components/tables/TransactionsTable/data';

import ConstructorTransForm from '../../ConstructorTransForm';

interface ConversionCardType {}
const ConversionCard: FC<ConversionCardType> = () => (
  <>
    <ConstructorTransForm types={[KeyTransactions.TYPE, KeyTransactions.CURRENCY]} />
    <ConstructorTransForm types={[KeyTransactions.RECIPIENT]} />
    <ConstructorTransForm types={[KeyTransactions.VALUE_DATE_TIME, KeyTransactions.COMMISSION]} />
    <ConstructorTransForm types={[KeyTransactions.RATE]} />
    <ConstructorTransForm types={[KeyTransactions.COMMENT]} />
  </>
);
export default ConversionCard;

export const AOFilters = ['show_deleted', 'contract_number', 'company_id', 'page', 'limit', 'order_id', 'status'];
export const PurchaseFilters = ['company_id', 'order_id', 'request_number', 'currency', 'status', 'limit', 'page'];
export const CommissionReportFilter = [
  'report_date',
  'company_id',
  'contract_number',
  'currency',
  '_order',
  'status',
  'activation_order_id',
  '_sort',
  'date_for',
];
export const revenueByDays = ['date_from', 'date_to', 'stores_ids'];
export const clientClaimsFilters = ['period_from', 'platform_ids', 'tab'];
export const ExpectedPaymentsFilters = ['date_to', 'company_id', 'ao_ids', 'payment_type', 'dont_show_late_payments'];
export const PivotTransactionFilters = ['report_date', 'company_id', '_sort', '_order'];
export const ClientsAmountReportFilters = ['date_for', 'company_id', '_sort', '_order'];
export const CompanyFilters = [
  'type',
  'page',
  'limit',
  'status',
  'sortedBy',
  'order',
  'company_name',
  'companyName',
  'group',
];
export const TransactionTableFilters = [
  'company_id',
  'currency',
  'tx_type',
  'date_from',
  'date_to',
  'company_name',
  'contract_number',
  'order_id',
  'show_deleted',
  'limit',
  'status',
  'size',
  'page',
  'filter',
  'category',
  'sort',
];

export const UsersTableFilters = ['page', 'limit', 'company_name', 'email', 'user_name', 'order', '_sort', '_order'];
export const UsersFormFilters = [
  'id',
  'fullname',
  'limit',
  'company_name',
  'email',
  'user_name',
  'auth',
  'active',
  'show_deleted',
];
export const AdminsTableFilters = ['page', 'limit', 'email', 'user_name', 'show_deleted'];

/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FormEvent, useEffect, useCallback, useState, useContext, FC, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import useUser from 'hooks/api/useUser';
import useAlert from 'hooks/api/useAlert';
import useModal from 'hooks/api/useModal';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import {
  AdminStateType,
  ErrorType,
  AdminFormFetchBodyType,
  AdminFormPropsType,
  getInitialError,
} from 'types/AdminTypes';
import CustomInput from 'components/inputs/CustomInput';
import DeleteButton from 'components/buttons/DeleteButton';
import PasswordInput from 'components/inputs/PasswordInput';
import { emailValidator } from 'helpers/validators';

import FormBottomBlock from './FormBottomBlock';

const initialAdminStateValues: AdminStateType = {
  fullName: '',
  username: '',
  email: '',
  authProvider: 'openid',
  password: '',
  active: true,
};

const AdminForm: FC<AdminFormPropsType> = ({ editType = false }: AdminFormPropsType) => {
  const classes = useStyles();
  const history = useHistory();
  const { adminId = '' } = useParams();
  const { createAdmin, adminData, getAdmin, getAdminIsFetching, editAdmin, deleteAdmin } = useUser();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { modalData, setModal } = useModal();
  const refreshContext = useContext(RefreshContext);
  const requiredText = t('auth.required');
  const [error, setError] = useState<ErrorType>(getInitialError(requiredText));
  const [adminState, setAdminState] = useState<AdminStateType>(initialAdminStateValues);
  const [isPending, setIsPending] = useState(false);
  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText }) => {
    setIsPending(false);

    return errorCallbackView({ errorField, errorCallbackText });
  });
  const errorCallbackView = useCallback(
    ({ errorField, errorCallbackText }) => {
      if (Object.keys(error).includes(fieldTranslate(errorField))) {
        setError((prevState) => ({
          ...prevState,
          [fieldTranslate(errorField)]: { status: true, text: errorCallbackText },
        }));

        return false;
      }

      return true;
    },
    [error],
  );

  const fieldTranslate = useCallback((key: string) => {
    switch (key) {
      case 'show_deleted':
        return 'showDeleted';
      case 'full_name':
        return 'fullName';
      case 'user_name':
        return 'username';
      default:
        return key;
    }
  }, []);

  const [deletedAdmin, setDeletedAdmin] = useState<boolean>(false);
  const toolTip = adminState.active ? '' : t('deletedRecordText');

  const successCallback = useCallback(
    (actionType) => {
      setIsPending(false);
      history.push(urls.getAdmin());
      let actionName;

      if (editType) {
        if (actionType.deleted) {
          actionName = t('global.deleted');
        } else {
          actionName = t('global.updated');
        }
      } else {
        actionName = t('global.created');
      }
      setAlert({
        type: 'success',
        message: `${t('alert.admin')} ${actionName}`,
      });
    },
    [history, setAlert, t, editType],
  );

  useMemo(() => {
    if (modalData.confirm) {
      deleteAdmin({ id: adminId, successCallback: successCallback.bind(null, { deleted: true }), errorCallback });
      setModal({ show: false, confirm: false });
    }
  }, [modalData, adminId, deleteAdmin, successCallback, errorCallback, setModal]);

  useEffect(() => {
    if (editType) {
      getAdmin({ id: adminId, errorCallback });
      refreshContext.set({
        func: getAdmin,
        params: { id: adminId, errorCallback },
      });
    }
  }, [getAdmin, editType, adminId, refreshContext, errorCallback]);

  useEffect(() => {
    if (Object.keys(adminData).length && editType) {
      setAdminState({ ...adminData });
      setDeletedAdmin(!adminData.active);
    }
  }, [adminData, editType]);

  useEffect(
    () => () => {
      refreshContext.clear();
    },
    [refreshContext],
  );

  const onBlurHandler = (field: string, fieldValue: string) => {
    if (field === 'email') {
      if (adminState.email && adminState.email.length > 0) {
        setError({ ...error, [field]: { status: !emailValidator(fieldValue), text: requiredText } });
      } else {
        setError({ ...error, [field]: { status: false, text: requiredText } });
      }
    } else if (field === 'username' || field === 'fullName') {
      setError({ ...error, [field]: { status: !validateName(fieldValue), text: requiredText } });
    } else {
      setError({ ...error, [field]: !fieldValue });
    }
  };

  const validateName = useCallback((value) => value && value.length > 0 && value.trim().length > 0, []);

  const nameValidateHandler = useCallback(
    (name, value) => {
      const errorName = validateName(value);
      setError((prevState) => ({
        ...prevState,
        [name]: { status: !errorName, text: requiredText },
      }));
    },
    [requiredText],
  );

  const setErrorHandler = useCallback(
    ({ name, value }) => {
      if (Object.keys(error).includes(name)) {
        if (name === 'fullName' || name === 'username') {
          nameValidateHandler(name, value);
        } else if (name === 'email') {
          if (adminState.email && adminState.email.length > 0) {
            setError((prev) => ({ ...prev, [name]: { status: !emailValidator(value), text: requiredText } }));
          } else {
            setError((prev) => ({ ...prev, [name]: { status: false, text: requiredText } }));
          }
        } else {
          setError((prev) => ({ ...prev, [name]: { status: !value, text: requiredText } }));
        }
      }
    },
    [error, requiredText],
  );
  const setFormValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setErrorHandler({ name, value });
      setAdminState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [error],
  );

  const validateAdminForm = useCallback(
    () =>
      Object.keys(error).every((name) => {
        if (name === 'fullName' || name === 'username') {
          return validateName(adminState[name]);
        }

        if (name === 'email') {
          if (adminState.email && adminState.email.length > 0) {
            return emailValidator(adminState[name]);
          }

          return true;
        }

        return true;
      }),
    [adminState],
  );

  const onSubmit = useCallback(
    (event: FormEvent) => {
      setIsPending(true);
      event.preventDefault();
      const { password, authProvider } = adminState;
      const errorObj: ErrorType = { ...error };
      const fetchBody: AdminFormFetchBodyType = {
        ...adminState,
        successCallback,
        errorCallback,
      };

      if (!password) {
        delete fetchBody.password;
      }

      if (editType) {
        fetchBody.id = adminId;

        if (!errorObj.username.status && !errorObj.fullName.status && adminId) {
          if (errorObj.email.status) {
            setIsPending(false);
          } else {
            editAdmin(fetchBody);
          }
        } else {
          setIsPending(false);
        }
      } else {
        Object.keys(error).forEach((name) => {
          setErrorHandler({ name, value: adminState[name] });
        });

        if (validateAdminForm() && (authProvider !== 'internal' || !!password)) {
          createAdmin(fetchBody);
        } else {
          setIsPending(false);
        }
      }
    },
    [error.email, adminId, editType, createAdmin, editAdmin, successCallback, errorCallback, adminState],
  );

  const deleteHandler = useCallback(() => {
    setModal({ show: true, title: `Delete administrator ${adminState.username}` });
  }, [setModal, adminState]);

  const cancelHandler = useCallback(() => {
    history.push(urls.getAdmin());
  }, [history]);

  if (getAdminIsFetching) {
    return null;
  }

  return (
    <form onSubmit={onSubmit} noValidate>
      {editType && <DeleteButton deleted={deletedAdmin} deleteHandler={deleteHandler} toolTip={toolTip} />}
      <div className={classes.root}>
        <div className={classes.topBlock}>
          <FormControl error={error.fullName.status} className={classes.formControl}>
            <CustomInput
              labelKey="auth.input.fullName"
              name="fullName"
              onChange={setFormValue}
              disabled={deletedAdmin}
              required
              toolTip={toolTip}
              isError={error.fullName.status}
              defaultValue={adminState.fullName}
              stylesProp={{ width: 500 }}
              errorText={error.fullName.text}
            />
          </FormControl>
          <FormControl error={error.username.status} className={classes.formControl}>
            <CustomInput
              labelKey="auth.input.username"
              name="username"
              onChange={setFormValue}
              disabled={deletedAdmin}
              toolTip={toolTip}
              required
              isError={error.username.status}
              defaultValue={adminState.username}
              stylesProp={{ width: 500 }}
              errorText={error.username.text}
            />
          </FormControl>
          <FormControl error={error.email.status} className={classes.formControl}>
            <CustomInput
              labelKey="auth.input.email"
              name="email"
              isError={error.email.status}
              onChange={setFormValue}
              defaultValue={adminState.email}
              toolTip={toolTip}
              onBlurHandler={() => onBlurHandler('email', adminState.email)}
              disabled={deletedAdmin}
              helperTextStyle={{ marginTop: -25 }}
              errorText={t('errors.emailNotValid')}
              stylesProp={{
                marginBottom: 30,
                width: 500,
              }}
            />
          </FormControl>
          <FormControl variant="filled" className={classes.selectFormControl} disabled={deletedAdmin}>
            <CustomInput
              labelKey="auth.authentication"
              name="authProvider"
              onChange={setFormValue}
              toolTip={toolTip}
              disabled={deletedAdmin}
              defaultValue={adminState.authProvider}
              select
              selectItems={[
                { value: 'openid', label: 'auth.openId' },
                { value: 'internal', label: 'auth.internalDatabase' },
              ]}
              inputWidth={500}
              stylesProp={{ width: 500 }}
            />
          </FormControl>
          {adminState.authProvider === 'internal' && (
            <PasswordInput
              password={adminState.password}
              error={error.password.status}
              toolTip={toolTip}
              deleted={deletedAdmin}
              onBlurHandler={() => onBlurHandler('password', adminState.password)}
              handlePasswordChange={setFormValue}
            />
          )}
        </div>
        <FormBottomBlock deleted={deletedAdmin} cancelHandler={cancelHandler} disabled={isPending} isNeedMinWidth />
      </div>
      <ReactTooltip />
    </form>
  );
};

export default AdminForm;

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '16px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    overflowX: 'auto',
  },
  topBlock: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
  },
  formControl: {
    height: '48px',
    marginBottom: '35px',
  },
  input: {
    width: '500px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  inputEmail: {
    marginBottom: '35px',
  },
  selectFormControl: {
    marginBottom: '35px',
  },
}));

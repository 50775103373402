import { requestAsync } from 'redux-query';

import endpoints from 'consts/endpoints';

type createAdminDataType = {
  fullName: string;
  username: string;
  email?: string;
  authProvider: string;
  password?: string;
  successCallback: Function;
  errorCallback: Function;
  resultKey: string;
};

type createAdminBodyType = {
  full_name: string;
  user_name: string;
  email?: string;
  auth_provider: string;
  password?: string;
};

export default ({
  fullName,
  username,
  email,
  authProvider,
  password,
  successCallback,
  errorCallback,
  resultKey = 'createAdminData',
}: createAdminDataType) => {
  const bodyObj: createAdminBodyType = {
    full_name: fullName,
    user_name: username,
    auth_provider: authProvider,
  };

  if (email) {
    bodyObj.email = email;
  }

  if (password) {
    bodyObj.password = password;
  }

  return requestAsync({
    url: endpoints.getAdminCreateUrl(),
    transform: (response) => ({
      [resultKey]: response,
    }),
    queryKey: endpoints.getAdminCreateUrl(),
    body: bodyObj,
    options: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    },
    meta: {
      successCallback,
      errorCallback,
      authToken: true,
    },
    update: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [resultKey]: (_prevEntities, newEntities) => newEntities,
    },
  });
};

import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

type FormBottomBlockPropsType = {
  deleted?: boolean;
  cancelHandler: any;
  cancelDisplay?: boolean;
  cancelChildren?: React.ReactNode;
  submitChildren?: React.ReactNode;
  disabled?: boolean;
  toolTip?: string;
  isNeedMinWidth?: boolean;
  style?: React.CSSProperties;
  validate?: boolean;
};

const FormBottomBlock: FC<FormBottomBlockPropsType> = ({
  deleted = false,
  cancelHandler,
  cancelDisplay = true,
  cancelChildren,
  submitChildren,
  disabled,
  toolTip = '',
  isNeedMinWidth,
  style = {},
  validate = true,
}: FormBottomBlockPropsType) => {
  const styles = useStyles();
  const { t } = useTranslation();

  if (!validate) return null;

  return (
    <div className={styles.footerBlock} style={isNeedMinWidth ? { minWidth: 500, ...style } : style}>
      {cancelDisplay && (
        <Button color="primary" className={styles.cancelButton} onClick={cancelHandler}>
          {cancelChildren || <span>{t('global.cancel')}</span>}
        </Button>
      )}

      {!deleted && (
        <div data-tip={toolTip}>
          <Button variant="contained" color="primary" type="submit" disabled={disabled}>
            {submitChildren || (
              <>
                <SaveIcon fontSize="small" className={styles.buttonIcon} />
                <span>{t('global.save')}</span>
              </>
            )}
          </Button>
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

export default FormBottomBlock;

const useStyles = makeStyles(() => ({
  footerBlock: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '64px',
    backgroundColor: '#f5f5f5',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  cancelButton: {
    marginRight: '50px',
  },
  buttonIcon: {
    marginRight: '8px',
  },
}));

import React, { FC } from 'react';

import useCompany, { useCompanyType } from 'hooks/api/useCompany';

import { KeyCardsEnum, statusParamsEnum } from '../data';
import ItemsAo from '../ItemsAo';
import { paramsObjAO } from '../../ActivationOrderForm';

interface CardApplaudType {
  params?: paramsObjAO;
}
const styles = { height: '100%', margin: 0 };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CardApplaud: FC<CardApplaudType> = React.memo(({ params: { status } }) => {
  const { listSwitch }: useCompanyType = useCompany();
  const row =
    status === statusParamsEnum.EDIT
      ? [KeyCardsEnum.AMOUNT, KeyCardsEnum.STATUS, KeyCardsEnum.CURRENCY]
      : [KeyCardsEnum.AMOUNT, KeyCardsEnum.CURRENCY];

  return (
    <>
      <ItemsAo types={[KeyCardsEnum.AO_TYPE, KeyCardsEnum.SIGN_DATE]} />
      <ItemsAo types={[KeyCardsEnum.COMPANY_ID]} />
      <ItemsAo types={[KeyCardsEnum.CONTRACT_ID]} />
      <ItemsAo types={[KeyCardsEnum.NAME, KeyCardsEnum.VAT_POLICY, KeyCardsEnum.PENALTY_POLICY]} />
      <ItemsAo style={status === statusParamsEnum.EDIT ? {} : { gridTemplateColumns: '2fr 186px' }} types={row} />
      <ItemsAo types={[KeyCardsEnum.DROPZONE]} />
      <ItemsAo style={{ marginTop: 24 }} types={[KeyCardsEnum.INTEREST, KeyCardsEnum.DAYS]} />
      <ItemsAo types={[KeyCardsEnum.PURCHASE_DATE, KeyCardsEnum.REPAYMENT_DATE]} />
      <ItemsAo types={[KeyCardsEnum.CUSTOM_SETTINGS]} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {listSwitch.custom_settings && (
        <>
          <ItemsAo style={styles} types={[KeyCardsEnum.GRACE_PERIOD]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.CAPITALIZATION_DISABLE_DATE]} />
          <ItemsAo style={styles} types={[KeyCardsEnum.DEFAULT_INTEREST_DISABLE_DATE]} />
        </>
      )}
    </>
  );
});

export default CardApplaud;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import urls from 'constants/urls';
import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { getCurrencyWithCode, formatAmount, formatDate } from 'helpers/formatters';
import { DocumentTypeEnum, getListTransactionCategory } from 'components/tables/TransactionsTable/ViewsForm/data';
import { getSymbolAndColorTransactions, KeyTransactions } from 'components/tables/TransactionsTable/data';
import { activationOrdersV2DataType } from 'hooks/api/useActivationOrder';
import getParams from 'helpers/getParams';

import { getPathOfDocumentType, getTitleOfDocumentType } from '../../data';

import styles from './styles.module.scss';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
  currentAo?: activationOrdersV2DataType;
  aoType: string;
};

const InternalTransactionView: FC<PropsT> = ({ transactionsDataById, aoType, currentAo }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { symbol } = getSymbolAndColorTransactions(transactionsDataById?.amount?.amount, transactionsDataById.type);

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.amount')}`}
            value={`${symbol} ${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency))}${formatAmount(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              Math.abs(transactionsDataById?.amount?.amount) || 0,
            )}`}
            additionalStyle={{ fontWeight: 700 }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')}`}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.category')}`}
            value={
              getListTransactionCategory[KeyTransactions.INTERNAL]().find(
                ({ key }) => key === transactionsDataById?.category,
              )?.value
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')}`}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            title={`${t('forms.transaction.client')}`}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          {Boolean(currentAo) && (
            <Item
              type={TypeEnum.HREF}
              fileProps={{
                link: `${getPathOfDocumentType[DocumentTypeEnum.ORDER]}/${
                  transactionsDataById.factoring_order_id
                }${getParams({ type: aoType })}`,
              }}
              title={`${t(getTitleOfDocumentType[DocumentTypeEnum.ORDER])}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={`${currentAo.name} [${formatDate(currentAo.sign_date)}]`}
              additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
            />
          )}
          {transactionsDataById.document_type === DocumentTypeEnum.REQUEST && (
            <Item
              type={TypeEnum.HREF}
              fileProps={{
                link: `${getPathOfDocumentType[transactionsDataById.document_type]}/${
                  transactionsDataById.document_id || transactionsDataById?.factoring_order_id
                }${getParams({ type: aoType })}`,
              }}
              title={`${t(getTitleOfDocumentType[transactionsDataById.document_type])} `}
              value={transactionsDataById?.document_number || transactionsDataById?.factoring_order_number}
              additionalStyle={{ textDecoration: 'underline', color: '#000000' }}
            />
          )}
          {transactionsDataById.document_type === DocumentTypeEnum.CLAIM && (
            <Item
              type={TypeEnum.TEXT}
              title={`${t(getTitleOfDocumentType[transactionsDataById.document_type])} `}
              value={transactionsDataById?.document_number || transactionsDataById?.factoring_order_number}
            />
          )}
          {transactionsDataById.category_dsc !== 'capitalization' && (
            <Item
              type={TypeEnum.TEXT}
              title={`${t('forms.transaction.comment')}`}
              value={transactionsDataById?.comment}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default InternalTransactionView;

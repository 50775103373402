import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import plusIcon from 'assets/img/plus-black.svg';
import minusIcon from 'assets/img/minus-black.svg';
import Accordion from 'components/uiKit/Accordion';
import Card from 'components/common/GeneralView/Card';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import { ItemTransactionsType } from 'hooks/api/useTransaction';
import { getCurrencyWithCode, formatAmount } from 'helpers/formatters';
import { getSymbolAndColorTransactions } from 'components/tables/TransactionsTable/data';
import urls from 'constants/urls';

import styles from './styles.module.scss';

type PropsT = {
  transactionsDataById: ItemTransactionsType;
};

const FeeTransactionView: FC<PropsT> = ({ transactionsDataById }) => {
  const { t } = useTranslation();
  const { color, symbol } = getSymbolAndColorTransactions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transactionsDataById?.amount?.amount,
    transactionsDataById.type,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.twoColumnsBlock}>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.amount')}`}
            value={`${symbol} ${getCurrencyWithCode(Number(transactionsDataById?.amount?.currency))}${formatAmount(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              Math.abs(transactionsDataById?.amount?.amount) || 0,
            )}`}
            additionalStyle={{ color, fontWeight: 700 }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.type')}`}
            value={
              transactionsDataById?.type
                ? transactionsDataById.type.charAt(0).toUpperCase() + transactionsDataById.type.slice(1)
                : ''
            }
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.date')}`}
            valueFormatType="date"
            value={transactionsDataById?.value_datetime}
          />
          <Item
            type={TypeEnum.HREF}
            fileProps={{ link: `${urls.getCompany()}/${transactionsDataById.factoring_company_id}` }}
            title={`${t('forms.transaction.client')}`}
            value={transactionsDataById?.factoring_company_name}
            additionalStyle={{ textDecoration: 'underline' }}
          />
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.comment')}`}
            value={transactionsDataById?.comment}
          />
        </Card>
      </div>
      <Accordion
        initialState
        title={t('forms.transaction.details')}
        closeImg={<img src={minusIcon} alt="close" />}
        openImg={<img src={plusIcon} alt="open" />}
        className={styles.accordionButton}
      >
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title={`${t('forms.transaction.paymentDetails')}`}
            value={transactionsDataById?.details}
          />
        </Card>
      </Accordion>
    </div>
  );
};

export default FeeTransactionView;

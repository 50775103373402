import React, { useEffect } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import Reconciliation from 'components/reports/Reconciliation';
import { updateTitle } from 'helpers/common';

const ReconciliationReportScreen = () => {
  useEffect(() => {
    updateTitle('Reconciliation report');
  }, []);

  return (
    <ContentWrapper style={{ padding: '24px 40px 24px 40px', position: 'relative' }}>
      <Reconciliation />
    </ContentWrapper>
  );
};

export default ReconciliationReportScreen;

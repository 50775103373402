// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useActivationOrder, { activationOrderDataType } from 'hooks/api/useActivationOrder';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import Card from 'components/common/GeneralView/Card';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';
import urls from 'constants/urls';
import { Account } from 'types/ActivationOrderTypes';
import {
  formatAmount,
  formatDateIsoShort,
  getCodeCurrencyOfName,
  getCurrencyWithCode,
  getNameCurrencyOfCode,
} from 'helpers/formatters';
import Tooltip from 'components/Tooltip';
import CreateTransactionCompanyPopUp from 'components/forms/CompanyForm/View/PopUpsCompany/CreateTransactionCompanyPopUp/CreateTransactionCompanyPopUp';
import claimsArrow from 'assets/img/claimsArrow.svg';
import useTransaction, { useTransactionType } from 'hooks/api/useTransaction';
import { bodyCreateTransactionsType } from 'queries/transaction/createListTransactions';

import styles from './styles.module.scss';

export type TypeAO = 'applaud_fix' | 'classic' | 'future_receivables' | 'available_receivables';

type PropsT = {
  type: TypeAO;
  activationOrderData: activationOrderDataType;
  accounts?: Account[];
  updateAccounts?: (id: string) => void;
};

const GeneralInformation: FC<PropsT> = ({ type, activationOrderData, accounts, updateAccounts }) => {
  const { t } = useTranslation();
  const { getActivationOrder } = useActivationOrder();
  const [dataForPopup, setDataForPopup] = useState(null);
  const showCreateTransactionCompanyPopUp = ['future_receivables', 'classic'].includes(type);
  const isClassicOrApplaudAO = type === 'classic' || type === 'applaud_fix';
  const isHasAttachedDocument = Boolean(activationOrderData.ao_document);
  const { createListTransactions, setSyncTr }: useTransactionType = useTransaction();

  const hasAccounts = accounts.length > 0;

  return (
    <>
      {dataForPopup !== null && showCreateTransactionCompanyPopUp && (
        <CreateTransactionCompanyPopUp
          isOpen={Boolean(dataForPopup)}
          onClose={() => setDataForPopup(null)}
          firstCellTitle={t('forms.activationOrder.ao')}
          firstCellContent={activationOrderData.name}
          secondCellTitle={t('global.type')}
          secondCellContent={t('forms.activationOrder.principalRepayment')}
          thirdCellTitle={t('forms.activationOrder.totalAmount')}
          companyId={activationOrderData.company_id}
          {...dataForPopup}
          leftCallback={({ date, amount }) => {
            createListTransactions({
              body: {
                factoring_order_id: activationOrderData.id,
                factoring_order_number: activationOrderData.name,
                factoring_company_id: activationOrderData.company_id,
                factoring_company_name: activationOrderData.company_name,
                type: 'internal',
                category: 'internal-principal-amount',
                value_datetime: `${formatDateIsoShort(date)}T00:00:00.000Z`,
                amount: {
                  currency: getCodeCurrencyOfName(dataForPopup.currency),
                  amount: Number(amount),
                },
              } as unknown as bodyCreateTransactionsType,
              successCallback: (result) => {
                setDataForPopup(null);
                setSyncTr({ params: { id_transactions: result.id } });
                getActivationOrder({
                  id: activationOrderData.id,
                  type: type === 'future_receivables' ? type : undefined,
                });
                updateAccounts(activationOrderData.id);
              },
            });
          }}
          rightCallback={() => setDataForPopup(null)}
        />
      )}
      <div className={styles.blockWithTwoCard}>
        <Card>
          <Item
            type={TypeEnum.HREF}
            title="forms.activationOrder.client"
            additionalStyle={{ textAlign: 'left', textDecoration: 'underline' }}
            value={activationOrderData[GeneralEnum.COMPANY_NAME]}
            fileProps={{
              isFileStile: false,
              link: `${urls.getCompany()}/${activationOrderData[GeneralEnum.COMPANY_ID]}`,
            }}
          />
          <Item
            type={TypeEnum.HREF}
            title="forms.activationOrder.agreement"
            additionalStyle={{ borderBottom: '1px solid #000' }}
            value={activationOrderData.contract?.number}
            fileProps={{
              isFileStile: false,
              link: `${urls.getCompany()}/general-docs/${activationOrderData[GeneralEnum.COMPANY_ID]}`,
            }}
          />
          <Item type={TypeEnum.TEXT} title="forms.activationOrder.ao" value={activationOrderData[GeneralEnum.NAME]} />
          <Item
            type={TypeEnum.TEXT}
            title="forms.activationOrder.signDate"
            value={activationOrderData[GeneralEnum.SIGN_DATE]}
            valueFormatType="date"
          />
        </Card>
        <Card>
          {hasAccounts ? (
            accounts.map((account) => {
              // NOTE: principalRepayment - это своеобразного вида задолженность. Поэтому она берется без знака минус
              const balance =
                typeof account.balance === 'number' ? Math.abs(account.balance) : account.balance?.replaceAll('-', '');

              return (
                <Item
                  key={account.id}
                  type={TypeEnum.TEXT}
                  title="forms.activationOrder.principalRepayment"
                  value={balance}
                  currency={getNameCurrencyOfCode(account.currency)}
                  valueFormatType="money"
                  additionalStyle={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  rightContent={
                    showCreateTransactionCompanyPopUp ? (
                      <Tooltip tooltipWithoutArrow title="Pay off" styleTooltip={{ width: 'max-content' }}>
                        <img
                          onClick={() =>
                            setDataForPopup({
                              currency: getNameCurrencyOfCode(account.currency),
                              thirdCellContent: `${getCurrencyWithCode(Number(account.currency))}${formatAmount(
                                Number(balance),
                              )}`,
                            })
                          }
                          style={{ width: 24, display: 'inline' }}
                          src={claimsArrow}
                          alt="claimsArrow"
                          className={styles.arrow}
                        />
                      </Tooltip>
                    ) : undefined
                  }
                />
              );
            })
          ) : (
            <Item type={TypeEnum.TEXT} title="forms.activationOrder.principalRepayment" value="N/A" />
          )}
          <Item
            type={TypeEnum.TEXT}
            title="forms.activationOrder.vatPolicy"
            valueFormatType="policy"
            value={activationOrderData[GeneralEnum.VAT_POLICY]}
          />
          <Item
            type={TypeEnum.TEXT}
            title="forms.activationOrder.defaultInterest"
            valueFormatType="policy"
            value={activationOrderData[GeneralEnum.PENALTY_POLICY]}
          />
          {activationOrderData[GeneralEnum.CLOSING_DATE] && (
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.closingDate"
              valueFormatType="date"
              value={activationOrderData[GeneralEnum.CLOSING_DATE]}
            />
          )}
        </Card>
      </div>
      {isHasAttachedDocument && (
        <Card>
          <Item
            type={TypeEnum.FILE}
            title="forms.activationOrder.attachedFile"
            fileProps={{
              isFileStile: true,
              link: activationOrderData.ao_document?.link,
              name: activationOrderData.ao_document?.file_name,
            }}
          />
        </Card>
      )}
      {isClassicOrApplaudAO && (
        <div className={styles.blockWithTwoCard}>
          <Card>
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.purchasePrice"
              value={activationOrderData[GeneralEnum.AMOUNT]}
              currency={activationOrderData.currency}
              valueFormatType="money"
            />
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.purchaseDate"
              value={activationOrderData[GeneralEnum.PURCHASE_DATE]}
              valueFormatType="date"
            />
          </Card>
          <Card>
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.commissionPercent"
              value={activationOrderData[GeneralEnum.DEFAULT_INTEREST]}
              valueFormatType="percent"
            />
            <Item
              type={TypeEnum.TEXT}
              title="forms.activationOrder.repaymentDate"
              value={activationOrderData[GeneralEnum.REPAYMENT_DATE]}
              valueFormatType="date"
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default GeneralInformation;

import React, { useEffect, useState } from 'react';

import ContentWrapper from 'components/ContentWrapper';
import ExpectedPaymentsHeader from 'components/tables/ExpectedPaymentsTable/ExpectedPaymentsHeader';
import ExpectedPaymentsTable from 'components/tables/ExpectedPaymentsTable';
import { updateTitle } from 'helpers/common';

const MonthlyReportScreen: any = ({ ...props }) => {
  const { requestParams } = props;
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    updateTitle('Expected Payments');
  }, []);

  return (
    <ContentWrapper maxWidthContent>
      <ExpectedPaymentsHeader requestParams={requestParams} refreshCount={refreshCount} />
      <ExpectedPaymentsTable refreshTable={() => setRefreshCount(refreshCount + 1)} />
    </ContentWrapper>
  );
};

export default MonthlyReportScreen;

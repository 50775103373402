import React, { FC, Children, useState, useCallback } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import PopUpLayout from 'helpers/PopUpLayout';
import ConfirmPopUp from 'components/ConfirmPopUp';
import { documentDownload } from 'components/forms/ActivationOrderForm/data';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';

import { CorrectionDetailsEnum, dataKeyDetails, delAdjustment, getAdjustmentCalcType } from './data';

const backSvg = require('assets/img/backPopUp.svg').default;
const clipSvg = require('assets/img/clip.svg').default;
const leftSvg = require('assets/img/leftArrow.svg').default;
const deleteSvg = require('assets/img/deleteIcon.svg').default;

interface CreateCorrectionPopUpProps {
  data: getAdjustmentCalcType;
  isOpenDetails: boolean;
  setIsOpenDetails: (data: boolean) => void;
  confirmCallBack?: () => void;
}

const CorrectionDetailsPopUp: FC<CreateCorrectionPopUpProps> = ({
  isOpenDetails = false,
  setIsOpenDetails,
  data,
  confirmCallBack,
}) => {
  const dropZoneStylingButtonPopUp = UseStylingDropZoneButton();
  const stylingButtonPopUp = UseStylingButton();
  const [detailsConfirm, setDetailsConfirm] = useState(false);
  const styles = useStyle();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const download = async ({ link }: documentDownload) => {
    setIsLoading(true);
    await getFileStorage(endpoints.getStorageFileUrl(link));
    setIsLoading(false);
  };

  const closeFC = () => {
    setIsOpenDetails(false);
    setDetailsConfirm(false);
  };

  const getValueStyle = useCallback((types: boolean, key: CorrectionDetailsEnum) => {
    let color = '#29292C';
    let style = {};

    if (key === CorrectionDetailsEnum.STATUS) {
      switch (types) {
        case true:
          color = '#26B634';
          break;
        case false:
          color = '#EC3E72';
          break;
        default:
          color = '#29292C';
      }
    }

    if (key === CorrectionDetailsEnum.DOCUMENT) {
      style = {
        display: 'inline-flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        cursor: 'pointer',
        backgroundColor: '#ECEEF2',
        borderRadius: 8,
        padding: '8px 12px',
        minWidth: 317,
        height: 32,
      };
    }

    return {
      color,
      ...style,
    };
  }, []);

  const getStyleRow = useCallback(
    (key: CorrectionDetailsEnum) => {
      let name = {
        color: '#838998',
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: 24,
      };
      let details = { marginBottom: 24 } as {
        marginBottom: number;
        maxHeight: number;
        minHeight: number;
        overflowY: 'auto';
      };

      if (key === CorrectionDetailsEnum.DOCUMENT) {
        name = { ...name, marginBottom: 16 };
        details = { ...details, marginBottom: 16 };
      }

      if (key === CorrectionDetailsEnum.COMMENT) {
        details = { ...details, maxHeight: 220, overflowY: 'auto' };

        if (data && data[key].length > 86) {
          details = { ...details, minHeight: data[key].length > 130 ? 120 : 76 };
        }
      }

      return { name, details };
    },
    [data, CorrectionDetailsEnum],
  );

  const getListDetails = useCallback(
    (key: CorrectionDetailsEnum) => {
      if (key === CorrectionDetailsEnum.DOCUMENT) {
        return Children.toArray(
          data?.documents.map((item) => (
            <span
              onClick={() => {
                if (isLoading || !(item.file_id && item.file_id.length > 0)) return;
                download({ link: item.file_id });
              }}
              style={{ ...getValueStyle(data?.active, key), marginBottom: 5 }}
            >
              <img style={{ marginRight: 10 }} src={clipSvg} alt="clip" />
              {item.title.slice(0, 30)}
              {item.title.length >= 30 && '...'}
            </span>
          )),
        );
      }

      if (key === CorrectionDetailsEnum.STATUS) {
        return (
          <span style={getValueStyle(data?.active, key)}>{data[key] ? t('tables.active') : t('tables.canceled')}</span>
        );
      }

      return data[key];
    },
    [CorrectionDetailsEnum, data, t],
  );

  const showDeleteButton = data?.active && data.type !== 'principal_repayment_amount';

  return (
    <PopUpLayout open={isOpenDetails} onClose={closeFC}>
      <div className={`${styles.wrapperContract} ${styles.scrollTable}`}>
        <img onClick={closeFC} className={styles.backContract} src={backSvg} alt="back" />
        <div className={styles.titleContract}>{t('tables.adjustmentDetails')}</div>
        <div className={`${styles.details} ${styles.scrollTable}`}>
          <ConfirmPopUp
            title={t('global.areYouSureAdjustment')}
            leftButtonText={t('global.deleteD')}
            rightButtonText={t('global.cancelC')}
            open={detailsConfirm}
            wrap={{ position: 'absolute', top: 46, width: 400, height: 199, left: 'calc(50% - 200px)' }}
            titleStyle={{ letterSpacing: '0.01em' }}
            leftCallback={async () => {
              if (data?.id) {
                await delAdjustment(data.id);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                confirmCallBack();
              }
              closeFC();
            }}
            rightCallback={() => {
              setDetailsConfirm(false);
            }}
          />
          {Children.toArray(
            dataKeyDetails.map(({ name, key }) => {
              const styleRow = getStyleRow(key);

              return (
                <>
                  <div style={styleRow.name}>{t(name)}</div>
                  <div className={styles.scrollTable} style={styleRow.details}>
                    {getListDetails(key)}
                  </div>
                </>
              );
            }),
          )}
        </div>
        <div style={{ marginTop: 30 }}>
          {showDeleteButton && (
            <Button
              startIcon={<img src={deleteSvg} alt="delete" />}
              onClick={() => {
                setDetailsConfirm(true);
              }}
              style={{ width: 199, height: 40, marginRight: 24, backgroundColor: '#EC3E72' }}
              {...stylingButtonPopUp}
            >
              {t('tables.deleteAdjustment')}
            </Button>
          )}
          <Button
            onClick={() => {
              closeFC();
            }}
            style={{ width: 207, height: 40 }}
            {...dropZoneStylingButtonPopUp}
            startIcon={<img src={leftSvg} alt="left" />}
          >
            {data.active ? t('tables.backAuditLog') : t('tables.backToAdjustment')}
          </Button>
        </div>
      </div>
    </PopUpLayout>
  );
};

const useStyle = makeStyles(() => ({
  details: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: 12,
    display: 'grid',
    alignContent: 'space-between',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    gridTemplateColumns: '212px 1fr',
    height: 344,
    overflowY: 'auto',
    letterSpacing: '0.02em',
    color: '#29292C',
  },
  wrapperContract: {
    overflowY: 'auto',
    boxSizing: 'border-box',
    width: 687,
    height: 573,
    maxHeight: '95vh',
    backgroundColor: '#F7F8FA',
    borderRadius: 8,
    padding: '68px 40px 40px 40px',
  },
  scrollTable: {
    scrollbarWidth: 'thin',
    /* width */
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 32,
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      border: '1px solid #ECEEF2',
      borderRadius: 8,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CECECE',
      borderRadius: 8,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#c6c8ce',
    },
  },
  titleContract: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    lineHeight: '29px',
    color: '#606176',
    marginBottom: 20,
    position: 'relative',
  },
  backContract: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 20,
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default CorrectionDetailsPopUp;
